import { reset } from "redux-form"
import { call, all, put, takeEvery } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"

import { updateStoredAuthToken } from "../authentication/saga"
import { tryWithNotifications, UID_ERROR } from "../notifications"
import { ApiTypes } from "../api"
import * as C from "./constants"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"

const adminPlaceholders = { firstName: "N/A", lastName: "N/A", email: "x@x.x" }

function* hideMyProfile() {
    yield put(Notifications.hide(UID_ERROR))
}

function* updateMyProfile(action) {
    const body =
        action.data.userId === 0 // user 0 doesn't have a name or email of their own
            ? { ...action.data, ...adminPlaceholders }
            : action.data

    yield* tryWithNotifications(
        {
            url: "/api/users/me",
            method: ApiTypes.PUT,
            body
        },
        function*({ authToken }) {
            yield updateStoredAuthToken(authToken)
            yield put(reset(C.MY_PROFILE_FORM))
            yield put(actions.hideMyProfile())
            yield put(actions.updateMyProfileSuccess())
            return "Saved changes to your profile"
        },
        function*() {
            yield put(actions.updateMyProfileFailure())
            return "Failed to update profile"
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(actionTypes.HIDE_MY_PROFILE, hideMyProfile)
        }),
        call(function*() {
            yield takeEvery(actionTypes.UPDATE_MY_PROFILE, updateMyProfile)
        })
    ])
}
