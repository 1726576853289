import React, { Component } from "react"
import SiteTitle from "../modules/core/SiteTitle"

class PageNotFound extends Component {
    render() {
        return (
            <div className="page_not_found">
                <SiteTitle title="Not Found" />
                <h1 style={{ textAlign: "center" }}>Page Not Found</h1>
            </div>
        )
    }
}

export default PageNotFound
