import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"

const formatConnected = isUp => (isUp ? "Connected" : "Unable to connect")
const formatRefreshDate = refreshDate => {
    const refreshMoment = moment.utc(
        refreshDate,
        "YYYY-MM-DDTHH:mm:ss.SSSZZ",
        true
    )

    return refreshMoment.isValid()
        ? refreshMoment.format("YYYY-MM-DD [at] HH:mm:ss ([UTC]ZZ)")
        : "Unknown"
}
export const StatusPage = ({ status: { dbSuccess, refreshDate } }) => (
    <Card className="server_status">
        <Card.Body>
            <Card.Title as="h1">HVPA Server Status</Card.Title>
            <Card.Subtitle as={Link} to="/">
                {process.env.PUBLIC_URL || "localhost:3000"}
            </Card.Subtitle>

            <Card.Text as="dl">
                <dt>Frontend Version</dt>
                <dd>v{process.env.REACT_APP_VERSION_NUMBER}</dd>
                <br />

                <dt>Database Status</dt>
                <dd>{formatConnected(dbSuccess)}</dd>
                <br />

                <dt>Database Refresh</dt>
                <dd>{formatRefreshDate(refreshDate)}</dd>
                <br />
            </Card.Text>
        </Card.Body>
    </Card>
)
export default connect(state => ({ status: state.server_status }))(StatusPage)
