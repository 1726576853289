import React from "react"
import { connect } from "react-redux"
import { Hoverlay } from "../../core/Hoverlay"
import { moduleEnabledSelector } from "../../moduleUtils"
import * as C from "../constants"
import * as reduxActions from "../actions"
import { MODULE_IDS } from "../../constants"

export const CDALink = ({
    cellData,
    rowData,
    moduleEnabled,
    downloadCdaXml,
    downloadCdaHtml
}) =>
    cellData &&
    moduleEnabled && (
        <>
            <Hoverlay placement="left" tooltip="Download CCDA as XML">
                <button
                    className="btn-blank"
                    onClick={() =>
                        downloadCdaXml(rowData[C.TABLE_KEYS.TCM_TRACKER_ID])
                    }
                >
                    <span className="fa fa-cloud-download" />
                </button>
            </Hoverlay>
            <Hoverlay placement="right" tooltip="Open CCDA as HTML page">
                <button
                    className="btn-blank"
                    onClick={() =>
                        downloadCdaHtml(rowData[C.TABLE_KEYS.TCM_TRACKER_ID])
                    }
                >
                    <span className="fa fa-eye" />
                </button>
            </Hoverlay>
        </>
    )

export default connect(
    state => ({ moduleEnabled: moduleEnabledSelector(MODULE_IDS.CCDA)(state) }),
    reduxActions
)(CDALink)
