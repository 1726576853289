import { delay } from "redux-saga"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"
import moment from "moment"

import { TOC_REFRESH_TIME_IN_MINUTES } from "../../configuration"
import { FetchError } from "../core/FetchError"
import { ApiTypes, downloadFile, fetchAndCheck, tryFetch } from "../api"
import { joinPath, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import { TIMESTAMP_STRING_COMPACT } from "../dates"
import { notifySimpleError } from "../notifications"

import * as C from "./constants"
import { addTimestamp } from "./helpers"
import { filtersSelector } from "./selectors"
import { authTokensSelector } from "../authentication/selectors"

export const UID_ERROR = "toc-print-error"

export function* exportToc() {
    const filters = yield select(filtersSelector)
    const sortDetails = yield select(getSortedColumns(C.NAME))
    const endpoint = urlWithParams(`/api/toc/csv`, {
        ...filters,
        ...sortDetails
    })
    const filename = `toc_${moment().format(TIMESTAMP_STRING_COMPACT)}.csv`
    // noinspection JSAnnotator,BadExpressionStatementJS,JSUnresolvedVariable
    yield* downloadFile("TOC CSV", endpoint, filename)
}

// TODO this could probably be unified with batch_print/saga.submitPrint() to some extent
export function* printToc() {
    const endpoint = "/api/toc/pdf"
    const defaultDelay = 5 // in seconds
    const shortDelay = 1
    let progress = 0

    yield put(Notifications.hide(UID_ERROR))

    let notification = progressNotification(progress) // keeping notification in a variable so we can hide it later
    yield put(notification)

    const filters = yield select(filtersSelector)
    const sortDetails = yield select(getSortedColumns(C.NAME))
    const request = {
        url: endpoint,
        body: { ...filters, ...sortDetails },
        method: ApiTypes.POST
    }

    try {
        const location = yield* fetchAndCheck(request, {
            needsBody: false,
            reader: response => response.headers?.get("location")
        })

        while (progress < 100) {
            let seconds = defaultDelay
            let taskStatus = yield* checkPrintStatus(location)

            if (taskStatus.status === C.TASK_STATUS.FAILED) {
                throw FetchError.newFromTask(request, taskStatus, "Print TOC")
            }

            if (progress !== taskStatus.progress) {
                progress = taskStatus.progress

                yield put(Notifications.hide(notification.uid))
                notification = progressNotification(progress)
                yield put(notification)

                seconds = shortDelay // so we don't wait too long after we hit 100%
            }
            yield call(delay, seconds * 1000)
        }
        yield put(Notifications.hide(notification.uid)) // so downloadFile() can take over the notifications

        const filename = `toc_${moment().format(TIMESTAMP_STRING_COMPACT)}.pdf`

        yield* downloadFile("TOC PDF", location.replace("/phs", ""), filename)
    } catch (error) {
        console.error(error.toString())
        yield* notifySimpleError("Failed to print TOC PDF")(error)
    } finally {
        yield put(Notifications.hide(notification.uid)) // so the notification doesn't stick around forever
    }
}

function progressNotification(percent) {
    return Notifications.warning({
        message: `Generating TOC PDF - ${percent}% complete`,
        position: "tc",
        autoDismiss: 0,
        dismissible: false
    })
}

function* checkPrintStatus(location) {
    return yield* fetchAndCheck({
        url: joinPath(location.replace("/phs", ""), "status"),
        reader: r => ({ ...r, body: r.json() })
    })
}

export function* refreshTocs() {
    const MILLISECONDS = TOC_REFRESH_TIME_IN_MINUTES * 60 * 1000
    while (true) {
        yield call(delay, MILLISECONDS)

        const details = yield select(getDetails)
        const authentication = yield select(authTokensSelector)
        if (authentication.authToken) {
            if (details.limit) {
                yield* tryFetch(
                    {
                        url: urlWithParams(`/api/toc`, details)
                    },
                    function*(body) {
                        yield put({
                            type: "@@fetcher/MODIFY_RESOURCE",
                            name: C.NAME,
                            dataTransform: () => addTimestamp(body),
                            stillInactive: true
                        })
                    }
                    // no failure handler, just wait for the next refresh.
                )
            }
        }
    }
}

const getDetails = state => {
    const { sort } = getSortedColumns(C.NAME)(state)
    return { sort, limit: 500, offset: 0 }
}

export function* entrySaga() {
    yield all([
        call(refreshTocs),
        call(function*() {
            yield takeEvery(C.EXPORT_TOC, exportToc)
        }),
        call(function*() {
            yield takeEvery(C.PRINT_TOC, printToc)
        })
    ])
}
