import React from "react"
import _ from "lodash"
import { CodeTile } from "./CodeTile"

export const CodeTiles = ({
    tilePropGetter = p => p,
    tileClassName,
    data,
    title,
    skipCount,
    className,
    style
}) => (
    <div className={className} style={style}>
        {_.isEmpty(data) || (
            <dt>
                {title}
                {skipCount || <span className="count"> ({data.length})</span>}
            </dt>
        )}
        {data.map((p, i) => (
            <CodeTile
                key={i}
                className={tileClassName}
                {...tilePropGetter(p, i)}
            />
        ))}
    </div>
)
