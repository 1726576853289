import { LOCAL, MODULE_IDS } from "../constants"
import { SHOW_GENERATE_PL } from "../patient_list/constants"
import { SHOW_ADD_PATIENT_MODAL } from "../add_new_patient/constants"
import { SHOW_INACTIVE_PATIENTS } from "../inactive_patients/constants"
import { SHOW_USER_TAGS_MODAL } from "../user_tags/constants"
import { OPEN_LOCATION_MODAL } from "../locations/constants"
import { CLEAR_ALERT_CACHE } from "../alerts/constants"
import { SHOW_BATCH_PRINT } from "../batch_print/constants"
import { SHOW_DOCUMENTS_MODAL } from "../documents/constants"

export const NAV_SEARCH_FORM = "NAV_SEARCH_FORM"

export const NAV_FORMAT = [
    {
        label: "Dashboard",
        href: "/dashboard",
        matchOn: ["/dashboard", "/patients", "/poc"]
    },
    {
        label: "Trackers",
        matchOn: ["/tcm", "/awv", "/patient-audits"],
        module: [MODULE_IDS.TCM_READ, MODULE_IDS.AWV],
        children: [
            {
                label: "Transitional Care Management",
                href: "/tcm",
                module: MODULE_IDS.TCM_READ
            },
            {
                label: "Annual Wellness Visit",
                href: "/awv",
                module: MODULE_IDS.AWV
            },
            {
                label: "Patient Audits",
                href: "/patient-audits",
                module: MODULE_IDS.AUDITS
            }
        ]
    },
    {
        label: "Campaigns",
        href: "/campaigns"
    },
    {
        label: "Reports",
        matchOn: "/reports",
        module: [
            MODULE_IDS.QUALITY_SUMMARY,
            MODULE_IDS.REPORTING,
            MODULE_IDS.COST_UTILIZATION
        ],
        children: [
            {
                label: "Quality Summary",
                href: "/reports/quality"
            },
            {
                label: "Cost Report",
                href: "/reports/cost"
            },
            {
                label: "Utilization Report",
                href: "/reports/utilization"
            },
            {
                label: "Reports Library",
                href: "/reports/library"
            }
        ]
    },
    {
        label: "Tools",
        children: [
            {
                label: "Generate Patient List",
                action: SHOW_GENERATE_PL
            },
            {
                label: "Add New Patient",
                action: SHOW_ADD_PATIENT_MODAL,
                module: MODULE_IDS.ADD_EDIT_PATIENT
            },
            {
                label: "View Inactive Patients",
                action: SHOW_INACTIVE_PATIENTS,
                module: MODULE_IDS.DEACTIVATE_PATIENT
            },
            {
                label: "Manage Tags",
                action: SHOW_USER_TAGS_MODAL
            },
            {
                label: "Shared Documents",
                action: SHOW_DOCUMENTS_MODAL,
                module: MODULE_IDS.DOCUMENTS
            },
            {
                label: "Community Resources",
                action: OPEN_LOCATION_MODAL,
                module: MODULE_IDS.COMMUNITY_RESOURCES
            },
            {
                label: "View Active Alerts",
                action: CLEAR_ALERT_CACHE
            },
            {
                label: "Batch Print",
                action: SHOW_BATCH_PRINT,
                module: [MODULE_IDS.POINT_OF_CARE, MODULE_IDS.EXTRACTS],
                requireAllModules: true
            },
            {
                label: "Change Log",
                href: "/changes"
            },
            {
                label: "User Guide",
                href: "https://guide.healthfocus.io/index.html",
                linkProps: { target: "_blank" },
                isExternal: 1
            }
        ]
    }
]

export const SEARCH_FIELDS = {
    SEARCH: "fuzzySearch",
    SCOPE: "scope",
    SEARCH_PATIENT_ID: "allowPatientId",
    SEARCH_CONTRACT_NUMBER: "allowContractNumber"
}

export const initialValues = {
    [SEARCH_FIELDS.SEARCH]: "",
    [SEARCH_FIELDS.SCOPE]: LOCAL
}
