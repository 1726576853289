import React from "react"
import { Button, Card } from "react-bootstrap"
import { reduxForm } from "redux-form"
import { connect } from "react-redux"
import classNames from "classnames"
import moment from "moment"
import _ from "lodash"
import LER from "../../core/LoadingErrorHOC"
import fetcher from "../../core/fetcher"
import SearchBar from "../../core/SearchBar"
import { isExternal, isSuperuser } from "../../authentication/selectors"
import { ACCESS_PRIVILEGES } from "../../permission_managment/constants"
import { filterBySearch } from "../../utils"
import { tableFactory } from "../../table"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import { getNewRow } from "../helpers"
import { USER_TAG_COLUMNS } from "../columns"
import { editTagInitialValuesSelector, userTagsSelector } from "../selectors"

const { Table, Settings, Description } = tableFactory({
    name: C.USER_TAGS_TABLE,
    columns: USER_TAG_COLUMNS,
    sortBy: [C.TABLE_KEYS.TAG_NAME],
    noFreeze: true // this table uses row renderers, which aren't compatible with column freezing at the moment
})

export class UserTagsTable extends React.Component {
    state = { search: "" }

    tagIsNew = () => this.props.currentTag[C.TABLE_KEYS.TAG_ID] === C.NEW_TAG_ID

    isCurrentTag = row =>
        this.props.currentTag[C.TABLE_KEYS.TAG_ID] === row[C.TABLE_KEYS.TAG_ID]

    isSelectable = rowData =>
        rowData[C.TABLE_KEYS.TAG_ID] !== 0 && // can't select All Patients
        !this.props.editing &&
        !this.isCurrentTag(rowData)

    annotateTagName = (row, tagNameDupes) => {
        const [tagName, owner, isPublic] = _.at(row, [
            C.TABLE_KEYS.TAG_NAME,
            C.TABLE_KEYS.OWNER_USERNAME,
            C.TABLE_KEYS.PUBLIC
        ])
        return (
            tagName +
            (!isPublic && tagNameDupes.has(tagName) ? ` (${owner})` : "")
        )
    }

    handleSearch = search => this.setState({ search })

    getTagNameDupes = () => {
        const rows = this.props.tags || []
        const tagsByName = _.groupBy(rows, row => row[C.TABLE_KEYS.TAG_NAME])
        return new Set(
            Object.keys(tagsByName).filter(name => tagsByName[name].length > 1)
        )
    }

    getScrollToRow = rows => {
        if (!this.props.editing) {
            return undefined
        }

        const sortDirectionNormalized = (
            this.props.sortDirection || []
        ).map(str => str.toLowerCase()) // lodash only accepts sort directions in lower case, but we save it in uppercase
        const orderedRows = _.orderBy(
            rows,
            this.props.sortBy,
            sortDirectionNormalized
        )
        return orderedRows.findIndex(this.isCurrentTag)
    }

    getStaticRowData = () => ({
        isSuperuser: this.props.isSuperuser,
        cancelEdit: this.props.cancelEdit,
        submit: this.props.handleSubmit,
        disableSave: this.props.invalid,
        deleteTag: () => {
            if (
                !window.confirm(
                    `Are you sure you want to delete the "${
                        this.props.currentTag[C.TABLE_KEYS.TAG_NAME]
                    }" tag? This cannot be undone!`
                )
            ) {
                return
            }
            this.props.deleteTag(this.props.currentTag[C.TABLE_KEYS.TAG_ID])
        }
    })

    shouldDisableEdit = row =>
        (row[C.TABLE_KEYS.OWNER_ID] === 0 && this.props.userId !== 0) || // tags owned by Admin can't be edited by anyone else
        row[C.TABLE_KEYS.PRIVILEGE] !== ACCESS_PRIVILEGES.EDIT ||
        (this.props.editing && !this.isCurrentTag(row)) // something else is being edited

    getNewRow = () => ({
        ...getNewRow(this.props),
        ...this.getStaticRowData(),
        [C.TABLE_KEYS.CREATED_DATE]: moment(),
        [C.TABLE_KEYS.PATIENT_COUNT]: 0,
        [C.TABLE_KEYS.PRIVILEGE]: "M",
        editing: true,
        saving: this.props.saving && this.tagIsNew()
    })

    resolveRow = tagNameDupes => row => ({
        ...row,
        ...this.getStaticRowData(),
        tagName: this.annotateTagName(row, tagNameDupes),
        editTag: () => this.props.editTag(row),
        editing: this.props.editing && this.isCurrentTag(row),
        saving: this.props.saving && this.isCurrentTag(row),
        disableEdit: this.shouldDisableEdit(row)
    })

    resolveRows = rows => {
        const tagNameDupes = this.getTagNameDupes()
        return rows.map(this.resolveRow(tagNameDupes))
    }

    selectRowsToInclude = () => {
        const resolved = filterBySearch(
            this.props.tags,
            C.TABLE_KEYS.TAG_NAME,
            this.state.search
        )

        if (this.props.editing && this.tagIsNew()) {
            resolved.push(this.getNewRow())
        }
        return resolved
    }

    addNewRow = () => {
        this.props.selectTag({
            ...this.getNewRow(),
            [C.TABLE_KEYS.TAG_ID]: C.NEW_TAG_ID,
            [C.TABLE_KEYS.TAG_NAME]: ""
        })
        this.props.editTag()
    }

    rowRenderer = ({ rowData }) => ({
        onClick: () =>
            this.isSelectable(rowData) && this.props.selectTag(rowData),
        className: classNames({
            selectable: this.isSelectable(rowData),
            selected: this.isCurrentTag(rowData)
        })
    })

    render() {
        const rows = this.selectRowsToInclude()
        return (
            <Card className="user-tags-table">
                <Card.Header className="card-header-group">
                    <Description Component="p" />
                    <p>
                        Public tags can be granted to any user; private tags are
                        only visible to you.
                    </p>
                    <SearchBar
                        className="tag-search"
                        placeholder="Filter by tag name"
                        onChange={this.handleSearch}
                        cosmeticButton
                    />
                    <Settings />
                </Card.Header>
                <LER loading={this.props.loading} error={this.props.error}>
                    <div role="form" className="table-container">
                        {/*using a div role="form" instead of an actual form because an actual form submits, reloading the page, if you hit Enter.*/}
                        <Table
                            list={this.resolveRows(rows)}
                            scrollToIndex={this.getScrollToRow(rows)}
                            rowRenderer={this.rowRenderer}
                        />
                    </div>
                    {this.props.editing || this.props.isExternal ? null : (
                        <Button
                            className="add-tag"
                            variant="outline-primary"
                            size="sm"
                            onClick={this.addNewRow}
                        >
                            New Tag
                        </Button>
                    )}
                </LER>
            </Card>
        )
    }
}

export default fetcher({
    name: C.USER_TAGS_TABLE,
    endpoint: "/api/tags/user",
    method: ApiTypes.GET,
    persistResource: true
})(
    connect(
        state => ({
            ...state[C.NAME],
            ...state.authentication,
            ...state.TABLES[C.USER_TAGS_TABLE],
            tags: userTagsSelector(state),
            initialValues: editTagInitialValuesSelector(state),
            isSuperuser: isSuperuser(state),
            isExternal: isExternal(state)
        }),
        { ...actions, onSubmit: actions.saveTag }
    )(
        reduxForm({
            form: C.EDIT_TAG_FORM,
            enableReinitialize: true
        })(UserTagsTable)
    )
)
