import React, { useMemo, useState } from "react"
import { Card } from "react-bootstrap"
import moment from "moment/moment"
import { getSpaceLeftBy } from "../../utils"
import * as C from "../constants"
import CareManagers from "./CareManagers"
import Providers from "./Providers"

const isActive = careManager => {
    const today = moment()
    return today.isSameOrBefore(careManager[C.KEYS.TERM_DT])
}

const CareTeam = ({ careManagers, metadata, providers }) => {
    const [showCMHistory, setShowCMHistory] = useState(false)

    const careManagersToShow = useMemo(
        () =>
            showCMHistory
                ? careManagers.map(careManager => ({
                      ...careManager,
                      [C.KEYS.INACTIVE]: !isActive(careManager)
                  }))
                : careManagers.filter(isActive),
        [careManagers, showCMHistory]
    )

    return (
        <Card className="card-table care_team">
            <CareManagers
                careManagers={careManagersToShow}
                metadata={metadata}
                showHistory={showCMHistory}
                setShowHistory={setShowCMHistory}
                maxLength={getSpaceLeftBy(providers, 6)}
            />
            <Providers
                providers={providers}
                metadata={metadata}
                maxLength={getSpaceLeftBy(careManagersToShow, 6)}
            />
        </Card>
    )
}
export default CareTeam
