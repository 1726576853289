import React from "react"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { getResourceDataAsObject } from "../../../core/fetcher"
import { showEdit } from "../../actions"
import * as C from "../../constants"

export const ShowEditButton = ({ canEdit, showEdit, campaignId }) =>
    !canEdit ? null : (
        <Button
            variant="primary"
            size="sm"
            onClick={() => showEdit(campaignId)}
        >
            <span className="fa fa-pencil" /> Edit Campaign
        </Button>
    )

export default connect(
    state =>
        getResourceDataAsObject(C.CAMPAIGN_PAGE, data =>
            _.pick(data.campaign, [
                C.TABLE_KEYS.CAMPAIGN_ID,
                C.TABLE_KEYS.EDIT_CAMPAIGN
            ])
        )(state),
    { showEdit }
)(ShowEditButton)
