import * as C from "./constants"

export const openModal = () => ({ type: C.SHOW_DOCUMENTS_MODAL })
export const closeModal = () => ({ type: C.HIDE_DOCUMENTS_MODAL })

export const uploadFiles = (files, description) => ({
    type: C.UPLOAD_DOCUMENTS,
    files,
    description
})

export const downloadFile = document => ({
    type: C.DOWNLOAD_DOCUMENT,
    document
})

export const deleteFile = documentId => ({
    type: C.DELETE_DOCUMENT,
    documentId
})
export const restoreFile = documentId => ({
    type: C.RESTORE_DOCUMENT,
    documentId
})

export const updateDescription = (documentId, description) => ({
    type: C.UPDATE_DOCUMENT_DESCRIPTION,
    documentId,
    description
})
