import { connect } from "react-redux"
import FilterHeader from "../../../core/FilterHeader"
import { getResourceData, getResourceDataAsArray } from "../../../core/fetcher"
import { FILTER_OPTIONS } from "../../../constants"
import { valueLabelToObj } from "../../../utils"
import * as reduxActions from "../../actions"
import * as C from "../../constants"
import { optionsMapSelector } from "../../../selectorUtils"

export default connect(
    state => ({
        filters: state.applied_filters, // these have been set to the campaign filters
        lookup: {
            /* the practice units in the FILTER_OPTIONS resource may not be enough.
             * If another user makes a campaign that filters on practice units that you do not have access to, and then shares the campaign with you, the names of those practice units won't be in FILTER_OPTIONS.
             * Instead, we have the request send us the list of all practice units used in the campaign, and merge that into your own unit filters lookup.*/
            units: {
                ...optionsMapSelector(data => data.units)(state),
                ...getResourceData(
                    C.CAMPAIGN_PAGE,
                    data => data.practiceUnits
                )(state)
            }
        }
    }),
    reduxActions
)(FilterHeader)
