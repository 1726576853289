import React from "react"
import * as C from "../constants"

export const PopulationCheck = ({ filters, setFilters }) => (
    <div
        className="population-check"
        style={{
            fontWeight:
                filters[C.FILTERS.POPULATION] ===
                C.POPULATION_VALUES.INCENTIVIZED
                    ? "bold"
                    : "normal"
        }}
        onClick={() =>
            setFilters({
                ...filters,
                [C.FILTERS.POPULATION]:
                    filters[C.FILTERS.POPULATION] ===
                    C.POPULATION_VALUES.INCENTIVIZED
                        ? C.POPULATION_VALUES.ALL
                        : C.POPULATION_VALUES.INCENTIVIZED
            })
        }
    >
        {filters[C.FILTERS.POPULATION] === C.POPULATION_VALUES.INCENTIVIZED ? (
            <span className="fa fa-check-square-o" />
        ) : (
            <span className="fa fa-square-o" />
        )}{" "}
        Limit to Incentivized Patients
    </div>
)
