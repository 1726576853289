export const NAME = "ADMISSIONS"

export const TABLE_KEYS = {
    EVENT_TYPE: "eventType",
    EVENT_DATE: "admitDt",
    DISCHARGE_DATE: "dischargeDt",
    FACILITY: "facilityName",
    DESCRIPTION: "diagDesc"
}

export const TRANSLATIONS = {
    [TABLE_KEYS.EVENT_TYPE]: "Type",
    [TABLE_KEYS.EVENT_DATE]: "Admission",
    [TABLE_KEYS.DISCHARGE_DATE]: "Discharge",
    [TABLE_KEYS.FACILITY]: "Facility",
    [TABLE_KEYS.DESCRIPTION]: "Description"
}
