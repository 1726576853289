import { connect } from "react-redux"
import { formValueSelector, reduxForm } from "redux-form"
import { FILTER_OPTIONS } from "../../../constants"
import { getResourceData } from "../../../core/fetcher"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import { canDeactivate } from "../../selectors"
import { DeactivatePatientModal } from "./DeactivatePatientModal"
import { PatientDeactivatedBar } from "./PatientDeactivatedBar"

export const DeactivatePatient = connect(
    state => ({
        ...state[C.NAME],
        canDeactivate: canDeactivate(state),
        reason: formValueSelector(C.DEACTIVATE_PATIENT_FORM)(
            state,
            C.KEYS.REASON
        ),
        reasons: getResourceData(
            FILTER_OPTIONS,
            data => data.deactivationReasons
        )(state)
    }),
    reduxActions
)(
    reduxForm({
        form: C.DEACTIVATE_PATIENT_FORM,
        onSubmit: (values, dispatch, props) => props.deactivatePatient(values)
    })(DeactivatePatientModal)
)

export const PatientDeactivated = connect(
    state => ({ canReactivate: canDeactivate(state) }),
    reduxActions
)(PatientDeactivatedBar)
