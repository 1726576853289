import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { Saving } from "../../../core/Loading"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import OrgIcon from "./OrgIcon"

const getProps = ({ rowData, pinCampaign, unpinCampaign }) =>
    rowData[C.FORCE_PINNED]
        ? {
              title: "Cannot unfavorite campaign",
              onClick: () => null,
              className: "unpin-icon",
              disabled: true
          }
        : rowData[C.USER_PINNED]
        ? {
              title: "Click to unfavorite campaign",
              onClick: unpinCampaign,
              className: "unpin-icon"
          }
        : {
              title: "Click to favorite campaign",
              onClick: pinCampaign,
              className: "pin-icon",
              orgClassName: "grayscale"
          }

export const PinUnpin = props => {
    const { disabled, onClick, orgClassName, className, title } = getProps(
        props
    )
    const { rowData } = props

    return rowData[C.TABLE_KEYS.SAVING] ? (
        <Saving />
    ) : rowData[C.TABLE_KEYS.ALL_HVPA_VISIBILITY] ? (
        <OrgIcon className={orgClassName} title={title} />
    ) : (
        <>
            <span
                className={classNames("fa fa-star", className)}
                title={title}
                onClick={() => !disabled && onClick(rowData)}
            />
        </>
    )
}

export default connect(null, reduxActions)(PinUnpin)
