import React, { useState } from "react"
import { Button, Card } from "react-bootstrap"
import LER from "../../core/LoadingErrorHOC"
import { tableFactory } from "../../table"
import { getInitialValues } from "../helpers"
import { COLUMNS } from "../columns"
import * as C from "../constants"
import AddAlertModal from "./AddAlertModal"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: COLUMNS
})

export const Alerts = ({
    data: { alerts },
    error,
    loading,
    createAlert,
    deleteAlert,
    initialize
}) => {
    const [modalShow, setModalShow] = useState(false)
    const closeModal = () => setModalShow(false)
    const openModal = () => {
        initialize(C.ADD_ALERT_FORM, getInitialValues())
        setModalShow(true)
    }

    return (
        <>
            <div className="page-header">
                <h1>Manage Alerts</h1>
                <Button
                    className="add_alert-modal-open_button"
                    variant="primary"
                    size="sm"
                    onClick={openModal}
                >
                    <i className="fa fa-plus-circle" /> New Alert
                </Button>
                <AddAlertModal
                    show={modalShow}
                    onHide={closeModal}
                    onSubmit={payload => createAlert(payload, closeModal)}
                />
            </div>
            <Card className="card-table">
                <Card.Header>
                    <Card.Title>Active Alerts</Card.Title>
                    <Settings />
                </Card.Header>
                <Description />
                <Card.Body>
                    <LER
                        list={alerts}
                        error={error}
                        loading={loading}
                        emptyMessage="No Alerts"
                    >
                        <Table list={alerts} tableData={{ deleteAlert }} />
                    </LER>
                </Card.Body>
            </Card>
        </>
    )
}
