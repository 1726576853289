import { TimestampDate, PatientName } from "../core/CellRenderers"
import { COLUMNS as PT_COLUMNS } from "../patient_tags/columns"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"
import {
    ActionButtons,
    PublicCheckbox,
    TagName
} from "./components/CellRenderers"

const column = columnBuilder(C.TABLE_TRANSLATION)

export const USER_TAG_COLUMNS = [
    column(C.TABLE_KEYS.TAG_NAME, {
        width: 140,
        flexGrow: 1,
        cellRenderer: TagName
    }),
    column(C.TABLE_KEYS.PUBLIC, {
        width: 70,
        align: ALIGNMENTS.CENTER,
        cellRenderer: PublicCheckbox
    }),
    column(C.TABLE_KEYS.OWNER, {
        width: 140,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PATIENT_COUNT, {
        width: 80,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.CREATED_DATE, {
        width: 150,
        cellRenderer: TimestampDate
    }),
    column(C.TABLE_KEYS.ACTIONS, {
        width: 80,
        disableSort: true,
        align: ALIGNMENTS.CENTER,
        cellRenderer: ActionButtons
    })
]

export const PATIENT_TAG_COLUMNS = [...PT_COLUMNS]

PATIENT_TAG_COLUMNS[0] = column(C.TABLE_KEYS.PATIENT_NAME, {
    width: 200,
    flexGrow: 1,
    cellRenderer: PatientName(C.TABLE_KEYS.PATIENT_ID)
}) // since we're displaying patients instead of tags
