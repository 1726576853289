import { SortDirection } from "react-virtualized"
export { ROLE_SUPERUSER } from "../admin/constants"
import * as PATIENT_LIST_CONSTANTS from "../patient_list/constants"
import { MEASURES_TABLE_COLUMNS } from "../patient_list/columns"

export const NAME = "CAMPAIGNS"
export const CAMPAIGN_LIST = "CAMPAIGN_LIST"
export const CAMPAIGN_PAGE = "CAMPAIGN_PAGE"
export const FAVORITE_CAMPAIGNS_NAME = "FAVORITE_CAMPAIGNS"
export const PATIENT_CAMPAIGNS_NAME = "PATIENT_CAMPAIGNS"
export const PATIENT_OTHER_CAMPAIGNS_NAME = "PATIENT_OTHER_CAMPAIGNS"
export const CAMPAIGN_PATIENT_LIST = "CAMPAIGN_PATIENT_LIST"
export const CAMPAIGN_PATIENT_LIST_COUNT = "CAMPAIGN_PATIENT_LIST_COUNT"
export const ACTIVE_USERS = "ACTIVE_USERS"
export const CAMPAIGN_FORM = "CAMPAIGN_FORM"
export const CAMPAIGN_FILTERS_FORM = "CAMPAIGN_FILTERS_FORM"
export const NEW_CAMPAIGN_FORM = "NEW_CAMPAIGN_FORM"
export const SET_CURRENT_CAMPAIGN = "SET_CURRENT_CAMPAIGN"
export const CURRENT_CAMPAIGN = "CURRENT_CAMPAIGN"
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const UNSUBSCRIBE_FROM_CAMPAIGN = "UNSUBSCRIBE_FROM_CAMPAIGN"
export const RESUBSCRIBE_TO_CAMPAIGN = "RESUBSCRIBE_TO_CAMPAIGN"
export const PIN_CAMPAIGN = "PIN_CAMPAIGN"
export const UNPIN_CAMPAIGN = "UNPIN_CAMPAIGN"
export const PIN_UNPIN_CAMPAIGN_COMPLETE = "PIN_UNPIN_CAMPAIGN_COMPLETE"
export const ADD_CAMPAIGN_PATIENT = "ADD_CAMPAIGN_PATIENT"
export const REMOVE_CAMPAIGN_PATIENT = "REMOVE_CAMPAIGN_PATIENT"
export const SHOW_EDIT = "SHOW_EDIT"
export const HIDE_EDIT = "HIDE_EDIT"
export const EDIT_CAMPAIGN_MODAL = "EDIT_CAMPAIGN_MODAL"

export const CREATE_NEW_CAMPAIGN = "CREATE_NEW_CAMPAIGN"
export const SAVE_CAMPAIGN_EDITS = "SAVE_CAMPAIGN_EDITS"
export const SET_CAMPAIGN_FILTERS = "SET_CAMPAIGN_FILTERS"

export const EXPORT_CAMPAIGN_PATIENT_LIST = "EXPORT_CAMPAIGN_PATIENT_LIST"
export const PRINT_CAMPAIGN_PATIENT_LIST = "PRINT_CAMPAIGN_PATIENT_LIST"

export const REMOVE_CAMPAIGN = "REMOVE_CAMPAIGN"
export const HIDE_CAMPAIGN = "HIDE_CAMPAIGN"
export const RESTORE_CAMPAIGN = "RESTORE_CAMPAIGN"

export const HVPA_WIDE = "hvpaWide"
export const VISIBILITY_SCOPE = "visibilityScope"

export const VISIBILITY_OPTIONS = {
    PRIVATE: "PRIVATE",
    SHARED: "SHARED"
}

export const UNSUBSCRIBED = "isUnsubscribed"
export const CAN_UNSUBSCRIBE = "canUnsubscribe"

export const USER_PINNED = "isUserPinned"
export const FORCE_PINNED = "isForcePinned"

export const TABLE_KEYS = {
    CAMPAIGN_ID: "campaignId",
    CAMPAIGN_NAME: "name",
    CAMPAIGN_DESCRIPTION: "description",
    CAMPAIGN_TYPE: "type",
    VISIBILITY_SCOPE: "visibilityScope",
    ALL_HVPA_VISIBILITY: "hvpaWide",
    OWNER_ID: "ownerId",
    CAMPAIGN_FILTER: "filter",
    EXTRA_PATIENTS: "extraPatients",
    UNIT_PRIVILEGES: "unitPrivileges",
    USER_PRIVILEGES: "userPrivileges",
    SUBSCRIBED: "subscribed",
    PINNED: "pinned",
    PIN_TYPE: "pinType",
    CAMPAIGN_OWNER: "ownerName",
    EDIT_CAMPAIGN: "canEdit",
    DELETE_CAMPAIGN: "canDelete",
    HIDDEN_VISIBILITY: "hidden_visibility",
    MEMBERSHIP: "hasPatient",
    MANUALLY_ADDED: "extraPatient",
    COLUMNS: "columns",
    FROZEN_COLUMNS: "frozenColumns",
    SAVING: "saving"
}

export const SECTIONS = {
    FAVOURITES: "favourites",
    CREATED: "created",
    HVPA_WIDE: "hvpaWide",
    SHARED: "shared",
    HIDDEN: "hidden"
}

export const {
    FILTERS,
    FILTER_TYPES,
    FILTER_TRANSLATION,
    initialValues: patientListInitialValues
} = PATIENT_LIST_CONSTANTS

export const CAMPAIGN_TYPE = {
    INFO: FILTER_TYPES.PATIENT_SUMMARY,
    MEASURES: FILTER_TYPES.PATIENT_MEASURES
}

export const CAMPAIGN_TRANSLATION = {
    ...FILTER_TRANSLATION,
    [TABLE_KEYS.CAMPAIGN_NAME]: "Campaign Name",
    [TABLE_KEYS.CAMPAIGN_TYPE]: "Campaign Type",
    [TABLE_KEYS.CAMPAIGN_DESCRIPTION]: "Description",
    [TABLE_KEYS.CAMPAIGN_OWNER]: "Owner",
    [TABLE_KEYS.EDIT_CAMPAIGN]: "Edit",
    [TABLE_KEYS.DELETE_CAMPAIGN]: "Delete",
    [TABLE_KEYS.HIDDEN_VISIBILITY]: "Hide",
    [VISIBILITY_SCOPE]: "Visibility",
    [CAMPAIGN_TYPE.INFO]: "Patient Summary",
    [CAMPAIGN_TYPE.MEASURES]: "Patient Measures",
    [TABLE_KEYS.EXTRA_PATIENTS]: "Additional Patients",
    [HVPA_WIDE]: "Visibility",
    [TABLE_KEYS.PIN_TYPE]: "Pin Campaign Settings"
}

export const DEFAULT_CAMPAIGN_SETTINGS = {
    filter: {
        ...patientListInitialValues,
        type: undefined // this is set one level up
    },
    [TABLE_KEYS.CAMPAIGN_TYPE]: CAMPAIGN_TYPE.MEASURES,
    [TABLE_KEYS.VISIBILITY_SCOPE]: VISIBILITY_OPTIONS.PRIVATE,
    [TABLE_KEYS.CAMPAIGN_DESCRIPTION]: "",
    [TABLE_KEYS.ALL_HVPA_VISIBILITY]: false,
    [TABLE_KEYS.EXTRA_PATIENTS]: [],
    [TABLE_KEYS.USER_PRIVILEGES]: [],
    [TABLE_KEYS.UNIT_PRIVILEGES]: [],
    [TABLE_KEYS.COLUMNS]: { columns: MEASURES_TABLE_COLUMNS, frozenColumns: [] }
}

export const DEFAULT_ORDER_BYS = {
    sortBy: [PATIENT_LIST_CONSTANTS.TABLE_KEYS.PATIENT_NAME],
    sortDirection: [SortDirection.ASC]
}

export const FORM_ASYNC_PROPS = {
    asyncBlurFields: [],
    asyncChangeFields: [TABLE_KEYS.CAMPAIGN_NAME]
}
