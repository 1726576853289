import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import * as LC from "../locations/constants"
import { dateFormat } from "../dates"
import * as C from "./constants"

const getRawCommunityLinkages = state =>
    getResourceData(C.COMMUNITY_LINKAGES, data => data.communityLinkages)(state)
const getCommunityLocations = state =>
    getResourceData(
        LC.COMMUNITY_LOCATIONS,
        data => data.communityLocations
    )(state)
const getPossibleCategories = state =>
    getResourceData(
        C.COMMUNITY_LINKAGES,
        data => data.communityLinkageOptions.categories
    )(state)

const getLabel = (list, filter, key) => {
    const foundItem = _.head(_.filter(list, filter))
    return foundItem ? foundItem[key] : ""
}

export const getLinkages = createSelector(
    getRawCommunityLinkages,
    getCommunityLocations,
    getPossibleCategories,
    (communityLinkages, communityLocations, categories) =>
        communityLinkages.map(linkage => ({
            ...linkage,
            date: dateFormat(linkage.srvDt), // the datepicker prefers MM/DD/YYYY to YYYY-MM-DD
            status: linkage.srvStatusCodeDescId,
            communityLocation: getLabel(
                communityLocations,
                location =>
                    location.communityLocationId ===
                    linkage.communityLocationId,
                "name"
            ),
            srvCatDescription: getLabel(
                categories,
                category => category.value === linkage.srvCatCodeDescId,
                "label"
            )
        }))
)
