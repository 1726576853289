import React from "react"
import { Field } from "redux-form"
import * as C from "../constants"
import { HiddenInput, TextInput } from "../../core/CustomFields"
import { maxLength, notInList, required } from "../../core/validators"

export const FavoriteNameField = ({ existingNames }) => (
    <>
        <Field
            name={C.FILTERS.NAME}
            component={TextInput}
            label="Name for this filter set"
            required
            validate={[required, maxLength(30), notInList(existingNames)]}
        />
        <Field name={C.FILTERS.ID} component={HiddenInput} />
    </>
)
