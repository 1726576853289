import * as PC from "../pickers/constants"
import * as C from "../cost/constants"
import { objToValueLabel } from "../utils"

export { FILTERS, FILTER_TRANSLATION } from "../cost/constants"

export const NAME = "COST_REPORT"
export const COST_REPORT_CATEGORY_TABLE = "COST_REPORT_CATEGORY_TABLE"
export const COST_REPORT_PPP_TABLE = "COST_REPORT_PPP_TABLE"
export const COST_REPORT_FILTERS_FORM = "COST_REPORT_FILTERS_FORM"
export const COST_REPORT_CHART = "COST_REPORT_CHART"
export const PROCEDURES_TABLE = "PROCEDURES_TABLE"

// actions
export const SET_COST_REPORT_FILTERS = "SET_COST_REPORT_FILTERS"
export const SET_COST_REPORT_VIEW = "SET_COST_REPORT_VIEW"
export const PRINT_COST_REPORT = "PRINT_COST_REPORT"
export const EXPORT_COST_REPORT = "EXPORT_COST_REPORT"
export const EXPORT_PROCEDURES = "EXPORT_PROCEDURES"

export const TABLE_KEYS = {
    ...C.TABLE_KEYS,
    EXPANDER: "expander",
    PROCEDURE_ID: "procedureId",
    PROCEDURE_NAME: "procedureName",
    SERVICING_PROVIDER_ID: "servicingProviderId",
    SERVICING_PROVIDER_NAME: "servicingProviderName",
    EPISODE_COUNT: "episodeCount",
    EVENT_COUNT: "eventCount",
    AVERAGE_COST: "costAverage",
    NETWORK_PERCENT: "percentNetwork",
    ALLOCATION_PERCENT: "percentAllocation",
    MONTHS: "months",
    MEMBER_MONTHS: "memberMonths",
    NETWORK_MEMBER_MONTHS: "networkMemberMonths",
    TOTAL_NETWORK: "networkClaimCost",
    NETWORK_EPISODE_COUNT: "networkEpisodeCount",
    RELATIVE_RISK: "relativeRisk"
}

export const TABLE_TRANSLATION = {
    ...C.TABLE_TRANSLATION,
    [TABLE_KEYS.PROCEDURE_NAME]: "Procedure",
    [TABLE_KEYS.SERVICING_PROVIDER_NAME]: "Servicing Provider",
    [TABLE_KEYS.EPISODE_COUNT]: "Episodes",
    [TABLE_KEYS.EVENT_COUNT]: "Events",
    [TABLE_KEYS.AVERAGE_COST]: "Average Cost",
    [TABLE_KEYS.NETWORK_PERCENT]: "Cost / Network",
    [TABLE_KEYS.ALLOCATION_PERCENT]: "Cost / Allocation",
    [TABLE_KEYS.TOTAL_NETWORK]: "Total Network",
    [TABLE_KEYS.MEMBER_MONTHS]: "M. Months",
    [TABLE_KEYS.NETWORK_MEMBER_MONTHS]: "Network MMs",
    [TABLE_KEYS.NETWORK_EPISODE_COUNT]: "Network Eps."
}

export const VIEWS = {
    CATEGORY: "CATEGORY",
    PAYER_PRACTICE_PROVIDER: "PAYER_PRACTICE_PROVIDER"
}

export const VIEW_TRANSLATION = {
    [VIEWS.CATEGORY]: "Category",
    [VIEWS.PAYER_PRACTICE_PROVIDER]: "Payer-Practice-Provider"
}
export const VIEW_OPTIONS = objToValueLabel(VIEW_TRANSLATION)

export const VIEW_TABLES = {
    [VIEWS.CATEGORY]: COST_REPORT_CATEGORY_TABLE,
    [VIEWS.PAYER_PRACTICE_PROVIDER]: COST_REPORT_PPP_TABLE
}

export const SUMMABLE_FIELDS = [
    TABLE_KEYS.EPISODE_COUNT,
    TABLE_KEYS.NETWORK_EPISODE_COUNT,
    TABLE_KEYS.TOTAL_COST,
    TABLE_KEYS.TOTAL_ALLOCATION,
    TABLE_KEYS.TOTAL_NETWORK
]

export const initialState = {
    view: VIEWS.CATEGORY,
    filters: PC.PERIOD_MONTH_STRINGS[PC.PERIODS.CURRENT_YEAR]
}
