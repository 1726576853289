import { SortDirection } from "react-virtualized"
import { getMaxDateColumn } from "../dates"

export const NAME = "UTILIZATION_DETAILS_TABLE"
export const UTILIZATION_DETAILS_FORM = "UTILIZATION_DETAILS_FORM"
export const UTILIZATION_DETAILS_CODE_DESCRIPTIONS =
    "UTILIZATION_DETAILS_CODE_DESCRIPTIONS"

// XXX idea: maybe cycle the axes of all these, so it's CLAIMS/LABS/PHARMACY/ADT at the top level in one big object, then NAMES/LABELS/etc in each of those. Then I could just pass one name (object?) and get everything I need.

export const CLAIMS = "CLAIMS"
export const LABS = "LABS"
export const PHARMACY = "PHARMACY"
export const MEDICATIONS = "MEDICATIONS"
export const ADT = "ADT"

export const KEYS = [CLAIMS, LABS, PHARMACY, MEDICATIONS, ADT]

export const LABELS = {
    [LABS]: "Results/Vitals",
    [PHARMACY]: "Pharmacy",
    [CLAIMS]: "Claims",
    [MEDICATIONS]: "Medication",
    [ADT]: "ADT"
}

export const ENDPOINT_SUFFIXES = {
    [CLAIMS]: "claims",
    [LABS]: "labs",
    [PHARMACY]: "prescriptions",
    [MEDICATIONS]: "medications",
    [ADT]: "adts"
}

export const TABLE_KEYS = {
    //shared
    SOURCE_ID: "sourceId",
    SOURCE_NAME: "sourceName",
    MASTER_PATIENT_ID: "masterPatientId", // currently unused
    MASTER_PROVIDER_ID: "masterProviderId",
    PROVIDER_NAME: "providerName",
    PROVIDER_NPI: "providerNpi",
    QUANTITY: "quantity",
    CODE: "code",
    CODE_DESCRIPTION: "codeDescription",

    // claims
    SOURCE_CLAIM_ID: "sourceClaimId",
    SOURCE_CLAIM_LINE: "sourceClaimLine",
    SERVICE_DATE: "serviceDate",
    PROCEDURE_CODE_TYPE: "procedureCodeType",
    PROCEDURE: "procedureFull",
    PROCEDURE_CODE: "procedureCode",
    PROCEDURE_CODE_DESC: "procedureCodeDesc",
    PAYER: "payerName",
    MODIFIERS: "modifiers",
    CLAIMS_DIAGNOSIS: "allDiagnosis",
    CLAIMS_DIAGNOSIS_CODES: "allDiagnosisCodes",
    CLAIMS_DIAGNOSIS_DESCS: "allDiagnosisDesc",
    PAID_AMT: "paidAmt",
    PLACE_OF_SERVICE: "placeOfService",

    // labs
    OBSERVATION_DATE: "observationDate",
    RESULT: "result",
    RESULT_OPERAND: "resultOperand",
    RESULT_UNITS: "resultUnits",
    MASTER_PROVIDER_IDS: "masterProviderIds",
    PROVIDER_NAMES: "providerNames",

    // pharmacy
    FILLED_DATE: "filledDate",
    NDC: "ndc",
    PROPRIETARY_NAME: "proprietaryName",
    SUBSTANCE_NAME: "substanceName",
    ACTIVE_NUMERATOR_STRENGTH: "activeNumeratorStrength",
    ACTIVE_INGRED_UNIT: "activeIngredUnit",
    DOSAGE_FORM: "dosageForm",
    ROUTE_NAME: "routeName",
    DAYS_SUPPLY: "daysSupply",
    COST: "cost",
    DEA_SCHEDULE: "deaSchedule",
    DOSE: "dose",

    // medication
    CODE_SYSTEM: "codeSystem",
    START_DATE: "startDate",
    END_DATE: "endDate",

    // ADT
    PATIENT_ID: "patientId", // currently unused
    FACILITY_NAME: "facilityName",
    ADMIT_DATE: "admitDt",
    DISCHARGE_DATE: "dischargeDt",
    ADMIT_TYPE: "admitType",
    DISCHARGE_TYPE: "dischargeType",
    TRANSITION_TYPE: "transitionType",
    ADT_DIAGNOSIS_CODES: "diagnosisCode",
    ADT_DIAGNOSIS_DESCRIPTIONS: "diagDesc",
    CCDA: "hasCda"
}

export const DEFAULT_ORDER_BYS = {
    [CLAIMS]: {
        sortBy: [TABLE_KEYS.SERVICE_DATE],
        sortDirection: [SortDirection.DESC]
    },
    [LABS]: {
        sortBy: [TABLE_KEYS.OBSERVATION_DATE],
        sortDirection: [SortDirection.DESC]
    },
    [PHARMACY]: {
        sortBy: [TABLE_KEYS.FILLED_DATE],
        sortDirection: [SortDirection.DESC]
    },
    [MEDICATIONS]: {
        sortBy: [getMaxDateColumn(TABLE_KEYS.START_DATE, TABLE_KEYS.END_DATE)],
        sortDirection: [SortDirection.DESC]
    },
    [ADT]: {
        sortBy: [
            getMaxDateColumn(TABLE_KEYS.ADMIT_DATE, TABLE_KEYS.DISCHARGE_DATE)
        ],
        sortDirection: [SortDirection.DESC]
    }
}

export const FORM_FIELDS = {
    ADMIT_DT_FROM: "admitDtFrom",
    ADMIT_DT_TO: "admitDtTo",
    DISCHARGE_DT_FROM: "dischargeDtFrom",
    DISCHARGE_DT_TO: "dischargeDtTo",
    START_DATE: "startDate",
    END_DATE: "endDate",
    START_DATE_FROM: "startDateFrom",
    START_DATE_TO: "startDateTo",
    END_DATE_FROM: "endDateFrom",
    END_DATE_TO: "endDateTo",
    PHYSICIAN: "providerId",
    PHYSICIANS: "providerIds",
    DIAGNOSIS: "diagnosisCode",
    CODE: "codes",
    MEDICATION: "medicationName",
    PROCEDURE: "procedureCode",
    TRANSITION_TYPE: "transitionType"
}

export const DATE_FIELDS = [
    FORM_FIELDS.START_DATE,
    FORM_FIELDS.END_DATE,
    FORM_FIELDS.START_DATE_FROM,
    FORM_FIELDS.START_DATE_TO,
    FORM_FIELDS.END_DATE_FROM,
    FORM_FIELDS.END_DATE_TO,
    FORM_FIELDS.ADMIT_DT_FROM,
    FORM_FIELDS.ADMIT_DT_TO,
    FORM_FIELDS.DISCHARGE_DT_FROM,
    FORM_FIELDS.DISCHARGE_DT_TO
]

export const UTILIZATION_DETAILS_FIELDS_TRANSLATION = {
    [FORM_FIELDS.ADMIT_DT_FROM]: "Admit Date From",
    [FORM_FIELDS.ADMIT_DT_TO]: "Admit Date To",
    [FORM_FIELDS.DISCHARGE_DT_FROM]: "Discharge Date From",
    [FORM_FIELDS.DISCHARGE_DT_TO]: "Discharge Date To",
    [FORM_FIELDS.START_DATE]: "Start Date",
    [FORM_FIELDS.END_DATE]: "End Date",
    [FORM_FIELDS.START_DATE_FROM]: "Start Date From",
    [FORM_FIELDS.START_DATE_TO]: "Start Date To",
    [FORM_FIELDS.END_DATE_FROM]: "End Date From",
    [FORM_FIELDS.END_DATE_TO]: "End Date To",
    [FORM_FIELDS.PHYSICIAN]: "Provider",
    [FORM_FIELDS.PHYSICIANS]: "Provider(s)",
    [FORM_FIELDS.DIAGNOSIS]: "Diagnosis Description",
    [FORM_FIELDS.CODE]: "Code Description",
    [FORM_FIELDS.MEDICATION]: "Medication",
    [FORM_FIELDS.PROCEDURE]: "Procedure",
    [FORM_FIELDS.TRANSITION_TYPE]: "Transition Type"
}

export const UTILIZATION_DETAILS_COLUMNS_TRANSLATION = {
    [TABLE_KEYS.SOURCE_NAME]: "Source Name",
    [TABLE_KEYS.QUANTITY]: "Quantity",
    [TABLE_KEYS.PROVIDER_NAME]: "Provider",
    [TABLE_KEYS.PROVIDER_NPI]: "Provider NPI",
    [TABLE_KEYS.SERVICE_DATE]: "Date",
    [TABLE_KEYS.PROCEDURE]: "Procedure",
    [TABLE_KEYS.PAYER]: "Payer",
    [TABLE_KEYS.CLAIMS_DIAGNOSIS]: "Diagnosis",
    [TABLE_KEYS.PAID_AMT]: "Paid",
    [TABLE_KEYS.OBSERVATION_DATE]: "Date",
    [TABLE_KEYS.CODE]: "Code Description",
    [TABLE_KEYS.RESULT]: "Result",
    [TABLE_KEYS.PROVIDER_NAMES]: "Provider",
    [TABLE_KEYS.FILLED_DATE]: "Date",
    [TABLE_KEYS.PROPRIETARY_NAME]: "Medication Name",
    [TABLE_KEYS.ROUTE_NAME]: "Route",
    [TABLE_KEYS.DAYS_SUPPLY]: "Days Supply",
    [TABLE_KEYS.COST]: "Cost/Price",
    [TABLE_KEYS.DOSE]: "Dose",
    [TABLE_KEYS.FACILITY_NAME]: "Facility",
    [TABLE_KEYS.PLACE_OF_SERVICE]: "Place of Service",
    [TABLE_KEYS.ADMIT_DATE]: "Admit Date",
    [TABLE_KEYS.DISCHARGE_DATE]: "Discharge Date",
    [TABLE_KEYS.ADMIT_TYPE]: "Admit Type",
    [TABLE_KEYS.DISCHARGE_TYPE]: "Discharge Type",
    [TABLE_KEYS.TRANSITION_TYPE]: "Transition Type",
    [TABLE_KEYS.ADT_DIAGNOSIS_CODES]: "Diagnosis",
    [TABLE_KEYS.ADT_DIAGNOSIS_DESCRIPTIONS]: "Diagnosis",
    [TABLE_KEYS.START_DATE]: "Start Date",
    [TABLE_KEYS.END_DATE]: "End Date",
    [TABLE_KEYS.CCDA]: "CCDA"
}
