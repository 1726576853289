import { objToValueLabel } from "../utils"
import { FILTERS } from "../toc/constants"
export { FILTERS, FILTER_TRANSLATION } from "../toc/constants"

export const NAME = "TCM"
export const TCM_FILTERS_FORM = "TCM_FILTERS_FORM"

export const UPDATE_TCM = "UPDATE_TCM"
export const EXPORT_TCM = "EXPORT_TCM"
export const REMOVE_TCM = "REMOVE_TCM"
export const RESTORE_TCM = "RESTORE_TCM"
export const SAVE_TCM_FILTERS = "SAVE_TCM_FILTERS"
export const SET_TCM_STATUS = "SET_TCM_STATUS"
export const TCM_UPDATE_ERROR = "TCM_UPDATE_ERROR"
export const TCM_CLEAR_ERROR = "TCM_CLEAR_ERROR"
export const DOWNLOAD_CDA_XML = "DOWNLOAD_CDA_XML"
export const DOWNLOAD_CDA_HTML = "DOWNLOAD_CDA_HTML"

export const TABLE_KEYS = {
    TCM_TRACKER_ID: "transitionId",
    PATIENT_ID: "patientId",
    PATIENT_NAME: "patName",
    PATIENT_DOB: "patDob",
    PATIENT_DOD: "patDod",
    PY_AGE: "pyAge",
    PCP: "pcpName",
    PRACTICE: "practiceName",
    CMI: "patCmIncentive",
    RISK_NUMBER: "patRiskNum",
    RISK_RANK: "patRiskRank",
    AT_RISK: "atRisk",
    LACE_SCORE: "patLaceScore",
    LACE_RISK: "patLaceRisk",
    FLAG_ICONS: "flagIcons",
    FLAG_COLORS: "flagColors",
    FLAG_DESCRIPTIONS: "flagDescriptions",
    PRODUCT_CLASS: "productClassName",
    PAYER_ID: "payerId",
    PAYER_NAME: "payerName",
    PAYER_IDS: "payerIds",
    ADMIT_DATE: "admitDt",
    DISCHARGE_DATE: "dischargeDt",
    DISCHARGE_FACILITY: "facilityName",
    ADMIT_DESCRIPTION: "admitStatusDesc",
    DISCHARGE_DESCRIPTION: "dischargeStatusDesc",
    EVENT_TYPE: "event",
    DIAGNOSIS: "diagnosis",
    CONCLUSION: "conclusion",
    FOLLOW_UP: "phoneCallDt", // was originally just phone calls, but has since diversified
    POST_FOLLOW_UP: "followUp",
    MED_RECONCILIATION: "medRecDt",
    OFFICE_VISIT: "officeVisitDt",
    BILLED: "billedDt",
    BILLING_DUE: "billingDueDt",
    READMIT: "readmitDt",
    COMMENT: "comment",
    REMOVE: "remove",
    STATUS: "tcmStatus",
    GRACE_PERIOD: "patInGracePrd",
    BILLING_POSSIBLE: "isBillable",
    PHONE_CALL_KEY: "keyPhoneCallDt",
    MED_RECONCILIATION_KEY: "keyMedRecDt",
    OFFICE_VISIT_KEY: "keyOfficeVisitDt",
    BILLED_KEY: "keyBilledDt",
    CONCLUSION_KEY: "keyConclusion",
    FOLLOW_UP_KEY: "keyFollowUp",
    COMMENT_KEY: "keyComment",
    CCDA: "hasCda",
    TAGS: "tags"
}

export const TRANSLATIONS = {
    [TABLE_KEYS.PATIENT_NAME]: "Patient Name",
    [TABLE_KEYS.PATIENT_DOB]: "DOB",
    [TABLE_KEYS.PY_AGE]: "PY Age",
    [TABLE_KEYS.RISK_NUMBER]: "Risk Rank",
    [TABLE_KEYS.AT_RISK]: "At Risk",
    [TABLE_KEYS.LACE_SCORE]: "LACE Score",
    [TABLE_KEYS.PRODUCT_CLASS]: "Product Class",
    [TABLE_KEYS.PAYER_NAME]: "Payer",
    [TABLE_KEYS.PAYER_IDS]: "Coverage",
    [TABLE_KEYS.PCP]: "PCP",
    [TABLE_KEYS.PRACTICE]: "Practice",
    [TABLE_KEYS.CMI]: "CMI",
    [TABLE_KEYS.ADMIT_DATE]: "Admit Date",
    [TABLE_KEYS.DISCHARGE_DATE]: "Discharge Date",
    [TABLE_KEYS.DISCHARGE_FACILITY]: "Discharge Facility",
    [TABLE_KEYS.ADMIT_DESCRIPTION]: "Admission Description",
    [TABLE_KEYS.DISCHARGE_DESCRIPTION]: "Discharge Description",
    [TABLE_KEYS.EVENT_TYPE]: "Event Type",
    [TABLE_KEYS.DIAGNOSIS]: "Diagnosis",
    [TABLE_KEYS.FOLLOW_UP]: "Follow-up",
    [TABLE_KEYS.MED_RECONCILIATION]: "Med Rec",
    [TABLE_KEYS.OFFICE_VISIT]: "Office Visit",
    [TABLE_KEYS.BILLING_DUE]: "Billing Due",
    [TABLE_KEYS.BILLED]: "Billed",
    [TABLE_KEYS.READMIT]: "Re-admit",
    [TABLE_KEYS.CONCLUSION]: "Conclusion",
    [TABLE_KEYS.POST_FOLLOW_UP]: "Post Follow-up",
    [TABLE_KEYS.COMMENT]: "Comment",
    [TABLE_KEYS.REMOVE]: "Remove",
    [TABLE_KEYS.STATUS]: "Status",
    [TABLE_KEYS.CCDA]: "CCDA",
    [TABLE_KEYS.TAGS]: "Tags"
}

// This is here because the database has an odd structure that puts a bunch of dates and corresponding IDs in a single flat structure. ###_KEY is the id for the entry holding the date field named ###.
export const TCM_LOOKUP = {
    [TABLE_KEYS.FOLLOW_UP]: TABLE_KEYS.PHONE_CALL_KEY,
    [TABLE_KEYS.MED_RECONCILIATION]: TABLE_KEYS.MED_RECONCILIATION_KEY,
    [TABLE_KEYS.OFFICE_VISIT]: TABLE_KEYS.OFFICE_VISIT_KEY,
    [TABLE_KEYS.BILLED]: TABLE_KEYS.BILLED_KEY,
    [TABLE_KEYS.CONCLUSION]: TABLE_KEYS.CONCLUSION_KEY,
    [TABLE_KEYS.POST_FOLLOW_UP]: TABLE_KEYS.FOLLOW_UP_KEY,
    [TABLE_KEYS.COMMENT]: TABLE_KEYS.COMMENT_KEY
}

export const STATUS_VALUES = { A: "A", I: "I", S: "S", R: "R", C: "C" } // just for clarity. Better to pass around a fixed set of constants than arbitrary character strings
export const STATUS_LABELS = {
    A: "Active",
    I: "Inactive",
    S: "Scheduled",
    R: "Removed",
    C: "Complete"
}
export const STATUS_OPTIONS = objToValueLabel(STATUS_LABELS)

export const initialState = {
    errors: new Set(),
    filters: { [FILTERS.STATUS]: [STATUS_VALUES.A, STATUS_VALUES.S] }
}
