import * as C from "./constants"

export const initializeTable = payload => ({
    type: C.INITIALIZE_TABLE,
    payload
})

export const updateTable = (tableName, payload) => ({
    type: C.UPDATE_TABLE,
    tableName,
    payload
})

export const resetTable = payload => ({
    type: C.RESET_TABLE,
    payload
})
