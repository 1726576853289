import moment from "moment"
import * as C from "./constants"

// the backend takes the date as separate year and month
export const getTableFilters = filters => {
    const [year, month] =
        filters[C.FILTERS.AS_OF_DATE]?.split("/").map(Number) || []
    return {
        ..._.omit(filters, [C.FILTERS.AS_OF_DATE]),
        [C.FILTERS.YEAR]: year || moment().year(),
        [C.FILTERS.MONTH]: month ?? moment().month() + 1 // +1 because Moment zero-indexes months
    }
}

// the chart replaces the DashboardFilters measures with its own input's value
export const getChartFilters = ({ filters, chartMeasure }) =>
    getTableFilters({
        ...filters,
        [C.FILTERS.MEASURES]: chartMeasure
    })
