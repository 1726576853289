import moment from "moment"
import _ from "lodash"
import { createSelector } from "reselect"
import { getResourceData } from "../core/fetcher"
import { TABLE_KEYS } from "../qsr/constants"
import { MEASURES } from "../constants"
import {
    isNilOrBlank,
    itemsToObject,
    matchesSearch,
    mergeObjects
} from "../utils"
import { getIdDateName, getIdName, getMeasureIdName } from "./helpers"
import * as C from "./constants"

const IS_REQUIRED = "is required"

const getIdFromFieldName = fieldName => Number(fieldName.split("_")[1])

function lacksDateBecauseAutocalc(fieldName, measures) {
    // autocalc measures can't have dates entered manually... unless they're composite measures
    const measure = measures[getIdFromFieldName(fieldName)]
    return measure.autoCalculated && measure.measureDatatype !== C.COMPOSITE
}

export const validate = (values, { measures }) => {
    const errors = {}

    // regular fields
    Object.keys(values)
        .filter(
            fieldName =>
                values[fieldName] && // ignore any entries with falsy values. ("0" isn't falsy btw because it's a string)
                !fieldName.includes("_date") &&
                !fieldName.includes("_sub_") &&
                !fieldName.includes("_comp_") && // we don't want the composite submeasures, only the combined value
                !lacksDateBecauseAutocalc(fieldName, measures)
        )
        .forEach(fieldName => {
            if (isNilOrBlank(values[`${fieldName}_date`])) {
                errors[`${fieldName}_date`] = IS_REQUIRED
            }
        })

    // subfields (usually survey questions)
    Object.keys(values)
        .filter(fieldName => fieldName.includes("_sub_") && values[fieldName])
        .forEach(fieldName => {
            const parentId = getIdFromFieldName(fieldName)
            const parentIdName = `id_${parentId}`
            if (isNilOrBlank(values[parentIdName])) {
                errors[parentIdName] = IS_REQUIRED
            }
        })

    // dates
    Object.keys(values)
        .filter(fieldName => fieldName.includes("_date"))
        .forEach(fieldName => {
            const baseFieldName = fieldName.replace("_date", "")
            if (values[fieldName] && !values[baseFieldName]) {
                errors[baseFieldName] = IS_REQUIRED
            }
        })

    return errors
}

// simple selectors

const getCategories = state =>
    getResourceData(C.ALL_MEASURES_TREE, data => data.categories)(state)

export const getRelevantCategories = state =>
    getResourceData(C.RELEVANT_MEASURES_TREE, data => data.categories)(state)
// can't put the "|| []" in these functions because it'll be a new empty list each time, which will prevent the selector from memoizing the value.

export const getAddMeasureFilter = state =>
    (state[C.ADD_NEW_MEASURES].filter || "").toLowerCase()

// memoized selectors

export const relevantCategoriesSelector = createSelector(
    getRelevantCategories,
    getResourceData(MEASURES, data => data.measuresMap),
    (categories, measuresMap) =>
        (categories || []).map(category => ({
            ...category,
            subCategories: category.subCategories.map(subcategory => ({
                ...subcategory,
                measures: subcategory.measures.map(measure => ({
                    ...measure,
                    link: _.get(measuresMap, [measure.id, TABLE_KEYS.LINK])
                })) // the function doesn't return the link, so we gotta add it ourselves
            })),
            incentivized: _.sumBy(category.subCategories, subcategory =>
                _.sumBy(
                    subcategory.measures,
                    measure =>
                        +(
                            moment().isAfter(moment.utc(measure.nextDueDt)) ||
                            measure.compliant === C.COMPLIANCE.NOT_COMPLIANT
                        ) // unary "+" turns booleans into ints
                )
            )
        }))
)

export const idLookUpSelector = createSelector(
    getRelevantCategories,
    categories =>
        mergeObjects(
            _.flatMap(categories, category =>
                _.flatMap(category.subCategories, subcategory =>
                    _.flatMap(subcategory.measures, measure => ({
                        [getMeasureIdName(measure)]: {
                            ...measure,
                            normalDropListOptions: measure.dropListOptions,
                            dropListOptions: itemsToObject(
                                measure.dropListOptions,
                                option => option.id,
                                option => option.name
                            )
                        },
                        [getIdDateName(measure.id)]: `${measure.name}'s date`
                    }))
                )
            )
        )
)

export const flatMeasuresMapSelector = createSelector(
    getResourceData(C.MEASURES, data => data.measures),
    measures => itemsToObject(measures, "measureId")
)

const mapAndFilterChildren = childrenTransformer => items =>
    (items || [])
        .map(item => ({
            ...item,
            children: childrenTransformer(item.children)
        }))
        .filter(item => item.children.length > 0)

export const addMeasureSelector = createSelector(
    idLookUpSelector,
    getCategories,
    (lookUpId, categories) =>
        mapAndFilterChildren(
            mapAndFilterChildren(children =>
                children.filter(measure => !lookUpId[getIdName(measure.value)])
            )
        )(categories || [])
)

export const filteredMeasures = createSelector(
    addMeasureSelector,
    getAddMeasureFilter,
    (categories, filter) =>
        mapAndFilterChildren(
            mapAndFilterChildren(children =>
                children.filter(item => matchesSearch(item.label, filter))
            )
        )(categories)
)

export const expandedMeasureSelector = createSelector(
    filteredMeasures,
    categories =>
        _.flatMap(categories, category => [
            category.label,
            ...category.children.map(child => child.label)
        ])
)
