import React from "react"

export const EmailLink = ({ email, urlParams }) => (
    <a
        href={"mailto:" + email + (urlParams ? "?" + urlParams : "")}
        target="_top"
    >
        {email}
    </a>
)

export default EmailLink
