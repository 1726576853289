import React from "react"
import { Accordion } from "react-bootstrap"
import { ReportListRow } from "./ReportListRow"

export const ReportListCategory = ({
    reportCategoryId,
    reportCategoryName,
    reports = [],
    selectedReportId
}) => (
    <Accordion className="report-library-category" defaultActiveKey="0">
        <Accordion.Toggle
            eventKey={reportCategoryId || "0"} // if category ID is 0 then it breaks for some reason
        >
            {reportCategoryName}
            <span className="fa fa-chevron-right" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={reportCategoryId || "0"}>
            <ul>
                {reports.map(item => (
                    <ReportListRow
                        key={item.reportId}
                        item={item}
                        isSelected={item.reportId === selectedReportId}
                    />
                ))}
            </ul>
        </Accordion.Collapse>
    </Accordion>
) // TODO ideally we'd have everything in the same accordion, but we can't do that until we update react-bootstrap to v2
