import * as C from "./constants"

export const getErrorKey = ({ field, patientId }) => `${field}-${patientId}`

export const getRedocNumFields = rowData => ({
    met: rowData[C.TABLE_KEYS.HCC_CONDITIONS_MET]?.length ?? 0,
    total: rowData[C.TABLE_KEYS.HCC_CONDITIONS]?.length ?? 0,
    digits: 0
})
export const getRedocRafFields = rowData => ({
    met: rowData[C.TABLE_KEYS.RAF_MET] ?? 0,
    total: rowData[C.TABLE_KEYS.RAF_TOTAL] ?? 0,
    digits: 3
})

const calculateRedoc = ({ met, total }) => (total ? met / total : null)
const calculateRedocRemaining = ({ met, total }) => (total ? total - met : null)

export const redocGetter = ({ rowData, columnData }) =>
    calculateRedoc(columnData.fieldsGetter?.(rowData) ?? {})
export const redocRemainingGetter = ({ rowData, columnData }) =>
    calculateRedocRemaining(columnData.fieldsGetter?.(rowData) ?? {})
