import React from "react"
import { Link } from "react-router-dom"
import { Hoverlay } from "../../../core/Hoverlay"
import * as C from "../../constants"

export const CampaignName = ({ cellData, rowData }) => (
    <Hoverlay tooltip={cellData}>
        <Link to={`/campaigns/${rowData[C.TABLE_KEYS.CAMPAIGN_ID]}`}>
            {cellData}
        </Link>
    </Hoverlay>
)
