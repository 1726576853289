import React from "react"
import { conditionalTimestampFormat, dateFormat } from "../../../../../dates"
import InputComponent from "./InputComponent"

const EditRow = ({
    oddRow,
    userSource,
    serviceCodeSystem,
    serviceCode,
    dataType,
    dropListOptions,
    valid,
    values,
    saving,
    onChange,
    serviceDt,
    logDt,
    saveEdits,
    cancelEdits,
    errors,
    subMeasureValues,
    onCompositeChange,
    measureId
}) => (
    <tr className={`${oddRow ? "oddRow" : ""} historical_row`}>
        <td>{userSource}</td>
        <td>{serviceCodeSystem}</td>
        <td>{serviceCode}</td>
        <td>
            <InputComponent
                measureId={measureId}
                dataType={dataType}
                dropListOptions={dropListOptions}
                errors={errors}
                disabled={saving}
                values={values}
                onChange={onChange}
                onCompositeChange={onCompositeChange}
                subMeasureValues={subMeasureValues}
            />
        </td>
        <td>{dateFormat(serviceDt)}</td>
        <td>{conditionalTimestampFormat(logDt)}</td>
        {valid ? (
            <td className="historical_row-buttons">
                <span
                    title="Save"
                    onClick={saveEdits}
                    className="fa fa-floppy-o"
                />
                <span
                    className="fa fa-times cancel-measure-edit"
                    onClick={cancelEdits}
                    title="Cancel"
                />
            </td>
        ) : (
            <td className="error">{errors[Object.keys(errors)[0]]}</td>
        )}
    </tr>
)

export default EditRow
