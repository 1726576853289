import * as saga from "./saga"
import * as components from "./components"
import * as constants from "./constants"
import * as actions from "./actions"
import reducer from "./reducer"

export default {
    actions,
    components,
    constants,
    reducer,
    saga
}
