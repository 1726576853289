import React from "react"
import { Field } from "redux-form"
import { Col, Row } from "react-bootstrap"
import { SelectField } from "../../core/CustomFields"
import * as QC from "../../qsr/constants"
import * as C from "../constants"

const commonFieldProps = {
    component: SelectField,
    placeholder: "All",
    multi: true
}

export const FiltersForm = ({
    useConditions,
    filterOptions: {
        hccConditions,
        payers,
        productClasses,
        productGroups,
        qsrMeasures,
        practiceUnits,
        regions
    }
}) => (
    <Row>
        <Col sm={6}>
            <Field
                name={QC.FILTERS.PAYERS}
                options={payers}
                {...commonFieldProps}
            />
        </Col>
        <Col sm={6}>
            <Field
                name={QC.FILTERS.POPULATION}
                options={C.POPULATION_OPTIONS}
                component={SelectField}
                notClearable
            />
        </Col>
        <Col sm={6}>
            <Field
                name={QC.FILTERS.PRODUCTS}
                options={productClasses}
                {...commonFieldProps}
            />
        </Col>
        <Col sm={6}>
            <Field
                name={QC.FILTERS.PRODUCT_GROUPS}
                options={productGroups}
                {...commonFieldProps}
            />
        </Col>
        <Col sm={6}>
            <Field
                name={QC.FILTERS.UNITS}
                options={practiceUnits}
                {...commonFieldProps}
            />
        </Col>
        <Col sm={6}>
            <Field
                name={QC.FILTERS.REGIONS}
                options={regions}
                {...commonFieldProps}
            />
        </Col>
        <Col>
            {useConditions ? (
                <Field
                    name={QC.FILTERS.HCC_CONDITIONS}
                    options={hccConditions}
                    {...commonFieldProps}
                />
            ) : (
                <Field
                    name={QC.FILTERS.MEASURES}
                    options={qsrMeasures}
                    {...commonFieldProps}
                />
            )}
        </Col>
    </Row>
)
