import { connect } from "react-redux"
import { getFormValues, isInvalid, submit } from "redux-form"
import { authTokensSelector } from "../../../authentication/selectors"
import { canEditCampaignSelector } from "../../selectors"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import { EditCampaignModal } from "./EditCampaignModal"

export { default as ShowEditButton } from "./ShowEditButton"

export default connect(
    state => ({
        authentication: authTokensSelector(state),
        campaignId: state[C.NAME][C.EDIT_CAMPAIGN_MODAL],
        canEdit: canEditCampaignSelector(state),
        formValues: getFormValues(C.CAMPAIGN_FORM)(state),
        invalid: isInvalid(C.CAMPAIGN_FORM)(state)
    }),
    { ...reduxActions, submit }
)(EditCampaignModal)
