import React from "react"
import { Dropdown } from "react-bootstrap"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"
import { FiltersCell } from "./FiltersCell"

export const FavoriteItem = ({ item, onClick, ...props }) => (
    <Hoverlay
        placement="right"
        isPopover
        className="filter-favorites-popover"
        tooltip={
            <FiltersCell cellData={item[C.TABLE_KEYS.FILTERS]} rowData={item} />
        }
    >
        <Dropdown.Item as="button" onClick={() => onClick(item)} {...props}>
            {item[C.TABLE_KEYS.NAME]}
        </Dropdown.Item>
    </Hoverlay>
)
