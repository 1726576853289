import React, { useMemo } from "react"
import { Field } from "redux-form"
import { connect } from "react-redux"
import { Col } from "react-bootstrap"
import _ from "lodash"
import { createStructuredSelector } from "reselect"
import { getResourceData } from "../../core/fetcher"
import { LabelledDatePicker, SelectField } from "../../core/CustomFields"
import { afterDateField, beforeDateField } from "../../core/validators"
import { getDynamicOptions } from "../helpers"
import * as C from "../constants"

const DateFilters = () => (
    <React.Fragment>
        <Col sm={6} lg={2}>
            <Field
                name={C.FORM_FIELDS.START_DATE}
                component={LabelledDatePicker}
                validDates={beforeDateField(C.FORM_FIELDS.END_DATE)}
                validate={beforeDateField(C.FORM_FIELDS.END_DATE)}
            />
        </Col>
        <Col sm={6} lg={2}>
            <Field
                name={C.FORM_FIELDS.END_DATE}
                component={LabelledDatePicker}
                validDates={afterDateField(C.FORM_FIELDS.START_DATE)}
                validate={afterDateField(C.FORM_FIELDS.START_DATE)}
            />
        </Col>
    </React.Fragment>
)

const ClaimsFilters = ({ list }) => {
    const providers = useMemo(
        () =>
            getDynamicOptions(
                list,
                C.TABLE_KEYS.MASTER_PROVIDER_ID,
                C.TABLE_KEYS.PROVIDER_NAME
            ),
        [list]
    )
    const procedures = useMemo(
        () =>
            getDynamicOptions(
                list,
                C.TABLE_KEYS.PROCEDURE_CODE,
                C.TABLE_KEYS.PROCEDURE
            ),
        [list]
    )
    const diagnoses = useMemo(
        () =>
            getDynamicOptions(
                _.map(list, item => ({
                    ...item,
                    // the lists can come in two different orders, so we need to sort them manually
                    [C.TABLE_KEYS.CLAIMS_DIAGNOSIS_CODES]: _.sortBy(
                        item[C.TABLE_KEYS.CLAIMS_DIAGNOSIS_CODES]
                    ),
                    [C.TABLE_KEYS.CLAIMS_DIAGNOSIS]: _.sortBy(
                        item[C.TABLE_KEYS.CLAIMS_DIAGNOSIS]
                    )
                })),
                C.TABLE_KEYS.CLAIMS_DIAGNOSIS_CODES,
                C.TABLE_KEYS.CLAIMS_DIAGNOSIS
            ),
        [list]
    )

    return (
        <React.Fragment>
            <DateFilters />
            <Col sm={6} lg={2}>
                <Field
                    name={C.FORM_FIELDS.PHYSICIAN}
                    component={SelectField}
                    options={providers}
                />
            </Col>
            <Col sm={6} lg={3}>
                <Field
                    name={C.FORM_FIELDS.PROCEDURE}
                    component={SelectField}
                    options={procedures}
                />
            </Col>
            <Col sm={12} lg={3}>
                <Field
                    name={C.FORM_FIELDS.DIAGNOSIS}
                    component={SelectField}
                    options={diagnoses}
                />
            </Col>
        </React.Fragment>
    )
}

const LabsFilters = ({ codes, providers }) => (
    <React.Fragment>
        <DateFilters />
        <Col sm={6} lg={3}>
            <Field
                name={C.FORM_FIELDS.PHYSICIAN}
                component={SelectField}
                options={providers}
            />
        </Col>
        <Col sm={6} lg={3}>
            <Field
                name={C.FORM_FIELDS.CODE}
                component={SelectField}
                options={codes}
                multi={true}
            />
        </Col>
    </React.Fragment>
)

const PharmacyFilters = ({ list }) => {
    const meds = getDynamicOptions(
        list,
        C.TABLE_KEYS.PROPRIETARY_NAME,
        C.TABLE_KEYS.PROPRIETARY_NAME
    )
    return (
        <React.Fragment>
            <DateFilters />
            <Col lg={4}>
                <Field
                    name={C.FORM_FIELDS.MEDICATION}
                    component={SelectField}
                    options={meds}
                />
            </Col>
        </React.Fragment>
    )
}

const MedicationFilters = ({ codes }) => (
    <React.Fragment>
        <Col sm={6} lg={2}>
            <Field
                name={C.FORM_FIELDS.START_DATE_FROM}
                component={LabelledDatePicker}
                validDates={beforeDateField(C.FORM_FIELDS.START_DATE_TO)}
                validate={beforeDateField(C.FORM_FIELDS.START_DATE_TO)}
            />
        </Col>
        <Col sm={6} lg={2}>
            <Field
                name={C.FORM_FIELDS.START_DATE_TO}
                component={LabelledDatePicker}
                validDates={afterDateField(C.FORM_FIELDS.START_DATE_FROM)}
                validate={afterDateField(C.FORM_FIELDS.START_DATE_FROM)}
            />
        </Col>
        <Col sm={6} lg={2}>
            <Field
                name={C.FORM_FIELDS.END_DATE_FROM}
                component={LabelledDatePicker}
                validDates={beforeDateField(C.FORM_FIELDS.END_DATE_TO)}
                validate={beforeDateField(C.FORM_FIELDS.END_DATE_TO)}
            />
        </Col>
        <Col sm={6} lg={2}>
            <Field
                name={C.FORM_FIELDS.END_DATE_TO}
                component={LabelledDatePicker}
                validDates={afterDateField(C.FORM_FIELDS.END_DATE_FROM)}
                validate={afterDateField(C.FORM_FIELDS.END_DATE_FROM)}
            />
        </Col>
        <Col lg={4}>
            <Field
                name={C.FORM_FIELDS.CODE}
                component={SelectField}
                options={codes}
            />
        </Col>
    </React.Fragment>
)

const AdtFilters = ({ list }) => {
    const transitions = getDynamicOptions(
        list,
        C.TABLE_KEYS.TRANSITION_TYPE,
        C.TABLE_KEYS.TRANSITION_TYPE
    )
    return (
        <React.Fragment>
            <Col sm={6} lg={2}>
                <Field
                    name={C.FORM_FIELDS.ADMIT_DT_FROM}
                    component={LabelledDatePicker}
                    validDates={beforeDateField(C.FORM_FIELDS.ADMIT_DT_TO)}
                    validate={beforeDateField(C.FORM_FIELDS.ADMIT_DT_TO)}
                />
            </Col>
            <Col sm={6} lg={2}>
                <Field
                    name={C.FORM_FIELDS.ADMIT_DT_TO}
                    component={LabelledDatePicker}
                    validDates={afterDateField(C.FORM_FIELDS.ADMIT_DT_FROM)}
                    validate={afterDateField(C.FORM_FIELDS.ADMIT_DT_FROM)}
                />
            </Col>
            <Col sm={6} lg={2}>
                <Field
                    name={C.FORM_FIELDS.DISCHARGE_DT_FROM}
                    component={LabelledDatePicker}
                    validDates={beforeDateField(C.FORM_FIELDS.DISCHARGE_DT_TO)}
                    validate={beforeDateField(C.FORM_FIELDS.DISCHARGE_DT_TO)}
                />
            </Col>
            <Col sm={6} lg={2}>
                <Field
                    name={C.FORM_FIELDS.DISCHARGE_DT_TO}
                    component={LabelledDatePicker}
                    validDates={afterDateField(C.FORM_FIELDS.DISCHARGE_DT_FROM)}
                    validate={afterDateField(C.FORM_FIELDS.DISCHARGE_DT_FROM)}
                />
            </Col>
            <Col sm={12} lg={4}>
                <Field
                    name={C.FORM_FIELDS.TRANSITION_TYPE}
                    component={SelectField}
                    options={transitions}
                />
            </Col>
        </React.Fragment>
    )
}

const filterComponents = {
    [C.CLAIMS]: ClaimsFilters,
    [C.LABS]: LabsFilters,
    [C.PHARMACY]: PharmacyFilters,
    [C.MEDICATIONS]: MedicationFilters,
    [C.ADT]: AdtFilters
}

const Filters = ({ tab, ...props }) => {
    const Component = filterComponents[tab]
    return Component ? <Component {...props} /> : null
}

const filtersSelector = createStructuredSelector({
    list: getResourceData(
        C.NAME,
        data =>
            data.claims ||
            data.labs ||
            data.prescriptions ||
            data.medications ||
            data.adts
    ),
    providers: getResourceData(C.NAME, data => data.providers),
    codes: getResourceData(C.NAME, data => data.codeDescriptions)
})
export default connect(filtersSelector)(Filters)
