import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { getResourceData } from "../../core/fetcher"
import { Hoverlay } from "../../core/Hoverlay"
import { phoneFormat, valueLabelToObj } from "../../utils"
import * as C from "../constants"
import LocationActions from "./LocationActions"

const DisplayList = connect(state => ({
    options: getResourceData(
        C.COMMUNITY_LOCATIONS,
        data => data.communityLocationOptions
    )(state)
}))(({ options, cellData = [], dataKey }) => {
    const dropdownList = options[dataKey]
    const lookupLabel = valueLabelToObj(dropdownList)

    const list = cellData.map(id => lookupLabel[id])
    return _.isEmpty(cellData) ? null : (
        <Hoverlay placement="top" tooltip={list.join(", ")}>
            {list[0]}
            {cellData.length > 1 && ` (+${cellData.length - 1})`}
        </Hoverlay>
    )
})

const CellRenderer = ({ cellData, rowData, dataKey }) => {
    switch (dataKey) {
        case C.TABLE_KEYS.COUNTIES:
        case C.TABLE_KEYS.REQUIREMENTS:
        case C.TABLE_KEYS.SOCIAL_NEEDS_DOMAIN:
        case C.TABLE_KEYS.SERVICES:
            return <DisplayList cellData={cellData} dataKey={dataKey} />
        case C.TABLE_KEYS.ACTIONS:
            return <LocationActions rowData={rowData} />
        case C.TABLE_KEYS.PHONE:
            return <span>{phoneFormat(cellData)}</span>
        case C.TABLE_KEYS.LOCATION_NAME:
        case C.TABLE_KEYS.PRIMARY_CONTACT:
            return <Hoverlay placement="top">{cellData}</Hoverlay>
        default:
            return <span>{cellData}</span>
    }
}

export default CellRenderer
