export const NAME = "INACTIVE_PATIENTS"

// actions
export const SHOW_INACTIVE_PATIENTS = "SHOW_INACTIVE_PATIENTS"
export const HIDE_INACTIVE_PATIENTS = "HIDE_INACTIVE_PATIENTS"
export const DEACTIVATE_PATIENT = "DEACTIVATE_PATIENT"
export const REACTIVATE_PATIENT = "REACTIVATE_PATIENT"

export const TABLE_KEYS = {
    PATIENT_ID: "patientId",
    PATIENT_NAME: "patientName",
    USER_NAME: "userName",
    REASON_ID: "reasonId",
    REASON: "reason",
    GENDER: "gender",
    PCP: "pcp",
    PRACTICE: "practice",
    DOB: "dob",
    EFF_DATE: "effDt",
    ACTIVE: "active",
    CAN_ACTIVATE: "canActivate",
    ACTIONS: "actions"
}
export const TRANSLATION = {
    [TABLE_KEYS.PATIENT_NAME]: "Patient Name",
    [TABLE_KEYS.USER_NAME]: "Deactivated By",
    [TABLE_KEYS.REASON]: "Reason",
    [TABLE_KEYS.GENDER]: "Gender",
    [TABLE_KEYS.PCP]: "Primary Care Provider",
    [TABLE_KEYS.PRACTICE]: "Practice",
    [TABLE_KEYS.DOB]: "Date of Birth",
    [TABLE_KEYS.EFF_DATE]: "Effective Date",
    [TABLE_KEYS.ACTIVE]: "Active",
    [TABLE_KEYS.ACTIONS]: "Actions"
}

export const initialState = { open: false }
