import React from "react"
import { Field, reduxForm } from "redux-form"
import _ from "lodash"
import { Col, Row } from "react-bootstrap"

import { SelectField, LabelledDatePicker } from "../../../core/CustomFields"
import { required, todayOrBefore, validDate } from "../../../core/validators"

import * as C from "../../constants"

const validateReasons = value =>
    _.isEmpty(value) ? "At least one reason is required" : undefined

const CareManagerForm = props => (
    <Row>
        <Col sm={12}>
            <Field
                name={C.KEYS.USER_ID}
                component={SelectField}
                options={props.careManagers}
                validate={required}
                required
                notClearable
            />
        </Col>
        <Col sm={6}>
            <Field
                name={C.KEYS.EFF_DT}
                component={LabelledDatePicker}
                validDates={todayOrBefore}
                validate={[validDate, todayOrBefore]}
                // validDates is for the datepicker; validate is for the form.
            />
        </Col>
        <Col sm={6}>
            <Field
                name={C.KEYS.TERM_DT}
                component={LabelledDatePicker}
                validate={validDate}
            />
        </Col>
        <Col sm={12}>
            <Field
                name={C.KEYS.REASON_IDS}
                component={SelectField}
                options={props.reasons}
                validate={validateReasons}
                multi
                required
            />
        </Col>
    </Row>
)

export default reduxForm({
    form: C.EDIT_CARE_MANAGER_FORM,
    enableReinitialize: true
})(CareManagerForm)
