import { joinTruthy } from "../utils"
import * as C from "./constants"

export const getTitle = (title, record) =>
    joinTruthy(
        [
            title,
            record[C.TABLE_KEYS.PATIENT_NAME],
            record[C.TABLE_KEYS.SERVICE_DESC]
        ],
        " - "
    )
