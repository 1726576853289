import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import user_audit from "../modules/user_audits"
import { dateFormat } from "../modules/dates"
import { GuideLink } from "../modules/indicators"

const CHANGES = [
    {
        date: "2024-08-30",
        version: "18.20.0",
        updates: [
            "Additions",
            [
                "Add Contract Numbers column to patient list",
                "Add Lace Score column to TOC and TCM",
                "Add refresh LACE support for a specific patient_id",
                "Add NPI_MATCH and AP (attributed provider) filters",
                "Add questionnaire detail and social history form loads to Athena Snowflake",
                "Add patient_risk refresh to intraday",
                "Add Encounter for Well Care value set to WCV measure",
                "Add vitals and A1C to EPIC Loader",
                "Add phq>=10 tag",
                "Add composite index to clinical_result and other indexes to temporary tables when loading clinical_results",
                "Add Risk Revenue module",
                "Add PH medication adherence report",
                "Add hf_import module to normalize and import providers",
                "Add inactive loader for FMOM Petoskey TAPAC BMS",
                "Add inactive patient tag",
                "Add member exclude table and update refresh mpi to account for member exclusions"
            ],
            "Fixes",
            [
                "Only hide patient search scope if global patient search is disabled, rather than the entire popover",
                "Fix missing comments",
                "Fix tobacco use screening entries",
                "Fix diastolic BP reference for Healthjump",
                "Fix incorrect date filtering on AWV worklist",
                "Fix Audit Tracker not restricting on specific codes in code reference",
                "Fix GLMC appt",
                "Fix patient audits name filter"
            ],
            "Improvements",
            [
                "Remove full practice list in Superuser Providers tabs; just show the ones they belong to",
                "Return numeric lace score (when exists)",
                "Return lace score and rank in TOC",
                "Refactor phr.risk to view and use partitions for underlying data",
                "Include all patient contract numbers in patient_summary",
                "Improve Epic loader",
                "Remove file extension from regex for bcc_elgp",
                "Exclude empty string results from PHQ9 tag",
                "Include SNOMED in the standard codes that are pivoted for OBX inclusion"
            ],
            "Updates",
            [
                "Update filter name display for fuzzy search",
                "Update Dx opportunity table to include identified_date",
                "Update DBM report to include ACA membership",
                "Update midwest cardiology eligibility loader",
                "Update RBCE Loader",
                "Update build_code_reference to override dev/prod status if a service code is explicitly requested",
                "Update Agilon extract to only look at latest load_id for patient populations",
                "Update APS loader for TPA APS files",
                "Update dbm loader for dx and tx opp",
                "Update mhp loader: add new tables to handle new Meridian membership formats and update mhp_elg_v"
            ]
        ]
    },
    {
        date: "2024-07-31",
        version: "18.19.0",
        updates: [
            "Additions",
            [
                "Add PCP/SCP type value for attribution and scp_attribution union when building patient_coverage; allows for prioritization of PCP attribution and re-sequencing of internal prioritization within attribution tables",
                "Add clinical results from biotech",
                "Add race and ethnicity to patient summary and measures",
                "Add new elgibility view for mclaren eligibility files",
                "Add elg and death functions",
                "Add new table and updating view and loader for updated AM Care Gap files",
                "Add new table and view for phr_claims and updating loader to process the phr file",
                "Add event summary table for cost/utilization reporting",
                "Add supplemental validation report to client access",
                "Add function to analyze/find any unmapped clinical results",
                "Add PHQ-2 Total Score to numerator compliant value sets for PHQ9",
                "Add new HFPN GM GIC stage table, update view, update loader",
                "Add new normalized Humana Quality Report stage table, update view, make regex for loader much more inclusive",
                "Add practice fusion stage table and function for inactive patients",
                "Add Meridian Membership layouts (Ambetter, MerdianComlpete, Merdian, Wellcare)",
                "Add loader for kona files and creating new tables and views",
                "Add Medicare ACO/MCR to default data",
                "Add description for TCM Tracker",
                "Add adt-mailer to etl_load to run on Saturday with other mailers (interface status, extracts, etc)",
                "Add hap dx opp loader",
                "Add mhp dx opp loader",
                "Add eCW to data coverage tab",
                "Add table and function for Cardiovascular Clinical Associates elg",
                "Add midwest cardiology loader",
                "Add hypertension nephrology patient list loader",
                "Add provider import model",
                "Add modifiers for procedure codes",
                "Add section descriptions within provider table",
                "Add sources to client access claim tables and update supplemental validation report table to only run if source table exists",
                "Add Disease Burden Management report to report libray",
                "Add EPIC loader to additional clients",
                "Add support for Molina layout (comma delimited)",
                "Add CMI column to patient measures list",
                "Add Race and Ethnicity filters to Generate Patient List, and rearrange the fields to fit",
                "Add patient search flags to site settings",
                "Add patient search options to filter panel",
                "Add Print New Letter button to Letters tab"
            ],
            "Fixes",
            [
                "Fix BCBS AWV incentive and BCBS TRC incentives",
                "Fix install order of Aetna views to allow drop/recreate",
                "Fix PH hcc member list load in ph module",
                "Fix athena snowflake ref and overhaul dx_opp procedure and warehouse table",
                "Fix scrolling in tab panes on Firefox",
                "Fix printing community linkages PDF",
                "Fix an incorrect reference to a filter options list on quality summary report",
                "Fix scrolling on report library",
                "Fix TCM dropdowns loading endlessly when there's an error",
                "Fix height of TOC dashboard for specialists",
                "Fix date format for care manager when adding patient"
            ],
            "Improvements",
            [
                "Include patients in SCP panels even if they are not already attributed to a PCP within the same network",
                "Use the latest load from eligibility for provider information",
                "Additionally limit tag access by primary practice",
                "Remove foreign key to address orphaned comments",
                "Differentiate between source_result_ids for sdoh and phq data to avoid conflict when loading within same package",
                "Prioritize the latest eligibility date when determing primary attribution when multiple eligibility records for the same patient, payer, and product",
                "Alter personnel resource mapping to align with provider roster in NPI lookup (improves first/last name match)",
                "Resolve missing diagnosis descriptions in claims detail",
                "Only include pcp or scp acrs practices in adt mailer",
                "Map additional loinc codes for KED Measure",
                "Make BCBS Host logo purple",
                "Remove logic allowing for non-discharged transitions from appearing when searching by patient name",
                "Expand header to include more patient demographics allowing for asynchronous file loads",
                "Improve column specificity and consistency across tables",
                "Remove not null constraint on provider for questionnaires",
                "Remove deprecated athena assets",
                "Clarify warning when patient audits pdf has too many columns",
                "Make QSR Met column a link to patient list",
                "Show scheduled TCMs by default, as well as active ones",
                "Allow fuzzy search validator to accept double quotes",
                "Properly format chart tooltip titles, values, and axis tick values",
                "Hide Remove TCM button when users who don't have write access"
            ],
            "Updates",
            [
                "Update gender case statement for eligibility to align with the new pro/fac claims version",
                "Update bcn mldr view",
                "Update eligibility_f to include namespaces",
                "Update mailer to include quick summary of last acrs file",
                "Update eligibility_f to include namespaces",
                "Update HAP SDF membership loader",
                "Update BCNA files to include ADHOC and CYTD (except MEM)",
                "Update logic for patient claim lookback to deal with filler claim end dates",
                "Update athena_snowflake to use a consolidated DV account",
                "Update RBCE loader to include MA files",
                "Update constraints so that no measure is in more than one subcategory",
                "Update transition function to prioritize records with a discharge date other than 9999-12-31 when competing values are received",
                "Update payer in mclaren eligibility view to match the name in default_data; adding mclaren commercial and medicaid to the products list",
                "Update DBM report",
                "Update functions for newest warehouse format",
                "Update referenced columns and pivot to a procedure-based encounter file",
                "Update elg function to accommodate variability in attribution and demographic files for each client",
                "Update BCS and COL mapping for BCBS EBCR in current measure engine",
                "Update ph dx opp to function and only include newer files (not AHA), include identified_date column"
            ]
        ]
    },
    {
        date: "2024-06-28",
        version: "18.18.0",
        updates: [
            "Additions",
            [
                "Add address column to patient list",
                "Add lazy loading for dashboard tabs",
                "Add client_access.patient_gen_condition table for reporting purposes",
                "Add new functions for Dockside",
                "Add ATHENA PPA record",
                "Add new user usage report to report library",
                "Add RBCE to client_access pro and fac claim",
                "Add New Patient tag",
                "Add appt providers to PCMH reports",
                "Add second numerator for new lead screening measure",
                "Add Health Education Impact report to the report library",
                "Add last and scheduled provider to pcmh reports",
                "Add MCT2D tag",
                "Add BCN MLDR loader",
                "Add bm1-bm4 validation criteria (age 2-18)",
                "Add file quote to loader and update view to load tx opp",
                "Add new table & updated view and moh loader due to MissedSvc file having an extra field added",
                "Add address to patient measures and summary",
                "Add wellness medicare to awv",
                "Add bcn hcc risk coding loader",
                "Add non-mihin adt loader"
            ],
            "Fixes",
            [
                "Fix batch printing and improve PrintAssessment",
                "Fix report library table sorting",
                "Fix patient history popover sizing; make it position properly after load",
                "Fix batch print error on very large batches",
                "Fix Snowflake retry loop to allow"
            ],
            "Improvements",
            [
                "Improved table readability via height and width adjustments for various screen dimensions",
                "Improved PoC titles",
                "Improve batch printing alerts",
                "Allow Edit User form to work even when LastPass browser plugin is enabled",
                "Improve patient searchbar performance",
                "Allow searching via patient ID",
                "Show all QSR filter options by default",
                "Filter out null lab dates",
                "Associate mihin_cks to membership",
                "Improved pcmh-asthma-copd query",
                "Improved batch error reporting",
                "Allow date parsing override for files found during SFTP sweep",
                "Assigning default report categories to all library reports",
                "Allow a bit more filename deviation for RC claim files",
                "Improve SDOH mailer to remove blank practices/providers",
                "Improve query performance for loading cclf_fac_claims"
            ],
            "Updates",
            [
                "Update cclf functions; add pom_cclf_truncate function to account for historical extracts with each monthly load",
                "Update PVP loader to use PY anchor date as last dx date",
                "Update HPV Tests value set name to High Risk HPV Lab Test",
                "Update MHP Treatment Opportunity view to force name matches against membership",
                "Update HE report to include appointment details and department",
                "Update package load_id handling",
                "Update BCBS Host color",
                "Halt loading host records to eligibility",
                "Update indexes for warehouse.eligibility",
                "Update spc value sets and include manual entries for received statin therapy",
                "Update load_eligibility procedure to properly identify implicit termination and record changes",
                "Update POC functions for performance",
                "Update new olympia loader and supporting files to load inactive patients, if requested",
                "Update statin value sets and spc/spd"
            ]
        ]
    },
    {
        date: "2024-05-31",
        version: "18.17.0",
        updates: [
            "Additions",
            [
                "Add patient audit filters",
                "Add pagination to patient audits",
                "Add module permissions to Patient Audits",
                "Add patient audits counter",
                "Add handler for failure or partial success with S3 uploads",
                "Add functionality for updating patient audit status",
                "Add a custom message to the notification when saving an audit status change",
                "Add categories to library report list",
                "Add ability to remove Point of Care comments",
                "Add separate module for PDF & CSV extracts",
                "Add spending chart module",
                "Add retinal screening mapping for EYN/EYP from AMG Athena (SE)",
                "Add ability to limit FilterPanel measures field to single select",
                "Add combined treatment opportunities for combo immunizations",
                "Add permission modules for patient audit tracker",
                "Add DockerHub login to pull images without unauthenticated service limits",
                "Add col/mam/ret loaders to npi",
                "Add loader, tables, and views for new appointment file layouts",
                "Add termed patient report",
                "Add table, update view, update loader for BCBS RBCE HCC Opportunity loader",
                "Add new loaders, and tables for PIP 011G, PIP 011H, and PIP 015B files",
                "Add new table, and updating loader to handle updated provider roster files",
                "Add second lead screening measure",
                "Add loader for new PH dx opp files and update view",
                "Add Spending Chart module",
                "Add BCBS-specific PTE value sets/codes to submit in SE"
            ],
            "Fixes",
            [
                "Fix to allow tabbing to and scrolling SelectCells",
                "Fix SelectCell to not allow two different Selects to be open at the same time",
                "Fix a caching issue with a patient list validator",
                "Fix all Reset Filters buttons to only appear when filters are not default",
                "Fix comment sort order",
                "Fix date format in BMS appointment view",
                "Fix pb physician loader",
                "Fix pa rev and header dataframes",
                "Remove duplicate entries that were added to payer_incentives",
                "Map missing products to product groups to improve treatment opportunity mapping"
            ],
            "Improvements",
            [
                "Ensure that focusing on TextInputCell puts the cursor at the end",
                "Adjust some wording on Risk Adjustment dashboard tab",
                "Change patient audits default sort",
                "Don't force superusers to use global scope on patient list",
                "Improve patient audit selector style to fit with counter buttons",
                "Rename Diag. Code column to just Code",
                "Reduce table overscan from 50 to 10 to improve performance",
                "Update audit status counts when a status changes",
                "Refactor patient list charts to display a little nicer",
                "Allow patient list to parse filters out of a URL",
                "Tweak filters and URL for care management",
                "Allow Care Management page to accept filters encoded in URL",
                "Create YouTrack issues when there are unique batch errors",
                "Have the tag engine to run before client_access to improve batch performance",
                "Improve ECW file matching and add new stage tables",
                "Remove products where payer not in payer",
                "Improve regex for source target mappings",
                "Include patient_amt in phr_claim_f function",
                "Allow for full patient name search in TCM tracker",
                "Remove deprecated prefix for smoking status"
            ],
            "Updates",
            [
                "Update CM dashboard Progress text display when unknown number needed for goal",
                "Update PVP loader and add stage table",
                "Update views to streamline ACRS transmissions",
                "Update CLCPF mapping",
                "Update BCN PDCM elg namespaces, report library membership report, and tag models to accommodate file updates",
                "Update the Humana loader to handle new Humana membership file layout",
                "Update incentive mapping for new BCBS HeB files",
                "Update interface status report function and add client table for lag override",
                "Update PIP 15B records report function",
                "Update get file date to compile ALGC months for file sort",
                "Update RBCE MLDR loader",
                "Update loader, tables, and view for updated cava claim data format",
                "Update Meridian loader to handle wellcare roster files",
                "Update treatment opportunities for 2024",
                "Update POM loader, diagnosis opp function, and stage tables",
                "Update 2024 cmcc config",
                "Update BCC loader for new treatment opportunities layout"
            ]
        ]
    },
    {
        date: "2024-04-30",
        version: "18.16.0",
        updates: [
            "Additions",
            [
                "Add an incremental MPI process",
                "Add new loader, tables, value sets, and views for Mansour SDOH data and PHQ9 data",
                "Add Practice column to TCM Tracker",
                "Add new patient report",
                "Add pdcm ckd/si patient tags",
                "Add BCC CM loader and report",
                "Add new loader, tables and views for Aetna Better Health",
                "Add audit schema installation",
                "Add SE, Athena mapping for Chlamydia Tests, 188078CERVIX/URETHRA",
                "Add engaged patient tag",
                "Add social history and questionnaires to NPI variation",
                "Add additional mappings for appointment status",
                "Add Dockside warehouse loaders",
                "Add mapping for new MR Attribution TIN variation",
                "Add a drop view statement to allow changes in view data types between batches",
                "Add regex for namespace split between SMPHO and MEMORIAL to allow for a combination of PIP_075 and APCD loaders",
                "Add positive/negative finding mapping to std_result",
                "Add 2024 treatment opportunities for Meridian and United",
                "Add mappings for GFR, ACR, NU, and CL",
                "Add mapping for eGFR and update value set for W30 measure",
                "Add MCT2D to installation",
                "Add 2024 Humana treatment opportunities",
                "Add ability to remove general conditions",
                "Add Practice column to TCM Tracker",
                "Add paging to library report requests",
                "Add NPI and Place of Service columns to utilization details",
                "Add Risk Dashboard product class field in site settings",
                "Add new enrollment layout for Aetna"
            ],
            "Fixes",
            [
                "Fix loaders failing due to ambiguous load_id on update statement",
                "Fix syntax error on snowflake; ensuring diagnosis code order is deterministic",
                "Fix fac claims in Measure Mart pulling in null revenue codes",
                "Replacing discharge dates of '0001-01-01 BC' with '9999-12-31'",
                "Fix install issue by including language in patient demographics",
                "Ensure that NPIs are coming in as text instead of bigint as Excel processing includes decimals",
                "Fix PDCM tag model",
                "Alter date columns to text type and add filter for possible bad data from PH",
                "Account for 'Invalid Member ID' across columns in some PH layouts",
                "Fix provider lookup in siu view",
                "Filter pip-011c to use valid patient date of bith (Invalid Member ID)",
                "Fix collation of provider IDs and names in utilization report filters",
                "Fix styling of dashboard table headers at small widths",
                "Fix button behaviour for DateEntry"
            ],
            "Improvements",
            [
                "Improve performance for refresh_fmc_risk",
                "Improve clinical mart build run time",
                "Split pro_claim_mart generation into multiple steps and removing unused worker tables",
                "Remove additional functions not being used in any loaders",
                "Consolidate all client_ccda clinical result functions into a single function",
                "Transition bcbs views to functions",
                "Allow existing PHR data to override site_contact config",
                "Remove test execution of exec_permissions",
                "Improve performance of f_get_user_tags",
                "Remove non-medicare from AWV measure",
                "Move Save Patient Measures snackbar back in front of sticky header",
                "Show 0 results instead of undefined on reports without rows",
                "Make campaign list table slightly larger so that you can see more campaigns and see that the page scrolls"
            ],
            "Updates",
            [
                "Update humana loader formatting",
                "Update athena snowflake diagnoses order to be deterministic",
                "Update index strategy for pro claim/detail",
                "Update custom mapping for Athena EED retinal eye exam",
                "update SDOH email formatting and content",
                "Update pdcm tag models",
                "Update non-enterprise socialhistory extracts to include primary provider details",
                "Update default provider for social history and questionnaires",
                "Update non-enterprise questionnaire extracts to include primary provider details",
                "Update moh module to evaluate file using (txt|csv)",
                "Update views and loaders to include new layout for patient demographics",
                "Update f_get_transitions_of_care to match API name filter changes",
                "Update treatment opportunity view to include namespace for each tin",
                "(Re-)updating ETL views and functions for United",
                "Update 2024 mapping table (BCBS)",
                "Update product type to be MCA when product is MEDICARE ADV HMO POS for HAP",
                "Update pdcm and landmark models since patient tag generated in generate_tag"
            ]
        ]
    },
    {
        date: "2024-03-29",
        version: "18.15.0",
        updates: [
            "Additions",
            [
                "Add comment column to f_get_patient_tags",
                "Add pat_in_grace_prd to f_get_patient_demog",
                "Add BCBS PDCM Membership Combined to Reports Library",
                "Add BCN PDCM Membership to Reports Library",
                "Add error message to log when no stage table found",
                "Add stage tables, views, and functions for Fox data",
                "Add client_access.historical_attribution",
                "Add Pending status to TCM Tracker",
                "Add new table and updating view for Aetna membership",
                "Add Mclaren and Rosewood loaders",
                "Add inline migration for phr.cmi_measures description",
                "Add index to master provider demog to improve performance",
                "Add grace period to point-of-care coverage info",
                "Add Comment column to point-of-care tags table",
                "Add a description section to CMI OutreachTile",
                "Add useful column-manipulating helper functions for the front end"
            ],
            "Fixes",
            [
                "Disable DROP OWNED BY statement called during the base installation to improve access control downstream",
                "Fix Location pointer within LOINC .zip file after recent change",
                "Correct procedure names and placement",
                "Fix display of payer logo in term45 state",
                "Fix broken Edit Tag button",
                "Show TCM comments on hover for any user who can see the TCM",
                "Correct columns when creating a campaign from the patient list"
            ],
            "Improvements",
            [
                "Ensure vaccinations are present in both scenarios (NPI/without)",
                "Tune log levels in all environments",
                "Improve logging to phr.application_log",
                "Tune metabase user access: restricting codes schema access to materialized views",
                "Overall logging improvements for batch runs",
                "Improve sort function for load_pro_claim",
                "Swap update_tcm_tracker to delta load for intraday batch runs",
                "Transition fdw Appointment into function to address slow query time in ETL",
                "Improve handling of login page fetch promises",
                "Remove unused togglePivot property on SortableList",
                "Verify auth every 5 minutes instead of 10",
                "Improve auth refresh logging"
            ],
            "Updates",
            [
                "Update std_result to better capture incomplete services",
                "Update client_access.provider, client_access.ed_patient_summary, client_access.appointment, client_access.attribution, and client_access.patient to improve Metabase reporting",
                "Update value set tables for new HEDIS version",
                "Update Aetna loader to handle Aetna membership files",
                "Update aetna eligibility view to align namespace, product, and end dates",
                "Update HAP loader for new MBEX file format",
                "Update eligibility loader to apply end dates (normally a day prior to the new file date)",
                "Update campaign, table, and table_settings tests"
            ]
        ]
    },
    {
        date: "2024-02-29",
        version: "18.14.0",
        updates: [
            "Additions",
            [
                "Add tag comments to POC tag badges",
                "Display grace period on Point of Care coverage info",
                "Add initial values for the campaign form columns",
                "Add tests for new tables and table_settings",
                "Add patient grace period flag to patient demographics",
                "Add BCBS PDCM Membership Combined to Reports Library",
                "Add comment column to f_get_patient_tags",
                "Add BCN PDCM Membership to Reports Library",
                "Add stage tables for Fox data",
                "Add package sort",
                "Add client_access tables for additional Metabase reporting",
                "Add Pending appointment status",
                "Add new table, update view, and update the loader for Aetna membership",
                "Add access to codes schema materialized views to metabase user",
                "Add index to master provider demog",
                "Add a description section to CMI OutreachTile",
                "Add a note to TableSettings for admin about columns",
                "Adding migration to populate codes.loinc with existing data"
            ],
            "Fixes",
            [
                "Repair broken Edit Tag button",
                "Correct columns when creating a campaign from the patient list",
                "Fix type in retore-ref calls"
            ],
            "Improvements",
            [
                "Logic updates for client logo display",
                "Make AutoLogout and AutoRefresh use worker-timer setInterval instead of the native one, which stops working when idle",
                "Transitioning update_tcm_tracker to do a delta load for intraday",
                "PHR event grouper now conditional on weekend runs",
                "UserStatusCell cell renderer should return null instead of undefined if empty",
                "Remove vestigial 'tableWidthElementId' functionality from tables",
                "Improve Campaign imports to handle data more reliably",
                "Improved wording in table settings and add a reset button",
                "Reconfigure logging for auth refreshes to improve diagnostic capabilities",
                "Improved base installation script permissions",
                "Ignore manual entry report for PCP_NPI lookup to prevents failure of downstream mart builds",
                "Improve stage table errors if targeted stage table isn't found",
                "Improved systemic application logging",
                "Improved McLaren loaders",
                "Transitioning fdw appointment into function to address slow query time in ETL",
                "Measure year updated to 2024"
            ],
            "Updates",
            [
                "Update RV to handle missing column and sort props better",
                "Updated campaign tests",
                "Update campaign asynchronous validation helper function and add associated tests",
                "Update table settings noFreeze logic",
                "Ensure vaccinations are present in with NPI and without NPI",
                "Update std_result to better capture incomplete services",
                "Update f_get_user_admin_dir; won't return a null row if no users or practices are defined",
                "Update value set tables to latest HEDIS version",
                "Update Aetna eligibility view to align namespace, product, and end dates.",
                "Update eligibility loader to apply end dates (normally a day prior to the new file date)",
                "Update HAP loader for the MBEX files, allow for variable headers"
            ]
        ]
    },
    {
        date: "2024-01-30",
        version: "18.13.0",
        updates: [
            "Additions",
            [
                "Add mapping for Athena FIT-DNA and Retinal Eye Exams",
                "Add stage table for rbce med_01 (commercial)",
                "Add stage table for rbce mem_01 (commercial)",
                "Add pro_claim view for rbce med_01 (commercial)",
                "Add new stage table for Molina treatment opportunities for new race and ethnicity columns",
                "Adding new table for nphc pro claims and updating the view and loader",
                "Add 2024 incentives for BCN, BCBS, and Priority Health",
                "Add cchc_siu module",
                "Add refresh_token table",
                "Add comments to tag.patient_tag, patient_tag fdw view, phr tables and etl",
                "Add default records for payer mapping",
                "Add eligibility loader (enable in module)",
                "Add metabase permissions for client access schemas",
                "Add index on refresh_token",
                "Add client_access.unmapped_attribution",
                "Add HOST product to display_product_group",
                "Add view for provider directory schema, adding export_table function to warehouse.py",
                "Create ph_pip_070_return_01 and ph_pip_070_return_02 to reflect structure of underlying tables",
                "Add refresh_unmapped_attibution function to refresh_all function",
                "Add claims comparison and access-summary",
                "Add regex_exc and regex_inc evaluation for source_target_delivery entries",
                "Add qualifier for regex columns",
                "Add diagnostic row_count",
                "Add OPNS loader and HABER file matches",
                "Add OPNS import",
                "Add eligibility view",
                "Add CCHC variation for BP",
                "Add patient_tag table and its population from stage.bcbs_landmark_elg_01 table",
                "Add frontend for CCHC batch print",
                "Add FQHC to batch print validator and file name generator",
                "Add missing validation to patient list appointment date filters",
                "Add a tooltip to TextInputCell so you can see the full text that you have entered"
            ],
            "Fixes",
            [
                "Assess full path when checking mirror directory",
                "Have wc_domains only run transfer_files_sftp regardless in certain configurations",
                "Adding ordered keyword to export_table, this forces 'select * from' COPY syntax and prevents 'COPY FROM view_name' from generating an error",
                "Memo-ize ReportPanel table factory to prevent infinite rerender loop",
                "Edit or remove useEffects without dependencies to improve performance",
                "Fix authentication error codes",
                "Fix next/last appointment columns to properly render in new format",
                "Fix validation for appt date filters",
                "Fix report library filtering on fields with fixed options",
                "Sync reauthentication across tabs, and attempt reauth if a request gets a 401"
            ],
            "Improvements",
            [
                "Increase precision of rate_to_date column denominator to be able to handle numbers longer than 7 digits",
                "Improved data coverage file name check for Tx opportunities",
                "Replacing entire base CTE within f_get_qsr_network_summ with CTE from practice/provider summary",
                "Type next/last appointment columns as timestamps",
                "Include race/ethnicity within standard extracts",
                "Improve MPI matching",
                "Expand client.source to include an se_override column to include source types other than EMR; default value is null, sources can set to include (true) or deny (false) regardless of origin data type",
                "Improve fdw views",
                "Ensure pluto user only limited during phr refresh itself",
                "Simplify logging",
                "Adding db_init.sh to batch to properly set environment variables each batch",
                "Set fallback AWV eligible date to start of anchor date year instead of current year",
                "Change the default timestamp string to not include timezones, as we no longer get those from the API",
                "Replace all basic authentication with token authentication",
                "Incremental style improvements",
                "Allow passing of initialViewDate into DateTimePicker",
                "Replace uses of start of current year with start of anchor year for care manangement",
                "Rename Labs tab in Utilization Details to Results/Vitals"
            ],
            "Updates",
            [
                "Update QSR dashboard functions to apply filters before calculating network rate",
                "Update parsers to handle not just empty files but files full of empty rows",
                "Update risk dashboard functions to apply filters before calculating network rate",
                "Update transition type to review facility type earlier in the case statement (SN).",
                "Remove deprecated public.refresh_attribution function",
                "Remove duplicates from High Cost Patient Report and Added Cost By Category",
                "Update permissions to grant usage and select on client_access_2 for metabase",
                "Update cchc_appointment_v to use the TRIMED source to allow for updating appointments between siu and appointment loads",
                "Disable cchc appointment loader (defer to SIU)",
                "Update f_get_qsr_practice_summ and f_get_qsr_provider_summ to filter on input measure_ids",
                "Update f_get_qsr_network_summ to be limited to active users/providers; updating for consistency with practice/provider summary",
                "Update PH module to interpret TML XLSX",
                "Update CM report library entries; they were not being reinstalled each night and based upon the current year rather than the anchor date",
                "Remove PHR step to sync provider directory; related but not finalized",
                "Update value sets to include missing mammography services",
                "Update refresh_member_best_demog to remove fake phone numbers",
                "Update refresh_member_best_demog to remove fake phone numbers",
                "Update views for QI loader",
                "Update CCHC TRIMED mappings for cholesterol",
                "Dependency updates and testing"
            ]
        ]
    },
    {
        date: "2023-12-30",
        version: "18.12.0",
        updates: [
            "Additions",
            [
                "Add appointment_note to phr",
                "Add Answer etl module",
                "Add logging to identify target xlsx tab builds",
                "Add appointment_note column to patient list and awv",
                "Add new Humana Treatment Opportunity mapping",
                "Add new appointment_npi & customdemog_npi variation",
                "Add new ACP measure value set",
                "Add stage table and edw view for appointments (awv tracker)",
                "Add pg_stat_statements",
                "Add proclaim, questionnaire, socialhistory, vaccination and vitals Athena extract queries for NPI-based filters",
                "Add assignment for file_month for bcn med adherence records",
                "Add table_description table for table descriptions",
                "Add pg_advisory_xact_lock() to prevent tuple concurrently updated errors when granting permissions",
                "Add pg_stat_statements to production configuration",
                "Add 'Transitional Care Management Services' value set to Office Visit services in tcm_measure",
                "Add elg view for Dockside",
                "Add proper file_month identification for BCN MCG files",
                "Add isGlobal parameter to all patient list functions",
                "Add BCBS data parsing and Med-Adherence tag update",
                "Add kidney disease and serious illness reports",
                "Add staging loaders for serious illness and ckd rbce reports",
                "Add default arguments to phr functions and DISTINCT ON for patient tag",
                "Add provider_directory as owner of each table, not just provider_directory schema",
                "Add measure_incentive_payer_ids column to patient_measures",
                "Add new ecw_inactive_07 table to handle latest file format",
                "Add appointment note column to awv and patient list",
                "Add ability to specify a different component for TableDescription",
                "Add table Description to all RV Tables",
                "Add TableDescriptions to the user admin React-Table tables",
                "Add incentive payers column to patient measures"
            ],
            "Fixes",
            [
                "Adding client_provider_directory_users to NOT get dropped when permissions are run",
                "Thirlby appointment fixes",
                "Ensuring DISTINCT ON order for tcm_tracker",
                "Remove Appointment view to allow for column type change",
                "Thirlby  file_load view fixes",
                "Moving refresh of care_plan_incentive_diff so that Incentive Reconciliation column doesn't show zero",
                "Thirlby appt view gender fixes",
                "Show alerts only once",
                "Correct field name for new patient birth sex",
                "Don't show empty datepicker box when scrolling with breakOut datepicker open",
                "Measure History datepicker will break out when selected",
                "Fix MeasureHistoryCell; incorrect placement of a data selector was causing it to treat offerHistory as always true"
            ],
            "Improvements",
            [
                "Ensuring 1:1 joins on TCM Audit report SQL",
                "Read Gravity SDOH zip file from S3",
                "Correct appointment update process to evaluate the proper number of columns",
                "Include target-specific dimension installation for src schema",
                "Avoid deleting entries in patient_tag when running other models",
                "Include submeasures in f_getMeasureValueHistoryDetails",
                "Include appointment view in healthfocus module",
                "Compare HEB and Membership files using an upper modification of the patient's first name",
                "Limiting phr user permissions",
                "Removing read access from warehouse schema for pluto user",
                "Removing fdw grant_ fucntions that seem to be duplicative of public schema functions and never called",
                "Consolidating permissions scripts",
                "Remove duplicate stage reference",
                "Remove phr and refining permissions",
                "Making test_batch.sh easier to run locally",
                "Removing top level comments from phr etl; duplicative and not updated",
                "Removing duplicative drop_load_id function from public",
                "Fill in a few table descriptions that already existed on the frontend",
                "Change id association to look at array inclusion instead of a direct match",
                "Adding join to member birthdate to remove duplication when a single contract number is associated with multiple patients",
                "Allow table column width to be specified as a function",
                "Improve styling of table column selectors to prevent overflow",
                "Change patient list service date column header rather than just tooltip, based on whether measure filter is active",
                "Improve Edit Filters button padding",
                "Improve order of dynamic date filter options",
                "Refactoring CostDashboard and QSRDashboard",
                "Imrpove table description CSS class naming",
                "Refactor PatientCostTable",
                "Remove unused imports",
                "Improve AWV mapping"
            ],
            "Updates",
            [
                "Removing mapping of G0009->Influenza from supplemental data exchange",
                "Modify awv to review appointment note",
                "Modify medicare awv tracker to include appointment note",
                "Update tag engine to ignore entries with a null model (assumed to build during installation)",
                "Update athena snowflake module to use the NPI match, but apply it to the primaryprovider field instead of navigating through customdemographics",
                "Modify query for provider; athena uses different names for npi between referring and standard provider tables",
                "Only look back 1 year for custom demographics",
                "Update appointment_note and adding DROP statement for allscripts_pro_appointment_v",
                "Update postgresql.conf to enable more logging of connections and queries",
                "Update custom value sets for CCHC, add new PVP layout for GLPO, include appointment notes in CCHC SIU",
                "Update appointment loads and add appointment index",
                "Update date grouping",
                "Update SDOH to set screening practice OID and name based off of provider NPI if screening practice undetermined by source name",
                "Updating standard healthjump model to use the source client_id as our source identifier",
                "Extract patient list table data calculation to a selector to prevent input lag in patient list filters"
            ]
        ]
    },
    {
        date: "2023-11-30",
        version: "18.11.0",
        updates: [
            "Additions",
            [
                "Add mapping for Group A Strep Test",
                "Add MLDR processing",
                "Add an 'active' boolean to tcm_conclusion to allow for disabling certain entries",
                "Add United Coverage for membership and opportunities",
                "Add stage.bcbs_rbce_mldr_01 for RBCE MLDR files",
                "Add f_get_measure_mart_entry() so we can get provider data for measure log events",
                "Add treatment opportunity view for mldr and enable module load",
                "Add telephone column to AWV table",
                "Add table and mapping for custom extracts from the humana compass file",
                "Add membership view",
                "Add weekly membership stage table for meridian",
                "Add compass eligibility view",
                "Adding constraint name to tcm_conclusion ON CONFLICT",
                "Add installation for SCP ADT report library",
                "Adding functions for healthjump appointments and clinical results",
                "Add new columns to QSR and risk dashboard practice and provider functions to put them on par with the network functions",
                "Adding VMD CCLF Pharmacy view",
                "Adding mappings to missing measure_ids frmo measure_map_treatment_opportunity",
                "Adding new stage table for VMDACO CCLF pharmacy data",
                "Adding all ETL functions for standard healthjump layout",
                "Adding SDOH Billing Report to PHR Report Library",
                "Add redoc RAF columns to AWV",
                "Add category column to f_get_awv_worklist",
                "Add build procedure for cam report",
                "Add support for imasem appointments",
                "Add treatment opportuity records for UHC from OPNS",
                "Add measure_user_guide default values",
                "Add cam extract module",
                "Add subscriber_id for UHC",
                "Adding last_update_dt and last_user_id to track manually entered data into AWV tracker",
                "Add client table for source_target_delivery",
                "Add a utility function for displaying a function as a percent for AWV closure rate",
                "Add new AWV redoc remaining columns, and refactor data getters and cell renderers",
                "Add closure rate to AWV",
                "Add result count to AWV footer"
            ],
            "Fixes",
            [
                "Moving the FIT-DNA measure into the case statement that just returns Y",
                "HealthJump: Adding file identification; Removing NOT NULL constraints from stage tables",
                "Correcting ADD measure_id",
                "Don't apply default bounds to TCM dates when filtering on patient name",
                "Removing DISTINCT ON for _patients event-grouper query",
                "Preset default source_id to prevent constraint error",
                "Correcting NULL medicare IDs in Meridian treatment opportunities",
                "Correcting for duplicate first/last naming in Molina treatment opportunity files",
                "f_get_external_patient_match was missing from phr install file",
                "Adding new tables, views and updating loaders to handle j00bn, pg002, and rbce029 med adherence and drug adherence files",
                "Correct column names for QSR dashboard bar chart columns",
                "Make eligible date never earlier than start of current year",
                "Rework edit_campaign modal to avoid a circular reference",
                "Fix styling of ineligible counter bar"
            ],
            "Improvements",
            [
                "Removing insert from migrations to prevent FK constraint error on new install",
                "Get last AWV values from measure_value instead of measure_value_log to make it harder to get old overwritten values",
                "Header includes embedded newlines, skip entirely and set file_header to False",
                "Moving PHR migrations to run after create table scripts",
                "Replace boolean awv measure_met column with char measure_compliance column",
                "Support for multiple sheets in an excel file",
                "NPI and Provider name columns switched in SDOHv3 output",
                "Implementing EXISTS check on patients to ensure no constraint violation",
                "Removing old load_opportunity now that load_tx_opp is being used",
                "Disabling Report Libary AWV Worklist",
                "Changing PHR constraint generation, limiting to single constraints file to be imported within 90-finalize",
                "Moving AWV worklist to install as traditional phr module and etl",
                "Modifying med adherence views to get columns we need to create a med adherence tag",
                "Removing DROP/CREATE TABLE for medicare_awv_worklist, replacing with INSERT...ON CONSTRAINT",
                "f_get_user_admin_dir should work for inactive users too",
                "In f_get_user_admin_dir, don't replace inactive practice IDs with 0s; instead, return the practice status",
                "Remove f_get_practice_users as it's now unused",
                "Update internal lab stage table and view, and update cchc module",
                "Setting SECURITY DEFINER for all patient detail functionality; phr user doesn't need direct warehouse access for these queries",
                "Add hovertext on QSR dashboard columns",
                "Rework survey measure calculations so we can specify that a survey shouldn't display its numeric result",
                "Link redoc columns to Risk module",
                "Better pagination for the AWV",
                "Clear DateEntry errors when changed or unmounted",
                "Minor style tweaks for DateEntry buttons",
                "Display a tooltip on DateEntry when there's an error, and allow errors to be cleared properly",
                "Store TCM errors with their messages",
                "Prevent deletion of non-manually-entered AWV last service dates",
                "Rename AWV category UNMET to INELIGIBLE",
                "Mark ineligible cells with light grey text instead of blank cells",
                "Rework AWV categories, and use values returned from backend to update counts",
                "Don't update AWV fetcher unnecessarily",
                "Change how we update AWV data and counts now that they're two different requests",
                "Tweak AWV Eligible Date cell renderer to explain the styling for eligible dates in the future"
            ],
            "Updates",
            [
                "Updating VSD to resolve local errors",
                "Updated loader to handle new PO Partners Quality Report 2023 file format",
                "Update treatment opportunity loader to evaluate NEW opportunities within the context of a namespace",
                "Updating treatment opportunities for Molina",
                "Updating treatment opportunities and adding payer incentives for Meridian Medicare",
                "Update file_month assignment for MLDR",
                "Update mv_user_patient_dir to include specialty relationships",
                "Update rbce rx loader to use date_of_service instead of fill_date",
                "Updating MHP treatment opportunity view to pull namespace from source files's product",
                "Get CDC version of race/ethnicity via std_cdc_* functions; update cfc to include eligibiltiy",
                "Update std_race/ethnicity and include cfc tables/views/functions for membership and professional claims",
                "Update treatment opportunity loader to evaluate NEW opportunities within the context of a namespace",
                "Update f_get_user_admin_dir to put users whose primary practice is deactivated into the 0 practice instead",
                "Update functions to security definer when referencing external schemas",
                "Update the User Guide link to point at guide.healthfocus.io",
                "Update AWV counter styling",
                "Remove compliance, scheduled, and eligible filters from AWV, and rearrange remaining filters"
            ]
        ]
    },
    {
        date: "2023-10-30",
        version: "18.10.0",
        updates: [
            "Additions",
            [
                "Add CSV functionality to AWV worklist",
                "Add another validator to Last AWV Date column, so it can't be before the previous last value",
                "Add DIRECTORY module, and use it to hide provider directory",
                "Add separate buttons for downloading XML and HTML CDAs",
                "Add CCDA XSL files to public directory",
                "Add CCDA module, and don't show CCDA downloads without it",
                "Add measure history column to AWV and patient measures tables",
                "Add HCC Redoc columns",
                "Include OB in visit type for priority attention",
                "Add match_source_file for gaps in care report",
                "Adding new table for processing new 011C file layout, also updating the view and the ph loader.",
                "Add AWV module",
                "Add Cologuard mapping",
                "Add mansour module",
                "Add general mapping of 'HbA1c Lab Test' for observations with a standardized description of 'HEMOGLOBIN A1C'",
                "Add hg/wg and fall risk",
                "Add Edinburg Postnatal Depression Scale Result (EPDS) for Athena mapping code EPDS",
                "Add SOCIAL NEEDS QUESTIONNAIRE code for SNA mapping",
                "Add new tables and views for reclmexp files; adding new method to load the reclmexp files using pandas to_sql method to get around files not being in UTF-8 format",
                "Add mapping for CCHC HBA1C",
                "Add SDOH practice OID when source can be matched to practice",
                "Add loader for RBCE QualProsp (EBCR) treatment opportunities",
                "Adding missing measure_map_treatment_opportunity entry 'Statin Use in persons with Diabetes' for Priority Health",
                "Add general condition for Pre-diabetes",
                "Add loader for CFC (Community Family Care IPA)",
                "Adding new custom value sets to codes entry for FIT-DNA",
                "Adding measure_map_treatment_opportunity records for Meridian Medicare",
                "Adding new table, updating view and updating loader for MHP for Medicare Member Roster",
                "Add MLDR processing",
                "Adding dimension_type and dimension_id and changing constraints to allow facility claims to be loaded to transition table"
            ],
            "Fixes",
            [
                "Properly store AWV filters and include them in request",
                "Fix DateEntry and date validators yet again to make them work with arbitrary date formats",
                "Fix an error that comes up when a patient has no HCC conditions",
                "Fix lazy loading on patient list",
                "Fix a crash on the POC Community Linkages tab",
                "Fix date validation on TCM So you can enter dates on the same day as the discharge",
                "Fix vertical alignment of DatePickers with breakOut",
                "Fix pagination on Care Management patients list",
                "Fix vertical spacing between dashboard items",
                "Fix AWV flag filters",
                "Fix Logic for mv_user_patient_dir not using mv_user_prov_dir.can_view_panel",
                "Correcting a typo; some dates are interpreted as text, so adding quotes around them",
                "Fix: GLOSC PVP module should disable auto-term",
                "Update PH phr_claim_v to remove N/A as a source claim id; this was causing a cartesian update process and general etl failure",
                "Correcting join in Wellcentive appointment view that was creating duplicate records",
                "Updating batch to exclude PHR from initial install and only install during PHR batch; this prevents PHR tables from being wiped if a batch errors out early",
                "Fix BCBS Blood Pressure code change not being reflected on HeB Entry reports",
                "Moving API container stop to edw_batch main script so not run during test_batch.sh",
                "Fix gender and appt status",
                "Fix source_appointment_id",
                "Adding missing PIP measures and unioning in the data from the stage.ph_pip_070_02 table",
                "Humana RECLMEXP loads weren't being passed a load_id so src loaders weren't running",
                "Correcting view logic for humana claims"
            ],
            "Improvements",
            [
                "ButtonGroup's onClick handlers should trigger on mouse down, because onClick doesn't work right when clicking over from an input",
                "Rename tracker nav items to not be acronyms",
                "Send costSpan as a string (to be parsed as an enum) instead of a boolean, to maintain compatibility",
                "Standardize AWV titles",
                "Rework AWV sagas to account for the different endpoints for different columns",
                "Don't send sort functions in print and export requests. only sort keys",
                "Hide the Trackers dropdown if both TCM and AWV aren't visible",
                "Cache tiered sort groupings to improve table performance",
                "Open CCDA HTML in new tab instead of downloading",
                "Replace all remaining AWV boolean column cell renderers with checkmarks",
                "Empty AWV worklist patient costs should be 0 instead of null",
                "Change BCC logo color to better differentiate",
                "Changing SDOH report to better count distinct services",
                "Disable BCN Facets risk view due to poor performance"
            ],
            "Updates",
            [
                "Limit access to AWV with AWV module",
                "Make dashboard TOC display differently when directory or tabs is aren't visibile",
                "Update CDA download icons to be more distinguishable",
                "Refine the Content-Security-Policy headers for CCDAs",
                "Replace AWV measureMet column with measureCompliance, and update cell renderer",
                "Update patient_class mapping based upon relationship with patient_type",
                "Update CCHC BI file integration",
                "Update incentive reconciliation build to include cases that are eligible for submission but have not yet occurred",
                "Updating fdw.appointment to user pro_claim",
                "Changing filtering for fields in TCM",
                "Update to include 'Intravascular' systolic and diastolic values",
                "Update athena_snowflake.py module to align col/mam extracts with existing names to avoid rebuilds each day and add stubs for col/mam enterprise extracts",
                "Update regex for RBCE claims",
                "Updating pneumonia vaccine measure (184) to code_domain 2 instead of 4, in line with all other vaccination measures",
                "Apply a namespace to PIP treatment opportunities",
                "Update transition loader to prioritize OB over ED",
                "Updating loader and table and view to handle latest Humana treatment opportunity files format",
                "Updating the value sets to make sure they match what we are expecting from measure_engine.measure_mart",
                "Update MLDR delimiter (xlsx->csv)",
                "Updating tcm_tracker to ensure most recent data entered is retained",
                "Updating treatment opportunities for Molina",
                "Updating TCM-related intraday ETL to do ON CONFLICT, removing delta scripts"
            ]
        ]
    },
    {
        date: "2023-09-29",
        version: "18.9.0",
        updates: [
            "Additions",
            [
                "AWV columns set up, rendered, sized, and subtitled",
                "Add Deceased filter to TOC/TCM",
                "Add functionality for saving AWV dates using DateEntry",
                "Include patient language in demographics",
                "Add new loader for HAP Treatment Opportunities using new file layout",
                "Add new loader for BCBS Med Adherence",
                "Add new loader for Ascension Complete membership and Treatment Opportunities",
                "Add new view for bcbs_med_adherence to be used for the new med adherence tag",
                "Add new tables/load criteria for new tenant",
                "Add AHA treatment opportunities and incentives for 2023 for Priority Health",
                "Add UA CHLAMYDIA DNA AMPL to custom_value_sets_to_codes",
                "Add some ID columns to AWV worklist table and rename columns to standard template",
                "Add Athena codes for SNA questions",
                "Add a demographic view to include genders on all wellcentive ETL views",
                "Add loader and custom value sets for phressia SDOH extract",
                "Add new loader for rbce dx opportunities",
                "Add encounter-based transition_Type logic to tcm tracker",
                "Add provider NPI for NPHC, Tapac, Petoskey"
            ],
            "Fixes",
            [
                "Position datepicker dropdown correctly when breakOut",
                "Fix error message on TOC date validators, and add some notes",
                "Fix procedure code type and source claim line and quantity",
                "Updating file match regex for United to allow for misspellings of the word 'Opportunities'",
                "Addressing performance issues in f_get_transitions_of_care; inlining what was a CTE shortens query time",
                "Alter month to use re.search in an effort to avoid starting position issues",
                "Adding loop to guarantee Metabase ownership of all Metabase tables",
                "Fixing PK issue in measure_map_treatment_opportunity"
            ],
            "Improvements",
            [
                "Include frozen columns in report library export displayed columns",
                "Add separate input for batch printing Priority Attention section",
                "Moved AWV & TCM menu items to a dropdown",
                "Optimize RV to reduce rerenders",
                "Limit TCM date entry values to 30 days after discharge",
                "Add DOB to reminder letter"
            ],
            "Updates",
            [
                "Update Yarn to latest and remove old versions where needed",
                "Meridian now sends 2 header rows; adjusted xlsx reader",
                "United now sending 'male' and 'female' instead of just 'm' and 'f' - Updating view to take first character",
                "Updating meridian loader to reformat Excel files before converting to .csv, to guarantee a specific set of columns and ordering",
                "Moving creation of MHP treatment opportunity view due to dependency on membership view",
                "Removing duplicate entries in payer_incentive_rule and payer_incentive",
                "Update mapping for CCHC labs detail file",
                "Update to measure_goal to count payer+product+patient to prevent payer data from multiple sources counting as multiple contracts",
                "Modifying the med_adherence view, adding new custom values sets to codes, and creating a new med_adherence tag"
            ]
        ]
    },
    {
        date: "2023-08-31",
        version: "18.8.0",
        updates: [
            "Additions",
            [
                "Add ImageFallback component",
                "Add CCHC loaders",
                "Add mapping for MA MAMM 3d SCREENING BIL as description",
                "Add QI Epic Interface",
                "Add new Humana loader",
                "Add provider_id and measure_id to f_get_qsr_chart",
                "Add gender to nphc appt",
                "Add PHR side to link transitions with transition CDA",
                "Add a loader for the MiHIN CCDA files",
                "Add install for col/mam views",
                "Add next appointment type info columns to patient_demog",
                "Additional incentive mappings for HAP",
                "Add deceased filter to tcm functions",
                "Add cost, last appointment, and grace period filters to patient list",
                "Add a Product Group pivot column to QSR",
                "Add product group filter to QSR, QSR dashboard forms, patient list, and QSR -> patient list links",
                "Add admitStatusDesc filters and columns to TOC and TCM",
                "Add appointment filters to patient list filters",
                "Add report descriptions to ReportPanel and ReportListRow",
                "Add custom mapping for mammogram along with COL/MAM value sets",
                "Add next appointment data fields to pm_stage and patient_measures",
                "Add CCDA column to TCM, and allow downloading of the file from S3"
            ],
            "Fixes",
            [
                "Correcting HAP incentive goal rates",
                "Fix crash when setting Care Management patient list filters",
                "Fix Care Managers filter in report library",
                "Added missing product_group_name to new payer_incentive record"
            ],
            "Improvements",
            [
                "Update value sets to improve Athena service mapping",
                "Match first 8 digits of date for search",
                "Combine cost max and min filters into a single display",
                "Allow display of custom images in At Risk column, falling back to red exclamation if image is missing",
                "Rework findAndUpdate to work more intuitively",
                "Add namespace to bcbs_treatment_opportunities_v, bcbs_ma_treatment_opportunities_v",
                "Always return cost 0 instead of null for patients without costs in patient_measures or patient_summary",
                "Adding DISTINCT counts to the SDOH extract mailer",
                "Renaming 'Source' to 'Payer' on CM Billing Report",
                "Updating provider names to be 'last,first'",
                "Fall back to providers' practices before falling back to active user's practice",
                "Ensure providers only show in one practice each in the directory",
                "Tweak button styles for better consistency",
                "Update QSR chart to include a separate line for each practice and provider",
                "Change style of Add Favorite button to be more distinguishable from Save Filters"
            ],
            "Updates",
            [
                "Expand Aetna Eligibility",
                "Removing self.date_override addition to etldriver",
                "Changed logic to get all flag descriptions for a patient when a specific risk_type is filtered",
                "Update aetna loaders to add namespace and handle medicare eligibility variation",
                "Updating patient_claim to aggregate pro & facility claims separately for utilization detail tab",
                "Update view to improve identification of unique appointments",
                "Update attribution to allow multiple coverages from a single source over different products",
                "Updated measure_map_treatment_opportunities for Humana, making sure they had a payer_incentive record for each",
                "Changing eligibility loader to use APCDElig file instead of PIP_075 file",
                "Update code_system from TPA clinical result loader to evaluate the code and insert a LOINC refrence prior to assigning the source name",
                "add normal_low/high to view and update COL target",
                "Multiple userguide updates"
            ]
        ]
    },
    {
        date: "2023-07-28",
        version: "18.7.0",
        updates: [
            "Additions",
            [
                "Include provider degree",
                "Add mv_user_source_dir, which specifices the coverage dropdown elements to display for filters",
                "Enable Fac. claim load for BCN MCG",
                "Add source_names column to patient_demog and use it in Patient List, TOC, and TCM Tracker",
                "Add steps to EDW batch to enable/disable logging of long-running statements",
                "Adding phr.patient_cost_summary to install so it can be referenced within other objects",
                "Add subcategory mapping for BCN PDCM (POC display)",
                "Add patient id to roster to support dependency for scheduling",
                "Add admit/discharge date indexes to mv_transition_opf_care",
                "Add entries for the Henry Ford Payers",
                "Add value_set mapping for In-office A1c",
                "Add filters to ensure valid upstream data for DOB and service date",
                "Add new stage tables for MHP Medicaid files",
                "Add ascension_complete_attr as a stage base for Data Coverage - Membership",
                "Add loader for Meridian Medicaid Member Roster file",
                "Add age filter for PA/NU services",
                "Add the patient_id field back into the PCMH reports; new field Patient ID remains in the reports",
                "Add hap_claim_03 to professional claim view",
                "Add namespace to treatment opportunity",
                "Add United Medicare incentives",
                "Add check on user_event.info to ensure that the value is numeric",
                "Add mapping to provider table when an npi isn't used as the primary identifier for providers",
                "Add care management patient list",
                "Add lock icon to private reports in list",
                "Add ColorPicker input type",
                "Add ability to keep table columns forcibly selected and apply it on the QSR",
                "Add DateTimePicker component",
                "Add product class column to TOC and TCM",
                "New Alerts page for superusers, with a table of active alerts and the ability to delete them",
                "Add Other Coverage filter to TOC and TCM",
                "Add a redirect from /care-management to /patients if no measure ID was specified",
                "Add population filter to default CM filters",
                "Add a standardized page-header class"
            ],
            "Fixes",
            [
                "Grant pluto edit access to alerts table",
                "Fixing error caused from adding a new section and not including the new section in the following clause",
                "Accurately represent the number of custom service codes that are ELIGIBLE for extraction, instead of the total number identified",
                "Fix DateTimePicker crash on add alert modal",
                "Fix patient measures sometimes getting patient summary columns on load",
                "Fix various bugs with alerts modal fields",
                "Fix display of Incentive Status column",
                "Fix error on submitting new alert (date was converted incorrectly)",
                "Fix crash when values were entered for alert message and color at the same time",
                "Remove an incorrect value being passed for sourceNames",
                "Properly map QSR dashboard rows to appropriate patient list filters"
            ],
            "Improvements",
            [
                "Expand Medicare report library",
                "Making risk visible for network, provider, and practice cost utilization report on UI",
                "Sort payer and source arrays by patient_demog.sort or patient_coverage.priority, as appropriate",
                "Add sort orders to coverage windows",
                "Add product_class column to TOC and TCM",
                "Rename record summary to service summary",
                "Expand value set mapping for Parknet",
                "Include extended care management codes in history",
                "Provide a more informative message when a table has no columns selected",
                "Make boolean filters in reports library single-select, fixing the incorrect display of their values in the filter header",
                "Improve display of Payer cells by supplying idField column props but not making the cell blank out if it's missing",
                "Merge patient list Products column into Coverage",
                "Make care management filter footer and Edit Filters form work from care management data instead of patient list",
                "Rework how tooltip colors are set, for ease of use and improved accessibility"
            ],
            "Updates",
            [
                "Updating warehouse dependency versions",
                "Drop afp views on install to prevent migration issues",
                "Updating payer target percentages based on HPHO spreadsheet",
                "Mapping DM Eye Exam NMMP",
                "Update AFP roster view, stage table, and glosc module to handle new file layout",
                "Materializing cost data and changing user panel joins for phr.patient_measures",
                "Removing redundant STABLE LANGUAGE from f_Get_patient_measures_from_campaign",
                "Remove unique constraint on payer_id and product_id",
                "Use materialized payer, product, and source arrays in patient_summary",
                "Update pro claim loader to prevent duplicative queries simply to outline counts",
                "Setting a maximum length for CCDA diagnoses",
                "Update to allow Healthjump to refresh the materialized demographic view",
                "Alter metabase connection limit to 50",
                "Update default_data product mappings to include United Healthcare",
                "Updating column order for source and source_type columns and changed pateint_id to be Patient ID",
                "Removed 'FMC' from the risk_type description to not confuse with the measure itself",
                "Update column names and incorporate additional details around PCP vs rendering provider",
                "Take the most severe transition type regardless of how the episode is referenced retrospectively",
                "Disable implicit termination for Aprima ACRS",
                "Update care management table footer",
                "Replace icons and favicon",
                "Update TOC and TCM to show all coverages",
                "Remove product abbreviations from Coverage column",
                'Change label on "Payer" columns to "Coverage"',
                "Differentiate care management print and export",
                "Update care management product graph and cost chart"
            ]
        ]
    },
    {
        date: "2023-06-30",
        version: "18.6.0",
        updates: [
            "Additions",
            [
                "New loader for memorial sch",
                "Adding warehouse eligibility loader for ascension complete",
                "Add mapping for ACD/FMM in standard SE model",
                "Add functional status assessment mapping for Athena",
                "Add mapping for bilateral mammogram",
                "Add FMC mammography mapping (LOINC w/description)",
                "Add loader for HPHO medical conditions",
                "Add mapping for ACD/FMM in standard se model. Add functional status assessment mapping for Athena",
                "Adding BCN PDCM measures to measure_value_sets_map",
                "Updating all QTY_OPERAND values to 1 for the '1 ENC' CMCC measures",
                "Add loader for GLPO GLMC roster file",
                "Add missing treatment opportunities for United and Ascension",
                "Install x12 schema",
                "Add EBCR treatment opportunity view and update load_stat_mailer to provide additional detail about the type of HL7 or CCDA loaded",
                "Add DATA_COVERAGE to the list of modules that can keep the dashboard visible",
                "Add new United Membership table and updating view for eligibility",
                "Add foundation for LACE risk score calculation; include function variation for delta processing to consolidate with intraday batch (ADT)",
                "Add LACE risk_type",
                "Add mappings for JVHL/CCDA",
                "Adding new inactive patient loader",
                "Adding missing relationship join to refresh_attribution"
            ],
            "Fixes",
            [
                "Set default role_ids correctly when there are multiple roles with the same access level",
                "Updating Agilon extract to pull from all data instead of most recent load",
                "Fix users showing up as Not Licensed when they aren't providers",
                "Fix errors when defining f_get_patient_measures and f_get_patient_summaries because patient_cost_summary isn't defined yet",
                "Fix improper count location for implicit termination",
                "Add DESC sort order on date for fdw.appointment; was not picking the most recent claim date when supplementing appointments",
                "Prevent crash when no user roles received from authentication",
                "Fix the payer/source logo conflict in the Payer cell renderer, not just Payers",
                "Fix table column header hover text not displaying"
            ],
            "Improvements",
            [
                "Shortening priority attention lookback to 3 months",
                "Updating f_get_cm_outreach_dts to reference phr.patient_measures to make patient counts consistent with patient lists",
                "Restricting Agilon access to LFM source",
                "Limit of YTD details for testing case",
                "Require result for cologuard",
                "New filter logic to allow for single column evaluation",
                "Add HFPN SE model",
                "Add install reference for HFPN",
                "Changing install order to prevent dependency errors on drop/recreate",
                "Changing order of PDCM dashboard measures",
                "Remove unused startMonthId and endMonthId from patient list requests",
                "Rework module membership calculations to correctly rely on role IDs instead of authentication roles",
                "Allow frozen columns back into tiered tables",
                "Update Payers column to show all payers, not just primary ones",
                "Add alt values for payer icons",
                "Reset color for LACE to purple"
            ],
            "Updates",
            [
                "Updating ON CONFLICT statement in CMCC install to update measure_map",
                "Updating agilon extract to pull from all data instead of most recent load",
                "Updating references to user.access_level in reports.sql",
                "Updating BCC CM measure to require 2 codes, as configured within the ME spec",
                "Updating staging table for United Treatment Opportunities",
                "Update BCN regex for med file",
                "Update superuser role defaults",
                "Setting BCN PDCM incentive thresholds to zero",
                "Update attribution build to evaluate days_deceased interval against the effective date of a deceased record (eff = deceased date)",
                "Remove care manager columns from patient_measures and patient_summary",
                "Set Agilon appointment lookback to 30 days",
                "Changing measure_id that is wrong for Child and Adolscent Well-Care Visits for United",
                "Updated fields to SDOH output for 2023 changes. Removing implicit PCP as service provider now that the service provider field is output to MiHIN",
                "Updating payer logo for PHP",
                "Update treatment opportunity loader to use namespace logic with implicit termination",
                "Remove duplicate reference to incentivized column",
                "Rename Payer column to Coverage and add a Coverage filter to complement the Payer one",
                "Remove reference to materialized_views.sql and functions.sql as no longer necessary",
                "Assume HCPCS when not otherwise indicated for procedure code type",
                "Update fdw.patient_mrn to use two different expressions when evaluating EMR MRNs"
            ]
        ]
    },
    {
        date: "2023-05-31",
        version: "18.5.0",
        updates: [
            "Additions",
            [
                "Add ability to ignore specified fields in a form when creating a favorite filter set",
                "Add measure filter to QSR filters",
                "Add measures filter to QSR dashboard network view, and conditions filter to Risk dashboard network view",
                "Add Tags column to Patient Measures list",
                "Add indicator for patients in grace period",
                "Add functionality for dynamically coloring a hoverlay tooltip's tail",
                "Add SDNA variant for display purposes",
                "New PDCM stage table and product mapping",
                "Add eligibility loader for HFPN HFHSF",
                "Add appointment loader for WFPC",
                "Add transfer-hidden option to sftp modules",
                "Add namespace dimension and FK within eligibility and add namespace to implicit termination logic within src.load_eligibility()",
                "Add events from event grouper to fdw.appointment to supplement last visit when appointment data doesn't exist",
                "Add stage table and mapping for BCNA MCG membership file",
                "Adding payer_incentive_is to records from United",
                "Add pre-diabetes A1c evaluation for patient tagging",
                "Add proxy feature and iterator for multiple endpoints configured within client.proxy",
                "Adding payer_incentive information and treatment_opportunity information for Ascension",
                "Adding and modifying United treatment opportunities and payer incentives",
                "Add network view filters",
                "Add per_member_id for use within the RBCE mapping views",
                "Add codes process to load CPT data from AMA to codes schema",
                "Added zero amount for new HFPN payer incentive records",
                "Add RBCE view for MAPPO eligibility",
                "Add payer entries for default product mapping",
                "Add RBCE Module",
                "New Loader: HFPN",
                "Add mapping for PCMH PPP for VMD",
                "Add server status mailer",
                "Add BCN specific numerator IDs to CMCC measure",
                "Add self.file_month parameter to MOH treatment opportunities",
                "Add missing treatment opportunity for BCBS Medicare for A1c <= 9%",
                "Add missing treatment opportunities for payers BCN, HAP, and Priority Health",
                "Add Group A Strep Test values"
            ],
            "Fixes",
            [
                'Practice Tags input should be labeled as that, not "Regions"',
                "Fix freeze when clicking Close on Edit Campaign modal",
                "Fix text alignment of table cells with hoverlays and an alignment",
                "Fixing measure_map_treatment_opportunity_id; mapping to correct measure",
                "Add primary key to client.provider",
                "Correct attribution sequence ordering by prioritizing EMR sources with a last appointment value that isn't null before ordering by the date",
                "Removing patient duplication from Agilon extracts",
                "Correcting descriptor column references",
                "Updated f_get_qsr_chart to resolve errors when handling int arrays",
                "Correct Timezone query to route to stdout",
                "Making HCC Gap Report in Report Library public and changing order of Event Grouper evaluation of ED/UC",
                "Alter eligibility roster views to ensure unique patients"
            ],
            "Improvements",
            [
                "Get individual patient letters from /patient/{id}/letter/{id} endpoint instead of printing them anew each time",
                "Don't mount the edit user form until after the fetcher has finished loading",
                "Prevent default form submit when Enter is pressed",
                "Make QSR measure filter into a dropdown, make all QSR filters multiselect",
                "Add a warning if you try to edit a filter set and include an ignored field",
                "Make QSR date field not required (falls back to current month and year)",
                "Mark deceased patients on TOC more clearly",
                "Collapse all expanded tiered-table rows when columns change, because the old expanded row paths may no longer apply",
                "Restyle checkbox tree to make it clearer what it means when no boxes are checked",
                "Filter out null values from mv_patient_incentives.incentivized_by_payer",
                "Simplify namespace logic to remove unnecessary cases",
                "Correct numeric pattern matching",
                "Limit inactive patient return based upon PCP relationship",
                "Add STR value_set_name to SE mapping, add timezone variable, update batch to set timezone each run",
                "Optionally match ophthalmology on opthalmology due to commen external misspelling",
                "Reducing locally-hosted backup retention to 3 days",
                "Add SDOH domain questions to last social needs assessment mapping",
                "Update BCBS to ignore Practitioner and Member files with YYMMM naming due to layout conflicts",
                "United new file format",
                "Updating last appointment date and next appointment to not return default values of 1753-01-01 or 9999-12-31",
                "Include BCN on PDCM"
            ],
            "Updates",
            [
                "Update QSR filter field names to match standards",
                "Updating 2023 Priority Health treatment opportunity records",
                "Update matching for GM connected care files",
                "Converting 2023 payer_incentive_ids over to their 2023 counterparts",
                "Updating client_access.make_agilon_extracts to use new full patient extracts form Veradigm",
                "Update clinical result loader to coalesce to the first appropriate value from child -> table -> parent; introduce null_descriptors.sql to identify avoided terms",
                "Update prism loader to load appointments from the appointment view",
                "Updating min rates for HFPN payer incentive rules"
            ]
        ]
    },
    {
        date: "2023-04-23",
        version: "18.4.0",
        updates: [
            "Additions",
            [
                "Ambulatory Care Sensitive Condition value set",
                "BCN PDCM Payer to default data",
                "BCN PDCM load views",
                "General condition: Pediatric Autism",
                "Ambulatory Care Sensitive Condition value set",
                "ETL option to allow specification of ACN name related to PH AHA loads",
                "Staging variants for Evidence Based Case Reports and update practitioner view with downstream eligibility/risk updates",
                "BCN to PHR Case Mix Index measures",
                "BCN measure map treatment oportunities and aligning records for 2023",
                "PIP layouts for Priority Health ETL",
                "Mapping for dx opp report",
                "Allow passing of extraFilters into Settings instead of Table - can now deselect columns instead of completely removing them"
            ],
            "Fixes",
            [
                "Patients with one code reporting results",
                "Missing measure map treatment opportunities",
                "Payer for new BCN PDCM records",
                "CategoryTree clean up",
                "Behaviour of CheckAllButton",
                "Empty extraFilters logic to repair tiered tables",
                "Tiered table pivot columns showing even when rows not expanded",
                "Empty extraFilters logic to repair tiered tables",
                "Fix tiered table pivot columns showing even when rows not expanded"
            ],
            "Improvements",
            [
                "NPI collection filter user experience",
                "QSR table and graph pull data overhaul: Network rates calculate separately, chart logic taking into account user's practice. Ensuring match between network rates, network-level rate to date and chart representations",
                "Patient campaigns to hold less unnecessary data",
                "Clean up CategoryTree and fix behaviour of CheckAllButton (it would set branches as values, not just leaves)",
                "Allow passing of extraFilters into Settings instead of Table, to just deselect columns instead of completely removing them",
                "Rework patient campaigns to hold less unnecessary data, and fix saving of campaign membership changes from the POC"
            ],
            "Updates",
            [
                "Campaign appointment data fixes",
                "eClinicalWorks appointment loader",
                "Blue Cross Blue Shield Provider-Delivered Care Management program payer data",
                "BCC eligibility loader",
                "FDW payer query to only include payers with attribution",
                "BCBS module to handle new EBCR layouts",
                "COL to use an updated min age",
                "CMCC measures requiring 2 claims"
            ]
        ]
    },
    {
        date: "2023-03-29",
        version: "18.3.0",
        updates: [
            "Additions",
            [
                "Frontend for patient condition deletion",
                'Add ability to mark a column as not one to filter on by prefixing it with a "!"',
                "Add SDOH status email report",
                "Adding new appointment and care manager tables to client-access",
                "Added columns to measure_goal table that were previously being calculated in queries; Network rate will now respect product class",
                "LOINC for GAD-7, PHQ-2/9, and EDN",
                "Add mapping for HPV (JVHL)",
                "Add CoCM codes and observation dates to PCMH depression report",
                "Add new appointment and care manager tables to client-access",
                "Add more granular Patient Seen values to tcm_conclusion",
                'Add a "Licensed" column to user table',
                "New views for RECLMEXP and RELAB",
                "Add JSON handling and 2023 SE value sets",
                "Adding a new report listing duplicate patients",
                "Adding Medicare DCE and ACO descriptors to ref.display_payer",
                "Adding procedure to generate extracts for Agilon; adding Agilon and First Mile to Client Access install",
                "Add routing for GLOSC roster and schedule endpoints",
                "Add supplemental error and medicare patient demographic reports",
                "Add docker prune to batch",
                "Adding Deceased Date field to payer only membership report",
                "Add regular expression for source identification"
            ],
            "Fixes",
            [
                "Hide or disable TCM inputs if user doesn't have TCM_WRITE module access",
                "Fix an issue with patient conditions saga",
                "Mark conditions as removed if they have a removed_dt",
                "Don't display any Met icon for removed condition rows",
                "Fix an error that was happening due to SVG icons having non-string classnames",
                "Don't display any Met icon for removed condition rows",
                "Fix hidden datepicker dropdowns on TCM",
                "Imported a connect instead of base component, causing doubled tooltips",
                "Fix QSR performance chart measures dropdown and years in labels",
                "Remove patient cost columns on patient list if user doesn't have access to cost-utilization module",
                "Add vmd staging tables vmd module",
                "Add condition for row count when ORU generation fails due to a lack of new records",
                "Add RBCE module, GZ file handling, and invert file_skip_bottom to allow for a positive integer in the module configuration",
                "Adding fuzzySearch to campaign_filters",
                "Replacing campaign dates coming as empty strings instead of null values"
            ],
            "Improvements",
            [
                "Split TCM_READ and TCM_WRITE modules off from CARE_MANAGEMENT, and update references appropriately",
                "Rework QSR to use the same style of filters as everywhere else",
                "Store QSR filters in store instead of pulling them directly from form. Also don't update the options dynamically",
                "Do QSR year and month selection with a datepicker instead of two selects",
                "Rename a QSR column for clarity",
                "Disable unrepresented filter options in QSR dropdowns, without actually deleting them",
                "Refactor hideIfNoneEditable function and patient conditions cell renderers",
                "Have hideIfNoneEditable assume editable entries exists if no editableKey is specified",
                "Show removed conditions as Pending for their met indicator",
                "Add more filter specifications to report library, including a regex-based field name matcher",
                "Cross out removed patient tags in Manage Tags modal instead of removing them immediately",
                "Tweak tab spacing to make last tab able to sit flush with right side",
                "Improve styling for numeric fields",
                "Allow horizontal scroll overflow for care management dashboard",
                "Modifying the Medication section of the PCMH Reports to be the same and to list a more valid Medication Type",
                "More useful logging added for reports",
                "Expanded ETL driver to handle JSON import",
                "Allow conditions to re-display if diagnosis received after removal"
            ],
            "Updates",
            [
                "Update QSR styling to match current standard",
                "Update field types for MHP treatment opportunities",
                "Updating BCBS ETL regex for Practitioner and Membership files to allow for %yM%m dates",
                "Update acrs to review all active attribution rather than limiting to '9999-12-31' explicit end date",
                "Update term to created_date for inactive extract",
                "Simplifying TPA loader views. Removing package_id logic now that all files/tables are loaded under a single load_id",
                "Updating payer incentive target rate percentages",
                "Update condition mart to use standard code interpretation",
                "Update Dx opportunity loader for HMG PVP",
                'Update "Med Rec Delivery" to indicate API receipt',
                "Update report name and conditional logic"
            ]
        ]
    },
    {
        date: "2023-02-23",
        version: "18.2.0",
        updates: [
            "Additions",
            [
                "DISTINCT to PHR patient_claim.sql",
                "FMC At Risk Column/Flag to TCM Audit Report",
                "High-Cost Patient Report /w/ Top Contributing Subcategory",
                "intra-day loader for LANES",
                "is_primary_practice condition to user_practice join when generating pm_stage and patient_summary",
                "Meridian Eligibility and Treatment Opportunity Names",
                "Refresh_cost_percentiles.sql missing from event-grouper install",
                "Risk Adjustment Dashboard Tab",
                "Risk Dashboard",
                "To TCM Report",
                "Utilities and Employment Tags to SDOH"
            ],
            "CCDA Demographic Fields Data",
            "Change",
            [
                "Create statement for holland i-cds tables",
                "PCMH - Well Child Care Report"
            ],
            "Configuration-driven anchor date selection between 2022 and 2023",
            "ECW Appointment Loader logic; a new approach for matching data to stage table",
            'Ensure an "incentivized" flag is set for all treatment opportunities',
            "Display an error when the child measure is set to render and the parent is not",
            "Expand",
            [
                "CMS CCLF Loader to handle Y/R file types",
                "Imaging CDS loader to optionally include a final flag"
            ],
            'Extend PCMH-Appointment to include "Rendering Practice"',
            "Fix f_get_patient_demog returns mostly null next appointment dates",
            "Generate",
            [
                "Patient cost percentiles",
                "Patient tags for patients >= 95th percentile in cost"
            ],
            "Limit PDCM numerator from EMR claims to 3 months",
            "Map Codes to SDOH Tags Correctly",
            "Migrate to the 2023 Performance Year",
            "Missing appointments within the registry",
            "PK violation on phr.transition",
            "Refresh concurrently PHR materialized views",
            "Remove",
            [
                "Duplication in TCM Audit Report",
                "Packard Health from Health Focus"
            ],
            "Split BCC supplemental exchange module for TPA",
            "Tag",
            [
                "DX Review - BMI >= 40 without Morbid Obesity",
                "DX Review - Uncomplicated DM with A1c >= 9%"
            ],
            "Truncate the Tag table on an empty run",
            "Update",
            [
                "CCLF Layouts",
                "FOBT Mapping for CCHC",
                "refresh_docker.sh to identify DEV hosts",
                "SDOH install, standardize the location of extracts",
                "SIU loader to parse appointment status",
                "TCM Audit Report incorrect cost",
                "Updates to 2022 BCBSM EMR Data Exchange File Submission"
            ]
        ]
    },
    {
        date: "2023-01-26",
        version: "18.1.0",
        updates: [
            "Added",
            [
                "BMI >= 40 without Morbid Obesity Dx Review",
                "Cost to TCM Audit report",
                "Dx Review tag for Uncomplicated diabetes with an A1c >= 9%",
                "Filter for discharge description",
                "LPO ACO Membership",
                "New BCBS Landmark loader and tagging",
                "Next appointment and cost data to POC",
                "Problem fact // condition loaders",
                "Rendering provider practice to PCMH-Appointment report",
                "Several EMR appointment loaders",
                "TCD Appointment module"
            ],
            "Aligned incentive year to anchor date",
            "Consolidated Athena for data coverage",
            "Corrected issue with Allscripts appointment loader using null start/end times causing update failures due to natural joins",
            "Improved",
            [
                "HCC capture by removing FK constraint on the provider",
                "Measure engine retrospective runs to replace measures that were specified",
                "SIU appointment status parsing",
                "Visibility of user authentication attempts"
            ],
            "Split IP events by facility",
            "Updated",
            [
                "Abbreviated Facesheet Generation",
                "ADD/ADHD PCMH report to use an inner join on the condition",
                "CCD result functions to better identify screenings",
                "The default behavior for source_target retrieval",
                "Demo TCM records",
                "Mammography mapping",
                "MIRTH permissions",
                "Nicknames",
                "ORU sample extract to properly randomize dates (remove 00 month/day)",
                "PMCH reports",
                "Treatment opportunity loader to ignore financial indicator for incentive flag - data received from payers is inaccurate",
                "United eligibility loader",
                "warehouse.problem constraints"
            ]
        ]
    },
    {
        date: "2022-12-22",
        version: "18.0.0",
        updates: [
            "New features:",
            ["Data coverage", "Favorite filters"],
            "Fixed:",
            [
                "QSR dashboard printing",
                "Overclassification of ED encounters from the procedure value set",
                "Alignment of various columns",
                "Cost Utilization date filters"
            ],
            "Updated: ADD/ADHD PCMH report"
        ]
    },
    {
        date: "2022-12-16",
        version: "17.7.0",
        updates: [
            "Added",
            [
                "data_coverage summary for use on dashboard",
                "Incentive Reconciliation to QSR Dashboard",
                "Insurance table for capturing records from ccd interfaces",
                "Mammography mapping codes",
                "Payer incentive status to care plan table (registry)",
                "Supplemental file changes to care_plan",
                "Supplemental eligibility and IDs to patient measures"
            ],
            "Corrected",
            [
                "CCDA loader for outside services without a child observation date",
                "Potential issues with incentive goals when a batch does not complete successfully on the first day of the month (causes a lookup of non-existent data)",
                "missing At Risk column in TCM export"
            ],
            "Finalized",
            ["Ascension Complete loader", "Removal of Urine Protein measure"],
            "Hotfix to address QSR Dashboard links",
            "Improved",
            [
                "Operand matching for nested xml",
                "TCM mapping of admit/discharge",
                "Introduced",
                "incentive_status and reconciliation values",
                "Master facility records",
                "sorting of tables with blank cells",
                "display of cards and tables in general"
            ],
            "Materialized elements of the patient list to improve return time",
            "Resolved issue with PDCM drop sequence",
            "Updated",
            [
                "CCDA clinical result function",
                "Documentation build process",
                "Dummy records for care plan (supp_elig / supp_ids)",
                "HeB Entry Report to include EMR records for non-SE services",
                "KED PCMH report",
                "PDCM claims loader",
                "PDCM reports",
                "Priority Health PIP loaders (drop site location)",
                "SDOH mapping",
                "Snowflake python package to prevent complete meltdown of the warehouse server",
                "std_result to parse footnotes"
            ],
            "Removed patient letter functionality"
        ]
    },
    {
        date: "2022-11-30",
        version: "17.6.0",
        updates: [
            "Added",
            [
                "Appointment details to pcmh-appointment report",
                "Loader for PHP",
                "Medication class and fill details to PCMH reporting",
                "SNOMED mapping for diabetes condition and measure eligibility"
            ],
            "Utility script to download full source archives for processing",
            "Aligned campaign and list function returns",
            "Changes (major) to the financial engine",
            "Cleared orphaned measure components during general run",
            "Corrected",
            [
                "View dependencies for Molina",
                "calc_care_plan to properly identify measures"
            ],
            "Fixed crash on utilization report page",
            "Improved",
            [
                "Performance of patient risk function",
                "Proxy module performance",
                "SDOH inverted question mapping",
                "Performance within episode grouper using incremental commits",
                "display of utilization report",
                "display of tables in general"
            ],
            "Initial deployment of Allscripts appointment loader",
            "Move event_grouper process to weekend batch",
            "PHR function performance",
            "Re-incorporated CPT-2 codes into CDC measures. This will be a critical component of the PUSH model for 2023",
            "Removed truncate of measure engine override preventing Treatment Opportunities to affect numerators",
            "Split FMC indicator for BCNA/MAPPO to improve TCM Tracking",
            "Standardized column names and ordering on Report Library reports",
            "Updated",
            [
                "App cache clear",
                "Member best demog / master patient demog to prioritize load id in both versions",
                "Payer-only-membership report to consolidate and display +1 for payer list",
                "PDCM loader",
                "Proxy module to return on match_file method",
                "Client Access Dashboard report to reflect incentivized patients",
                "Ascension Complete loader"
            ]
        ]
    },
    {
        date: "2022-11-10",
        version: "17.5.0",
        updates: [
            "Added:",
            [
                "FMC risk score",
                "Patient MRN (EMR identifier)",
                "Sub-module identification via dot separator",
                "TOC/TCM At Risk icon/flags"
            ],
            "Allowed client-override of displayed report library entries",
            "Corrected contract number identification in supplemental files",
            "Improved operand matching for measure mart",
            "Fixed validation of Blood Pressure measure",
            "Updated:",
            ["Molina Rx loader", "United treatment opportunities"]
        ]
    },
    {
        date: "2022-11-02",
        version: "17.4.0",
        updates: [
            "Added:",
            [
                "Age, dob, and product_line to First Mile extract",
                "Exclusion for SPD",
                "CDS support for imaging studies",
                "DOB to MiHIN health system identifiers",
                "Interface status mailer",
                "Interval reference for nightly batch emails",
                "Loader for Ascension Complete",
                "Loaders for Health Net, LA Care and Anthem",
                "Medications to utilization details",
                "Module for Ascension Complete",
                "Module for Anthem",
                "Patient's EMR MRN to fdw.patient_mrn and report library",
                "Patient mrn to internal pcmh reports",
                "Roster generation for LANES",
                "SCR for MCT2D APS extract",
                "SDOH positive domain mapping for tags",
                "Treatment opportunity loader for manually identified measures on POC",
                "United treatment opportunities"
            ],
            "Allow limitation of practice interfaces to specific client provider tables. This will avoid cases of cross-practice attribution following providers",
            "Altered:",
            [
                "Athena enterprise query to exclude specialty appointments",
                "AWV measure to look at individuals age 65+ rather than MCR/MCA products",
                "Encounter identifier to include full patient identifier list",
                "Transition FDW view to select distinct",
                "Attribution sequence to favor EMR over Program enrollment"
            ],
            "Corrected AMG Athena enterprise eligibility loader to reference last updated date from custom demographics",
            "Consolidated PH eligibility views",
            "Fixed the views so that they will run even if there is bad data in some of the tables",
            "Fixed various crashes on TOC due to special sorting schemes",
            "Enabled:",
            [
                "Interface status mailer on weekend (sat) runs",
                "SFTP-protocol specification for outbound interfaces"
            ],
            "Improved:",
            [
                "7-day archive purge",
                "ADT load to use a left join on admit and discharge type dimension",
                "Batch documentation for Athena interface",
                "guess_date function",
                "Mapped value sets for measure calculation and display",
                "Row-level failure for ETLs"
            ],
            "Limited WCV measure based upon provider taxonomy",
            "Re-ordered Member best demog to focus on load_id rather than person_id (improves demographic reversals)",
            "Removed:",
            [
                "Class/module name diff for ascension_comp",
                "Constraint on LOINC/Athena code systems for utilization details",
                "Duplicate diagnoses from utlization -> claim view",
                "Hard-coded source entries in ETL views",
                "Legacy urine protein measure",
                "random sort for patients in member_best_demog without a load_id"
            ],
            "Resolved format_phone drop constraint",
            "Reviewed 2023 measures",
            "Reworked of FE process",
            "Sorted nulls last for demographic build",
            "Updated:",
            [
                "Athena Enterprise model to limit appointments based upon department",
                "CDC measure to align nephropathy variation",
                "DCE loader to use package_finalize grouping for claim loaders",
                "HCC reports",
                "Interface status mailer to include row/record count",
                "MiHIN CKS loader to reference updated file names",
                "Negative result matching for ECP",
                "Payer-only-membership report to consolidate and display +1 for payer list",
                "PDCM table layout"
            ],
            "Allowed display of transition types other than IP and ED in POC admissions table"
        ]
    },
    {
        date: "2022-10-17",
        version: "17.3.0",
        updates: ["Store table settings separately for each campaign"]
    },
    {
        date: "2022-09-19",
        version: "17.2.0",
        updates: [
            "Added:",
            [
                "mihin_inactive_f and call instead of death subsection of the transition src load",
                "Proxy module"
            ],
            "Consolidated:",
            [
                "PIP elegibility",
                "PH treatment opportunity files when loading from multiple locations"
            ],
            "Expanded custom value sets: Change in code system for AMGTOUCHWORKS SDOH screening #907 NMMP DM Urine Protein #884",
            "Improved:",
            [
                "Display of campaigns page",
                "Base PHR installation to avoid missing data"
            ],
            "Limited:",
            [
                "client_ccda module to the indicated source",
                "Milliman extract to population with claims"
            ],
            "Removed:",
            [
                "Default SSNs when evaluating MPI",
                "Exclusion of updated transition records where diagnoses were removed"
            ],
            "Updated:",
            [
                "CCD nephropathy measure",
                "Molina file matching",
                "Allow admin users to edit campaigns",
                "Meridian (MHP) loader"
            ],
            "Fixed:",
            [
                "Scope display on patient list filters for superusers",
                "Tendency for certain settings to persist if you log out and then back in as a different user",
                "Ensure frozen columns are included in print and export"
            ]
        ]
    },
    {
        date: "2022-09-02",
        version: "17.1.1",
        updates: [
            "Apply unique constraint to measure variance",
            "Convert NDC MV->Table",
            "Correct Next/Last Appointment on campaigns",
            "Updated:",
            [
                "Humana views to account for missing fields",
                "Immunization combo measure treatment opportunities for BCBS/BCN"
            ]
        ]
    },
    {
        date: "2022-08-30",
        version: "17.1.0",
        updates: [
            "Added:",
            [
                "API entry for Med Rec in ACRS delivery file",
                "Custom extract filter table to client configuration",
                "Custom value sets for supplemental exchange",
                "medhistory load type for CCD",
                "New BP matches for calc_care_plan",
                "Rx categorization",
                "Utilization measure type",
                "Validation for extreme variance in manual entry"
            ],
            "Improved:",
            [
                "Multi-source termination where implicit logic isn't available",
                "REGEX matching"
            ],
            "Updated:",
            [
                "CCD clinical result loader (expansion of source result id)",
                "ETL documention",
                "Humana/Meridian load process to resolve missing data fields"
            ]
        ]
    },
    {
        date: "2022-08-23",
        version: "17.0.0",
        updates: [
            "Made user access to practice units intransitive. Giving a user access to a unit won't give everyone else in that unit access to the user's panel",
            "Fixed:",
            [
                "Issue with inactive patient records applying globally",
                "Variable selection of service date for PCMH report"
            ],
            "Allowed display of multiple diagnoses per ADT row",
            "Removed edge cases of transitive access for admins",
            "Validated LOINC format for supplemental files"
        ]
    },
    {
        date: "2022-08-19",
        version: "16.4.0",
        updates: [
            "Improved:",
            [
                "std_result() to better separate values from units",
                "TCM tracker status so closed transitions can be reviewed"
            ],
            "Transition diagnosis consolidation",
            "Updated:",
            ["PH incentives", "PH eligibility loader target APCD->PIP"]
        ]
    },
    {
        date: "2022-08-14",
        version: "16.3.0",
        updates: [
            "Added:",
            [
                "Filter to CM Incentive tab",
                "Hyperlipidemia general condition and report",
                "PCMH-depression and PCMH-hyperlipidemia reports",
                "Practice filter to CM Incentive tab",
                "Transition to client_access"
            ],
            "Updated:",
            [
                "PH incentives",
                "Risk association to use type instead of ID for cases where clients have offset identifiers.",
                "Staging layouts"
            ]
        ]
    },
    {
        date: "2022-08-09",
        version: "16.2.0",
        updates: [
            "Fix several issues with auto-calculated measures, including one that freezes the page when a value is entered",
            "Fix crash when exporting TOC"
        ]
    },
    {
        date: "2022-08-04",
        version: "16.1.0",
        updates: [
            "Fix various issues with tag access, mainly to do with changes not being applied immediately",
            "Display percentages and timestamps correctly in corner cases",
            "Fix sorting of ADT table",
            "Fix error on Labs page when lab codes are long and numeric"
        ]
    },
    {
        date: "2022-07-26",
        version: "16.0.0",
        updates: [
            "Major rework of tag permissions system. Access can be granted automatically from a variety of sources, and manually with either read or edit privileges.",
            'Renamed "Labs, Claims, & Pharmacy" section to "Utilization Details"',
            'Added ADT table to "Utilization Details" section',
            "Improve Utilization Details filters"
        ]
    },
    {
        date: "2022-07-14",
        version: "15.4.0",
        updates: [
            "Improved report library performance",
            "Improved report library filters",
            "Include patient ID column in library reports",
            "Fix several issues with auto-calculated measures, which prevented submission of measure values",
            "Don't show measures the patient already has in Add Measure",
            "Added more error notifications"
        ]
    },
    {
        date: "2022-07-07",
        version: "15.3.0",
        updates: [
            "add phone number to patient list",
            "refresh provider directory after updating user practices or relations",
            "Improve timezone calculations",
            "Fix sorting of Edit User practices tab"
        ]
    },
    {
        date: "2022-06-28",
        version: "15.2.0",
        updates: [
            "Tags only expand access to patients for external users",
            "Improve display of dates on user list",
            "Fix a corner case with autocalc measures "
        ]
    },
    {
        date: "2022-06-08",
        version: "15.1.0",
        updates: [
            "Allowed autocalc measures to consider previously-entered values for a given day",
            "Corrected display of some percentages on TOC",
            "Added POC links to patient list PDFs, and patient IDs to CSVs"
        ]
    },
    {
        date: "2022-05-27",
        version: "15.0.0",
        updates: [
            "Major rework of report library, including filters comparable to other tables",
            "Added next and last appointment date to TOC"
        ]
    },
    {
        date: "2022-05-20",
        version: "14.6.0",
        updates: ["Added some missing data to patient list exports"]
    },
    {
        date: "2022-05-13",
        version: "14.5.0",
        updates: [
            "Added additional information to POC conditions tab",
            "Improved sorting of report library columns"
        ]
    },
    {
        date: "2022-05-05",
        version: "14.4.0",
        updates: [
            "Prevent occasional failure of POC to load",
            "Added last appointment date to patient list",
            "Allow filtering of TOC on discharged status",
            "TCM tracks who made changes and when"
        ]
    },
    {
        date: "2022-04-22",
        version: "14.3.0",
        updates: [
            "Fixed many issues with patient tags",
            "Normal users can now create public tags",
            "Improved display of many notifications",
            "Fixed crash on user admin screen",
            "Added practice tag filter to all QSR dashboard views",
            "Sex and orientation are no longer required fields in patient info"
        ]
    },
    {
        date: "2022-04-07",
        version: "14.2.0",
        updates: [
            "Fixed several breakages for practice admins on user administration page",
            "Fixed calculation of BMI percentile",
            "Corrected time zones in some displayed dates",
            "Added ability to summarize number of BPs collected within a single visit",
            "TCM weren't showing after 14 days post-discharge; this has been pushed to 30 days",
            "Fixed issue to identify BCBS PDCM patients who have 1 billing code on the PDCM Dashboard",
            "Removed duplication of claims between BCBS and OSC sources when calculating cost",
            "Restricted visible patients to attributed PCP instead of care team"
        ]
    },
    {
        date: "2022-03-25",
        version: "14.1.0",
        updates: [
            "Added concept of practice tags to complement patient tags",
            "Made it easier to add and filter by custom genders",
            "Added custom genders to PDF and CSV export",
            "Improved display of non-member patients in campaigns",
            "Improved display of dates in several charts and tables",
            "Fixed practice unit search",
            "Various small styling tweaks"
        ]
    },
    {
        date: "2022-03-11",
        version: "14.0.0",
        updates: [
            "Added ability to freeze columns on data tables",
            "Size and style adjustment for logos and icons",
            "Links added within the report library to POC pages",
            "Improve audit logging of report views",
            "All changes to user accounts and settings are now logged",
            "Display user last login date on management panel",
            "Various UI improvements to User Administration view",
            "Removal of legacy LDAP authentication module",
            "Added configuration option for measure calculation mode",
            "Added configuration option for default cost product",
            "Added organizational control of source attribution",
            "Added organizational control of source demographic priority",
            "Improved structure and capture for birth sex, gender identity, and sexual orientation",
            "Improved performance and logic for patient search",
            "Various fixes for exported documents and charts",
            "Adjusted sort order for cost/utilization categories",
            "Make patient tags more prominent on POC pages",
            "Display performance year on application footer",
            "Optimization of cost and patient list queries",
            "Addition of standard PCMH reports in the report library",
            "Continue to calculate measures for patients in a term-45 state",
            "Improvements to master patient index gender assignment",
            "Additional general conditions added to support reporting",
            "Supplement IP/ED/SNF/OB counts with claims data",
            "Practice admins now always have access to all panels in their practice",
            "Fixed several errors in cost calculations",
            "Changed TOC sort order to show admissions as well as discharges",
            "Fixed bug that caused displayed measure values to change after opening and closing measure history"
        ]
    },
    {
        date: "2021-12-17",
        version: "13.12.0",
        updates: [
            "Redesigned tabs on dashboard and POC",
            "Redesigned UI for modules on site settings page",
            "Redesigned measure saving notifications to match rest of site",
            "Added a spinner to measures on the POC while they are saving",
            "Display patient cost breakdown chart correctly when the patient has dual coverage",
            "Add filters to TCM Tracker",
            "Add patient name filter to TOC",
            "Dates in TOC filters should be applied correctly",
            "Fixed exporting of reports",
            "Rates below 10% on the QSR now render with a digit after the decimal point",
            "Prevented display of disabled practices in the Add/Edit User practices tab",
            "Only the Admin user can edit Admin-owned tags (including location tags)",
            "Added ability to remove tags from a patient in one click"
        ]
    },
    {
        date: "2021-11-18",
        version: "13.11.0",
        updates: [
            "Included general conditions in Conditions batch print",
            'Ensured that external users are only displayed in user table when "External Users" virtual practice is selected',
            'Don\'t show "All Patients" tag any more',
            "Improved display of tag tables and added search",
            "Search bars with cosmetic magnifying-glass icons don't treat then as buttons",
            "Added module for community linkages",
            "Ensured that all users can be granted access to location tags",
            "Dropdowns clear properly when their form is reset"
        ]
    },
    {
        date: "2021-11-10",
        version: "13.10.0",
        updates: [
            "Added configurable permissions for several modules",
            "Ensured that external users appear on user list",
            "Updated many CSV and PDF exports to include some missing data",
            'Added "Total Cost of Care" row to cost report',
            "Added tags for each community location. Tags are automatically added to a patient when community linkages are created",
            "Manually adding patient tags requires a start date",
            "Provider directory now shows a patient count on practice units",
            "Ability to batch print conditions separately from POC",
            "In batch print patient select, you can now search by DOB",
            "Resolved more timezone issues",
            "Improved styling on tiered tables and search bars"
        ]
    },
    {
        date: "2021-10-19",
        version: "13.9.1",
        updates: ["Fixed display issues on cost report and QSR"]
    },
    {
        date: "2021-10-14",
        version: "13.9.0",
        updates: [
            "Rebuilt cost report and QSR to be much more performant",
            "Added a Total row and a Patient Count column to the cost report",
            "Added an Encounter Type column to the TCM",
            "Removed Trend tab from utilization report",
            "Batch printing cost requires access to the Cost module",
            "Prevented empty addresses displaying as a series of commas",
            "Show all of a patient's coverages if they have more than one"
        ]
    },
    {
        date: "2021-09-09",
        version: "13.8.0",
        updates: [
            "Added new columns to the TCM",
            "Fixed saving of values on the TCM"
        ]
    },
    {
        date: "2021-09-02",
        version: "13.7.0",
        updates: [
            "Added ability to delete use-defined tags",
            "Display improvements in tags modal",
            "Fixed date filters on TOC again",
            "Improved display of dates on TOC and TCM"
        ]
    },
    {
        date: "2021-08-20",
        version: "13.6.0",
        updates: [
            "User-defined tags modal also shows patients and users with each tag",
            "Prevent external users from adding tags to patients",
            "Display product class with patient coverage",
            "Fixes to TOC dates and filters",
            "Fixed positioning issues with some tooltips"
        ]
    },
    {
        date: "2021-08-05",
        version: "13.5.0",
        updates: [
            "Added modal to manage user-defined tags",
            "Minor fixes to the cost filters",
            "Resolved an issue preventing some campaigns from displaying their contents"
        ]
    },
    {
        date: "2021-07-29",
        version: "13.4.0",
        updates: [
            "Added modal to view deactivated patients",
            "Made TOC admission and discharge dates into timestamps",
            "More bug fixes in cost components"
        ]
    },
    {
        date: "2021-07-15",
        version: "13.3.0",
        updates: ["Various bug fixes and display improvements"]
    },
    {
        date: "2021-07-08",
        version: "13.2.0",
        updates: ["Added ability to mark patients for deactivation"]
    },
    {
        date: "2021-07-01",
        version: "13.1.0",
        updates: [
            "Added a user guide page giving more detailed definitions of each measure, and added links to the page each time measures appear",
            "Made user list show one user per row, with the option to expand rows to view additional details",
            "Included patient tags in Point-of-Care PDF",
            "External users no longer see an empty patient list",
            "Fixed broken privacy policy link",
            "Gave print and export icons tooltips instead of having them expand on hover"
        ]
    },
    {
        date: "2021-06-07",
        version: "13.0.2",
        updates: [
            "Fixed nonfunctional care management filters on ToC",
            "Fixed incorrect filtering on patient list links on QSR dashboard"
        ]
    },
    {
        date: "2021-06-07",
        version: "13.0.1",
        updates: [
            "Fixed bug preventing practice admins from seeing users and creating new ones",
            "Removed patient name from PoC tab title"
        ]
    },
    {
        date: "2021-05-14",
        version: "13.0.0",
        updates: [
            "Added patient tags as a way for users to create custom patient groupings",
            "Add read-only 'external users' with reduced privileges",
            "Made all table columns resizable",
            "Removed unused measures and payers from form filters",
            "Added ability to remove inactive providers from the tool",
            "Increased max length of several fields",
            "Tidied display of nav menu at small window sizes",
            "Corrected filtering of risk types on patient list",
            "Fixed crash when viewing campaigns that filter on providers",
            "Fixed sorting on QSR table",
            "Clicking measure names on QSR dashboard no longer filters out compliant patients from the results",
            "Clicking on a provider on the cost-utilization dashboard now links to the Cost Report instead of the Patient Measures list"
        ]
    },
    {
        date: "2021-03-16",
        version: "12.3.0",
        updates: [
            "Added option to prevent logging of measure values for specified roles",
            "Improved sorting on large tables"
        ]
    },
    {
        date: "2021-03-11",
        version: "12.2.0",
        updates: [
            "Added integrated user guide",
            "Added /external-poc endpoint for external patient matching",
            "Added scope filter to searchbar in nav",
            "Added a link to the Community Resources modal (previously only accessible from the PoC Linkages tab) to the main nav",
            "Improved performance of QSR dashboard",
            "Reloading on a campaign's patient list page no longer blankscreens",
            "Password reset box no longer persists after logging in as a different user"
        ]
    },
    {
        date: "2021-02-22",
        version: "12.1.0",
        updates: [
            "Split conditions into 'HCC Conditions' and 'General Conditions' on patient list and POC",
            "Added General Conditions column and filter to TOC",
            "Applied TOC filters to its pdf and csv",
            "Display and performance fixes for QSR pdf and csv",
            "Updating campaign filters now takes effect immediately",
            "Made campaign patient list paginate correctly",
            "Prevented occasional crash in reports library",
            "Site setting to enable/disable global access to patients",
            "Users' primary practices can be set from the Admin page",
            "Improved display of notifications",
            "Stability improvements to various form fields"
        ]
    },
    {
        date: "2021-01-28",
        version: "12.0.0",
        updates: [
            "Added Cost Report",
            "Added Cost/Utilization Report",
            "Added Cost/Utilization Dashboard",
            "Added Patient Costs tab to Point of Care",
            "Added Spending chart to patient list page",
            "Added Costs column to patient list",
            'Admins can set a user\'s practice as their "primary practice."'
        ]
    },
    {
        date: "2021-01-27",
        version: "11.7.0",
        updates: [
            "Added table filters to TOC table",
            "Added ability to edit TCMs from TOC table",
            "Added Practice column to TOC table",
            "Some display changes to site-administration pages",
            "New users can only have letters, numbers, and underscores in their login ID",
            "Fixed several incorrect counts resulting from physicians in multiple practices being counted twice",
            "Fixed misalignments on PoC Measures tables",
            "Fixed some graphical issues in table settings",
            "Fixed certain charts not resizing when window is resized"
        ]
    },
    {
        date: "2020-12-16",
        version: "11.6.0",
        updates: [
            "Usernames on the login screen are now case-insensitive",
            "Users are now forced to reset password again after it's reset by an admin",
            "Improvements to patient search",
            "Fixed several issues that were causing patients to be left out or duplicated on the patient list",
            "Fixed several issues with how BMI is displayed",
            "Fixed PDF print producing empty PDFs in certain cases",
            "Fixed incorrect dates on TOC table",
            "Fixed missing entries and columns in Edit User modal's practices and provider tables",
            'Selecting "All Providers" no longer saves and closes Edit User modal'
        ]
    },
    {
        date: "2020-09-10",
        version: "11.5.3",
        updates: [
            "The Care Management dashboard now links to the list of single-encounter patients correctly",
            "Added a Log Date column to the Measure History modal"
        ]
    },
    {
        date: "2020-08-03",
        version: "11.5.2",
        updates: [
            "Fixed a bug that was preventing BMI from calculating properly"
        ]
    },
    {
        date: "2020-08-20",
        version: "11.5.0",
        updates: [
            "Printing campaigns to CSV no longer returns a file containing only 'null'",
            "Fixed crash when updating questionnaire measures",
            "Corrected some values in QSR CSV"
        ]
    },
    {
        date: "2020-05-21",
        version: "11.4.0",
        updates: [
            "Added a settings page for superusers to edit site-specific info"
        ]
    },
    {
        date: "2020-04-09",
        version: "11.3.0",
        updates: [
            "Care management incentive tabs on dashboard link to patient measures list instead of patient summary",
            "Prevented occasional crash on login page",
            "Fixed date picker inputs again",
            "Sorting by care manager no longer breaks patient list",
            "Clearing a date filter no longer causes a crash",
            "Dialed back overzealous autocompletion when adding/editing user accounts",
            "Improved feedback to user when non-fatal errors occur"
        ]
    },
    {
        date: "2020-02-20",
        version: "11.2.0",
        updates: [
            "Updated software frameworks, improving performance and changing some styling slightly",
            "Added ability to activate and deactivate care management, report library, and utilization report pages from database",
            'Replaced "HVPA" and support contact info throughout site with database-configurable, organization-specific values',
            "Sped up loading of patient measures list",
            "Added Care Management filter to patient list filters",
            "Risk types are only limited to HVPA-specific ones on the PoC page, not on the patient list filters",
            "Re-added conditions column to campaigns and to patient list csv",
            "Practice unit names now display correctly when viewing other people's campaigns",
            "Added ability to assign superusers to practice units",
            "Prevented top-level site admin from creating campaigns; that's not what that account is for",
            'Stopped top-level site admin from having to see an "an admin reset your password" message every time it logs in',
            "Improved measure validation messages on PoC page"
        ]
    },
    {
        date: "2019-11-14",
        version: "11.1.0",
        updates: [
            "Stopped campaigns with certain conditions from returning empty patient lists.",
            "Added patient care management information to the POC page.",
            "Added a practice unit filter to the quality summary dashboard.",
            "Added PDF printing and CSV exporting to the quality summary dashboard.",
            "Added phone number, priority attention, and community linkages to PoC and batch print PDFs.",
            "Added PDF printing of patient lists to campaigns.",
            "Prevented campaigns from being shared with inactive users.",
            "Hid deceased patients by default on the patient list."
        ]
    },
    {
        date: "2019-07-31",
        version: "11.0.0",
        updates: [
            "Compliant indicator has been changed from a green X to a green check mark."
        ]
    },
    {
        date: "2019-06-20",
        version: "10.4.0",
        updates: [
            "Adjustments to the QSR summary tab on the dashboard in order to improve the user experience.",
            "Practice/user now has the ability to select which measures to print on reminder letters."
        ]
    },
    {
        date: "2019-05-30",
        version: "10.3.0",
        updates: [
            "Users will now be able to search patients with hyphenated names.",
            "Added Care Manager(s) as an optional field on the Transitions of Care (TOC) table.",
            "Added a Risk tab to the POC page that will allow users to add patient risk details. Also added an information icon displayed next to each risk type entry.",
            "Practice is now an optional column that can be added to patient lists/summary and Campaigns."
        ]
    },
    {
        date: "2019-05-06",
        version: "10.2.0",
        updates: [
            "Priority attention will no longer populate duplicates on the POC PDF.",
            'Gad-7 will now allow the user to select "not at all" for all seven questions for a score of 0.',
            "Historical care manager data is now visible on POC page for patients who have care manager relationships assigned.",
            "Care managers will now select the reason they are enrolling patients in care management services.",
            "SNF discharges will now show on the POC priority attention tab."
        ]
    },
    {
        date: "2019-04-14",
        version: "10.1.0",
        updates: [
            "Users will now need to select the date of service for each measure; user can either click on the empty date field to open it and select a date of service, or the user can easily click the calendar at the end of the date field which will auto populate today's date.",
            "Users now have the option to add Care Manager column to patient lists to see if a patient in seen by a Care Manager, campaigns will also have the care manager option. Campaigns also have the option for a user to create a campaign based on a specific risk type.",
            "Users will now have the option to include or exclude screenings when printing the POC form. This feature is included in batch printing as well.",
            'If user is printing POC form for patient who is compliant (up to date) with all services then the POC PDF will generate with patient demographics, and conditions. In place of the measures you will see the statement "No required services" on the PDF.',
            "Due dates have been removed and replaced with a compliance indicator.",
            "Complete check boxes have been removed, user will now select date of service and save entry complete will automatically populate.",
            "Care Managers can now select an effective and term date for patients they provide care management services to."
        ]
    },
    {
        date: "2019-03-07",
        version: "10.0.0",
        updates: [
            "Conditions on PoC PDF are now sorted by last identified date",
            "Reminder letter function has been disabled for all deceased patients",
            "QSR measures are now sorted in alphabetical order",
            "Adjusted the order of columns displayed on the QSR to add the product class to the drill down"
        ]
    },
    {
        date: "2019-02-07",
        version: "9.4.1",
        updates: ["Improved logging"]
    },
    {
        date: "2019-01-17",
        version: "9.4.0",
        updates: [
            "Point of Care PDF for deceased patients will show deceased with a deceased date"
        ]
    },
    {
        date: "2018-12-06",
        version: "9.3.0",
        updates: [
            "When clicking on a measure name from the dashboard the unmet patient list will now default to incentivized patients",
            "Identified and fixed an issue with the QSR rounding up making the totals on the QSR incorrect",
            "Margins and spacing on the point of care PDF have been adjusted",
            "Users now have the option to multi-select risk ranks when generating a campaign or patient list based on risk rank"
        ]
    },
    {
        date: "2018-11-22",
        version: "9.2.0",
        updates: [
            "Resolved issues preventing the library reports from rendering",
            "Adjusted patient list csv export to correctly reflect the incentivized field",
            "Adjusted behavior of Diabetes/Hypertension blood pressure measures to mirror the clinical blood pressure data entered on the Point of Care form",
            "Merged duplicate patient lab feeds to prevent the same information displaying multiple times",
            "Added visual indicators to the patient list to identify deceased patients"
        ]
    },
    {
        date: "2018-11-08",
        version: "9.1.0",
        updates: [
            "Added non-compliant pop-up on hover for the red circle-with-exclamation-mark",
            "Restricted the maximum number of characters/digits within the patient information editable fields",
            "CMI column has now been added to the TOC table and TCM tracker",
            "Adolescent Weight Assessment due date has been fixed and should now reflect a correct due date",
            'When selecting declined or excluded for a measure the due date and compliance will stay the same since the patient has not "met"the measure. The patient will continue to show on the unmet list until the measure is truly met'
        ]
    },
    {
        date: "2018-11-01",
        version: "9.0.0",
        updates: [
            "At least one annual adolescent well care visit due dates were updated to accurately reflect due date.",
            "An issue with some QSR measures not populating a patient list when clicking on the needed for goal value has now been resolved",
            "Safe guards have been put in place to make sure certain measures are not auto-calculating or saving unless all required information is entered. i.e. BMI will no longer calculate if height and weight were not both entered with the same date of service, when recording a blood pressure the user is not able to save the changes unless both systolic and diastolic values are recorded."
        ]
    },
    {
        date: "2018-10-18",
        version: "8.11.0",
        updates: [
            "Pharmacy tab:  certain medications were displaying null null (kit) under the dose column this has been fixed to display kit.",
            "QSR filter enhancement; when a user navigates to a patient list, they can click the back button and filters applied will be retained. ",
            "TCM Tracker functionality has been improved to decrease lag time when loading the TCM tracker page and saving data entry.",
            "POC form has been fixed to include the priority attention section on the print out",
            "Print PDF: users are now able to download to PDF patient summary/measure list"
        ]
    },
    {
        date: "2018-10-12",
        version: "8.10.0",
        updates: [
            "QSR payer and product filters were able to be selected at the same time.",
            "Historical values in the PoC form were enhanced to be more accurate and clear.",
            "Assessment scores have been fixed and will now populate in the history tab.",
            "A real time refresh on hand-entered measures now applies on most measures. The Adult Access to Health Care and Social Needs Assessment measures do not have real time refresh yet."
        ]
    },
    {
        date: "2018-10-04",
        version: "8.9.0",
        updates: [
            "Measure due dates were updated to more accurately reflect the due date.",
            "Diabetic Blood Pressure measure updated for accuracy.",
            "Point of Care history detail tables were edited for improved ability to read information.",
            "Changed the word “Prescriber” back to “Provider”",
            "When printing required services from the Point of Care, only the Required Services will print.",
            "Corrections to the BCBSM Care Management eligibility list",
            "BCBSM Commercial goals were updated to align with payer goals",
            "PCP affiliated with manually entered services will now be assigned based on attributed PCP at the office",
            "QSR filters corrected so that Product and Payer can be selected simultaneously",
            "Transitions of Care data will now be refreshed in a real-time manner"
        ]
    },
    {
        date: "2018-09-13",
        version: "8.8.0",
        updates: [
            "BCN commercial goals have been refreshed to match payer goals",
            "The hypertension blood pressure measure has been refined to more closely match better the appropriate population for the denominator. ",
            "QSR printed visual now turns green when the goal has been reached"
        ]
    },
    {
        date: "2018-08-30",
        version: "8.7.0",
        updates: [
            "Primary payer and payer logo added to the ToC and TCM table",
            "Patient exclusionary measures will be visible on the Conditions tab of the POC page",
            "Report filters added to QSR report when exported to CSV",
            "Fixed missing columns from CSV export of patient list; Next appt date will now appear on the CSV export.",
            "PHQ9 numeric score will now display in the last value section",
            "Reminder letter updated to remove duplicate sentence",
            "Correction to the QSR table calculations to update correct totals"
        ]
    },
    {
        date: "2018-08-17",
        version: "8.6.0",
        updates: [
            "User sessions will time out after 50 minutes",
            "Height and weight values will now round to 2 decimal places.",
            "The Quality Dashboard that can be seen upon sign in is now printable from the Quality Summary Report page. When the user clicks on the “Print” button, they will have the opportunity print the Quality Summary Report, which is the table that can be seen from the Reports page, the Quality Summary Dashboard, which is the colorful set of bars that is seen on the Dashboard / initial sign on page, and the Data Coverage Report.",
            "Dates on the Point of Care page can be manually edited, instead of having to select a date from the calendar.",
            "Patients who are not attributed to a PCP/office by a payer but are coming to Health Focus via an EHR feed are now labelled within the Patient Lists with the payer identified as the EHR vendor or the office name. The “Product” label for these patients will be “Unknown” until further notice."
        ]
    },
    {
        date: "2018-08-09",
        version: "8.5.0",
        updates: [
            "Fixed the CSV download from the TCM Tracker to include all fields",
            "Added page numbers to the PDF download from the TCM Dashboard list"
        ]
    },
    {
        date: "2018-08-02",
        version: "8.4.0",
        updates: [
            "Add new Change Log Page (this page)",
            "Fixed bug where the Forced Logout notification stayed around after logging in again",
            "When Adding a New Patient, ethnicity and race are no longer required and country is set to US by default",
            "Added Patient Name to the Labs, Pharmacy and Claims page",
            "Added HCC Weight in the Conditions Section to the Point of Care PDF",
            "Quality Summary Report shows incentivized patients by default",
            "New button added to the Quality Summary Report to print a selection from: Quality Summary Report, Quality Summary Dashboard and the Coverages section",
            "Descriptions added to columns on the Labs, Pharmacy and Claims page",
            "Fixed measure in the POC form to assure that all have future due dates",
            "TCM: Fixed a bug to assure that all TCMs were flowing from the dashboard page to the TCM Tracker",
            "TCM: Increased the number of risk ranks showing on TCM patients",
            "TCM: Increased the flow of billing dates coming in through feeds on the TCM Tracker page",
            "TCM: Changed the name of unknown facilities to be Unknown or a blank space",
            "TCM: Added a column for discharge disposition to show if the patient is deceased, was discharged to home, a Skilled Nursing Facility, or somewhere else",
            "Fixed the Social Determinants of Health measure so that results could be manually entered",
            "Fixed the QSR chart so the trend line would show up when the incentivized filter is active",
            "Diabetes measures fixes so that measures are appropriately showing as met/unmet and due/not due",
            "Quality Summary Dashboard now limited to incentivized patients",
            "Care Management denominators fixed to only include those patients in care management."
        ]
    },
    {
        date: "2018-07-12",
        version: "8.3.0",
        updates: [
            "Display secondary insurance information when primary is unknown",
            "Indicate patients that are deceased on the Dashboard Transitions of Care",
            "Hide deceased patients from the TCM Tracker",
            "Indicate that a patient is deceased on their Point of Care Page",
            "Quality Summary Dashboard now allows you to see more than just the top 10"
        ]
    },
    {
        date: "2018-07-06",
        version: "8.2.0",
        updates: [
            "Changed Batch Print to download files in batches of 20",
            "For Community Linkage history, added username of last person who modified the record",
            "Fixed bug in Care Management Incentive Dashboard progress bar",
            "Quality Summary Dashboard, only display top 10 measures by Incentive Opportunity",
            "Fixed bug when editing TCM entry from the Point of Care Priority Attention section",
            "Fixed bug where the filter for Risk Rank was not properly applied to the patient list"
        ]
    }
]

const UpdateList = ({ updates, version }) => (
    <ul>
        {updates.map((update, i) => {
            const key = `${version}-${i}`
            if (_.isArray(update)) {
                return <UpdateList updates={update} key={key} version={key} />
            }
            return <li key={key}>{update}</li>
        })}
    </ul>
)

class ChangeLogPage extends React.Component {
    render() {
        return (
            <user_audit.components.default page={user_audit.constants.CHANGELOG}>
                <SiteTitle title="Changelog" />
                <div className="changelog">
                    <h1>
                        Change Log
                        <GuideLink page="view-change-log" />
                    </h1>
                    {_.sortBy(CHANGES, "date")
                        .reverse()
                        .map(({ date, version, updates }) => (
                            <div key={version} style={{ padding: "15px 0" }}>
                                <span>{dateFormat(date)}</span>
                                <h3 style={{ marginTop: "0" }}>
                                    Version {version}
                                </h3>
                                <UpdateList
                                    updates={updates}
                                    version={version}
                                />
                            </div>
                        ))}
                </div>
            </user_audit.components.default>
        )
    }
}

export default ChangeLogPage
