import React, { Fragment } from "react"
import { Field } from "redux-form"
import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import { PermissionManagement } from "../../../permission_managment/components/PermissionManagement"
import { SelectField } from "../../../core/CustomFields"
import OrgName from "../../../core/OrgName"
import * as C from "../../constants"

const headerStyle = {
    marginTop: "10px",
    marginBottom: "15px",
    marginLeft: "10px"
} // XXX this can probably move into CSS

const shouldDisableView = formValues =>
    _.get(formValues, C.HVPA_WIDE)
        ? "All users can already see the campaign"
        : undefined

const Permissions = ({
    owner,
    users,
    units,
    pinningOptions,
    superUser,
    formValues
}) => (
    <div>
        {superUser && (
            <Row>
                <Col sm={4}>
                    <Field
                        name={C.HVPA_WIDE}
                        component={SelectField}
                        notClearable
                        noHighlighting
                        options={[
                            {
                                label: (
                                    <Fragment>
                                        All <OrgName />
                                    </Fragment>
                                ),
                                value: true
                            },
                            { label: "Custom", value: false }
                        ]}
                    />
                </Col>
                {formValues && formValues[C.HVPA_WIDE] && (
                    <Col sm={8} md={6}>
                        <Field
                            name={C.TABLE_KEYS.PIN_TYPE}
                            component={SelectField}
                            options={pinningOptions || []}
                            noHighlighting
                        />
                    </Col>
                )}
            </Row>
        )}
        <div style={{ marginBottom: "20px" }}>
            <h3 style={headerStyle}>Users</h3>
            <Field
                name={C.TABLE_KEYS.USER_PRIVILEGES}
                component={PermissionManagement}
                loading={!users}
                disableView={shouldDisableView(formValues)}
                ownerId={owner}
            />
        </div>
        <div>
            <h3 style={headerStyle}>Practice Units</h3>
            <Field
                name={C.TABLE_KEYS.UNIT_PRIVILEGES}
                component={PermissionManagement}
                loading={!units}
                options={units || []}
                disableView={shouldDisableView(formValues)}
            />
        </div>
    </div>
)
export default Permissions
