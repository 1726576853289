import { LongCell, Payer, TimestampDate } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import * as C from "./constants"
import { ErrorCell } from "./components/CellRenderers"

const column = columnBuilder(C.TRANSLATIONS)

export const COLUMNS = [
    column(C.TABLE_KEYS.SOURCE_NAME, {
        cellRenderer: Payer,
        idField: C.TABLE_KEYS.SOURCE_ID,
        width: 180,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.FILE_NAME, {
        cellRenderer: LongCell,
        width: 200,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.COUNT, { width: 110 }),
    column(C.TABLE_KEYS.LOAD_DATE, { cellRenderer: TimestampDate, width: 170 }),
    column(C.TABLE_KEYS.ERROR, {
        cellRenderer: ErrorCell,
        width: 60
    })
]
