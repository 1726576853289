import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import { reducer as form } from "redux-form"
import { reducer as notifications } from "react-notification-system-redux"
import { reducer as resources } from "@healthfocus/redux-obtain"
import moment from "moment"

import { createReducer } from "./modules/utils"
import { REHYDRATE } from "./modules/constants"
import auth from "./modules/authentication"
import patient_list from "./modules/patient_list"
import alerts from "./modules/alerts"
import measures from "./modules/measures"
import campaigns from "./modules/campaigns"
import batch_print from "./modules/batch_print"
import info from "./modules/info"
import care_team from "./modules/care_team"
import patient_risk from "./modules/patient_risk"
import community_locations from "./modules/locations"
import add_new_patient from "./modules/add_new_patient"
import user_tags from "./modules/user_tags"
import patient_tags from "./modules/patient_tags"
import table from "./modules/table"
import table_settings from "./modules/table_settings"
import my_account from "./modules/my_account"
import admin from "./modules/admin"
import qsr from "./modules/qsr"
import qsr_dashboard from "./modules/qsr_dashboard"
import risk_dashboard from "./modules/risk_dashboard"
import outreach from "./modules/outreach_dashboard"
import awv from "./modules/awv"
import tcm from "./modules/tcm"
import toc from "./modules/toc"
import cost_utilization from "./modules/cost_utilization"
import cost_dashboard from "./modules/cost_dashboard"
import cost_report from "./modules/cost_report"
import patient_cost from "./modules/patient_cost"
import inactive_patients from "./modules/inactive_patients"
import dashboard_filters from "./modules/dashboard_filters"
import care_management from "./modules/care_management"
import reports_library from "./modules/reports_library"
import patient_audits from "./modules/patient_audits"
import documents from "./modules/documents"
import { TIMESTAMP_STRING } from "./modules/dates"

const rehydrated_reducer = createReducer(false, {
    [REHYDRATE]: () => true,
    [auth.constants.LOGOUT]: () => true,
    [auth.constants.FORCE_LOGOUT]: () => true
})
const server_status_reducer = createReducer(
    {},
    {
        PAGE_REFRESHED: (state, action) => action.payload
    }
)

const system_down_reducer = createReducer(false, {
    SYSTEM_ERROR: () => true // FIXME huh for some reason this value doesn't seem to end up in the store
})

const poc_id_reducer = createReducer(null, {
    SET_PATIENT_ID: (state, action) => action.payload
})

const nav_dimensions_reducer = createReducer(
    {},
    {
        STORE_NAV_DIMENSIONS: (state, { width, height }) => ({ width, height })
    }
)

const audits_viewed_reducer = createReducer(
    {},
    {
        [patient_audits.constants.LOG_AUDIT_VIEW]: state => ({
            previous: state.latest,
            latest: moment().format(TIMESTAMP_STRING)
        })
    }
)

const createRootReducer = history =>
    combineReducers({
        notifications,
        router: connectRouter(history),
        form,
        resources,
        [auth.constants.NAME]: auth.reducer,
        [table_settings.constants.NAME]: table_settings.reducer,
        [table.constants.TABLE_STORE]: table.reducer,
        [alerts.constants.NAME]: alerts.reducer,
        [batch_print.constants.NAME]: batch_print.reducer,
        [info.constants.NAME]: info.reducer,
        [qsr.constants.NAME]: qsr.reducer,
        [qsr_dashboard.constants.NAME]: qsr_dashboard.reducer,
        [risk_dashboard.constants.NAME]: risk_dashboard.reducer,
        [outreach.constants.NAME]: outreach.reducer,
        [care_team.constants.NAME]: care_team.reducer,
        [patient_risk.constants.NAME]: patient_risk.reducer,
        [patient_list.constants.GENERATE_PATIENT_LIST_FORM]:
            patient_list.reducers.generate_patient_list_reducer,
        applied_filters: patient_list.reducers.filter_reducer, // TODO extract "applied_filters" to patient_list.constants
        [campaigns.constants.NAME]: campaigns.reducer,
        [measures.constants.MEASURES]: measures.reducer.measuresReducer,
        [measures.constants.MEASURE_SNACKBAR]: measures.reducer.measureSnackbar,
        [measures.constants.ADD_NEW_MEASURES]:
            measures.reducer.addMeasureReducer,
        [cost_utilization.constants.NAME]: cost_utilization.reducer,
        [community_locations.constants.LOCATION_MODAL]:
            community_locations.reducer,
        [add_new_patient.constants.ADD_NEW_PATIENT]: add_new_patient.reducer,
        [user_tags.constants.NAME]: user_tags.reducer,
        [patient_tags.constants.NAME]: patient_tags.reducer,
        [my_account.constants.MY_PROFILE_NAME]: my_account.reducer,
        [admin.constants.ADMIN_NAME]: admin.reducer,
        [awv.constants.NAME]: awv.reducer,
        [tcm.constants.NAME]: tcm.reducer,
        [toc.constants.NAME]: toc.reducer,
        [cost_dashboard.constants.NAME]: cost_dashboard.reducer,
        [patient_cost.constants.NAME]: patient_cost.reducer,
        [cost_report.constants.NAME]: cost_report.reducer,
        [inactive_patients.constants.NAME]: inactive_patients.reducer,
        [dashboard_filters.constants.NAME]: dashboard_filters.reducer,
        [care_management.constants.NAME]: care_management.reducer,
        [reports_library.constants.NAME]: reports_library.reducer,
        [patient_audits.constants.NAME]: patient_audits.reducer,
        [documents.constants.NAME]: documents.reducer,
        rehydrated: rehydrated_reducer,
        server_status: server_status_reducer,
        system_down: system_down_reducer,
        poc_id: poc_id_reducer,
        nav_dimensions: nav_dimensions_reducer,
        [patient_audits.constants.PATIENT_AUDITS_VIEWED]: audits_viewed_reducer
    })

export default createRootReducer
