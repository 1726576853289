import React from "react"
import { Breadcrumb } from "react-bootstrap"

export const Breadcrumbs = ({ items }) => (
    <Breadcrumb>
        {items.map(([label, href], i) => {
            if (i === items.length - 1) {
                // i.e. this is the last item
                return (
                    <Breadcrumb.Item key={i} active>
                        {label}
                    </Breadcrumb.Item>
                )
            }
            return (
                <Breadcrumb.Item key={i} href={href}>
                    {label}
                </Breadcrumb.Item>
            )
        })}
    </Breadcrumb>
)

export default Breadcrumbs
