import moment from "moment"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { getResourceData, modifyResource } from "../core/fetcher"
import { ApiTypes, downloadFile, tryFetch } from "../api"
import { urlWithParams } from "../utils"
import { notifySimpleError } from "../notifications"
import { getSortedColumns } from "../selectorUtils"
import { TIMESTAMP_STRING_COMPACT } from "../dates"
import * as C from "./constants"
import { filtersAndCategoriesSelector } from "./selectors"
import { clearError, updateError } from "./actions"

const getCategory = row =>
    C.AWV_CATEGORY_LOOKUP[row[C.TABLE_KEYS.CATEGORY]]?.name

const FIELD_HANDLERS = {
    [C.TABLE_KEYS.SERVICE_DATE]: {
        getUrl: patientId => `/api/awv/${patientId}/last`,
        errorMsg: "Failed to update last AWV date"
    },
    [C.TABLE_KEYS.AWV_SCHEDULED_DATE]: {
        getUrl: patientId => `/api/awv/${patientId}/scheduled`,
        errorMsg: "Failed to update AWV scheduled date"
    }
}

export function* updateAwvDate({ payload }) {
    const { patientId, field, value } = payload

    const { getUrl, errorMsg } = FIELD_HANDLERS[field]
    yield* tryFetch(
        {
            url: getUrl(patientId),
            method: value ? ApiTypes.PUT : ApiTypes.DELETE,
            body: { field, value }
        },
        function*(response) {
            const { awvs } = yield select(getResourceData(C.NAME))
            const rowIndex = _.findIndex(awvs, {
                [C.TABLE_KEYS.PATIENT_ID]: patientId
            })
            const oldRow = awvs[rowIndex]
            const newRow = { ...oldRow, ...response.awv }

            const oldCategory = getCategory(oldRow)
            const newCategory = getCategory(newRow)

            yield put(
                modifyResource({
                    name: C.AWV_COUNTS,
                    dataTransform: counts =>
                        oldCategory === newCategory
                            ? counts
                            : {
                                  ...counts,
                                  [oldCategory]: counts[oldCategory] - 1,
                                  [newCategory]: counts[newCategory] + 1
                              }
                })
            )
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: data => ({
                        ...data,
                        awvs: data.awvs.toSpliced(rowIndex, 1, newRow)
                    })
                })
            )
            yield put(clearError(payload))
        },
        function*(error) {
            const errorMessage = error.message ?? errorMsg
            yield notifySimpleError(errorMessage)(error)
            yield put(
                updateError({
                    ...payload,
                    errorMessage
                })
            )
        }
    )
}

export function* exportAwv() {
    const filters = yield select(filtersAndCategoriesSelector)
    const sortDetails = yield select(getSortedColumns(C.AWV_TABLE))
    const endpoint = urlWithParams(`/api/awv/csv`, {
        ...filters,
        ...sortDetails
    })
    const filename = `awv_${moment().format(TIMESTAMP_STRING_COMPACT)}.csv`
    yield* downloadFile("AWV Worklist CSV", endpoint, filename)
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SAVE_AWV_DATE, updateAwvDate)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_AWV_CSV, exportAwv)
        })
    ])
}
