import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import CostUtilization from "../modules/cost_utilization"
import user_audit from "../modules/user_audits"

const UtilizationReportPage = () => (
    <user_audit.components.default page={user_audit.constants.UTILIZATION}>
        <SiteTitle title="Cost & Utilization Report" />
        <Breadcrumbs
            items={[["Dashboard", "/dashboard"], ["Cost & Utilization Report"]]}
        />
        <CostUtilization.components.default />
    </user_audit.components.default>
)

export default UtilizationReportPage
