import React from "react"
import {
    ErCell,
    IpCell,
    ObCell,
    SnCell
} from "../patient_list/components/CellRenderer"
import { FormattedDate } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import * as C from "./constants"

const ttCellRenderers = {
    IP: IpCell,
    ED: ErCell,
    OB: ObCell,
    SN: SnCell
}

const TransitionType = ({ cellData }) => {
    const Component = ttCellRenderers[cellData]
    return Component ? <Component cellData={cellData} /> : cellData
}

const column = columnBuilder(C.TRANSLATIONS)

export const TABLE_COLUMNS = [
    column(C.TABLE_KEYS.EVENT_TYPE, {
        cellRenderer: TransitionType,
        width: 100
    }),
    column(C.TABLE_KEYS.EVENT_DATE, {
        cellRenderer: FormattedDate,
        width: 100
    }),
    column(C.TABLE_KEYS.DISCHARGE_DATE, {
        cellRenderer: FormattedDate,
        width: 100
    }),
    column(C.TABLE_KEYS.FACILITY, {
        width: 400,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.DESCRIPTION, {
        width: 400,
        flexGrow: 2
    })
]
