import { createSelector } from "reselect"
import { getResourceData } from "../core/fetcher"
import * as SC from "../site_settings/constants"
import * as QC from "../qsr/constants"
import { SETTINGS } from "../constants"
import * as C from "./constants"

export const filtersSelector = createSelector(
    state => state[C.NAME].filters,
    getResourceData(SETTINGS, data => data[SC.RISK_DASHBOARD_PRODUCT]),
    (filters, defaultProduct) => ({
        [QC.FILTERS.PRODUCTS]: defaultProduct ? [defaultProduct] : undefined,
        ...filters
    })
)
