import React from "react"
import classNames from "classnames"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import Toggle from "../../core/Toggle"
import { tableFactory } from "../../table"
import { tableHeightForLength } from "../../utils"
import * as C from "../constants"
import { CARE_MANAGER_TABLE_COLUMNS } from "../columns"
import CareManagerModal from "./care_manager_modal"
import CareManagerButton from "./CareManagerButton"
import CellRenderer from "./CellRenderer"

const { Table, Settings, Description } = tableFactory({
    name: C.CARE_MANAGER_TABLE_NAME,
    columns: CARE_MANAGER_TABLE_COLUMNS,
    sortBy: [C.KEYS.EFF_DT],
    sortDirection: [SortDirection.DESC]
})

const CareManagers = ({
    careManagers,
    maxLength,
    metadata,
    setShowHistory,
    showHistory
}) => (
    <Card
        id="care_managers"
        className={classNames("card-table", {
            "showing-history": showHistory
        })}
    >
        <CareManagerModal />

        <Card.Header>
            <CardTitle>
                Care Manager{careManagers.length > 1 && "s"}
                <CareManagerButton className="fa-plus-circle" />
            </CardTitle>

            <Settings />

            <div className="show-history">
                <label className="show-history-label" htmlFor="showHistory">
                    Show Full History
                </label>
                <Toggle
                    name="showHistory"
                    checked={showHistory}
                    onChange={event => setShowHistory(event.target.checked)}
                />
            </div>
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC {...metadata} list={careManagers} hideEmpty>
                <Table
                    list={careManagers}
                    cellRenderer={CellRenderer}
                    rowClassNames={{
                        inactive: rowData => rowData[C.KEYS.INACTIVE]
                    }}
                    height={tableHeightForLength(maxLength)}
                />
            </LEHOC>
        </Card.Body>
    </Card>
)
export default CareManagers
