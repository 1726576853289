import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { useResizeDetector } from "react-resize-detector"
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import { isModuleEnabledSelector } from "../modules/moduleUtils"
import info from "../modules/info"
import comments from "../modules/comments"
import admissions from "../modules/admissions"
import attention from "../modules/attention"
import measures from "../modules/measures"
import campaigns from "../modules/campaigns"
import batch_print from "../modules/batch_print"
import care_team from "../modules/care_team"
import reminder_letter_details from "../modules/reminder_letter_details"
import patient_risk from "../modules/patient_risk"
import community_linkages from "../modules/community_linkages"
import patient_coverage from "../modules/patient_coverage"
import patient_cost from "../modules/patient_cost"
import patient_tags from "../modules/patient_tags"
import conditions from "../modules/conditions"
import user_audit from "../modules/user_audits"
import { MODULE_IDS } from "../modules/constants"
import { joinPath } from "../modules/utils"

export const PointOfCarePage = ({
    isModuleEnabled,
    poc_id,
    location,
    navHeight = 0
}) => {
    const { height: headerHeight = 0, ref } = useResizeDetector({
        refreshMode: "throttle",
        refreshRate: 100
    })
    const headerTop = navHeight - 1 // -1 because otherwise we get a 1px gap for some reason
    const shadowTop = headerTop + headerHeight

    const page = `${user_audit.constants.POC}/${poc_id || 0}`
    return (
        <user_audit.components.default page={page}>
            <Breadcrumbs
                items={[
                    ["Dashboard", "/dashboard"],
                    ["Patient List", "/patients"],
                    ["Point of Care"]
                ]}
            />
            <div className="poc-header" ref={ref} style={{ top: headerTop }}>
                <info.components.Name />
                <info.components.HeaderInfo />
                <div className="poc-buttons">
                    <Link to={joinPath(location?.pathname, "clp")}>
                        <Button variant="primary" size="sm">
                            <span className="fa fa-external-link" /> Utilization
                            Details
                        </Button>
                    </Link>

                    <measures.components.AddMeasureModal />
                    {isModuleEnabled(MODULE_IDS.EXTRACTS) && (
                        <batch_print.components.PrintPoc />
                    )}
                </div>
            </div>
            <div className="poc-header-shadow-box" style={{ top: shadowTop }} />
            <Row className="poc-row row-equal-columns">
                <Col xl={3} className="col-poc-info">
                        <info.components.Info />
                    </Col>
                    <Col xl={9}>
                        <Tabs
                            defaultActiveKey={1}
                            id="poc-tab-container"
                            className="poc-tabs"
                            mountOnEnter
                        >
                            <Tab
                                tabClassName="attention_tab"
                                eventKey={1}
                                title="Priority Attention"
                            >
                                <attention.components.default />
                            </Tab>
                        <Tab tabClassName="risk_tab" eventKey={2} title="Risk">
                                <patient_risk.components.default />
                            </Tab>
                            <Tab
                                tabClassName="conditions_tab"
                                eventKey={3}
                                title="Conditions"
                            >
                                <conditions.components.default />
                            </Tab>
                            <Tab
                                tabClassName="care_team_tab"
                                eventKey={4}
                                title="Care Team"
                            >
                                <care_team.components.default />
                            </Tab>
                            {isModuleEnabled(MODULE_IDS.COMMUNITY_LINKAGES) && (
                                <Tab
                                    tabClassName="community_linkages_tab"
                                    eventKey={5}
                                    title="Linkages"
                                >
                                    <community_linkages.components.default />
                                </Tab>
                            )}
                            <Tab
                                tabClassName="comments_tab"
                                eventKey={6}
                                title="Comments"
                            >
                                <comments.components.default />
                            </Tab>
                            {isModuleEnabled(MODULE_IDS.CAMPAIGNS) && (
                                <Tab
                                    tabClassName="campaigns_tab"
                                    eventKey={7}
                                    title="Campaigns"
                                >
                                    <campaigns.components.PatientCampaigns />
                                </Tab>
                            )}
                            <Tab
                                tabClassName="reminder_letter_tab"
                                eventKey={8}
                                title="Letters"
                            >
                                <reminder_letter_details.components.default />
                            </Tab>
                        <Tab tabClassName="tags_tab" eventKey={9} title="Tags">
                                <patient_tags.components.default />
                            </Tab>
                            {isModuleEnabled(MODULE_IDS.COST_UTILIZATION) && (
                                <Tab
                                    tabClassName="cost_tab"
                                    eventKey={10}
                                    title="Costs"
                                >
                                    <patient_cost.components.default />
                                </Tab>
                            )}
                            <Tab
                                tabClassName="coverage_tab"
                                eventKey={11}
                                title="Coverage"
                            >
                                <patient_coverage.components.default />
                            </Tab>
                        </Tabs>
                    </Col>
                <Col xl={12}>
                    <patient_tags.components.TagBadges />
                </Col>
                </Row>
            <measures.components.default />
            <admissions.components.default />
        </user_audit.components.default>
    )
}

export default connect(state => ({
    navHeight: state.nav_dimensions.height,
    isModuleEnabled: isModuleEnabledSelector(state)
}))(PointOfCarePage)
