import React from "react"
import { connect } from "react-redux"
import * as C from "../../constants"
import * as reduxActions from "../../actions"

export const EditCampaign = ({ cellData, rowData, deleteCampaign }) =>
    cellData && (
        <span
            className="fa fa-trash-o"
            onClick={() => deleteCampaign(rowData[C.TABLE_KEYS.CAMPAIGN_ID])}
        />
    )

export default connect(null, reduxActions)(EditCampaign)
