import { all, call, put, select, takeEvery } from "redux-saga/effects"
import * as C from "./constants"
import { modifyResource } from "../core/fetcher"
import { findAndUpdate } from "../utils"
import { ApiTypes, tryFetch } from "../api"
import { notifySimpleError } from "../notifications"
import { insertNewComment } from "./helpers"

export function* saveComment({ text }) {
    const poc_id = yield select(state => state.poc_id)
    const loginId = yield select(state => state.authentication.loginId)
    yield* tryFetch(
        {
            url: `/api/patients/${poc_id}/comments`,
            method: ApiTypes.POST,
            body: { comment: text }
        },
        function*(body) {
            const newComment = { ...body, loginId }
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: insertNewComment(newComment)
                })
            )
        },
        notifySimpleError("Unable to save comment")
    )
}

function* updateComment(userCommentId, update) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: data => ({
                ...data,
                comments: findAndUpdate(data.comments, update, {
                    userCommentId
                })
            })
        })
    )
}

function* deleteComment({ userCommentId }) {
    yield* tryFetch(
        {
            url: `/api/comments/${userCommentId}`,
            method: ApiTypes.DELETE
        },
        function*() {
            yield* updateComment(userCommentId, {
                removed: true,
                changingStatus: false
            })
        },
        notifySimpleError("Unable to delete comment.")
    )
}

function* restoreComment({ userCommentId }) {
    yield* tryFetch(
        {
            url: `/api/comments/${userCommentId}`,
            method: ApiTypes.PUT
        },
        function*() {
            yield* updateComment(userCommentId, {
                removed: false,
                changingStatus: false
            })
        },
        notifySimpleError("Unable to restore comment.")
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SAVE_COMMENT, saveComment)
        }),
        call(function*() {
            yield takeEvery(C.DELETE_COMMENT, deleteComment)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_COMMENT, restoreComment)
        })
    ])
}
