import * as CC from "../cost/constants"
import * as PC from "../pickers/constants"

export const NAME = "UTILIZATION_REPORT"

// forms
export const UTILIZATION_FILTERS_FORM = "UTILIZATION_FILTERS_FORM"
export const PRINT_UTILIZATION_FORM = "PRINT_UTILIZATION_FORM"

// tables
export const CU_COST_TABLE = "CU_COST_TABLE"
export const CU_UTILIZATION_TABLE = "CU_UTILIZATION_TABLE"

// actions
export const SET_UTILIZATION_FILTERS = "SET_UTILIZATION_FILTERS"
export const PRINT_UTILIZATION_PDF = "PRINT_UTILIZATION_PDF"
export const EXPORT_UTILIZATION_CSV = "EXPORT_UTILIZATION_CSV"

export const TABLE_KEYS = {
    ...CC.TABLE_KEYS,
    MONTH_ID: "monthId",
    YEAR: "yearId",
    EPISODES_CUMULATIVE: "episodesCumulative",
    PMPM_COST_CUMULATIVE: "costPmpmCumulative",
    RATE_CUMULATIVE: "rateCumulative",
    TOTAL_COST_CHANGE: "costTotalChange",
    TOTAL_VISITS_CHANGE: "episodeCountChange",
    AVERAGE_PRICE_CHANGE: "priceAverageChange",
    AVERAGE_PRICE: "priceAverage"
}
export const TABLE_TRANSLATION = {
    ...CC.TABLE_TRANSLATION,
    [TABLE_KEYS.MONTH_ID]: "Month",
    [TABLE_KEYS.YEAR]: "Year",
    [TABLE_KEYS.PATIENT_COUNT]: "Membership",
    [TABLE_KEYS.EPISODES_CUMULATIVE]: "Episodes (Cumulative)",
    [TABLE_KEYS.PMPM_COST_CUMULATIVE]: "Cost PMPM (Cumulative)",
    [TABLE_KEYS.RATE_CUMULATIVE]: "Rate (Cumulative)",
    [TABLE_KEYS.TOTAL_COST_CHANGE]: "% Change Total Cost",
    [TABLE_KEYS.TOTAL_VISITS_CHANGE]: "% Change Utilization",
    [TABLE_KEYS.AVERAGE_PRICE_CHANGE]: "% Change Average Price",
    [TABLE_KEYS.AVERAGE_PRICE]: "Average Price"
}

export const FILTERS = {
    ...CC.FILTERS,
    EXPORT_COST: "exportCost",
    EXPORT_UTILIZATION: "exportUtilization",
    EXPORT_RELATIVE: "exportRelative",
    EXPORT_ABSOLUTE: "exportAbsolute"
}

export const FILTER_TRANSLATION = {
    ...CC.FILTER_TRANSLATION,
    [FILTERS.EXPORT_COST]: "Cost",
    [FILTERS.EXPORT_UTILIZATION]: "Utilization",
    [FILTERS.EXPORT_RELATIVE]: "Relative",
    [FILTERS.EXPORT_ABSOLUTE]: "Absolute"
}

export const TABS = {
    COST: "cost",
    UTILIZATION: "utilization"
}
export const TAB_KEYS = {
    [TABS.COST]: FILTERS.EXPORT_COST,
    [TABS.UTILIZATION]: FILTERS.EXPORT_UTILIZATION
}

export const YEAR_KEYS = [
    TABLE_KEYS.YEAR,
    TABLE_KEYS.TOTAL_COST_CHANGE,
    TABLE_KEYS.TOTAL_VISITS_CHANGE,
    TABLE_KEYS.AVERAGE_PRICE_CHANGE
]

// noinspection JSCheckFunctionSignatures
export const DATE_FORMATTERS = {
    MONTH: x => x.toLocaleString("default", { month: "long" }),
    YEAR: x =>
        x.toLocaleString("default", {
            year: "numeric"
        })
}

export const TAB_TITLES = {
    [TABS.COST]: "Cost",
    [TABS.UTILIZATION]: "Utilization"
}

export const initialState = {
    filters: PC.PERIOD_MONTH_STRINGS[PC.PERIODS.CURRENT_YEAR]
}

export const PRINT_INITIAL_VALUES = {
    [FILTERS.EXPORT_COST]: false,
    [FILTERS.EXPORT_UTILIZATION]: false
}

export const COST_VIEW_LABELS = {
    [TABS.COST]: {
        [CC.COST_VIEWS.RELATIVE]: "Cost per Patient",
        [CC.COST_VIEWS.ABSOLUTE]: "Total Cost"
    },
    [TABS.UTILIZATION]: {
        [CC.COST_VIEWS.RELATIVE]: "Episodes per 1000 Patients",
        [CC.COST_VIEWS.ABSOLUTE]: "Total Utilization"
    }
}
