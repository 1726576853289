import * as C from "./constants"

export const addActiveNotification = payload => ({
    type: C.ADD_ACTIVE_NOTIFICATION,
    payload
})
export const clearAlertCache = () => ({ type: C.CLEAR_ALERT_CACHE })
export const setCache = payload => ({ type: C.SET_CACHE, payload })

export const createAlert = (payload, closeModal) => ({
    type: C.CREATE_ALERT,
    payload,
    closeModal
})
export const deleteAlert = alertId => ({ type: C.DELETE_ALERT, alertId })
