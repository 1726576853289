import fetcher from "../../core/fetcher"
import { CostChart } from "../../patient_list/components/PatientListCostChart"
import { PERIOD_MONTH_IDS, PERIODS } from "../../pickers/constants"
import * as PLC from "../../patient_list/constants"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import { transformAndFilterFilters } from "../../transformFilter"

import * as C from "../constants"

export default fetcher({
    name: PLC.PATIENT_LIST_COST_CHART,
    endpoint: state =>
        urlWithParams(
            "/api/patients/cost/chart",
            transformAndFilterFilters({
                ...state[C.NAME].filters,
                ...PERIOD_MONTH_IDS[PERIODS.LAST_12_MONTHS]
            })
        ),
    method: ApiTypes.GET
})(CostChart)
