import { all, call, select, takeEvery } from "redux-saga/effects"
import { downloadFile } from "../api"
import { getFilename, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import * as C from "./constants"

function* getCoverageReport(category, extension) {
    const sortedColumns = yield select(getSortedColumns(C.NAME))
    const fileNameCategory = _.lowerCase(category).replaceAll(" ", "_")

    yield* downloadFile(
        `${category} Data Coverage ${extension.toUpperCase()}`,
        urlWithParams(`/api/coverage/${category}/${extension}`, sortedColumns),
        getFilename(`data_coverage_${fileNameCategory}`, extension)
    )
}

export function* printPdf(action) {
    yield* getCoverageReport(action.category, "pdf")
}
export function* exportCsv(action) {
    yield* getCoverageReport(action.category, "csv")
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.PRINT_COVERAGE_PDF, printPdf)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_COVERAGE_CSV, exportCsv)
        })
    ])
}
