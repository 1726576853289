import React from "react"
import qsr from "../modules/qsr"
import user_audit from "../modules/user_audits"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"

const PerformanceReportPage = () => (
    <user_audit.components.default page={user_audit.constants.QSR}>
        <SiteTitle title="Quality Summary Report" />
        <Breadcrumbs
            items={[["Dashboard", "/dashboard"], ["Quality Summary Report"]]}
        />
        <qsr.components.QsrOptions />
        <qsr.components.default />
    </user_audit.components.default>
)

export default PerformanceReportPage
