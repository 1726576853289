import * as C from "./constants"

export const updateTag = payload => ({
    type: C.UPDATE_PATIENT_TAG,
    payload
})

export const reloadTags = () => ({
    type: C.RELOAD_TAGS
})

export const removeTag = payload => ({
    type: C.REMOVE_PATIENT_TAG,
    payload
})
export const restoreTag = payload => ({
    type: C.RESTORE_PATIENT_TAG,
    payload
})
