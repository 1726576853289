import React from "react"
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import _ from "lodash"

export const FilterButtonsHeader = ({
    categories = {},
    counts = {},
    name,
    onChange,
    values = {}
}) => {
    const totalWidth = _.sum(Object.values(counts))

    return (
        <ToggleButtonGroup
            className="btn-group-counters"
            name={name}
            type="checkbox"
            size="sm"
            value={values}
            onChange={onChange}
        >
            {Object.values(categories).map(category => (
                <ToggleButton
                    type="checkbox"
                    key={category.name}
                    value={category.name}
                    className={category.name}
                    variant={category.variant}
                    style={{
                        flexBasis: `${(100 * counts[category.name]) /
                            totalWidth}%`
                    }}
                >
                    {counts[category.name]}&nbsp;{category.label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}
