export const NAME = "PATIENT_TAGS"

export const RELOAD_TAGS = "RELOAD_TAGS"
export const UPDATE_PATIENT_TAG = "UPDATE_PATIENT_TAG"
export const REMOVE_PATIENT_TAG = "REMOVE_PATIENT_TAG"
export const RESTORE_PATIENT_TAG = "RESTORE_PATIENT_TAG"

export const TABLE_KEYS = {
    PATIENT_TAG_ID: "patientTagId",
    TAG_ID: "tagId",
    TAG: "tagName",
    SOURCE: "sourceName",
    SOURCE_DESCRIPTION: "sourceDescription",
    USER_ID: "userId",
    USER: "userName",
    CREATED_DATE: "createdDate",
    START_DATE: "startDate",
    END_DATE: "endDate",
    LAST_CHANGED_BY_NAME: "lastChangedByName",
    LAST_CHANGED_DATE: "lastChangedDate",
    ACTIONS: "actions",
    COMMENT: "comment"
}

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.TAG]: "Tag",
    [TABLE_KEYS.SOURCE]: "Source",
    [TABLE_KEYS.SOURCE_DESCRIPTION]: "Source",
    [TABLE_KEYS.USER]: "Added By",
    [TABLE_KEYS.CREATED_DATE]: "Date Added",
    [TABLE_KEYS.START_DATE]: "Start Date",
    [TABLE_KEYS.END_DATE]: "End Date",
    [TABLE_KEYS.LAST_CHANGED_BY_NAME]: "Last Changed By",
    [TABLE_KEYS.LAST_CHANGED_DATE]: "Last Changed",
    [TABLE_KEYS.COMMENT]: "Comment",
    [TABLE_KEYS.ACTIONS]: "Actions"
}

export const FILTERS = {
    PATIENT: "patientId",
    TAG: "tagId",
    START_DATE: "startDate",
    END_DATE: "endDate"
}

export const FILTER_TRANSLATION = {
    [FILTERS.PATIENT]: "Patient",
    [FILTERS.TAG]: "Tag",
    [FILTERS.START_DATE]: "Start Date",
    [FILTERS.END_DATE]: "End Date"
}
