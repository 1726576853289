import _ from "lodash"
import { createSelector } from "reselect"
import { getResourceData } from "./core/fetcher"
import { callOrTake, asArray } from "./utils"
import { SETTINGS } from "./constants"

const hasAccess = (moduleId, settings, roleId) => {
    const moduleIds = asArray(moduleId)

    if (!moduleId || _.isEmpty(moduleIds)) return true

    return _.at(settings.modules, moduleIds).some(module =>
        module?.roles?.includes(roleId)
    )
}
const hasAccessToAll = (moduleId, settings, roleId) => {
    const moduleIds = asArray(moduleId)

    if (!moduleId || _.isEmpty(moduleIds)) return true

    return _.at(settings.modules, moduleIds).every(module =>
        module?.roles?.includes(roleId)
    )
}

// note: this defaults to enabled
const moduleDisabled = (moduleId, settings, roleId) =>
    _.isEmpty(settings) ? false : !hasAccess(moduleId, settings, roleId)

// note: this defaults to disabled
const moduleEnabled = (moduleId, settings, roleId) =>
    _.isEmpty(settings) ? false : hasAccess(moduleId, settings, roleId)

const allModulesEnabled = (moduleId, settings, roleId) =>
    _.isEmpty(settings) ? false : hasAccessToAll(moduleId, settings, roleId)

// those but as selectors
const moduleSelector = func => moduleId =>
    createSelector(
        getResourceData(SETTINGS),
        state => state.authentication?.roleId,
        (settings, roleId) => func(moduleId, settings, roleId)
    )
export const moduleDisabledSelector = moduleSelector(moduleDisabled)
export const moduleEnabledSelector = moduleSelector(moduleEnabled)

// returns a function moduleId => boolean instead of just a boolean
const moduleFunctionSelector = func =>
    createSelector(
        getResourceData(SETTINGS),
        state => state.authentication?.roleId,
        (settings, roleId) => moduleId => func(moduleId, settings, roleId)
    )
export const isModuleDisabledSelector = moduleFunctionSelector(moduleDisabled)
export const isModuleEnabledSelector = moduleFunctionSelector(moduleEnabled)

/** If requiresAll is false, check if one of a list of modules is enabled.
 * If requiresAll is true, check if EVERY module in the list is enabled.
 * You only need this if you might be checking multiple modules at once.
 */
export const areModulesEnabledSelector = (requiresAllGetter, modulesGetter) =>
    createSelector(
        getResourceData(SETTINGS),
        state => state.authentication?.roleId,
        (settings, roleId) => item => {
            const requiresAll = requiresAllGetter(item)
            const func = requiresAll ? allModulesEnabled : moduleEnabled
            const modules = modulesGetter(item)
            return func(modules, settings, roleId)
        }
    )

// similar but for routes instead of modules
const routeEnabled = (route, props) => {
    // it's a little awkward how we switch back and forth between enabled and disabled... but we do it this way because a value that's not defined, which is usually falsy, instead means that the route *is* enabled

    const disabledByModule = props.isModuleDisabled(route.module)
    const disabledDirectly = callOrTake(props, route.disabled)

    return !disabledByModule && !disabledDirectly
}

const getActiveRoute = props => ({ fallback, ...route }) =>
    routeEnabled(route, props)
        ? route
        : !!fallback
        ? { ...route, redirect: fallback }
        : null

export const getActiveRoutes = (routes, props) =>
    _.compact(routes.map(getActiveRoute(props)))

// similar but for columns
export const moduleColumnsSelector = columns =>
    createSelector(isModuleEnabledSelector, isModuleEnabled =>
        _.filter(
            columns,
            column => !column.module || isModuleEnabled(column.module)
        )
    )
