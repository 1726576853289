import React from "react"
import { Form } from "react-bootstrap"
import classNames from "classnames"
import { getValidProps } from "../../core/helpers"
import { Label } from "../../core/CustomFields"
import { Hoverlay } from "../../core/Hoverlay"
import { ButtonGroup } from "./ButtonGroup"

export const PickerField = ({
    input,
    meta,
    label,
    disabled,
    disabledMessage,
    options,
    required,
    ...props
}) => {
    const validProps = getValidProps(input, meta)
    const { onChange, value, name } = validProps
    const picker = (
        <ButtonGroup
            {...props}
            disabled={disabled}
            value={value}
            onClick={onChange}
            options={options}
        />
    )
    return (
        <Form.Group className={classNames("picker", name)}>
            <Label {...validProps} label={label} required={required} />
            {disabledMessage ? (
                <Hoverlay disabled={!disabled} tooltip={disabledMessage}>
                    {picker}
                </Hoverlay>
            ) : (
                picker
            )}
        </Form.Group>
    )
}
