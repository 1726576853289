import { connect } from "react-redux"
import { fetcher } from "../../core/fetcher"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import {
    eligibleDateFallbackSelector,
    filtersAndCategoriesSelector
} from "../selectors"
import { AwvWorklist } from "./AwvWorklist"

export { default as AwvCounter } from "./AwvCounter"

export default fetcher({
    name: C.NAME,
    endpoint: state =>
        urlWithParams("/api/awv", filtersAndCategoriesSelector(state)),
    requestBodySelector: filtersAndCategoriesSelector,
    paginationKey: "awvs",
    method: ApiTypes.GET
})(
    connect(
        (state, { data }) => ({
            ...state[C.NAME],
            eligibleDateFallback: eligibleDateFallbackSelector(state),
            ...data
        }),
        actions
    )(AwvWorklist)
)
