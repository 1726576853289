import React from "react"
import classNames from "classnames"
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap"
import _ from "lodash"
import { getContrastTextColor } from "../utils"

// TODO make sure the hoverlay's tooltip can be read by a screenreader

/* If you want the hoverlay tooltip to not be directly on its trigger, you can pass
 * a ({ ref, ...triggerHandler }) => (...) function as the child, as per:
 * https://react-bootstrap.github.io/docs/components/overlays#customizing-trigger-behavior
 */
export class Hoverlay extends React.PureComponent {
    displayOverlay = props => {
        const OverlayComponent = this.props.isPopover ? Popover : Tooltip
        const id = this.props.isPopover ? "popover" : "tooltip"
        const overlayContent = this.getOverlayContent()

        // merge all the props from various sources
        const mergedProps = _.merge({}, props, this.props.overlayProps)

        mergedProps.className = classNames(
            props.className,
            this.props.className,
            this.props.overlayProps?.className
        )

        const { tooltipColor } = this.props
        if (tooltipColor) {
            mergedProps.style["--tooltip-color"] = tooltipColor
            mergedProps.style["--tooltip-text"] = getContrastTextColor(
                tooltipColor
            )
            // have to use this css-variable trick if we want to color the tooltip arrow (not just the body),
            // because you can't style pseudo-elements directly from js
        }

        return (
            <OverlayComponent id={id} {...mergedProps}>
                {overlayContent}
            </OverlayComponent>
        )
    }

    getOverlayContent = () =>
        _.isNil(this.props.tooltip) ? this.getContent() : this.props.tooltip
    // so passing 0 will display "0", but passing null or undefined will display the trigger's content.
    // (if you want it to not show at all, either pass "" or set disabled={true})

    displayContent = () => {
        const content = this.getContent()

        if (_.isFunction(content)) {
            /* If you want to customize rendering beyond what we can already do.
             * Function should look something like
             *   ({ ref, ...triggerHandler }) =>
             *   <TriggerElement {...triggerHandler>
             *       <TooltipAnchor ref={ref} />
             *   </TriggerElement>"
             */
            return content
        }
        if (
            React.isValidElement(content) &&
            content.type !== React.Fragment &&
            !this.props.forceWrapper
        ) {
            return content
        }

        // forceWrapper is mainly used when wrapping inputs that can be disabled (or otherwise have pointer-events: none). Disabled inputs don't dispatch events, but the wrapper can.
        const Wrapper = this.props.wrapper || "span"

        return (
            <Wrapper
                className={classNames(
                    "hoverlay-wrapper",
                    this.props.wrapperClassName
                )}
            >
                {content}
            </Wrapper>
        )
    }

    getContent = () => this.props.children || this.props.cellData

    getShow = () =>
        !this.props.disabled && this.props.tooltip !== "" && this.props.show
    // if the title is an empty string, then it should just not display at all
    // props.show will usually be undefined, letting the OverlayTrigger control itself

    render() {
        const {
            children,
            cellData,
            tooltip,
            isPopover,
            className,
            overlayProps,
            disabled,
            ...props
        } = this.props
        return (
            <OverlayTrigger
                placement="auto" // can be overwritten by props
                trigger={["click", "hover", "focus"]}
                rootClose
                {...props}
                popperConfig={{
                    modifiers: [
                        {
                            name: "preventOverflow",
                            options: { boundary: "window" }
                        }
                    ]
                }}
                overlay={this.displayOverlay}
                show={this.getShow()}
            >
                {this.displayContent()}
            </OverlayTrigger>
        )
    }
}
