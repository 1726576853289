import _ from "lodash"
import PageNotFound from "./views/PageNotFound"
import LoginPage from "./views/LoginPage"
import DashboardPage from "./views/DashboardPage"
import PatientListPage from "./views/PatientListPage"
import TcmPage from "./views/TcmPage"
import UtilizationDetailsRedirect from "./views/UtilizationDetailsRedirect"
import PointOfCareRedirect from "./views/PointOfCareRedirect"
import CampaignPage from "./views/CampaignPage"
import QSRPage from "./views/QSRPage"
import SystemDownPage from "./views/SystemDownPage"
import UtilizationReportPage from "./views/UtilizationReportPage"
import ReportsLibrary from "./views/ReportsLibrary"
import ReportsLibraryRedirect from "./views/ReportsLibraryRedirect"
import ChangeLog from "./views/ChangeLog"
import AdminPage from "./views/AdminPage"
import StatusPage from "./views/StatusPage"
import AlertsPage from "./views/AlertsPage"
import AthenaRedirect from "./views/AthenaRedirect"
import ExternalRedirect from "./views/ExternalRedirect"
import SettingsPage from "./views/SettingsPage"
import CostReportPage from "./views/CostReportPage"
import CareManagementPage from "./views/CareManagementPage"
import PatientAuditsPage from "./views/PatientAuditsPage"
import AwvPage from "./views/AwvPage"
import { DASHBOARD_MODULES, MODULE_IDS } from "./modules/constants"

export const loginRoute = {
    path: "/",
    component: LoginPage
}

export const systemDown = {
    path: "/system_down",
    component: SystemDownPage
}

export const statusRoute = {
    path: "/status",
    component: StatusPage
}

export const protectedRoutes = [
    {
        path: "/dashboard",
        component: DashboardPage,
        module: DASHBOARD_MODULES,
        fallback: "/patients"
    },
    {
        path: "/patients",
        component: PatientListPage
    },
    {
        path: "/care-management/:id",
        component: CareManagementPage
    },
    {
        path: "/tcm",
        component: TcmPage,
        module: MODULE_IDS.TCM_READ
    },
    {
        path: "/awv",
        component: AwvPage,
        module: MODULE_IDS.AWV
    },
    {
        path: "/patient-audits",
        component: PatientAuditsPage
        // TODO add a module for this
    },
    {
        path: "/poc/:id/clp",
        component: UtilizationDetailsRedirect,
        module: MODULE_IDS.POINT_OF_CARE
    },
    {
        path: "/poc/:id",
        component: PointOfCareRedirect,
        module: MODULE_IDS.POINT_OF_CARE
    },
    {
        path: "/campaigns",
        component: CampaignPage,
        module: MODULE_IDS.CAMPAIGNS
    },
    {
        path: "/reports/quality",
        component: QSRPage,
        module: MODULE_IDS.QUALITY_SUMMARY
    },
    {
        path: "/reports/library/:reportId",
        component: ReportsLibrary,
        module: MODULE_IDS.REPORTING
    },
    {
        path: "/reports/library",
        component: ReportsLibrary,
        module: MODULE_IDS.REPORTING
    },
    {
        path: "/reports/cost",
        component: CostReportPage,
        module: MODULE_IDS.COST_UTILIZATION
    },
    {
        path: "/reports/utilization",
        component: UtilizationReportPage,
        module: MODULE_IDS.COST_UTILIZATION
    },
    {
        path: "/reports",
        component: ReportsLibraryRedirect,
        module: MODULE_IDS.REPORTING
    },
    {
        path: "/changes",
        component: ChangeLog
    },
    {
        path: "/admin",
        component: AdminPage
    },
    {
        path: "/settings",
        component: SettingsPage
    },
    {
        path: "/alerts",
        component: AlertsPage
    },
    {
        path: "/athena-poc",
        component: AthenaRedirect
    },
    {
        path: "/external-poc",
        component: ExternalRedirect
    },
    {
        path: "",
        component: PageNotFound
    }
]

export const routeModules = _.keyBy(
    protectedRoutes.filter(route => route.module),
    route => route.path
)
