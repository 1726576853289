import * as C from "./constants"

export const getMinName = name => name + ".min"
export const getMaxName = name => name + ".max"
export const getMinLabel = name => "Min " + name
export const getMaxLabel = name => "Max " + name

export const getTableName = reportId => `${C.NAME}-${reportId}`

export const objToColumnValue = obj =>
    Object.entries(obj).map(([column, value]) => ({
        column,
        value
    }))

export const idToLabels = filterOptions => ({ column, value }) => {
    const filterOptionKey = C.DROPDOWN_OPTION_KEYS[column.toLowerCase()]
    return {
        column,
        value: filterOptionKey
            ? filterOptions[filterOptionKey]
                  .filter(({ value: fv }) => value.includes(fv))
                  .map(({ label }) => label)
            : value
    }
}
