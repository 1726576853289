import React from "react"
import { Button, Modal } from "react-bootstrap"
import Loading from "../../../core/Loading"
import * as C from "../../constants"
import { shouldDisablePrint } from "../../helpers"
import { PrintAssessments } from "../PrintAssessments"
import { PrintField } from "../PrintField"
import { PrintLetter } from "../PrintLetter"
import { PrintLinkages } from "../PrintLinkages"
import { PrintConditions } from "../PrintConditions"

export const PrintPocModal = ({
    formValues,
    onPrint,
    onHide,
    patientDeceased,
    printing,
    show_poc,
    ...props
}) => (
    <Modal className="batch-print" show={show_poc} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Print</Modal.Title>
        </Modal.Header>
        {!formValues || printing ? (
            <div style={{ padding: "20px", textAlign: "center" }}>
                <Loading />
                {printing && <p>Generating PDF...</p>}
            </div>
        ) : (
            <React.Fragment>
                <Modal.Body>
                    <PrintAssessments formValues={formValues} {...props} />
                    <PrintField name={C.PRINT_FIELDS.ATTENTION} />
                    <PrintConditions />
                    <PrintField name={C.PRINT_FIELDS.GRAPH} />
                    {!patientDeceased && (
                        // deceased patients don't need reminder letters
                        <PrintLetter formValues={formValues} {...props} />
                    )}
                    <PrintLinkages />
                    <PrintField name={C.PRINT_FIELDS.COST} />
                    {/* Cost period is taken from the Cost tab, so we don't need an input for that */}
                    <PrintField name={C.PRINT_FIELDS.FQHC} />
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        className="btn-modal-close"
                        onClick={onHide}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        className="btn-modal-save"
                        onClick={onPrint}
                        disabled={shouldDisablePrint(formValues)}
                    >
                        <span className="fa fa-print" /> Print
                    </Button>
                </Modal.Footer>
            </React.Fragment>
        )}
    </Modal>
)
