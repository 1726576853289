import { formValueSelector } from "redux-form"
import { SortDirection } from "react-virtualized"
import { createSelector, createSelectorCreator, defaultMemoize } from "reselect"
import _ from "lodash"
import { TABLE_STORE } from "./table/constants"
import { getResourceData } from "./core/fetcher"
import { callOrGet, takeUntil, valueLabelToObj } from "./utils"
import { FILTER_OPTIONS } from "./constants"

export const createDebugSelector = createSelectorCreator(defaultMemoize, {
    equalityCheck: (previousVal, currentVal) => {
        const rv = currentVal === previousVal
        if (!rv)
            console.log(
                "Selector param value changed",
                previousVal,
                "->",
                currentVal
            )
        return rv
    }
})

// easyFormValueSelector(formName, ...fields)(state)
// if only one field, it'll return its value; otherwise it'll return an object
export const easyFormValueSelector = (formName, ...fields) => {
    const selector = formValueSelector(formName)
    return createSelector(
        state => selector(state, ...fields),
        formValues => formValues,
        { memoizeOptions: { equalityCheck: _.isEqual } }
    )
}

// oneLineFormValueSelector(state, formName, ...fields)
export const oneLineFormValueSelector = createSelector(
    (state, formName, ...fields) =>
        formValueSelector(formName)(state, ...fields),
    formValues => formValues,
    { memoizeOptions: { equalityCheck: _.isEqual, maxSize: 63 } }
)

// fieldValueSelector(...fields)(state, formName)
export const fieldValueSelector = (...fields) =>
    createSelector(
        (state, formName) => formValueSelector(formName)(state, ...fields),
        formValues => formValues,
        { memoizeOptions: { equalityCheck: _.isEqual, maxSize: 63 } }
    )

/* If you sort on a pivot column, you need to sort on any preceding pivot columns first,
 * or else the row order can become unpredictable. The collapsed table would appear to be
 * out of order, because it'd be sorted by a column that wasn't actually visible. */
export const reorderSortColumns = (
    sortBy,
    selectedColumns,
    map = col => col.key
) => {
    const pivotKeys = _.uniq(
        selectedColumns.filter(col => col.canPivot).flatMap(map)
    )

    if (_.isEmpty(pivotKeys)) {
        return sortBy
    }

    const newSort = _.flatMap(sortBy, colKey =>
        pivotKeys.includes(colKey)
            ? [...takeUntil(pivotKeys, colKey), colKey]
            : colKey
    )

    return _.uniq(newSort)
}

export const getSortedColumns = (
    tableName,
    filter = col => col.selected,
    map = col => col.key
) => state => {
    const {
        frozenColumns = [],
        columns = [],
        sortBy = [],
        sortDirection = []
    } = _.get(state, [TABLE_STORE, tableName], {})

    const selectedColumns = [...frozenColumns, ...columns].filter(filter)

    const column = _.uniq(selectedColumns.flatMap(map))

    const sortMapping = _.fromPairs(
        _.zip(sortBy, sortDirection).filter(([col]) => !_.isFunction(col))
        // can't really send an entire function as a sort column, so let's skip those
    )

    const newOrder = reorderSortColumns(
        _.keys(sortMapping),
        selectedColumns,
        map
    )

    const sort = newOrder
        .map(col => [col, sortMapping[col]])
        .map(([col, dir]) => (dir === SortDirection.DESC ? `-${col}` : col))

    return {
        column,
        sort
    }
}

export const rowCountSelector = resource =>
    getResourceData(resource, data => (_.isNumber(data) ? data : 0))

export const optionsMapSelector = key =>
    createSelector(
        getResourceData(FILTER_OPTIONS, data => callOrGet(data, key)),
        valueLabelToObj
    )
