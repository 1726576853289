import React, { useEffect } from "react"
import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import LER from "../../core/LoadingErrorHOC"
import { ApiTypes } from "../../api"
import { SETTINGS } from "../../constants"
import * as C from "../constants"
import { fetchUserPreferences } from "../actions"

const ContentWrapper = ({ children, fetchUserPreferences, loading }) => {
    useEffect(() => {
        fetchUserPreferences()
    }, [])
    return (
        <div className="container-fluid main-content" id="main-content">
            <LER loading={loading}>{children}</LER>
        </div>
    )
}

export default fetcher({
    name: SETTINGS,
    endpoint: "/api/settings",
    method: ApiTypes.GET
})(
    connect(
        (state, { loading }) => ({ loading: loading || state[C.NAME].loading }),
        {
            fetchUserPreferences
        }
    )(ContentWrapper)
)
