import React from "react"
import { connect } from "react-redux"
import { Button, Card } from "react-bootstrap"
import moment from "moment"
import _ from "lodash"
import classNames from "classnames"
import fetcher from "../../core/fetcher"
import LER from "../../core/LoadingErrorHOC"
import { isExternal } from "../../authentication/selectors"
import { ApiTypes } from "../../api"
import { tableFactory, TableDescription } from "../../table"
import { isoToUsDate } from "../../dates"
import { AddTagsModal } from "../../patient_tags/components/AddTagsModal"
import * as ptActions from "../../patient_tags/actions"
import * as PC from "../../patient_tags/constants"
import * as C from "../constants"
import * as actions from "../actions"
import { PATIENT_TAG_COLUMNS } from "../columns"
import { acceptTagUsers } from "../transforms"

const { Table, Settings } = tableFactory({
    name: C.TAG_PATIENTS_TABLE,
    columns: PATIENT_TAG_COLUMNS
})

export class PatientTagsTable extends React.Component {
    state = { showModal: false, editingPatient: null }

    showEditPatient = editingPatient =>
        this.setState({ showModal: true, editingPatient })
    hideEditPatient = () =>
        this.setState({ showModal: false, editingPatient: null })

    getPatientField = field => _.get(this.state.editingPatient, [field])

    getTagOptions = () => [
        {
            value: this.props.currentTag[C.TABLE_KEYS.TAG_ID],
            label: this.props.currentTag[C.TABLE_KEYS.TAG_NAME]
        }
    ]
    getPatientOptions = () =>
        this.state.editingPatient
            ? [
                  {
                      value: this.state.editingPatient[C.TABLE_KEYS.PATIENT_ID],
                      label: this.state.editingPatient[
                          C.TABLE_KEYS.PATIENT_NAME
                      ]
                  }
              ]
            : undefined
    getTaggedPatientIds = () =>
        (this.props.patients || []).map(
            patient => patient[C.TABLE_KEYS.PATIENT_ID]
        )

    getInitialValues = () => {
        return {
            [PC.TABLE_KEYS.PATIENT_TAG_ID]: this.getPatientField(
                PC.TABLE_KEYS.PATIENT_TAG_ID
            ),
            [PC.FILTERS.TAG]: this.props.currentTag[C.TABLE_KEYS.TAG_ID],
            [PC.FILTERS.PATIENT]: this.getPatientField(C.TABLE_KEYS.PATIENT_ID),
            [PC.FILTERS.START_DATE]:
                isoToUsDate(this.getPatientField(C.TABLE_KEYS.START_DATE)) ||
                moment(),
            [PC.FILTERS.END_DATE]: isoToUsDate(
                this.getPatientField(C.TABLE_KEYS.END_DATE)
            )
        }
    }

    render() {
        return (
            <Card>
                <Card.Header className="card-header-group">
                    <TableDescription Component="p">
                        This table lists all of the patients with the "
                        {this.props.currentTag[C.TABLE_KEYS.TAG_NAME]}" tag.
                    </TableDescription>
                    <Settings />
                </Card.Header>
                    <LER loading={this.props.loading} error={this.props.error}>
                        <div
                            className={classNames(
                                "table-container",
                                this.props.disableEdit || "can-edit"
                            )}
                    >
                            <Table
                                list={this.props.patients}
                                rowClassNames={{
                                    removed_row: rowData => rowData.removed
                                }}
                                tableData={{
                                    disableEdit: this.props.disableEdit,
                                    onEdit: this.showEditPatient,
                                    onRemove: this.props.removeTag,
                                    onUndo: this.props.restoreTag,
                                onClickPatientName: this.props.hideUserTagsModal
                                }}
                                emptyMessage="There are no patients in this tag."
                            />
                        </div>
                        {this.props.disableEdit || (
                            <Button
                                className="add-tag"
                                variant="outline-primary"
                                size="sm"
                                onClick={() => this.showEditPatient(null)}
                            >
                                Add patient to tag
                            </Button>
                        )}
                    </LER>
                <AddTagsModal
                    show={this.state.showModal}
                    tagOptions={this.getTagOptions()}
                    patientOptions={this.getPatientOptions()}
                    initialValues={this.getInitialValues()}
                    onHide={this.hideEditPatient}
                    updateTag={this.props.updateTag}
                    taggedPatientIds={this.getTaggedPatientIds()}
                />
            </Card>
        )
    }
}

export default fetcher({
    name: C.TAG_DETAILS,
    endpoint: state =>
        `/api/tags/${state[C.NAME].currentTag[C.TABLE_KEYS.TAG_ID] || 0}`,
    method: ApiTypes.GET,
    acceptResponse: response => ({
        ...response,
        users: acceptTagUsers(response.users)
    })
})(
    connect(
        (state, { data: { patients } }) => ({
            ...state[C.NAME],
            patients,
            disableEdit: isExternal(state)
        }),
        {
            ...actions,
            ...ptActions
        }
    )(PatientTagsTable)
)
