import React from "react"
import { connect } from "react-redux"
import * as C from "../constants"
import { getResourceData } from "./fetcher"

const OrgName = ({ orgName }) => <React.Fragment>{orgName}</React.Fragment>

export default connect(state => ({
    orgName:
        getResourceData(
            C.SETTINGS,
            data => (data.organization || {}).abbrevName
        )(state) || ""
}))(OrgName)
