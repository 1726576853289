import React from "react"
import classNames from "classnames"
import { Hoverlay } from "../../core/Hoverlay"

export const CodeTile = ({ code, description, className, extra }) =>
    !!code && (
        <dd>
            <span
                className={classNames(
                    "code-tile",
                    { "code-tile-hoverable": !!description },
                    className
                )}
            >
                {description ? (
                    <Hoverlay
                        className="tooltip-darkblue"
                        tooltip={description}
                        placement="right"
                    >
                        {code}
                    </Hoverlay>
                ) : (
                    code
                )}
            </span>
            {extra}
        </dd>
    )
