import _ from "lodash"

export const ACCESS_PRIVILEGES = {
    EDIT: "M",
    READ: "R",
    M: "M", // M for Modify
    R: "R"
}
export const ACCESS_PRIVILEGES_SORT_ORDER = [
    ACCESS_PRIVILEGES.EDIT,
    ACCESS_PRIVILEGES.READ,
    undefined
]
export const ACCESS_PRIVILEGES_SORT_INDICES = _.invert(
    ACCESS_PRIVILEGES_SORT_ORDER
)

export const CLEAR_BUTTON = {
    privilege: undefined,
    className: "fa-close",
    title: "None"
}
export const READ_BUTTON = {
    privilege: ACCESS_PRIVILEGES.READ,
    className: "fa-eye",
    title: "Access"
}
export const EDIT_BUTTON = {
    privilege: ACCESS_PRIVILEGES.EDIT,
    className: "fa-pencil",
    title: "Edit"
}
export const PERMISSION_BUTTONS = {
    [undefined]: CLEAR_BUTTON,
    [ACCESS_PRIVILEGES.READ]: READ_BUTTON,
    [ACCESS_PRIVILEGES.EDIT]: EDIT_BUTTON
}
