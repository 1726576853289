import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SET_QSR_AGGREGATION]: (state, action) => ({
        ...state,
        aggregation: action.payload
    }),
    [C.SET_QSR_DASHBOARD_FILTERS]: (state, action) => ({
        ...state,
        filters: action.payload
    })
})

export default reducer
