import * as components from "./components"
import * as constants from "./constants"
import * as saga from "./saga"
import reducer from "./reducer"

export default {
    components,
    constants,
    saga,
    reducer
}
