import * as C from "./constants"

export const saveFavoriteFilters = (
    formName,
    skipFields,
    updatingSelected
) => ({
    type: C.SAVE_FAVORITE_FILTERS,
    formName,
    skipFields,
    updatingSelected
})
export const deleteFavoriteFilters = payload => ({
    type: C.DELETE_FAVORITE_FILTERS,
    payload
})
export const unDeleteFavoriteFilters = payload => ({
    type: C.UNDELETE_FAVORITE_FILTERS,
    payload
})
export const selectFavoriteFilters = (formName, payload) => ({
    type: C.SELECT_FAVORITE_FILTERS,
    formName,
    payload
})

export const stopSavingFilters = () => ({ type: C.STOP_SAVING_FILTERS })

export const removeDeletedFavorites = form => ({
    type: C.REMOVE_DELETED_FAVORITES,
    form
})

export const setShowFiltersModal = show => ({
    type: C.SHOW_FILTERS_MODAL,
    show
})
export const setShowFavoritesModal = show => ({
    type: C.SHOW_FAVORITES_MODAL,
    show
})
export const setShowFavoriteNameModal = show => ({
    type: C.SHOW_FAVORITE_NAME_MODAL,
    show
})
export const setShowEditFavoriteModal = show => ({
    type: C.SHOW_EDIT_FAVORITE_MODAL,
    show
})
