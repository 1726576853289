import React, { Component } from "react"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { change, getFormValues } from "redux-form"
import { Hoverlay } from "../../core/Hoverlay"
import { closeLocationModal, setLocationModeEdit } from "../actions"
import * as C from "../constants"

class LocationActions extends Component {
    selectLocation = () => {
        this.props.change(
            C.ADD_COMMUNITY_LINKAGE_FORM,
            C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION,
            this.props.rowData[
                C.ADD_COMMUNITY_LOCATION_FIELDS.COMMUNITY_LOCATION_ID
            ]
        )
        this.props.closeLocationModal()
    }
    editLocation = () =>
        this.props.setLocationModeEdit({ initialValues: this.props.rowData })

    render() {
        return (
            <div className="location-actions">
                <Hoverlay tooltip="Edit" placement="left">
                    <span
                        className="fa fa-pencil location-action-edit"
                        onClick={this.editLocation}
                    />
                </Hoverlay>
                {this.props.rowData.allowSelect &&
                    (this.props.linkageFormValues &&
                    this.props.linkageFormValues[
                        C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION
                    ] === this.props.rowData.communityLocationId ? (
                        <Button variant="default" size="sm" disabled>
                            Selected
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={this.selectLocation}
                        >
                            Select
                        </Button>
                    ))}
            </div>
        )
    }
}

export default connect(
    state => ({
        linkageFormValues: getFormValues(C.ADD_COMMUNITY_LINKAGE_FORM)(state)
    }),
    { setLocationModeEdit, closeLocationModal, change }
)(LocationActions)
