import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, reset } from "redux-form"
import _ from "lodash"
import LEHOC from "../../core/LoadingErrorHOC"
import fetcher from "../../core/fetcher"
import { moduleEnabledSelector } from "../../moduleUtils"
import { ApiTypes } from "../../api"
import { MODULE_IDS } from "../../constants"
import {
    flatMeasuresMapSelector,
    relevantCategoriesSelector,
    validate
} from "../selectors"
import * as reduxActions from "../actions"
import * as C from "../constants"
import AddMeasureModal from "./add_measure"
import MeasureCategory from "./MeasureCategory"
import SnackBar from "./SnackBar"

export { AddMeasureModal }

export class MeasureSection extends Component {
    // noinspection JSCheckFunctionSignatures
    shouldComponentUpdate(nextProps) {
        return !(
            _.isEqual(this.props.categories, nextProps.categories) &&
            this.props.loading === nextProps.loading &&
            this.props.error === nextProps.error
        )
    }

    render() {
        const {
            categories,
            handleSubmit,
            loading,
            error,
            change,
            disabled
        } = this.props
        return (
            <div
                id="segment-measures"
                role="tablist"
                className="widget compress-font card card-default card-group card-table"
                style={{ boxShadow: "none" }}
            >
                <LEHOC loading={loading} error={error} list={categories}>
                    <div className="poc_measures">
                        {categories.map(category => (
                            <MeasureCategory
                                key={category.name}
                                category={category}
                                actions={{ change }}
                                disabled={disabled}
                            />
                        ))}
                    </div>
                </LEHOC>
                <SnackBar handleSubmit={handleSubmit} />
            </div>
        )
    }
}

const ReduxMeasureForm = reduxForm({
    form: C.POC_MEASURE_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(MeasureSection)

export default connect(
    state => ({
        measures: flatMeasuresMapSelector(state),
        categories: relevantCategoriesSelector(state),
        initialValues: {},
        disabled: !moduleEnabledSelector(MODULE_IDS.POC_MEASURES)(state)
    }),
    { ...reduxActions, onSubmit: reduxActions.saveMeasures, reset }
)(
    fetcher({
        name: C.RELEVANT_MEASURES_TREE,
        endpoint: state => `/api/patients/${state.poc_id}/measures`,
        method: ApiTypes.GET
    })(ReduxMeasureForm)
)
