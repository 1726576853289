import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import fetcher, { getResourceData } from "../../core/fetcher"
import { NAME as INFO, KEYS as INFO_KEYS } from "../../info/constants"
import { isExternal } from "../../authentication/selectors"
import { ApiTypes } from "../../api"
import { FILTER_OPTIONS } from "../../constants"
import * as C from "../constants"
import * as actions from "../actions"
import { Tags } from "./Tags"
import { TagBadges as Badges } from "./TagBadges"

export const TagBadges = fetcher({
    name: C.NAME,
    method: ApiTypes.GET,
    endpoint: state => `/api/patients/${state.poc_id}/tags`,
    requestBodySelector: state => state[C.NAME].fetchCount
})(Badges)

// TagBadges is always visible on the POC but the Patient Tags tab lazy-loads, so the fetcher needs to go on the former

export default connect(
    state => ({
        tags: getResourceData(C.NAME, data => data.tags)(state),
        formValues: getFormValues(C.NAME)(state),
        tagOptions: getResourceData(
            FILTER_OPTIONS,
            x => (x.tags || []).filter(tag => !!tag.value) // tag 0 is All Patients, which is the same as leaving the filter blank
        )(state),
        patientOptions: getResourceData(INFO, data => [
            {
                value: data[INFO_KEYS.PATIENT_ID],
                label: data[INFO_KEYS.NAME]
            }
        ])(state),
        disableEdit: isExternal(state)
    }),
    actions
)(Tags)
