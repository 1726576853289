import { connect } from "react-redux"
import { getFormValues, reduxForm } from "redux-form"
import _ from "lodash"

import fetcher, { getResourceData } from "../../../core/fetcher"
import { NAME as INFO_NAME } from "../../../info/constants"
import { periodSelector } from "../../../patient_cost/selectors"
import { ApiTypes } from "../../../api"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import { transformServices } from "../../helpers"
import { pocAssessmentsSelector } from "../../selectors"
import PrintPoc from "./PrintPoc"

const PrintPocForm = reduxForm({
    form: C.POC_PRINT_MODAL_FORM,
    enableReinitialize: true
})(PrintPoc)

export default fetcher({
    name: C.SERVICES,
    endpoint: state => `/api/patients/${state.poc_id}/services`,
    method: ApiTypes.GET,
    acceptResponse: data => ({
        [C.PRINT_FIELDS.DUE_SERVICES]: data[C.PRINT_FIELDS.DUE_SERVICES].map(
            transformServices
        )
    })
})(
    connect((state, { data, initialValues }) => {
        return {
            printing: state[C.NAME].printing,
            poc_id: state.poc_id,
            patientDeceased: !!getResourceData(
                INFO_NAME,
                data => data.patDod
            )(state),
            patient_name: getResourceData(
                INFO_NAME,
                data => data.patName
            )(state),
            show_poc: state[C.NAME].show_poc,
            formValues: getFormValues(C.POC_PRINT_MODAL_FORM)(state),
            assessments: pocAssessmentsSelector(state),
            costPeriod: periodSelector(state),
            initialValues: {
                ...C.initialValues,
                [C.PRINT_FIELDS.DUE_SERVICES]: _.map(
                    data[C.PRINT_FIELDS.DUE_SERVICES],
                    item => item.value
                ),
                [C.PRINT_FIELDS.ASSESSMENTS]: [],
                ...initialValues
            },
            ...data
        }
    }, reduxActions)(PrintPocForm)
)
