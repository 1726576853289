import * as C from "./constants"

export const setAggregation = payload => ({
    type: C.SET_QSR_AGGREGATION,
    payload
})
export const setFilters = payload => ({
    type: C.SET_QSR_DASHBOARD_FILTERS,
    payload
})
export const printQSR = () => ({ type: C.PRINT_QSR_DASHBOARD })
export const exportQSR = () => ({ type: C.EXPORT_QSR_DASHBOARD })
