import { all, call, put, select, takeEvery } from "redux-saga/effects"
import _ from "lodash"
import moment from "moment"
import { modifyResource } from "../core/fetcher"
import { updateLocalTcm } from "../tcm/helpers"
import { DATE_STRING, SERVER_DATE_STRING } from "../dates"
import { ApiTypes, tryFetch } from "../api"
import { notifyError, notifySimpleError } from "../notifications"
import * as C from "./constants"

const startTcmRemoval = id =>
    put(
        modifyResource({
            name: C.NAME,
            dataTransform: updateLocalTcm({
                [C.TABLE_KEYS.REMOVED]: true,
                changingStatus: true
            })(id)
        })
    )

export function* updateField({ id, field, value, transitionId }) {
    if (_.isNil(id)) {
        const fieldName = C.TCM_TRANSLATION[field]
        return yield notifyError({
            title: `Unable to save ${fieldName} date.`,
            message: `This transition's TCM data is missing a "${fieldName}" field. This is a data error; please contact support.`
        })
    }

    yield* tryFetch(
        {
            url: `/api/tcm/${id}`,
            method: ApiTypes.PUT,
            body: {
                value
            }
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: updateLocalTcm({
                        [field]: value
                    })(transitionId)
                })
            )
        },
        notifySimpleError("Failed to update TCM")
    )
}

export function* sendToServer(action) {
    const { tcm } = action.payload
    const { initial, values } = yield select(
        state => state.form[C.TCM_POPOVER_FORM] || {}
    )

    if (!values || !initial) {
        return
    }
    for (let field of C.ENTRY_FIELDS) {
        if (values[field] !== initial[field]) {
            yield updateField({
                transitionId: tcm.transitionId,
                id: tcm[C.TCM_LOOKUP[field]],
                field,
                value: values[field]
                    ? moment
                          .utc(values[field], DATE_STRING)
                          .format(SERVER_DATE_STRING)
                    : null
            })
        }
    }
}

function* restorePA(action) {
    const id = action.payload

    yield startTcmRemoval(id)

    yield* tryFetch(
        {
            url: `/api/tcm/${id}/show`,
            method: ApiTypes.POST
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: updateLocalTcm({
                        [C.TABLE_KEYS.REMOVED]: false,
                        changingStatus: false
                    })(id)
                })
            )
        },
        notifySimpleError("Failed to restore priority attention entry")
    )
}

export function* removePA(action) {
    const id = action.payload

    yield startTcmRemoval(id)

    yield* tryFetch(
        {
            url: `/api/tcm/${id}/hide`,
            method: ApiTypes.POST
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: updateLocalTcm({
                        [C.TABLE_KEYS.REMOVED]: true,
                        changingStatus: false
                    })(id)
                })
            )
        },
        notifySimpleError("Failed to remove priority attention entry")
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.REMOVE_PA, removePA)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_PA, restorePA)
        }),
        call(function*() {
            yield takeEvery(C.SAVE_PA_TCM, sendToServer)
        })
    ])
}
