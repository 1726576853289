import * as C from "./constants"

export const setFilters = payload => ({
    type: C.SET_UTILIZATION_FILTERS,
    payload
})

export const printPdf = payload => ({ type: C.PRINT_UTILIZATION_PDF, payload })

export const exportCsv = payload => ({
    type: C.EXPORT_UTILIZATION_CSV,
    payload
})
