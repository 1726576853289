import React from "react"

export const deactivatedSuccess = patient => (
    <span>
        Marked patient <strong>{patient}</strong> for deactivation. <br />
        The patient will be removed after the next nightly reset.
    </span>
)
export const reactivatedSuccess = patient => (
    <span>
        Patient <strong>{patient}</strong> has been reactivated.
    </span>
)
