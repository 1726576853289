import React from "react"
import { FormGroup } from "react-bootstrap"
import PatientSelect from "../../core/PatientSelect"
import { Label } from "../../core/CustomFields"
import * as C from "../constants"
import { getValidProps } from "../../core/helpers"

export const PatientSelectField = props => {
    const validProps = getValidProps(props.input, props.meta)
    const { onChange, onBlur } = validProps
    return (
        <FormGroup>
            <Label
                {...validProps}
                label={C.FILTER_TRANSLATION[C.FILTERS.PATIENT]}
                required
            />
            <PatientSelect
                {...props}
                onChange={option => onChange(option.value)}
                onBlur={onBlur}
                single
            />
        </FormGroup>
    )
}
