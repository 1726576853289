import React from "react"
import { connect } from "react-redux"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"
import fetcher from "../../core/fetcher"
import LEHOC from "../../core/LoadingErrorHOC"
import { PERIOD_MONTH_IDS } from "../../pickers/constants"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import { modalTitleSelector } from "../selectors"
import { EncounterSection } from "./EncounterSection"

export const PatientCostModal = ({
    encounters,
    loading,
    error,
    title,
    show,
    onHide
}) => (
    <Modal show={show} onHide={onHide} className="patient-cost-modal">
        <Modal.Header closeButton>
            <Modal.Title>Encounters: {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <LEHOC list={encounters} loading={loading} error={error}>
                {encounters.map((encounter, index) => (
                    <EncounterSection key={index} encounter={encounter} />
                ))}
            </LEHOC>
        </Modal.Body>
        <Modal.Footer>
            <span>All displayed costs represent amounts allowed.</span>
            <Button variant="primary" onClick={onHide}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
)

export default fetcher({
    name: C.PATIENT_COST_DETAIL,
    method: ApiTypes.GET,
    endpoint: state =>
        urlWithParams(`/api/patients/${state.poc_id}/cost/encounters`, {
            ...PERIOD_MONTH_IDS[state[C.NAME][C.PERIOD]],
            ...state[C.NAME].modalFilters
        })
})(
    connect(
        (state, { data: { encounters = [] } }) => ({
            title: modalTitleSelector(state),
            encounters: _.sortBy(encounters, e => e.startDate).reverse() // sort by date descending
        }),
        actions
    )(PatientCostModal)
)
