import React from "react"
import { FormattedDate } from "../core/CellRenderers"
import { Hoverlay } from "../core/Hoverlay"
import { columnBuilder } from "../table"
import * as C from "./constants"

const column = columnBuilder(C.RISK_TRANSLATION)

const TABLE_COLUMNS = [
    column(C.TABLE_KEYS.SOURCE, {
        flexGrow: 1,
        width: 100
    }), // TODO this should probably have a hoverlay containing the source description
    column(C.TABLE_KEYS.TYPE_DESC, {
        flexGrow: 1,
        width: 140,
        cellRenderer: ({ cellData, rowData }) => (
            <div className="risk-cell-risk_type">
                <span className="cell-text" title={cellData}>
                    {cellData}
                </span>
                &nbsp;
                {rowData[C.TABLE_KEYS.INFO] && (
                    <Hoverlay
                        className="tooltip-blue"
                        tooltip={rowData[C.TABLE_KEYS.INFO]}
                    >
                        <span className="fa indicator-info" />
                    </Hoverlay>
                )}
            </div>
        )
    }),
    column(C.TABLE_KEYS.RESULT, {
        width: 60
    }),
    column(C.TABLE_KEYS.DATE, {
        width: 100,
        cellRenderer: FormattedDate
    }),
    column(C.TABLE_KEYS.RANK, { width: 110 }),
    column(C.TABLE_KEYS.USER, {
        flexGrow: 1,
        width: 60
    })
]

export default TABLE_COLUMNS
