import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { getFormSyncErrors, getFormValues, reduxForm } from "redux-form"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"

import { getResourceData } from "../../core/fetcher"
import { GuideLink } from "../../indicators"
import { FILTER_OPTIONS } from "../../constants"
import { encodeFiltersInUrl } from "../../utils"
import * as C from "../constants"
import * as reduxActions from "../actions"
import { defaultInitialValuesSelector, fixedScopeSelector } from "../selectors"
import FilterPanel from "./FilterPanel"

const Filter = reduxForm({
    form: C.GENERATE_PATIENT_LIST_FORM
})(FilterPanel)

export const GeneratePatientList = ({
    show,
    hideGeneratePL,
    url,
    options,
    errors,
    fixedScope,
    initialValues,
    formValues,
    limitMeasures,
    modalTitle,
    onSamePage
}) => (
    <Modal dialogClassName="large-modal" show={show} onHide={hideGeneratePL}>
        <Modal.Header closeButton>
            <Modal.Title>
                {modalTitle || "Generate Patient List"}
                <GuideLink page="generate-patient-list" className="white" />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "15px", backgroundColor: "#e4eefa" }}>
            <Filter
                options={options}
                fixedScope={fixedScope}
                initialValues={initialValues}
                formValues={formValues}
                limitMeasures={limitMeasures}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={hideGeneratePL}>
                Close
            </Button>
            {_.isEmpty(_.get(errors, C.FILTERS_SECTION)) ? (
                <Button
                    as={Link}
                    to={url}
                    variant="primary"
                    onClick={() => hideGeneratePL()}
                    replace={onSamePage}
                >
                    Generate
                </Button>
            ) : (
                <Button variant="primary" disabled>
                    Generate
                </Button>
            )}
        </Modal.Footer>
    </Modal>
)

const GeneratePLConnect = connect((state, { href = "/patients", options }) => {
    const formValues = getFormValues(C.GENERATE_PATIENT_LIST_FORM)(state)?.[
        C.FILTERS_SECTION
    ]
    return {
        formValues,
        options: options || getResourceData(FILTER_OPTIONS)(state),
        errors: getFormSyncErrors(C.GENERATE_PATIENT_LIST_FORM)(state),
        fixedScope: fixedScopeSelector(state),
        url: encodeFiltersInUrl(href, formValues, true),
        onSamePage: location.pathname === href
    }
})(GeneratePatientList)

export default GeneratePLConnect

export const GeneratePLFromMenu = connect(
    state => ({
        ...state[C.GENERATE_PATIENT_LIST_FORM],
        initialValues: {
            [C.FILTERS_SECTION]: defaultInitialValuesSelector(state)
        }
    }),
    reduxActions
)(GeneratePLConnect)
