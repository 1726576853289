import { all, call, select, takeLatest } from "redux-saga/effects"
import { transformAndFilterFilters } from "../transformFilter"
import { downloadFile } from "../api"
import { getFilename, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"

import { measureNameSelector } from "./selectors"
import * as C from "./constants"

const getDetails = state => ({
    ...transformAndFilterFilters(state[C.NAME].filters),
    ...getSortedColumns(C.PATIENTS)(state),
    cmMeasureName: measureNameSelector(state)
})

function* requestReport(extension) {
    const { measureId } = yield select(state => state[C.NAME])
    const details = yield select(getDetails)

    const endpoint = urlWithParams(`/api/patients/${extension}`, details)
    const filename = getFilename(`care_management_${measureId}`, extension)
    yield* downloadFile(
        `Care Management patients ${extension.toUpperCase()}`,
        endpoint,
        filename
    )
}

export function* exportPatientList() {
    yield requestReport("csv")
}

export function* printPatientList() {
    yield requestReport("pdf")
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeLatest(C.EXPORT_CM_PATIENT_LIST, exportPatientList)
        }),
        call(function*() {
            yield takeLatest(C.PRINT_CM_PATIENT_LIST, printPatientList)
        })
    ])
}
