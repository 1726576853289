import React from "react"
import classNames from "classnames"
import _ from "lodash"
import { InfoIndicator } from "../../../../indicators"
import { IncentiveMarker } from "./IncentiveMarker"
import { joinLines } from "../../../../utils"

const formatDesc = desc => {
    if (!desc) {
        return desc
    }
    const strDesc = String(desc)

    if (strDesc.search(/\\[rn]/g) < 0) {
        // if there's no \r or \n in the string, we don't have to do anything
        return desc
    }

    // replace each \n with a <br/>
    return joinLines(strDesc.replace(/\\r/g, "").split("\\n"))
}

const MeasureName = ({
    name,
    desc,
    link,
    loading,
    incentiveModuleActive,
    incentivizedByPayer,
    incentiveStatus,
    suppElig,
    suppIds
}) => {
    const incentivesExist = !_.isEmpty(incentivizedByPayer)
    const payersWithLogos = _.filter(
        _.compact(incentivizedByPayer), // we somehow get null entries sometimes?
        payer => payer.logo
    )
    const formattedDesc = formatDesc(desc)

    return (
        <p
            className={classNames("measure-text form-control-static", {
                "measure-incentivized": incentivesExist,
                disabled: loading
            })}
        >
            <span>{name}</span>

            <InfoIndicator
                as="a"
                tooltip={formattedDesc || "Measure description not available"}
                innerProps={{
                    href: link
                        ? `https://guide.healthfocus.io/measure_descriptions.html#${link}`
                        : undefined,
                    className: "guide-link-measure",
                    target: "_blank"
                }}
            />

            {incentivesExist && (
                <IncentiveMarker
                    incentiveModuleActive={incentiveModuleActive}
                    incentiveStatus={incentiveStatus?.[1]}
                    incentivizedByPayer={incentivizedByPayer}
                    payersWithLogos={payersWithLogos}
                    suppElig={suppElig}
                    suppIds={suppIds?.split(", ")}
                />
            )}
        </p>
    )
}

export default MeasureName
