import React, { useEffect } from "react"
import { connect } from "react-redux"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import campaigns from "../modules/campaigns"
import patient_list from "../modules/patient_list"
import product_chart from "../modules/product_graph"
import { moduleEnabledSelector } from "../modules/moduleUtils"
import { uncrush, urlParamsObject } from "../modules/utils"
import { MODULE_IDS } from "../modules/constants"

export const PatientListPage = ({
    costModuleEnabled,
    setPatientFilter,
    location,
    defaults,
    type,
    ...props
}) => {
    useEffect(() => {
        if (!location.search) {
            setPatientFilter(defaults)
            return
        }

        const params = urlParamsObject(location.search)
        if (!params.filters) {
            setPatientFilter(defaults)
            return
        }

        const filters = uncrush(params.filters)

        if (params.noDefaults) {
            setPatientFilter(filters)
        } else {
            setPatientFilter({
                ...defaults,
                ...filters
            })
        }
    }, [location.search])

    return (
        <>
            <SiteTitle title="Patient List" />

            <Breadcrumbs
                items={[["Dashboard", "/dashboard"], ["Patient List"]]}
            />
            <patient_list.components.Title>
                <campaigns.components.CreateCampaign usePatientList />
            </patient_list.components.Title>

            <patient_list.components.default {...props} />
            <product_chart.components.default />
            {costModuleEnabled && (
                <patient_list.components.PatientListCostChart />
            )}
        </>
    )
}

const costModuleEnabled = moduleEnabledSelector(MODULE_IDS.COST_UTILIZATION)
const chartModuleEnabled = moduleEnabledSelector(MODULE_IDS.SPENDING_CHART)

export default connect(
    state => ({
        costModuleEnabled:
            costModuleEnabled(state) && chartModuleEnabled(state),
        defaults: patient_list.selectors.defaultInitialValuesSelector(state)
    }),
    patient_list.actions
)(PatientListPage)
