import _ from "lodash"
import { createReducer } from "../utils"
import * as C from "./constants"
import { getErrorKey } from "./helpers"

const reducer = createReducer(C.initialState, {
    [C.TCM_UPDATE_ERROR]: (state, action) => {
        const { transitionId, field, error, errorMessage } = action.payload
        const errors = {
            ...state.errors,
            [getErrorKey(field, transitionId)]: errorMessage ?? error?.message
        }
        console.log(action.payload, errors)
        return { ...state, errors }
    },
    [C.TCM_CLEAR_ERROR]: (state, action) => {
        const { transitionId, field } = action.payload
        const errors = _.omit(state.errors, getErrorKey(field, transitionId))
        return { ...state, errors }
    },
    [C.SAVE_TCM_FILTERS]: (state, action) => ({
        ...state,
        filters: action.payload
    })
})

export default reducer
