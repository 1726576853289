import React, { Component } from "react"
import { connect } from "react-redux"
import * as C from "../constants"
import * as reduxActions from "../actions"
import LoginForm from "./LoginForm"

export { ClientLogo } from "./ClientLogo"

export class LoginContainer extends Component {
    handleSubmit = values => this.props.loginAction(values)

    render() {
        return (
            <div>
                <LoginForm
                    onSubmit={this.handleSubmit}
                    auth={this.props.auth}
                />
            </div>
        )
    }
}

export const Login = connect(
    state => ({ auth: state[C.NAME] }),
    reduxActions
)(LoginContainer)
