import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import utilization_details from "../modules/utilization_details"

const UtilizationDetailsPage = ({ poc_id }) => (
    <>
        <SiteTitle title="Utilization Details" />
        <Breadcrumbs
            items={[
                ["Dashboard", "/dashboard"],
                ["Patient List", "/patients"],
                ["Point of Care", `/poc/${poc_id}`],
                ["Utilization Details"]
            ]}
        />

        <utilization_details.components.default />
    </>
)

export default UtilizationDetailsPage
