import { LongCell } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"
import { FiltersCell } from "./components/FiltersCell"
import { FilterFavoriteActions } from "./components/FilterFavoriteActions"

const column = columnBuilder(C.TABLE_TRANSLATION)

export const FAVORITE_FILTER_COLUMNS = [
    column(C.TABLE_KEYS.NAME, {
        width: 100,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.FILTERS, {
        width: 200,
        flexGrow: 5,
        cellRenderer: FiltersCell
    }),
    column(C.TABLE_KEYS.ACTIONS, {
        width: 55,
        flexGrow: 0.1,
        align: ALIGNMENTS.CENTER,
        className: "filter-favorite-actions",
        disableSort: true,
        cellRenderer: FilterFavoriteActions
    })
]
