import { all, call, select, takeEvery } from "redux-saga/effects"
import { getFormValues } from "redux-form"

import { downloadFile } from "../api"
import { getFilename, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import { stampDepth } from "../table"

import { getTableFilters } from "./helpers"
import * as C from "./constants"

const getQsrDetails = (state, { expanded, ...additionalDetails }) => {
    const filter = getTableFilters(getFormValues(C.QUALITY_SUMMARY_FORM)(state))
    const level = stampDepth(expanded) + 1 // +1 because nothing expanded is still level 1
    const sortedColumns = getSortedColumns(C.QUALITY_SUMMARY_REPORT_TABLE)(
        state
    )

    return {
        ...filter,
        level,
        ...sortedColumns,
        ...additionalDetails
    }
}

export function* requestQSRReport(details, label, path, filename) {
    const endpoint = urlWithParams(path, details)
    yield* downloadFile(label, endpoint, filename)
}

function* requestMainPageQsrReport(action, extension) {
    const payload = action ? action.payload : null

    yield* requestQSRReport(
        yield select(getQsrDetails, payload),
        "QSR " + extension.toUpperCase(),
        "/api/qsr/" + extension,
        getFilename("qsr", extension)
    )
}

export function* printQSR(action) {
    yield* requestMainPageQsrReport(action, "pdf")
}

export function* exportQSR(action) {
    yield* requestMainPageQsrReport(action, "csv")
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.PRINT_QSR, printQSR)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_QSR, exportQSR)
        })
    ])
}
