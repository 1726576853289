import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import { PatientCoverage } from "./PatientCoverage"

export default fetcher({
    name: C.NAME,
    method: ApiTypes.GET,
    endpoint: state => `/api/patients/${state.poc_id}/coverage`,
    acceptResponse: data => ({
        coverages: data.coverages.map(coverage => ({
            ...coverage,
            [C.TABLE_KEYS.TERM_45]: coverage[C.TABLE_KEYS.STATUS] !== "Active" // XXX I'm not sure what the value for term-45 state is, because no patient on dev actually has it
        }))
    })
})(PatientCoverage)
