import { createReducer, sizeExactly1 } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SET_QSR_FILTERS]: (state, { payload }) => ({
        ...state,
        filters: payload,
        ...(sizeExactly1(payload[C.FILTERS.MEASURES]) && {
            chartMeasure: _.head(payload[C.FILTERS.MEASURES])
        })
    }),
    [C.SET_QSR_CHART_MEASURE]: (state, { payload }) => ({
        ...state,
        chartMeasure: payload
    })
})

export default reducer
