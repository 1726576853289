import React from "react"
import { Button, Modal } from "react-bootstrap"
import { Field } from "redux-form"

import LEHOC from "../../../core/LoadingErrorHOC"
import {
    fixedDigits,
    maxLength,
    mustBeANumber,
    required,
    validPhoneNumber
} from "../../../core/validators"
import {
    CreatableSelectField,
    SelectField,
    PhoneNumber,
    TextInput
} from "../../../core/CustomFields"

import * as C from "../../constants"
import { AddPatientFieldset } from "../../../add_new_patient/components/AddPatientFieldset"

export const DemographicsForm = ({
    handleHide,
    handleSubmit,
    loading,
    invalid,
    options: { races, ethnicities, sexes, genders, orientations }
}) => (
    <div>
        <Modal.Header closeButton>
            <Modal.Title>Edit Patient Information</Modal.Title>
        </Modal.Header>
        <LEHOC loading={loading}>
            <Modal.Body className="patient-info-edit">
                <AddPatientFieldset name="Background" colCount={3}>
                    <Field
                        name={C.KEYS.RACE_ID}
                        component={SelectField}
                        options={races}
                    />
                    <Field
                        name={C.KEYS.ETHNICITY_ID}
                        component={SelectField}
                        options={ethnicities}
                    />
                </AddPatientFieldset>

                <AddPatientFieldset name="Gender and Sexuality" colCount={3}>
                    <Field
                        name={C.KEYS.SEX_ID}
                        component={SelectField}
                        options={sexes}
                    />
                    <Field
                        name={C.KEYS.GENDER}
                        component={CreatableSelectField}
                        options={genders}
                        validate={required}
                        required
                        multi
                    />
                    <Field
                        name={C.KEYS.ORIENTATION}
                        component={CreatableSelectField}
                        options={orientations}
                        multi
                    />
                </AddPatientFieldset>

                <AddPatientFieldset name="Contact" colCount={3}>
                    <Field
                        name={C.KEYS.ADDRESS_1}
                        component={TextInput}
                        validate={[required, maxLength(128)]}
                        required
                    />
                    <Field
                        name={C.KEYS.ADDRESS_2}
                        component={TextInput}
                        validate={maxLength(128)}
                    />
                    <Field
                        name={C.KEYS.CITY}
                        component={TextInput}
                        validate={[required, maxLength(64)]}
                        required
                    />
                    <Field
                        name={C.KEYS.STATE}
                        component={SelectField}
                        options={C.STATE_OPTIONS}
                        validate={required}
                        required
                        notClearable
                    />
                    <Field
                        name={C.KEYS.ZIP}
                        component={TextInput}
                        validate={[required, fixedDigits(5), mustBeANumber]}
                        required
                    />
                    <Field
                        name={C.KEYS.PHONE}
                        component={PhoneNumber}
                        validate={validPhoneNumber}
                    />
                    {/* XXX really, no email? What if the patient's email changes? */}
                </AddPatientFieldset>
            </Modal.Body>
        </LEHOC>
        {!loading && (
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={invalid}
                >
                    Save
                </Button>
            </Modal.Footer>
        )}
    </div>
)
