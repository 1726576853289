import _ from "lodash"
import { objToValueLabel } from "../utils"

export const NAME = "AWV_WORKLIST"
export const AWV_COUNTS = "AWV_COUNTS"
export const AWV_TABLE = "AWV_TABLE"
export const AWV_FILTERS_FORM = "AWV_FILTERS_FORM"

export const AWV_MEASURE_ID = 219

// actions
export const SET_AWV_FILTERS = "SET_AWV_FILTERS"
export const SET_AWV_CATEGORIES = "SET_AWV_CATEGORIES"
export const SAVE_AWV_DATE = "SAVE_AWV_DATE"
export const UPDATE_AWV_ERROR = "UPDATE_AWV_ERROR"
export const CLEAR_AWV_ERROR = "CLEAR_AWV_ERROR"
export const EXPORT_AWV_CSV = "EXPORT_AWV_CSV"

// table stuff
export const FILTERS = {
    PATIENT_NAME: "patientName",
    PAYERS: "payers",
    SOURCES: "sources",
    PRODUCTS: "productClasses",
    PROVIDERS: "providers",
    PRACTICE_UNITS: "units",
    TAGS: "tags",
    REGIONS: "regions",
    DECEASED: "deceased",
    NON_PHYSICIAN_PCP: "nonPhysicianPcp",
    SERVICE_DATE_FROM: "serviceDateFrom",
    SERVICE_DATE_TO: "serviceDateTo",
    AWV_ELIGIBLE_DATE_FROM: "awvEligibleDateFrom",
    AWV_ELIGIBLE_DATE_TO: "awvEligibleDateTo",
    AWV_SCHEDULED_DATE_FROM: "awvScheduledDateFrom",
    AWV_SCHEDULED_DATE_TO: "awvScheduledDateTo",
    PCP_RENDERED: "pcpRendered",
    FLAGS: "flags",
    FLAG_MANUAL: "flagManual",
    FLAG_EMR: "flagEmr",
    FLAG_PAYER: "flagPayer",
    COST: "cost",
    COST_MIN: "costMin",
    COST_MAX: "costMax",
    COST_SPAN: "costSpan",
    RISK_RANK: "riskRank",
    HCC_CONDITIONS: "hccConditions",
    HCC_CONDITIONS_MET: "hccConditionsMet"
}

export const DATE_FILTERS = [
    FILTERS.SERVICE_DATE_FROM,
    FILTERS.SERVICE_DATE_TO,
    FILTERS.AWV_ELIGIBLE_DATE_FROM,
    FILTERS.AWV_ELIGIBLE_DATE_TO,
    FILTERS.AWV_SCHEDULED_DATE_FROM,
    FILTERS.AWV_SCHEDULED_DATE_TO
]

export const TABLE_KEYS = {
    PATIENT_ID: "patientId",
    PATIENT_NAME: "patName",
    PATIENT_GENDER: "patGender",
    PATIENT_DOB: "patDob",
    PHONE: "telephone",
    SOURCE_ID: "sourceId",
    SOURCE_NAME: "sourceName",
    PROVIDER_ID: "providerId",
    PROVIDER_NAME: "providerName",
    NON_PHYSICIAN_PCP: "nonPhysicianPcp",
    PRACTICE_ID: "practiceUnitId",
    PRACTICE_NAME: "practiceUnitName",
    PAYER_ID: "payerId",
    PAYER_NAME: "payerName",
    PRODUCT_CLASS_ID: "productClassId",
    PRODUCT_CLASS_NAME: "productClassName",
    EFF_DATE: "effDate",
    END_DATE: "endDate",
    CONTRACT_NUMBER: "contractNumber",
    MBI: "mbi",
    SERVICE_DATE: "serviceDt",
    SERVICE_CODE: "serviceCode",
    FLAG_MANUAL: "flagManual",
    FLAG_EMR: "flagEmr",
    FLAG_PAYER: "flagPayer",
    PCP_RENDERED: "pcpRendered",
    RENDERING_PROVIDER_NPI: "renderingProviderNpi",
    RENDERING_PROVIDER_NAME: "renderingProviderName",
    RENDERING_PRACTICE_NAME: "renderingPracticeName",
    MEASURE_COMPLIANCE: "measureCompliance",
    AWV_ELIGIBLE: "awvEligible",
    AWV_ELIGIBLE_DATE: "awvEligibleDate",
    AWV_SCHEDULED: "awvScheduled",
    AWV_SCHEDULED_DATE: "awvScheduledDate",
    AWV_APPOINTMENT_TYPE: "awvAppointmentType",
    AWV_APPOINTMENT_NOTE: "awvAppointmentNote",
    COST_YTD: "costYtd",
    COST_12_MONTH: "costTwelveMonth",
    PATIENT_HCC: "patientHcc",
    RISK_RANK: "riskRank",
    RISK_NUM: "riskNum",
    HCC_CONDITIONS: "hccConditions",
    HCC_CONDITIONS_MET: "hccConditionsMet",
    RAF_TOTAL: "rafTotal",
    RAF_MET: "rafMet",
    HCC_REDOC_NUM: "hccRedocNum",
    HCC_REDOC_RAF: "hccRedocRaf",
    HCC_REDOC_NUM_REMAINING: "hccRedocNumRemaining",
    HCC_REDOC_RAF_REMAINING: "hccRedocRafRemaining",
    MEASURE_HISTORY: "measureHistory",
    CATEGORY: "category"
}

export const FILTER_TRANSLATION = {
    [FILTERS.PATIENT_NAME]: "Patient Name",
    [FILTERS.PAYERS]: "Payers",
    [FILTERS.SOURCES]: "Interfaces",
    [FILTERS.PRODUCTS]: "Product Classes",
    [FILTERS.PROVIDERS]: "Primary Care Provider",
    [FILTERS.PRACTICE_UNITS]: "Practice Units",
    [FILTERS.TAGS]: "User Tags",
    [FILTERS.REGIONS]: "Practice Tags",
    [FILTERS.DECEASED]: "Deceased",
    [FILTERS.NON_PHYSICIAN_PCP]: "Non Physician PCP",
    [FILTERS.SERVICE_DATE_FROM]: "Last AWV Date From",
    [FILTERS.SERVICE_DATE_TO]: "Last AWV Date To",
    [FILTERS.AWV_ELIGIBLE_DATE_FROM]: "AWV Eligible Date From",
    [FILTERS.AWV_ELIGIBLE_DATE_TO]: "AWV Eligible Date To",
    [FILTERS.AWV_SCHEDULED_DATE_FROM]: "AWV Scheduled Date From",
    [FILTERS.AWV_SCHEDULED_DATE_TO]: "AWV Scheduled Date To",
    [FILTERS.PCP_RENDERED]: "PCP Rendered",
    [FILTERS.FLAGS]: "Flags",
    [FILTERS.COST]: "Cost",
    [FILTERS.COST_MIN]: "Min Cost",
    [FILTERS.COST_MAX]: "Max Cost",
    [FILTERS.COST_SPAN]: "Cost Period",
    [FILTERS.RISK_RANK]: "Risk Rank",
    [FILTERS.HCC_CONDITIONS]: "HCC Conditions",
    [FILTERS.HCC_CONDITIONS_MET]: "HCC Conditions Met"
}

export const FLAG_LABELS = {
    [FILTERS.FLAG_MANUAL]: "Manual",
    [FILTERS.FLAG_EMR]: "EMR",
    [FILTERS.FLAG_PAYER]: "Payer"
}
export const FLAG_OPTIONS = objToValueLabel(FLAG_LABELS)

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.PATIENT_ID]: "Patient ID",
    [TABLE_KEYS.PATIENT_NAME]: "Patient",
    [TABLE_KEYS.PATIENT_GENDER]: "Gender",
    [TABLE_KEYS.PATIENT_DOB]: "DOB",
    [TABLE_KEYS.PHONE]: "Patient Phone",
    [TABLE_KEYS.SOURCE_NAME]: "Interface",
    [TABLE_KEYS.PROVIDER_NAME]: "PCP",
    [TABLE_KEYS.NON_PHYSICIAN_PCP]: "Non-physician PCP",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice",
    [TABLE_KEYS.PAYER_NAME]: "Payer",
    [TABLE_KEYS.PRODUCT_CLASS_NAME]: "Product",
    [TABLE_KEYS.EFF_DATE]: "Eff Date",
    [TABLE_KEYS.END_DATE]: "End Date",
    [TABLE_KEYS.CONTRACT_NUMBER]: "Contract Number",
    [TABLE_KEYS.MBI]: "MBI",
    [TABLE_KEYS.SERVICE_DATE]: "Last AWV Date",
    [TABLE_KEYS.SERVICE_CODE]: "Last Code",
    [TABLE_KEYS.FLAG_MANUAL]: "Flag Manual",
    [TABLE_KEYS.FLAG_EMR]: "Flag EMR",
    [TABLE_KEYS.FLAG_PAYER]: "Flag Payer",
    [TABLE_KEYS.PCP_RENDERED]: "PCP Rendered",
    [TABLE_KEYS.RENDERING_PROVIDER_NPI]: "Rendering Provider NPI",
    [TABLE_KEYS.RENDERING_PROVIDER_NAME]: "Rendering Provider",
    [TABLE_KEYS.RENDERING_PRACTICE_NAME]: "Rendering Practice",
    [TABLE_KEYS.MEASURE_COMPLIANCE]: "Measure Compliance",
    [TABLE_KEYS.AWV_ELIGIBLE]: "AWV Eligible",
    [TABLE_KEYS.AWV_ELIGIBLE_DATE]: "AWV Eligible Date",
    [TABLE_KEYS.AWV_SCHEDULED]: "AWV Scheduled",
    [TABLE_KEYS.AWV_SCHEDULED_DATE]: "AWV Scheduled Date",
    [TABLE_KEYS.AWV_APPOINTMENT_TYPE]: "AWV Appointment Type",
    [TABLE_KEYS.AWV_APPOINTMENT_NOTE]: "AWV Appointment Note",
    [TABLE_KEYS.COST_YTD]: "Cost YTD",
    [TABLE_KEYS.COST_12_MONTH]: "Cost Rolling Year",
    [TABLE_KEYS.PATIENT_HCC]: "Patient HCC",
    [TABLE_KEYS.RISK_NUM]: "Patient HCC Strat",
    [TABLE_KEYS.HCC_REDOC_NUM]: "HCC Redoc #",
    [TABLE_KEYS.HCC_REDOC_RAF]: "HCC Redoc RAF",
    [TABLE_KEYS.HCC_REDOC_NUM_REMAINING]: "HCC Redoc # Remaining",
    [TABLE_KEYS.HCC_REDOC_RAF_REMAINING]: "HCC Redoc RAF Remaining",
    [TABLE_KEYS.MEASURE_HISTORY]: "Measure History"
}

// etc

export const AWV_CATEGORIES = {
    INELIGIBLE: {
        name: "ineligible",
        label: "ineligible",
        variant: "outline-danger",
        code: "I"
    },
    ELIGIBLE: {
        name: "eligible",
        label: "eligible",
        variant: "outline-warning",
        code: "E"
    },
    SCHEDULED: {
        name: "scheduled",
        label: "scheduled",
        variant: "outline-success",
        code: "S"
    },
    COMPLETED: {
        name: "completed",
        label: "completed",
        variant: "outline-primary",
        code: "C"
    }
}
export const AWV_CATEGORY_LOOKUP = _.mapKeys(
    AWV_CATEGORIES,
    category => category.code
)
export const AWV_NAME_LOOKUP = _.mapKeys(
    AWV_CATEGORIES,
    category => category.name
)

export const initialState = {
    filters: {
        [FILTERS.DECEASED]: false
    },
    categories: [
        AWV_CATEGORIES.INELIGIBLE.name,
        AWV_CATEGORIES.ELIGIBLE.name,
        AWV_CATEGORIES.SCHEDULED.name
    ],
    errors: {}
}
