import * as C from "./constants"

export const exportLibrary = reportId => ({
    type: C.EXPORT_LIBRARY,
    reportId
})
export const printLibrary = reportId => ({
    type: C.PRINT_LIBRARY,
    reportId
})
export const setLibraryFilters = (reportId, filters) => ({
    type: C.SET_LIBRARY_FILTERS,
    reportId,
    filters
})
