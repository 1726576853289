import React from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Field } from "redux-form"

import LEHOC from "../../core/LoadingErrorHOC"
import { PasswordField, SelectField, TextInput } from "../../core/CustomFields"
import UserDisclaimer from "../../core/UserDisclaimer"

import * as C from "../constants"
import { GuideLink } from "../../indicators"

// Note that all validation is done in validateUser.js

const MyAccountFields = ({ isAdmin, loading, roleOptions }) => (
    <Row>
        {/* We could use UserInfoDecoys here to prevent autocomplete, but since all the data here belongs to the current user, autocomplete actually makes sense here. */}
        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.FIRST_NAME}
                disabled={loading || isAdmin}
                component={TextInput}
                required={!isAdmin}
            />
        </Col>
        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.LAST_NAME}
                disabled={loading || isAdmin}
                component={TextInput}
                required={!isAdmin}
            />
        </Col>

        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.LOGIN_ID}
                disabled={true}
                component={TextInput}
                props={{ autoComplete: "username" }}
            />
        </Col>
        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.NPI}
                disabled={true}
                component={TextInput}
            />
        </Col>

        <Col lg={12}>
            <Field
                name={C.USER_FIELDS.EMAIL}
                disabled={loading || isAdmin}
                component={TextInput}
                required={!isAdmin}
                props={{ autoComplete: "email" }}
            />
        </Col>

        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.USER_STATUS}
                disabled={true}
                component={SelectField}
                options={C.ADD_USER_STATUS_OPTIONS}
            />
        </Col>

        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.ROLE}
                disabled={true}
                component={SelectField}
                options={roleOptions}
            />
        </Col>

        <Col lg={12}>
            <Field
                name={C.USER_FIELDS.CURRENT_PASSWORD}
                component={PasswordField}
                disabled={loading}
                autoComplete="chrome-off"
            />
        </Col>

        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.NEW_PASSWORD}
                component={PasswordField}
                disabled={loading}
            />
        </Col>
        <Col lg={6}>
            <Field
                name={C.USER_FIELDS.CONFIRM_PASSWORD}
                component={PasswordField}
                disabled={loading}
            />
        </Col>

        <Col lg={12}>
            <UserDisclaimer />
        </Col>
    </Row>
)

const MyAccountModal = ({
    handleSubmit,
    hideMyProfile,
    invalid,
    isAdmin,
    loading,
    pristine,
    roleOptions,
    saving,
    show,
    updateMyProfile
}) => (
    <Modal dialogClassName="small-modal" show={show} onHide={hideMyProfile}>
        <form onSubmit={handleSubmit(updateMyProfile)} autoComplete="off">
            <Modal.Header closeButton>
                <Modal.Title>
                    My Account
                    <GuideLink page="view-my-account" className="white" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LEHOC loading={loading}>
                    <MyAccountFields
                        loading={loading}
                        isAdmin={isAdmin}
                        roleOptions={roleOptions}
                    />
                </LEHOC>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="pull-left"
                    onClick={hideMyProfile}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    disabled={loading || saving || pristine || invalid}
                    variant="primary"
                    className="pull-right"
                >
                    Save
                </Button>
            </Modal.Footer>
        </form>
    </Modal>
)
export default MyAccountModal
