import { useEffect } from "react"
import { logUserAudit } from "../actions"
import { connect } from "react-redux"

export const UserAudit = ({ children, info, logUserAudit, page }) => {
    useEffect(() => {
        logUserAudit({
            page: page,
            info: info
        })
    }, [page, info])

    return children
}

export default connect(null, { logUserAudit })(UserAudit)
