import _ from "lodash"
import {
    alphanumeric,
    errorRecorder,
    maxLength,
    required,
    validEmail
} from "../../../core/validators"
import * as C from "../../constants"

export function validatePasswords(values, props) {
    const errors = {}
    const recordErrors = errorRecorder(errors, values, props)

    if (_.has(props, ["registeredFields", C.USER_FIELDS.CURRENT_PASSWORD])) {
        recordErrors([currentPassword], C.USER_FIELDS.CURRENT_PASSWORD)
    }
    recordErrors([newPassword], C.USER_FIELDS.NEW_PASSWORD)
    recordErrors([confirmPassword], C.USER_FIELDS.CONFIRM_PASSWORD)

    return _.pickBy(errors) // i.e. only the ones with truthy values
}

export const validateUser = (values, props) => {
    const errors = validatePasswords(values, props)
    const recordErrors = errorRecorder(errors, values, props)

    recordErrors([required, maxLength(64)], C.USER_FIELDS.FIRST_NAME)
    recordErrors([required, maxLength(64)], C.USER_FIELDS.LAST_NAME)
    recordErrors([required, validEmail, maxLength(255)], C.USER_FIELDS.EMAIL)

    return _.pickBy(errors) // i.e. only the ones with truthy values
}

export const validateEditUser = (values, props) => {
    const errors = validateUser(values, props)
    const recordErrors = errorRecorder(errors, values, props)

    recordErrors([required, confirmDelete], C.USER_FIELDS.USER_STATUS)
    recordErrors([required], C.USER_FIELDS.ROLE)

    recordErrors([userUnits(props.getAccessLevel)], C.USER_FIELDS.USER_UNITS)
    recordErrors(
        [primaryPractice(props.getAccessLevel)],
        C.USER_FIELDS.PRIMARY_PRACTICE
    )

    return _.pickBy(errors) // i.e. only the ones with truthy values
}

export const validateAddUser = (values, props) => {
    const errors = validateEditUser(values, props)
    const recordErrors = errorRecorder(errors, values, props)

    recordErrors(
        [required, alphanumeric, maxLength(15)],
        C.USER_FIELDS.LOGIN_ID
    )
    recordErrors([required], C.USER_FIELDS.NEW_PASSWORD)

    return _.pickBy(errors) // i.e. only the ones with truthy values
}

const userUnits = getAccessLevel => (userUnits, user) =>
    getAccessLevel(user) !== C.EXTERNAL_VALUE && _.isEmpty(userUnits)
        ? "Please select at least one practice unit."
        : undefined
const primaryPractice = getAccessLevel => (pp, user) =>
    getAccessLevel(user) === C.EXTERNAL_VALUE || pp
        ? undefined
        : "Please select a primary practice."

export const currentPassword = (currentPassword, allValues) =>
    (allValues[C.USER_FIELDS.NEW_PASSWORD] ||
        allValues[C.USER_FIELDS.CONFIRM_PASSWORD]) &&
    !currentPassword
        ? "Current password is required to change password"
        : undefined
export const newPassword = (newPassword, allValues) =>
    (allValues[C.USER_FIELDS.CURRENT_PASSWORD] ||
        allValues[C.USER_FIELDS.CONFIRM_PASSWORD]) &&
    !newPassword
        ? "New password cannot be empty"
        : undefined
export const confirmPassword = (confirmPassword, allValues) =>
    allValues[C.USER_FIELDS.NEW_PASSWORD] &&
    allValues[C.USER_FIELDS.NEW_PASSWORD] !== confirmPassword
        ? "New and confirmed password must match"
        : undefined

export const confirmDelete = (userStatus, allValues) =>
    userStatus === C.DELETED && !allValues[C.USER_FIELDS.CONFIRM_DELETE]
        ? 'You must check the "Confirm Delete" box when deleting a user'
        : undefined
