import { connect } from "react-redux"
import { isExternal } from "../../authentication/selectors"
import { userCanEditTagSelector } from "../selectors"
import * as C from "../constants"
import * as actions from "../actions"
import { UserTags } from "./UserTags"

export default connect(
    state => ({
        ...state[C.NAME],
        userIsExternal: isExternal(state),
        userCanEditTag: userCanEditTagSelector(state)
    }),
    actions
)(UserTags)
