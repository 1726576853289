import { all, call, takeEvery, select, put } from "redux-saga/effects"
import { modifyResource } from "../core/fetcher"
import { ApiTypes } from "../api"
import { tryWithNotifications } from "../notifications"
import * as C from "./constants"
import * as actions from "./actions"

function* addRiskDetails({ payload }) {
    const poc_id = yield select(state => state.poc_id)

    yield tryWithNotifications(
        {
            url: `/api/patients/${poc_id}/riskRanks`,
            method: ApiTypes.POST,
            body: payload
        },
        function*(body) {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: () => body
                })
            )
            yield put(actions.hideRiskModal())
        },
        function*() {
            yield put(actions.stopSaving())
            return "The new risk could not be saved."
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.ADD_RISK_DETAILS, addRiskDetails)
        })
    ])
}
