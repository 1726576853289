import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import cost_report from "../modules/cost_report"

const CostReportPage = () => (
    <React.Fragment>
        <SiteTitle title="Cost Report" />
        <Breadcrumbs items={[["Dashboard", "/dashboard"], ["Cost Report"]]} />
        <cost_report.components.default />
    </React.Fragment>
)

export default CostReportPage
