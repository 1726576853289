import React, { Component } from "react"
import { itemsToObject } from "../../../../../utils"
import * as C from "../../../../constants"
import DisplayRow from "./DisplayRow"
import EditRow from "./EditRow"

// FIXME whole lot of direct references to fields in here instead of reference via constants

const getError = (value, dataType, minValue, maxValue, name) => {
    switch (dataType) {
        case C.NUMERIC:
        case C.ALPHANUMERIC:
            if (!value && value !== 0) {
                return `${name} Value is required`
            }
            if (isNaN(Number(value))) {
                return `${name} Not a number`
            }
            if (value > maxValue) {
                return `${name} Less than ${maxValue}`
            }
            if (value < minValue) {
                return `${name} Greater than ${minValue}`
            }
            break
        default:
            return null
    }
} // TODO this doesn't account for how systolic BP must be bigger than diastolic
//    can we maybe just use the actual validators instead? however those are determined

export default class HistoricalItem extends Component {
    state = {
        editing: false,
        saving: false,
        values: {
            [this.props.measureId]:
                this.props.dataType === C.ENUMERATION
                    ? this.props.codeDescId
                    : this.props.numericVal || this.props.textVal,
            ...(this.props.dataType === C.COMPOSITE &&
                itemsToObject(
                    this.props.subMeasureValues,
                    item => item.measureId,
                    item => item.numericVal
                ))
        }
    }

    onChange = e =>
        this.setState({
            values: {
                ...this.state.values,
                [this.props.measureId]: e.target.value
            }
        })
    onCompositeChange = (e, measureId) =>
        this.setState(
            {
                values: {
                    ...this.state.values,
                    [measureId]: Number(e.target.value)
                }
            },
            () => {
                const newValue = this.props.subMeasureValues
                    .map(item => item.measureId)
                    .map(id => this.state.values[id])
                    .filter(item => !!item)
                    .join(" / ")
                this.setState({
                    values: {
                        ...this.state.values,
                        [this.props.measureId]: newValue
                    }
                })
            }
        )
    edit = () => {
        this.setState({
            editing: true
        })
    }
    cancel = () => {
        this.setState({ editing: false })
    }
    delete = () => {
        this.setState({ saving: true })
        this.props.removeHistoricalItem({
            measureValueLogId: this.props.measureValueLogId,
            patientId: this.props.patientId,
            measureId: this.props.measureId
        })
    }
    save = () => {
        this.setState({ saving: true })
        return this.props.saveEdits({
            measureValueLogId: this.props.measureValueLogId,
            measureId: this.props.measureId,
            dataType: this.props.dataType,
            dropListOptions: this.props.dropListOptions,
            values: this.state.values,
            logDt: this.props.logDt,
            subMeasures: this.props.subMeasureValues?.map(
                this.getSubmeasureValueData
            )
        })
    }

    getSubmeasureValueData = (subMeasureValue, index) => {
        const dataType = this.props.subMeasures[index].dataType // TODO find a way to merge props.subMeasures and props.subMeasureValues into a single prop, so we can get the datatype directly from subMeasureValue instead of looking it up in props.subMeasures
        return {
            measureId: subMeasureValue.measureId,
            measureValueLogId: subMeasureValue.measureValueLogId,
            numericVal: [C.NUMERIC, C.ALPHANUMERIC].includes(dataType)
                ? this.state.values[subMeasureValue.measureId]
                : null
        }
    }

    getErrors = () => {
        switch (this.props.dataType) {
            case C.NUMERIC:
            case C.ALPHANUMERIC:
                return _.pickBy({
                    [this.props.measureId]: getError(
                        this.state.values[this.props.measureId],
                        this.props.dataType,
                        this.props.minValue,
                        this.props.maxValue,
                        ""
                    )
                })
            case C.COMPOSITE:
                return _.pickBy(
                    itemsToObject(
                        this.props.subMeasures,
                        submeasureDef => submeasureDef.id,
                        submeasureDef =>
                            getError(
                                this.state.values[submeasureDef.id],
                                submeasureDef.dataType,
                                submeasureDef.minValue,
                                submeasureDef.maxValue,
                                submeasureDef.name
                            )
                    )
                )
            default:
        }
        return {}
    }

    render() {
        const sourceIsHealthFocus =
            this.props.sourceName === C.HEALTH_FOCUS_SOURCE_NAME

        const moduleIsEnabled = this.props.moduleIsEnabled ?? true

        const belongsToUser =
            this.props.isSuperuser ||
            this.props.currentUser.toLowerCase() ===
                this.props.loginId.toLowerCase()

        const permitted =
            sourceIsHealthFocus && moduleIsEnabled && belongsToUser

        const sharedProps = {
            oddRow: this.props.oddRow,
            userSource: sourceIsHealthFocus
                ? this.props.loginId
                : this.props.sourceName,
            serviceCodeSystem: this.props.serviceCodeSystem,
            serviceCode: this.props.serviceCode,
            serviceDt: this.props.serviceDt,
            logDt: this.props.logDt,
            measureId: this.props.measureId,
            dataType: this.props.dataType
        }

        return this.state.editing ? (
            <EditRow
                {...sharedProps}
                values={this.state.values}
                saving={this.state.saving}
                onChange={this.onChange}
                dropListOptions={this.props.dropListOptions}
                valid={Object.keys(this.getErrors()).length === 0}
                saveEdits={this.save}
                cancelEdits={this.cancel}
                errors={this.getErrors()}
                subMeasureValues={this.props.subMeasureValues}
                onCompositeChange={this.onCompositeChange}
            />
        ) : (
            <DisplayRow
                {...sharedProps}
                value={this.props.displayValue}
                restricted={!permitted}
                allowEdit={this.props.allowEdit}
                startEdit={this.edit}
                deleteItem={this.delete}
            />
        )
    }
}
