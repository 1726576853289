import React from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import { GuideLink } from "../../indicators"
import * as reduxActions from "../actions"
import * as C from "../constants"
import { PopulationCheck } from "./PopulationCheck"
import { TitleToggle } from "./TitleToggle"

export const Title = ({ children, form, filters, goToPatientFilters }) => {
    const filter = form ? form[C.FILTERS_SECTION] : filters
    if (!filter) {
        debugger
    }
    return (
        <div className="page-header patient-list-page-header">
            <h1>
                Patient{" "}
                {filter[C.FILTERS.TYPE] === C.FILTER_TYPES.PATIENT_MEASURES
                    ? "Measures"
                    : "Summary"}
            </h1>
            <TitleToggle filters={filters} setFilters={goToPatientFilters} />
            <GuideLink
                page={
                    filters[C.FILTERS.TYPE] === C.FILTER_TYPES.PATIENT_MEASURES
                        ? "view-patient-measures"
                        : "view-patient-summary"
                }
            />
            <PopulationCheck
                filters={filters}
                setFilters={goToPatientFilters}
            />

            {children}
        </div>
    )
}

export default connect(
    state => ({
        form: getFormValues(C.PATIENT_LIST_FILTER_FORM)(state),
        filters: state.applied_filters
    }),
    reduxActions
)(Title)
