import React from "react"
import _ from "lodash"
import { Hoverlay } from "../../core/Hoverlay"
import { ImageFallback } from "../../core/ImageFallback"
import fallbackIcon from "../../../assets/images/circle-exclamation-regular.svg"

const IndicatorImage = ({ image }) => (
    <ImageFallback
        src={`/risk_icons/${image}`}
        className="indicator-risk"
        fallbackSrc={fallbackIcon}
    />
)

const AtRisk = field => ({ rowData }) => {
    const risks = rowData[field] ?? []
    return (
        <Hoverlay
            className="tooltip-list-multicolor"
            tooltipColor={_.head(risks)?.color}
            tooltip={
                <ul>
                    {risks?.map(({ color, description, icon, image }, i) => (
                        <li key={i} style={{ backgroundColor: color }}>
                            {image ? (
                                <IndicatorImage key={i} image={image} />
                            ) : (
                                <span
                                    key={i}
                                    className={`indicator-risk fa fa-fw fa-${icon}`}
                                />
                            )}{" "}
                            {description}
                        </li>
                    ))}
                </ul>
            }
        >
            <span className="at_risk">
                {risks?.map(({ icon, color, image }, i) =>
                    image ? (
                        <IndicatorImage key={i} image={image} />
                    ) : (
                        <span
                            key={i}
                            className={`indicator-risk fa fa-${icon}`}
                            style={{ color }}
                        />
                    )
                )}
            </span>
        </Hoverlay>
    )
}

export default AtRisk
