import React from "react"
import { Card, Image } from "react-bootstrap"
import logo from "../assets/images/logo.gif"
import Footer from "../modules/core/Footer"
import EmailLink from "../modules/core/EmailLink"
import * as C from "../modules/authentication/constants"

const SystemDownPage = () => (
    <div id="main-content" className="login">
        <Card body className="system_down_page">
            <Image
                src={logo}
                alt="background"
                className="logo img-fluid center-block"
            />
            <p>
                The Health Focus registry is temporarily unavailable due to
                system maintenance. We apologize for any inconvenience.
            </p>
            <p>
                For immediate assistance please contact{" "}
                <EmailLink
                    email={C.DEFAULT_CONTACT_EMAIL}
                    urlParams="Subject=Health%20Focus%20is%20down"
                />
                .
            </p>
        </Card>
        <Footer />
    </div>
)

export default SystemDownPage
