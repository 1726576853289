import { createSelector } from "reselect"
import { getFormValues } from "redux-form"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import { urlWithParams } from "../../utils"
import { getTableFilters } from "../helpers"
import * as C from "../constants"

const endpointSelector = createSelector(
    getFormValues(C.QUALITY_SUMMARY_FORM),
    formValues =>
        urlWithParams(
            `/api/qsr/options`,
            getTableFilters(
                _.pick(formValues || {}, [
                    C.FILTERS.AS_OF_DATE,
                    C.FILTERS.POPULATION
                ])
            )
        )
)

const QSROptionsFetcher = fetcher({
    name: C.QSR_OPTIONS,
    method: ApiTypes.GET,
    endpoint: endpointSelector
})(() => null)

export default QSROptionsFetcher
