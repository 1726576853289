export { STATE_OPTIONS } from "../constants"

export const ADD_NEW_PATIENT = "ADD_NEW_PATIENT"
export const ADD_NEW_PATIENT_FORM = "ADD_NEW_PATIENT_FORM"

export const initialState = {
    open: false
}

export const SAVE_PATIENT = "SAVE_PATIENT"
export const HIDE_ADD_PATIENT_MODAL = "HIDE_ADD_PATIENT_MODAL"
export const SHOW_ADD_PATIENT_MODAL = "SHOW_ADD_PATIENT_MODAL"

export const ADD_NEW_PATIENT_FIELDS = {
    FIRST_NAME: "patFirstName",
    MIDDLE_NAME: "patMiddleName",
    LAST_NAME: "patLastName",
    DATE_OF_BIRTH: "patDob",
    SEX: "patBirthSexId",
    GENDER: "patGender",
    ORIENTATION: "patOrientation",
    EMAIL: "email",
    ETHNICITY: "patEthnicityId",
    RACE: "patRaceId",
    ADDRESS_1: "patAddress1",
    ADDRESS_2: "patAddress2",
    CITY: "patCity",
    STATE: "patState",
    COUNTRY: "patCountry",
    PHONE: "patPhone",
    ZIP: "patZip",
    PCP: "patPcpId",
    CARE_MANAGER_ID: "patCareManagerId",
    CARE_MANAGER_EFF_DT: "patCareManagerEffDt",
    CARE_MANAGER_TERM_DT: "patCareManagerTermDt"
}

export const ADD_NEW_PATIENT_TRANSLATION = {
    [ADD_NEW_PATIENT_FIELDS.FIRST_NAME]: "First Name",
    [ADD_NEW_PATIENT_FIELDS.MIDDLE_NAME]: "Middle Name",
    [ADD_NEW_PATIENT_FIELDS.LAST_NAME]: "Last Name",
    [ADD_NEW_PATIENT_FIELDS.DATE_OF_BIRTH]: "Date of Birth",
    [ADD_NEW_PATIENT_FIELDS.SEX]: "Sex on Birth Certificate",
    [ADD_NEW_PATIENT_FIELDS.GENDER]: "Gender Identity",
    [ADD_NEW_PATIENT_FIELDS.EMAIL]: "Email",
    [ADD_NEW_PATIENT_FIELDS.ORIENTATION]: "Sexual Orientation",
    [ADD_NEW_PATIENT_FIELDS.ETHNICITY]: "Ethnicity",
    [ADD_NEW_PATIENT_FIELDS.RACE]: "Race",
    [ADD_NEW_PATIENT_FIELDS.ADDRESS_1]: "Address 1",
    [ADD_NEW_PATIENT_FIELDS.ADDRESS_2]: "Address 2",
    [ADD_NEW_PATIENT_FIELDS.CITY]: "City",
    [ADD_NEW_PATIENT_FIELDS.STATE]: "State",
    [ADD_NEW_PATIENT_FIELDS.COUNTRY]: "Country",
    [ADD_NEW_PATIENT_FIELDS.ZIP]: "Zip Code",
    [ADD_NEW_PATIENT_FIELDS.PHONE]: "Phone Number",
    [ADD_NEW_PATIENT_FIELDS.PCP]: "PCP",
    [ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_ID]: "Care Manager",
    [ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_EFF_DT]:
        "Care Manager Enrollment Date",
    [ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_TERM_DT]: "Care Manager Discharge Date"
}

const RAW_COUNTRY_OPTIONS = ["US"]

export const COUNTRY_OPTIONS = RAW_COUNTRY_OPTIONS.map(state => ({
    label: state,
    value: state
}))
