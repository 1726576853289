import { connect } from "react-redux"

import fetcher from "../../core/fetcher"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import { filtersSelector } from "../selectors"
import * as C from "../constants"
import * as actions from "../actions"
import RiskDashboard from "./RiskDashboard"

export default connect(
    state => ({ ...state[C.NAME], filters: filtersSelector(state) }),
    actions
)(
    fetcher({
        name: C.NAME,
        method: ApiTypes.GET,
        endpoint: state => {
            const moduleState = state[C.NAME]
            return urlWithParams(
                `/api/risk/dashboard/${moduleState.aggregation}`,
                filtersSelector(state)
            )
        }
    })(RiskDashboard)
)
