import React from "react"

export const NoAccess = ({ message }) => {
    return <i title={message} className="fa fa-minus-circle" />
}

export const InputBox = ({ lock, checked, ...props }) => {
    const { value, message } = lock || {}

    if (lock && !value) {
        return <NoAccess message={message} />
    }

    return (
        <input
            type="checkbox"
            checked={!!lock || checked}
            disabled={!!lock}
            title={message}
            {...props}
        />
    )
}
