import React from "react"
import { connect } from "react-redux"
import { clearInterval, setInterval } from "worker-timers"
import { MINUTES_IDLE_BEFORE_FORCE_LOGOUT } from "../../../configuration"
import * as C from "../constants"
import { forceLogoutAction } from "../actions"

const MINUTES_UNITL_AUTO_LOGOUT = MINUTES_IDLE_BEFORE_FORCE_LOGOUT // in mins
const CHECK_INTERVAL = 2000 // in ms
const STORE_KEY = "lastAction"

class AutoLogout extends React.Component {
    componentDidMount() {
        this.check()
        this.initListener()
        this.initInterval()
    }

    getLastAction = () => parseInt(localStorage.getItem(STORE_KEY), 10)

    setLastAction = lastAction => {
        localStorage.setItem(STORE_KEY, lastAction.toString())
    }

    initListener = () => {
        document.body.addEventListener("click", this.reset)
        document.body.addEventListener("keypress", this.reset)
    }

    reset = () => this.setLastAction(Date.now())

    initInterval = () => {
        this.interval = setInterval(() => {
            this.check()
        }, CHECK_INTERVAL)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
        document.body.removeEventListener("click", this.reset)
        document.body.removeEventListener("keypress", this.reset)
    }

    check = () => {
        const now = Date.now()
        const timeleft =
            this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000
        const diff = timeleft - now
        const isTimeout = diff < 0
        if (isTimeout) {
            this.props.forceLogoutAction({
                title: `You have been inactive for ${C.INACTIVITY_MINUTES} minutes, and have been logged out for your own security`
            })
        }
    }

    render() {
        return null
    }
}

export default connect(null, { forceLogoutAction })(AutoLogout)
