import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import { PERIOD_MONTH_IDS, VIEWS } from "../pickers/constants"
import { COST_DASHBOARD_PRODUCT } from "../site_settings/constants"
import { SETTINGS } from "../constants"
import * as CC from "../cost/constants"
import * as C from "./constants"
import { categoryTreeSelector } from "../cost/selectors"
import { getInitialValues } from "../cost/helpers"

export const networkDataSelector = createSelector(
    getResourceData(C.NAME, data => data.costSummaries),
    data =>
        _.sortBy(data, [
            CC.TABLE_KEYS.CATEGORY_ID,
            CC.TABLE_KEYS.SUBCATEGORY_ID
        ])
            .map(rowData => {
                const path = _.compact([
                    rowData[CC.TABLE_KEYS.CATEGORY_ID],
                    rowData[CC.TABLE_KEYS.SUBCATEGORY_ID],
                    rowData[CC.TABLE_KEYS.SERVICE_ID]
                ])
                return {
                    ...rowData,
                    [CC.TABLE_KEYS.PATH]: path,
                    [CC.TABLE_KEYS.DISPLAY_NAME]: [
                        "Total",
                        rowData[CC.TABLE_KEYS.CATEGORY_NAME],
                        rowData[CC.TABLE_KEYS.SUBCATEGORY_NAME]
                    ][path.length]
                }
            })
            .map((row, index, data) => {
                const next = data[index + 1]
                const isLeaf =
                    !next ||
                    row[CC.TABLE_KEYS.PATH].length >=
                        next[CC.TABLE_KEYS.PATH].length
                return {
                    ...row,
                    isLeaf
                }
            })
)

export const requestBodySelector = createSelector(
    state => state[C.NAME],
    getResourceData(SETTINGS, data => data[COST_DASHBOARD_PRODUCT]),
    (dashboardStore, defaultProduct) => {
        const { filters, aggregation } = dashboardStore

        const sharedFilters = _.omit(filters, [
            CC.FILTERS.PERIOD,
            CC.FILTERS.PRODUCTS,
            CC.FILTERS.PRACTICE_UNITS,
            CC.FILTERS.CATEGORIES
        ])
        const viewFilters = _.pick(filters, [
            aggregation === VIEWS.NETWORK
                ? CC.FILTERS.PRACTICE_UNITS
                : CC.FILTERS.CATEGORIES
        ])

        const specifiedProduct = _.head(filters[CC.FILTERS.PRODUCTS]) // unpack the array. (It needed to be an array for the FilterHeader component)

        return {
            ...sharedFilters,
            ...PERIOD_MONTH_IDS[filters[CC.FILTERS.PERIOD]], // unpack the period selector into actual months
            productClass: specifiedProduct || defaultProduct || null,
            ...viewFilters
        }
    }
)

export const filtersSelector = createSelector(
    state => state[C.NAME].aggregation,
    state => state[C.NAME].filters,
    getResourceData(SETTINGS, data => data[COST_DASHBOARD_PRODUCT]),
    (aggregation, filters, dashboardProduct) => ({
        [C.FILTERS.PRODUCTS]: [dashboardProduct],
        ...filters,
        ...(aggregation === VIEWS.NETWORK
            ? {
                  [CC.FILTERS.CATEGORIES]: undefined,
                  [CC.FILTERS.SUBCATEGORIES]: undefined
              }
            : {
                  [CC.FILTERS.PRACTICE_UNITS]: undefined,
                  [CC.FILTERS.REGIONS]: undefined
              })
    })
)

export const initialValuesSelector = createSelector(
    filtersSelector,
    categoryTreeSelector,
    (filters, categoryTree) => getInitialValues(filters, categoryTree)
)
