import React from "react"
import _ from "lodash"
import SiteTitle from "../../core/SiteTitle"
import { GuideLink } from "../../indicators"
import * as C from "../constants"

export const PatientName = props => {
    const [name, patientId] = _.at(props, [C.KEYS.NAME, C.KEYS.PATIENT_ID])
    return (
        <div className="poc-title">
            <h1>
                <SiteTitle
                    title={
                        "Point of Care" +
                        (patientId ? ` - Patient ${patientId}` : "")
                    }
                />
                Point of Care for:<span>{name || "Loading..."}</span>
                <GuideLink page="view-patient-point-of-care" />
            </h1>
        </div>
    )
}

export default PatientName
