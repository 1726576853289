import React from "react"
import { sortByLabel } from "../../../transformFilter"

export const SelectedPatientsList = ({ onClickPatient, patients }) => (
    <div className="selected_patients_list">
        {sortByLabel(patients).map(patient => (
            <div key={patient.value}>
                <span>{patient.label}</span>
                <span
                    className="fa fa-close"
                    onClick={() => onClickPatient(patient)}
                />
            </div>
        ))}
    </div>
)
