import React, { useMemo } from "react"
import { bar } from "billboard.js"

import { NoResults } from "../../core/LoadingErrorHOC"
import Chart from "../../core/Chart"
import * as CC from "../../cost/constants"
import { formatDollars, formatDollarsCents } from "../../numbers"

const CONFIG = {
    axis: {
        x: {
            type: "timeseries",
            tick: {
                format: "%b '%y",
                culling: false
            }
        },
        y: {
            tick: {
                format: formatDollars
            }
        }
    },
    grid: {
        y: {
            show: true
        }
    },
    tooltip: {
        format: {
            title: x =>
                x.toLocaleString("default", {
                    month: "long",
                    year: "numeric"
                }),
            value: formatDollarsCents
        }
    }
}

export const CostChart = ({ categories, months }) => {
    const data = useMemo(
        () => ({
            x: "x",
            type: bar(),
            groups: [categories],
            rows: [
                ["x", ...categories], // header row
                ...months
            ],
            colors: CC.CATEGORY_COLOR_CODES
        }),
        [categories, months]
    )

    if (_.isEmpty(categories) || _.isEmpty(months)) {
        return <NoResults />
    }

    return <Chart className="cost-chart" config={CONFIG} data={data} />
}
