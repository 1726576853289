import React from "react"
import { Link } from "react-router-dom"
import { InfoIndicator } from "../../../../indicators"

export const UserTypeInfo = ({ isSuperuser }) => (
    <span>
        User Type{" "}
        <InfoIndicator
            className="tooltip-list tooltip-light_text tooltip-blue"
            placement="right-start"
            tooltip={
                <ul>
                    <li>
                        <strong>Superusers</strong> have full administrative
                        access to the entire site. They can edit users and site
                        settings.
                    </li>
                    <li>
                        <strong>Normal users</strong>' panels are associated
                        with practices and/or tags. They can see the global
                        patient list.
                    </li>
                    <li>
                        <strong>External users</strong>' panels are associated
                        with tags only. They can only see the patients in their
                        panels.
                    </li>
                    {isSuperuser ? (
                        <li>
                            Some roles may be restricted from using certain
                            parts of the site; see the{" "}
                            <Link to="/settings">Site Settings page</Link> for
                            details.
                        </li>
                    ) : (
                        <li>
                            Some roles may be restricted from using certain
                            parts of the site.
                        </li>
                    )}
                </ul>
            }
        />
    </span>
)

// export default connect(state => ({}))(UserTypeInfo)
