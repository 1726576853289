import React from "react"
import { getFormValues } from "redux-form"
import { connect } from "react-redux"
import { BlockPicker } from "react-color"
import classNames from "classnames"
import Cleave from "cleave.js/react"
import _ from "lodash"
import { FormGroup } from "react-bootstrap"
import { getValidProps } from "./helpers"
import DatePicker from "./DatePicker"
import DateTimePicker from "./DateTimePicker"
import { Hoverlay } from "./Hoverlay"
import Select, { CreatableSelect } from "./Select"

require("cleave.js/dist/addons/cleave-phone.us") // needs to be included but we don't actually do anything with it

const getLabelText = (label, translation, name) =>
    label === null ? null : label || _.get(translation, name) // if label is explicitly null then leave it blank

const onChangeNormalized = onChange => event => {
    onChange(event.target.value)
    event.stopPropagation()
}

export const TextInput = ({
    input,
    meta,
    label,
    labelClass,
    notClearable,
    isTextArea,
    noHighlighting,
    className,
    required, // XXX doesn't interact at all with isRequired validation, but it probably should somehow...
    disabled,
    autoUpdate,
    children,
    ...props
}) => {
    const validProps = getValidProps(input, meta)
    const {
        value,
        onChange,
        name,
        id,
        touched,
        error,
        translation
    } = validProps
    const text = getLabelText(label, translation, name)
    const inputProps = {
        ...input,
        id,
        name,
        value,
        required,
        disabled,
        className: "form-control",
        placeholder: disabled ? "" : text,
        ...props,
        onChange: onChangeNormalized(onChange)
    }

    return (
        <FormGroup
            className={classNames(
                {
                    filter_field_error: (touched || autoUpdate) && error,
                    active_filter_field_input:
                        !error &&
                        !disabled &&
                        !noHighlighting &&
                        !_.isEmpty(value)
                },
                className
            )}
            style={{ position: "relative" }}
        >
            {children}
            <Label
                {...validProps}
                label={text}
                labelClass={labelClass}
                required={required}
                autoUpdate={autoUpdate}
            />
            {isTextArea ? (
                <textarea {...inputProps} style={{ resize: "none" }} />
            ) : (
                <input type="text" {...inputProps} />
            )}
            {!!value && !notClearable && !disabled && (
                <ClearButton
                    onChange={onChange}
                    style={{
                        bottom: 0,
                        right: 0,
                        padding: "0 13px"
                        // was top: "34px", right: "26px"
                    }}
                />
            )}
        </FormGroup>
    )
}

export const NumericInput = ({ ...props }) => (
    <TextInput {...props} type="number" />
)

// XXX we could make this a Cleave if we want to get the comma formatting just right
export const DollarInput = ({ ...props }) => (
    <TextInput
        {...props}
        type="number"
        className={classNames("dollar-field", props.className)}
        min={0}
    >
        <span className="dollar-sign">$</span>
    </TextInput>
)

const getDropdownClassName = ({
    className,
    touched,
    autoUpdate,
    error,
    disabled,
    noHighlighting,
    value
}) => {
    const hasValue = _.isArrayLike(value) ? !_.isEmpty(value) : !!value
    return classNames(
        {
            filter_field_error: (touched || autoUpdate) && error,
            active_filter_field_input:
                !error && !disabled && !noHighlighting && hasValue
        },
        className
    )
}

const getDropdownProps = props => {
    const {
        input,
        meta,
        label,
        placeholder,
        required,
        disabled,
        multi = false,
        autoUpdate,
        notClearable,
        clearWhenDisabled,
        onChange,
        options = []
    } = props

    const { id, ...validProps } = getValidProps(input, meta)
    const { value } = validProps

    const className = getDropdownClassName({
        ...props,
        value
    })

    const labelProps = {
        ...validProps,
        id,
        label,
        required,
        autoUpdate
    }
    const selectProps = _.pickBy(
        {
            ...validProps,
            inputId: id, // needs to be inputId instead of id for clicking the label to focus on it
            onBlur: () => validProps.onBlur(value), // need to make a custom blur handler or it'll clear the value
            onChange: onChange ?? validProps.onChange,
            placeholder,
            required,
            options,
            multi,
            disabled,
            isClearable: !notClearable && !_.isNil(value),
            backspaceRemovesValue: !notClearable,
            clearWhenDisabled
        },
        _.negate(_.isUndefined)
    )

    return {
        ...props,
        labelProps, // so we can pass them on to the label as a unit
        selectProps, // so we can pass them to the select in the same way
        className,
        id,
        onChange
    }
}

export const SelectField = props => {
    const {
        className,
        style,
        noLabel, // different from not declaring a label. This means "don't even leave space for a label"
        labelProps,
        selectProps
    } = getDropdownProps(props)

    return (
        <FormGroup style={style} className={className}>
            {noLabel || <Label {...labelProps} />}
            <Select {...selectProps} />
        </FormGroup>
    )
}

export const CreatableSelectField = props => {
    const {
        className,
        style,
        noLabel, // different from not declaring a label. This means "don't even leave space for a label"
        labelProps,
        selectProps
    } = getDropdownProps(props)

    return (
        <FormGroup style={style} className={className}>
            {noLabel || <Label {...labelProps} />}
            <CreatableSelect {...selectProps} />
        </FormGroup>
    )
}

function DatePickerField({
    input,
    meta,
    required,
    disabled,
    autoUpdate,
    noLabel,
    notClearable,
    PickerComponent,
    className,
    ...pickerProps
}) {
    const validProps = getValidProps(input, meta)
    const { label, noHighlighting } = pickerProps
    const { value, touched, error, id } = validProps
    const displayableError = (touched || autoUpdate) && error

    return (
        <FormGroup
            className={classNames(
                {
                    filter_field_error: displayableError,
                    active_filter_field_input:
                        !error &&
                        !disabled &&
                        !noHighlighting &&
                        !_.isEmpty(value)
                },
                className
            )}
        >
            {noLabel || (
                <Label
                    {...validProps}
                    label={label}
                    required={required}
                    autoUpdate={autoUpdate}
                />
            )}

            <PickerComponent
                {...pickerProps}
                {...validProps}
                disabled={disabled}
                inputProps={{
                    id,
                    required,
                    disabled,
                    value,
                    ...pickerProps.inputProps
                }}
                disableClear={notClearable}
                error={displayableError}
            />
        </FormGroup>
    )
}

const datePickerFieldFactory = PickerComponent =>
    connect((state, { meta }) => ({
        formValues: getFormValues(meta.form)(state),
        PickerComponent
    }))(DatePickerField)

export const LabelledDatePicker = datePickerFieldFactory(DatePicker)
export const LabelledDateTimePicker = datePickerFieldFactory(DateTimePicker)

// looks like a text input, but is actually a button
export const InputButton = ({
    disabled,
    id,
    notClearable,
    onChange,
    onClick,
    placeholder,
    value
}) => (
    <div className={classNames("button-field", { selected: value })}>
        <button id={id} type="button" onClick={onClick}>
            <span>{value ? value : placeholder || "Select..."}</span>
        </button>
        {value && !notClearable && !disabled && (
            <ClearButton
                onChange={onChange}
                style={{
                    right: 12,
                    top: 0
                }}
            />
        )}
    </div>
)

export const ButtonField = ({
    className,
    input,
    label,
    meta,
    noHighlighting,
    ...props
}) => {
    const validProps = getValidProps(input, meta)
    const { value } = validProps
    return (
        <FormGroup
            className={classNames(
                {
                    active_filter_field_button:
                        !noHighlighting && !_.isEmpty(value)
                },
                className
            )}
        >
            <Label {...validProps} label={label} />
            <InputButton {...props} {...validProps} onClick={input.onClick} />
        </FormGroup>
    )
}

export const CheckboxField = ({
    input,
    meta,
    disabled,
    label,
    labelRight,
    className
}) => {
    const { name, translation, value } = getValidProps(input, meta)
    const text = getLabelText(label, translation, name)

    return (
        <FormGroup
            as="label"
            className={classNames("checkbox-field", className, {
                disabled: disabled,
                checked: !!value,
                active_filter_field_label: !!value
            })}
        >
            {labelRight ? "" : text}
            {value ? (
                <span className="fa fa-check-square-o" />
            ) : (
                <span className="fa fa-square-o" />
            )}
            {labelRight ? text : ""}
            <input
                disabled={disabled}
                {...input}
                checked={value} // without this it won't initialize correctly, and you'll have to click twice to change an initially-checked field
                type="checkbox"
            />
        </FormGroup>
    )
}

export const Label = ({
    name,
    label,
    labelClass,
    value,
    touched,
    autoUpdate, // i.e. this component will update its validation without needing to be touched
    warning,
    error,
    translation,
    required,
    id
}) => {
    const text = getLabelText(label, translation, name)
    // passing a null label means "hide the label, even if one could be automatically assigned"
    const visibleError = (touched || autoUpdate) && error
    const visibleWarning = (touched || autoUpdate) && warning
    const hasValue = _.isArrayLike(value) ? !_.isEmpty(value) : !!value

    const errorText = visibleError || visibleWarning
    const errorIncludesLabel = errorText?.includes(text)

    return text ? (
        <label
            htmlFor={id}
            className={classNames(labelClass, {
                active_filter_field_label: hasValue,
                error_label: visibleError,
                warning_label: !visibleError && visibleWarning
            })}
        >
            {errorText && !errorIncludesLabel && text + " " // If we didn't find the label text in the error message, add it to the front
            }
            {errorText || text}
            {required && <span className="required-indicator"> *</span>}
        </label>
    ) : (
        <span />
    )
}
// XXX another idea for 'required' validation error handling: leave the field name in black but the error message in red, and for required the error message is just an asterisk. That way we don't have any redundancy!

const ClearButton = ({ onChange, style, className }) => (
    <span
        className={classNames("Select-clear-zone", className)} // the uppercase S in the class name is imposed by the react-select library, we don't have a choice here really
        title="Clear value"
        aria-label="Clear value"
        onClick={() => onChange("")}
        style={style}
    >
        <span className="Select-clear">×</span>
    </span>
)

export const PasswordField = ({ autoComplete, ...props }) => (
    <TextInput
        {...props}
        type="password"
        autoComplete={autoComplete || "new-password"}
    />
)

export const HiddenInput = ({ input, meta, autoComplete }) => {
    const validProps = getValidProps(input, meta)
    const { value, name } = validProps
    return (
        <input
            type="hidden"
            name={name}
            value={value}
            autoComplete={autoComplete}
        />
    )
}

const passRawValue = func => event => func(event.target.rawValue)

export const PhoneNumber = ({
    className,
    disabled,
    input,
    label,
    meta,
    required,
    style
}) => {
    const validProps = getValidProps(input, meta)
    const { name, value, onChange, onBlur, error, touched } = validProps

    return (
        <FormGroup
            style={style}
            className={classNames(
                {
                    filter_field_error: touched && error,
                    active_filter_field_input:
                        !error && !disabled && !_.isEmpty(value)
                },
                className
            )}
        >
            <Label {...validProps} required={required} />
            <Cleave
                className="form-control"
                label={label}
                disabled={disabled}
                name={name}
                value={value}
                onChange={passRawValue(onChange)}
                onBlur={passRawValue(onBlur)}
                placeholder={label || "Phone Number"}
                options={{ phone: true, phoneRegionCode: "US" }}
            />
        </FormGroup>
    )
}

export const ColorField = ({
    input,
    meta,
    className,
    colorOptions = [],
    disabled,
    required,
    style
}) => {
    const validProps = getValidProps(input, meta)
    const { value, onChange, error, touched } = validProps
    return (
        <FormGroup
            className={classNames("color-picker", {
                filter_field_error: touched && error,
                active_filter_field_input:
                    !error && !disabled && !_.isEmpty(value),
                className
            })}
            style={style}
        >
            <Label {...validProps} required={required} />

            <Hoverlay
                trigger="click"
                placement="bottom"
                className="color-picker-tooltip"
                disabled={disabled}
                tooltipColor={value}
                tooltip={
                    <BlockPicker
                        color={value}
                        colors={colorOptions}
                        onChangeComplete={color => onChange(color.hex)}
                    />
                }
            >
                <div className="color-prefix">
                    <div
                        className="color-swatch-sm"
                        style={{
                            backgroundColor: value
                        }}
                    />
                    #
                </div>
                <input
                    {...input}
                    className="form-control"
                    value={value.replaceAll("#", "")}
                    disabled={disabled}
                    onChange={event => {
                        const { target } = event
                        const { value } = target
                        const hexed = value.replaceAll(/[^\da-fA-F]/g, "")
                        const colorCode = hexed.slice(0, 6)

                        onChange(colorCode ? "#" + colorCode : null)

                        // place the text cursor in the correct spot afterwards
                        const cursorPos =
                            target.selectionStart -
                            (colorCode === value.slice(0, 6) ? 0 : 1)
                        setTimeout(() =>
                            target.setSelectionRange(cursorPos, cursorPos)
                        )
                    }}
                    placeholder="000000"
                />
            </Hoverlay>
            {!!value && !disabled && (
                <ClearButton
                    onChange={onChange}
                    style={{
                        bottom: 0,
                        right: 0,
                        padding: "0 13px"
                    }}
                />
            )}
        </FormGroup>
    )
}
