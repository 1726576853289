import { all, call, put, select, takeEvery } from "redux-saga/effects"
import * as C from "./constants"
import Notifications from "react-notification-system-redux"
import moment from "moment"
import { modifyResource } from "../core/fetcher"
import {
    ERROR_NOTIFICATION,
    notifySimpleError,
    savingNotification,
    SUCCESS_NOTIFICATION,
    tryWithNotifications,
    UID_SAVING
} from "../notifications"
import { findAndUpdate } from "../utils"
import { TIMESTAMP_STRING } from "../dates"
import { ApiTypes, downloadFile, uploadFiles } from "../api"

function* updateDocument(documentId, update) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: data => ({
                ...data,
                documents: findAndUpdate(
                    data.documents,
                    update,
                    C.TABLE_KEYS.DOCUMENT_ID,
                    documentId
                )
            })
        })
    )
}

function* uploadDocuments({ files, description }) {
    const formData = new FormData()
    formData.append(C.TABLE_KEYS.DESCRIPTION, description)

    for (const file of files) {
        formData.append("files", file)
    }

    const filenames = _.join(
        _.map(files, file => `"${file.name}"`),
        ", "
    )

    try {
        yield put(
            Notifications.info(
                savingNotification({
                    message: `Uploading file${
                        files.length > 1 ? "s:" : ""
                    } ${filenames}...`
                })
            )
        )
        const response = yield* uploadFiles(`/api/documents`, formData) // using uploadFiles instead of a more normal fetch is why we can't use tryWithNotifications here

        if (!_.isEmpty(response.files)) {
            // add username to returned file records
            const userName = yield select(
                state => state.authentication.userName
            )
            const newFiles = response.files.map(file => ({
                ...file,
                [C.TABLE_KEYS.CREATED_BY_NAME]: userName
            }))

            // add those file records to the audit record
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: data => ({
                        ...data,
                        documents: [...data.documents, ...newFiles]
                    })
                })
            )

            yield put(
                Notifications.success({
                    ...SUCCESS_NOTIFICATION,
                    message: "Upload complete."
                })
            )
        }

        for (const filename in response.failures) {
            yield put(
                Notifications.error({
                    ...ERROR_NOTIFICATION,
                    title: `Failed to upload ${filename}`,
                    message: response.failures[filename]
                })
            )
        }
    } catch (error) {
        yield notifySimpleError("Upload failed.")(error)
    } finally {
        yield put(Notifications.hide(UID_SAVING))
    }
}

function* downloadDocument({ document }) {
    yield* downloadFile(
        document[C.TABLE_KEYS.FILE_NAME],
        `/api/documents/${document[C.TABLE_KEYS.DOCUMENT_ID]}`,
        document[C.TABLE_KEYS.FILE_NAME],
        { pendingMessage: `Downloading ${document[C.TABLE_KEYS.FILE_NAME]}...` }
    )
}

function* deleteDocument({ documentId }) {
    yield* tryWithNotifications(
        {
            url: `/api/documents/${documentId}`,
            method: ApiTypes.DELETE
        },
        function*() {
            const auth = yield select(state => state.authentication)

            yield* updateDocument(documentId, {
                [C.TABLE_KEYS.DELETED]: true,
                [C.TABLE_KEYS.DELETED_BY]: auth.userId,
                [C.TABLE_KEYS.DELETED_DATE]: moment().format(TIMESTAMP_STRING)
            })
        }
    )
}

function* restoreDocument({ documentId }) {
    yield* tryWithNotifications(
        {
            url: `/api/documents/${documentId}/restore`,
            method: ApiTypes.PUT
        },
        function*() {
            yield* updateDocument(documentId, {
                [C.TABLE_KEYS.DELETED]: false,
                [C.TABLE_KEYS.DELETED_BY]: undefined,
                [C.TABLE_KEYS.DELETED_DATE]: undefined
            })
        }
    )
}

function* updateDescription({ documentId, description }) {
    yield* tryWithNotifications(
        {
            url: `/api/documents/${documentId}`,
            method: ApiTypes.PUT,
            body: { description }
        },
        function*() {
            yield* updateDocument(documentId, {
                [C.TABLE_KEYS.DESCRIPTION]: description
            })
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.UPLOAD_DOCUMENTS, uploadDocuments)
        }),
        call(function*() {
            yield takeEvery(C.DOWNLOAD_DOCUMENT, downloadDocument)
        }),
        call(function*() {
            yield takeEvery(C.DELETE_DOCUMENT, deleteDocument)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_DOCUMENT, restoreDocument)
        }),
        call(function*() {
            yield takeEvery(C.UPDATE_DOCUMENT_DESCRIPTION, updateDescription)
        })
    ])
}
