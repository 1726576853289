import { all, call, put, takeEvery } from "redux-saga/effects"
import { ApiTypes, tryFetch } from "../api"
import * as C from "./constants"

function* fetchPreferences() {
    yield* tryFetch(
        {
            url: "/api/user/settings"
        },
        function*(payload) {
            yield put({ type: C.FETCH_USER_PREFERENCES_SUCCESS, payload })
        },
        function*() {
            yield put({
                type: C.FETCH_USER_PREFERENCES_SUCCESS,
                payload: null
            })
        }
    )
}

function* persistSettings({ tableName, payload }) {
    yield* tryFetch({
        url: "/api/user/settings",
        method: ApiTypes.PUT,
        body: { tableName, ...payload }
        // no success handler because this is one-way, and no failure handler because this is not very important
    })
}

function* removeUserPreferences({ tableName }) {
    yield* tryFetch({
        url: "/api/user/settings",
        method: ApiTypes.DELETE,
        body: { tableName }
        // ditto
    })
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.FETCH_USER_PREFERENCES, fetchPreferences)
        }),
        call(function*() {
            yield takeEvery(C.PERSIST_SETTINGS, persistSettings)
        }),
        call(function*() {
            yield takeEvery(C.REMOVE_USER_PREFERENCES, removeUserPreferences)
        })
    ])
}
