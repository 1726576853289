import { useEffect } from "react"
import { connect } from "react-redux"
import { clearInterval, setInterval } from "worker-timers"
import * as actions from "../actions"
import * as C from "../constants"

export const AutoRefresh = ({ refreshPasswordToken }) => {
    useEffect(() => {
        // check if a refresh is needed on mount, because who knows how long it's been since we last checked
        refreshPasswordToken()

        // schedule future checks
        const intervalId = setInterval(
            refreshPasswordToken,
            C.AUTH_REFRESH_INTERVAL
        )
        return () => clearInterval(intervalId)
    }, [])

    return null
}

export default connect(null, actions)(AutoRefresh)
