import React from "react"
import { timestampFormat } from "../../dates"
import { formatDollarsCents } from "../../numbers"
import * as C from "../constants"

import spinner from "../../../assets/images/loading.gif"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"

const PatientCostInfo = ({ data, loading, error }) =>
    loading ? (
        <img src={spinner} alt="Loading..." />
    ) : error || _.isEmpty(data) ? null : (
        <>
            <div>
                <dt>Total Cost (YTD):</dt>
                <dd>{formatDollarsCents(data[C.KEYS.COST_YTD]) || "$0.00"}</dd>
            </div>
            <div>
                <dt>Total Cost (Last 12 Months):</dt>
                <dd>
                    {formatDollarsCents(data[C.KEYS.COST_12_MONTH]) || "$0.00"}
                </dd>
            </div>
        </>
    )

const PatientCostInfoContainer = fetcher({
    name: C.COST_TOTALS,
    endpoint: state => `/api/patients/${state.poc_id}/cost/summary`,
    method: ApiTypes.GET
})(PatientCostInfo)

export const PatientHeaderInfo = ({ nextAppointment, canAccessCosts }) => (
    <dl className="poc-header-info">
        {canAccessCosts && <PatientCostInfoContainer />}
        <div>
            <dt>Next Appointment:</dt>
            <dd>{timestampFormat(nextAppointment) || "None"}</dd>
        </div>
    </dl>
)

export default PatientHeaderInfo
