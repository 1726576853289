import _ from "lodash"
import { createSelector } from "reselect"
import { getResourceData } from "../core/fetcher"
import { FormattedDate } from "../core/CellRenderers"
import { FILTER_OPTIONS } from "../constants"
import { itemsToObject, valueLabelToObj } from "../utils"
import * as C from "./constants"
import { CellRenderer } from "./components/report_panel/CellRenderer"
import { idToLabels, objToColumnValue } from "./helpers"

// this is a bit hacky but it works ok for now. Later we'll use the real TextMeasurer
function getMinFieldLength(field) {
    if (
        field.name
            .toLowerCase()
            .match(
                /name|practice|provider|payer|care|summary|email|source|desc|condition|managers/
            ) ||
        field.name === "PCP" ||
        field.type === C.TIMESTAMP
    ) {
        return 180
    } else if (C.ALL_NUMERIC_TYPES.includes(field.type)) {
        return 60
    } else {
        return 100
    }
}

// ideally we should get the longest value in this field in any row, with a max
const getColumnWidth = field => {
    const length =
        _.sum(
            field.name
                .split("")
                .map(char =>
                    " -.,':;liI()".includes(char)
                        ? 5
                        : char.match(/[A-Zwm]/)
                        ? 10
                        : 7
                )
        ) + 20 // for the sort triangle
    const minLength = getMinFieldLength(field)
    return Math.max(minLength, length)
}

const getLabel = field => {
    const name = _.trimStart(field.name, "!") // a field that shouldn't be made into a filter has a "!" starting its name

    return name.includes("_") // if so it's probably an unformatted column name; we should format it
        ? _.startCase(name).replaceAll("Id", "ID") // ID should always be in caps
        : name // otherwise, the name we were given should be fine
}

const getColumnFromField = field => ({
    selected: field.name !== C.PATIENT_ID, // don't show patient ID by default, it's not really useful to users. Instead, we use it in the Patient Name column below.
    label: getLabel(field),
    key: field.name,
    width: getColumnWidth(field),
    flexGrow: 1,
    cellRenderer: field.type === C.DATE ? FormattedDate : CellRenderer,
    columnData: { type: field.type }
})

export const reportColumnsSelector = createSelector(
    getResourceData(C.CURRENT_REPORT, data => data.fields),
    (fields = []) => fields.map(getColumnFromField),
    { memoizeOptions: { equalityCheck: _.isEqual } } // column list get refetched each time you fetch the report, but the value shouldn't change
)

const _lookupSelector = createSelector(
    getResourceData(FILTER_OPTIONS),
    filterOptions =>
        _.mapValues(filterOptions, options => {
            try {
                return valueLabelToObj(options)
            } catch (TypeError) {
                // this filterOption wasn't written in the correct format
                return {}
            }
        })
)
export const lookupSelector = createSelector(_lookupSelector, lookup => key => {
    let keyNormalized = key.toLowerCase()
    return keyNormalized in C.FIXED_OPTIONS
        ? itemsToObject(C.FIXED_OPTIONS[keyNormalized])
        : lookup[C.DROPDOWN_OPTION_KEYS[keyNormalized]]
})
export const activeReportSelector = reportId =>
    getResourceData(C.AVAILABLE_REPORTS, data =>
        (data.reportList || []).find(item => item.reportId === reportId)
    )

export const filtersSelector = reportId =>
    createSelector(
        getResourceData(FILTER_OPTIONS),
        state => state[C.NAME][reportId],
        (filterOptions, filters) => {
            const rangeFilters = _.pickBy(
                filters,
                filter => _.isPlainObject(filter) && !_.isEmpty(filter)
            )
            const multiFilters = _.pickBy(
                filters,
                filter => _.isArray(filter) && !_.isEmpty(filter)
            )
            const simpleFilters = _.pickBy(
                filters,
                filter => !_.isPlainObject(filter) && !_.isArray(filter)
            )

            return {
                filters: objToColumnValue(simpleFilters),
                multiFilters: objToColumnValue(multiFilters).map(
                    idToLabels(filterOptions)
                ),
                rangeFilters: objToColumnValue(rangeFilters)
            }
        }
    )
