import {
    DATE_STRING,
    parseDate,
    SERVER_DATE_STRING,
    TIMESTAMP_STRING,
    TIMESTAMP_STRING_NO_MS
} from "../dates"

export const tcmDateValidator = dischargeDt => date => {
    if (!date) return
    if (!dischargeDt) return "Patient has not been discharged"

    const valueMoment = parseDate(date)
    const dischargeMoment =
        parseDate(dischargeDt, TIMESTAMP_STRING) ??
        parseDate(dischargeDt, TIMESTAMP_STRING_NO_MS) ??
        parseDate(dischargeDt, SERVER_DATE_STRING)

    if (!valueMoment) return `Date must be ${DATE_STRING}`
    if (!dischargeMoment) return "Invalid discharge date"

    if (dischargeMoment.startOf("day").isAfter(valueMoment)) {
        return "Must not be before discharge date"
    }
    if (
        dischargeMoment
            .add(30, "days")
            .endOf("day")
            .isBefore(valueMoment)
    ) {
        return "Must be no more than 30 days after discharge"
    }
}
export const validTcmDate = dischargeDt => value =>
    !tcmDateValidator(dischargeDt)(value)
