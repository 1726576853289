export const LOG_USER_AUDIT = "LOG_USER_AUDIT"

export const CHANGELOG = "changelog"
export const CAMPAIGNS = "campaigns"
export const TCM = "tcm"
export const UTILIZATION = "utilization"
export const DASHBOARD = "dashboard"
export const LIBRARY = "library"
export const QSR = "qsr"
export const POC = "poc"
