import { QUALITY_SUMMARY_FORM as QSR_FORM } from "../qsr/constants"
import { QSR_FILTERS_FORM as QSR_DASHBOARD_FORM } from "../qsr_dashboard/constants"
import { PATIENT_LIST_FILTER_FORM as PATIENT_LIST_FORM } from "../patient_list/constants"

export { QSR_FORM, QSR_DASHBOARD_FORM, PATIENT_LIST_FORM }

export const SITE_SETTINGS_FORM = "SITE_SETTINGS_FORM"

// actions
export const SUBMIT_SETTINGS = "SUBMIT_SETTINGS"
export const CLEAR_CACHE = "CLEAR_CACHE"

// form sections
export const ORGANIZATION = "organization"
export const CONTACT = "contact"
export const MODULES = "modules"
export const UM_SETTINGS = "umSettings"
export const TABLE_FILTERS = "tableFilters"
export const GLOBAL_TOGGLES = "globalToggles"
export const ALLOW_GLOBAL_SEARCH = "allowGlobal"
export const REAL_TIME_METRICS = "realTimeMetrics"
export const POPULATION = "population"
export const COST_DASHBOARD_PRODUCT = "costDashboardProduct"
export const RISK_DASHBOARD_PRODUCT = "riskDashboardProduct"
export const FUZZY_SEARCH_FLAGS = "fuzzySearchFlags"
export const ALLOW_CONTRACT_NUMBER_SEARCH = "allowContractNumber"
export const ALLOW_PATIENT_ID_SEARCH = "allowPatientId"
export const ALLOW_CONTRACT_NUMBER_SEARCH_PATH =
    FUZZY_SEARCH_FLAGS + "." + ALLOW_CONTRACT_NUMBER_SEARCH
export const ALLOW_PATIENT_ID_SEARCH_PATH =
    FUZZY_SEARCH_FLAGS + "." + ALLOW_PATIENT_ID_SEARCH

export const SITE_SETTINGS_SECTIONS_TRANSLATION = {
    [ORGANIZATION]: "Organization",
    [CONTACT]: "Support Contact",
    [MODULES]: "Module Access",
    [TABLE_FILTERS]: "Default Table Filters",
    [GLOBAL_TOGGLES]: "Global Toggles",
    [ALLOW_GLOBAL_SEARCH]: "Search Settings",
    [UM_SETTINGS]: "User Management Settings"
}

// form fields
export const SETTINGS_FIELDS = {
    [ORGANIZATION]: {
        NAME: "name",
        ABBREV: "abbrevName"
    },
    [CONTACT]: {
        NAME: "name",
        EMAIL: "email",
        PHONE: "telephone"
    },
    [MODULES]: MODULES,
    [TABLE_FILTERS]: {
        QSR_TABLE: QSR_FORM,
        QSR_DASHBOARD_TABLE: QSR_DASHBOARD_FORM,
        PATIENT_LIST_TABLE: PATIENT_LIST_FORM,
        COST_DASHBOARD_PRODUCT,
        RISK_DASHBOARD_PRODUCT
    },
    [UM_SETTINGS]: {
        MAX_LOGINS: "maxLoginAttempts",
        EXPIRATION: "expirationDays"
    },
    [FUZZY_SEARCH_FLAGS]: {
        ALLOW_CONTRACT_NUMBER_SEARCH,
        ALLOW_PATIENT_ID_SEARCH
    }
}

export const SITE_SETTINGS_TRANSLATION = {
    [ORGANIZATION]: {
        [SETTINGS_FIELDS[ORGANIZATION].NAME]: "Full Name",
        [SETTINGS_FIELDS[ORGANIZATION].ABBREV]: "Abbreviation"
    },
    [CONTACT]: {
        [SETTINGS_FIELDS[CONTACT].NAME]: "Name",
        [SETTINGS_FIELDS[CONTACT].EMAIL]: "Email",
        [SETTINGS_FIELDS[CONTACT].PHONE]: "Telephone"
    },
    [MODULES]: "Module Access",
    [TABLE_FILTERS]: {
        [QSR_FORM]: { [POPULATION]: "Quality Summary Report" },
        [QSR_DASHBOARD_FORM]: { [POPULATION]: "QSR Dashboard" },
        [PATIENT_LIST_FORM]: { [POPULATION]: "Patient List" }
    },
    [ALLOW_GLOBAL_SEARCH]: "Allow global patient search",
    [FUZZY_SEARCH_FLAGS]: {
        [ALLOW_CONTRACT_NUMBER_SEARCH]: "Allow patient search by contract #",
        [ALLOW_PATIENT_ID_SEARCH]: "Allow patient search by patient ID"
    },
    [REAL_TIME_METRICS]: "Use real-time measure calculation",
    [UM_SETTINGS]: {
        [SETTINGS_FIELDS[UM_SETTINGS].MAX_LOGINS]: "Max login attempts",
        [SETTINGS_FIELDS[UM_SETTINGS].EXPIRATION]: "User expiration time (days)"
    }
}
