import { connect } from "react-redux"
import { getMetaData, getResourceData } from "../../../core/fetcher"
import auth from "../../../authentication"
import { FILTER_OPTIONS } from "../../../constants"
import * as C from "../../constants"
import { campaignTypeSelector, ownerSelector } from "../../selectors"
import { CampaignSettings } from "./CampaignSettings"

export default connect(state => {
    const { loading } = getMetaData(C.ACTIVE_USERS)(state)
    const users = getResourceData(C.ACTIVE_USERS, data => data.users)(state)
    return {
        owner: ownerSelector(state),
        users: loading ? [] : users,
        authentication: auth.selectors.authTokensSelector(state),
        options: getResourceData(FILTER_OPTIONS)(state),
        info_or_measures: campaignTypeSelector(state),
        superUser: auth.selectors.isSuperuser(state),
        currentUser: state.authentication.userId
    }
})(CampaignSettings)
