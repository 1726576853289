import { Modal } from "react-bootstrap"
import { DemographicsForm } from "./DemographicsForm"
import React from "react"

export const EditDemographicsModal = ({
    loading,
    options,
    showEdit,
    showEditPatientInfo,
    hideEditPatientInfo,
    updatePatientInfo,
    handleSubmit
}) => {
    return (
        <React.Fragment>
            <span
                className="fa fa-edit"
                onClick={showEditPatientInfo}
                style={{ cursor: "pointer" }}
            />
            <Modal size="lg" show={showEdit} onHide={hideEditPatientInfo}>
                <DemographicsForm
                    handleSubmit={handleSubmit(updatePatientInfo)}
                    handleHide={hideEditPatientInfo}
                    handleShow={showEditPatientInfo}
                    loading={loading}
                    options={options}
                />
            </Modal>
        </React.Fragment>
    )
}
