import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest
} from "redux-saga/effects"
import _ from "lodash"
import { change, getFormValues, reset } from "redux-form"
import { notifySimpleError, tryWithNotifications } from "../notifications"
import { ApiTypes, tryFetch } from "../api"
import {
    addToData,
    modifyData,
    modifyResource,
    removeFromData
} from "../core/fetcher"
import { canReadTag } from "../admin/components/users/helpers"
import { updateUserSuccess } from "../admin/actions"
import { TAG_SOURCES } from "../admin/constants"
import { ACCESS_PRIVILEGES } from "../permission_managment/constants"
import { FILTER_OPTIONS } from "../constants"
import * as C from "./constants"
import {
    cancelEdit,
    cancelSaveTag,
    deleteTagComplete,
    saveTagComplete,
    selectTag,
    setTagPrivilegesComplete
} from "./actions"
import { userTagsSelector } from "./selectors"
import { updateIfMatch, updateUserPrivileges } from "./transforms"

function* saveTag(action) {
    const tagId = action.payload[C.TABLE_KEYS.TAG_ID]

    const body = _.pick(action.payload, Object.values(C.FORM_FIELDS))

    if (_.isNil(tagId) || tagId === C.NEW_TAG_ID) {
        yield* addTag(body)
    } else {
        yield* editTag(tagId, body)
    }
}

const asLabelValue = (response, extra) => ({
    label: response[C.TABLE_KEYS.TAG_NAME],
    value: response[C.TABLE_KEYS.TAG_ID],
    isPublic: response[C.TABLE_KEYS.PUBLIC],
    ...extra
})

const getNewTagSource = body =>
    body[C.TABLE_KEYS.PUBLIC] ? TAG_SOURCES.OWNER : TAG_SOURCES.PRIVATE

function* addTag(body) {
    yield* tryWithNotifications(
        { url: "/api/tags/", method: ApiTypes.PUT, body },
        function*(response) {
            const [userName, loginId] = yield select(state => [
                state.authentication.userName,
                state.authentication.loginId
            ])

            // add tag to user tags table
            yield put(
                modifyResource({
                    name: C.USER_TAGS_TABLE,
                    dataTransform: addToData("tags", {
                        ...response,
                        [C.TABLE_KEYS.PATIENT_COUNT]: 0,
                        [C.TABLE_KEYS.OWNER]: userName,
                        [C.TABLE_KEYS.OWNER_USERNAME]: loginId,
                        [C.TABLE_KEYS.PRIVILEGE]: ACCESS_PRIVILEGES.EDIT,
                        [C.TABLE_KEYS.SOURCE]: getNewTagSource(body)
                    })
                })
            )

            // add tag to filter options
            yield put(
                modifyResource({
                    name: FILTER_OPTIONS,
                    dataTransform: data => ({
                        ...data,
                        tags: _.sortBy(
                            [...data.tags, asLabelValue(response)],
                            tag => tag.label
                        )
                    })
                })
            )

            // select the new tag in the user tags table
            yield put(selectTag(response))
            yield put(cancelEdit())
        },
        function*() {
            yield put(cancelEdit())
            return "Failed to add new tag."
        }
    )
}
function* editTag(tagId, body) {
    yield* tryWithNotifications(
        { url: `/api/tags/${tagId}`, method: ApiTypes.POST, body },
        function*() {
            // update tag in user tags table
            yield put(
                modifyResource({
                    name: C.USER_TAGS_TABLE,
                    dataTransform: modifyData(
                        "tags",
                        {
                            ...body,
                            [C.TABLE_KEYS.SOURCE]: getNewTagSource(body)
                        },
                        C.TABLE_KEYS.TAG_ID,
                        tagId
                    )
                })
            )

            // update tag in filter options
            yield put(
                modifyResource({
                    name: FILTER_OPTIONS,
                    dataTransform: modifyData(
                        "tags",
                        asLabelValue(body, { value: tagId }),
                        "value",
                        tagId
                    )
                })
            )

            yield put(saveTagComplete(body))
            yield put(cancelEdit())
            yield put(updateUserSuccess())
        },
        function*() {
            yield put(cancelEdit())
            return "Failed to edit tag."
        }
    )
}

function* onCancelEdit() {
    yield put(reset(C.EDIT_TAG_FORM))
}

function* deleteTag(action) {
    const tagId = action.payload
    yield* tryWithNotifications(
        {
            url: `/api/tags/${tagId}`,
            method: ApiTypes.DELETE
        },
        function*() {
            // delete tag from user tags table
            yield put(
                modifyResource({
                    name: C.USER_TAGS_TABLE,
                    dataTransform: removeFromData(
                        "tags",
                        C.TABLE_KEYS.TAG_ID,
                        tagId
                    )
                })
            )
            // delete tag from filter options
            yield put(
                modifyResource({
                    name: FILTER_OPTIONS,
                    dataTransform: removeFromData("tags", "value", tagId)
                })
            )

            yield put(deleteTagComplete())
            yield put(updateUserSuccess())

            return "The tag was deleted successfully."
        },
        function*() {
            yield put(cancelSaveTag())
            return "Failed to delete tag."
        }
    )
}

function* setTagPrivileges(action) {
    const { tagId, oldPrivilege, ...body } = action.payload

    yield* tryFetch(
        {
            url: `/api/tags/${tagId}/access`,
            method: ApiTypes.POST,
            body
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.TAG_DETAILS,
                    dataTransform: data => {
                        const users = _.map(
                            data.users,
                            updateUserPrivileges(body)
                        )

                        const practices = _.map(
                            data.practices,
                            updateIfMatch(
                                body.unitIds,
                                C.TABLE_KEYS.PRACTICE_ID,
                                {
                                    privilege: body.privilege,
                                    error: false
                                }
                            )
                        )

                        return { ...data, users, practices }
                    }
                })
            )

            const activeUserId = yield select(
                state => state.authentication.userId
            )
            const activeUserAccessWasDirectlyChanged = _.includes(
                body.userIds,
                activeUserId
            )

            // update tags table
            yield put(
                modifyResource({
                    name: C.USER_TAGS_TABLE,
                    dataTransform: modifyData(
                        "tags",
                        { [C.TABLE_KEYS.PRIVILEGE]: body.privilege },
                        tag => {
                            const tagAccessIsManual =
                                activeUserAccessWasDirectlyChanged &&
                                tag[C.TABLE_KEYS.SOURCE] === TAG_SOURCES.MANUAL
                            const tagAccessIsFromPractice =
                                tag[C.TABLE_KEYS.SOURCE] ===
                                    TAG_SOURCES.PRACTICE &&
                                _.includes(
                                    body.unitIds,
                                    tag[C.TABLE_KEYS.SOURCE_DESCRIPTION]
                                )
                            return (
                                tag[C.TABLE_KEYS.TAG_ID] === tagId &&
                                (tagAccessIsManual || tagAccessIsFromPractice)
                            )
                        }
                    )
                })
            )

            const userTags = yield select(userTagsSelector)
            const newUserTag = _.find(
                userTags,
                tag => tag[C.TABLE_KEYS.TAG_ID] === tagId
            )

            if (!newUserTag || !canReadTag(newUserTag)) {
                // remove from filter options
                yield put(
                    modifyResource({
                        name: FILTER_OPTIONS,
                        dataTransform: removeFromData("tags", "value", tagId)
                    })
                )

                // it's no longer the current tag
                yield put(deleteTagComplete())
            }

            yield put(updateUserSuccess())
        },

        function*(error) {
            const formValues = yield select(getFormValues(C.TAG_USERS_FORM))
            const failureValues = {
                privilege: oldPrivilege,
                error: true
            }

            if (!formValues) {
                // the form got removed already. Don't worry about rolling it back now
                return
            }

            if (body.userIds) {
                const rolledBackUserPrivileges = _.map(
                    formValues[C.FORM_FIELDS.USER_PRIVILEGES],
                    updateIfMatch(body.userIds, "id", failureValues)
                )
                yield put(
                    change(
                        C.TAG_USERS_FORM,
                        C.FORM_FIELDS.USER_PRIVILEGES,
                        rolledBackUserPrivileges
                    )
                )
            }

            if (body.unitIds) {
                const rolledBackPracticePrivileges = _.map(
                    formValues[C.FORM_FIELDS.PRACTICE_PRIVILEGES],
                    updateIfMatch(body.unitIds, "id", failureValues)
                )
                yield put(
                    change(
                        C.TAG_USERS_FORM,
                        C.FORM_FIELDS.PRACTICE_PRIVILEGES,
                        rolledBackPracticePrivileges
                    )
                )
            }

            yield* notifySimpleError("Failed to grant tag access.")(error)
        }
    )

    yield put(setTagPrivilegesComplete(body))
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeLatest(C.SAVE_TAG, saveTag)
        }),
        call(function*() {
            yield takeLatest(C.CANCEL_EDIT_TAG, onCancelEdit)
        }),
        call(function*() {
            yield takeEvery(C.DELETE_TAG, deleteTag)
        }),
        call(function*() {
            yield takeEvery(C.SET_TAG_PRIVILEGES, setTagPrivileges)
        })
    ])
}
