import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Button, Modal } from "react-bootstrap"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"
import { persistSettings, removeUserPreference } from "../actions"
import { TableSettingsList } from "./TableSettingsList"

export const TableSettingsInstructions = ({ columns, noFreeze }) => (
    <>
        <p>Click/tap on a column to show or hide it. </p>
        <p>
            Click/tap and drag on <span className="fa fa-bars drag-handle" /> to
            rearrange the order of the columns.
        </p>
        {noFreeze || (
            <p>
                Columns to the left of the vertical line are frozen and will not
                scroll horizontally with the rest of the table.
            </p>
        )}
        {_.some(columns, col => col.forceSelected) && (
            <p>
                Columns in lighter blue cannot be deselected, but can still be
                moved.
            </p>
        )}
    </>
)

export class TableSettings extends Component {
    state = { show: false, options: [], freezeOptions: [] }

    setOptions = (options, freezeOptions) =>
        this.setState({ options, freezeOptions })

    show = () => {
        const { noFreeze, columns = [], frozenColumns = [] } = this.props
        this.setState({
            show: true,
            options: noFreeze ? [...frozenColumns, ...columns] : columns,
            freezeOptions: noFreeze ? [] : frozenColumns
        })
    }

    hide = () => this.setState({ show: false })

    saveColumns = () => {
        // XXX might move these action calls to a saga, idk. just to match the others below
        const tableInfo = {
            columns: this.state.options,
            frozenColumns: this.state.freezeOptions
        }
        this.props.persistSettings(this.props.tableName, tableInfo)
        this.props.updateTable(this.props.tableName, tableInfo)
        this.setState({ show: false })
    }

    restoreDefaults = () => {
        this.props.resetTable()
        this.setState({ show: false })
    }

    componentDidMount() {
        this.props.initializeTable()
    }

    render() {
        const modalSize =
            this.props.columns.length > 11
                ? "xxl"
                : this.props.columns.length > 7
                ? "xl"
                : "lg"

        return (
            <div
                className={this.props.className || "pull-right"}
                style={{ height: "21px", ...this.props.style }}
            >
                <Hoverlay tooltip="Table Settings" className="tooltip-blue">
                    <span
                        className="fa fa-cog clickable-icon btn-settings"
                        style={{ fontSize: "1.5em", cursor: "pointer" }}
                        onClick={this.show}
                    />
                </Hoverlay>
                <Modal
                    size={modalSize}
                    show={this.state.show}
                    onHide={this.hide}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Table Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h2>Column Visibility and Order</h2>
                        <TableSettingsInstructions {...this.props} />
                        {this.props.isAdminUser && (
                            <p>
                                <strong>
                                    The columns selected by the admin user
                                    become the default columns for every other
                                    user.
                                </strong>
                            </p>
                        )}
                        <TableSettingsList
                            {...this.state}
                            setOptions={this.setOptions}
                            noFreeze={this.props.noFreeze}
                        />
                        {this.props.TableLegend && <h2>Table Legend</h2>}
                        {this.props.TableLegend}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            style={{ float: "left" }}
                            onClick={this.restoreDefaults}
                        >
                            Restore defaults
                        </Button>
                        <Button variant="secondary" onClick={this.hide}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.saveColumns}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default connect(
    (state, { tableName }) => ({
        tableSettings: state[C.NAME].columns[tableName] || {},
        tableDefaults: state[C.NAME].defaultColumns[tableName] || {},
        isAdminUser: state.authentication.userId === 0
    }),
    {
        persistSettings,
        removeUserPreference
    }
)(TableSettings)
