export const COMMUNITY_LINKAGES = "COMMUNITY_LINKAGES"
export const COMMUNITY_LINKAGES_STATE = "COMMUNITY_LINKAGES_STATE"

export const ADD_COMMUNITY_LINKAGE_FORM = "ADD_COMMUNITY_LINKAGE_FORM"

export const ADD_NEW_COMMUNITY_LINKAGE = "ADD_NEW_COMMUNITY_LINKAGES"
export const UPDATE_COMMUNITY_LINKAGE = "UPDATE_COMMUNITY_LINKAGE"
export const PRINT_PDF = "PRINT_COMMUNITY_LINKAGES_PDF"
export const EXPORT_CSV = "EXPORT_COMMUNITY_LINKAGES_CSV"

export const ADD_COMMUNITY_LINKAGE_FIELDS = {
    CATEGORY: "categoryId",
    LOCATION: "locationId",
    STATUS: "statusId",
    DATE: "date",
    COMMENT: "comment"
}

export const CHANGE_NEW_LINKAGE_STATUS = "NEW_LINKAGE_SUCCESS"
export const CHANGE_UPDATED_LINKAGE_STATUS = "CHANGE_UPDATED_LINKAGE_STATUS"

export const initialState = {
    submitSuccess: false,
    updateSuccess: false,
    recentlyUpdatedLinkage: undefined
}

export const LINAKGES_TRANSLATION = {
    [ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY]: "Category",
    [ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION]: "Location",
    [ADD_COMMUNITY_LINKAGE_FIELDS.STATUS]: "Status",
    [ADD_COMMUNITY_LINKAGE_FIELDS.DATE]: "Date",
    [ADD_COMMUNITY_LINKAGE_FIELDS.COMMENT]: "Comment"
}
