import React from "react"
import { Button, Modal } from "react-bootstrap"
import OrgName from "../../core/OrgName"

const ReminderModal = ({ hideModal, show }) => (
    <Modal show={show}>
        <Modal.Header>
            <Modal.Title>Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Protected health information (PHI) that is used by provider
                offices shall be limited to the minimum necessary information
                needed to accomplish the services for which it is engaged by
                those covered entities and that all of the PHI disclosed by{" "}
                <OrgName />
                is limited to the minimum necessary to accomplish the purposes
                of outside entities.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={hideModal}>
                Acknowledged
            </Button>
        </Modal.Footer>
    </Modal>
)

export default ReminderModal
