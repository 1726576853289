import moment from "moment"
import _ from "lodash"
import * as PLC from "../patient_list/constants"
import { PATH } from "../constants"
import { MONTH_STRING } from "../dates"

export const NAME = "QUALITY_SUMMARY_REPORT"
export const QSR_OPTIONS = "QSR_OPTIONS"
export const QUALITY_SUMMARY_REPORT_TABLE = "QUALITY_SUMMARY_REPORT_TABLE"
export const QUALITY_SUMMARY_FORM = "QUALITY_SUMMARY_FORM"
export const COVERAGE_NAME = "COVERAGE_REFERENCE"
export const PERFORMANCE_NAME = "QSR_PERFORMANCE"
export const PRINT_QSR = "PRINT_QSR"
export const EXPORT_QSR = "EXPORT_QSR"
export const SET_QSR_FILTERS = "SET_QSR_FILTERS"
export const SET_QSR_CHART_MEASURE = "SET_QSR_CHART_MEASURE"

export const TABLE_KEYS = {
    PRACTICE_ID: "practiceUnitId",
    PRACTICE_NAME: "practiceName",
    PROVIDER_ID: "providerId",
    PROVIDER_NAME: "providerName",
    MEASURE_ID: "measureId",
    MEASURE_NAME: "measureName",
    PRODUCT_ID: "productClassId",
    PRODUCT_CLASS_NAME: "productClassName",
    PRODUCT_GROUP_ID: "productGroupId",
    PRODUCT_GROUP_NAME: "productGroupName",
    PRODUCT_GROUP_DESC: "productGroupDesc",
    PRODUCT_GROUP_COLOR: "productGroupColor",
    PAYER_ID: "payerId",
    PAYER_NAME: "payerName",
    ELIGIBLE: "numElig",
    MET: "numMet",
    UNMET: "numUnmet",
    TARGET: "numNeeded",
    NEEDED_FOR_GOAL: "neededForGoal",
    RATE_TO_DATE: "rateToDate",
    GOAL_RATE: "goalRate",
    HVPA_RATE: "hvpaRate",
    PAYOUT_AVAILABLE: "payoutAvailable",
    PAYOUT_EARNED: "payoutEarned",
    PAYOUT_REMAINING: "payoutRemaining",
    GOAL_RATE_ALL: "goalRateAll",
    RATE_BREAKDOWN: "rateBreakdown",
    MONEY_BREAKDOWN: "moneyBreakdown",
    LAST_LOAD_DATE: "lastLoadDt",
    SERVICE_THROUGH_DATE: "serviceThroughDt",
    COVERAGE_TYPE: "type",
    COVERAGE_NAME: "name",
    COVERAGE_DESCRIPTION: "description",
    IS_TCM_MEASURE: "isTcmMeasure",
    INCENTIVE_DIFFERENCE: "incentiveDifference",
    LINK: "link",
    REP_AS_OF_DT: "repAsOfDt",
    PATH
}

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.MEASURE_NAME]: "Measure Name",
    [TABLE_KEYS.PAYER_NAME]: "Payer",
    [TABLE_KEYS.PRODUCT_CLASS_NAME]: "Product Class",
    [TABLE_KEYS.PRODUCT_GROUP_NAME]: "Product Group",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice Unit",
    [TABLE_KEYS.PROVIDER_NAME]: "Provider",
    [TABLE_KEYS.ELIGIBLE]: "Total Eligible",
    [TABLE_KEYS.TARGET]: "Target Goal",
    [TABLE_KEYS.MET]: "Met",
    [TABLE_KEYS.NEEDED_FOR_GOAL]: "Needed For Goal",
    [TABLE_KEYS.GOAL_RATE]: "Target Rate",
    [TABLE_KEYS.RATE_TO_DATE]: "Rate to Date",
    [TABLE_KEYS.HVPA_RATE]: "Network Rate",
    [TABLE_KEYS.PAYOUT_AVAILABLE]: "$Available",
    [TABLE_KEYS.PAYOUT_EARNED]: "$Earned",
    [TABLE_KEYS.PAYOUT_REMAINING]: "$Remaining",
    [TABLE_KEYS.INCENTIVE_DIFFERENCE]: "Incentive Reconciliation"
}

export const FILTERS = {
    AS_OF_DATE: "asOf",
    YEAR: "year",
    MONTH: "repMonNumeric",
    UNITS: "units",
    PAYERS: "payers",
    PROVIDERS: "providers",
    PRODUCTS: "productClasses",
    PRODUCT_GROUPS: "productGroups",
    MEASURES: "measures",
    POPULATION: "population",
    CHART_MEASURE: "chartMeasureId", // not used in the form per se, but in a separate input
    HCC_CONDITIONS: "hccConditions",
    REGIONS: "regions"
}

export const QSR_TRANSLATION = {
    [FILTERS.AS_OF_DATE]: "End Date",
    [FILTERS.YEAR]: "Year",
    [FILTERS.MONTH]: "Month",
    [FILTERS.UNITS]: "Practice Unit(s)",
    [FILTERS.PAYERS]: "Payer(s)",
    [FILTERS.PROVIDERS]: "Provider(s)",
    [FILTERS.PRODUCTS]: "Product(s)",
    [FILTERS.PRODUCT_GROUPS]: "Product Group(s)",
    [FILTERS.POPULATION]: "Population",
    [FILTERS.MEASURES]: "Measure(s)",
    [FILTERS.CHART_MEASURE]: "Measure",
    [FILTERS.HCC_CONDITIONS]: "HCC Condition(s)",
    [FILTERS.REGIONS]: "Practice Tag(s)"
}

// map QSR filters to FILTER_OPTIONS filters.
// They're basically all the same except for providers/pcps.
export const FILTER_MAPPING = {
    [FILTERS.UNITS]: PLC.FILTERS.PRACTICE_UNITS,
    [FILTERS.PROVIDERS]: PLC.FILTERS.PCPS,
    [FILTERS.PAYERS]: PLC.FILTERS.PAYERS,
    [FILTERS.MEASURES]: PLC.FILTERS.MEASURES,
    [FILTERS.PRODUCTS]: PLC.FILTERS.PRODUCTS,
    [FILTERS.PRODUCT_GROUPS]: PLC.FILTERS.PRODUCT_GROUPS
}

export const initialValues = {
    [FILTERS.AS_OF_DATE]: moment().format(MONTH_STRING)
    // population filter is set from site settings
}

export const BB_KEYS = {
    X: "x",
    GOAL_RATE: "payerGoalRate",
    HVPA_RATE: "hvpaRate",
    PRACTICE_RATE: "practiceRate",
    PROVIDER_RATE: "providerRate"
}
export const BB_LABELS = {
    [BB_KEYS.GOAL_RATE]: "All Payer Plan Goal",
    [BB_KEYS.HVPA_RATE]: "Network Performance"
}

export const BB_COLORS = {
    [BB_KEYS.HVPA_RATE]: "#1874cd",
    [BB_KEYS.GOAL_RATE]: "#b55093",
    [BB_KEYS.PRACTICE_RATE]: "#8ccf3f",
    [BB_KEYS.PROVIDER_RATE]: "#ed5643"
}

export const initialState = { filters: initialValues }

export const COLUMN_AGGREGATORS = {
    [TABLE_KEYS.ELIGIBLE]: _.sum,
    [TABLE_KEYS.TARGET]: (vals, children) => {
        const { goalRate } = _.maxBy(children, TABLE_KEYS.GOAL_RATE)
        const totalElig = _.sumBy(children, TABLE_KEYS.ELIGIBLE)
        return Math.ceil((totalElig * goalRate) / 100)
    },
    [TABLE_KEYS.MET]: _.sum,
    [TABLE_KEYS.NEEDED_FOR_GOAL]: (vals, children) => {
        const { goalRate } = _.maxBy(children, TABLE_KEYS.GOAL_RATE)
        const totalElig = _.sumBy(children, TABLE_KEYS.ELIGIBLE)
        const totalMet = _.sumBy(children, TABLE_KEYS.MET)
        const needed = Math.ceil((totalElig * goalRate) / 100 - totalMet)
        return Math.max(0, needed)
    },
    [TABLE_KEYS.GOAL_RATE]: _.max,
    [TABLE_KEYS.RATE_TO_DATE]: (values, children) => {
        const totalElig = _.sumBy(children, TABLE_KEYS.ELIGIBLE)
        const totalMet = _.sumBy(children, TABLE_KEYS.MET)
        return Number((100 * totalMet) / totalElig)
    },
    [TABLE_KEYS.HVPA_RATE]: _.head,
    [TABLE_KEYS.PAYOUT_AVAILABLE]: _.sum,
    [TABLE_KEYS.PAYOUT_EARNED]: _.sum,
    [TABLE_KEYS.PAYOUT_REMAINING]: _.sum,
    [TABLE_KEYS.INCENTIVE_DIFFERENCE]: _.sum
}
