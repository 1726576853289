import React from "react"
import { connect } from "react-redux"
import { fetcher, getMetaData, getResourceData } from "../../core/fetcher"
import { FilterButtonsHeader } from "../../core/FilterButtonsHeader"
import Select from "../../core/Select"
import { FILTER_OPTIONS } from "../../constants"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import { countFiltersSelector, statusCountsSelector } from "../selectors"
import { setAuditFilter, setStatusFilter } from "../actions"
import * as C from "../constants"
import spinner from "../../../assets/images/loading.gif"

const AuditsCounter = ({
    loading,
    auditOptions,
    onSelect,
    selectedAudit,
    ...props
}) => (
    <>
        {loading ? (
            <img src={spinner} alt="Loading..." />
        ) : (
            <FilterButtonsHeader {...props} />
        )}
        <Select
            value={selectedAudit}
            placeholder="All Audits"
            options={auditOptions}
            onChange={onSelect}
            loading={loading}
            disabled={loading}
            isClearable
        />
    </>
)

export default fetcher({
    name: C.PATIENT_AUDIT_COUNTS,
    endpoint: state =>
        urlWithParams("/api/patient_audit/counts", countFiltersSelector(state)),
    method: ApiTypes.GET
})(
    connect(
        (state, { loading }) => ({
            categories: C.AUDIT_CATEGORIES,
            auditOptions: getResourceData(
                FILTER_OPTIONS,
                data => data.audits
            )(state),
            loading: loading || getMetaData(FILTER_OPTIONS)(state)?.loading,
            counts: statusCountsSelector(state),
            selectedAudit: state[C.NAME].filters[C.FILTERS.AUDIT],
            values: state[C.NAME].filters[C.FILTERS.STATUS] ?? []
        }),
        { onChange: setStatusFilter, onSelect: setAuditFilter }
    )(AuditsCounter)
)
