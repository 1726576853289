import React, { useMemo } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import _ from "lodash"

import { encodeFiltersInUrl, getFilterValues, itemsToObject } from "../../utils"
import { Hoverlay } from "../../core/Hoverlay"
import * as QC from "../../qsr/constants"
import * as C from "../constants"

const qsrRowToPatientFilters = (rowData, { columns, frozenColumns }) =>
    getFilterValues(
        rowData,
        itemsToObject(
            [...frozenColumns, ...columns].filter(col => col.filterField),
            col => QC.FILTER_MAPPING[col.filterField],
            col => col.idField
        )
    )

export const PatientListLink = ({
    cellData,
    rowData,
    tableData,
    filters,
    extraFilters // passed in via columnData
}) => {
    const url = useMemo(() => {
        const allFilters = {
            ...filters,
            ...qsrRowToPatientFilters(rowData, tableData), // this comes after filters because you might be, say, filtering on two measures, but have clicked on a row for one specific measure. In a case like that, you only want this link to filter on the one measure.
            ...(_.isFunction(extraFilters)
                ? extraFilters({ rowData, filters })
                : extraFilters)
        }
        return encodeFiltersInUrl("/patients", allFilters)
    }, [rowData, tableData, filters])

    return <Link to={url}>{cellData}</Link>
}

const PatientListLinkCell = ({ overlay, ...props }) => (
    // for some reason a Link can't actually take a ref? So we force a wrapper here instead
    <Hoverlay tooltip={props.cellData} disabled={!overlay} forceWrapper>
        <PatientListLink {...props} />
    </Hoverlay>
)

export default connect((state, { columnData: { store, ...columnData } }) => ({
    ...state[store || C.NAME],
    ...columnData
}))(PatientListLinkCell)
