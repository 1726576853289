import { createSelector } from "reselect"
import { getMetaData, getResourceData } from "../core/fetcher"
import _ from "lodash"
import { PERIODS } from "../pickers/constants"
import {
    categoriesSelector,
    payersSelector,
    productsSelector,
    singleChartCombiner,
    subcategoriesSelector
} from "../cost/selectors"
import { itemsToObject, objToValueLabel } from "../utils"
import { FILTER_OPTIONS } from "../constants"
import * as C from "./constants"

const logosSelector = getResourceData(
    FILTER_OPTIONS,
    data => data[C.PAYER_LOGOS] || {}
)

export const lookupSelector = createSelector(
    categoriesSelector,
    subcategoriesSelector,
    (categories, subcategories) => [{}, {}, categories, subcategories]
)

export const payerInfoSelector = createSelector(
    payersSelector,
    logosSelector,
    productsSelector,
    (payers, logos, products) => rowData => ({
        [C.TABLE_KEYS.PAYER_NAME]: payers[rowData[C.TABLE_KEYS.PAYER_ID]],
        [C.TABLE_KEYS.PAYER_LOGO]: logos[rowData[C.TABLE_KEYS.PAYER_ID]],
        [C.TABLE_KEYS.PRODUCT_NAME]: products[rowData[C.TABLE_KEYS.PRODUCT_ID]]
    })
)

export const modalTitleSelector = createSelector(
    state => state[C.NAME].modalFilters,
    categoriesSelector,
    subcategoriesSelector,
    (modalFilters, categories, subcategories) =>
        [
            categories[modalFilters[C.TABLE_KEYS.CATEGORY_ID]],
            subcategories[modalFilters[C.TABLE_KEYS.SUBCATEGORY_ID]]
        ].join(" - ")
)

const payerProductKey = row =>
    `${row[C.TABLE_KEYS.PAYER_ID]}-${row[C.TABLE_KEYS.PRODUCT_ID]}`

export const patientTableDataSelector = createSelector(
    lookupSelector,
    payerInfoSelector,
    getResourceData(C.TABLE_NAME, data => data.costs),
    (lookup, getPayerInfo, data) =>
        _.sortBy(data, [
            C.TABLE_KEYS.PAYER_ID,
            C.TABLE_KEYS.PRODUCT_ID,
            C.TABLE_KEYS.CATEGORY_ID,
            C.TABLE_KEYS.SUBCATEGORY_ID
        ])
            .map(rowData => {
                const path = _.compact([
                    payerProductKey(rowData),
                    rowData[C.TABLE_KEYS.CATEGORY_ID],
                    rowData[C.TABLE_KEYS.SUBCATEGORY_ID]
                ])
                const displayName =
                    path.length > 0 ? lookup[path.length][_.last(path)] : ""
                return {
                    ...rowData,
                    [C.TABLE_KEYS.PATH]: path,
                    [C.TABLE_KEYS.DISPLAY_NAME]: displayName,
                    ...getPayerInfo(rowData)
                }
            })
            .map((row, index, data) => {
                const next = data[index + 1]
                const isLeaf =
                    !next ||
                    row[C.TABLE_KEYS.PATH].length >=
                        next[C.TABLE_KEYS.PATH].length
                return { ...row, isLeaf }
            })
)

export const patientChartDataSelector = createSelector(
    getResourceData(C.PATIENT_COST_CHART),
    getMetaData(C.PATIENT_COST_CHART),
    getMetaData(FILTER_OPTIONS),
    categoriesSelector,
    singleChartCombiner
)

export const payerOptionsSelector = createSelector(
    patientTableDataSelector,
    tableData => {
        const lookup = itemsToObject(
            tableData,
            row => payerProductKey(row),
            row =>
                `${row[C.TABLE_KEYS.PAYER_NAME]} - ${
                    row[C.TABLE_KEYS.PRODUCT_NAME]
                }`
        )

        return objToValueLabel(lookup)
    }
)

export const periodSelector = state =>
    _.get(state, [C.NAME, C.PERIOD], PERIODS.CURRENT_YEAR)
