export const NAME = "REMINDER_LETTER_DETAILS"

export const GET_PATIENT_LETTER = "GET_PATIENT_LETTER"

export const TABLE_KEYS = {
    LETTER_ID: "letterId",
    LETTER_TYPE: "letterName",
    LETTER_USER_NAME: "letterUserName",
    PRINT_DATE: "letterDt"
}

export const TRANSLATION = {
    [TABLE_KEYS.LETTER_TYPE]: "Letter Type",
    [TABLE_KEYS.LETTER_USER_NAME]: "User",
    [TABLE_KEYS.PRINT_DATE]: "Date"
}
