import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import alerts from "../modules/alerts"

const AlertsPage = () => (
    <>
        <SiteTitle title="Manage Alerts" />
        <Breadcrumbs items={[["Dashboard", "/dashboard"], ["Alerts"]]} />
        <alerts.components.default />
    </>
)

export default AlertsPage
