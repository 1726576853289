import React, { useState } from "react"
import classNames from "classnames"
import { Dropdown } from "react-bootstrap"
import { matchNavItem } from "../helpers"

const HoverNavDropDown = ({ children, id, label, location, matchOn }) => {
    const [show, setShow] = useState(false)

    const active = matchNavItem(matchOn, location)

    return (
        <Dropdown
            as="li"
            id={id}
            show={show}
            className={classNames("nav-item", { show, active })} // needs to be both a dropdown and a nav item
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onToggle={() => setShow(!show)}
            navbar
        >
            <Dropdown.Toggle
                as="a"
                style={{ borderRadius: 0 }}
                className={classNames({ active })}
                disabled={!active}
            >
                {label}
            </Dropdown.Toggle>
            <Dropdown.Menu as="ul">{children}</Dropdown.Menu>
        </Dropdown>
    )
}
export default HoverNavDropDown
