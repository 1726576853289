import * as C from "./constants"
import { undoTransformFilter } from "../transformFilter"
import { findAndUpdate, urlWithParams } from "../utils"
import { ApiTypes, getDefaultHeaders } from "../api"

export const getCampaignTableName = campaignId => `CAMPAIGN-${campaignId}`

export const updateCampaignPage = updatedCampaign => () => ({
    ...updatedCampaign,
    campaign: {
        ...updatedCampaign.campaign,
        filter: undoTransformFilter(
            JSON.parse(updatedCampaign.campaign.filterJson)
        )
    }
})

export const updateCampaignList = updatedCampaign => data => ({
    campaigns: data?.campaigns
        ?.map(({ campaignId }) => campaignId)
        .includes(updatedCampaign.campaign[C.TABLE_KEYS.CAMPAIGN_ID])
        ? data.campaigns.map(item =>
              item[C.TABLE_KEYS.CAMPAIGN_ID] ===
              updatedCampaign.campaign[C.TABLE_KEYS.CAMPAIGN_ID]
                  ? updatedCampaign.campaign
                  : item
          )
        : [...data.campaigns, updatedCampaign.campaign]
})

export const removeCampaignFromList = id => data => ({
    campaigns: data?.campaigns.filter(item => item.campaignId !== id)
})

const campaignUpdater = update => campaignId => data => ({
    campaigns: findAndUpdate(
        data?.campaigns,
        update,
        C.TABLE_KEYS.CAMPAIGN_ID,
        campaignId
    )
})

export const unsubscribeToCampaign = campaignUpdater({ [C.UNSUBSCRIBED]: true })
export const subscribeToCampaign = campaignUpdater({ [C.UNSUBSCRIBED]: false })

export const pinCampaignInList = campaignUpdater({ [C.USER_PINNED]: true })
export const unpinCampaignInList = campaignUpdater({ [C.USER_PINNED]: false })

export const unpinCampaignFromTopCampaigns = campaignId => data => ({
    campaigns: data?.campaigns.filter(item => item.campaignId !== campaignId)
})

// form validation

export const asyncValidate = (values, _dispatch, { authentication }) => {
    const updates = {
        name: values[C.TABLE_KEYS.CAMPAIGN_NAME],
        id: values[C.TABLE_KEYS.CAMPAIGN_ID] || null
    }
    return fetch(urlWithParams(`/api/campaigns/validName`, updates), {
        method: ApiTypes.GET,
        headers: getDefaultHeaders(authentication)
    }).then(body =>
        body
            ? Promise.resolve(body)
            : Promise.reject({
                  [C.TABLE_KEYS.CAMPAIGN_NAME]:
                      "is already taken, please choose another one."
              })
    )
}
