import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { modifyData, modifyResource } from "../core/fetcher"
import { ApiTypes, tryFetch } from "../api"
import { notifySimpleError } from "../notifications"
import * as C from "./constants"

function* updateCondition(conditionId, removed, changingStatus) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: modifyData(
                "hccConditions",
                {
                    [C.TABLE_KEYS.REMOVED]: removed,
                    changingStatus
                },
                C.TABLE_KEYS.CONDITION_ID,
                conditionId
            )
        })
    )
}
function* updateGenCondition(genConditionId, removed, changingStatus) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: modifyData(
                "genConditions",
                {
                    [C.TABLE_KEYS.REMOVED]: removed,
                    changingStatus
                },
                C.TABLE_KEYS.GEN_CONDITION_ID,
                genConditionId
            )
        })
    )
}

function* setCondition(conditionId, removing, isGeneral) {
    const update = isGeneral ? updateGenCondition : updateCondition
    const type = isGeneral ? "gen_conditions" : "conditions"

    yield* update(conditionId, removing, true)
    const patientId = yield select(state => state.poc_id)

    yield* tryFetch(
        {
            url: `/api/patients/${patientId}/${type}/${conditionId}`,
            method: removing ? ApiTypes.DELETE : ApiTypes.PUT
        },
        function*() {
            yield* update(conditionId, removing, false)
        },
        function*(error) {
            yield* update(conditionId, !removing, false)

            const errorMessage = removing
                ? "Failed to remove patient condition"
                : "Failed to restore patient condition"
            yield* notifySimpleError(errorMessage)(error)
        }
    )
}

export function* removeCondition({ conditionId }) {
    yield* setCondition(conditionId, true)
}
export function* restoreCondition({ conditionId }) {
    yield* setCondition(conditionId, false)
}
export function* removeGenCondition({ conditionId }) {
    yield* setCondition(conditionId, true, true)
}
export function* restoreGenCondition({ conditionId }) {
    yield* setCondition(conditionId, false, true)
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.REMOVE_CONDITION, removeCondition)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_CONDITION, restoreCondition)
        }),
        call(function*() {
            yield takeEvery(C.REMOVE_GEN_CONDITION, removeGenCondition)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_GEN_CONDITION, restoreGenCondition)
        })
    ])
}
