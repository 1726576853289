import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { getFormValues } from "redux-form"
import { ApiTypes } from "../api"
import { tryWithNotifications } from "../notifications"
import {
    addFavorite,
    updateFavorite,
    removeFavorite,
    unRemoveFavorite,
    setFavoriteLoading
} from "../table_settings/actions"
import {
    selectFavoriteFilters,
    setShowEditFavoriteModal,
    setShowFavoriteNameModal,
    stopSavingFilters
} from "./actions"
import * as C from "./constants"

function* saveFavorite({ formName, skipFields = [], updatingSelected }) {
    const formValues = yield select(getFormValues(formName))
    const [name, id] = _.at(formValues, [C.FILTERS.NAME, C.FILTERS.ID])
    const filters = _.omit(formValues, [
        C.FILTERS.NAME,
        C.FILTERS.ID,
        ...skipFields
    ])
    const filtersString = JSON.stringify(filters)
    const body = {
        name, // yeah the param is just called "name"
        [C.TABLE_KEYS.FORM]: formName,
        [C.TABLE_KEYS.FILTERS]: filtersString
    }

    yield* tryWithNotifications(
        {
            url: "/api/user/settings/favorites/" + (id || ""),
            method: ApiTypes.PUT,
            body
        },
        function*(newId) {
            // newId will be null if we're just editing
            const func = id ? updateFavorite : addFavorite
            const favorite = {
                [C.TABLE_KEYS.NAME]: name,
                [C.TABLE_KEYS.ID]: id || newId,
                [C.TABLE_KEYS.FORM]: formName,
                [C.TABLE_KEYS.FILTERS]: filters
            }
            yield put(func(formName, favorite))

            if (updatingSelected) {
                yield put(selectFavoriteFilters(formName, favorite))
            }

            yield put(setShowFavoriteNameModal(null))
            yield put(setShowEditFavoriteModal(null))

            return `Filter set "${name}" has been saved.`
        },
        function*() {
            return `Unable to save filter set "${name}".`
        }
    )
    yield put(stopSavingFilters())
}

function* deleteFavorite({ payload }) {
    yield put(
        setFavoriteLoading(payload[C.TABLE_KEYS.FORM], payload[C.TABLE_KEYS.ID])
    )

    yield* tryWithNotifications(
        {
            message: null, // we'll have a loading spinner instead.
            url: `/api/user/settings/favorites/${payload[C.TABLE_KEYS.ID]}`,
            method: ApiTypes.DELETE
        },
        function*() {
            yield put(
                removeFavorite(
                    payload[C.TABLE_KEYS.FORM],
                    payload[C.TABLE_KEYS.ID]
                )
            )
            return null // we don't need a notification, the strikeout should suffice
        },
        function*() {
            return `Unable to delete filter set "${
                payload[C.TABLE_KEYS.NAME]
            }".`
        }
    )
}
function* unDeleteFavorite({ payload }) {
    yield put(
        setFavoriteLoading(payload[C.TABLE_KEYS.FORM], payload[C.TABLE_KEYS.ID])
    )

    const filtersString = JSON.stringify(payload[C.TABLE_KEYS.FILTERS])
    const body = {
        name: payload[C.TABLE_KEYS.NAME],
        [C.TABLE_KEYS.FORM]: payload[C.TABLE_KEYS.FORM],
        [C.TABLE_KEYS.FILTERS]: filtersString
    }

    yield* tryWithNotifications(
        {
            message: null, // we'll have a loading spinner instead.
            url: "/api/user/settings/favorites/",
            method: ApiTypes.PUT,
            body
        },
        function*(newId) {
            yield put(
                unRemoveFavorite(
                    payload[C.TABLE_KEYS.FORM],
                    payload[C.TABLE_KEYS.ID],
                    newId
                )
            )

            return null // we don't need a notification, the removal of the strikeout should suffice
        },
        function*() {
            return `Unable to restore filter set "${
                payload[C.TABLE_KEYS.NAME]
            }".`
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SAVE_FAVORITE_FILTERS, saveFavorite)
        }),
        call(function*() {
            yield takeEvery(C.DELETE_FAVORITE_FILTERS, deleteFavorite)
        }),
        call(function*() {
            yield takeEvery(C.UNDELETE_FAVORITE_FILTERS, unDeleteFavorite)
        })
    ])
}
