import React from "react"
import { Modal } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import { tableFactory } from "../../table"
import { TABLE_COLUMNS } from "../columns"
import * as C from "../constants"
import InactivePatientsTable from "./InactivePatientsTable"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    sortBy: [C.TABLE_KEYS.EFF_DATE],
    sortDirection: [SortDirection.DESC]
})

export const InactivePatients = ({ hideInactivePatients, open }) => (
    <Modal
        dialogClassName="inactive-patients"
        size="xl"
        show={open}
        onHide={hideInactivePatients}
    >
        <Modal.Header closeButton>
            <Modal.Title>Inactive Patients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <Settings />
                <Description Component="p" />
                <p>
                    Patients marked for reactivation are highlighted in{" "}
                    <span
                        style={{
                            padding: "0 3px",
                            backgroundColor: "#b3e4cb"
                        }}
                    >
                        green
                    </span>
                    .
                </p>
            </div>

            <InactivePatientsTable Table={Table} />
        </Modal.Body>
    </Modal>
)
