import React, { Component } from "react"
import _ from "lodash"
import classNames from "classnames"
import { getParentStamp } from "../helpers"

const CategoryLine = ({ item, indent, single }) => (
    <div className={classNames("category-filters-line", { indent, single })}>
        {item}
    </div>
)

const separators = [
    [item => item], // placeholder in case something weird happens
    [items => items.join(", ")],
    [items => items.join("; "), items => ` (${items.join(", ")})`],
    [
        items =>
            items.map((item, index) => (
                <CategoryLine
                    key={index}
                    item={item}
                    single={items.length === 1}
                />
            )),
        items => ` - ${items.join("; ")}`,
        items => ` (${items.join(", ")})`
    ],
    [
        items =>
            items.map((item, index) => (
                <CategoryLine key={index} item={item} />
            )),
        items =>
            items.map((item, index) => (
                <CategoryLine key={index} item={item} indent />
            )),
        items => ` - ${items.join("; ")}`,
        items => ` (${items.join(", ")})` // TODO maybe italicize these? Though when I try, the components don't seem to work right
    ]
]

export class CategoryFilter extends Component {
    getCategoriesFilters = stamps => {
        // build a tree of selected categories
        const filtersTree = {}
        // noinspection JSCheckFunctionSignatures
        stamps.forEach(stamp =>
            _.setWith(filtersTree, stamp + ".stamp", stamp, Object)
        )

        // fill in the tree with paths, labels, and depths
        const annotated = this.annotateNodes(filtersTree, 0)

        // turn that into a printable form
        const filters = this.displayCategories(annotated, 0, annotated.depth)
        return {
            depth: annotated.depth,
            filters
        }
    }

    // annotate this node and its descendants with stamp, label, and depth
    annotateNodes = (node, depth) => {
        const { stamp, ...filters } = node

        if (
            _.isEmpty(filters) || // this is a leaf node
            stamp // this is fully checked
        ) {
            const label = _.isEmpty(stamp) ? "" : this.props.lookup(stamp)
            return { stamp, label, depth }
        }

        // otherwise this is half-checked

        // figure out a stamp for this node based on its children
        const children = _.mapValues(filters, subNode =>
            this.annotateNodes(subNode, depth + 1)
        )
        const childValues = Object.values(children)
        const derivedStamp = getParentStamp(_.head(childValues).stamp)

        // get the rest of the data we'll need to return
        const label = derivedStamp ? this.props.lookup(derivedStamp) : "" // no label for top-level "Total" node
        const maxDepth = Math.max(...childValues.map(child => child.depth))

        return { label, stamp: derivedStamp, depth: maxDepth, ...children }
    }

    displayCategories = (node, curDepth, maxDepth) => {
        const { label = "", depth, stamp, ...children } = node

        if (_.isEmpty(children)) {
            return label
        }
        const childStrings = Object.values(children).map(child =>
            this.displayCategories(child, curDepth + 1, maxDepth)
        )
        const joined = separators[maxDepth][curDepth](childStrings)
        return _.isString(joined) ? (
            label + joined
        ) : (
            <span>
                {label} {joined}
            </span>
        )
    }

    render() {
        const { depth, filters } = this.getCategoriesFilters(this.props.filter)
        return (
            <div
                className={classNames("category-filters", {
                    "category-filters-block": depth > 2
                })}
            >
                <dt>{this.props.label}:</dt>
                <dd>{filters}</dd>
            </div>
        )
    }
}
export default CategoryFilter
