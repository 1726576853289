import React from "react"
import classNames from "classnames"
import { callOrMatch } from "../../utils"

// TODO could probably unify this with MeasureCompliance somehow

export const YesNoIndicator = ({ value, yes = true, no = false, pending }) => (
    <span
        className={classNames("fa", {
            "indicator-yes": callOrMatch(yes, value),
            "indicator-no": callOrMatch(no, value),
            "indicator-pending":
                !_.isNil(pending) && callOrMatch(pending, value)
        })}
    />
)
export default YesNoIndicator
