import React, { useMemo } from "react"
import { connect } from "react-redux"
import { Field, getFormValues } from "redux-form"
import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import {
    LabelledDatePicker,
    NumericInput,
    SelectField,
    TextInput
} from "../../../core/CustomFields"
import {
    afterDateField,
    beforeDateField,
    maxLength,
    mustBeANumber
} from "../../../core/validators"
import LER from "../../../core/LoadingErrorHOC"
import { itemsToLabelValue } from "../../../utils"
import { BOOLEAN_OPTIONS } from "../../../constants"
import * as C from "../../constants"
import { getMaxLabel, getMaxName, getMinLabel, getMinName } from "../../helpers"

const inColumn = (field, component) => (
    <Col sm={6} lg={4} key={field.name}>
        {component}
    </Col>
)

// utility functions

const isBlankOrInvalidNumber = value =>
    _.isNil(value) || value === "" || Number.isNaN(Number(value))

const normalizeNumber = value =>
    isBlankOrInvalidNumber(value) ? undefined : Number(value)

const noSmallerThan = name => (value, allValues) =>
    isBlankOrInvalidNumber(value) || // if it's an invalid number, the mustBeANumber validation will catch it
    _.get(allValues, getMinName(name), Number.MIN_SAFE_INTEGER) <= Number(value)
        ? undefined
        : `${getMaxLabel(name)} must be no smaller than ${getMinLabel(name)}`

// various types of fields

// important note: don't pass arrow functions as props directly, or this'll reregister the fields on every update, potentially causing a lot of slowdown
const PairedFields = ({ name, field1, field2, ...props }) => (
    <Col lg={8}>
        <Row>
            <Col xs={6}>
                <Field
                    name={getMinName(name)}
                    label={getMinLabel(name)}
                    {...props}
                    {...field1}
                />
            </Col>
            <Col xs={6}>
                <Field
                    name={getMaxName(name)}
                    label={getMaxLabel(name)}
                    {...props}
                    {...field2}
                />
            </Col>
        </Row>
    </Col>
)

const _DateFields = ({ field }) => {
    const validateMin = useMemo(() => beforeDateField(getMaxName(field.name)), [
        field
    ])
    const validateMax = useMemo(() => afterDateField(getMinName(field.name)), [
        field
    ])
    return (
        <PairedFields
            name={field.name}
            field1={{
                validDates: validateMin,
                validate: validateMin
            }}
            field2={{
                validDates: validateMax,
                validate: validateMax
            }}
            component={LabelledDatePicker}
        />
    )
}

const DateFields = connect(state => ({
    formValues: getFormValues(C.REPORT_LIBRARY_FILTERS_FORM)(state)
}))(_DateFields)

const _NumericFields = ({ field, ...props }) => (
    <PairedFields
        name={field.name}
        field1={{
            validate: mustBeANumber
        }}
        field2={{
            validate: [mustBeANumber, noSmallerThan(field.name)]
        }}
        component={NumericInput}
        normalize={normalizeNumber}
        {...props}
    />
)
const NumericFields = connect()(_NumericFields) // It throws a "maximum update depth exceeded" error without this

const dropdownField = (field, options, props) =>
    inColumn(
        field,
        <Field
            name={field.name}
            label={field.name}
            options={options}
            component={SelectField}
            multi
            {...props}
        />
    )
const fixedItemsField = (field, items) =>
    dropdownField(field, itemsToLabelValue(items))

const booleanField = field =>
    dropdownField(field, BOOLEAN_OPTIONS, { multi: false })

// choose which type of field to use

const getField = (field, filterOptions = {}) => {
    const fieldName = field.name.toLowerCase()

    if (fieldName.startsWith("!")) {
        // fields with names starting in "!" shouldn't be filterable upon
        return null
    }
    if (fieldName === C.PATIENT_ID) {
        return null
    }

    if (C.DATE_TYPES.includes(field.type)) {
        return <DateFields key={field.name} field={field} />
    }
    if (C.INTEGER_TYPES.includes(field.type)) {
        return <NumericFields key={field.name} field={field} />
    }
    if (C.DECIMAL_TYPES.includes(field.type)) {
        return <NumericFields key={field.name} field={field} step="0.001" />
    }
    if (C.BOOLEAN_TYPES.includes(field.type)) {
        return booleanField(field)
    }

    if (fieldName in C.DROPDOWN_OPTION_KEYS) {
        return dropdownField(
            field,
            filterOptions[C.DROPDOWN_OPTION_KEYS[fieldName]]
        )
    }
    if (fieldName in C.FIXED_OPTIONS) {
        return fixedItemsField(field, C.FIXED_OPTIONS[fieldName])
    }

    for (const regex in C.REGEX_OPTION_KEYS) {
        if (new RegExp(regex).test(fieldName)) {
            return fixedItemsField(field, C.REGEX_OPTION_KEYS[regex])
        }
    }

    return inColumn(
        field,
        <Field
            name={field.name}
            label={field.name}
            component={TextInput}
            validate={maxLength(50)}
        />
    )
}

// the component proper

export const ReportFilters = ({
    fields = [],
    filterOptions = {},
    loading,
    error
}) => (
    <LER loading={loading} error={error}>
        <Row>{fields.map(field => getField(field, filterOptions))}</Row>
    </LER>
)
