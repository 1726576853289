import * as C from "./constants"

export const removeCondition = conditionId => ({
    type: C.REMOVE_CONDITION,
    conditionId
})
export const restoreCondition = conditionId => ({
    type: C.RESTORE_CONDITION,
    conditionId
})

export const removeGenCondition = conditionId => ({
    type: C.REMOVE_GEN_CONDITION,
    conditionId
})
export const restoreGenCondition = conditionId => ({
    type: C.RESTORE_GEN_CONDITION,
    conditionId
})
