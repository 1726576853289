import React from "react"
import { connect } from "react-redux"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { isModuleEnabledSelector } from "../modules/moduleUtils"
import { isExternal } from "../modules/authentication/selectors"
import { GuideLink } from "../modules/indicators"
import directory from "../modules/directory"
import toc from "../modules/toc"
import qsr_dashboard from "../modules/qsr_dashboard"
import risk_dashboard from "../modules/risk_dashboard"
import outreach_dashboard from "../modules/outreach_dashboard"
import cost_dashboard from "../modules/cost_dashboard"
import data_coverage from "../modules/data_coverage"
import campaigns from "../modules/campaigns"
import user_audit from "../modules/user_audits"
import SiteTitle from "../modules/core/SiteTitle"
import { MODULE_IDS } from "../modules/constants"

const DASHBOARD_TABS = [
    {
        name: "qsr_tab",
        title: "Quality Summary",
        PaneComponent: qsr_dashboard.components.default,
        module: MODULE_IDS.QUALITY_SUMMARY
    },
    {
        name: "risk_tab",
        title: "Risk Adjustment",
        PaneComponent: risk_dashboard.components.default,
        module: MODULE_IDS.RISK
    },
    {
        name: "outreach_tab",
        title: "Care Mgmt Incentive",
        PaneComponent: outreach_dashboard.components.default,
        module: MODULE_IDS.CARE_MANAGEMENT
    },
    {
        name: "campaigns_tab",
        title: "Favorite Campaigns",
        PaneComponent: campaigns.components.FavoriteCampaigns,
        module: MODULE_IDS.CAMPAIGNS
        // XXX may want to pass in a height to keep it sized to match other dashboard panels. Or just set the height inside.
    },
    {
        name: "cost_tab",
        title: "Cost & Utilization",
        PaneComponent: cost_dashboard.components.default,
        module: MODULE_IDS.COST_UTILIZATION
    },
    {
        name: "coverage_tab",
        title: "Data Coverage",
        PaneComponent: data_coverage.components.default,
        module: MODULE_IDS.DATA_COVERAGE
    }
]

const DashboardPage = ({ isExternalUser, moduleEnabled }) => {
    const showDirectory = moduleEnabled(MODULE_IDS.DIRECTORY) && !isExternalUser
    const defaultActiveKey = DASHBOARD_TABS.find(({ module }) =>
        moduleEnabled(module)
    )?.name

    return (
        <user_audit.components.default page={user_audit.constants.DASHBOARD}>
            <SiteTitle title="Dashboard" />
            <h1>
                Dashboard
                <GuideLink page="view-health-focus-homepage" />
            </h1>

            <Row className="row-equal-columns dashboard-group">
                {showDirectory && (
                    <Col lg={3} className="col-directory">
                        <directory.components.default />
                    </Col>
                )}
                {!!defaultActiveKey && (
                    <Col lg={showDirectory ? 9 : 12} className="dashboard-tabs">
                        <Tabs
                            defaultActiveKey={defaultActiveKey}
                            id="controlled-tabs-dashboard"
                            mountOnEnter
                        >
                            {DASHBOARD_TABS.map(
                                ({
                                    module,
                                    name,
                                    className,
                                    PaneComponent,
                                    componentProps,
                                    ...tabProps
                                }) =>
                                    moduleEnabled(module) && (
                                        <Tab
                                            key={name}
                                            eventKey={name}
                                            className={name} // can be overwritten if you passed in a className tab prop
                                            tabClassName={name}
                                            {...tabProps}
                                        >
                                            <PaneComponent
                                                {...componentProps}
                                            />
                                        </Tab>
                                    )
                            )}
                        </Tabs>
                    </Col>
                )}
                {moduleEnabled(MODULE_IDS.TRANSITIONS_OF_CARE) && (
                    <Col lg={showDirectory && !defaultActiveKey ? 9 : 12}>
                        <toc.components.default isSmall={!!defaultActiveKey} />
                    </Col>
                )}
            </Row>
        </user_audit.components.default>
    )
}

export default connect(state => ({
    isExternalUser: isExternal(state),
    moduleEnabled: isModuleEnabledSelector(state)
}))(DashboardPage)
