import {
    DescriptionCell,
    FileLinkCell
} from "../patient_audits/components/CellRenderers"
import { LongCell } from "../core/CellRenderers"
import { timestampFormat } from "../dates"
import { columnBuilder } from "../table"
import { ALIGNMENTS, MODULE_IDS } from "../constants"
import * as C from "./constants"
import { DeleteCell } from "./components/CellRenderers"

const TIMESTAMP_WIDTH = 150

const column = columnBuilder(C.TABLE_TRANSLATION)

export const COLUMNS = [
    column(C.TABLE_KEYS.FILE_NAME, { cellRenderer: FileLinkCell, width: 80 }),
    column(C.TABLE_KEYS.DESCRIPTION, {
        cellRenderer: DescriptionCell,
        columnData: { idField: C.TABLE_KEYS.DOCUMENT_ID },
        flexGrow: 0.4
    }),
    column(C.TABLE_KEYS.CREATED_BY_NAME, {
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.CREATED_DATE, {
        cellFormatter: timestampFormat,
        width: TIMESTAMP_WIDTH,
        flexGrow: 0
    }),
    column(C.TABLE_KEYS.DELETED, {
        cellRenderer: DeleteCell,
        width: 55,
        flexGrow: 0,
        align: ALIGNMENTS.CENTER,
        module: MODULE_IDS.DOCUMENT_UPLOAD,
        disableSort: true
    })
]
