import { createSelector } from "reselect"
import _ from "lodash"
import moment from "moment"
import { getResourceData } from "../core/fetcher"
import { moduleEnabledSelector } from "../moduleUtils"
import { EMPTY_OBJECT, isNilOrEmpty, itemsToObject } from "../utils"
import { easyFormValueSelector, optionsMapSelector } from "../selectorUtils"
import { FILTER_OPTIONS, MODULE_IDS } from "../constants"
import { usToIsoDate } from "../dates"
import * as C from "./constants"

export const canEditSelector = moduleEnabledSelector(MODULE_IDS.AUDITS_UPLOAD)
export const statusCountsSelector = createSelector(
    getResourceData(FILTER_OPTIONS, data => data.auditStatuses),
    getResourceData(C.PATIENT_AUDIT_COUNTS),
    (options, counts) =>
        itemsToObject(
            options,
            ({ value }) => value,
            ({ label }) => counts[label] ?? 0
        )
)

export const filtersSelector = createSelector(
    state => state[C.NAME].filters,
    filters =>
        _.pickBy(
            {
                ...filters,
                [C.FILTERS.SERVICE_DATE_TO]: usToIsoDate(
                    filters[C.FILTERS.SERVICE_DATE_TO]
                ),
                [C.FILTERS.SERVICE_DATE_FROM]: usToIsoDate(
                    filters[C.FILTERS.SERVICE_DATE_FROM]
                )
            },
            _.negate(isNilOrEmpty)
        )
)
export const countFiltersSelector = createSelector(
    filtersSelector,
    filters => ({ ...filters, [C.FILTERS.STATUS]: null })
)

const annotated = (list, users, lastViewed) =>
    _.map(list, subRecord => ({
        ...subRecord,
        [C.TABLE_KEYS.RECENTLY_UPDATED]:
            subRecord[C.TABLE_KEYS.RECENTLY_UPDATED] ||
            moment(subRecord[C.TABLE_KEYS.CREATED_DATE]).isAfter(lastViewed) ||
            (subRecord[C.TABLE_KEYS.DELETED_DATE] &&
                moment(subRecord[C.TABLE_KEYS.DELETED_DATE]).isAfter(
                    lastViewed
                )),
        [C.TABLE_KEYS.CREATED_BY_NAME]:
            users[subRecord[C.TABLE_KEYS.CREATED_BY_ID]]
    }))

export const auditsSelector = createSelector(
    getResourceData(C.NAME, data => data.audits),
    optionsMapSelector(data => data.users),
    optionsMapSelector(C.FILTERS.TAGS),
    state => moment(state[C.PATIENT_AUDITS_VIEWED].previous),
    (audits, users, tags, lastViewed) => {
        return _.map(audits, record => ({
            ...record,
            [C.TABLE_KEYS.TAGS]: _.at(tags, record[C.TABLE_KEYS.TAGS]),
            [C.TABLE_KEYS.FILES]: annotated(
                record[C.TABLE_KEYS.FILES],
                users,
                lastViewed
            ),
            [C.TABLE_KEYS.NOTES]: annotated(
                record[C.TABLE_KEYS.NOTES],
                users,
                lastViewed
            )
        }))
    }
)

export const recordSelector = createSelector(
    getResourceData(C.NAME, data => data.audits),
    state => state[C.NAME].recordId,
    (audits, recordId) =>
        _.find(audits, { [C.TABLE_KEYS.RECORD_ID]: recordId }) || EMPTY_OBJECT
)

export const statusOptionsSelector = createSelector(
    getResourceData(FILTER_OPTIONS),
    easyFormValueSelector(C.PA_STATUS_FORM, C.TABLE_KEYS.STATUS),
    (options, statusId) => ({
        statusOptions: options.auditStatuses,
        reasonOptions: _.filter(options.auditStatusReasons, {
            statusId
        })
    })
)

export const statusInitialValuesSelector = createSelector(
    statusOptionsSelector,
    recordSelector,
    ({ statusOptions, reasonOptions }, record) => ({
        [C.TABLE_KEYS.STATUS]:
            _.find(statusOptions, { label: record[C.TABLE_KEYS.STATUS] })
                ?.value ?? undefined,
        [C.TABLE_KEYS.STATUS_REASON]:
            _.find(reasonOptions, {
                label: record[C.TABLE_KEYS.STATUS_REASON]
            })?.value ??
            (reasonOptions.length === 1 ? reasonOptions[0].value : undefined),
        [C.TABLE_KEYS.STATUS_NOTE]: record[C.TABLE_KEYS.STATUS_NOTE]
    })
)
