import React from "react"
import { Button } from "react-bootstrap"
import _ from "lodash"
import * as C from "../../constants"
import { PrintPocModal } from "./PrintPocModal"

const PrintPoc = ({
    costPeriod,
    patient_name,
    poc_id,
    title = "Print",
    hidePocPrint,
    showPocPrint,
    submitPocPrint,
    ...props
}) => {
    const handleShow = () => props.printing || showPocPrint()
    const handleHide = () => props.printing || hidePocPrint()

    const handlePrint = () =>
        submitPocPrint({
            patients: [
                {
                    value: poc_id,
                    first: patient_name.split(" ")[1],
                    last: _.trim(patient_name.split(" ")[0], ",")
                }
            ],
            [C.PRINT_FIELDS.COST_PERIOD]: costPeriod,
            ...props.formValues
        })

    return (
        <>
            <Button
                variant="primary"
                size="sm"
                className="pull-right poc-print"
                onClick={handleShow}
            >
                <span className="fa fa-print" /> {title}
            </Button>
            <PrintPocModal
                onHide={handleHide}
                onPrint={handlePrint}
                {...props}
            />
        </>
    )
}
export default PrintPoc
