import React from "react"
import { Card } from "react-bootstrap"

import fetcher from "../../core/fetcher"
import LEHOC from "../../core/LoadingErrorHOC"
import { ApiTypes } from "../../api"

import * as C from "../constants"
import { requestBodySelector } from "../selectors"

const UtilizationDetailsTable = ({ Table, data, suffix, error, loading }) => {
    const list = data[suffix] || []

    return (
        <Card.Body>
            <LEHOC loading={loading} error={error} list={list}>
                <Table list={list} />
            </LEHOC>
        </Card.Body>
    )
}

export default fetcher({
    name: C.NAME,
    endpoint: (state, props) => `/api/patients/${state.poc_id}/${props.suffix}`,
    method: ApiTypes.POST,
    persistResource: true,
    requestBodySelector
})(UtilizationDetailsTable)
