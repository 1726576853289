import React, { useMemo, useState } from "react"
import { SortDirection } from "react-virtualized"
import { Card, Collapse } from "react-bootstrap"
import _ from "lodash"
import { tableFactory } from "../../../table"
import LEHOC from "../../../core/LoadingErrorHOC"
import CardTitle from "../../../core/CardTitle"
import { CAMPAIGN_COLUMNS } from "../../columns"
import * as C from "../../constants"

export const CampaignPanel = ({
    error,
    id,
    list,
    loading,
    title,
    defaultHide
}) => {
    const [show, setShow] = useState(!defaultHide)
    const { Table } = useMemo(
        () =>
            tableFactory({
                name: id,
                columns: CAMPAIGN_COLUMNS[id],
                sortBy: [C.TABLE_KEYS.CAMPAIGN_NAME],
                sortDirection: [SortDirection.ASC]
            }),
        [id]
    )

    return (
        <Card className="card-table" style={{ marginBottom: "25px" }}>
            <Card.Header className="campaign-heading" id={id} role="tab">
                <CardTitle>
                    <button onClick={() => setShow(!show)} aria-expanded={show}>
                        {title} {list ? `(${list.length})` : ""}
                        <span className="toggle pull-right" />
                    </button>
                </CardTitle>
            </Card.Header>
            <Collapse in={loading || (show && !_.isEmpty(list))}>
                <div>
                    <LEHOC loading={loading} error={error} list={list}>
                        <Table list={list} />
                    </LEHOC>
                </div>
            </Collapse>
        </Card>
    )
}
