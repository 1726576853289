import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { change, getFormValues, initialize } from "redux-form"
import Notifications from "react-notification-system-redux"
import { modifyData, modifyResource } from "../core/fetcher"
import { ApiTypes, tryFetch } from "../api"
import {
    notifySimpleError,
    savingNotification,
    SUCCESS_NOTIFICATION,
    UID_SAVING
} from "../notifications"
import { closeLocationModal, setLocationModeSelect } from "./actions"
import * as C from "./constants"

export function* saveLocation(action) {
    yield put(Notifications.info(savingNotification()))
    const mode = yield select(state => state[C.LOCATION_MODAL].mode)

    const values = yield select(state =>
        getFormValues(C.ADD_COMMUNITY_LOCATION_FORM)(state)
    )
    values[C.TABLE_KEYS.PHONE] = (values[C.TABLE_KEYS.PHONE] || "").replaceAll(
        /\D/g,
        ""
    )

    const updatedLocation =
        mode === C.ADD_MODE
            ? yield addCommunityLocation(values)
            : yield editCommunityLocation(values)

    yield put(Notifications.hide(UID_SAVING))

    if (!updatedLocation) {
        return
    }

    yield put(Notifications.success(SUCCESS_NOTIFICATION))

    yield put(initialize(C.ADD_COMMUNITY_LOCATION_FORM, updatedLocation))

    if (action.payload.selectAfterSave) {
        yield put(
            change(
                C.ADD_COMMUNITY_LINKAGE_FORM,
                C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION,
                updatedLocation.communityLocationId
            )
        )
        yield put(closeLocationModal())
    } else {
        yield put(setLocationModeSelect())
    }
}

export function* editCommunityLocation(values) {
    return yield* tryFetch(
        {
            url: `/api/locations`,
            method: ApiTypes.PUT,
            body: {
                location: { ...values }
            }
        },
        function*(body) {
            yield put(
                modifyResource({
                    name: C.COMMUNITY_LOCATIONS,
                    dataTransform: modifyData(
                        "communityLocations",
                        body.location,
                        "communityLocationId"
                    )
                })
            )
            return body.location
        },
        notifySimpleError("Failed to update location")
    )
}

export function* addCommunityLocation(values) {
    return yield tryFetch(
        {
            url: `/api/locations`,
            method: ApiTypes.POST,
            body: {
                location: { ...values }
            }
        },
        function*(resJson) {
            yield put(
                modifyResource({
                    name: C.COMMUNITY_LOCATIONS,
                    dataTransform: data => ({
                        ...data,
                        communityLocations: data.communityLocations.concat(
                            resJson.location
                        )
                    })
                })
            )

            return resJson.location
        },
        notifySimpleError("Failed to add new location")
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SAVE_LOCATION, saveLocation)
        })
    ])
}
