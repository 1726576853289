import React from "react"
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import * as C from "../constants"

export const TabChooser = ({ setTab, style, tab }) => (
    <ToggleButtonGroup
        name="tabChooser"
        type="radio"
        value={tab}
        onChange={setTab}
        style={style}
        data-toggle="buttons"
    >
        {Object.entries(C.LABELS).map(([tab, label], i) => (
            <ToggleButton key={i} value={tab} variant="default" size="sm">
                {label}
            </ToggleButton>
        ))}
    </ToggleButtonGroup>
)
export default TabChooser
