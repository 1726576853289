import { createReducer } from "../utils"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.SHOW_ADD_RISK_FORM]: state => ({
        ...state,
        show: true,
        loading: false
    }),
    [C.HIDE_ADD_RISK_FORM]: state => ({
        ...state,
        show: false,
        loading: false
    }),
    [C.ADD_RISK_DETAILS]: state => ({
        ...state,
        show: true,
        loading: true
    }),
    [C.STOP_SAVING]: state => ({
        ...state,
        loading: false
    })
})

export default reducer
