import React, { useEffect, useState } from "react"
import { Image } from "react-bootstrap"
import { ApiTypes, DEFAULT_HEADERS } from "../../api"

export const ClientLogo = () => {
    const [logo, setLogo] = useState(null)

    useEffect(() => {
        const controller = new AbortController()

        // we don't use a redux-obtain fetcher because that automatically adds authentication, which we can't do because we haven't logged in yet
        fetch("/logo", {
            signal: controller.signal,
            method: ApiTypes.GET,
            headers: DEFAULT_HEADERS
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`/logo returned ${response.status}`)
                }
                return response.blob()
            })
            .then(blob => {
                if (blob.size > 0) {
                    setLogo(URL.createObjectURL(blob))
                }
            })
            .catch(() => {
                // do nothing
            })

        return () => controller.abort()
    }, [])

    return logo ? <Image src={logo} alt="" /> : null
}
