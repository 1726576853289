import React from "react"
import { Button } from "react-bootstrap"

const AddButton = props => (
    <Button
        size="lg"
        variant="primary"
        onClick={props.onClick}
        disabled={props.disabled}
    >
        <span className="fa fa-plus" /> {props.label}
    </Button>
)

export default AddButton
