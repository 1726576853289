import React from "react"
import { Hoverlay } from "../core/Hoverlay"
import { columnBuilder } from "../table"
import * as C from "./constants"
import { PayerProduct } from "./components/PayerProduct"
import { DollarsCents, IndentedCell } from "../core/CellRenderers"

const column = columnBuilder(C.TABLE_TRANSLATION)

export const PayerCell = ({ rowData }) => (
    <div className="indent-cell">
        <Hoverlay className="patient-payer-tooltip">
            <PayerProduct
                className="payer-cell"
                payerName={rowData[C.TABLE_KEYS.PAYER_NAME]}
                payerLogo={rowData[C.TABLE_KEYS.PAYER_LOGO]}
                productName={rowData[C.TABLE_KEYS.PRODUCT_NAME]}
            />
        </Hoverlay>
    </div>
)

export const IndentedModalLinkCell = ({ cellData, rowData }) => {
    const path = rowData[C.TABLE_KEYS.PATH]

    switch (path.length) {
        case 1:
            return <PayerCell rowData={rowData} />
        case 3:
            return (
                // TODO aria role to make it clear that this is a button to open the modal. Or make it an actual button and style it to look like a link
                <IndentedCell
                    cellData={cellData}
                    className="modal-link"
                    onClick={() => rowData.onClick(rowData)}
                />
            )
        default:
            return <IndentedCell cellData={cellData} />
    }
}

export const PATIENT_COST_COLUMNS = [
    column(C.TABLE_KEYS.DISPLAY_NAME, {
        cellRenderer: IndentedModalLinkCell,
        width: 200,
        flexGrow: 2
    }),
    column(C.TABLE_KEYS.TOTAL_COST, {
        cellRenderer: DollarsCents,
        width: 100
    }),
    column(C.TABLE_KEYS.MONTHLY_COST, {
        cellRenderer: DollarsCents,
        width: 125
    })
]
