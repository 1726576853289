import React from "react"
import { tableFactory } from "../../table"
import { EXCLUSIONS_TABLE_COLUMNS } from "../columns"
import * as C from "../constants"
import { ConditionCard } from "./ConditionCard"

const tableComponents = tableFactory({
    name: C.EXCLUSIONS,
    columns: EXCLUSIONS_TABLE_COLUMNS,
    sortBy: [],
    sortDirection: []
})

const Exclusions = props => (
    <ConditionCard title="Exclusions" {...tableComponents} {...props} />
)

export default Exclusions
