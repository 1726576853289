import React from "react"
import { Button } from "react-bootstrap"

export const PatientDeactivatedBar = ({ canReactivate, reactivatePatient }) => (
    <div className="deactivation-notice">
        Deactivation Pending
        {canReactivate && (
            <Button
                className="reactivate-patient"
                variant="outline-light"
                size="sm"
                onClick={reactivatePatient}
            >
                Reactivate
            </Button>
        )}
    </div>
)
