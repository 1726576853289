import React from "react"
import classNames from "classnames"
import { CHECKBOX_CLASSES, RADIO_CLASSES } from "../constants"

const PrintItem = ({
    boxClasses,
    children,
    className,
    label,
    labelLeft,
    onClick,
    selected,
    ...props
}) => (
    <div className={classNames({ selected }, className)} {...props}>
        <span
            onClick={onClick}
            className={classNames("rf-checkbox", labelLeft && "label-left")}
        >
            <span
                className={
                    boxClasses
                        ? classNames("fa", boxClasses[!!selected])
                        : undefined
                }
            />
            {label}
        </span>

        {selected && children}
    </div>
)

export const PrintCheckbox = ({ className, onClick, selected, ...props }) => {
    return (
        <PrintItem
            selected={selected}
            className={classNames("print-checkbox", className)}
            onClick={() => onClick(!selected)}
            boxClasses={CHECKBOX_CLASSES}
            {...props}
        />
    )
}

export const PrintRadio = ({ className, id, onClick, selected, ...props }) => (
    <PrintItem
        selected={selected === id}
        className={classNames("print-radio", className)}
        onClick={() => onClick(id)}
        boxClasses={RADIO_CLASSES}
        {...props}
    />
)
