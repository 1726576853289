import _ from "lodash"
import moment from "moment"
import ordinal from "ordinal"
import { DATE_STRING, dateFormat } from "../dates"
import { isNilOrBlank } from "../utils"

export const getIdName = id => `id_${id}`
export const getMeasureIdName = props => getIdName(props.id)
export const getIdDateName = id => `id_${id}_date`

export const getSubmeasureIdName = parentId => item =>
    `id_${parentId}_sub_${item.id}`
export const getCompositeSubmeasureIdName = parentId => item =>
    `id_${parentId}_comp_${item.id}`

export const getValuesAndMeta = (
    { required = [], optional = [] } = {},
    extraValues = []
) => {
    // required measures

    const measuresAreRequired = !_.isEmpty(required)
    const requiredDiffer = required.some(r => r.value.changed || r.date.changed)

    const uniqueNextRequiredDates = _.uniq(required.map(r => r.date.next))
    const requiredDatesAllMatch = uniqueNextRequiredDates.length === 1
    const allRequiredValuesExist =
        measuresAreRequired && _.every(required, r => r.value.next)
    const allRequiredValuesEntered = // i.e. not only are they present, but they've actually been entered
        measuresAreRequired &&
        _.every(required, r => r.value.next && !r.value.isFallback)
    const requiredAreAllFallbacks =
        measuresAreRequired && _.every(required, r => r.value.isFallback)
    const requiredCanDisplay =
        allRequiredValuesExist &&
        requiredDatesAllMatch &&
        !requiredAreAllFallbacks

    // optional measures

    const optionalsAreAllFallbacks =
        !_.isEmpty(optional) && _.every(optional, o => o.value.isFallback)

    const validOptionalDates = optional
        .filter(o => o.value.next && !o.value.isFallback) // only considering dates from optionals that have had values manually entered, because it shouldn't complain because some optional measure had a value last year
        .map(o => o.date.next)

    const optionalDatesAllMatch = _.uniq(validOptionalDates).length <= 1

    // calculated date

    const requiredDateConsensus = requiredCanDisplay
        ? _.head(uniqueNextRequiredDates)
        : undefined
    const optionalDateConsensus = optionalsAreAllFallbacks
        ? undefined
        : _.maxBy(
              validOptionalDates,
              dateStr => moment.utc(dateStr, DATE_STRING) // note that dateStr can't be null because everything left in validOptionalDates must have reqAllMatchNoFallbacks value.next property
          )

    const calculatedDate = measuresAreRequired
        ? requiredDateConsensus
        : optionalDateConsensus

    // more optional measures
    // we do a different comparison than the required measures because we have to limit ourselves to the optionals with the right date. If an optional was removed, nothing in the remaining optionals would have `changed === true`.

    const prevValidOptionals = optional.map(o =>
        o.value.prev && o.date.prev === calculatedDate ? o : null
    )
    const nextValidOptionals = optional.map(o =>
        o.value.next && o.date.next === calculatedDate ? o : null
    )
    const optionalsDiffer = !_.isEqual(prevValidOptionals, nextValidOptionals)

    const canDisplayWithOnlyOptionals =
        !measuresAreRequired &&
        _.some(nextValidOptionals) &&
        !optionalsAreAllFallbacks

    // put it all together

    const okayToDisplay = requiredCanDisplay || canDisplayWithOnlyOptionals

    const values = [...required, ...nextValidOptionals].map(o =>
        o ? o.value.next : null
    )

    return {
        update: okayToDisplay && (requiredDiffer || optionalsDiffer),
        clear: !okayToDisplay,
        error:
            (allRequiredValuesEntered && !requiredDatesAllMatch) ||
            !optionalDatesAllMatch,
        values: [...values, ...extraValues],
        date: calculatedDate
    }
}

const getPercentile = percentile => {
    const percentileNumber = Number(percentile)
    return _.isNaN(percentileNumber) ? null : percentileNumber
}

export const formatPercentile = value => {
    const { percentile } = value
    if (_.isNil(percentile)) {
        return ""
    }
    const percentileNumber = getPercentile(percentile)
    return _.isNil(percentileNumber)
        ? null
        : `${ordinal(percentileNumber)} Percentile`
}

const detailBuilder = (prevValues, nextValues, idLookup) => id => {
    const name = getIdName(id)
    const dateName = getIdDateName(id)
    const prevValue = prevValues[name]
    const nextValue = nextValues[name]
    const prevDate = prevValues[dateName]
    const nextDate = nextValues[dateName]

    // is there an older value for today?

    const useFallback = isNilOrBlank(nextValue) && isNilOrBlank(nextDate)
    const measure = idLookup[name] || {}
    const fallbackValue = useFallback ? measure.numericVal : undefined
    const fallbackDate = useFallback ? dateFormat(measure.serviceDt) : undefined
    // those need to fall back to undefined specifically, so that they'll appear unchanged if no value has been set for them yet

    return {
        id,
        name,
        dateName,
        value: {
            prev: prevValue,
            next: nextValue || fallbackValue, // will be null if there's a date entered but no value, or null if there are no prior values
            isFallback: useFallback,
            changed: prevValue !== nextValue
        },
        date: {
            prev: prevDate,
            next: nextDate || fallbackDate,
            isFallback: useFallback,
            changed: prevDate !== nextDate
        }
    }
}

export function getAllDetails(
    prevValues = {},
    nextValues = {},
    definition = {},
    idLookup = {}
) {
    const currentDetails = detailBuilder(prevValues, nextValues, idLookup)
    const { requiredIds = [], optionalIds = [] } = definition
    const required = requiredIds.map(currentDetails)
    const optional = optionalIds.map(currentDetails)

    return { required, optional }
}

export const getDropListOptions = dropListOptions =>
    (dropListOptions || []).map(item => ({
        label: item.name,
        value: item.id
    }))
