import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import _ from "lodash"
import { Hoverlay } from "../../core/Hoverlay"
import UndoRemoveButtons from "../../core/UndoRemoveButtons"
import { TextInputCell } from "../../tcm/components/TextInputCell"

import { canEditSelector } from "../selectors"
import * as C from "../constants"
import {
    deleteFile,
    showFilesModal,
    showNotesModal,
    restoreFile,
    showStatusModal
} from "../actions"

const isRecentlyChanged = subrecords =>
    _.some(subrecords, sr => sr[C.TABLE_KEYS.RECENTLY_UPDATED])

const CountButtonCell = ({
    canEdit,
    rowData,
    icon,
    openModal,
    countField,
    subRecordsField,
    tooltipField
}) => (
    <Hoverlay
        className="tooltip-list"
        tooltip={
            <ul>
                {_.map(rowData[subRecordsField], (item, i) => (
                    <li key={i}>{item[tooltipField]}</li>
                ))}
            </ul>
        }
        disabled={!rowData[countField]}
    >
        <button
            className={classNames("btn-blank btn-modal", {
                "audit-recent-change": isRecentlyChanged(
                    rowData[subRecordsField]
                )
            })}
            onClick={() => openModal(rowData[C.TABLE_KEYS.RECORD_ID])}
            disabled={!rowData[countField] && !canEdit}
        >
            <span className={classNames("fa", icon)} />
            {rowData[countField] > 0 && " " + rowData[countField]}
        </button>
    </Hoverlay>
)

export const FilesCell = connect(
    state => ({
        icon: "fa-files-o",
        countField: C.TABLE_KEYS.FILE_COUNT,
        subRecordsField: C.TABLE_KEYS.FILES,
        tooltipField: C.TABLE_KEYS.FILE_NAME,
        canEdit: canEditSelector(state)
    }),
    {
        openModal: showFilesModal
    }
)(CountButtonCell)

export const NotesCell = connect(
    state => ({
        icon: "fa-sticky-note-o",
        countField: C.TABLE_KEYS.NOTE_COUNT,
        subRecordsField: C.TABLE_KEYS.NOTES,
        tooltipField: C.TABLE_KEYS.NOTE,
        canEdit: canEditSelector(state)
    }),
    {
        openModal: showNotesModal
    }
)(CountButtonCell)

export const FileLinkCell = ({ cellData, rowData, tableData }) => (
    <a
        onClick={() =>
            rowData[C.TABLE_KEYS.DELETED] || tableData.downloadFile(rowData)
        }
    >
        {cellData}
    </a>
)

export const DeleteCell = connect(
    () => ({
        field: C.TABLE_KEYS.RECORD_FILE_ID,
        statusField: C.TABLE_KEYS.DELETED
    }),
    { remove: deleteFile, undo: restoreFile }
)(UndoRemoveButtons)

export const DescriptionCell = props => {
    const { cellData, columnData, rowData, tableData } = props
    return (
        <TextInputCell
            {...props}
            key={cellData}
            canWrite={tableData.canEdit && !rowData[C.TABLE_KEYS.DELETED]}
            placeholder="Description"
            update={description =>
                tableData.updateDescription(
                    rowData[columnData.idField],
                    description
                )
            }
        />
    )
}

const _StatusCell = ({ cellData, rowData, showStatusModal }) => (
    <Hoverlay tooltip={rowData[C.TABLE_KEYS.STATUS_DESC]} disabled={!cellData}>
        <button
            className={classNames("btn-blank btn-modal btn-status", {
                "audit-recent-change": rowData[C.TABLE_KEYS.RECENTLY_UPDATED]
            })}
            onClick={() => showStatusModal(rowData[C.TABLE_KEYS.RECORD_ID])}
        >
            {cellData}
            <span className="fa fa-edit" />
        </button>
    </Hoverlay>
)

export const StatusCell = connect(null, {
    showStatusModal
})(_StatusCell)
