import _ from "lodash"

export const getDynamicOptions = (list, valueKey, labelKey) =>
    _.sortBy(
        _.uniqBy(
            _.zipWith(
                // the fields might contain arrays, so we flatMap the lists
                _.flatMap(list, valueKey),
                _.flatMap(list, labelKey),
                (value, label) => ({ value, label })
            ),
            o => o.value
        ),
        o => o.label
    )
