import React from "react"
import classNames from "classnames"

export const Toggle = ({ name, className, meta, value, ...props }) => (
    <div className={classNames(className, "toggle-switch")}>
        <input
            id={name}
            type="checkbox"
            className="hidden"
            checked={value}
            {...props}
        />
        <label htmlFor={name} />
    </div>
)

export default Toggle
