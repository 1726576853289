import React from "react"
import { SortDirection } from "react-virtualized"
import { Card } from "react-bootstrap"

import CardTitle from "../../core/CardTitle"
import { ExportIcon } from "../../core/Icons"
import LEHOC from "../../core/LoadingErrorHOC"
import FilterHeader from "../../core/FilterHeader"
import DashboardFilters from "../../dashboard_filters/components"
import { FiltersForm } from "../../toc/components/FiltersForm"
import { hideIfNoneEditable } from "../../attention/helpers"
import { tableFactory } from "../../table"

import * as C from "../constants"
import { TABLE_COLUMNS } from "../columns"
import TableLegend from "./TableLegend"

const ROW_CLASS_NAMES = {
    grace_period: rowData => rowData[C.TABLE_KEYS.GRACE_PERIOD],
    removed_row: rowData => rowData[C.TABLE_KEYS.STATUS] === C.STATUS_VALUES.R
}

export const DEFAULT_ORDER_BYS = {
    sortBy: [C.TABLE_KEYS.DISCHARGE_DATE, C.TABLE_KEYS.PATIENT_NAME],
    sortDirection: [SortDirection.DESC, SortDirection.ASC]
}
const LOOKUP = { [C.FILTERS.STATUS]: C.STATUS_LABELS }

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    ...DEFAULT_ORDER_BYS,
    TableLegend
})

const TcmTracker = ({
    loading,
    error,
    tcms,
    filters,
    setFilters,
    canEdit,
    paginationFunctions,
    exportTCM
}) => (
    <Card className="card-table tcm-tracker" id="tcm-tracker">
        <Card.Header id="tcm-list-header">
            <CardTitle>TCM Tracker</CardTitle>
            <DashboardFilters
                title="TCM Tracker - Filters"
                className="toc-filters"
                form={C.TCM_FILTERS_FORM}
                formComponent={FiltersForm}
                initialValues={filters}
                emptyValues={C.initialState.filters}
                setFilters={setFilters}
                size="xl"
                includeStatusFilter
            />

            <Settings
                list={tcms}
                extraFilter={hideIfNoneEditable({
                    hideColumns: [C.TABLE_KEYS.REMOVE],
                    canEdit
                })}
            />
            <ExportIcon onClick={exportTCM} className="pull-right" />
            <FilterHeader
                className="tcm-filters"
                filters={filters}
                translation={C.FILTER_TRANSLATION}
                lookup={LOOKUP}
            />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={tcms}>
                <Table
                    {...paginationFunctions}
                    list={tcms}
                    rowClassNames={ROW_CLASS_NAMES}
                />
            </LEHOC>
        </Card.Body>
    </Card>
)
export default TcmTracker
