import React from "react"
import { Button, FormGroup, Modal } from "react-bootstrap"
import SearchBar from "../../../core/SearchBar"
import RFCheckBoxTree from "../../../core/RFCheckBoxTree"
import * as C from "../../constants"

export const AddMeasureModal = ({
    initialExpanded,
    measures,
    onChange,
    onClose,
    onSubmit,
    show
}) => (
    <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add Measure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div id="measures-list" className="well">
                <FormGroup>
                    <SearchBar
                        placeholder="Search for Measure"
                        onChange={onChange}
                    />
                </FormGroup>
                {initialExpanded.length === 0 ? (
                    <p>No results.</p>
                ) : (
                    <div className="overflow">
                        {measures.length !== 0 && (
                            <RFCheckBoxTree
                                name={C.ADD_MEASURE_FIELDS.MEASURES}
                                nodes={measures}
                                initialExpanded={initialExpanded}
                            />
                        )}
                    </div>
                )}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                Close
            </Button>
            <Button variant="primary" onClick={onSubmit}>
                Add Measure
            </Button>
        </Modal.Footer>
    </Modal>
)
