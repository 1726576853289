import React from "react"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"

export const ColorCell = ({ cellData }) => (
    <div>
        <div
            className="color-swatch-sm"
            style={{
                backgroundColor: cellData
            }}
        />
        &nbsp;
        {cellData}
    </div>
)

export const ColoredTooltipCell = ({ cellData, rowData }) => (
    <Hoverlay cellData={cellData} tooltipColor={rowData[C.TABLE_KEYS.COLOR]} />
)

export const ClearAlert = ({ rowData, tableData }) => (
    <button
        className="icon-button"
        onClick={() => tableData.deleteAlert(rowData[C.TABLE_KEYS.ALERT_ID])}
    >
        <span className="fa fa-trash-o" />
    </button>
)
