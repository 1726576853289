import React, { Component } from "react"
import * as C from "../constants"
import { reduxForm } from "redux-form"
import { Button } from "react-bootstrap"
import { validateLocation } from "../validate"
import LocationFieldPanel from "./LocationFieldPanel"

class LocationUpdate extends Component {
    save = this.props.handleSubmit(() =>
        this.props.saveLocation({ selectAfterSave: false })
    )
    selectAfterSave = this.props.handleSubmit(() =>
        this.props.saveLocation({ selectAfterSave: true })
    )

    render() {
        const {
            allowSelect,
            locationFormValues,
            pristine,
            communityLocationOptions,
            setLocationModeSelect
        } = this.props
        const disabledText = validateLocation(locationFormValues)
        const backButton = (
            <Button
                variant="secondary"
                size="sm"
                onClick={setLocationModeSelect}
            >
                Back
            </Button>
        )
        const saveButton = !!disabledText ? (
            <Button variant="primary" size="sm" disabled>
                {disabledText}
            </Button>
        ) : pristine ? (
            <Button variant="primary" size="sm" disabled>
                No changes detected
            </Button>
        ) : (
            <React.Fragment>
                <Button variant="primary" size="sm" onClick={this.save}>
                    Save
                </Button>
                {allowSelect && (
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={this.selectAfterSave}
                    >
                        Save And Select
                    </Button>
                )}
            </React.Fragment>
        )
        return (
            <LocationFieldPanel
                {...communityLocationOptions}
                backButton={backButton}
                saveButton={saveButton}
            />
        )
    }
}

export default reduxForm({
    form: C.ADD_COMMUNITY_LOCATION_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(LocationUpdate)
