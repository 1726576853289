import { getFormValues } from "redux-form"
import { all, call, select, takeEvery } from "redux-saga/effects"
import { costFiltersSelector } from "../cost/selectors"
import { downloadFile } from "../api"
import { getFilename, urlWithParams } from "../utils"
import * as C from "./constants"

function* getUtilizationReport(extension, extraFilters) {
    const filters = yield select(costFiltersSelector(C.NAME))

    yield* downloadFile(
        `Cost & Utilization ${extension.toUpperCase()}`,
        urlWithParams(`/api/utilization/${extension}`, {
            ...filters,
            ...extraFilters
        }),
        getFilename("cost_utilization_report", extension)
    )
}

export function* exportCsv(action) {
    const tabKey = C.TAB_KEYS[action.payload]
    const exportValues = tabKey ? { [tabKey]: true } : {}

    yield* getUtilizationReport("csv", exportValues)
}
export function* printPdf() {
    const tabsToPrint = yield select(getFormValues(C.PRINT_UTILIZATION_FORM))
    yield* getUtilizationReport("pdf", tabsToPrint)
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.PRINT_UTILIZATION_PDF, printPdf)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_UTILIZATION_CSV, exportCsv)
        })
    ])
}
