import React from "react"
import { Card } from "react-bootstrap"
import CardTitle from "../../core/CardTitle"
import LER from "../../core/LoadingErrorHOC"
import * as C from "../../attention/constants"
import { tableHeightForLength } from "../../utils"

export const ConditionCard = ({
    title,
    headerContent,
    data = [],
    Table,
    Settings,
    Description,
    error,
    loading,
    maxLength
}) => (
    <Card className="card-table">
        <Card.Header>
            <CardTitle>
                {title}{" "}
                {loading ? " - Loading" : data.length ? "" : " - No Data"}
            </CardTitle>
            {headerContent}
            <Settings />
        </Card.Header>
        <Description />
        <Card.Body>
            <LER loading={loading} error={error} list={data} hideEmpty>
                <Table
                    list={data}
                    rowClassNames={{
                        removed_row: rowData => rowData[C.TABLE_KEYS.REMOVED]
                    }}
                    height={tableHeightForLength(maxLength + 0.5)} // extra half-line so you can see that there are more rows
                />
            </LER>
        </Card.Body>
    </Card>
)
