import React, { Fragment } from "react"
import { CardGroup } from "react-bootstrap"
import OrgName from "../../../core/OrgName"
import * as C from "../../constants"
import { CampaignPanel } from "./CampaignPanel"

export const CampaignList = ({ campaigns, loading, error }) => (
    <CardGroup id="campaigns" role="tablist" aria-multiselectable="true">
        <CampaignPanel
            title="My Favorite Campaigns"
            id={C.SECTIONS.FAVOURITES}
            list={
                campaigns &&
                campaigns.filter(
                    item => item[C.USER_PINNED] || item[C.FORCE_PINNED]
                )
            }
            loading={loading}
            error={error}
        />
        <CampaignPanel
            title="My Created Campaigns"
            id={C.SECTIONS.CREATED}
            list={
                campaigns &&
                campaigns.filter(
                    item =>
                        !item[C.UNSUBSCRIBED] &&
                        item.isOwner &&
                        !item[C.USER_PINNED] &&
                        !item[C.FORCE_PINNED]
                )
            }
            loading={loading}
            error={error}
        />
        <CampaignPanel
            title={
                <Fragment>
                    All <OrgName /> Campaigns
                </Fragment>
            }
            id={C.SECTIONS.HVPA_WIDE}
            list={
                campaigns &&
                campaigns.filter(
                    item =>
                        !item[C.UNSUBSCRIBED] &&
                        item[C.HVPA_WIDE] &&
                        !item[C.USER_PINNED] &&
                        !item[C.FORCE_PINNED]
                )
            }
            loading={loading}
            error={error}
        />
        <CampaignPanel
            title="Shared with me"
            id={C.SECTIONS.SHARED}
            list={
                campaigns &&
                campaigns.filter(
                    item =>
                        !item[C.UNSUBSCRIBED] &&
                        !item.isOwner &&
                        !item[C.HVPA_WIDE] &&
                        !item[C.USER_PINNED] &&
                        !item[C.FORCE_PINNED]
                )
            }
            loading={loading}
            error={error}
        />
        <CampaignPanel
            title="Hidden Campaigns"
            id={C.SECTIONS.HIDDEN}
            list={campaigns && campaigns.filter(item => item[C.UNSUBSCRIBED])}
            loading={loading}
            error={error}
            defaultHide
        />
    </CardGroup>
)
