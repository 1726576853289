import React, { forwardRef, useEffect, useMemo, useRef } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { useResizeDetector } from "react-resize-detector"
import bb from "billboard.js"
import classNames from "classnames"
import _ from "lodash"

const TooltipTemplate = () => (
    <table className="{=CLASS_TOOLTIP}">
        <caption>{"{=TITLE}"}</caption>
        <tbody>
            {"{{"}
            <tr className="{=CLASS_TOOLTIP_NAME}">
                <td className="name">
                    <span className="bb-tooltip-item-tile" />
                    {"{=NAME}"}
                </td>
                <td className="value">{"{=VALUE}"}</td>
            </tr>
            {"}}"}
        </tbody>
    </table>
)

const getRowColor = (rowId, chartRef) => chartRef.current.color(rowId)

const getRowTileSelector = (rowId, chartId) =>
    `#${chartId} .bb-tooltip-name-${rowId.replace(
        /[ ()]/g,
        "-"
    )} .bb-tooltip-item-tile`

const DEFAULT_DATA = {
    resizeAfter: true,
    unload: true
}
const getChartProps = (config, chartId, chartRef) =>
    _.merge(
        {
            bindto: "#" + chartId,
            tooltip: {
                // allowing the chart library to dynamically set inline styles on the color tiles violates the Content Security Policy, so remove those styles from the template...
                contents: {
                    template: renderToStaticMarkup(<TooltipTemplate />)
                },
                // ...and instead add the colors manually afterward (this counts as a script-src of 'self')
                onshown: rows =>
                    rows
                        .filter(({ value }) => !_.isNil(value))
                        .forEach(({ id }) => {
                            const selector = getRowTileSelector(id, chartId)
                            const element = document.querySelector(selector)
                            if (!element) return
                            element.style.backgroundColor = getRowColor(
                                id,
                                chartRef
                            )
                        })
            }
        },
        config
    )

const Chart = ({ config, data, className, id, ...props }, ref) => {
    const chartRef = ref ?? useRef() // can pass in a ref if you need to access the chart instance from outside

    const chartId = useMemo(() => id ?? _.uniqueId("chart-"), [id])

    // this makes resizing much smoother
    const { ref: divRef } = useResizeDetector({
        refreshMode: "throttle",
        refreshRate: 100
    })

    useEffect(() => {
        // initial chart setup
        chartRef.current = bb.generate({
            ...getChartProps(config, chartId, chartRef),
            data: {
                ...DEFAULT_DATA,
                ...data
            }
        })
    }, [])

    useEffect(() => {
        // load fresh data when the prop changes
        chartRef.current?.load({
            ...DEFAULT_DATA,
            ...data
        })
    }, [data])

    return (
        <div
            id={chartId}
            className={classNames("chart-container", className)}
            {...props}
            ref={divRef}
        />
    )
}

export default forwardRef(Chart)
