import { all, fork } from "redux-saga/effects"
import auth from "./modules/authentication"
import patient_list from "./modules/patient_list"
import alerts from "./modules/alerts"
import toc from "./modules/toc"
import tcm from "./modules/tcm"
import awv from "./modules/awv"
import comments from "./modules/comments"
import attention from "./modules/attention"
import conditions from "./modules/conditions"
import patient_risk from "./modules/patient_risk"
import measures from "./modules/measures"
import campaigns from "./modules/campaigns"
import batch_print from "./modules/batch_print"
import info from "./modules/info"
import care_team from "./modules/care_team"
import qsr from "./modules/qsr"
import qsr_dashboard from "./modules/qsr_dashboard"
import risk_dashboard from "./modules/risk_dashboard"
import library from "./modules/reports_library"
import community_linkages from "./modules/community_linkages"
import community_locations from "./modules/locations"
import add_new_patient from "./modules/add_new_patient"
import table from "./modules/table"
import table_settings from "./modules/table_settings"
import my_account from "./modules/my_account"
import admin from "./modules/admin"
import user_audit from "./modules/user_audits"
import site_settings from "./modules/site_settings"
import cost_utilization from "./modules/cost_utilization"
import cost_dashboard from "./modules/cost_dashboard"
import cost_report from "./modules/cost_report"
import patient_tags from "./modules/patient_tags"
import inactivePatients from "./modules/inactive_patients"
import userTags from "./modules/user_tags"
import dashboardFilters from "./modules/dashboard_filters"
import dataCoverage from "./modules/data_coverage"
import reminderLetter from "./modules/reminder_letter_details"
import care_management from "./modules/care_management"
import patient_audits from "./modules/patient_audits"
import documents from "./modules/documents"

export function* rootSaga() {
    try {
        yield all([
            fork(auth.saga.entrySaga),
            fork(patient_list.saga.entrySaga),
            fork(toc.saga.entrySaga),
            fork(tcm.saga.entrySaga),
            fork(awv.saga.entrySaga),
            fork(alerts.saga.entrySaga),
            fork(comments.saga.entrySaga),
            fork(attention.saga.entrySaga),
            fork(conditions.saga.entrySaga),
            fork(patient_risk.saga.entrySaga),
            fork(campaigns.saga.entrySaga),
            fork(qsr.saga.entrySaga),
            fork(qsr_dashboard.saga.entrySaga),
            fork(risk_dashboard.saga.entrySaga),
            fork(library.saga.entrySaga),
            fork(batch_print.saga.entrySaga),
            fork(info.saga.entrySaga),
            fork(care_team.saga.entrySaga),
            fork(measures.saga.entrySaga),
            fork(community_linkages.saga.entrySaga),
            fork(community_locations.saga.entrySaga),
            fork(add_new_patient.saga.entrySaga),
            fork(table.saga.entrySaga),
            fork(table_settings.saga.entrySaga),
            fork(my_account.saga.entrySaga),
            fork(admin.saga.entrySaga),
            fork(user_audit.saga.entrySaga),
            fork(site_settings.saga.entrySaga),
            fork(cost_utilization.saga.entrySaga),
            fork(cost_dashboard.saga.entrySaga),
            fork(cost_report.saga.entrySaga),
            fork(patient_tags.saga.entrySaga),
            fork(inactivePatients.saga.entrySaga),
            fork(userTags.saga.entrySaga),
            fork(dashboardFilters.saga.entrySaga),
            fork(dataCoverage.saga.entrySaga),
            fork(reminderLetter.saga.entrySaga),
            fork(care_management.saga.entrySaga),
            fork(patient_audits.saga.entrySaga),
            fork(documents.saga.entrySaga)
        ])
    } catch (error) {
        console.error(error)
    }
}

export default rootSaga
