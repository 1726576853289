import { connect } from "react-redux"
import fetcher, { getResourceData } from "../../core/fetcher"
import * as patient_list_actions from "../../patient_list/actions"
import { FILTER_OPTIONS } from "../../constants"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import Directory from "./Directory"

let resourceData = getResourceData(FILTER_OPTIONS, data => data.regions)
export default fetcher(
    {
        name: C.DIRECTORY,
        endpoint: "/api/directory",
        method: ApiTypes.GET,
        persistResource: true
    },
    patient_list_actions
)(
    connect(state => ({
        regionOptions: resourceData(state)
    }))(Directory)
)
