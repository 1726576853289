import _ from "lodash"
import { createSelector } from "reselect"
import { getFormValues } from "redux-form"
import { usToIsoDate } from "../dates"
import * as C from "./constants"

export const requestBodySelector = createSelector(
    getFormValues(C.UTILIZATION_DETAILS_FORM),
    filters =>
        _.mapValues(filters, (value, key) =>
            C.DATE_FIELDS.includes(key) ? usToIsoDate(value) : value
        )
)
