import React, { useRef, useState } from "react"
import Draggable from "react-draggable"
import classNames from "classnames"
import { SortDirection } from "react-virtualized"
import { arePropsBasicallyEqual } from "../helpers"

const SORT_CLASSES = {
    [SortDirection.ASC]: "sort sort_asc",
    [SortDirection.DESC]: "sort sort_desc",
    [undefined]: "sort"
}

export const HeaderRenderer = ({
    className,
    disableSort,
    onResize,
    sortDirection,
    label,
    title,
    offset,
    ...props
}) => {
    const [dragPos, setDragPos] = useState(0)
    const nodeRef = useRef(null)

    return (
        <div
            {...props}
            className={classNames(
                "grid-cell-header",
                disableSort && "no-sort",
                !disableSort && SORT_CLASSES[sortDirection],
                className
            )}
            title={title || label} // TODO really this should be an optional hoverlay instead of a title
        >
            {label}
            <Draggable
                nodeRef={nodeRef}
                axis="x"
                position={{ x: dragPos, y: 0 }}
                onDrag={(e, data) => setDragPos(data.x)}
                bounds={{ left: -offset }}
                onStop={(e, data) => {
                    // We wait until we stop dragging to resize the column because resizing it in real time has poor performance.
                    onResize(offset + data.x)
                    setDragPos(0)
                    // we reset dragPos to 0 after resizing the column because if we didn't, any offset would appear doubled.
                    // e.g. if we moved the dragger 100px to the right, we'd end up with a column 100px wider, and the dragger 100px to the right of the column's end.
                }}
            >
                <div ref={nodeRef} onClickCapture={e => e.stopPropagation()} />
                {/*this onClickCapture prevents the click handler from bubbling up to the column header component. Clicking the column header normally causes the table to sort by that column, but we don't want that to happen when we click on the resizer.*/}
            </Draggable>
        </div>
    )
}

export default React.memo(HeaderRenderer, arePropsBasicallyEqual)
