import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import {
    getFormSyncErrors,
    getFormSyncWarnings,
    getFormValues,
    isInvalid
} from "redux-form"
import _ from "lodash"
import { idLookUpSelector } from "../selectors"
import * as C from "../constants"

const getInvalidRow = (key, errorText, name) => (
    <span key={key}>
        {name} {errorText}
    </span>
)

const Invalid = ({ formErrors, idLookUp }) =>
    _.entries(formErrors).map(([key, errorText]) => {
        if (key.includes("_date")) {
            // date field
            return getInvalidRow(key, errorText, idLookUp[key])
        }

        if (key.includes("_sub")) {
            // submeasure field, don't display
            return <span key={key} />
        }

        if (!key.includes("_comp")) {
            // regular field. note the "!" in the condition
            return getInvalidRow(key, errorText, idLookUp[key].name)
        }

        const [, measureId, , subMeasureId] = key.split("_")
        const measure = idLookUp[`id_${measureId}`]
        const subMeasure = measure.subMeasures.find(
            item => Number(item.id) === Number(subMeasureId)
        )
        if (subMeasure) {
            // composite field has a problem in its submeasures
            return getInvalidRow(key, errorText, subMeasure.name)
        }

        // hm something's wrong then
        return <span key={key}>Something went wrong</span>
    })

export const SnackBar = ({
    show,
    invalid,
    handleSubmit,
    formErrors,
    formWarnings,
    values,
    idLookUp
}) => {
    const noActiveFields = _.isEmpty(_.keys(_.pickBy(values)))

    if (!show || noActiveFields) {
        return null
    }

    const hasWarnings = !_.isEmpty(formWarnings)
    return (
        <div
            id="snackbar"
            className={classNames({
                invalid
            })}
        >
            {invalid ? (
                <Invalid formErrors={formErrors} idLookUp={idLookUp} />
            ) : (
                <React.Fragment>
                    <div className="saveable">
                        <span>You have unsaved changes</span>
                        <button onClick={handleSubmit}>Save Changes</button>
                    </div>
                    {hasWarnings && (
                        <div className="warning">
                            <Invalid
                                formErrors={formWarnings}
                                idLookUp={idLookUp}
                            />
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default connect(state => ({
    ...state[C.MEASURE_SNACKBAR],
    invalid: isInvalid(C.POC_MEASURE_FORM)(state),
    values: getFormValues(C.POC_MEASURE_FORM)(state),
    formErrors: getFormSyncErrors(C.POC_MEASURE_FORM)(state),
    formWarnings: getFormSyncWarnings(C.POC_MEASURE_FORM)(state),
    idLookUp: idLookUpSelector(state)
}))(SnackBar)
