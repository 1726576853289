import * as C from "./constants"

export const setPeriod = payload => ({
    type: C.SET_PATIENT_COST_PERIOD,
    payload
})
export const setChartFilters = payload => ({
    type: C.SET_PATIENT_COST_CHART_FILTERS,
    payload
})
export const setModalFilters = payload => ({
    type: C.SET_PATIENT_COST_MODAL_FILTERS,
    payload
})
