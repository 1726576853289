import {
    DollarsCents,
    LongNumber,
    RateCell,
    formatYearMonth
} from "../core/CellRenderers"
import { columnBuilder } from "../table"
import * as C from "./constants"

const column = columnBuilder(C.TABLE_TRANSLATION)

const SHARED_COLUMNS = [
    column(C.TABLE_KEYS.MONTH_ID, {
        cellFormatter: formatYearMonth,
        width: 80
    }),
    column(C.TABLE_KEYS.PATIENT_COUNT, {
        width: 105,
        cellRenderer: LongNumber
    }),
    column(C.TABLE_KEYS.EPISODE_COUNT, { width: 85, cellRenderer: LongNumber }),
    column(C.TABLE_KEYS.EPISODES_CUMULATIVE, {
        width: 170,
        cellRenderer: LongNumber
    })
]

export const COST_COLUMNS = [
    ...SHARED_COLUMNS,
    column(C.TABLE_KEYS.PMPM_COST, { cellRenderer: DollarsCents, width: 100 }),
    column(C.TABLE_KEYS.PMPM_COST_CUMULATIVE, {
        cellRenderer: DollarsCents,
        width: 190
    })
]

export const UTILIZATION_COLUMNS = [
    ...SHARED_COLUMNS,
    column(C.TABLE_KEYS.EPISODES_PER_1000, {
        label: "Rate / 1000",
        hover: "Episodes per 1000 Patients",
        cellRenderer: RateCell,
        width: 85
    }),
    column(C.TABLE_KEYS.RATE_CUMULATIVE, {
        cellRenderer: RateCell,
        width: 140
    })
]
