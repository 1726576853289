import React from "react"
import classNames from "classnames"
import { Col } from "react-bootstrap"

const ColCard = ({ children, className, ...props }) => (
    <Col {...props} className={classNames("card", className)}>
        {children}
    </Col>
)
export default ColCard
