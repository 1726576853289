import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(
    {
        [C.CURRENT_CAMPAIGN]: null,
        [C.EDIT_CAMPAIGN_MODAL]: 0,
        savingCampaigns: []
    },
    {
        [C.SET_CURRENT_CAMPAIGN]: (state, action) => ({
            ...state,
            [C.CURRENT_CAMPAIGN]: action.payload
        }),
        [C.SHOW_EDIT]: (state, action) => ({
            ...state,
            [C.EDIT_CAMPAIGN_MODAL]: action.payload
        }),
        [C.HIDE_EDIT]: state => ({
            ...state,
            [C.EDIT_CAMPAIGN_MODAL]: 0
        }),
        [C.PIN_CAMPAIGN]: (state, { payload }) => ({
            ...state,
            savingCampaigns: _.uniq([
                ...state.savingCampaigns,
                payload.campaignId
            ])
        }),
        [C.UNPIN_CAMPAIGN]: (state, { payload }) => ({
            ...state,
            savingCampaigns: _.uniq([
                ...state.savingCampaigns,
                payload.campaignId
            ])
        }),
        [C.PIN_UNPIN_CAMPAIGN_COMPLETE]: (state, { campaignId }) => ({
            ...state,
            savingCampaigns: _.without(state.savingCampaigns, campaignId)
        })
    }
)
export default reducer
