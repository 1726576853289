import React from "react"
import * as C from "../constants"

import spinner from "../../../assets/images/loading.gif"

export const FilterFavoriteActions = ({ rowData, tableData }) =>
    rowData[C.TABLE_KEYS.LOADING] ? (
        <img src={spinner} alt="Saving..." />
    ) : rowData[C.TABLE_KEYS.DELETED] ? (
        <button
            className="fa fa-undo btn-blank"
            onClick={() => tableData.onUndelete(rowData)}
        />
    ) : (
        <>
            <button
                className="fa fa-edit btn-blank"
                onClick={() => tableData.onEdit(rowData)}
            />
            <button
                className="fa fa-trash-o btn-blank"
                onClick={() => tableData.onDelete(rowData)}
            />
        </>
    )
