import React from "react"
import Measure from "./Measure"

export const MeasureSubcategory = ({ subcategory, actions, disabled }) => (
    <div>
        <h4>{subcategory.name}</h4>
        <hr />
        <table className="measures table">
            <thead>
                <tr>
                    <th className="measures-name">Name</th>
                    <th className="measures-compliance">Compliance</th>
                    <th className="measures-value-last">Last Value</th>
                    <th className="measures-date-last">Last Date</th>
                    {disabled || (
                        <React.Fragment>
                            <th className="measures-value-new">New Value</th>
                            <th className="measures-date-new">
                                New Service Date
                            </th>
                        </React.Fragment>
                    )}
                    <th className="measures-history">History</th>
                </tr>
            </thead>
            <tbody>
                {subcategory.measures.map(measure => (
                    <Measure
                        key={measure.id}
                        measure={measure}
                        actions={actions}
                        disabled={disabled}
                    />
                ))}
            </tbody>
        </table>
    </div>
)
