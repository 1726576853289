import * as actionTypes from "./actionTypes"

export const showMyProfile = () => ({
    type: actionTypes.SHOW_MY_PROFILE
})

export const hideMyProfile = () => ({
    type: actionTypes.HIDE_MY_PROFILE
})

export const fetchMyProfile = () => ({
    type: actionTypes.FETCH_MY_PROFILE
})

export const fetchMyProfileSuccess = () => ({
    type: actionTypes.FETCH_MY_PROFILE_SUCCESS
})

export const fetchMyProfileFailure = () => ({
    type: actionTypes.FETCH_MY_PROFILE_FAILURE
})

export const updateMyProfile = data => ({
    type: actionTypes.UPDATE_MY_PROFILE,
    data
})

export const updateMyProfileSuccess = data => ({
    type: actionTypes.UPDATE_MY_PROFILE_SUCCESS,
    data
})

export const updateMyProfileFailure = () => ({
    type: actionTypes.UPDATE_MY_PROFILE_FAILURE
})
