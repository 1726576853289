import { connect } from "react-redux"
import { reduxForm } from "redux-form"

import { getResourceData } from "../../../core/fetcher"
import { FILTER_OPTIONS } from "../../../constants"
import { userTagsSelector } from "../../tagSelectors"
import * as C from "../../constants"
import * as actions from "../../actions"
import { PracticeModal } from "./PracticeModal"

const addUserTagsSelector = userTagsSelector(C.ADD_PRACTICE_UNIT_FORM)

const AddPracticeModalForm = reduxForm({
    form: C.ADD_PRACTICE_UNIT_FORM,
    enableReinitialize: true
})(PracticeModal)

export default connect(
    state => ({
        label: "Add",
        regionOptions: getResourceData(
            FILTER_OPTIONS,
            data => data.regions
        )(state),
        tags: addUserTagsSelector(state)
    }),
    {
        onHide: actions.hideAddPracticeModal,
        onSubmit: actions.addPractice
    }
)(AddPracticeModalForm)
