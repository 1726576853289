import { all, call, put, select, takeEvery } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"
import { getFormValues, reset } from "redux-form"
import moment from "moment"
import { getResourceData, modifyResource } from "../core/fetcher"
import { reloadTags } from "../patient_tags/actions"
import { ApiTypes, downloadFile, tryFetch } from "../api"
import {
    DATE_STRING,
    SERVER_DATE_STRING,
    TIMESTAMP_STRING_COMPACT
} from "../dates"
import { filterByValue, urlWithParams } from "../utils"
import * as C from "./constants"

function* pending(msg) {
    const notif = Notifications.warning({
        message: msg,
        position: "tc",
        autoDismiss: 0,
        dismissible: false
    })
    yield put(notif)
    return notif
}

export function* addCommunityLinkage() {
    const pendingNotif = yield pending("Saving Community Linkage...")

    const poc_id = yield select(state => state.poc_id)
    const values = yield select(state =>
        getFormValues(C.ADD_COMMUNITY_LINKAGE_FORM)(state)
    )

    yield* tryFetch(
        {
            url: `/api/patients/${poc_id}/linkages`,
            method: ApiTypes.POST,
            body: {
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY]:
                    values[C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY],
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION]:
                    values[C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION],
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.STATUS]:
                    values[C.ADD_COMMUNITY_LINKAGE_FIELDS.STATUS],
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE]: moment
                    .utc(
                        values[C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE],
                        DATE_STRING
                    )
                    .format(SERVER_DATE_STRING),
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.COMMENT]:
                    values[C.ADD_COMMUNITY_LINKAGE_FIELDS.COMMENT]
            }
        },
        function*(body) {
            yield handleSuccess(body, pendingNotif)
            yield put(reset(C.ADD_COMMUNITY_LINKAGE_FORM))
            yield put(reloadTags())
        },
        function*(error) {
            yield handleError(
                error,
                values[C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY],
                pendingNotif
            )
        }
    )
}

export function* updateCommunityLinkage(action) {
    const pendingNotif = yield pending("Updating Community Linkage...")

    const poc_id = yield select(state => state.poc_id)
    yield tryFetch(
        {
            url: `/api/patients/${poc_id}/linkages/${action.payload.communityLinkId}`,
            method: ApiTypes.PUT,
            body: {
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.STATUS]:
                    action.payload[C.ADD_COMMUNITY_LINKAGE_FIELDS.STATUS],
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE]:
                    action.payload[C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE],
                [C.ADD_COMMUNITY_LINKAGE_FIELDS.COMMENT]:
                    action.payload[C.ADD_COMMUNITY_LINKAGE_FIELDS.COMMENT]
            }
        },
        function*(body) {
            yield handleSuccess(body, pendingNotif)
        },
        function*(error) {
            yield handleError(
                error,
                action.payload[C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY],
                pendingNotif
            )
        }
    )
}

function* handleError(error, categoryId, pendingNotif) {
    yield put(Notifications.hide(pendingNotif.uid))

    if (error.response.status === 409) {
        const category = yield select(state =>
            getResourceData(
                C.COMMUNITY_LINKAGES,
                data =>
                    filterByValue(
                        data.communityLinkageOptions.categories,
                        categoryId
                    )?.[0]
            )(state)
        )

        yield put(
            Notifications.error({
                message: `Error - Community Linkage Already Exists! Check the history of '${category.label}' for an existing record.`,
                position: "tc",
                autoDismiss: 5
            })
        )
    } else {
        yield put(
            Notifications.error({
                message: error.message,
                position: "tc",
                autoDismiss: 5
            })
        )
    }
}

function* handleSuccess(body, pendingNotif) {
    const { communityLinkages } = body
    yield put(
        modifyResource({
            name: C.COMMUNITY_LINKAGES,
            dataTransform: data => ({
                ...data,
                communityLinkages
            })
        })
    )

    yield put(Notifications.hide(pendingNotif.uid))
    yield put(
        Notifications.success({
            message: "Community Linkage Saved!",
            position: "tc",
            autoDismiss: 3
        })
    )
}

function* exportCSV(action) {
    const poc_id = yield select(state => state.poc_id)
    const endpoint = urlWithParams(
        `/api/patients/${poc_id}/linkages/csv`,
        action.payload
    )
    const filename = `community_linkages_${moment().format(
        TIMESTAMP_STRING_COMPACT
    )}.csv`
    yield* downloadFile("Community Linkages CSV", endpoint, filename)
}

function* printPDF(action) {
    const poc_id = yield select(state => state.poc_id)
    const endpoint = urlWithParams(
        `/api/patients/${poc_id}/linkages/pdf`,
        action.payload
    )
    const filename = `community_linkages_${moment().format(
        TIMESTAMP_STRING_COMPACT
    )}.pdf`
    yield* downloadFile("Community Linkages PDF", endpoint, filename)
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.ADD_NEW_COMMUNITY_LINKAGE, addCommunityLinkage)
        }),
        call(function*() {
            yield takeEvery(C.UPDATE_COMMUNITY_LINKAGE, updateCommunityLinkage)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_CSV, exportCSV)
        }),
        call(function*() {
            yield takeEvery(C.PRINT_PDF, printPDF)
        })
    ])
}
