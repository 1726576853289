import React, { Component } from "react"
import { Field } from "redux-form"
import CheckboxTree from "react-checkbox-tree-enhanced"
import _ from "lodash"

const getValidValues = nodes =>
    _.flatMap(nodes, node => [node.value, ...getValidValues(node.children)])

export class RFCheckBoxTreeComponent extends Component {
    state = {
        expanded: this.props.initialExpanded || []
    }

    getChecked = () =>
        _.intersection(this.props.input.value, getValidValues(this.props.nodes))

    render() {
        return (
            <CheckboxTree
                showNodeIcon={false}
                nodes={this.props.nodes}
                checked={this.getChecked()}
                expanded={this.state.expanded}
                onCheck={this.props.input.onChange}
                onExpand={expanded => this.setState({ expanded })}
            />
        )
    }
}

const RFCheckBoxTree = props => (
    <Field
        parse={value => value.map(Number)}
        {...props}
        component={RFCheckBoxTreeComponent}
        key={props.initialExpanded} // reinitialize if expanded externally
    />
)

export default RFCheckBoxTree
