import React, { useState } from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { SelectField } from "../../core/CustomFields"
import CardTitle from "../../core/CardTitle"
import Toggle from "../../core/Toggle"
import {
    optionsSelector,
    qsrChartFiltersSelector,
    qsrYearSelector
} from "../selectors"
import * as actions from "../actions"
import * as C from "../constants"
import ChartLoader from "./ChartLoader"

const ChartTitle = ({ year, onChange, showPrior }) => (
    <CardTitle>
        Performance Chart for {year}
        &nbsp;
        <span
            className={classNames("card-title-prior", {
                "hide-prior": !showPrior
            })}
        >
            vs. {year - 1}
            &nbsp;
            <Toggle name="pivotType" checked={showPrior} onChange={onChange} />
        </span>
    </CardTitle>
)

const PerformanceChart = ({ chartMeasure, options, setChartMeasure, year }) => {
    const [showPrior, setShowPrior] = useState(false)

    return (
        <Card className="card-table qsr-performance-chart">
            <Card.Header>
                <ChartTitle
                    year={year}
                    showPrior={showPrior}
                    onChange={event => setShowPrior(event.target.checked)}
                />
                <SelectField
                    className="measure-select"
                    input={{
                        name: C.FILTERS.CHART_MEASURE,
                        value: chartMeasure,
                        onChange: value => setChartMeasure(value)
                    }}
                    options={options.chartMeasures}
                    label="Measure"
                    notClearable
                    noHighlighting
                />
            </Card.Header>
            <Card.Body>
                {chartMeasure ? (
                    <ChartLoader showPrior={showPrior} />
                ) : (
                    <div className="qsr-performance-placeholder">
                        Please choose a measure
                    </div>
                )}
            </Card.Body>
        </Card>
    )
}

export default connect(
    state => ({
        ...state[C.NAME],
        options: optionsSelector(state),
        year: qsrYearSelector(state)
    }),
    actions
)(PerformanceChart)
