export const NAME = "PATIENT_AUDITS"
export const FILES_NAME = "PATIENT_AUDIT_FILES"
export const PATIENT_AUDIT_COUNTS = "PATIENT_AUDIT_COUNTS"
export const PATIENT_AUDITS_VIEWED = "PATIENT_AUDITS_VIEWED"

export const PATIENT_AUDIT_FILTERS = "PATIENT_AUDIT_FILTERS"
export const PA_STATUS_FORM = "PA_STATUS_FORM"

export const SET_PATIENT_AUDIT_FILTERS = "SET_PATIENT_AUDIT_FILTERS"
export const SET_PATIENT_AUDIT_STATUS_FILTER = "SET_PATIENT_AUDIT_STATUS_FILTER"
export const SET_PATIENT_AUDIT_AUDIT_FILTER = "SET_PATIENT_AUDIT_AUDIT_FILTER"
export const CLOSE_PA_MODAL = "CLOSE_PA_MODAL"
export const SHOW_PA_FILES_MODAL = "SHOW_PA_FILES_MODAL"
export const SHOW_PA_NOTES_MODAL = "SHOW_PA_NOTES_MODAL"
export const SHOW_PA_STATUS_MODAL = "SHOW_PA_STATUS_MODAL"
export const UPLOAD_PA_FILES = "UPLOAD_PA_FILES"
export const DOWNLOAD_PA_FILE = "DOWNLOAD_PA_FILE"
export const DELETE_PA_FILE = "DELETE_PA_FILE"
export const RESTORE_PA_FILE = "RESTORE_PA_FILE"
export const UPDATE_PA_FILE_DESC = "UPDATE_PA_FILE_DESC"
export const NEW_PA_NOTE = "NEW_PA_NOTE"
export const UPDATE_PA_NOTE = "UPDATE_PA_NOTE"
export const REMOVE_PA_NOTE = "REMOVE_PA_NOTE"
export const RESTORE_PA_NOTE = "RESTORE_PA_NOTE"
export const STOP_SAVING_PA_FILE = "STOP_SAVING_PA_FILE"
export const STOP_SAVING_PA_NOTE = "STOP_SAVING_PA_NOTE"
export const UPDATE_PA_STATUS = "UPDATE_PA_STATUS"
export const EXPORT_PATIENT_AUDITS = "EXPORT_PATIENT_AUDITS"
export const PRINT_PATIENT_AUDITS = "PRINT_PATIENT_AUDITS"
export const LOG_AUDIT_VIEW = "LOG_AUDIT_VIEW"

export const FILTERS = {
    AUDIT: "auditId",
    CLOSED: "closed",
    STATUS: "statusIds",
    SERVICE: "serviceIds",
    SERVICE_DATE_FROM: "serviceDateFrom",
    SERVICE_DATE_TO: "serviceDateTo",
    PATIENT_NAME: "patientName",
    DECEASED: "deceased",
    PCPS: "pcps",
    UNITS: "units",
    PRODUCTS: "products",
    PAYERS: "payers",
    TAGS: "tags",
    REGIONS: "regions"
}

export const FILTER_LOOKUP_KEYS = {
    [FILTERS.AUDIT]: "audits",
    [FILTERS.SERVICE]: "auditServices",
    [FILTERS.STATUS]: "auditStatuses"
}

export const FILTER_TRANSLATION = {
    [FILTERS.AUDIT]: "Audit",
    [FILTERS.CLOSED]: "Closed",
    [FILTERS.STATUS]: "Status",
    [FILTERS.SERVICE]: "Services",
    [FILTERS.SERVICE_DATE_FROM]: "Service Date From",
    [FILTERS.SERVICE_DATE_TO]: "Service Date To",
    [FILTERS.PATIENT_NAME]: "Patient Name",
    [FILTERS.DECEASED]: "Deceased",
    [FILTERS.PCPS]: "PCPs",
    [FILTERS.UNITS]: "Practice Units",
    [FILTERS.PRODUCTS]: "Product Classes",
    [FILTERS.PAYERS]: "Payers",
    [FILTERS.TAGS]: "Tags",
    [FILTERS.REGIONS]: "Regions"
}

export const TABLE_KEYS = {
    AUDIT_NAME: "auditName",
    RECORD_ID: "recordId",
    PATIENT_ID: "patientId",
    PATIENT_NAME: "patientName",
    PATIENT_DOB: "patientDob",
    PATIENT_GENDER: "patientGender",
    PRACTICE_NAME: "practiceName",
    PROVIDER_NAME: "providerName",
    PRODUCT_CLASS: "productClass",
    PAYER_IDS: "payerIds",
    PAYER_NAMES: "payerNames",
    TAGS: "tags",
    RECORD_TYPE_CODE: "recordType",
    RECORD_TYPE: "recordTypeDesc",
    SERVICE_DATE: "serviceDate",
    CREATED_DATE: "createdDate",
    CREATED_BY_ID: "createdBy",
    CREATED_BY_NAME: "createdByName",
    DIAGNOSIS_CODE: "diagnosisCode",
    CODE_SYSTEM: "codeSystem",
    RESULT: "result",
    UNIT: "unit",
    SENT_RECORD_ID: "sentRecordId",
    SOURCE_ID: "sourceId",
    SOURCE: "sourceName",
    SERVICE: "serviceCode",
    SERVICE_DESC: "serviceDesc",
    FILE_COUNT: "fileCount",
    NOTE_COUNT: "noteCount",
    CLOSED: "closed",
    STATUS: "status",
    STATUS_DESC: "statusDesc",
    STATUS_REASON: "statusReason",
    STATUS_NOTE: "statusNote",
    DELETE: "delete",
    DELETED: "deleted",
    DELETED_DATE: "deletedDate",
    RECENTLY_UPDATED: "recentlyUpdated",
    FILES: "files",
    NOTES: "notes",

    // files
    RECORD_FILE_ID: "recordFileId",
    FILE_NAME: "fileName",
    DESCRIPTION: "description",

    // notes
    RECORD_NOTE_ID: "recordNoteId",
    REPLY_NOTE_ID: "replyNoteId",
    NOTE: "note",
    HIGH_IMPORTANCE: "highImportance"
}

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.AUDIT_NAME]: "Audit",
    [TABLE_KEYS.PATIENT_ID]: "Patient ID",
    [TABLE_KEYS.PATIENT_NAME]: "Patient Name",
    [TABLE_KEYS.PATIENT_DOB]: "DOB",
    [TABLE_KEYS.PATIENT_GENDER]: "Gender",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice",
    [TABLE_KEYS.PROVIDER_NAME]: "PCP",
    [TABLE_KEYS.PRODUCT_CLASS]: "Product Class",
    [TABLE_KEYS.PAYER_NAMES]: "Payers",
    [TABLE_KEYS.TAGS]: "Tags",
    [TABLE_KEYS.RECORD_TYPE]: "Record Type",
    [TABLE_KEYS.SERVICE_DATE]: "Service Date",
    [TABLE_KEYS.CREATED_DATE]: "Last Updated",
    [TABLE_KEYS.CREATED_BY_NAME]: "Last Updated By",
    [TABLE_KEYS.DIAGNOSIS_CODE]: "Code",
    [TABLE_KEYS.CODE_SYSTEM]: "Code System",
    [TABLE_KEYS.RESULT]: "Result",
    [TABLE_KEYS.SENT_RECORD_ID]: "Sent Record ID",
    [TABLE_KEYS.SOURCE]: "Source",
    [TABLE_KEYS.SERVICE]: "Service",
    [TABLE_KEYS.FILES]: "Files",
    [TABLE_KEYS.NOTES]: "Notes",
    [TABLE_KEYS.CLOSED]: "Closed",
    [TABLE_KEYS.STATUS]: "Status",
    [TABLE_KEYS.STATUS_REASON]: "Status Reason",
    [TABLE_KEYS.STATUS_NOTE]: "Status Note",
    [TABLE_KEYS.DELETE]: "Delete",

    // files
    [TABLE_KEYS.FILE_NAME]: "File",
    [TABLE_KEYS.DESCRIPTION]: "Description",

    // notes
    [TABLE_KEYS.NOTE]: "Note"
}

export const AUDIT_CATEGORIES = {
    NEW: {
        name: 1,
        label: "New",
        variant: "outline-primary",
        code: 1
    },
    IN_PROGRESS: {
        name: 2,
        label: "In Progress",
        variant: "outline-info",
        code: 2
    },
    REVIEW: {
        name: 3,
        label: "Review",
        variant: "outline-warning",
        code: 3
    },
    PENDING: {
        name: 4,
        label: "Pending",
        variant: "outline-dark",
        code: 4
    },
    PASS: {
        name: 5,
        label: "Pass",
        variant: "outline-success",
        code: 5
    },
    FAIL: {
        name: 6,
        label: "Fail",
        variant: "outline-danger",
        code: 6
    },
    CANCELED: {
        name: 7,
        label: "Canceled",
        variant: "outline-secondary",
        code: 7,
        initial: false
    }
}
