import React from "react"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"
import { FavoriteNameField } from "./FavoriteNameField"

export const FavoriteNameModal = ({
    show,
    errors,
    existingNames,
    saving,
    closeModal,
    saveFavoriteFilters,
    submit
}) => (
    <Modal show={show} onHide={closeModal}>
        <Modal.Header>
            <Modal.Title>Name Filter Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FavoriteNameField existingNames={existingNames} />
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="secondary"
                onClick={closeModal}
                style={{ marginRight: "auto" }}
            >
                Cancel
            </Button>
            <Button
                variant="primary"
                disabled={saving || !_.isEmpty(errors)}
                onClick={e => {
                    saveFavoriteFilters(true)
                    submit(e)
                }}
            >
                Update and Save Filters
            </Button>
        </Modal.Footer>
    </Modal>
)
