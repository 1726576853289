import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import fetcher from "../modules/core/fetcher"
import Loading from "../modules/core/Loading"
import { setPocId } from "../modules/structure/actions"
import { ApiTypes } from "../modules/api"
import { urlParamsObject } from "../modules/utils"
import { PATIENT_ID } from "../modules/constants"

export const ExternalRedirect = ({ data, loading, error, errorText }) =>
    loading ? (
        <Loading />
    ) : error ? (
        <p className="poc-error">
            {errorText || "No patient exists with the specified source and ID."}
        </p>
    ) : (
        <Redirect to={`/poc/${data[PATIENT_ID]}`} />
    )

export default connect(state => ({ poc_id: state.poc_id }), {
    setPocId
})(
    fetcher({
        name: PATIENT_ID,
        endpoint: (state, props) => {
            const { id, source } = urlParamsObject(props.location.search)
            return `/api/external?source=${source}&identifier=${id}`
        },
        method: ApiTypes.GET
    })(ExternalRedirect)
)
