import React from "react"
import _ from "lodash"
import spinner from "../../assets/images/loading.gif"

export const UndoButton = ({ undo, id }) => (
    <button className="btn-blank" onClick={() => undo(id)} title="Undo">
        <span className="fa fa-undo" />
    </button>
)

export const RemoveButton = ({ remove, id }) => (
    <button className="btn-blank" onClick={() => remove(id)} title="Delete">
        <span className="fa fa-trash-o" />
    </button>
)

export default ({
    rowData = {},
    field,
    statusField = "removed",
    saving,
    savingField = "changingStatus",
    undoableValues = [true],
    ...props
}) =>
    saving || rowData[savingField] ? (
        <img className="btn-blank-spinner" src={spinner} alt="Saving..." />
    ) : _.includes(undoableValues, rowData[statusField]) ? (
        <UndoButton id={rowData[field]} {...props} />
    ) : (
        <RemoveButton id={rowData[field]} {...props} />
    )
