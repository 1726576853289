import _ from "lodash"
import * as C from "./constants"

export const stampJoin = parentStamp => ownId => `${parentStamp}.${ownId}`

export const getParentStamp = stamp => _.initial(stamp.split(".")).join(".")

export const getChildren = (tree, parentStamp) =>
    Object.keys(_.get(tree, parentStamp)).map(stampJoin(parentStamp))

const pumpNextStamp = (tree, remaining) => {
    const curStamp = _.head(remaining)
    const parentStamp = getParentStamp(curStamp)

    const allSiblings = parentStamp
        ? getChildren(tree, parentStamp)
        : Object.keys(tree) // no parents? You're a root node

    const pickedSiblings = _.intersection(remaining, allSiblings)

    // remove all siblings; they'll be dealt with one way or another
    _.pullAll(remaining, allSiblings)

    if (allSiblings.length === pickedSiblings.length) {
        // all siblings were picked, so we throw them out and make plans to handle their parent's stamp later
        return { keep: [], defer: [parentStamp] }
    } else {
        // only some siblings were picked, so we need to keep all of them
        return { keep: pickedSiblings, defer: [] }
    }
}

export const leavesToBranches = (tree, leafStamps) => {
    const layers = _.groupBy(leafStamps, stamp => stamp.split(".").length)
    const maxDepth = _.max(Object.keys(layers).map(Number))

    const output = []

    for (let depth = maxDepth; depth > 0; depth--) {
        const layer = layers[depth]

        // make sure there's a next layer
        if (!layers[depth - 1]) {
            layers[depth - 1] = []
        }
        const nextLayer = layers[depth - 1]

        // prevent infinite loop
        const maxLoops = layer.length
        let loopCount = 0

        // process layer
        while (!_.isEmpty(layer) && loopCount < maxLoops) {
            const { keep, defer } = pumpNextStamp(tree, layer)

            nextLayer.push(...defer)
            output.push(...keep)

            loopCount += 1
        }
    }
    return output
}

export const branchesToLeaves = (tree, stamps = []) =>
    stamps.flatMap(stamp => {
        const branch = _.get(tree, stamp)
        return _.isEmpty(branch)
            ? stamp
            : branchesToLeaves(tree, Object.keys(branch).map(stampJoin(stamp)))
    })

export const getInitialValues = (filters, categoryTree) => ({
    ...filters,
    [C.FILTERS.CATEGORIES]: branchesToLeaves(
        categoryTree,
        filters?.[C.FILTERS.CATEGORIES]
    )
})

export const normalizeCostSummaries = costSummaries =>
    costSummaries.map(cs => ({
        ...cs,
        [C.TABLE_KEYS.PRACTICE_ID]:
            cs[C.TABLE_KEYS.PRACTICE_ID] ?? cs.practiceId, // the response uses practiceId when it should be using practiceUnitId, so we normalize it here. (The cs[CC.TABLE_KEYS.PRACTICE_ID] is just in case a response actually does have the correct fields)
        [C.TABLE_KEYS.TOTAL_COST]:
            cs[C.TABLE_KEYS.TOTAL_COST] ?? cs.claimCost ?? 0 // ditto
    }))

export const isBottomLevelRow = row =>
    !!(
        row[C.TABLE_KEYS.CATEGORY_ID] &&
        row[C.TABLE_KEYS.SUBCATEGORY_ID] &&
        row[C.TABLE_KEYS.PAYER_ID] &&
        row[C.TABLE_KEYS.PRODUCT_ID] &&
        row[C.TABLE_KEYS.PRACTICE_ID] &&
        row[C.TABLE_KEYS.PROVIDER_ID]
    )
