import React from "react"
import { SortDirection } from "react-virtualized"
import { Card } from "react-bootstrap"
import fetcher from "../../core/fetcher"
import CardTitle from "../../core/CardTitle"
import LEHOC from "../../core/LoadingErrorHOC"
import { tableFactory } from "../../table"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import { COVERAGE_TABLE_COLUMNS } from "../columns"

const { Table, Settings, Description } = tableFactory({
    name: C.COVERAGE_NAME,
    columns: COVERAGE_TABLE_COLUMNS,
    sortBy: [C.TABLE_KEYS.COVERAGE_NAME],
    sortDirection: [SortDirection.ASC]
})

const cellRenderer = ({ cellData }) => <span>{cellData}</span>

export const CoverageReference = React.memo(({ data, error, loading }) => {
    const filteredList = data?.coverages?.filter(({ lastLoadDt }) => lastLoadDt)

    return (
        <Card className="card-table">
            <Card.Header id="coverage-reference-list-header">
                <CardTitle>Date Coverage Reference</CardTitle>
                <Settings />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC loading={loading} error={error} list={filteredList}>
                    <Table list={filteredList} cellRenderer={cellRenderer} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
})

export default fetcher({
    name: C.COVERAGE_NAME,
    endpoint: "/api/qsr/coverages",
    method: ApiTypes.GET
})(CoverageReference)
