import React from "react"
import { Col, Row } from "react-bootstrap"
import SiteTitle from "../../../core/SiteTitle"
import Breadcrumbs from "../../../core/Breadcrumbs"
import { GuideLink } from "../../../indicators"
import CreateCampaignButton from "../create_campaign"
import EditCampaignModal from "../edit_campaign"
import { CampaignList } from "./CampaignList"

export const Campaigns = ({ loading, error, campaigns }) => (
    <>
        <SiteTitle title="Campaigns" />
        <Breadcrumbs items={[["Dashboard", "/dashboard"], ["Campaigns"]]} />
        <Row>
            <Col xs={6}>
                <h1>
                    Campaigns
                    <GuideLink page="view-campaigns" />
                </h1>
            </Col>
            <Col xs={6} style={{ marginTop: "15px" }}>
                <CreateCampaignButton />
            </Col>
        </Row>
        <CampaignList campaigns={campaigns} loading={loading} error={error} />
        <EditCampaignModal />
    </>
)
