import moment from "moment"
import * as C from "./constants"
import { conditionalRequired, beforeDate } from "../core/validators"

const TODAY = moment().startOf("day")
const ONE_YEAR_FROM_NOW = moment(TODAY).add(1, "year")

export const eligibleDateValidator = beforeDate(ONE_YEAR_FROM_NOW, {
    errorMessage: "Must be no later than one year from now"
})

export const validCostSpan = (value, allValues, props, name) =>
    conditionalRequired(
        allValues[C.FILTERS.COST_MIN] || allValues[C.FILTERS.COST_MAX]
    )(value, allValues, props, name)
