import { connect } from "react-redux"
import "react-table/react-table.css"

import fetcher from "../../../core/fetcher"
import { ApiTypes } from "../../../api"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import {
    activeReportSelector,
    filtersSelector,
    lookupSelector,
    reportColumnsSelector
} from "../../selectors"
import { ReportPanel } from "./ReportPanel"

export default fetcher({
    name: C.CURRENT_REPORT,
    endpoint: (state, props) => `/api/reports/${props.reportId}`,
    requestBodySelector: (state, props) =>
        filtersSelector(props.reportId)(state),
    paginationKey: "records",
    method: ApiTypes.POST,
    acceptResponse: response => ({
        // each row comes with a `total` field that's hard to remove on the backend, so we remove it here instead
        ...response,
        fields: response.fields.filter(field => field.name !== "total"),
        records: response.records.map(({ total, ...record }) => record),
        total: _.head(response.records)?.total
    })
})(
    connect(
        (state, props) => ({
            ...activeReportSelector(props.reportId)(state),
            columns: reportColumnsSelector(state),
            lookup: lookupSelector(state),
            filters: state[C.NAME][props.reportId]
        }),
        reduxActions
    )(ReportPanel)
)
