import React from "react"
import { Link } from "react-router-dom"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import classNames from "classnames"
import moment from "moment"

import LEHOC from "../../core/LoadingErrorHOC"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import CardTitle from "../../core/CardTitle"
import FilterHeader from "../../core/FilterHeader"
import DashboardFilters from "../../dashboard_filters/components"
import { hideIfNoneEditable } from "../../attention/helpers"
import { tableFactory } from "../../table"
import { getMaxDateColumn } from "../../dates"
import { GuideLink } from "../../indicators"

import * as C from "../constants"
import { TABLE_COLUMNS } from "../columns"
import TableLegend from "./TableLegend"
import { FiltersForm } from "./FiltersForm"

const CARD_TITLE = "Transitions of Care"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    TableLegend,
    sortBy: [
        getMaxDateColumn(
            C.TABLE_KEYS.TRANSITION_DATE,
            C.TABLE_KEYS.DISCHARGE_DATE
        )
    ],
    sortDirection: [SortDirection.DESC]
})

const getTime = ({ tocs, timestamp }) =>
    tocs && timestamp
        ? `Last refresh on ${moment(timestamp, "x", true).format(
              "MMMM D [at] h:mm a"
          )}`
        : ""

const getExtraFilter = canEdit =>
    hideIfNoneEditable({
        hideColumns: [C.TABLE_KEYS.EDITABLE],
        editableKey: C.TABLE_KEYS.EDITABLE,
        canEdit
    })

const rowClassNames = {
    grace_period: rowData => rowData[C.TABLE_KEYS.GRACE_PERIOD],
    "patient-list-deceased": rowData => rowData[C.TABLE_KEYS.PATIENT_DOD]
}

export const TocList = ({
    canRead,
    canEdit,
    data,
    loading,
    error,
    exportToc,
    filters,
    printToc,
    paginationFunctions,
    isSmall,
    ...props
}) => (
    <Card className={classNames("card-table toc-list", isSmall && "toc-small")}>
        <Card.Header className="toc-list-header">
            <CardTitle>
                {canRead ? (
                    <Link style={{ textDecoration: "underline" }} to="/tcm">
                        {CARD_TITLE}
                    </Link>
                ) : (
                    CARD_TITLE
                )}
                <GuideLink page="view-transitions-of-care-section" />
            </CardTitle>

            <DashboardFilters
                {...props}
                title="Transitions of Care - Filters"
                className="toc-filters"
                form={C.TOC_FILTERS_FORM}
                formComponent={FiltersForm}
                initialValues={filters}
                emptyValues={C.initialState.filters}
                size="xl"
                includeActiveFilter
            />
            <span className="toc-timestamp">{getTime(data)}</span>
            <Settings list={data.tocs} extraFilter={getExtraFilter(canEdit)} />
            <PrintIcon onClick={printToc} className="pull-right" />
            <ExportIcon onClick={exportToc} className="pull-right" />
            <FilterHeader
                filters={filters}
                translation={C.FILTER_TRANSLATION}
            />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={data.tocs}>
                <Table
                    {...paginationFunctions}
                    list={data.tocs}
                    rowClassNames={rowClassNames}
                />
            </LEHOC>
        </Card.Body>
    </Card>
)

export default TocList
