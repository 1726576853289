export const NAME = "CARE_TEAM"
export const CARE_MANAGER_TABLE_NAME = "CARE_MANAGER_TABLE"
export const SPECIALIST_TABLE_NAME = "SPECIALIST_TABLE"
export const EDIT_CARE_MANAGER_FORM = "EDIT_CARE_MANAGER_FORM"

export const SHOW_EDIT_CARE_MANAGER = "SHOW_EDIT_CARE_MANAGER"
export const HIDE_EDIT_CARE_MANAGER = "HIDE_EDIT_CARE_MANAGER"
export const SAVE_CARE_MANAGER = "SAVE_CARE_MANAGER"
export const STOP_SAVING_CARE_MANAGER = "STOP_SAVING_CARE_MANAGER"
export const UPDATE_INITIAL_VALUES = "UPDATE_INITIAL_VALUES"

export const initialState = {
    show: false,
    loading: false,
    isUpdate: false,
    initialValues: {}
}

export const PROVIDER_RELATIONSHIPS = [
    "Specialist",
    "Care Manager / Specialist"
]

export const KEYS = {
    USER_ID: "careManagerUserId",
    REASON_IDS: "reasonIds",
    EFF_DT: "effDt",
    TERM_DT: "termDt",
    PROVIDER_NAME: "providerName",
    SPECIALTY: "providerSpecialty",
    OFFICE: "practiceName",
    RECENT_SERVICE_DATE: "lastPatVisitDt",
    PAT_RELATIONSHIP: "patRelationship",
    REASONS: "reasons",
    EDIT: "edit",
    INACTIVE: "inactive"
}

export const SPECIALIST_TRANSLATION = {
    [KEYS.PROVIDER_NAME]: "Name",
    [KEYS.SPECIALTY]: "Primary Specialty",
    [KEYS.OFFICE]: "Office",
    [KEYS.RECENT_SERVICE_DATE]: "Last Visit"
}
export const CARE_MANAGER_TRANSLATION = {
    [KEYS.USER_ID]: "Care Manager",
    [KEYS.REASON_IDS]: "Reason(s)",
    [KEYS.EFF_DT]: "Enrollment Date",
    [KEYS.TERM_DT]: "Discharge Date",
    [KEYS.PROVIDER_NAME]: "Name",
    [KEYS.REASONS]: "Reason(s)",
    [KEYS.EDIT]: "Edit"
}
