import React from "react"
import { Modal, Button } from "react-bootstrap"
import { connect } from "react-redux"

import * as C from "../../constants"

const ConfirmModal = props => (
    <Modal
        dialogClassName="small-modal admin-modal"
        show={props.show}
        onHide={props.cancel}
    >
        <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.confirmMessage}</Modal.Body>
        <Modal.Footer>
            <Button
                variant="secondary"
                className="pull-left"
                onClick={props.cancel}
            >
                Cancel
            </Button>
            <Button
                variant="primary"
                className="pull-right"
                onClick={props.confirm}
            >
                Ok
            </Button>
        </Modal.Footer>
    </Modal>
)

export default connect(
    state => ({
        show: state[C.ADMIN_NAME].confirmModalOpen,
        confirmMessage: state[C.ADMIN_NAME].confirmMessage
    }),
    null
)(ConfirmModal)
