import { createSelectorCreator, defaultMemoize } from "reselect"
import _ from "lodash"
import {
    fetcher as baseFetcher,
    getMetaData as gmd,
    getResourceData as grd,
    modifyResource as mrs
} from "@healthfocus/redux-obtain"
import { callOrGet, findAndUpdate, getFieldOrSelf } from "../utils"

// This file is a wrapper around redux-obtain's fetcher. If we ever have to make customizations to fetcher's behaviour, we can put it here instead of submitting a pull request to the github.

export const fetcher = baseFetcher
export default fetcher

const createDeepEqualsSelector = createSelectorCreator(
    defaultMemoize,
    _.isEqual // no significant performance hit for this deep equality check, because we're only using it on getMetaData which returns an object with two fields, both primitives.
)
export const getMetaData = resource =>
    createDeepEqualsSelector(gmd(resource), meta => meta || {})

export const getResourceData = grd // I don't think this needs memoization
export const modifyResource = mrs // ditto

const emptyObject = Object.freeze({}) // use this instead of a plain object so that we can reuse it, making selectors safer to work with
const emptyArray = Object.freeze([])

/* if you have a connect that only pulls from a getResourceData,
 * you could shrink the selector from
 *   state => {...getResourceData(name, resourceSelector)(state)}
 * to just
 *   getResourceData(name, resourceSelector)
 * ...except that before the resource is loaded, getResourceData
 * returns undefined, which isn't a valid argument for connect.
 * This version of the function returns an empty object instead,
 * which is valid. */
export const getResourceDataAsObject = (name, selector) => state =>
    getResourceData(name, selector)(state) || emptyObject

/* similar, but for when you only ever want an array returned. */
export const getResourceDataAsArray = (name, selector) => state => {
    const data = getResourceData(name, selector)(state)
    return _.isNil(data) || _.isEqual(data, emptyObject) ? emptyArray : data
}

// some useful utilities for updating data objects
// note that these only work when data[dataField] is an array. Update manually if not.

export const modifyData = (dataField, newValues, matchBy, matchValue) => (
    data = {}
) => ({
    ...data,
    [dataField]: findAndUpdate(data[dataField], newValues, matchBy, matchValue)
})
export const removeFromData = (dataField, matchBy, matchValue) => (
    data = {}
) => ({
    ...data,
    [dataField]: _.reject(
        data[dataField],
        item =>
            callOrGet(item, matchBy) ===
            (_.isUndefined(matchValue)
                ? true
                : getFieldOrSelf(matchValue, matchBy))
    )
})
export const addToData = (dataField, newItem) => (data = {}) => ({
    ...data,
    [dataField]: [...data[dataField], newItem]
})
