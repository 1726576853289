import React from "react"
import { connect } from "react-redux"
import * as actions from "../actions"

export const ReprintLetter = ({
    getPatientLetter,
    patientId,
    cellData,
    rowData
}) => <a onClick={() => getPatientLetter(patientId, rowData)}>{cellData}</a>

export default connect(
    state => ({
        patientId: state.poc_id
    }),
    actions
)(ReprintLetter)
