import _ from "lodash"
import { createSelector } from "reselect"
import { getDefaultHeaders } from "../api"
import * as AC from "../admin/constants"
import * as C from "./constants"

export const hasRole = role => state =>
    _.get(state, [C.NAME, C.ROLES], []).includes(role)

export const isSuperuser = hasRole(AC.ROLE_SUPERUSER)
export const isExternal = hasRole(AC.ROLE_EXTERNAL)

export const authTokensSelector = createSelector(
    state => state[C.NAME].authToken,
    state => state[C.NAME].refreshToken,
    (authToken, refreshToken) => ({ authToken, refreshToken })
)

export const defaultHeadersSelector = createSelector(
    authTokensSelector,
    getDefaultHeaders
)
