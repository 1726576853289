import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData, getResourceDataAsArray } from "../core/fetcher"
import { getTopTagPrivileges } from "../user_tags/helpers"
import { itemsToObject } from "../utils"
import * as C from "./constants"
import { getAccessLevelSelector } from "./helperSelectors"
import { getPrimaryPractice } from "./components/users/helpers"

// each user's primary practice ID

export const userPrimaryPracticesSelector = createSelector(
    getResourceData(C.USERS, data => data.users),
    users =>
        itemsToObject(users, C.USER_TABLE_KEYS.USER_ID, user =>
            getPrimaryPractice(user, C.USER_PRACTICE_KEYS.PRACTICE_ID)
        )
)

// each user's effective primary practice, given the admin looking at them. Users from inactive practices, or practices the admin can't access, are given the zero practice instead.

export const userEffectivePrimaryPracticesSelector = createSelector(
    getResourceData(C.PRACTICE_UNITS),
    userPrimaryPracticesSelector,
    (practices, userPractices) => {
        const practicesById = _.keyBy(
            practices,
            C.PRACTICE_TABLE_KEYS.PRACTICE_ID
        )
        return _.mapValues(userPractices, practiceId =>
            practicesById[practiceId]?.[C.PRACTICE_TABLE_KEYS.STATUS] ===
            C.ACTIVE
                ? practicesById[practiceId]
                : C.PRACTICE_ZERO
        )
    }
)

// the list of practices for the practices table

export const practicesSelector = createSelector(
    getResourceDataAsArray(C.PRACTICE_UNITS),
    practices => [C.PRACTICE_ZERO, ...practices]
)

// the list of users for the users table
export const usersSelector = createSelector(
    getResourceData(C.USERS, data => data.users),
    state => state[C.ADMIN_NAME].selectedPractices,
    userEffectivePrimaryPracticesSelector,
    getAccessLevelSelector,
    (users = [], selectedPractices, userPrimaryPractices, getAccessLevel) =>
        users
            .filter(user =>
                selectedPractices.includes(
                    userPrimaryPractices[user[C.USER_TABLE_KEYS.USER_ID]]?.[
                        C.PRACTICE_TABLE_KEYS.PRACTICE_ID
                    ]
                )
            )
            .map((user = {}) => ({
                ...user,
                [C.USER_TABLE_KEYS.PRIMARY_PRACTICE]:
                    userPrimaryPractices[user[C.USER_TABLE_KEYS.USER_ID]]?.[
                        C.PRACTICE_TABLE_KEYS.PRACTICE_NAME
                    ],
                [C.USER_TABLE_KEYS.TAGS]: getTopTagPrivileges(
                    user[C.USER_TABLE_KEYS.TAGS]
                ),
                [C.USER_TABLE_KEYS.ACCESS_LEVEL]: getAccessLevel(user)
            }))
)
