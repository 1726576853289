import React from "react"
import classNames from "classnames"
import { Hoverlay } from "../../../../core/Hoverlay"
import { COMPLIANCE_ATTRIBUTES, PayerIcon } from "../../../../indicators"
import { IncentivizedTooltip } from "./IncentivizedTooltip"

export const IncentiveMarker = ({
    payersWithLogos,
    incentiveModuleActive,
    incentiveStatus,
    incentivizedByPayer,
    suppElig,
    suppIds
}) => (
    <Hoverlay
        className="incentivized-tooltip"
        tooltip={
            <IncentivizedTooltip
                incentiveModuleActive={incentiveModuleActive}
                incentiveStatus={incentiveStatus}
                incentivizedByPayer={incentivizedByPayer}
                suppElig={suppElig}
                suppIds={suppIds}
            />
        }
    >
        <span className="payer-logos">
            {payersWithLogos.map((payer, index) => (
                <span className="dual-icon" key={index}>
                    <PayerIcon logo={payer.logo} alt={payer.payerName} />
                    {incentiveModuleActive && (
                        <span
                            className={classNames(
                                "dual-icon-compliance",
                                COMPLIANCE_ATTRIBUTES[incentiveStatus]
                                    ?.className
                            )}
                        />
                    )}
                </span>
            ))}
        </span>
    </Hoverlay>
)
