import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { fetcher } from "../../core/fetcher"
import { Hoverlay } from "../../core/Hoverlay"
import { FilterButtonsHeader } from "../../core/FilterButtonsHeader"
import { formatFractionAsPercent } from "../../numbers"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as reduxActions from "../actions"
import { filtersSelector } from "../selectors"
import spinner from "../../../assets/images/loading.gif"

export const AwvCounter = ({
    counts = {},
    loading,
    categories,
    setCategories
}) => {
    if (loading) return <img src={spinner} alt="Loading..." />

    const completedCount = counts[C.AWV_CATEGORIES.COMPLETED.name]
    const totalCount = _.sum(_.values(counts))
    const closureRate = formatFractionAsPercent(completedCount / totalCount)

    return (
        <>
            <Hoverlay tooltip={`${completedCount} of ${totalCount} completed`}>
                Closure Rate {closureRate || "N/A"}
            </Hoverlay>
            <FilterButtonsHeader
                name="awv-categories"
                categories={C.AWV_CATEGORIES}
                counts={counts}
                values={categories}
                onChange={setCategories}
            />
        </>
    )
}

export default fetcher({
    name: C.AWV_COUNTS,
    endpoint: state => urlWithParams("/api/awv/counts", filtersSelector(state)),
    requestBodySelector: state => state[C.NAME].filters,
    method: ApiTypes.GET
})(
    connect(
        (state, { data }) => ({
            counts: data,
            ...state[C.NAME]
        }),
        reduxActions
    )(AwvCounter)
)
