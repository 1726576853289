import React from "react"
import { FormGroup } from "react-bootstrap"
import SearchBar from "../../core/SearchBar"

export const NavSearchBar = ({ input, meta }) => (
    <FormGroup className={meta && meta.error ? "has-error" : null}>
        <SearchBar
            {...input}
            disabled={!input.value || meta.error}
            type="search"
            style={{ borderRight: "0" }}
            placeholder="Search for Patient"
            buttonType="submit"
            autoComplete="off"
        />
    </FormGroup>
)
