import React from "react"
import { Field } from "redux-form"
import { Button, Col, Row } from "react-bootstrap"
import _ from "lodash"
import { TextInput, CheckboxField, SelectField } from "../../core/CustomFields"
import { minVal, mustBeANumber, validEmail } from "../../core/validators"
import * as C from "../constants"
import { SettingsFieldset } from "./SettingsFieldset"
import { ModulePicker } from "./ModulePicker"
import ToggleField from "./ToggleField"

const ORGANIZATION_FIELDS = C.SETTINGS_FIELDS[C.ORGANIZATION]
const CONTACT_FIELDS = C.SETTINGS_FIELDS[C.CONTACT]
const UM_SETTINGS_FIELDS = C.SETTINGS_FIELDS[C.UM_SETTINGS]

export const SiteSettings = ({
    roles = [],
    modules,
    productClasses,
    submitSettings,
    handleSubmit
}) => (
    <form onSubmit={handleSubmit(submitSettings)} className="site_settings">
        <Row>
            <Col lg={6}>
                <Row>
                    <SettingsFieldset name={C.ORGANIZATION}>
                        <Col sm={9}>
                            <Field
                                name={ORGANIZATION_FIELDS.NAME}
                                component={TextInput}
                            />
                        </Col>
                        <Col sm={3}>
                            <Field
                                name={ORGANIZATION_FIELDS.ABBREV}
                                component={TextInput}
                            />
                        </Col>
                    </SettingsFieldset>
                </Row>
                <Row>
                    <SettingsFieldset name={C.CONTACT}>
                        <Col sm={4}>
                            <Field
                                name={CONTACT_FIELDS.NAME}
                                component={TextInput}
                            />
                        </Col>
                        <Col sm={4}>
                            <Field
                                name={CONTACT_FIELDS.PHONE}
                                component={TextInput}
                            />
                        </Col>
                        <Col sm={4}>
                            <Field
                                name={CONTACT_FIELDS.EMAIL}
                                component={TextInput}
                                validate={validEmail}
                            />
                        </Col>
                    </SettingsFieldset>
                </Row>
                <Row>
                    <SettingsFieldset
                        name={C.TABLE_FILTERS}
                        colClassName="table_filters"
                    >
                        <ToggleField name={C.QSR_FORM} />
                        <ToggleField name={C.QSR_DASHBOARD_FORM} />
                        <ToggleField name={C.PATIENT_LIST_FORM} />

                        <Field
                            name={C.COST_DASHBOARD_PRODUCT}
                            component={SelectField}
                            options={productClasses}
                            label="Cost Dashboard product class:"
                            className="settings-dropdown col-sm-12"
                            noSection
                        />
                        <Field
                            name={C.RISK_DASHBOARD_PRODUCT}
                            component={SelectField}
                            options={productClasses}
                            label="Risk Dashboard product class:"
                            className="settings-dropdown col-sm-12"
                            noSection
                        />
                    </SettingsFieldset>
                    <SettingsFieldset
                        name={C.GLOBAL_TOGGLES}
                        colClassName="global_toggles"
                        noSection
                    >
                        <Field
                            name={C.ALLOW_GLOBAL_SEARCH}
                            component={CheckboxField}
                        />
                        <Field
                            name={C.ALLOW_CONTRACT_NUMBER_SEARCH_PATH}
                            component={CheckboxField}
                        />
                        <Field
                            name={C.ALLOW_PATIENT_ID_SEARCH_PATH}
                            component={CheckboxField}
                        />
                        <Field
                            name={C.REAL_TIME_METRICS}
                            component={CheckboxField}
                        />
                    </SettingsFieldset>
                </Row>
                <Row>
                    <SettingsFieldset lg={12} name={C.UM_SETTINGS}>
                        <Col sm={6}>
                            <Field
                                name={UM_SETTINGS_FIELDS.MAX_LOGINS}
                                component={TextInput}
                                validate={[mustBeANumber, minVal(1)]}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={UM_SETTINGS_FIELDS.EXPIRATION}
                                component={TextInput}
                                validate={[mustBeANumber, minVal(1)]}
                            />
                        </Col>
                    </SettingsFieldset>
                </Row>
            </Col>

            <SettingsFieldset
                name={C.MODULES}
                className="modules"
                lg={6}
                noSection
            >
                <div>
                    <div className="table-header">
                        <span className="spacer" />
                        <span className="column-header" />
                        {roles.map(role => (
                            <span key={role.roleId} className="column-header">
                                {role.name}
                            </span>
                        ))}
                    </div>
                    {_.sortBy(
                        _.values(modules),
                        module => module.moduleName
                    ).map(module => (
                        <Field
                            key={module.modulesId}
                            module={module}
                            name={`modules.${module.modulesId}`}
                            component={ModulePicker}
                            roles={roles}
                        />
                    ))}
                </div>
            </SettingsFieldset>
        </Row>

        <div className="sticky-bottom">
            <Button variant="primary" type="submit">
                Save changes
            </Button>
        </div>
    </form>
)
