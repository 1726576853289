import { connect } from "react-redux"

import fetcher from "../../../core/fetcher"
import { rowCountSelector } from "../../../selectorUtils"

import {
    endpointSelector,
    patientListSelector,
    requestBodySelector,
    tableDataSelector
} from "../../selectors"
import * as C from "../../constants"

import { PatientList } from "./PatientList"

export default fetcher({
    name: C.CAMPAIGN_PATIENT_LIST,
    endpoint: endpointSelector,
    requestBodySelector,
    defaultOrderBys: C.DEFAULT_ORDER_BYS,
    paginationKey: "patients"
})(
    connect(state => ({
        list: patientListSelector(state), // can't just use the fetcher's data directly because we need to use this to add conditions values
        rowCount: rowCountSelector(C.CAMPAIGN_PATIENT_LIST_COUNT)(state),
        tableData: tableDataSelector(state)
    }))(PatientList)
)
