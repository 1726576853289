import * as PLC from "../patient_list/constants"
import * as AWC from "../awv/constants"
export const TABLE_DESCRIPTIONS = "TABLE_DESCRIPTIONS"

// actions
export const INITIALIZE_TABLE = "INITIALIZE_TABLE"
export const UPDATE_TABLE = "UPDATE_TABLE"
export const RESET_TABLE = "RESET_TABLE"

// store keys
export const TABLE_STORE = "TABLES"

export const NULLS_HIGH_COLUMNS = [PLC.TABLE_KEYS.INCENTIVE_STATUS]
export const NULLS_LAST_COLUMNS = [
    AWC.TABLE_KEYS.HCC_REDOC_NUM,
    AWC.TABLE_KEYS.HCC_REDOC_RAF,
    AWC.TABLE_KEYS.HCC_REDOC_NUM_REMAINING,
    AWC.TABLE_KEYS.HCC_REDOC_RAF_REMAINING
] // TODO replace this with a prop passed into the columns instead

// constants

export const HEADER_HEIGHT = 40
export const ROW_HEIGHT = 35
export const COLUMN_WIDTH_DEFAULT = 100
export const COLUMN_FLEX_GROW_DEFAULT = 0.2
export const COL1_EXTRA_SPACE = 10
