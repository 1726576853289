import React, { useState } from "react"
import { Card } from "react-bootstrap"

import { ExportIcon, PrintIcon } from "../../core/Icons"
import FilterHeader from "../../core/FilterHeader"
import { FiltersButton } from "../../dashboard_filters/components/FiltersButton"
import * as C from "../constants"
import GeneratePatientList from "./GeneratePatientList"
import { ClearFiltersButton } from "./ClearFiltersButton"

const Header = ({
    Settings,
    filters,
    exportPatientList,
    printPatientList,
    isDefault,
    headerTranslation,
    ...props
}) => {
    const [show, setShow] = useState(false)
    return (
        <Card.Header className="patient-list-header">
            <FiltersButton
                onClick={() => setShow(true)}
                className="filter-button"
            />
            <GeneratePatientList
                {...props}
                show={show}
                initialValues={{
                    [C.FILTERS_SECTION]: filters
                }}
                hideGeneratePL={() => setShow(false)}
            />
            <FilterHeader filters={filters} translation={headerTranslation} />
            {isDefault || <ClearFiltersButton />}
            <ExportIcon onClick={exportPatientList} />
            <PrintIcon onClick={printPatientList} />
            {Settings}
        </Card.Header>
    )
}

export default Header
