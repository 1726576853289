import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import { ACCESS_PRIVILEGES } from "../permission_managment/constants"
import { USER_TAGS_TABLE } from "../user_tags/constants"
import {
    getExternalTooltip,
    getTagSourceText,
    getTopPrivilege,
    getTopTagPrivileges
} from "../user_tags/helpers"
import { easyFormValueSelector } from "../selectorUtils"
import * as C from "./constants"
import {
    getAccessLevelSelector,
    isExternalAccess,
    isSuperuserAccess
} from "./helperSelectors"
import { canEditTag, canReadTag } from "./components/users/helpers"

// TAG UTILITY FUNCTIONS

const isOwner = source =>
    [C.TAG_SOURCES.PRIVATE, C.TAG_SOURCES.OWNER].includes(source)

export const capPrivilege = (privilege, accessLevel) =>
    isExternalAccess(accessLevel) && privilege === ACCESS_PRIVILEGES.EDIT
        ? ACCESS_PRIVILEGES.READ
        : privilege

const getLock = (func, forcedTag = {}) =>
    func(forcedTag) && getTagSourceText(forcedTag).tooltip

const buildTag = (tagId, privilege, source) => ({
    [C.TAG_COLUMNS.TAG_ID]: tagId,
    [C.TAG_COLUMNS.PRIVILEGE]: privilege,
    [C.TAG_COLUMNS.SOURCE]: source
})

const getDefaultTag = (tag, accessLevel) => {
    if (!tag[C.TAG_COLUMNS.PUBLIC]) {
        // private tags are never available to anyone but their owner
        return null
    }
    if (tag[C.TAG_COLUMNS.TAG_ID] < 0 && isSuperuserAccess(accessLevel)) {
        // system tags are editable for superusers
        return buildTag(
            tag[C.TAG_COLUMNS.TAG_ID],
            ACCESS_PRIVILEGES.EDIT,
            C.TAG_SOURCES.SYSTEM
        )
    }
    if (tag[C.TAG_COLUMNS.TAG_ID] < 0 && !isExternalAccess(accessLevel)) {
        // system tags are readable to non-external users
        return buildTag(
            tag[C.TAG_COLUMNS.TAG_ID],
            ACCESS_PRIVILEGES.READ,
            C.TAG_SOURCES.SYSTEM
        )
    }
    if (isSuperuserAccess(accessLevel)) {
        // other public tags are readable to all superusers
        return buildTag(
            tag[C.TAG_COLUMNS.TAG_ID],
            ACCESS_PRIVILEGES.READ,
            C.TAG_SOURCES.SUPERUSER
        )
    }

    // no default value for this tag
    return null
}

// TAG SELECTORS

export const editableTagsSelector = createSelector(
    getResourceData(USER_TAGS_TABLE, data => data.tags),
    allTags =>
        _.sortBy(getTopTagPrivileges(allTags), C.TAG_COLUMNS.TAG_NAME).filter(
            tag => tag[C.TAG_COLUMNS.PUBLIC] && canEditTag(tag)
        )
)

/** constructs a dict of the selected user's current forced tag privileges based
 * on access level, tag ownership, and practice membership.
 * The data is constructed on the frontend instead of taken from the DB because
 * we're accounting for unsaved changes, e.g. if you're creating a new user or
 * have changed a user's access level. */
export const currentForcedTagsSelector = formName =>
    createSelector(
        getResourceData(C.SELECTED_USER),
        editableTagsSelector,
        getResourceData(C.USER_PRACTICE_TAGS),
        easyFormValueSelector(
            formName,
            C.USER_FIELDS.USER_UNITS,
            C.USER_FIELDS.ROLE
        ),
        getAccessLevelSelector,
        (
            user = {},
            editableTags = [],
            practiceTags = {},
            formValues = {},
            getAccessLevel
        ) => {
            const accessLevel = getAccessLevel(formValues)

            // tags forced by practice membership
            const userPracticeIds = isExternalAccess(accessLevel)
                ? []
                : _.get(formValues, C.USER_FIELDS.USER_UNITS, [])
            const userPracticeTags = _.at(practiceTags, userPracticeIds)
            const topPracticeTags = getTopTagPrivileges(
                _.flatten(_.compact(userPracticeTags))
            ).map(tag => ({
                ...tag,
                [C.TAG_COLUMNS.SOURCE]: C.TAG_SOURCES.PRACTICE
            }))

            // tags forced by access level
            const accessLevelTags = _.compact(
                _.map(editableTags, tag => getDefaultTag(tag, accessLevel))
            )

            // tags forced by ownership
            const ownedTags = _.filter(user[C.USER_FIELDS.TAGS], tag =>
                isOwner(tag[C.TAG_COLUMNS.SOURCE])
            )
            const topOwnedTags = getTopTagPrivileges(ownedTags)

            // put em all together
            return _.keyBy(
                [...topPracticeTags, ...accessLevelTags, ...topOwnedTags], // the ordering is significant. owned tags overrule access tags overrule practice tags
                C.TAG_COLUMNS.TAG_ID
            )
        }
    )

/** return the tags and values to display on the add/edit user tags tab */
export const userTagsSelector = formName =>
    createSelector(
        editableTagsSelector,
        currentForcedTagsSelector(formName),
        easyFormValueSelector(formName, C.USER_FIELDS.TAGS, C.USER_FIELDS.ROLE),
        getAccessLevelSelector,
        (
            editableTags = [],
            currentForcedTags = {},
            formValues = {},
            getAccessLevel
        ) =>
            _.map(editableTags, ({ tagId, tagName }) => {
                const forcedTag = _.get(currentForcedTags, tagId, {})
                const [forcedPrivilege, forcedSource] = _.at(forcedTag, [
                    C.TAG_COLUMNS.PRIVILEGE,
                    C.TAG_COLUMNS.SOURCE
                ])

                // if a user is external, their edit permissions can be downgraded
                const accessLevel = getAccessLevel(formValues)
                const noEdit =
                    isExternalAccess(accessLevel) && !isOwner(forcedSource) // external users that somehow own tags can still modify them
                const manualPrivilege = capPrivilege(
                    _.get(formValues, [C.USER_FIELDS.TAGS, tagId]),
                    accessLevel
                )

                const readLock = getLock(canReadTag, forcedTag)
                const editLock = noEdit
                    ? getExternalTooltip()
                    : getLock(canEditTag, forcedTag)

                return {
                    [C.TAG_COLUMNS.TAG_NAME]: tagName,
                    [C.TAG_COLUMNS.TAG_ID]: tagId,
                    [C.TAG_COLUMNS.PRIVILEGE]: getTopPrivilege(
                        manualPrivilege,
                        forcedPrivilege
                    ),
                    readLock,
                    editLock
                }
            })
    )

/** same but for add/edit practice */
export const practiceTagsSelector = formName =>
    createSelector(
        editableTagsSelector,
        easyFormValueSelector(formName, C.PRACTICE_FIELDS.TAGS),
        (editableTags = [], tagPrivileges = {}) =>
            _.map(editableTags, tag => ({
                ..._.pick(tag, [C.TAG_COLUMNS.TAG_ID, C.TAG_COLUMNS.TAG_NAME]),
                [C.TAG_COLUMNS.PRIVILEGE]:
                    tagPrivileges[tag[C.TAG_COLUMNS.TAG_ID]]
            }))
    )
