import React, {
    Component,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react"
import { createPortal } from "react-dom"
import Cleave from "cleave.js/react"
import { useOutsideClick } from "use-dom-outside-click"
import moment from "moment"
import DateTime from "react-datetime"
import classNames from "classnames"
import _ from "lodash"
import { asArray, defaultOrBlank } from "../utils"
import { DATE_STRING, END_OF_TIME_MOMENT, START_OF_TIME_MOMENT } from "../dates"
import { getStateClass, getValidProps } from "./helpers"
import { Hoverlay } from "./Hoverlay"
import { Saving } from "./Loading"

// This assumes that dateFormat is actually decently formatted (which it will be as long as this is only used by DatePickerComponent)
// If you pass some arbitrary string to this, it'll have trouble.
const parseDatePattern = dateFormat =>
    dateFormat
        .split("/")
        .filter(chunk => !!chunk)
        .map(chunk => chunk[0]) // e.g. "YYYY" -> "Y"
        .map(char => (char === "Y" ? char : char.toLowerCase())) // only 'Y' can be in uppercase in cleave.js' format

export const DatePickerInput = ({ dateFormat, inputRef, ...props }) => {
    const datePattern = dateFormat
        ? parseDatePattern(dateFormat)
        : ["m", "d", "Y"]

    return (
        <Cleave
            {...props}
            htmlRef={ref => (inputRef.current = ref)} // for whatever reason, this only works as a callback ref
            autoComplete="off"
            placeholder={dateFormat}
            options={{
                date: true,
                datePattern
            }}
            // TODO add some ARIA input attributes, such as aria-haspopup. Look at SelectCell in DevTools for an example.
        />
    )
}

export const DatePickerButton = ({
    clearDate,
    setDateToday,
    disableClear,
    disableToday,
    disabled,
    loading,
    clearTooltip = "Clear date",
    todayTooltip = "Use today's date",
    value
}) => (
    <>
        {loading ? (
            <span className="datepicker-loading">
                <Saving />
            </span>
        ) : value && !disableClear ? (
            <Hoverlay
                className="tooltip-red"
                placement="right"
                tooltip={clearTooltip}
                disabled={disabled}
                forceWrapper
            >
                <i
                    className="datepicker-button select-clear-date fa fa-calendar-times-o"
                    onClick={disabled ? undefined : clearDate}
                />
            </Hoverlay>
        ) : !value && !disableToday ? (
            <Hoverlay
                className="tooltip-blue"
                placement="right"
                tooltip={todayTooltip}
                disabled={disabled}
                forceWrapper
            >
                <i
                    className="datepicker-button select-today fa fa-calendar"
                    onClick={disabled ? undefined : setDateToday}
                />
            </Hoverlay>
        ) : null}
    </>
)

// This is a separate component so that we can position the breakOut menu manually and make it not render while we're scrolling. It doesn't sync correctly with scrolling on its own.
export const DatePickerBreakoutOverlay = ({
    children,
    menuRef,
    containerRef,
    closeCalendar
}) => {
    const [scrolling, setScrolling] = useState(false)

    useOutsideClick(menuRef, closeCalendar, [containerRef])

    useEffect(() => {
        const waitForScrollFinish = _.debounce(() => setScrolling(false), 300)
        const onScroll = () => {
            setScrolling(true)
            waitForScrollFinish() // don't show again until we stop scrolling
        }
        window.addEventListener("scroll", onScroll, true)
        return () => {
            waitForScrollFinish.cancel()
            window.removeEventListener("scroll", onScroll, true)
        }
    }, [])

    const { left = 0, top = 0, height = 0 } =
        containerRef?.current?.getBoundingClientRect() || {}

    return (
        <div className="rdtOverlay">
            <div
                className="rdtPicker rdtBreakout"
                ref={menuRef}
                style={{
                    display: scrolling ? "none" : "block", // can't show it during scroll because the element doesn't update its position fast enough.
                    left,
                    top: top + height // right below the input
                }}
            >
                {children}
            </div>
        </div>
    )
}

class DatePickerComponent extends Component {
    menuRef = this.props.menuRef || React.createRef()

    closeCalendar = () => null // will be redefined as soon as the input is rendered though

    renderInput = (inputProps, openCalendar, closeCalendar) => {
        this.closeCalendar = closeCalendar // it's pretty dumb that I had to do it like this, but this closeCalendar function is the only way I could find to imperatively close the picker. So we have to pass it along to the other places that need it.

        return (
            <DatePickerInput
                {...inputProps}
                inputRef={this.props.inputRef}
                disabled={this.props.disabled}
                value={this.props.value} // DateTime doesn't always seem to update in time, so set the displayed value manually
                dateFormat={this.props.dateFormat}
                className={this.props.inputClassName}
            />
        )
    }

    renderView = (viewMode, renderDefault) => {
        return (
            this.props.show &&
            createPortal(
                <DatePickerBreakoutOverlay
                    closeCalendar={this.closeCalendar}
                    containerRef={this.props.containerRef}
                    menuRef={this.menuRef}
                >
                    {renderDefault()}
                </DatePickerBreakoutOverlay>,
                document.body
            )
        )
    }

    render() {
        return (
            <DateTime
                {...this.props}
                strictParsing
                closeOnSelect
                closeOnTab
                closeOnClickOutside={false}
                timeFormat={false}
                renderInput={this.renderInput}
                renderView={this.renderView}
            />
        )
    }
}

export const isValidDate = ({
    validDates = [],
    formValues,
    name,
    ...props
}) => date => {
    // an empty date is fine
    if (!date) {
        return true
    }

    // is it a valid Moment date?
    // (It should be since the inner DatePicker is generating it, but make sure)
    if (!moment.isMoment(date) || !date.isValid()) {
        return false
    }

    // is it within valid date bounds?
    if (
        date.isBefore(START_OF_TIME_MOMENT) ||
        date.isAfter(END_OF_TIME_MOMENT)
    ) {
        return false
    }

    // additional validation
    // validPickerDate should be a redux-form validator.
    return _.every(
        asArray(validDates),
        validator => !validator(date, formValues, props, name)
    )
}
export const formatDate = (value, dateFormat) =>
    moment.isMoment(value) ? value.format(dateFormat) : value || ""

/**
 * Important note: this component only accepts dates as Moment objects, or as strings in MM/DD/YYYY
 * format (or whichever format is specified in the dateFormat prop). Which is dumb, but unfortunately
 * it's necessary because we need to be able to have partial strings stored and converting a YYYY-MM-DD
 * formatted datestring to MM/DD/YYYY (or vice versa) would lose that
 */
export const DatePicker = (props, ref) => {
    const {
        dateFormat = DATE_STRING,
        inputProps = {},
        disabled = inputProps.disabled,
        value,
        defaultValue,
        warning,
        error,
        style,
        className,
        menuRef,
        onBlur,
        onChange,
        onFocus
    } = props

    const inputRef = ref ?? useRef()
    const containerRef = useRef()

    const [show, setShow] = useState(false)

    // input event handlers

    const handleChange = useCallback(
        value => {
            onChange?.(formatDate(value, dateFormat))
        },
        [onChange]
    )
    const handleBlur = useCallback(
        value => {
            onBlur?.(formatDate(value, dateFormat))
            setShow(false)
        },
        [onBlur]
    )
    const handleFocus = useCallback(
        event => {
            onFocus?.(event)
            setShow(true)
        },
        [onFocus]
    )

    // date updaters

    const setDate = useCallback(
        date => {
            handleChange(date)
            handleBlur(date)
        },
        [handleChange, handleBlur]
    )

    const setDateToday = useCallback(
        () => setDate(moment().format(dateFormat)),
        [setDate, dateFormat]
    )
    const clearDate = useCallback(
        () => setDate(defaultOrBlank(value, defaultValue)),
        [setDate, value, defaultValue]
    )

    const isValidDateCallback = useCallback(isValidDate(props), [props])

    return (
        <div
            ref={containerRef}
            style={style}
            className={classNames(
                "datepicker rdtBreakoutContainer",
                className,
                getStateClass(disabled, error, warning, value)
            )}
        >
            <DatePickerComponent
                value={value}
                show={show}
                onChange={handleChange}
                onClose={handleBlur}
                onOpen={handleFocus}
                inputProps={inputProps}
                isValidDate={isValidDateCallback}
                inputClassName={classNames(
                    "form-control",
                    inputProps?.className
                )}
                dateFormat={dateFormat}
                disabled={disabled}
                inputRef={inputRef}
                containerRef={containerRef}
                menuRef={menuRef}
            />
            <DatePickerButton
                {...props}
                disabled={disabled}
                clearDate={clearDate}
                setDateToday={setDateToday}
            />
        </div>
    )
}

export default React.forwardRef(DatePicker)

export const ValidatedDatePicker = props => {
    const { input, meta, ...otherProps } = props
    const validProps = getValidProps(input, meta)
    return <DatePicker {...otherProps} {...validProps} />
}
