import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SAVE_TOC_FILTERS]: (state, action) => ({
        ...state,
        filters: action.payload
    })
})

export default reducer
