import React from "react"
import { Field } from "redux-form"
import { SelectField } from "../../../../core/CustomFields"
import { getDropListOptions, getMeasureIdName } from "../../../helpers"
import * as C from "../../../constants"
import Survey from "./Survey"
import { CheckboxField } from "./CustomFields"
import AutoCalculated from "./AutoCalculated"
import CompositeMeasure from "./CompositeMeasure"
import NumericWrapper from "./NumericWrapper"

export const MeasureInput = props => {
    if (props.dataType === C.COMPOSITE) {
        return <CompositeMeasure {...props} />
    }
    if (props.autoCalc) {
        return (
            <Field
                name={getMeasureIdName(props)}
                nameText={props.name}
                component={AutoCalculated}
                dataType={props.dataType}
                dropListOptions={props.dropListOptions}
                unitLabel={props.unitLabel}
                disabled={props.disabled}
            />
        )
    }
    if (props.subMeasures && props.subMeasures.length > 0) {
        return <Survey {...props} />
    }
    switch (props.dataType) {
        case C.NUMERIC:
        case C.ALPHANUMERIC:
            return (
                <NumericWrapper
                    {...props}
                    name={getMeasureIdName(props)}
                    nameText={props.name}
                />
            )
        case C.CHECKBOX:
            return (
                <Field
                    {...props}
                    name={getMeasureIdName(props)}
                    nameText={props.name}
                    component={CheckboxField}
                />
            )
        case C.ENUMERATION:
            return (
                <Field
                    {...props}
                    name={getMeasureIdName(props)}
                    nameText={props.name}
                    options={getDropListOptions(props.dropListOptions)}
                    component={SelectField}
                                    />
            )
        default:
            return <div />
    }
}

export default MeasureInput
