import React from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import _ from "lodash"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import { tableFactory } from "../../table"
import * as C from "../constants"
import { TABLE_COLUMNS } from "../columns"
import { hideIfNoneEditable } from "../helpers"

export const defaultOrderBys = {
    sortBy: [C.TABLE_KEYS.ADMIT_DATE],
    sortDirection: [SortDirection.DESC]
}

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    ...defaultOrderBys
})

export const PriorityAttention = ({
    loading,
    error,
    canEdit,
    data: { tcms }
}) => (
    <Card id="priority-attention" className="card-table">
        <Card.Header id="priority-attention-list-header">
            <CardTitle>
                Priority Attention {_.isEmpty(tcms) ? " - No Data" : ""}
            </CardTitle>
            <Settings
                list={tcms}
                extraFilter={hideIfNoneEditable({
                    hideColumns: [C.TABLE_KEYS.EDIT_TCM, C.TABLE_KEYS.REMOVE],
                    editableKey: C.TABLE_KEYS.EDITABLE,
                    canEdit
                })}
            />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={tcms} hideEmpty>
                <Table
                    list={tcms}
                    rowClassNames={{
                        removed_row: rowData => rowData[C.TABLE_KEYS.REMOVED]
                    }}
                />
            </LEHOC>
        </Card.Body>
    </Card>
)
