import React from "react"
import { GracePeriod, PayerIcon } from "../../indicators"
import * as C from "../constants"

export const CoveragePlan = ({ plan }) => {
    const [payerName, payerLogo, productClassName, term45] = _.at(plan, [
        C.KEYS.PAYER_NAME,
        C.KEYS.PAYER_LOGO,
        C.KEYS.PRODUCT_NAME,
        C.KEYS.GRACE_PERIOD
    ])
    return (
        <div>
            {payerLogo && <PayerIcon logo={payerLogo} term45={term45} />}
            <span>
                {payerName} {productClassName && `(${productClassName}) `}
            </span>
            {term45 && <GracePeriod />}
        </div>
    )
}
