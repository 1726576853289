import {
    validatePasswords,
    validateUser
} from "../../admin/components/users/validateUser"

export const validateSelf = (values, props) => {
    if (values.userId !== 0) {
        return validateUser(values, props)
    }

    return validatePasswords(values, props)
}
