import React from "react"
import table_settings from "../../table_settings"
import AutoLogout from "../../authentication/components/AutoLogout"
import AutoRefresh from "../../authentication/components/AutoRefresh"
import ResetPasswordModal from "../../authentication/components/ResetPasswordModal"
import Footer from "../../core/Footer"
import Locations from "../../locations/components"
import add_new_patient from "../../add_new_patient"
import user_tags from "../../user_tags"
import batch_print from "../../batch_print"
import patient_list from "../../patient_list"
import inactive_patients from "../../inactive_patients"
import Navigation from "../../navigation/components"
import MeasuresFetcher from "./MeasuresFetcher"
import FilterOptionsFetcher from "./FilterOptionsFetcher"
import documents from "../../documents"

const Page = ({ children, ...props }) => (
    <>
        <Navigation {...props} />
        <div id="main-banner" />
        <table_settings.components.SettingsFetcher>
            {children}
        </table_settings.components.SettingsFetcher>
        <Footer />
        <batch_print.components.BatchPrint />
        <patient_list.components.GeneratePL />
        <add_new_patient.components.default />
        <inactive_patients.components.default />
        <user_tags.components.default />
        <AutoLogout />
        <AutoRefresh />
        <MeasuresFetcher />
        <FilterOptionsFetcher />
        <table_settings.components.TableDescFetcher />
        <ResetPasswordModal />
        <Locations />
        <documents.components.default />
    </>
)

export default Page
