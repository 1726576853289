import { connect } from "react-redux"
import DateEntry from "../../core/DateEntry"
import { canWriteTcmSelector } from "../selectors"
import * as C from "../constants"
import { getErrorKey } from "../helpers"
import * as reduxActions from "../actions"
import { tcmDateValidator } from "../validators"

const validate = rowData =>
    tcmDateValidator(rowData[C.TABLE_KEYS.DISCHARGE_DATE])

export default connect(
    (state, { rowData, dataKey }) => ({
        canWrite: canWriteTcmSelector(state),
        error:
            state[C.NAME].errors[
                getErrorKey(dataKey, rowData[C.TABLE_KEYS.TCM_TRACKER_ID])
            ],
        validate: validate(rowData),
        keyGetter: ({ cellData, rowData }) =>
            `${rowData[C.TABLE_KEYS.PATIENT_ID]}_${cellData}`,
        getPayload: () => ({
            field: dataKey,
            tcmId: rowData[C.TCM_LOOKUP[dataKey]],
            transitionId: rowData[C.TABLE_KEYS.TCM_TRACKER_ID]
        })
    }),
    { ...reduxActions, saveDate: reduxActions.updateTCM }
)(DateEntry)
