import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import classNames from "classnames"
import _ from "lodash"
import { Hoverlay } from "../../../core/Hoverlay"
import * as C from "../../constants"
import UserInfoPage from "./info/UserInfoPage"
import UserTagsPage from "./tags/UserTagsPage"
import UserPracticePage from "./practices/UserPracticePage"
import UserProviderPage from "./providers/UserProviderPage"

const getTabTitle = (title, tabDisabled) => (
    <Hoverlay
        tooltip={C.TAB_WARNINGS[title]}
        disabled={!tabDisabled || !C.TAB_WARNINGS[title]}
        // the hoverlay is disabled if the tab *isn't* disabled, because we don't need to show a warning
    >
        {title}
    </Hoverlay>
)

const getTabClass = (invalidTabs, eventKey) =>
    classNames({ "user-tab-error": _.includes(invalidTabs, Number(eventKey)) })

// I'd have liked for this to be a component instead, but the only component that can be a direct child of Tabs is a Tab.
const conditionalTab = ({
    disableCondition = () => false,
    invalidTabs,
    ...props
}) => (eventKey, title, Component, disabled = false) => (
    <Tab
        eventKey={eventKey}
        title={getTabTitle(title, disabled)}
        tabClassName={getTabClass(invalidTabs, eventKey)}
        className="filters"
        disabled={disabled || disableCondition(eventKey)}
    >
        <Component {...props} />
    </Tab>
)

export const UserModalTabs = ({ activeKey, onSelect, ...props }) => {
    const createTab = conditionalTab(props)
    const isExternal = props.selectedUser[C.USER_FIELDS.IS_EXTERNAL]

    return (
        <Tabs
            defaultActiveKey={1}
            activeKey={activeKey}
            onSelect={onSelect}
            id={`tabs-${props.form}`}
        >
            {createTab(1, C.TAB_INFO, UserInfoPage, false)}
            {createTab(2, C.TAB_PRACTICES, UserPracticePage, isExternal)}
            {createTab(3, C.TAB_PANELS, UserProviderPage, isExternal)}
            {createTab(4, C.TAB_TAGS, UserTagsPage, false)}
        </Tabs>
    )
}
