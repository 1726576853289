import React from "react"
import { Col, FormGroup, Row } from "react-bootstrap"
import _ from "lodash"
import PatientSelect from "../../../core/PatientSelect"

const StaticPatient = ({ patient, onRemove }) => (
    <div>
        <span>{patient.label}</span>
        <span className="fa fa-close" onClick={onRemove(patient)} />
    </div>
)

export class ModifyStaticPatientList extends React.Component {
    removePatient = toRemove => () =>
        this.props.input.onChange(
            this.props.input.value.filter(
                patient => patient.value !== toRemove.value
            )
        )

    render() {
        return (
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <label style={{ fontWeight: "bold" }}>
                            Add Patients
                        </label>
                        <PatientSelect
                            onChange={this.props.input.onChange}
                            selectedPatients={this.props.input.value}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <label style={{ fontWeight: "bold", width: "100%" }}>
                        Selected Patients
                        <span style={{ float: "right" }}>Remove</span>
                    </label>
                    <div
                        className="selected_patients_list"
                        style={{ minHeight: "0" }}
                    >
                        {_.sortBy(this.props.input.value, a => a.label).map(
                            patient => (
                                <StaticPatient
                                    key={patient.value}
                                    patient={patient}
                                    onRemove={this.removePatient}
                                />
                            )
                        )}
                    </div>
                </Col>
            </Row>
        )
    }
}

export default ModifyStaticPatientList
