import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { Card } from "react-bootstrap"
import _ from "lodash"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import { dateFormat } from "../../dates"
import { phoneFormat } from "../../utils"
import * as C from "../constants"
import ReminderModal from "./ReminderModal"
import EditDemographics from "./demographics"
import { CoveragePlan } from "./CoveragePlan"
import { DeactivatePatient, PatientDeactivated } from "./deactivate"

export default class PatientInfoContainer extends Component {
    state = { show: false, already_shown: false }

    static getDerivedStateFromProps(props, state) {
        if (
            props.data?.[C.KEYS.NAME] &&
            !props.data.canViewPanel &&
            !state.already_shown
        ) {
            return { show: true, already_shown: true }
        }
        return {}
    }

    hideModal = () => this.setState({ show: false })

    render() {
        return this.props.error ? (
            <Redirect to="/page_not_found" />
        ) : (
            <PatientInfo
                {...this.props}
                info={this.props.data}
                show={this.state.show}
                hideModal={this.hideModal}
            />
        )
    }
}

const Coverages = ({ coverages }) => (
    <td className="patient-info-coverage">
        {_.some(coverages, plan => !_.isEmpty(plan.patContractNumbers))
            ? coverages.map((plan, index) => (
                  <CoveragePlan key={index} plan={plan} />
              ))
            : "None"}
    </td>
)

const getAddress = info =>
    _.compact([
        info[C.KEYS.ADDRESS_1],
        info[C.KEYS.ADDRESS_2],
        info[C.KEYS.CITY],
        info[C.KEYS.STATE],
        info[C.KEYS.ZIP]
    ]).join(", ")

export const PatientInfo = ({
    loading,
    error,
    info,
    show,
    hideModal,
    canEdit
}) => (
    <Card id="patient-info" className="card-table patient-info">
        <Card.Header>
            <CardTitle>Patient Information</CardTitle>
            {canEdit && <EditDemographics info={info} />}
            {info[C.KEYS.DEACTIVATED] ? (
                <PatientDeactivated />
            ) : (
                <DeactivatePatient />
            )}
        </Card.Header>
        <LEHOC loading={loading} error={error} list={Object.keys(info)}>
            <Card.Body>
                <div className="table-wrapper dataTables_wrapper">
                    <table className="table table-striped table-hover dataTable">
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>{info[C.KEYS.NAME]}</td>
                            </tr>
                            <tr>
                                <td>DOB:</td>
                                <td>{dateFormat(info[C.KEYS.DOB])}</td>
                            </tr>
                            {info[C.KEYS.DOD] && (
                                <tr className="patient-info-deceased">
                                    <td>Deceased:</td>
                                    <td>{dateFormat(info[C.KEYS.DOD])}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Sex on Birth Certificate:</td>
                                <td>{info[C.KEYS.SEX_STR]}</td>
                            </tr>
                            <tr>
                                <td>Gender Identity:</td>
                                <td>{info[C.KEYS.GENDER]}</td>
                            </tr>
                            <tr>
                                <td>Sexual Orientation:</td>
                                <td>{info[C.KEYS.ORIENTATION]}</td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{getAddress(info)}</td>
                            </tr>
                            <tr>
                                <td>Tel:</td>
                                <td>{phoneFormat(info[C.KEYS.PHONE])}</td>
                            </tr>
                            <tr>
                                <td>Race / Ethnicity:</td>
                                <td>
                                    {(info[C.KEYS.RACE] ||
                                        info[C.KEYS.ETHNICITY]) &&
                                        `${info[C.KEYS.RACE]} / ${
                                            info[C.KEYS.ETHNICITY]
                                        }`}
                                </td>
                            </tr>
                            <tr>
                                <td>PCP:</td>
                                <td>{info[C.KEYS.PCP]}</td>
                            </tr>
                            <tr>
                                <td>Coverage:</td>
                                <Coverages coverages={info[C.KEYS.COVERAGES]} />
                            </tr>
                            <tr>
                                <td>Care Management:</td>
                                <td>{info[C.KEYS.CARE_MANAGEMENT]}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </LEHOC>
        <ReminderModal show={show} hideModal={hideModal} />
    </Card>
)
