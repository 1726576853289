import { connect } from "react-redux"
import { fetcher } from "../../core/fetcher"
import {
    categoryNodesSelector,
    categoryTreeSelector,
    costFiltersSelector,
    fundStampLookupSelector
} from "../../cost/selectors"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import { MODULE_IDS } from "../../constants"
import * as actions from "../actions"
import * as C from "../constants"
import { chartDataSelector, tableDataSelector } from "../selectors"
import { CostUtilization } from "./CostUtilization"
import { moduleEnabledSelector } from "../../moduleUtils"

export default fetcher({
    name: C.NAME,
    endpoint: state =>
        urlWithParams(`/api/utilization`, costFiltersSelector(C.NAME)(state)),
    method: ApiTypes.GET
})(
    connect(
        state => ({
            ...state[C.NAME],
            canPrint: moduleEnabledSelector(MODULE_IDS.EXTRACTS)(state),
            categoryTree: categoryTreeSelector(state),
            categoryNodes: categoryNodesSelector(state),
            filterLookup: fundStampLookupSelector(state),
            chartData: chartDataSelector(state),
            tableData: tableDataSelector(state)
        }),
        actions
    )(CostUtilization)
)
