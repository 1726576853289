import { itemsToLabelValue } from "../utils"

export const NAME = "ALERTS"
export const ADD_ALERT_FORM = "ADD_ALERT_FORM"

export const ADD_ACTIVE_NOTIFICATION = "ADD_ACTIVE_NOTIFICATION"
export const RNS_HIDE_NOTIFICATION = "RNS_HIDE_NOTIFICATION"
export const CLEAR_ALERT_CACHE = "CLEAR_ALERT_CACHE"
export const SET_CACHE = "SET_CACHE"
export const CREATE_ALERT = "CREATE_ALERT"
export const DELETE_ALERT = "DELETE_ALERT"

export const TABLE_KEYS = {
    ALERT_ID: "alertId",
    TYPE: "type",
    COLOR: "color",
    MESSAGE: "message",
    START_DATE: "activeFromDt",
    END_DATE: "activeToDt",
    CLEAR_ALERT: "clearAlert"
}

export const FILTERS = {
    TYPE: TABLE_KEYS.TYPE,
    COLOR: TABLE_KEYS.COLOR,
    MESSAGE: TABLE_KEYS.MESSAGE,
    START_DATE: TABLE_KEYS.START_DATE,
    END_DATE: TABLE_KEYS.END_DATE
}

export const TRANSLATION = {
    [TABLE_KEYS.TYPE]: "Type",
    [TABLE_KEYS.COLOR]: "Color",
    [TABLE_KEYS.MESSAGE]: "Message",
    [TABLE_KEYS.START_DATE]: "Active From",
    [TABLE_KEYS.END_DATE]: "Active To",
    [TABLE_KEYS.CLEAR_ALERT]: "Clear"
}

export const ALERT_OPTIONS = itemsToLabelValue(["MAINTENANCE", "FEEDBACK"])

export const ALERT_COLORS = [
    "#cc0033",
    "#ff995c",
    "#ffc107",
    "#00a651",
    "#4b8fdd"
]

export const initialState = []
