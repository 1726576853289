import { FormattedDate, LongCell, TimestampDate } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"
import { TagActionsCell } from "./components/TagActionsCell"

const column = columnBuilder(C.TABLE_TRANSLATION)

export const COLUMNS = [
    column(C.TABLE_KEYS.TAG, { flexGrow: 1 }),
    column(C.TABLE_KEYS.START_DATE, { cellRenderer: FormattedDate }),
    column(C.TABLE_KEYS.END_DATE, { cellRenderer: FormattedDate }),
    column(C.TABLE_KEYS.SOURCE_DESCRIPTION, {
        width: 150,
        flexGrow: 4,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.USER, {
        flexGrow: 2
    }),
    column(C.TABLE_KEYS.CREATED_DATE, {
        width: 150,
        cellRenderer: TimestampDate
    }),
    column(C.TABLE_KEYS.LAST_CHANGED_BY_NAME, { width: 130, flexGrow: 2 }),
    column(C.TABLE_KEYS.LAST_CHANGED_DATE, {
        width: 150,
        cellRenderer: TimestampDate
    }),
    column(C.TABLE_KEYS.COMMENT, {
        width: 120,
        flexGrow: 2,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.ACTIONS, {
        width: 80,
        disableSort: true,
        align: ALIGNMENTS.CENTER,
        cellRenderer: TagActionsCell
    })
]
