import * as C from "./constants"

export const loginAction = payload => ({ type: C.LOGIN_REQUEST, ...payload })
export const loginSuccessAction = payload => ({
    type: C.LOGIN_SUCCESS,
    payload
})
export const loginErrorAction = payload => ({ type: C.LOGIN_ERROR, payload })
export const logoutAction = () => ({ type: C.LOGOUT })
export const forceLogoutAction = ({ title, message }) => ({
    type: C.FORCE_LOGOUT,
    title,
    message
})

export const displayPasswordReset = payload => ({
    type: C.DISPLAY_PASSWORD_RESET,
    payload
})
export const submitPasswordReset = payload => ({
    type: C.RESET_PASSWORD_SUBMIT,
    payload
})
export const updatePasswordToken = payload => ({
    type: C.UPDATE_PASSWORD_TOKEN,
    payload
})
export const refreshPasswordToken = () => ({
    type: C.REFRESH_PASSWORD_TOKEN
})
