import _ from "lodash"
import TRANSLATION from "../translation"
import { keepEach } from "../utils"
import { FIELD_STATES } from "../constants"

export const getTranslation = (input, meta) =>
    // return translation if it exists and this input is in it; otherwise let it be empty
    meta?.form && _.get(TRANSLATION[meta.form], input?.name) // need to use _.get because input.name could be in "a.b" form
        ? TRANSLATION[meta.form]
        : {}
// XXX unfortunately if you pass a translation into the form directly, it won't get found by this. TODO fix that

const getId = (input, meta) => {
    // specifically, a likely-unique value for an html element's "id" field

    if (!input?.name) {
        // if there's no form or name, we just won't link the label
        return undefined
    }

    if (meta?.form) {
        return `${meta.form}-${input.name}`.toLowerCase()
    }

    return input.name.toLowerCase() // maybe not 100% safe but close enough
}

export const getValidProps = (input, meta) => ({
    name: input?.name || "",
    value: input?.value ?? "",
    onChange: input?.onChange || (() => {}),
    onBlur: input?.onBlur || (() => {}),
    style: input?.style || {},
    error: meta?.error || undefined,
    warning: meta?.warning || undefined,
    touched: meta?.touched || undefined,
    form: meta?.form || undefined,
    dispatch: meta?.dispatch || undefined,
    id: getId(input, meta),
    translation: getTranslation(input, meta)
})

export const getStateClass = (disabled, error, warning, valid) =>
    _.first(
        keepEach(
            [
                FIELD_STATES.DISABLED,
                FIELD_STATES.ERROR,
                FIELD_STATES.WARNING,
                FIELD_STATES.VALID
            ],
            [disabled, error, warning, valid]
        )
    )
