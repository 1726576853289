import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import _ from "lodash"
import { filterColumnsByKeys, getUnselectedColumns } from "../../table/helpers"
import { TableSettingsList } from "./TableSettingsList"
import { TableSettingsInstructions } from "./TableSettings"

export const TableSettingsField = ({ input, meta, columnDefinitions }) => {
    useEffect(() => {
        if (_.isEmpty(columnDefinitions)) {
            // probably columnDefinitions isn't determined yet because the form values are still loading. Don't do anything yet.
            return
        }

        const validColumnKeys = _.map(columnDefinitions, ({ key }) => key)

        // we do these following steps to ensure that, if we switch list types, we'll retain as many of the selected columns as possible.
        // this is similar to placeColumnsByKey() in helpers, but is distinct in how the column placement and ordering are calculated.

        const frozenColumns = filterColumnsByKeys(
            input.value?.frozenColumns,
            validColumnKeys
        )
        const selectedColumns = filterColumnsByKeys(
            input.value?.columns,
            validColumnKeys
        )
        const unselectedColumns = getUnselectedColumns(columnDefinitions, [
            ...(input.value?.frozenColumns || []),
            ...(input.value?.columns || [])
        ])

        input.onChange({
            frozenColumns,
            columns: [...selectedColumns, ...unselectedColumns]
        })
    }, [columnDefinitions])

    return (
        <div>
            <Button
                variant="secondary"
                onClick={() => input.onChange(meta.initial)}
                style={{ float: "right" }}
            >
                Reset to defaults
            </Button>
            <h3>Column Visibility and Order</h3>
            <p>
                Set the columns that will be displayed when viewing this
                campaign.
            </p>
            <TableSettingsInstructions />
            <TableSettingsList
                options={input.value?.columns}
                freezeOptions={input.value?.frozenColumns}
                setOptions={(options, freezeOptions) =>
                    input.onChange({
                        columns: options,
                        frozenColumns: freezeOptions
                    })
                }
            />
        </div>
    )
}
