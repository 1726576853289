import React from "react"
import { Col, Row } from "react-bootstrap"
import { Field } from "redux-form"
import {
    DollarInput,
    LabelledDatePicker,
    SelectField,
    TextInput
} from "../../core/CustomFields"
import {
    afterDateField,
    moreThanField,
    todayOrBefore
} from "../../core/validators"
import { constants as PC, PickerField } from "../../pickers"
import { BOOLEAN_OPTIONS } from "../../constants"
import * as C from "../constants"
import { eligibleDateValidator, validCostSpan } from "../validators"

export const AwvFilters = ({ filterOptions, formValues }) => {
    const costSpanRequired =
        formValues?.[C.FILTERS.COST_MIN] || formValues?.[C.FILTERS.COST_MAX]

    return (
        <>
            <Row>
                <Col
                    sm={6}
                    lg={{ span: 4, order: "first" }}
                    xl={{ span: 3, order: "first" }}
                >
                    <Field
                        name={C.FILTERS.PATIENT_NAME}
                        component={TextInput}
                    />
                </Col>
                <Col
                    sm={6}
                    lg={{ span: 4, order: "first" }}
                    xl={{ span: 2, order: "first" }}
                >
                    <Field
                        name={C.FILTERS.DECEASED}
                        component={SelectField}
                        options={BOOLEAN_OPTIONS}
                    />
                </Col>
                <Col
                    sm={12}
                    lg={{ span: 6, order: 1 }}
                    xl={{ span: 4, order: 0 }}
                >
                    <Field
                        name={C.FILTERS.TAGS}
                        component={SelectField}
                        options={filterOptions.tags}
                        multi
                    />
                </Col>
                <Col
                    xs={{ span: 12, order: 0 }}
                    sm={{ span: 6, order: 1 }}
                    lg={{ span: 4, order: "first" }}
                    xl={{ span: 3, order: "first" }}
                >
                    <Field
                        name={C.FILTERS.PRODUCTS}
                        component={SelectField}
                        options={filterOptions.productClasses}
                        multi
                    />
                </Col>
                <Col
                    xs={{ span: 12, order: 0 }}
                    sm={{ span: 6, order: 0 }}
                    lg={{ span: 6, order: 0 }}
                    xl={{ span: 4, order: 0 }}
                >
                    <Field
                        name={C.FILTERS.PROVIDERS}
                        component={SelectField}
                        options={filterOptions.pcps}
                        multi
                    />
                </Col>
                <Col
                    sm={{ span: 6, order: 1 }}
                    lg={{ span: 6, order: 1 }}
                    xl={{ span: 4, order: 0 }}
                >
                    <Field
                        name={C.FILTERS.PRACTICE_UNITS}
                        component={SelectField}
                        options={filterOptions.units}
                        multi
                    />
                </Col>
                <Col sm={{ span: 6, order: 10 }} xl={4}>
                    <Field
                        name={C.FILTERS.REGIONS}
                        component={SelectField}
                        options={filterOptions.regions}
                        multi
                    />
                </Col>
                <Col sm={{ span: 6, order: 10 }} xl={2}>
                    <Field
                        name={C.FILTERS.NON_PHYSICIAN_PCP}
                        component={SelectField}
                        options={BOOLEAN_OPTIONS}
                    />
                </Col>
                <Col sm={{ span: 6, order: 10 }} xl={2}>
                    <Field
                        name={C.FILTERS.PCP_RENDERED}
                        component={SelectField}
                        options={BOOLEAN_OPTIONS}
                    />
                </Col>
                <Col sm={{ span: 6, order: 10 }} xl={4}>
                    <Field
                        name={C.FILTERS.PAYERS}
                        component={SelectField}
                        options={filterOptions.payers}
                        multi
                    />
                </Col>
                <Col sm={{ span: 6, order: 10 }} xl={4}>
                    <Field
                        name={C.FILTERS.SOURCES}
                        component={SelectField}
                        options={filterOptions.interfaces}
                        multi
                    />
                </Col>
            </Row>
            <hr className="divider" />
            <Row>
                <Col sm={6} xl={3}>
                    <Field
                        name={C.FILTERS.FLAGS}
                        component={SelectField}
                        options={C.FLAG_OPTIONS}
                        multi
                    />
                </Col>
                <Col sm={6} xl={3}>
                    <Field
                        name={C.FILTERS.RISK_RANK}
                        component={SelectField}
                        options={filterOptions.riskRank}
                        multi
                    />
                </Col>
                <Col sm={6} xl={4}>
                    <Field
                        name={C.FILTERS.HCC_CONDITIONS}
                        component={SelectField}
                        options={filterOptions.hccConditions}
                        multi
                    />
                </Col>
                <Col sm={6} xl={2}>
                    <Field
                        name={C.FILTERS.HCC_CONDITIONS_MET}
                        component={SelectField}
                        options={BOOLEAN_OPTIONS}
                    />
                </Col>
            </Row>
            <hr className="divider" />
            <Row>
                <Col xl={8}>
                    <Row>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.SERVICE_DATE_FROM}
                                component={LabelledDatePicker}
                                validDates={todayOrBefore}
                                validate={todayOrBefore}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.SERVICE_DATE_TO}
                                component={LabelledDatePicker}
                                validDates={[
                                    todayOrBefore,
                                    afterDateField(C.FILTERS.SERVICE_DATE_FROM)
                                ]}
                                validate={[
                                    todayOrBefore,
                                    afterDateField(C.FILTERS.SERVICE_DATE_FROM)
                                ]}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.AWV_ELIGIBLE_DATE_FROM}
                                component={LabelledDatePicker}
                                validDates={eligibleDateValidator}
                                validate={[eligibleDateValidator]}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.AWV_ELIGIBLE_DATE_TO}
                                component={LabelledDatePicker}
                                validDates={[
                                    eligibleDateValidator,
                                    afterDateField(
                                        C.FILTERS.AWV_ELIGIBLE_DATE_FROM
                                    )
                                ]}
                                validate={[
                                    eligibleDateValidator,
                                    afterDateField(
                                        C.FILTERS.AWV_ELIGIBLE_DATE_FROM
                                    )
                                ]}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.AWV_SCHEDULED_DATE_FROM}
                                component={LabelledDatePicker}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.AWV_SCHEDULED_DATE_TO}
                                component={LabelledDatePicker}
                                validDates={afterDateField(
                                    C.FILTERS.AWV_SCHEDULED_DATE_FROM
                                )}
                                validate={[
                                    afterDateField(
                                        C.FILTERS.AWV_SCHEDULED_DATE_FROM
                                    )
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xl={4} className="leftBorderBlue">
                    <hr className="divider lg-down" />
                    <Row>
                        <Col sm={6} lg={3} xl={12}>
                            <Field
                                name={C.FILTERS.COST_MIN}
                                component={DollarInput}
                                min={0}
                            />
                        </Col>
                        <Col sm={6} lg={3} xl={12}>
                            <Field
                                name={C.FILTERS.COST_MAX}
                                component={DollarInput}
                                validate={moreThanField(C.FILTERS.COST_MIN)}
                                min={0}
                            />
                        </Col>
                        <Col sm={12} lg={6} xl={12}>
                            <Field
                                name={C.FILTERS.COST_SPAN}
                                className="field-cost_span"
                                disabled={!costSpanRequired}
                                disabledMessage="Select a min or max cost first."
                                required={costSpanRequired}
                                validate={validCostSpan}
                                component={PickerField}
                                options={PC.PERIOD_OPTIONS}
                                // TODO convert to costIsYtd in saga
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}
