import React from "react"
import fetcher from "../../core/fetcher"
import LEHOC from "../../core/LoadingErrorHOC"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import { ScrollablePatientRiskRank } from "./ScrollablePatientRiskRank"

export const PatientRiskRankInfo = ({ loading, error, data }) => (
    <div id="plDuePopup" className="plDuePopup scrollable-table-container">
        <LEHOC loading={loading} error={error} list={data}>
            <ScrollablePatientRiskRank {...data} />
        </LEHOC>
    </div>
)

export default fetcher({
    name: C.NAME,
    endpoint: (state, { patientId }) => `/api/patients/${patientId}/riskRanks`,
    method: ApiTypes.GET,
    persistResource: true
})(PatientRiskRankInfo)
