import React, { useCallback, useMemo } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import moment from "moment"
import { Field, FormSection } from "redux-form"
import { Col, Row } from "react-bootstrap"

import {
    CheckboxField,
    CreatableSelectField,
    DollarInput,
    LabelledDatePicker,
    LabelledDateTimePicker,
    SelectField,
    TextInput
} from "../../core/CustomFields"
import OrgName from "../../core/OrgName"
import {
    afterDate,
    afterDateField,
    beforeDate,
    conditionalRequired,
    moreThanField,
    todayOrBefore,
    validFuzzySearch
} from "../../core/validators"
import { moduleEnabledSelector } from "../../moduleUtils"
import { constants as PC, PickerField } from "../../pickers"
import { BOOLEAN_OPTIONS, MODULE_IDS, SCOPE_OPTIONS } from "../../constants"
import { TIMESTAMP_STRING_FIELD } from "../../dates"
import { searchFlagsSelector } from "../selectors"
import * as C from "../constants"

const careManagersPlaceholders = {
    [C.YES_NO_VALUES.YES]: "All",
    [C.YES_NO_VALUES.NO]: "None"
}
const TIMESTAMP_OPTIONS = {
    dateFormat: TIMESTAMP_STRING_FIELD
}

const miscColSizing = { sm: 6, md: 4, lg: 6, xl: 4 }
const datesColSizing = { sm: 6, md: 6, lg: 6 }

const dateValidators = _.mapValues(
    {
        [C.FILTERS.DOB_TO]: C.FILTERS.DOB_FROM,
        [C.FILTERS.LAST_SERVICE_TO]: C.FILTERS.LAST_SERVICE_FROM,
        [C.FILTERS.PATIENT_LAST_SERVICE_TO]: C.FILTERS.PATIENT_LAST_SERVICE_FROM
    },
    field => afterDateField(C.FILTERS_SECTION + "." + field)
)
const timestampValidators = _.mapValues(
    {
        [C.FILTERS.LAST_APPT_DATE_FROM]: [beforeDate],
        [C.FILTERS.LAST_APPT_DATE_TO]: [
            beforeDate,
            C.FILTERS.LAST_APPT_DATE_FROM
        ],
        [C.FILTERS.NEXT_APPT_DATE_FROM]: [afterDate],
        [C.FILTERS.NEXT_APPT_DATE_TO]: [
            afterDate,
            C.FILTERS.NEXT_APPT_DATE_FROM
        ]
    },
    ([todayValidator, field]) =>
        _.compact([
            todayValidator(moment(), TIMESTAMP_OPTIONS),
            field &&
                afterDateField(
                    C.FILTERS_SECTION + "." + field,
                    TIMESTAMP_OPTIONS
                )
        ])
)

const FilterPanel = ({
    fixedScope,
    incentiveModuleActive,
    fuzzySearchFlags,
    options: {
        payers,
        sources,
        productClasses,
        productGroups,
        units,
        regions,
        pcps,
        tags,
        careManagers,
        genders,
        races,
        ethnicities,
        riskRank,
        riskType,
        measures,
        hccConditions,
        genConditions,
        compliant,
        appointmentTypes,
        appointmentStatuses
    },
    limitMeasures,
    formValues = {}
}) => {
    const [careManaged, costMin, costMax] = _.at(formValues, [
        C.FILTERS.CARE_MANAGEMENT,
        C.FILTERS.COST_MIN,
        C.FILTERS.COST_MAX
    ])
    const nonzeroTags = (tags || []).filter(tag => !!tag.value) // tag 0 is All Patients, which is the same as leaving the filter blank

    const costSpanRequired = costMin || costMax
    const costSpanValidator = useCallback(
        conditionalRequired(costSpanRequired),
        [costSpanRequired]
    )

    const endOfDay = useMemo(() => moment().endOf("day"), []) // so that timestamp TO fields can be EOD by default

    return (
        <FormSection name={C.FILTERS_SECTION} className="filter-panel">
            <Row>
                <Col xl={5}>
                    <Row>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.FUZZY_SEARCH}
                                component={TextInput}
                                validate={validFuzzySearch}
                                className="filter-fuzzy_search"
                                labelClass="filter-fuzzy_search-label"
                            >
                                {fuzzySearchFlags[
                                    C.FILTERS.SEARCH_PATIENT_ID
                                ] && (
                                    <Field
                                        name={C.FILTERS.SEARCH_PATIENT_ID}
                                        component={CheckboxField}
                                    />
                                )}
                                {fuzzySearchFlags[
                                    C.FILTERS.SEARCH_CONTRACT_NUMBER
                                ] && (
                                    <Field
                                        name={C.FILTERS.SEARCH_CONTRACT_NUMBER}
                                        component={CheckboxField}
                                    />
                                )}
                            </Field>
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.GENDER}
                                component={CreatableSelectField}
                                options={genders}
                                multi
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.RACE}
                                component={SelectField}
                                options={races}
                                multi
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.ETHNICITY}
                                component={SelectField}
                                options={ethnicities}
                            />
                        </Col>
                        <Col sm={6} md={4} lg={true}>
                            <Field
                                name={C.FILTERS.DOB_FROM}
                                component={LabelledDatePicker}
                                validDates={todayOrBefore}
                                validate={[todayOrBefore]}
                            />
                        </Col>
                        <Col sm={6} md={4} lg={true}>
                            <Field
                                name={C.FILTERS.DOB_TO}
                                component={LabelledDatePicker}
                                validDates={[
                                    todayOrBefore,
                                    dateValidators[C.FILTERS.DOB_TO]
                                ]}
                                validate={[
                                    todayOrBefore,
                                    dateValidators[C.FILTERS.DOB_TO]
                                ]}
                            />
                        </Col>
                        <Col sm={6} md={4} xl="auto">
                            <Field
                                name={C.FILTERS.DECEASED}
                                component={SelectField}
                                options={C.YES_NO_OPTIONS}
                            />
                        </Col>
                        {/*<Col md={4} xl={2}>
                    <Field
                        name={C.FILTERS.PERIOD}
                        component={Dropdown}
                        options={C.YEAR_OPTIONS}
                        placeholder="Current Year"
                    />
                </Col>*/}
                    </Row>
                </Col>

                <hr className="vertical-hr lg-up" />

                <Col xl={7}>
                    <hr className="divider lg-down" />
                    <Row>
                        {/* Pairs to keep together:
                         * Practice Unit and Practice Tag
                         * Practice Tag and Patient Tag (not 100% vital)
                         * Payer and Other Coverage
                         * Product Class and Product Group
                         */}
                        <Col sm={6} md={4}>
                            <Field
                                name={C.FILTERS.POPULATION}
                                notClearable
                                component={SelectField}
                                options={C.POPULATION_OPTIONS}
                            />
                        </Col>
                        <Col sm={6} md={4}>
                            <Field
                                name={C.FILTERS.PRODUCTS}
                                multi
                                component={SelectField}
                                options={productClasses}
                            />
                        </Col>
                        <Col sm={6} md={4}>
                            <Field
                                name={C.FILTERS.PRODUCT_GROUPS}
                                multi
                                component={SelectField}
                                options={productGroups}
                            />
                        </Col>
                        <Col sm={6} md={4}>
                            <Field
                                name={C.FILTERS.PRACTICE_UNITS}
                                multi
                                component={SelectField}
                                options={units}
                            />
                        </Col>
                        <Col sm={6} md={{ span: 4, order: 10 }}>
                            <Field
                                name={C.FILTERS.PCPS}
                                multi
                                component={SelectField}
                                options={pcps}
                            />
                        </Col>
                        <Col sm={6} md={4}>
                            <Field
                                name={C.FILTERS.REGIONS}
                                multi
                                component={SelectField}
                                options={regions}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <Field
                                name={C.FILTERS.TAGS}
                                multi
                                component={SelectField}
                                options={nonzeroTags}
                            />
                        </Col>

                        <Col sm={6} md={{ span: 4, order: 10 }}>
                            <Field
                                name={C.FILTERS.PAYERS}
                                multi
                                component={SelectField}
                                options={payers}
                            />
                        </Col>
                        <Col sm={6} md={{ span: 4, order: 10 }}>
                            <Field
                                name={C.FILTERS.SOURCES}
                                multi
                                component={SelectField}
                                options={sources}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr className="divider" />
            <Row>
                <Col sm={6} md="auto">
                    <Field
                        name={C.FILTERS.CM_INCENTIVE}
                        component={SelectField}
                        options={C.YES_NO_OPTIONS}
                    />
                </Col>
                <Col sm={6} md="auto">
                    <Field
                        name={C.FILTERS.CARE_MANAGEMENT}
                        component={SelectField}
                        options={C.YES_NO_OPTIONS}
                    />
                </Col>
                <Col sm={12} md={true}>
                    <Field
                        name={C.FILTERS.CARE_MANAGERS}
                        multi
                        component={SelectField}
                        options={careManagers}
                        disabled={!!careManaged}
                        placeholder={careManagersPlaceholders[careManaged]}
                        clearWhenDisabled
                    />
                </Col>
            </Row>
            <hr className="divider" />
            <Row>
                <Col lg={6} xl={7}>
                    <Row>
                        <Col
                            {...miscColSizing}
                            md={{ span: 6, order: 0 }}
                            xl={{ span: 6, order: 0 }}
                        >
                            <Field
                                name={C.FILTERS.MEASURES}
                                multi={!limitMeasures}
                                notClearable={limitMeasures}
                                component={SelectField}
                                options={measures}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 4, order: 1 }}
                            xl={{ span: 4, order: 1 }}
                        >
                            <Field
                                name={C.FILTERS.COMPLIANT}
                                component={SelectField}
                                options={compliant}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 4, order: 10 }}
                            xl={{ span: 4, order: 10 }}
                        >
                            <Field
                                name={C.FILTERS.ACUTE_ENCOUNTERS}
                                multi
                                component={SelectField}
                                options={C.ACUTE_ENCOUNTERS_OPTIONS}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 4, order: 1 }}
                            xl={{ span: 4, order: 1 }}
                        >
                            {incentiveModuleActive && (
                                <Field
                                    name={C.FILTERS.INCENTIVE_STATUS}
                                    multi
                                    component={SelectField}
                                    options={C.INCENTIVE_STATUS_OPTIONS}
                                />
                            )}
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 4, order: 1 }}
                            xl={{ span: 4, order: 1 }}
                        >
                            <Field
                                name={C.FILTERS.RISK_RANK}
                                multi
                                component={SelectField}
                                options={riskRank}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 6, order: 0 }}
                            xl={{ span: 6, order: 0 }}
                        >
                            <Field
                                name={C.FILTERS.RISK_TYPE}
                                multi
                                component={SelectField}
                                options={riskType}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 4, order: 10 }}
                            xl={{ span: 4, order: 10 }}
                        >
                            <Field
                                name={C.FILTERS.GRACE_PERIOD}
                                component={SelectField}
                                options={BOOLEAN_OPTIONS}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 4, order: 10 }}
                            xl={{ span: 4, order: 10 }}
                        >
                            <Field
                                name={C.FILTERS.HCC_CONDITIONS_MET}
                                component={SelectField}
                                options={compliant}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 6, order: 10 }}
                            xl={{ span: 6, order: 10 }}
                        >
                            <Field
                                name={C.FILTERS.GEN_CONDITIONS}
                                multi
                                component={SelectField}
                                options={genConditions}
                            />
                        </Col>
                        <Col
                            {...miscColSizing}
                            md={{ span: 6, order: 10 }}
                            xl={{ span: 6, order: 10 }}
                        >
                            <Field
                                name={C.FILTERS.HCC_CONDITIONS}
                                multi
                                component={SelectField}
                                options={hccConditions}
                            />
                        </Col>
                    </Row>
                    <hr className="divider" />
                    <Row>
                        <Col>
                            <Field
                                name={C.FILTERS.COST_MIN}
                                component={DollarInput}
                            />
                        </Col>
                        <Col>
                            <Field
                                name={C.FILTERS.COST_MAX}
                                component={DollarInput}
                                validate={moreThanField(C.FILTERS.COST_MIN)}
                            />
                        </Col>
                        <Col lg="auto">
                            <Field
                                name={C.FILTERS.COST_SPAN}
                                className="field-cost_span"
                                disabled={!costSpanRequired}
                                disabledMessage="Select a min or max cost first."
                                required={costSpanRequired}
                                validate={costSpanValidator}
                                component={PickerField}
                                options={PC.PERIOD_OPTIONS}
                            />
                        </Col>
                    </Row>
                </Col>

                <hr className="vertical-hr md-up" />

                <Col lg={6} xl={5}>
                    <hr className="divider md-down" />
                    <Row>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.LAST_SERVICE_FROM}
                                component={LabelledDatePicker}
                                validDates={todayOrBefore}
                                validate={todayOrBefore}
                            />
                        </Col>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.LAST_SERVICE_TO}
                                component={LabelledDatePicker}
                                validDates={[
                                    todayOrBefore,
                                    dateValidators[C.FILTERS.LAST_SERVICE_TO]
                                ]}
                                validate={[
                                    todayOrBefore,
                                    dateValidators[C.FILTERS.LAST_SERVICE_TO]
                                ]}
                            />
                        </Col>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.PATIENT_LAST_SERVICE_FROM}
                                component={LabelledDatePicker}
                                validDates={todayOrBefore}
                                validate={todayOrBefore}
                            />
                        </Col>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.PATIENT_LAST_SERVICE_TO}
                                component={LabelledDatePicker}
                                validDates={[
                                    todayOrBefore,
                                    dateValidators[
                                        C.FILTERS.PATIENT_LAST_SERVICE_TO
                                    ]
                                ]}
                                validate={[
                                    todayOrBefore,
                                    dateValidators[
                                        C.FILTERS.PATIENT_LAST_SERVICE_TO
                                    ]
                                ]}
                            />
                        </Col>
                        <Col xs={12}>
                            <Field
                                name={C.FILTERS.INCLUDE_UNSERVICED}
                                component={CheckboxField}
                                className="field-include_unserviced"
                                labelRight
                            />
                        </Col>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.LAST_APPT_DATE_FROM}
                                component={LabelledDateTimePicker}
                                validDates={
                                    timestampValidators[
                                        C.FILTERS.LAST_APPT_DATE_FROM
                                    ]
                                }
                                validate={
                                    timestampValidators[
                                        C.FILTERS.LAST_APPT_DATE_FROM
                                    ]
                                }
                            />
                        </Col>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.LAST_APPT_DATE_TO}
                                component={LabelledDateTimePicker}
                                initialViewDate={endOfDay}
                                validDates={
                                    timestampValidators[
                                        C.FILTERS.LAST_APPT_DATE_TO
                                    ]
                                }
                                validate={
                                    timestampValidators[
                                        C.FILTERS.LAST_APPT_DATE_TO
                                    ]
                                }
                            />
                        </Col>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.NEXT_APPT_DATE_FROM}
                                component={LabelledDateTimePicker}
                                validDates={
                                    timestampValidators[
                                        C.FILTERS.NEXT_APPT_DATE_FROM
                                    ]
                                }
                                validate={
                                    timestampValidators[
                                        C.FILTERS.NEXT_APPT_DATE_FROM
                                    ]
                                }
                            />
                        </Col>
                        <Col {...datesColSizing}>
                            <Field
                                name={C.FILTERS.NEXT_APPT_DATE_TO}
                                component={LabelledDateTimePicker}
                                initialViewDate={endOfDay}
                                validDates={
                                    timestampValidators[
                                        C.FILTERS.NEXT_APPT_DATE_TO
                                    ]
                                }
                                validate={
                                    timestampValidators[
                                        C.FILTERS.NEXT_APPT_DATE_TO
                                    ]
                                }
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.NEXT_APPT_DATE_DYNAMIC}
                                component={SelectField}
                                options={C.DYNAMIC_DATE_OPTIONS}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.APPOINTMENT_PROVIDERS}
                                component={SelectField}
                                options={pcps}
                                multi
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.APPOINTMENT_TYPES}
                                component={SelectField}
                                options={appointmentTypes}
                                multi
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.APPOINTMENT_STATUSES}
                                component={SelectField}
                                options={appointmentStatuses}
                                multi
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div>
                <hr className="divider" />
                <Row>
                    <Col md={3}>
                        <Field
                            name={C.FILTERS.SCOPE}
                            component={SelectField}
                            notClearable
                            options={SCOPE_OPTIONS}
                            disabled={!!fixedScope}
                        />
                    </Col>
                    <Col
                        md={8}
                        className="filter-scope-explainer"
                        style={{
                            paddingTop: fixedScope ? 0 : "2rem"
                        }}
                    >
                        <p>
                            A scope of <strong>My Panel(s)</strong> means that
                            you are filtering on the set of patients you have a
                            relationship with. A scope of{" "}
                            <strong>All Patients</strong> means that you are
                            filtering against the entire <OrgName /> network of
                            patients.
                        </p>
                        {!!fixedScope && <p>{fixedScope}</p>}
                    </Col>
                </Row>
            </div>
        </FormSection>
    )
}

const incentiveModuleSelector = moduleEnabledSelector(
    MODULE_IDS.INCENTIVE_TRACKING
)
export default connect(state => ({
    incentiveModuleActive: incentiveModuleSelector(state),
    fuzzySearchFlags: searchFlagsSelector(state)
}))(FilterPanel)
