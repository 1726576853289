import React from "react"
import { delay } from "redux-saga"
import classNames from "classnames"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"
import { modifyResource } from "../core/fetcher"
import { PAGE_MOUNTED } from "../structure/constants"
import auth from "../authentication"
import { ApiTypes, tryFetch } from "../api"
import { notifySimpleError, tryWithNotifications } from "../notifications"
import { isLightContrast } from "../utils"
import { dateFormat, TIMESTAMP_STRING_FIELD, TIMESTAMP_STRING } from "../dates"
import * as C from "./constants"
import { addActiveNotification, setCache } from "./actions"

function* handleAlerts(body) {
    const cached_alerts = yield select(state => state[C.NAME])
    const loginId = yield select(state => state[auth.constants.NAME].loginId)
    const alerts = body.alerts || []

    for (let alert of alerts) {
        const shouldIgnore = cached_alerts.some(
            cur =>
                cur.alertId === alert.alertId &&
                cur.active === false &&
                cur.loginId === loginId
        )
        const isLoggedIn = yield select(
            state => state.authentication.isLoggedIn
        )

        if (!shouldIgnore && isLoggedIn) {
            const backgroundColor = alert.color || "#CC0033"
            const isLight = isLightContrast(backgroundColor)
            const notif = Notifications.info({
                children: (
                    <div
                        className={classNames(
                            "notification-inner",
                            isLight && `notification-light`
                        )}
                        style={{ backgroundColor }}
                    >
                        <span className="fa indicator-info" />
                        <span>{alert.message}</span>
                        <span className="fa alert-close" />
                    </div>
                ),
                position: "tl",
                autoDismiss: 0
            })
            yield put(
                addActiveNotification({
                    uid: notif.uid,
                    alertId: alert.alertId,
                    loginId
                })
            )
            yield put(notif)
        }
    }
    return (body.alerts || []).length !== 0
}

export function* getAlerts() {
    let rehydrated = false
    while (!rehydrated) {
        yield call(delay, 300)
        rehydrated = yield select(state => state.rehydrated)
    }
    const isLoggedIn = yield select(state => state.authentication.isLoggedIn)
    if (!isLoggedIn) {
        return
    }

    return yield* tryFetch(
        {
            url: "/alert"
        },
        handleAlerts,
        notifySimpleError("Unable to fetch alerts", { autoDismiss: 5 })
    )
}

export function* clearAlertCache() {
    const current_cached_alerts = yield select(state => state[C.NAME])
    const loginId = yield select(state => state.authentication.loginId)
    const new_cached_alerts = current_cached_alerts.filter(
        item => item.loginId !== loginId
    )
    yield put(setCache(new_cached_alerts))
    const alertsExist = yield getAlerts()
    if (!alertsExist) {
        yield put(
            Notifications.success({
                message: "There are no active alerts",
                position: "tc",
                autoDismiss: 5
            })
        )
    }
}

export function* createAlert({ payload, closeModal }) {
    const body = {
        ...payload,
        [C.FILTERS.START_DATE]: dateFormat(
            payload[C.FILTERS.START_DATE],
            TIMESTAMP_STRING_FIELD,
            TIMESTAMP_STRING,
            false
        ),
        [C.FILTERS.END_DATE]: dateFormat(
            payload[C.FILTERS.END_DATE],
            TIMESTAMP_STRING_FIELD,
            TIMESTAMP_STRING,
            false
        )
    }

    yield* tryWithNotifications(
        {
            url: `/api/alert`,
            method: ApiTypes.PUT,
            body
        },
        function*(alertId) {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: data => ({
                        alerts: [
                            ...data.alerts,
                            { ...body, [C.TABLE_KEYS.ALERT_ID]: alertId }
                        ]
                    })
                })
            )
            closeModal()
        }
    )
}

export function* deleteAlert({ alertId }) {
    yield* tryWithNotifications(
        {
            url: `/api/alert/${alertId}`,
            method: ApiTypes.DELETE
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: data => ({
                        alerts: data.alerts.filter(
                            alert => alert[C.TABLE_KEYS.ALERT_ID] !== alertId
                        )
                    })
                })
            )
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(PAGE_MOUNTED, getAlerts)
        }),
        call(function*() {
            yield takeEvery(C.CLEAR_ALERT_CACHE, clearAlertCache)
        }),
        call(function*() {
            yield takeEvery(C.CREATE_ALERT, createAlert)
        }),
        call(function*() {
            yield takeEvery(C.DELETE_ALERT, deleteAlert)
        })
    ])
}
