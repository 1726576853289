import _ from "lodash"
import {
    DATE_STRING,
    dateFormat,
    SERVER_DATE_STRING,
    TIMESTAMP_STRING_FIELD,
    TIMESTAMP_STRING_NO_MS
} from "./dates"
import {
    intersectionExists,
    itemsToObject,
    mapValuesToObject,
    objToLabelValue,
    omitEmpty
} from "./utils"
import * as C from "./patient_list/constants"

const DATE_FILTERS = [
    C.FILTERS.DOB_FROM,
    C.FILTERS.DOB_TO,
    C.FILTERS.DUE_DATE_FROM,
    C.FILTERS.DUE_DATE_TO,
    C.FILTERS.LAST_SERVICE_FROM,
    C.FILTERS.LAST_SERVICE_TO,
    C.FILTERS.PATIENT_LAST_SERVICE_FROM,
    C.FILTERS.PATIENT_LAST_SERVICE_TO
]

const TIMESTAMP_FILTERS = [
    C.FILTERS.LAST_APPT_DATE_FROM,
    C.FILTERS.LAST_APPT_DATE_TO,
    C.FILTERS.NEXT_APPT_DATE_FROM,
    C.FILTERS.NEXT_APPT_DATE_TO
]

const filterTransformer = (
    dateFromFormat,
    dateToFormat,
    timestampFromFormat,
    timestampToFormat
) => filters =>
    _.pickBy(
        {
            ...filters,
            [C.FILTERS.CARE_MANAGERS]: filters[C.FILTERS.CARE_MANAGEMENT]
                ? null
                : filters[C.FILTERS.CARE_MANAGERS],
            // don't include careManagers unless careManagement is null

            ...mapValuesToObject(DATE_FILTERS, key =>
                dateFormat(filters[key], dateFromFormat, dateToFormat, true)
            ),
            ...mapValuesToObject(TIMESTAMP_FILTERS, key =>
                dateFormat(
                    filters[key],
                    timestampFromFormat,
                    timestampToFormat,
                    true
                )
            )
        },
        v => !_.isNil(v) && "" !== v
    )

const transformFilter = filterTransformer(
    DATE_STRING,
    SERVER_DATE_STRING,
    TIMESTAMP_STRING_FIELD,
    TIMESTAMP_STRING_NO_MS
)
export default transformFilter

export const transformAndFilterFilters = filters => {
    const transformedFilters = transformFilter(filters)
    return omitEmpty(transformedFilters)
}

export const undoTransformFilter = filterTransformer(
    SERVER_DATE_STRING,
    DATE_STRING,
    TIMESTAMP_STRING_NO_MS,
    TIMESTAMP_STRING_FIELD
)

export const sortByLabel = items => _.sortBy(items, p => p.label)
const forceNumericValue = items =>
    _.map(items, item => ({ ...item, value: Number(item.value) }))

export const groupFacilities = facilities => {
    const grouped = _.groupBy(facilities, facility => facility.label)
    const mapped = _.mapValues(grouped, items => items.map(item => item.value))
    return sortByLabel(objToLabelValue(mapped))
}
export const getFacilitiesByIds = (filterOptions, value) =>
    filterOptions.facilities.filter(facility =>
        intersectionExists(facility.value, value)
    )

export const transformFilterDropdowns = payload =>
    payload && {
        ...payload,
        races: sortByLabel(payload.races),
        sources: sortByLabel(payload.sources),
        measures: sortByLabel(payload.measures),
        ethnicities: sortByLabel(payload.ethnicities),
        hccConditions: sortByLabel(payload.hccConditions),
        genConditions: sortByLabel(payload.genConditions),
        productClasses: sortByLabel(payload.productClasses),
        payers: sortByLabel(forceNumericValue(payload.payers)),
        units: sortByLabel(forceNumericValue(payload.units)),
        pcps: sortByLabel(
            _.uniqBy(forceNumericValue(payload.pcps), item => item.value)
        ),
        riskRank: forceNumericValue(payload.riskRank),
        payerLogos: itemsToObject(
            payload.payerLogos,
            p => p.id,
            p => p.logo
        ),
        sourceLogos: itemsToObject(
            payload.sourceLogos,
            p => p.id,
            p => p.logo
        ),
        facilities: groupFacilities(payload.facilities)
    }
