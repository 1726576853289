import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from "../../notifications"
import * as actions from "../actions"

class ErrorNotification extends React.Component {
    componentWillUnmount() {
        this.props.showSnackbar()
    }
    render() {
        const message = (this.props.error || {}).message
        const pocPath = `/poc/${this.props.patientId}`
        const isSamePage =
            _.trimEnd(this.props.location.pathname, "/") === pocPath
        return (
            <div className="notification-measure-error">
                {message}
                {isSamePage || (
                    <Link className="btn btn-sm btn-danger" to={pocPath}>
                        Return to POC
                    </Link>
                )}
            </div>
        )
        // we could use react-notification-system's `action` prop instead of this custom component, but then we'd have to change pages manually instead of letting the link take care of it.
    }
}
const ErrorNotificationWrapper = withRouter(
    connect(null, actions)(ErrorNotification)
)

export const measureErrorNotification = (error, title, data = {}) => {
    return {
        ...ERROR_NOTIFICATION,
        title,
        message: null, // the message is displayed in the child component instead
        children: (
            <ErrorNotificationWrapper
                error={error}
                patientId={data.patientId}
            />
        )
    }
}

class SuccessNotification extends React.Component {
    componentWillUnmount() {
        this.props.showSnackbar()
    }
    render() {
        return null // this component is only here so that we can call showSnackbar() on unmount, so no render is needed.
    }
}
const SuccessNotificationWrapper = connect(null, actions)(SuccessNotification)

export const measureSuccessNotification = (message, options) => {
    return {
        ...SUCCESS_NOTIFICATION,
        ...options,
        children: <SuccessNotificationWrapper />,
        message
    }
}
