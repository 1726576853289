import { change } from "redux-form"
import { connect } from "react-redux"
import { getResourceData } from "../../core/fetcher"
import auth from "../../authentication"
import patient_list from "../../patient_list"
import { clearCache } from "../../site_settings/actions"
import { storeNavDimensions } from "../../structure/actions"
import { searchFlagsSelector } from "../../patient_list/selectors"
import { SETTINGS } from "../../constants"
import { initialValuesSelector, navItemsSelector } from "../selectors"
import { Navigation } from "./Navigation"

export default connect(
    state => ({
        loginId: state.authentication.loginId,
        roles: state.authentication.roles,
        navItems: navItemsSelector(state),
        fuzzySearchFlags: searchFlagsSelector(state),
        initialValues: initialValuesSelector(state),
        allowGlobal:
            getResourceData(SETTINGS, data => data.allowGlobal)(state) &&
            !auth.selectors.isExternal(state)
    }),
    {
        ...auth.actions,
        ...patient_list.actions,
        change,
        clearCache,
        storeNavDimensions
    }
)(Navigation)
