import { all, call, select, takeEvery } from "redux-saga/effects"
import { downloadFile } from "../api"
import { getFilename, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import { requestBodySelector } from "./selectors"
import * as C from "./constants"
import * as CC from "../cost/constants"
import { getTableName } from "./helpers"

function* getDashboardCostReport(aggregation, extension) {
    const filters = yield select(requestBodySelector)
    const tableName = getTableName(aggregation)
    const sortedColumns = yield select(getSortedColumns(tableName))

    const displayNameIndex = sortedColumns.column.indexOf(
        CC.TABLE_KEYS.DISPLAY_NAME
    )
    if (displayNameIndex > -1) {
        // we need to replace the "displayName" column with "categoryName" because in the reports, the categoryName and subcategoryName generate in different rows
        sortedColumns.column[displayNameIndex] = CC.TABLE_KEYS.CATEGORY_NAME
    }

    const params = { ...filters, ...sortedColumns }

    yield* downloadFile(
        `${aggregation} cost summary ${extension.toUpperCase()}`,
        urlWithParams(
            `/api/cost/dashboard/${aggregation}/${extension}`,
            params
        ),
        getFilename(`cost_summary_${aggregation}`, extension)
    )
}

export function* printPdf(action) {
    yield* getDashboardCostReport(action.payload, "pdf")
}
export function* exportCsv(action) {
    yield* getDashboardCostReport(action.payload, "csv")
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.PRINT_COST_DASHBOARD_PDF, printPdf)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_COST_DASHBOARD_CSV, exportCsv)
        })
    ])
}
