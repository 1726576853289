export const NAME = "FILTER_MODALS"
export const FAVORITE_FILTERS_FORM = "FAVORITE_FILTERS_FORM"

// actions
export const SAVE_FAVORITE_FILTERS = "SAVE_FAVORITE_FILTERS"
export const DELETE_FAVORITE_FILTERS = "DELETE_FAVORITE_FILTERS"
export const UNDELETE_FAVORITE_FILTERS = "UNDELETE_FAVORITE_FILTERS"
export const SELECT_FAVORITE_FILTERS = "SELECT_FAVORITE_FILTERS"

export const STOP_SAVING_FILTERS = "STOP_SAVING_FILTERS"
export const REMOVE_DELETED_FAVORITES = "REMOVE_DELETED_FAVORITES"

export const SHOW_FILTERS_MODAL = "SHOW_FILTERS_MODAL"
export const SHOW_FAVORITES_MODAL = "SHOW_FAVORITES_MODAL"
export const SHOW_FAVORITE_NAME_MODAL = "SHOW_FAVORITE_NAME_MODAL"
export const SHOW_EDIT_FAVORITE_MODAL = "SHOW_EDIT_FAVORITE_MODAL"

// table keys & filters

export const FILTERS = {
    ID: "favoriteFiltersId",
    NAME: "favoriteFiltersName"
}

export const TABLE_KEYS = {
    ID: "favoriteFiltersId",
    NAME: "name",
    FILTERS: "filters",
    ACTIONS: "actions",
    FORM: "formName",
    LOADING: "loading",
    DELETED: "deleted"
}

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.NAME]: "Name",
    [TABLE_KEYS.FILTERS]: "Filters",
    [TABLE_KEYS.ACTIONS]: "Actions"
}

export const initialState = {
    showFiltersModal: null,
    showFavoritesModal: null,
    showFavoriteNameModal: null,
    showEditFavoriteModal: null,
    saving: false,
    selectedFilters: {}
}
