import _ from "lodash"
import { createReducer } from "../utils"
import { getErrorKey } from "./helpers"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SET_AWV_FILTERS]: (state, { filters }) => ({
        ...state,
        filters
    }),
    [C.SET_AWV_CATEGORIES]: (state, { categories }) => ({
        ...state,
        categories
    }),
    [C.UPDATE_AWV_ERROR]: (state, { payload }) => ({
        ...state,
        errors: {
            ...state.errors,
            [getErrorKey(payload)]: payload.errorMessage
        }
    }),
    [C.CLEAR_AWV_ERROR]: (state, { payload }) => ({
        ...state,
        errors: _.omit(state.errors, [getErrorKey(payload)])
    })
})

export default reducer
