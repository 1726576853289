import React, { useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import { useOutsideClick } from "use-dom-outside-click"
import _ from "lodash"
import Loading from "../../core/Loading"
import { LabelledDatePicker } from "../../core/CustomFields"
import { tcmDateValidator, validTcmDate } from "../../tcm/validators"
import { mapValuesToObject } from "../../utils"
import { dateFormat } from "../../dates"
import * as C from "../constants"

export const TCMPopoverForm = ({
    dischargeDt,
    invalid,
    innerRef,
    loading,
    menuRef,
    onHide,
    savePATCMEntries,
    tcm
}) => {
    useOutsideClick(innerRef, onHide, [menuRef])

    useEffect(
        // run on unmount
        () => () => {
            if (!invalid && !_.isEmpty(tcm)) {
                // the isEmpty check is because we don't want to do anything with `tcm` until it's actually loaded
                savePATCMEntries(tcm)
            }
        },
        [tcm]
    )

    const validator = useMemo(() => tcmDateValidator(dischargeDt), [
        dischargeDt
    ])

    return (
        <>
            {loading && <Loading /> // we don't use a regular LEHOC because we want the table to render but stay hidden, so that the popover will stay the same size while it's loading
            }
            <table
                className="table popover-table tcm-popover-table"
                ref={innerRef}
            >
                <thead>
                    <tr className="tcm_edit_popover_tr">
                        {C.ENTRY_FIELDS.map(item => (
                            <th key={item}>{C.TCM_TRANSLATION[item]}</th>
                        ))}
                        <th>Billing Due</th>
                        <th>Re-admit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {C.ENTRY_FIELDS.map(item => (
                            <td key={item}>
                                <Field
                                    component={LabelledDatePicker}
                                    name={item}
                                    isValidDate={validTcmDate(dischargeDt)}
                                    validate={validator}
                                    noLabel
                                                                        menuRef={menuRef}
                                />
                            </td>
                        ))}
                        <td style={{ verticalAlign: "middle" }}>
                            {dateFormat(tcm.billingDueDt)}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                            {dateFormat(tcm.readmitDt)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default connect((state, { tcm }) => ({
    initialValues: mapValuesToObject(C.ENTRY_FIELDS, field =>
        tcm[field] ? dateFormat(tcm[field]) : tcm[field]
    )
}))(
    reduxForm({
        form: C.TCM_POPOVER_FORM,
        enableReinitialize: true,
        destroyOnUnmount: false
    })(TCMPopoverForm)
)
