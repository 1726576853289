import React, { useEffect, useState } from "react"
import EmailLink from "../../core/EmailLink"
import PhoneLink from "../../core/PhoneLink"
import { GuideLink } from "../../indicators"
import { ApiTypes, DEFAULT_HEADERS } from "../../api"
import * as C from "../constants"

export const AssistanceText = () => {
    const [data, setData] = useState({})

    useEffect(() => {
        const controller = new AbortController()

        // we don't use a redux-obtain fetcher because that automatically adds authentication, which we can't do because we haven't logged in yet
        fetch("/contact", {
            signal: controller.signal,
            method: ApiTypes.GET,
            headers: DEFAULT_HEADERS
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`/contact returned ${response.status}`)
                }
                return response.json()
            })
            .then(data => setData(data))
            .catch(() => {
                // do nothing
            })

        return () => controller.abort()
    }, [])

    const { email, name, telephone } = data
    return (
        <div style={{ width: "100%", textAlign: "center" }}>
            If you need assistance logging in, consult the{" "}
            <GuideLink
                style={{ padding: 0 }}
                page="logging-in-to-the-application"
            >
                User Guide
            </GuideLink>{" "}
            or contact{" "}
            {name ? (
                <span>
                    {name} at {telephone && <PhoneLink number={telephone} />}
                    {telephone && email && " or "}
                    {email && <EmailLink email={email} />}
                </span>
            ) : (
                <EmailLink email={C.DEFAULT_CONTACT_EMAIL} />
            )}
            .
        </div>
    )
}
export default AssistanceText
