import * as C from "./constants"

export const setCurrentCampaign = payload => ({
    type: C.SET_CURRENT_CAMPAIGN,
    payload
})
export const deleteCampaign = payload => ({ type: C.DELETE_CAMPAIGN, payload })
export const unsubscribeFromCampaign = payload => ({
    type: C.UNSUBSCRIBE_FROM_CAMPAIGN,
    payload
})
export const resubscribeToCampaign = payload => ({
    type: C.RESUBSCRIBE_TO_CAMPAIGN,
    payload
})
export const pinCampaign = payload => ({ type: C.PIN_CAMPAIGN, payload })
export const unpinCampaign = payload => ({ type: C.UNPIN_CAMPAIGN, payload })
export const pinUnpinCampaignComplete = campaignId => ({
    type: C.PIN_UNPIN_CAMPAIGN_COMPLETE,
    campaignId
})

export const createNewCampaign = payload => ({
    type: C.CREATE_NEW_CAMPAIGN,
    payload
})
export const saveCampaignEdits = payload => ({
    type: C.SAVE_CAMPAIGN_EDITS,
    payload
})
export const exportCampaignPatientList = () => ({
    type: C.EXPORT_CAMPAIGN_PATIENT_LIST
})
export const printCampaignPatientList = () => ({
    type: C.PRINT_CAMPAIGN_PATIENT_LIST
})

export const removeCampaign = payload => ({ type: C.REMOVE_CAMPAIGN, payload })
export const hideCampaign = payload => ({ type: C.HIDE_CAMPAIGN, payload })

export const showEdit = payload => ({ type: C.SHOW_EDIT, payload })
export const hideEdit = () => ({ type: C.HIDE_EDIT })

export const addCampaignPatient = payload => ({
    type: C.ADD_CAMPAIGN_PATIENT,
    payload
})
export const removeCampaignPatient = payload => ({
    type: C.REMOVE_CAMPAIGN_PATIENT,
    payload
})
export const setCampaignFilters = payload => ({
    type: C.SET_CAMPAIGN_FILTERS,
    payload
})
