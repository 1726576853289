import * as C from "./constants"

export const showInactivePatients = () => ({
    type: C.SHOW_INACTIVE_PATIENTS
})
export const hideInactivePatients = () => ({
    type: C.HIDE_INACTIVE_PATIENTS
})
export const deactivatePatient = patientId => ({
    type: C.DEACTIVATE_PATIENT,
    payload: patientId
})
export const reactivatePatient = patientId => ({
    type: C.REACTIVATE_PATIENT,
    payload: patientId
})
