import _ from "lodash"
import { getCompositeSubmeasureIdName } from "../../../../helpers"

const SUBMEASURE_IDS = {
    systolic: "id_120_comp_31",
    diastolic: "id_120_comp_30"
}

const allSubMeasuresEntered = submeasureIds => (value, allValues) => {
    const numEmpty = submeasureIds.filter(id => _.isNil(allValues[id])).length
    const invalid = 0 < numEmpty && numEmpty < submeasureIds.length // don't invalidate if they're all entered or all empty
    return invalid && "must have all submeasures filled in"
}

const bloodPressure = ({ subMeasures, id }) => {
    const subMeasureIds = subMeasures.map(getCompositeSubmeasureIdName(id))
    const { systolic, diastolic } = SUBMEASURE_IDS

    return [
        allSubMeasuresEntered(subMeasureIds),
        (values, allValues) => {
            const subMeasures = _.pick(allValues, subMeasureIds)
            const invalid =
                Number(subMeasures[systolic]) <= Number(subMeasures[diastolic])
            return invalid && "systolic must be greater than diastolic"
        }
    ]
}

export default { id_120: bloodPressure }
