import React from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { Field, reduxForm } from "redux-form"
import { TableDescription } from "../../table"
import { PermissionManagement } from "../../permission_managment/components/PermissionManagement"
import { ACCESS_PRIVILEGES } from "../../permission_managment/constants"
import * as C from "../constants"
import * as actions from "../actions"
import { tagUserInitialValuesSelector } from "../selectors"

const TagUserCard = ({ title, children, ...props }) => (
    <Card>
        <Card.Header className="card-header-group">
            <Card.Title className="user-tags-permissions-subheader">
                <h3>{title}</h3>
            </Card.Title>
            <TableDescription Component="p">{children}</TableDescription>
        </Card.Header>
        <Field component={PermissionManagement} {...props} />
    </Card>
)

export class TagUserTable extends React.Component {
    setTagAccess = accessData =>
        this.props.setTagPrivileges({
            tagId: this.props.currentTag[C.TABLE_KEYS.TAG_ID],
            ...accessData
        })
    setTagUsers = (privilege, oldPrivilege, userIds) =>
        this.setTagAccess({ privilege, oldPrivilege, userIds })
    setTagPractices = (privilege, oldPrivilege, unitIds) =>
        this.setTagAccess({ privilege, oldPrivilege, unitIds })

    render() {
        return (
            <>
                <TagUserCard
                    title="Users"
                    name={C.FORM_FIELDS.USER_PRIVILEGES}
                    loading={this.props.loading}
                    onSet={this.setTagUsers}
                    saving={this.props.savingUsers}
                    needsConfirmation={(item, from, to) =>
                        item.id === this.props.activeUserId &&
                        from === ACCESS_PRIVILEGES.EDIT &&
                        to !== ACCESS_PRIVILEGES.EDIT
                            ? `Are you sure you want to change your access to the tag \"${
                                  this.props.currentTag[C.TABLE_KEYS.TAG_NAME]
                              }\"? You will not be able to continue editing it.`
                            : false
                    }
                >
                    These tables list all of the users visible to you, and
                    whether they can access or edit the "
                    {this.props.currentTag[C.TABLE_KEYS.TAG_NAME]}" tag.
                </TagUserCard>

                <TagUserCard
                    title="Practices"
                    name={C.FORM_FIELDS.PRACTICE_PRIVILEGES}
                    loading={this.props.loading}
                    onSet={this.setTagPractices}
                    saving={this.props.savingPractices}
                >
                    These tables lists all of the practices visible to both you
                    and the tag's owner, and whether they can access or edit the
                    "{this.props.currentTag[C.TABLE_KEYS.TAG_NAME]}" tag.
                </TagUserCard>
            </>
        )
    }
}

export default connect(
    state => ({
        ...state[C.NAME],
        initialValues: tagUserInitialValuesSelector(state),
        activeUserId: state.authentication.userId
    }),
    actions
)(
    reduxForm({
        form: C.TAG_USERS_FORM,
        enableReinitialize: true
    })(TagUserTable)
)
