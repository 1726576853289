import React from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import DashboardFilters from "../../dashboard_filters/components"
import { ExportIcon } from "../../core/Icons"
import FilterHeader from "../../core/FilterHeader"
import LER from "../../core/LoadingErrorHOC"
import { tableFactory } from "../../table"
import columns from "../columns"
import * as C from "../constants"
import { AwvFilters } from "./AwvFilters"

const { Settings, Table, Description } = tableFactory({
    name: C.AWV_TABLE,
    columns,
    sortBy: C.TABLE_KEYS.PATIENT_NAME,
    sortDirection: SortDirection.ASC
})

export const AwvWorklist = ({
    awvs,
    total,
    filters,
    setFilters,
    loading,
    error,
    exportCsv,
    paginationFunctions,
    eligibleDateFallback
}) => (
    <Card className="card-table awv">
        <Card.Header>
            <Card.Title>Annual Wellness Visit</Card.Title>
            <DashboardFilters
                size="xl"
                title="Edit AWV Worklist Filters"
                form={C.AWV_FILTERS_FORM}
                formComponent={AwvFilters}
                initialValues={filters}
                setFilters={setFilters}
            />
            <Settings />
            {/* TODO implement print */}
            {/*<PrintIcon className="pull-right" />*/}
            <ExportIcon className="pull-right" onClick={exportCsv} />
            <FilterHeader
                filters={filters}
                translation={C.FILTER_TRANSLATION}
            />
        </Card.Header>
        <Description />
        <Card.Body>
            <LER list={awvs} loading={loading} error={error}>
                <Table
                    {...paginationFunctions}
                    list={awvs}
                    rowCount={total}
                    tableData={{ eligibleDateFallback }}
                />
            </LER>
        </Card.Body>
        <Card.Footer>
            <div className="entry-count">
                {loading
                    ? "Loading..."
                    : error
                    ? "Error in results"
                    : `${total} result${total === 1 ? "" : "s"}`}
            </div>
        </Card.Footer>
    </Card>
)
