import React from "react"
import { Col, Row } from "react-bootstrap"
import { Field, reduxForm } from "redux-form"
import { SelectField } from "../../../core/CustomFields"
import { required } from "../../../core/validators"
import * as C from "../../constants"

const AddRiskForm = ({ hfRiskType, riskRank }) => (
    <Row>
        <Col md={6}>
            <Field
                name={C.TABLE_KEYS.TYPE}
                component={SelectField}
                options={hfRiskType}
                validate={required}
                notClearable
                required
            />
        </Col>
        <Col md={6}>
            <Field
                name={C.TABLE_KEYS.RANK}
                component={SelectField}
                options={riskRank}
                validate={required}
                notClearable
                required
            />
        </Col>
    </Row>
)

export default reduxForm({
    form: C.ADD_RISK_FORM,
    enableReinitialize: true
})(AddRiskForm)
