import { connect } from "react-redux"
import { getFormValues, isInvalid } from "redux-form"
import fetcher, { getResourceData } from "../../../core/fetcher"
import { authTokensSelector } from "../../../authentication/selectors"
import { easyFormValueSelector } from "../../../selectorUtils"
import { ApiTypes } from "../../../api"
import patient_list from "../../../patient_list"
import table from "../../../table"
import {
    activeUnitsSelector,
    activeUsersSelector,
    defaultColumnsSelector
} from "../../selectors"
import * as reduxActions from "../../actions"
import * as C from "../../constants"
import { CreateCampaignButton } from "./CreateCampaignButton"

export default fetcher({
    name: C.ACTIVE_USERS,
    endpoint: "/api/users/active",
    method: ApiTypes.GET,
    persistResource: true
})(
    connect(
        state => ({
            authentication: authTokensSelector(state),
            users: activeUsersSelector(state),
            units: activeUnitsSelector(state),
            formValues: getFormValues(C.NEW_CAMPAIGN_FORM)(state),
            invalid: isInvalid(C.NEW_CAMPAIGN_FORM)(state),
            appliedFilters: state.applied_filters,
            currentCampaign: getResourceData(C.CAMPAIGN_PAGE)(state),
            currentUser: state.authentication.loginId,
            // these are each used by different cases of the CreateCampaignButton initialValues function
            defaultColumns: defaultColumnsSelector(state),
            tableData:
                state[table.constants.TABLE_STORE][state.applied_filters?.type],
            campaignColumnDefinitions:
                patient_list.columns.COLUMNS_BY_TYPE[
                    easyFormValueSelector(
                        C.NEW_CAMPAIGN_FORM,
                        C.TABLE_KEYS.CAMPAIGN_TYPE
                    )(state)
                ]
        }),
        reduxActions
    )(CreateCampaignButton)
)
