import React, { Component, useRef, useState } from "react"
import { connect } from "react-redux"
import { initialize, isInvalid } from "redux-form"
import { Hoverlay } from "../../core/Hoverlay"
import * as reduxActions from "../actions"
import * as C from "../constants"
import TCMPopoverForm from "./TCMPopoverForm"

export const TCMPopover = props => {
    const Component = props.Component || TCMPopoverForm

    const [show, setShow] = useState(false)

    const innerRef = useRef()
    const menuRef = useRef()

    return (
        <div>
            <Hoverlay
                show={show}
                isPopover
                trigger={props.formInvalid ? "none" : "click"}
                className="tcm-popover"
                tooltip={
                    <Component
                        onHide={() => setShow(false)}
                        innerRef={innerRef}
                        menuRef={menuRef}
                        {...props}
                    />
                }
            >
                <button className="btn-blank" onClick={() => setShow(true)}>
                    <span className="fa fa-pencil tcm-edit" />
                </button>
            </Hoverlay>
        </div>
    )
}

export default connect(
    (state, { form }) => ({
        formInvalid: isInvalid(C.TCM_POPOVER_FORM)(state)
    }),
    {
        ...reduxActions,
        initialize
    }
)(TCMPopover)
