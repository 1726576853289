import { connect } from "react-redux"
import { getResourceData, modifyResource } from "../../../../../core/fetcher"
import { isSuperuser } from "../../../../../authentication/selectors"
import { moduleEnabledSelector } from "../../../../../moduleUtils"
import { MODULE_IDS } from "../../../../../constants"
import * as C from "../../../../constants"
import * as reduxActions from "../../../../actions"
import PatientHistory from "./PatientHistory"

export default connect(
    (state, { id, measureId, patientId }) => ({
        currentUser: state.authentication.loginId,
        moduleIsEnabled: moduleEnabledSelector(MODULE_IDS.POC_MEASURES)(state),
        isSuperuser: isSuperuser(state),
        historyList: getResourceData(C.MEASURE_HISTORY)(state),
        patientId: patientId ?? state.poc_id,
        measureId: measureId ?? id
    }),
    { ...reduxActions, modifyResource }
)(PatientHistory)
