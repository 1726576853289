import React, { useMemo, useState } from "react"
import { Card } from "react-bootstrap"
import _ from "lodash"
import SearchBar from "../../../core/SearchBar"
import LEHOC from "../../../core/LoadingErrorHOC"
import CardTitle from "../../../core/CardTitle"
import { EMPTY_OBJECT, matchesSearch, objectPop } from "../../../utils"
import { ReportListCategory } from "./ReportListCategory"

const getCategory = reports => {
    if (_.isEmpty(reports)) return undefined
    const { reportCategoryId, reportCategoryName } =
        _.head(reports) ?? EMPTY_OBJECT
    return { reportCategoryId, reportCategoryName, reports }
}

const categorizeReports = allReports => {
    const grouped = _.groupBy(allReports, report => report.reportCategoryId)
    const uncategorized = getCategory(objectPop(grouped, 0))

    const categories = _.values(grouped).map(getCategory)
    const sorted = _.sortBy(categories, report =>
        _.lowerCase(report.reportCategoryName)
        )
    return _.compact([...sorted, uncategorized])
}

export const ReportList = ({ data, error, loading, reportId }) => {
    const [filter, setFilter] = useState("")

    const reportCategories = useMemo(() => {
        const reports = _.filter(data.reportList, item =>
            matchesSearch(item.reportName, filter)
        )
        return categorizeReports(reports)
    }, [data.reportList, filter])

        return (
            <Card className="report-list">
                <Card.Header>
                    <CardTitle as="h2" style={{ fontSize: "20px" }}>
                        Available Reports
                    </CardTitle>
                    <div className="form-group report-search">
                        <SearchBar
                            id="directory_filter"
                            placeholder="Filter"
                        onChange={setFilter}
                        />
                    </div>
                </Card.Header>
                <Card.Body className="table-wrapper">
                    <LEHOC
                    loading={loading}
                    error={error}
                    list={data.reportList}
                    >
                    {reportCategories.map(category => (
                        <ReportListCategory
                            key={category.reportCategoryId}
                            selectedReportId={reportId}
                            {...category}
                                    />
                                ))}
                    </LEHOC>
                </Card.Body>
            </Card>
        )
}
