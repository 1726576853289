import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import { CostChartCard } from "../../cost/components/CostChartCard"
import { costChartDataSelector } from "../../cost_report/selectors"
import { PERIOD_MONTH_IDS, PERIODS } from "../../pickers/constants"
import { transformAndFilterFilters } from "../../transformFilter"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"

const chartDataSelector = costChartDataSelector(C.PATIENT_LIST_COST_CHART)
export const CostChart = connect(chartDataSelector)(CostChartCard)

export default fetcher({
    name: C.PATIENT_LIST_COST_CHART,
    endpoint: state =>
        urlWithParams(
            "/api/patients/cost/chart",
            transformAndFilterFilters({
                ...state.applied_filters,
                ...PERIOD_MONTH_IDS[PERIODS.LAST_12_MONTHS]
            })
        ),
    method: ApiTypes.GET
})(CostChart)
