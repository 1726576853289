import React from "react"
import * as C from "../constants"
import { Col, Row } from "react-bootstrap"
import { Field } from "redux-form"
import { SelectField, PhoneNumber, TextInput } from "../../core/CustomFields"
import {
    fixedDigits,
    mustBeANumber,
    validEmail,
    validPhoneNumber
} from "../../core/validators"

const LocationFieldPanel = ({
    backButton,
    saveButton,
    counties,
    socialNeeds,
    services,
    requirements
}) => (
    <>
        <div className="location-buttons">
            {backButton}
            {saveButton}
        </div>
        <div
            style={{
                backgroundColor: "#e4eefa",
                padding: 10,
                borderTop: "1px solid #ccc"
            }}
        >
            <Row style={{ paddingTop: 5 }}>
                <Col sm={6} md={{ span: 3, offset: 3 }}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.NAME}
                        component={TextInput}
                    />
                </Col>
                <Col sm={6} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.PRIMARY_CONTACT}
                        component={TextInput}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.COUNTIES}
                        options={counties}
                        component={SelectField}
                        multi={true}
                    />
                </Col>
                <Col sm={6} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.SOCIAL_NEEDS}
                        options={socialNeeds}
                        component={SelectField}
                        multi={true}
                    />
                </Col>
                <Col sm={6} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.SERVICES}
                        options={services}
                        component={SelectField}
                        multi={true}
                    />
                </Col>
                <Col sm={6} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.REQUIREMENTS}
                        options={requirements}
                        component={SelectField}
                        multi={true}
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.COUNTRY}
                        options={C.COUNTRY_OPTIONS}
                        component={SelectField}
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.STATE}
                        options={C.STATE_OPTIONS}
                        component={SelectField}
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.CITY}
                        component={TextInput}
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.ZIP}
                        component={TextInput}
                        validate={[fixedDigits(5), mustBeANumber]}
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.ADDRESS1}
                        component={TextInput}
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.ADDRESS2}
                        component={TextInput}
                    />
                </Col>
                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.TELEPHONE}
                        component={PhoneNumber}
                        validate={validPhoneNumber}
                    />
                </Col>

                <Col sm={4} md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.EMAIL}
                        component={TextInput}
                        validate={validEmail}
                    />
                </Col>
                <Col sm={4} md={{ span: 3, offset: 3 }}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.WEBSITE}
                        component={TextInput}
                    />
                </Col>
                <Col md={3}>
                    <Field
                        name={C.ADD_COMMUNITY_LOCATION_FIELDS.DESCRIPTION}
                        component={TextInput}
                    />
                </Col>
            </Row>
        </div>
    </>
)

export default LocationFieldPanel
