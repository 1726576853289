// noinspection JSCheckFunctionSignatures

import { createSelector } from "reselect"
import { getFormValues } from "redux-form"
import _ from "lodash"
import { getResourceData, getResourceDataAsObject } from "../core/fetcher"
import { ACCESS_PRIVILEGES } from "../permission_managment/constants"
import { FILTER_OPTIONS, MEASURES } from "../constants"
import { easyFormValueSelector } from "../selectorUtils"
import {
    compactFilters,
    EMPTY_OBJECT,
    encodeFiltersInUrl,
    sortedUnique,
    urlWithParams
} from "../utils"
import patientList from "../patient_list"
import { tableFactory } from "../table"
import { getDefaultColumns, placeColumnsByKeys } from "../table/helpers"
import tableSettings from "../table_settings"
import * as C from "./constants"
import { getCampaignTableName } from "./helpers"

const OWNER_MESSAGE = "A campaign's owner can always view and edit it."

export const campaignsSelector = resourceName =>
    createSelector(
        getResourceData(resourceName, data => data.campaigns),
        state => state[C.NAME].savingCampaigns,
        (campaigns, savingCampaigns = []) =>
            campaigns?.map(campaign => ({
                ...campaign,
                [C.TABLE_KEYS.SAVING]: savingCampaigns.includes(
                    campaign[C.TABLE_KEYS.CAMPAIGN_ID]
                )
            }))
    )

export const canEditCampaignSelector = createSelector(
    getResourceData(C.CAMPAIGN_PAGE, data => data.campaign),
    getResourceData(C.CAMPAIGN_LIST, data => data.campaigns),
    state => state[C.NAME][C.EDIT_CAMPAIGN_MODAL],
    (currentCampaign, campaignList, campaignId) =>
        currentCampaign?.[C.TABLE_KEYS.EDIT_CAMPAIGN] ??
        _.find(campaignList, { campaignId })?.[C.TABLE_KEYS.EDIT_CAMPAIGN]
)

const formSelector = state =>
    getFormValues(C.CAMPAIGN_FORM)(state) ||
    getFormValues(C.NEW_CAMPAIGN_FORM)(state) ||
    {}

export const campaignTypeSelector = createSelector(
    formSelector,
    form => form[C.TABLE_KEYS.CAMPAIGN_TYPE]
)

export const formUrlSelector = createSelector(
    state => state[C.NAME][C.CURRENT_CAMPAIGN],
    getFormValues(C.CAMPAIGN_FILTERS_FORM),
    (campaignId, formValues) =>
        encodeFiltersInUrl(`/campaigns/${campaignId}`, formValues?.filter)
)

export const tableDataSelector = createSelector(
    getResourceDataAsObject(C.CAMPAIGN_PAGE, data => data.campaign),
    campaign => ({
        useMeasureServiceDate: !_.isEmpty(campaign.filter?.[C.FILTERS.MEASURES])
    })
)

const getCurrentUserId = state => state.authentication.userId

export const ownerSelector = createSelector(
    formSelector,
    getCurrentUserId,
    (form, id) => form.ownerId || id
)

export const patientListSelector = createSelector(
    getResourceData(C.CAMPAIGN_PATIENT_LIST, data => data.patients),
    getResourceData(MEASURES, data => data.measuresMap),
    patientList.selectors.optionLookupsSelector,
    state => state.server_status.anchorDate,
    (patients, measuresMap, filterOptions, anchorDate) =>
        patientList.selectors.patientListCombiner(
            patients,
            measuresMap,
            filterOptions,
            anchorDate
        )
)

export const activeUsersSelector = createSelector(
    getResourceData(C.ACTIVE_USERS, data => data.users),
    getResourceData(C.CURRENT_CAMPAIGN, data => data.campaign),
    state => state.authentication.userId,
    (activeUsers = [], campaign = {}, currentUserId) => {
        if (_.isEmpty(activeUsers)) {
            // e.g. if activeUsers is an empty object, like it is before the fetch is finished
            return []
        }

        const users = activeUsers.map(({ label, id, value }) => {
            const isOwner =
                (id || value) ===
                (campaign[C.TABLE_KEYS.OWNER_ID] || currentUserId)
                    ? OWNER_MESSAGE
                    : false

            return {
                label,
                id: id || value, // it's a hassle for the options to sometimes have values, sometimes ids, so let's make them always have ids
                disabled: {
                    [undefined]: isOwner,
                    [ACCESS_PRIVILEGES.READ]: isOwner
                },
                privilege: isOwner ? ACCESS_PRIVILEGES.EDIT : undefined
            }
        })
        return _.sortBy(users, user => user.label)
    }
)

export const activeUnitsSelector = createSelector(
    getResourceData(FILTER_OPTIONS, data => data.units),
    (units = []) =>
        _.sortBy(
            units.map(({ label, value }) => ({
                label,
                id: value
            })),
            unit => unit.label
        )
)

export const initialUserPrivilegesSelector = createSelector(
    activeUsersSelector,
    getResourceData(C.CURRENT_CAMPAIGN),
    (activeUsers, campaign = {}) =>
        sortedUnique(campaign[C.TABLE_KEYS.USER_PRIVILEGES], activeUsers)
)

export const initialUnitPrivilegesSelector = createSelector(
    activeUnitsSelector,
    getResourceData(C.CURRENT_CAMPAIGN),
    (activeUnits, campaign = {}) =>
        sortedUnique(campaign[C.TABLE_KEYS.UNIT_PRIVILEGES], activeUnits)
)

export const requestBodySelector = getResourceData(
    C.CAMPAIGN_PAGE,
    data => data.campaign.filterSql
)

export const tableFactorySelector = createSelector(
    getResourceData(C.CAMPAIGN_PAGE, data => data.campaign),
    (campaign = {}) => {
        const columns =
            patientList.columns.COLUMNS_BY_TYPE[
                campaign[C.TABLE_KEYS.CAMPAIGN_TYPE]
            ]
        return tableFactory({
            //TODO maybe we should pass in the campaign description here, for the TableDescription. Or however that's done
            name: getCampaignTableName(campaign[C.TABLE_KEYS.CAMPAIGN_ID]),
            columns,
            ...C.DEFAULT_ORDER_BYS
        })
    }
)

export const campaignIsModifiedSelector = createSelector(
    getResourceData(C.CAMPAIGN_PAGE, data => data.campaign?.filter),
    state => state.applied_filters,
    (campaignFilters, appliedFilters) =>
        !_.isEqual(
            compactFilters(campaignFilters),
            compactFilters(appliedFilters)
        )
)

export const endpointParamsSelector = createSelector(
    getResourceData(C.CAMPAIGN_PAGE, data => data?.campaign),
    campaignIsModifiedSelector,
    state => state.applied_filters,
    (campaign = EMPTY_OBJECT, modified, filters) => ({
        ...(modified ? filters : {}),
        modified,
        type: campaign.type,
        campaignId: campaign.campaignId
    })
)
export const endpointSelector = createSelector(
    endpointParamsSelector,
    endpointParams =>
        urlWithParams(
            `/api/campaigns/${endpointParams.campaignId}/patients`,
            endpointParams
        )
)

export const footerEndpointSelector = createSelector(
    endpointParamsSelector,
    endpointParams =>
        urlWithParams(
            `/api/campaigns/${endpointParams.campaignId}/patients/length`,
            endpointParams
        )
)

export const defaultColumnsSelector = createSelector(
    state => state[tableSettings.constants.NAME].columns,
    state => state[tableSettings.constants.NAME].defaultColumns,
    easyFormValueSelector(C.NEW_CAMPAIGN_FORM, C.TABLE_KEYS.CAMPAIGN_TYPE),
    (
        tableSettings,
        tableDefaults,
        listType = patientList.constants.FILTER_TYPES.PATIENT_MEASURES
    ) => {
        const definitions = patientList.columns.COLUMNS_BY_TYPE[listType]
        const defaults = getDefaultColumns(definitions, tableDefaults[listType])
        const settings = tableSettings[listType]

        if (!_.isEmpty(settings)) {
            const manualValues = placeColumnsByKeys(
                definitions,
                settings.columns,
                settings.frozenColumns
            )

            if (
                !_.isEmpty(manualValues.columns) ||
                !_.isEmpty(manualValues.frozenColumns)
            ) {
                return manualValues
            }
        }

        return defaults
    }
)
