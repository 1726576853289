import { connect } from "react-redux"
import _ from "lodash"
import fetcher, {
    getResourceData,
    getResourceDataAsObject
} from "../../core/fetcher"
import { moduleEnabledSelector } from "../../moduleUtils"
import { ApiTypes } from "../../api"
import { MODULE_IDS } from "../../constants"
import * as C from "../constants"
import * as reduxActions from "../actions"
import { canDeactivate } from "../selectors"
import PatientInfo from "./PatientInfo"
import PatientName from "./PatientName"
import PatientHeaderInfo from "./PatientHeaderInfo"

export const Info = fetcher({
    name: C.NAME,
    endpoint: state => `/api/patients/${state.poc_id}/info`,
    method: ApiTypes.GET
})(
    connect(state => ({
        canDeactivate: canDeactivate(state),
        canEdit: moduleEnabledSelector(MODULE_IDS.ADD_EDIT_PATIENT)(state)
    }))(PatientInfo)
)

export const Name = connect(
    getResourceDataAsObject(C.NAME, data =>
        _.pick(data, [C.KEYS.NAME, C.KEYS.PATIENT_ID])
    ),
    reduxActions
)(PatientName)

export const HeaderInfo = connect(state => ({
    nextAppointment: getResourceData(
        C.NAME,
        data => data[C.KEYS.NEXT_APPOINTMENT]
    )(state),
    canAccessCosts: moduleEnabledSelector(MODULE_IDS.COST_UTILIZATION)(state)
}))(PatientHeaderInfo)
