import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { RFCheckBoxTreeComponent } from "../../core/RFCheckBoxTree"
import { Label } from "../../core/CustomFields"
import { getValidProps } from "../../core/helpers"
import { categoryLeavesSelector } from "../selectors"
import { CheckAllButton } from "./CheckAllButton"

export const CategoryTree = ({
    className,
    input,
    leaves,
    meta,
    style,
    ...props
}) => (
    <div
        className={classNames(
            "category-tree",
            { "category-tree-none_checked": _.isEmpty(input.value) },
            className
        )}
        style={style}
    >
        <div className="category-tree-header">
            <Label {...getValidProps(input, meta)} />
            <CheckAllButton
                value={input.value}
                onChange={input.onChange}
                allValues={leaves}
                noneIsAll
            />
        </div>
        <RFCheckBoxTreeComponent input={input} {...props} />
    </div>
)

export default connect(state => ({ leaves: categoryLeavesSelector(state) }))(
    CategoryTree
)
