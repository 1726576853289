import { all, call, put, select, takeEvery } from "redux-saga/effects"
import moment from "moment"

import { modifyData, modifyResource } from "../core/fetcher"
import { ApiTypes, downloadFile, openFileInNewTab, tryFetch } from "../api"
import { TIMESTAMP_STRING_COMPACT } from "../dates"
import { notifySimpleError } from "../notifications"
import { urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"

import * as C from "./constants"
import { clearError, updateError } from "./actions"
import { acceptResponse, filtersSelector } from "./selectors"
import { updateLocalTcm } from "./helpers"

const updateTcms = updater => data => acceptResponse(updater(data))

const startTcmRemoval = id =>
    put(
        modifyResource({
            name: C.NAME,
            dataTransform: updateTcms(
                updateLocalTcm({
                    changingStatus: true
                })(id)
            )
        })
    )

export function* updateTcm(action) {
    const { transitionId, tcmId, field, value } = action.payload

    yield* tryFetch(
        {
            url: `/api/tcm/${tcmId}`,
            method: value ? ApiTypes.PUT : ApiTypes.DELETE,
            body: { field, value }
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: updateTcms(
                        updateLocalTcm({
                            [field]: value
                        })(transitionId)
                    )
                })
            )
            yield put(clearError(action.payload))
        },
        function*(error) {
            yield notifySimpleError("Failed to update TCM")(error)
            yield put(updateError({ error, ...action.payload }))
        }
    )
}

export function* restoreTcm({ transitionId }) {
    yield* tcmStatusRequest(
        transitionId,
        C.STATUS_VALUES.A,
        notifySimpleError("Failed to restore TCM")
    )
}

export function* removeTcm({ transitionId }) {
    yield* tcmStatusRequest(
        transitionId,
        C.STATUS_VALUES.R,
        notifySimpleError("Failed to remove TCM")
    )
}

export function* setTcmStatus({ status, transitionId }) {
    yield* tcmStatusRequest(transitionId, status, function*(error) {
        yield notifySimpleError("Failed to update TCM status")(error)
        yield put(
            updateError({
                error,
                field: C.TABLE_KEYS.STATUS,
                transitionId
            })
        )
    })
}

function* tcmStatusRequest(transitionId, status, failure) {
    yield startTcmRemoval(transitionId)
    yield* tryFetch(
        {
            url: `/api/tcm/${transitionId}/status`,
            method: ApiTypes.PUT,
            body: status
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: updateTcms(
                        updateLocalTcm({
                            [C.TABLE_KEYS.STATUS]: status,
                            changingStatus: false
                        })(transitionId)
                    )
                })
            )
        },
        failure
    )
}

export function* exportTcm() {
    const columns = yield select(
        getSortedColumns(
            C.NAME,
            item => !!item.selected && item.key !== C.TABLE_KEYS.REMOVE
        )
    )
    const filters = yield select(filtersSelector)
    const details = { ...columns, ...filters }
    const endpoint = urlWithParams(`/api/tcm/csv`, details)
    const filename = `tcm_${moment().format(TIMESTAMP_STRING_COMPACT)}.csv`
    yield* downloadFile("TCM CSV", endpoint, filename)
}

export function* downloadCDAXML({ transitionId }) {
    yield* downloadFile(
        "CCDA XML",
        `/api/tcm/${transitionId}/xml`,
        `ccda-${transitionId}.xml`
    )
}
export function* downloadCDAHTML({ transitionId }) {
    yield* downloadFile(
        "CCDA HTML",
        `/api/tcm/${transitionId}/html`,
        `ccda-${transitionId}.html`,
        {
            onSuccess: openFileInNewTab
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.REMOVE_TCM, removeTcm)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_TCM, restoreTcm)
        }),
        call(function*() {
            yield takeEvery(C.UPDATE_TCM, updateTcm)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_TCM, exportTcm)
        }),
        call(function*() {
            yield takeEvery(C.SET_TCM_STATUS, setTcmStatus)
        }),
        call(function*() {
            yield takeEvery(C.DOWNLOAD_CDA_XML, downloadCDAXML)
        }),
        call(function*() {
            yield takeEvery(C.DOWNLOAD_CDA_HTML, downloadCDAHTML)
        })
    ])
}
