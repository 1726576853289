import _ from "lodash"
import * as C from "./constants"

export const isUserInPractice = (user, practice) =>
    _.includes(
        user[C.USER_FIELDS.USER_UNITS],
        (practice ?? user)[C.PRACTICE_TABLE_KEYS.PRACTICE_ID]
    )

export const isUserAdminInPractice = (user, practice) =>
    _.includes(
        user[C.USER_FIELDS.ADMIN_UNITS],
        (practice ?? user)[C.PRACTICE_TABLE_KEYS.PRACTICE_ID]
    )

export const isVirtualPractice = row =>
    row[C.PRACTICE_TABLE_KEYS.PRACTICE_ID] === 0

export const isDisabled = (row, field = C.USER_TABLE_KEYS.STATUS) =>
    row[field] !== C.ACTIVE
