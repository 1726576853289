import * as C from "./constants"

export const showCareManagerModal = payload => ({
    type: C.SHOW_EDIT_CARE_MANAGER,
    payload
})
export const hideCareManagerModal = () => ({ type: C.HIDE_EDIT_CARE_MANAGER })

export const saveCareManager = payload => ({
    type: C.SAVE_CARE_MANAGER,
    payload
})
export const stopSavingCareManager = () => ({
    type: C.STOP_SAVING_CARE_MANAGER
})
export const updateInitialValues = payload => ({
    type: C.UPDATE_INITIAL_VALUES,
    payload
})
