export const NAME = "PATIENT_CONDITIONS"
export const GEN_CONDITIONS = "GEN_CONDITIONS"
export const EXCLUSIONS = "EXCLUSIONS"

export const REMOVE_CONDITION = "REMOVE_CONDITION"
export const RESTORE_CONDITION = "RESTORE_CONDITION"
export const REMOVE_GEN_CONDITION = "REMOVE_GEN_CONDITION"
export const RESTORE_GEN_CONDITION = "RESTORE_GEN_CONDITION"

export const TABLE_KEYS = {
    CONDITION_ID: "conditionId",
    CONDITION_NAME: "conditionName",
    GEN_CONDITION_ID: "genConditionId",
    GEN_CONDITION_NAME: "genConditionName",
    DIAGNOSIS_CODE: "code",
    DESCRIPTION: "descMed",
    CONTEXT: "context",
    HCC_RISK: "hccRisk",
    PROVIDER_NAME: "providerName",
    FIRST_IDENTIFIED_DATE: "firstIdentifiedDt",
    LAST_IDENTIFIED_DATE: "lastIdentifiedDt",
    NEXT_DUE_DATE: "nextDueDt",
    MET: "met",
    REMOVE: "remove",
    REMOVED: "removed",
    REMOVED_DT: "removedDt",
    EXCL_REASON: "exclReason",
    MEASURES_AFFECTED: "measuresAffected"
}

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.CONDITION_NAME]: "Condition Name",
    [TABLE_KEYS.GEN_CONDITION_NAME]: "Condition Name",
    [TABLE_KEYS.DIAGNOSIS_CODE]: "Code",
    [TABLE_KEYS.DESCRIPTION]: "Description",
    [TABLE_KEYS.CONTEXT]: "Context",
    [TABLE_KEYS.HCC_RISK]: "HCC Weight",
    [TABLE_KEYS.PROVIDER_NAME]: "Provider",
    [TABLE_KEYS.LAST_IDENTIFIED_DATE]: "Last Identified",
    [TABLE_KEYS.REMOVE]: "Remove",
    [TABLE_KEYS.EXCL_REASON]: "Exclusion Reason",
    [TABLE_KEYS.MEASURES_AFFECTED]: "Measures Affected"
}
