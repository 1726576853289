import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import * as PC from "../../patient_list/constants"
import { encodeFiltersInUrl, matchesSearch } from "../../utils"

const getPhysicianFilter = physician => ({
    [PC.FILTERS.PCPS]: [physician.providerId],
    [PC.FILTERS.TYPE]: PC.FILTER_TYPES.PATIENT_SUMMARY
})
const getClinicFilter = clinic => ({
    [PC.FILTERS.PRACTICE_UNITS]: [clinic.practiceUnitId],
    [PC.FILTERS.TYPE]: PC.FILTER_TYPES.PATIENT_SUMMARY
})

const PhysicianLink = ({ physician }) => {
    const url = useMemo(
        () =>
            physician.canViewPanel
                ? encodeFiltersInUrl("/patients", getPhysicianFilter(physician))
                : undefined,
        [physician]
    )
    const children = (
        <>
            {physician.providerName}
            <span className="patient-count">({physician.numPatients})</span>
        </>
    )
    return physician.canViewPanel ? (
        <Link to={url} className="clinic-physician">
            {children}
        </Link>
    ) : (
        <div className="clinic-physician">{children}</div>
    )
}

const PracticeUnit = ({ filter, unit }) => {
    const clinicUrl = useMemo(
        () => encodeFiltersInUrl("/patients", getClinicFilter(unit)),
        [unit]
    )

    return (
        <li>
            <Link className="clinic-name" to={clinicUrl}>
                <span className="patient-count">({unit.numPatients})</span>
                {unit.practiceName}
            </Link>
            {unit.physicians
                .filter(item => matchesSearch(item.providerName, filter))
                .map(physician => (
                    <PhysicianLink
                        key={`${physician.providerId}-${physician.isSpecialist}`}
                        physician={physician}
                    />
                ))}
        </li>
    )
}

export default PracticeUnit
