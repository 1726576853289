import React, { useMemo } from "react"
import { Card } from "react-bootstrap"
import classNames from "classnames"
import _ from "lodash"
import LEHOC from "../../core/LoadingErrorHOC"
import Chart from "../../core/Chart"

const PRODUCT_COLORS = {
    COMMERCIAL: "red",
    MEDICARE: "blue",
    MEDICAID: "green"
}
const DEFAULT_COLOR = "#999"

const getColor = (label, colors) => {
    const originalLabel = label.replace(/ \(\d+\)/, "")
    return (
        colors[originalLabel] ?? PRODUCT_COLORS[originalLabel] ?? DEFAULT_COLOR
    )
}

// noinspection SpellCheckingInspection
const getConfig = (config, onUpdate) =>
    _.merge(
        {
            legend: {
                item: {
                    onclick: function(id) {
                        // can't be an arrow function because of the this-binding
                        onUpdate(id)
                    }
                }
            }
        },
        config
    )

const getData = (data, headers, names, colors) =>
    _.merge(
        {
            keys: {
                value: headers
            },
            names,
            color: (bbDefaultColor, rawLabel) =>
                getColor(rawLabel.id || rawLabel, colors) // yeah, bbDefaultColor is never used
        },
        data
    )

export const ProductChart = ({
    colors,
    config,
    data,
    headers,
    name,
    names,
    loading,
    error,
    onUpdate,
    forwardRef
}) => {
    const chartConfig = useMemo(() => getConfig(config, onUpdate), [
        config,
        onUpdate
    ])

    const chartData = useMemo(() => getData(data, headers, names, colors), [
        data,
        headers,
        names,
        colors
    ])

    return (
        <Card.Body>
            <LEHOC loading={loading} error={error} list={headers}>
                <Chart
                    ref={forwardRef}
                    className={classNames("cost-chart", name)}
                    data={chartData}
                    config={chartConfig}
                />
            </LEHOC>
        </Card.Body>
    )
}

export default ProductChart
