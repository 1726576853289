import { createSelector } from "reselect"
import { routeModules } from "../../routes"
import { searchFlagsSelector } from "../patient_list/selectors"
import { areModulesEnabledSelector } from "../moduleUtils"
import * as C from "./constants"

const navItemIsActive = selector => item => {
    return selector(routeModules[item.href]) && selector(item)
}

export const navItemsSelector = createSelector(
    areModulesEnabledSelector(
        item => item?.requireAllModules,
        item => item?.module
    ),
    areModulesEnabled =>
        C.NAV_FORMAT.filter(navItemIsActive(areModulesEnabled)).map(
            navItem => ({
                ...navItem,
                children: navItem.children?.filter(
                    navItemIsActive(areModulesEnabled)
                )
            })
        )
)

export const initialValuesSelector = createSelector(
    searchFlagsSelector,
    searchFlags => ({ ...C.initialValues, ...searchFlags })
)
