import React, { useEffect, useState } from "react"
import classNames from "classnames"
import _ from "lodash"
import { Hoverlay } from "../../core/Hoverlay"

import spinner from "../../../assets/images/loading.gif"

export const PermissionItem = ({ item, buttons, onClick, className }) => {
    // nice highlight and fade styling when moved
    const [saved, setSaved] = useState(false)
    useEffect(() => {
        if (item.saving) {
            setSaved(true)
        }
    }, [item.saving])

    const innerStateClass = item.saving
        ? "permission-item-saving"
        : item.error
        ? "permission-item-error"
        : saved
        ? "permission-item-saved"
        : ""

    return (
        <div className={classNames("permission-item", className)}>
            <div
                className={classNames("permission-item-inner", innerStateClass)}
            >
                <span className="permission-item-label">{item.label}</span>

                {item.saving ? (
                    <span className="permission-item-spinner">
                        <img src={spinner} alt="Saving..." />
                    </span>
                ) : (
                    (buttons || []).map((button, i) => {
                        const isDisabled = _.get(
                            item.disabled,
                            button.privilege,
                            button.disabled
                        )
                        // TODO if there's a button.title then the tooltip should be styled a little differently, to distinguish between the tooltips explaining the action and the ones explaining why it's disabled.
                        // TODO also the tooltips should probably be on the icon instead of the cell
                        return (
                            <Hoverlay
                                key={i}
                                tooltip={isDisabled || button.title || ""}
                                disabled={isDisabled && !_.isString(isDisabled)}
                            >
                                <button
                                    className={classNames(
                                        "fa permission-item-button btn-blank",
                                        button.className,
                                        isDisabled ? "disabled" : null
                                    )}
                                    disabled={isDisabled}
                                    onClick={() =>
                                        isDisabled
                                            ? null
                                            : onClick(button.privilege)(item.id)
                                    }
                                />
                            </Hoverlay>
                        )
                    })
                )}
            </div>
        </div>
    )
}
