import React from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { FavoriteItem } from "./FavoriteItem"
import * as C from "../constants"

export const FavoritesButton = ({
    list,
    onClick,
    selected,
    selectFavorite
}) => (
    <DropdownButton
        size="sm"
        variant={null}
        title={<span className="fa fa-star-o clickable-icon" />}
        className="filter-favorites-dropdown"
    >
        <Dropdown.Header>Favorite Filters</Dropdown.Header>
        {list
            .filter(item => !item[C.TABLE_KEYS.DELETED])
            .map(item => (
                <FavoriteItem
                    key={item[C.TABLE_KEYS.ID]}
                    item={item}
                    onClick={selectFavorite}
                    active={
                        selected?.[C.TABLE_KEYS.ID] === item[C.TABLE_KEYS.ID]
                    }
                />
            ))}
        <Dropdown.Divider />
        <Dropdown.Item onClick={onClick}>
            <span className="fa fa-gear" /> Manage
        </Dropdown.Item>
    </DropdownButton>
)
