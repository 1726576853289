import { createSelector } from "reselect"
import moment from "moment"
import _ from "lodash"
import {
    getMetaData,
    getResourceData,
    getResourceDataAsObject
} from "../core/fetcher"
import {
    addPath,
    getColumnKeys,
    getCopyFields,
    getSelectedColumns
} from "../table/helpers"
import { TABLE_STORE } from "../table/constants"
import {
    categoriesSelector,
    doubleChartCombiner,
    subcategoriesSelector
} from "../cost/selectors"
import { MONTH_STRING } from "../dates"
import { itemsToObject, matchesSearch } from "../utils"
import { FILTER_OPTIONS } from "../constants"
import * as C from "./constants"
import { COST_COLUMN_AGGREGATORS } from "./columns"

// derive additional field values for rows

const divideColumns = (row, numer, denom) =>
    row[denom] ? row[numer] / row[denom] : null

const costPercent = (row, numer, denom) =>
    row[denom] ? (100 * row[numer]) / row[denom] : null

const getMonthSpan = filters => {
    const start = moment.utc(filters[C.FILTERS.START_DATE], MONTH_STRING)
    const end = moment
        .utc(filters[C.FILTERS.END_DATE], MONTH_STRING)
        .endOf("month")
    const duration = moment.duration(end.diff(start))
    return Math.max(Math.round(duration.asMonths()), 1)
    // the Math.max is probably unnecessary, but just in case
}

const addMonths = months => row => ({
    ...row,
    [C.TABLE_KEYS.MONTHS]: months
})

const addAllocation = row => ({
    ...row,
    [C.TABLE_KEYS.TOTAL_ALLOCATION]:
        row[C.TABLE_KEYS.TOTAL_NETWORK] *
        row[C.TABLE_KEYS.RELATIVE_RISK] *
        divideColumns(
            row,
            C.TABLE_KEYS.MEMBER_MONTHS,
            C.TABLE_KEYS.NETWORK_MEMBER_MONTHS
        )
})

const derivePmpmColumns = row => ({
    ...row,
    [C.TABLE_KEYS.PMPM_COST]: divideColumns(
        row,
        C.TABLE_KEYS.TOTAL_COST,
        C.TABLE_KEYS.MEMBER_MONTHS
    ),
    [C.TABLE_KEYS.PMPM_ALLOCATION]: divideColumns(
        row,
        C.TABLE_KEYS.TOTAL_ALLOCATION,
        C.TABLE_KEYS.MEMBER_MONTHS
    ),
    [C.TABLE_KEYS.PMPM_NETWORK]: divideColumns(
        row,
        C.TABLE_KEYS.TOTAL_NETWORK,
        C.TABLE_KEYS.NETWORK_MEMBER_MONTHS
    ),
    [C.TABLE_KEYS.PATIENT_COUNT]: divideColumns(
        row,
        C.TABLE_KEYS.MEMBER_MONTHS,
        C.TABLE_KEYS.MONTHS
    )
})

// the fields in this function are all derived from the ones calculated in derivePmpmColumns(), which is why it's a separate function
const derivePercentColumns = row => ({
    ...row,
    [C.TABLE_KEYS.ALLOCATION_PERCENT]: costPercent(
        row,
        C.TABLE_KEYS.PMPM_COST,
        C.TABLE_KEYS.PMPM_ALLOCATION
    ),
    [C.TABLE_KEYS.NETWORK_PERCENT]: costPercent(
        row,
        C.TABLE_KEYS.PMPM_COST,
        C.TABLE_KEYS.PMPM_NETWORK
    ),
    [C.TABLE_KEYS.TARGET]: Math.max(
        row[C.TABLE_KEYS.PMPM_ALLOCATION],
        row[C.TABLE_KEYS.PMPM_NETWORK]
    )
})

const deriveColumns = row => derivePercentColumns(derivePmpmColumns(row))

// derive row stamps/paths

const keysToStamp = (keys, depth) => row =>
    _.compact(_.at(row, keys).slice(0, depth)).join(".")

// calculate values for summary rows

const aggregate = (rows, depth, columnKeys, view) => {
    // aggregate the numeric fields
    const sums = _.mapValues(COST_COLUMN_AGGREGATORS, (aggregator, key) =>
        aggregator(
            rows.map(row => row[key]),
            rows,
            { view, depth }
        )
    )

    // copy over the non-numeric fields (and also months and network member months, which also stay the same throughout)
    const copy = _.isEmpty(rows)
        ? {}
        : _.pick(_.head(rows), [
              C.TABLE_KEYS.MONTHS,
              C.TABLE_KEYS.NETWORK_MEMBER_MONTHS,
              ...getCopyFields(columnKeys, depth)
          ])

    // calculate derived columns as well
    const derived = deriveColumns({ ...copy, ...sums })

    // and finally add the path
    return addPath(derived, columnKeys)
}

const summarizeRows = (rows, table, view) => {
    const { columns = [], frozenColumns = [] } = table || {}
    const selectedColumns = getSelectedColumns([...frozenColumns, ...columns])
    const columnKeys = getColumnKeys(selectedColumns)
    const { pivotNameKeys, pivotIdKeys } = columnKeys

    const stampedRows = rows.map(row => addPath(row, columnKeys))

    // summarize the rows, then summarize the summaries, and so on
    const summaries = _.reduce(
        pivotIdKeys,
        (result, pivotId, level) => {
            const depth = pivotIdKeys.length - level
            const grouped = _.groupBy(
                _.head(result),
                keysToStamp(columnKeys.pivotIdKeys, depth)
            )
            const summary = Object.values(grouped).map(group =>
                aggregate(group, depth, columnKeys, view)
            )
            return [summary, ...result]
        },
        [stampedRows]
    )

    // add a "Total" row
    const total = aggregate(_.head(summaries), 0, columnKeys, view)
    total[pivotNameKeys[0]] = "Total" // We reuse the highest-level pivot column instead of adding a new column just for Total

    return [total, ...summaries.flat()]
}

// the actual cost report table selector

export const costTableDataSelector = createSelector(
    getResourceData(C.NAME),
    state => state[C.NAME].filters,
    state => state[TABLE_STORE],
    (data, filters, tables) => {
        const months = getMonthSpan(filters)
        const rows = (data?.rows || [])
            .map(addMonths(months))
            .map(addAllocation)
            .map(deriveColumns)

        return itemsToObject(
            C.VIEWS,
            view => view,
            view => summarizeRows(rows, tables[C.VIEW_TABLES[view]], view)
        )
    }
)

export const costTableRowsSelector = createSelector(
    costTableDataSelector,
    state => state[C.NAME].view,
    (rowViews, view) => rowViews[view]
)

// cost chart selector

export const costChartDataSelector = resourceName =>
    createSelector(
        getResourceDataAsObject(resourceName),
        getMetaData(resourceName),
        getMetaData(FILTER_OPTIONS),
        categoriesSelector,
        doubleChartCombiner
    )

// procedure table selectors

const getProcedureRow = (categoryLookup, subcategoryLookup) => procedure => ({
    ...procedure,
    [C.TABLE_KEYS.CATEGORY_NAME]:
        categoryLookup[procedure[C.TABLE_KEYS.CATEGORY_ID]],
    [C.TABLE_KEYS.SUBCATEGORY_NAME]:
        subcategoryLookup[procedure[C.TABLE_KEYS.SUBCATEGORY_ID]],
    [C.TABLE_KEYS.PROCEDURE_NAME]: procedure[C.TABLE_KEYS.PROCEDURE_NAME]
        ? `${procedure[C.TABLE_KEYS.PROCEDURE_NAME]} (${
              procedure[C.TABLE_KEYS.PROCEDURE_ID]
          })`
        : procedure[C.TABLE_KEYS.PROCEDURE_ID], // if the name is blank, we should at least return the procedure id
    [C.TABLE_KEYS.SERVICING_PROVIDER_NAME]:
        (procedure[C.TABLE_KEYS.SERVICING_PROVIDER_NAME] || "").trim() ||
        "(unknown)",
    [C.TABLE_KEYS.PATH]: _.compact([
        [
            procedure[C.TABLE_KEYS.CATEGORY_ID],
            procedure[C.TABLE_KEYS.SUBCATEGORY_ID],
            procedure[C.TABLE_KEYS.PROCEDURE_ID]
        ].join("_"),
        procedure[C.TABLE_KEYS.SERVICING_PROVIDER_ID]
    ]),
    isLeaf: !!procedure[C.TABLE_KEYS.SERVICING_PROVIDER_ID]
})

export const proceduresDataSelector = createSelector(
    getResourceData(C.PROCEDURES_TABLE),
    categoriesSelector,
    subcategoriesSelector,
    (state, searchTerm) => searchTerm,
    (data, categoryLookup, subcategoryLookup, searchTerm) =>
        (data.procedures || [])
            .filter(
                procedure =>
                    !searchTerm ||
                    matchesSearch(
                        procedure[C.TABLE_KEYS.PROCEDURE_NAME],
                        searchTerm
                    ) ||
                    matchesSearch(
                        procedure[C.TABLE_KEYS.PROCEDURE_ID],
                        searchTerm
                    )
            )
            .map(getProcedureRow(categoryLookup, subcategoryLookup))
)
