import React from "react"

import { connect } from "react-redux"
import * as C from "../../my_account/constants"
import * as reduxActions from "../../my_account/actions"
import MyAccountModal from "../../my_account/components"

const MyAccountLink = ({ showMyProfile }) => (
    <li className="nav-item dropdown-item">
        <button onClick={showMyProfile}>My Account</button>
        <MyAccountModal />
    </li>
)

export default connect(
    state => ({
        show: state[C.MY_PROFILE_NAME].open
    }),
    reduxActions
)(MyAccountLink)
