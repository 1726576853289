import React from "react"
import _ from "lodash"
import TableSettings from "../../table_settings/components/TableSettings"
import { connectTable, getSelectedColumns } from "../helpers"
import { TableDescription, connectDescription } from "./TableDescription"
import RV from "./RV"

/** Notes on extraFilter:
 *
 * Passing an extraFilter prop has different effects based on whether you pass
 * it to Settings or to Table.
 * If you pass extraFilter to Settings, it just deselects columns that don't
 * match. They can be re-selected manually, however.
 * If you pass extraFilter to Table, columns that don't match will be completely
 * hidden, even if they've been manually selected. This is primarily used on
 * tiered tables.
 *
 * If you pass Table or Settings an extraFilter prop, it should be a function
 * in the format (column, rows, columns) => boolean. If you're passing to Settings
 * and you actually use the `rows` parameter in the filter, make sure you pass
 * `rows` to Settings as well.
 * */

export const applyExtraFilter = (extraFilter, rows) => (col, i, cols) =>
    extraFilter?.(col, rows, cols) ?? true

export const tableFactory = factoryProps => {
    const tableConnector = connectTable(factoryProps)
    const descConnector = connectDescription(factoryProps.name)

    return {
        Table: tableConnector(Table),
        Settings: tableConnector(Settings),
        Description: descConnector(TableDescription)
    }
}

export const Table = props => {
    // hide columns that aren't selected or don't match extraFilter
    const getFilteredColumns = columns =>
        getSelectedColumns(columns).filter(
            applyExtraFilter(props.extraFilter, props.list)
        )

    return (
        <RV
            {...props}
            columns={getFilteredColumns(props.columns)}
            frozenColumns={getFilteredColumns(props.frozenColumns)}
        />
    )
}

export const Settings = props => {
    // deselect columns that don't match extraFilter
    const selectColumns = columns =>
        _.map(columns, (col, i, cols) => ({
            ...col,
            selected:
                col.forceSelected ||
                (col.selected &&
                    applyExtraFilter(props.extraFilter, props.list)(
                        col,
                        i,
                        cols
                    ))
        })) // TODO unify this with Table.getFilteredColumns better

    return (
        <TableSettings
            {...props}
            columns={selectColumns(props.columns)}
            frozenColumns={selectColumns(props.frozenColumns)}
        />
    )
}
