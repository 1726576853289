import React, { useCallback } from "react"
import { Card } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import { getRowClass } from "../../table"

// TODO this could be merged back into QSR.jsx pretty easily

const grc = getRowClass(0)

const QSRBody = ({
    Table,
    expanded,
    setExpanded,
    activeFilters,
    rows,
    loading,
    error
}) => {
    const extraFilter = useCallback(
        column => !activeFilters.includes(column.key),
        [activeFilters]
    )
    return (
        <Card.Body className="qsr-body">
            <LEHOC list={rows} loading={loading} error={error}>
                <Table
                    list={rows}
                    expanded={expanded}
                    onExpanded={setExpanded}
                    extraFilter={extraFilter}
                    getRowClass={grc}
                />
                <div className="custom_scrollbar">
                    Swipe the table left and right to view hidden columns
                </div>
            </LEHOC>
        </Card.Body>
    )
}

export default QSRBody
