import { createReducer } from "../utils"
import * as C from "./constants"

export const filter_reducer = createReducer(C.initialValues, {
    [C.SET_PATIENT_FILTER]: (state, { filters }) => ({
        ...C.blankValues,
        ...filters
    })
})

export const generate_patient_list_reducer = createReducer(
    { show: false },
    {
        [C.HIDE_GENERATE_PL]: () => ({ show: false }),
        [C.SHOW_GENERATE_PL]: () => ({ show: true })
    }
)
