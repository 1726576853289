import React, { Component } from "react"
import _ from "lodash"

import LEHOC from "../../core/LoadingErrorHOC"

import * as C from "../constants"
import { FavoriteFiltersModal } from "./FavoriteFiltersModal"
import { AddFavoriteModal } from "./AddFavoriteModal"
import { FiltersModal } from "./FiltersModal"
import { FiltersButton } from "./FiltersButton"
import { FavoritesButton } from "./FavoritesButton"
import { FavoriteNameModal } from "./FavoriteNameModal"

export class FiltersContainer extends Component {
    openFiltersModal = () => this.props.setShowFiltersModal(this.props.form)
    closeFiltersModal = () => this.props.setShowFiltersModal(null)
    openFavoritesModal = () => this.props.setShowFavoritesModal(this.props.form)
    closeFavoritesModal = () => {
        this.clearFavoriteName()
        this.props.removeDeletedFavorites(this.props.form)
        this.props.setShowFavoritesModal(null)
    }
    openAddFavoriteModal = () =>
        this.props.setShowEditFavoriteModal(this.props.form)
    closeAddFavoriteModal = () => {
        this.resetForm()
        this.props.setShowEditFavoriteModal(null)
    }
    openFavoriteNameModal = () => {
        this.clearFavoriteName()
        this.props.setShowFavoriteNameModal(this.props.form)
    }
    closeFavoriteNameModal = () => this.props.setShowFavoriteNameModal(null)

    editFavorite = row => {
        this.props.initialize({
            [C.FILTERS.NAME]: row[C.TABLE_KEYS.NAME],
            [C.FILTERS.ID]: row[C.TABLE_KEYS.ID],
            ...row.filters
        })
        this.openAddFavoriteModal()
    }
    addNewFavorite = () => {
        this.clearFavoriteName()
        this.openAddFavoriteModal()
    }

    clearFavoriteName = () => {
        // get rid of the last entered set's name without resetting anything else
        this.props.change(C.FILTERS.NAME, "")
        this.props.untouch(C.FILTERS.NAME)
    }

    submit = e => {
        this.props.submit() // this mainly just calls props.setFilters, and unsets the selected favorite if the filters no longer match
        this.closeFiltersModal()
        this.closeFavoriteNameModal()
        e?.preventDefault() // prevents submit on hitting Enter, because it reloads the page and puts the form data in the URL. Neither of which we want.
    }

    resolveRows = () =>
        _.map(this.props.favorites, row => ({
            ...row,
            lookup: this.props.lookup,
            translation: this.props.translation
        }))

    resetForm = () => {
        this.props.initialize(this.props.emptyValues || {})
    }
    selectFavorite = item => {
        this.props.selectFavoriteFilters(this.props.form, item)
        this.props.initialize(item.filters)
        this.props.setFilters?.(item.filters) // setFilters is passed into the top-level DashboardFilters component, since each module stores its filters separately
    }

    saveFavoriteFilters = updatingSelected =>
        this.props.saveFavoriteFilters(
            this.props.form,
            this.props.favoritesIgnoreFields,
            updatingSelected
        )

    tableData = {
        onEdit: this.editFavorite,
        onDelete: this.props.deleteFavoriteFilters,
        onUndelete: this.props.unDeleteFavoriteFilters
    }

    render() {
        const list = this.resolveRows()

        const FormComponent = this.props.formComponent ?? (() => null)

        return (
            <>
                <FiltersButton onClick={this.openFiltersModal} />

                {/* just the filters */}
                <FiltersModal
                    {...this.props}
                    show={
                        this.props.showFiltersModal === this.props.form &&
                        !this.props.showFavoriteNameModal
                    }
                    closeModal={this.closeFiltersModal}
                    openNameModal={this.openFavoriteNameModal}
                    submit={this.submit}
                    resetForm={this.resetForm}
                >
                    <LEHOC {...this.props.meta}>
                        <FormComponent {...this.props} />
                    </LEHOC>
                </FiltersModal>

                <FavoritesButton
                    list={list}
                    selected={this.props.selectedFilters[this.props.form]}
                    selectFavorite={this.selectFavorite}
                    onClick={this.openFavoritesModal}
                />

                {/* lists all your favorite filter sets */}
                <FavoriteFiltersModal
                    show={
                        this.props.showFavoritesModal === this.props.form &&
                        !this.props.showEditFavoriteModal &&
                        !this.props.showFavoriteNameModal
                    }
                    onHide={this.closeFavoritesModal}
                    onAddNew={this.addNewFavorite}
                    selectFavorite={this.selectFavorite}
                    form={this.props.form}
                    list={list}
                    tableData={this.tableData}
                />

                {/* filters plus name, for when you add or edit a new filter set from FavoriteFiltersModal */}
                <AddFavoriteModal
                    {...this.props}
                    show={this.props.showEditFavoriteModal === this.props.form}
                    closeModal={this.closeAddFavoriteModal}
                    existingNames={this.props.existingNames}
                    submit={() => this.saveFavoriteFilters(false)}
                    resetForm={this.resetForm}
                >
                    <LEHOC {...this.props.meta}>
                        <FormComponent {...this.props} />
                    </LEHOC>
                </AddFavoriteModal>

                {/* just the name, for when you're in FiltersModal and want to save your current filters as a new filter set */}
                <FavoriteNameModal
                    {...this.props}
                    saveFavoriteFilters={this.saveFavoriteFilters}
                    show={this.props.showFavoriteNameModal === this.props.form}
                    closeModal={this.closeFavoriteNameModal}
                    existingNames={this.props.existingNames}
                    submit={this.submit}
                />
            </>
        )
    }
}
