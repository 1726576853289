import React from "react"
import { Hoverlay } from "../../core/Hoverlay"

const NonCompliant = ({ message, ...props }) => (
    <Hoverlay
        className="tooltip-red"
        tooltip={message || "Not compliant"} /*XXX or should it be "Overdue"?*/
    >
        <span className="fa indicator-overdue" {...props} />
    </Hoverlay>
)

export default NonCompliant
