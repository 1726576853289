import { all, call, select, takeEvery } from "redux-saga/effects"
import { requestQSRReport } from "../qsr/saga"
import { getFilename } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import { getTableName } from "./helpers"
import * as C from "./constants"

const getQsrDetails = state => {
    const { filters, aggregation } = state[C.NAME]
    const tableName = getTableName(aggregation)
    const sortedColumns = getSortedColumns(tableName)(state)
    return {
        ...filters,
        aggregation,
        ...sortedColumns
    }
}

function* getDashboardQsrReport(extension) {
    const details = yield select(getQsrDetails)

    yield* requestQSRReport(
        details,
        `QSR ${details.aggregation} summary ${extension.toUpperCase()}`,
        `/api/qsr/dashboard/${details.aggregation}/${extension}`,
        getFilename(`qsr_${details.aggregation}s`, extension)
    )
}

export function* printQSR() {
    yield* getDashboardQsrReport("pdf")
}

export function* exportQSR() {
    yield* getDashboardQsrReport("csv")
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.PRINT_QSR_DASHBOARD, printQSR)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_QSR_DASHBOARD, exportQSR)
        })
    ])
}
