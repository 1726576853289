import React from "react"
import _ from "lodash"
import Loading from "../../core/Loading"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import { urlWithParams } from "../../utils"
import { getChartFilters } from "../helpers"
import * as C from "../constants"
import QsrChart from "./QsrChart"

export const ChartLoader = props =>
    props.loading ? (
        <div className="qsr-performance-placeholder">
            <Loading />
        </div>
    ) : props.error ? (
        <div className="qsr-performance-placeholder">
            Uh oh, an error has occured
        </div>
    ) : _.isEmpty(props.data.currentYear) &&
      (!props.showPrior || _.isEmpty(props.data.priorYear)) ? (
        <div className="qsr-performance-placeholder">
            There is no data to display.
        </div>
    ) : (
        <QsrChart {...props} />
    )

export default fetcher({
    name: C.PERFORMANCE_NAME,
    endpoint: state =>
        urlWithParams(`/api/qsr/performance`, getChartFilters(state[C.NAME])),
    method: ApiTypes.GET
})(ChartLoader)
