import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { FormSection } from "redux-form"
import _ from "lodash"
import * as C from "../constants"

export const SettingsFieldset = ({
    children,
    name,
    className,
    colClassName = "",
    component = Row,
    noSection = false,
    ...props
}) => {
    const [nonSectionedChildren, sectionedChildren] = _.partition(
        React.Children.toArray(children),
        child => child.props.noSection
    )

    return (
        <Col
            className={colClassName}
            sm={12}
            {..._.pull(props, ["xs", "sm", "md", "lg", "xl"])}
        >
            <Card className={className} as="fieldset">
                <legend>{C.SITE_SETTINGS_SECTIONS_TRANSLATION[name]}</legend>
                {noSection ? (
                    children
                ) : (
                    <React.Fragment>
                        <FormSection name={name} component={component}>
                            {sectionedChildren}
                        </FormSection>
                        {nonSectionedChildren}
                    </React.Fragment>
                )}
            </Card>
        </Col>
    )
}
