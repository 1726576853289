import React from "react"
import { Button, FormGroup } from "react-bootstrap"
import classNames from "classnames"
import _ from "lodash"
import { getValidProps } from "../../core/helpers"

const getModuleLabel = ({ description, moduleName }) => (
    <React.Fragment>
        <b>{moduleName}</b> <span>({description})</span>
    </React.Fragment>
)

export const ModulePicker = ({
    input,
    meta,
    module,
    roles,
    error,
    disabled,
    className
}) => {
    const { value: curValue, onChange } = getValidProps(input, meta)

    const handleChange = ({ target: { checked, value } }) => {
        const changedId = Number(value) // because the values for checkboxes are stored as strings
        const newValue = checked
            ? _.uniq([...curValue, changedId])
            : _.without(curValue, changedId)
        onChange(newValue)
    }

    return (
        <FormGroup
            className={classNames(
                {
                    filter_field_error: error && !disabled
                },
                className
            )}
        >
            <p>{getModuleLabel(module)}</p>

            <div>
                <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => onChange([])}
                >
                    None
                </Button>
            </div>

            {roles.map(({ name, roleId }) => (
                <div key={roleId} className="cell">
                    <label aria-label={name}>
                        <input
                            type="checkbox"
                            value={roleId}
                            checked={curValue.includes(roleId)}
                            onChange={handleChange}
                        />
                    </label>
                </div>
            ))}
        </FormGroup>
    )
}
