import { connect } from "react-redux"
import _ from "lodash"
import fetcher, { getMetaData } from "../../core/fetcher"
import { urlWithParams } from "../../utils"
import { FILTER_OPTIONS } from "../../constants"
import * as C from "../constants"
import {
    acceptResponse,
    canWriteTcmSelector,
    filtersSelector,
    tcmsSelector
} from "../selectors"
import * as reduxActions from "../actions"
import TCM, { DEFAULT_ORDER_BYS } from "./TCM"

const defaultOrderBys = _.zipWith(
    [DEFAULT_ORDER_BYS.sortBy, DEFAULT_ORDER_BYS.sortDirection],
    (column, direction) => ({ column, direction })
)
export default fetcher({
    name: C.NAME,
    endpoint: state => urlWithParams("/api/tcm", filtersSelector(state)),
    defaultOrderBys,
    paginationKey: "tcms",
    acceptResponse
})(
    connect(
        (state, { loading }) => ({
            filters: state[C.NAME].filters,
            canEdit: canWriteTcmSelector(state),
            tcms: tcmsSelector(state),
            loading: loading || getMetaData(FILTER_OPTIONS)(state).loading // don't finish loading until the filter options are loaded too
        }),
        reduxActions
    )(TCM)
)
