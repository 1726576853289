import { delay } from "redux-saga"
import { all, call, put, race, takeEvery } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"
import { getStoredState } from "redux-persist"
import { asyncLocalStorage } from "redux-persist/storages"
import moment from "moment"
import { ApiTypes, DEFAULT_HEADERS, tryFetch } from "../api"
import { parseDate, TIMESTAMP_STRING } from "../dates"
import { errorNotification, tryWithNotifications } from "../notifications"
import * as C from "./constants"
import {
    displayPasswordReset,
    forceLogoutAction,
    loginErrorAction,
    loginSuccessAction,
    updatePasswordToken
} from "./actions"

export const loginEndpoint = body =>
    fetch("/login", {
        method: ApiTypes.POST,
        headers: DEFAULT_HEADERS,
        body: JSON.stringify(body)
    })

export function* checkCredentials(loginRequest) {
    const res = yield call(loginEndpoint, loginRequest)
    if (res.status === 401) {
        throw new Error("Incorrect username or password")
    }
    return yield res.json()
}

export function validate(username, password) {
    if (username === "") throw new Error("Username cannot be empty")
    if (password === "") throw new Error("Password cannot be empty")
    if (username.length > 50)
        throw new Error("Username must be less than 50 characters")
    if (password.length > 50)
        throw new Error("Password must be less than 50 characters")
}

export function* loginFlow({ username, password }) {
    const loginId = String(username || "").toLowerCase() // Cast to string is probably unnecessary, but just in case
    try {
        const now = Date.now()
        localStorage.setItem("lastAction", now.toString())
        validate(loginId, password)
        const body = yield call(checkCredentials, { loginId, password })
        yield put(Notifications.hide("force-logout-notification-id"))
        yield put(loginSuccessAction({ loginId, ...body }))
    } catch (error) {
        yield put(loginErrorAction())
        yield put(
            Notifications.error({
                title: error.message,
                position: "tc",
                autoDismiss: 5
            })
        )
    }
}

export function* logoutFlow() {
    yield put(Notifications.removeAll())
}

export function* forcedLogout({ title, message }) {
    yield put(Notifications.removeAll())
    yield put(
        Notifications.error({
            uid: "force-logout-notification-id",
            title,
            message,
            position: "tc",
            autoDismiss: 0
        })
    )
}

export function* callStatusEndpoint() {
    return yield fetch("/status", {
        method: ApiTypes.GET,
        headers: DEFAULT_HEADERS
    })
}

export function* checkStatus() {
    try {
        const { res, timer } = yield race({
            res: call(callStatusEndpoint),
            timer: call(delay, C.MS_TO_WAIT_FOR_WEBSERVICE_RESPONSE)
        })
        if (timer || res.status !== 200) {
            yield put({
                type: C.SYSTEM_ERROR,
                payload: "Web service did not respond with 200"
            })
        }

        const payload = yield res.json()

        yield put({
            type: "PAGE_REFRESHED",
            payload
        })

        if (!payload.dbSuccess) {
            yield put({
                type: C.SYSTEM_ERROR,
                payload: `DB Status: DOWN`
            })
        }
    } catch (error) {
        yield put({
            type: C.SYSTEM_ERROR,
            payload: "Web service did not respond with 200"
        })
    }
}

export function* errorHandle() {
    yield takeEvery(
        [
            action =>
                action.type.includes("ERROR") && action.type !== C.SYSTEM_ERROR,
            "PAGE_LOAD"
        ],
        checkStatus
    )
}

export function* updatePassword(action) {
    yield* tryWithNotifications(
        {
            url: "/api/users/me/password",
            method: ApiTypes.PUT,
            body: action.payload
        },
        function*({ authToken }) {
            yield updateStoredAuthToken(authToken)
            yield put(displayPasswordReset(false))
        },
        "Failed to update password. Please contact an administrator."
    )
}

export function* updateStoredAuthToken(authToken) {
    if (authToken) {
        yield put(
            updatePasswordToken({
                authToken
            })
        )
    } else {
        yield put(
            Notifications.warning(
                errorNotification({
                    title:
                        "Password was updated, but you are still logged in with your old password. Please log out and back in again."
                })
            )
        )
    }
}

export function* refreshPasswordToken() {
    // skip checking what's in our actual store; local storage has either those values, or a newer set of values fetched by a different tab
    const storedState = yield call(getStoredState, {
        storage: asyncLocalStorage
    })
    const storedAuth = storedState[C.NAME]
    const { nextRefresh, refreshToken } = storedAuth ?? {}

    if (!nextRefresh) return // we just logged in, and local storage hasn't updated yet. No need for a refresh

    console.debug(`Checking for authentication refresh`)

    if (moment().isBefore(parseDate(nextRefresh, TIMESTAMP_STRING))) {
        console.debug(`No refresh needed until ${nextRefresh}`)
        yield put(updatePasswordToken(storedAuth))
        return
    }

    yield* tryFetch(
        {
            url: "/api/refresh",
            method: ApiTypes.POST,
            body: refreshToken
        },
        function*(response) {
            console.debug(
                `Authentication refreshed! Next refresh will be after ${response.nextRefresh}`
            )
            yield put(updatePasswordToken(response))
        },
        function*() {
            yield put(
                forceLogoutAction({
                    title:
                        "Your authentication has expired, and you have been logged out."
                })
            )
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.LOGIN_REQUEST, loginFlow)
        }),
        call(function*() {
            yield takeEvery(C.LOGOUT, logoutFlow)
        }),
        call(function*() {
            yield takeEvery(C.FORCE_LOGOUT, forcedLogout)
        }),
        call(function*() {
            yield takeEvery(
                [
                    action =>
                        action.type.includes("ERROR") &&
                        action.type !== C.SYSTEM_ERROR,
                    "PAGE_LOAD"
                ],
                checkStatus
            )
        }),
        call(function*() {
            yield takeEvery(C.RESET_PASSWORD_SUBMIT, updatePassword)
        }),
        call(function*() {
            yield takeEvery(C.REFRESH_PASSWORD_TOKEN, refreshPasswordToken)
        })
    ])
}
