import * as C from "./constants"

export const showBatchPrint = () => ({ type: C.SHOW_BATCH_PRINT })
export const hideBatchPrint = () => ({ type: C.HIDE_BATCH_PRINT })
export const showPocPrint = () => ({ type: C.SHOW_POC_PRINT })
export const hidePocPrint = () => ({ type: C.HIDE_POC_PRINT })
export const submitBatchPrint = payload => ({
    type: C.SUBMIT_BATCH_PRINT,
    payload
})
export const submitPocPrint = payload => ({
    type: C.SUBMIT_POC_PRINT,
    payload
})
export const submitPrintSuccess = () => ({
    type: C.SUBMIT_PRINT_SUCCESS
})
export const submitPrintError = payload => ({
    type: C.SUBMIT_PRINT_ERROR,
    payload
})
export const updateBatchPrintPercentage = payload => ({
    type: C.UPDATE_BATCH_PRINT_PERCENTAGE,
    payload
})
