import React, { useState } from "react"
import _ from "lodash"
import { Button, Card, Form } from "react-bootstrap"
import { useIncrease } from "../../hooks"
import * as C from "../constants"
import LER from "../../core/LoadingErrorHOC"

export const AuditFiles = ({
    Description,
    Table,
    canEdit,
    files,
    downloadFile,
    uploadFiles,
    updateDescription,
    loading,
    error,
    emptyMessage
}) => {
    const [filesToUpload, setFilesToUpload] = useState([])
    const [descText, setDescText] = useState("")

    const reset = () => {
        setFilesToUpload([])
        setDescText("")
    }

    // if a new file was added, clear inputs
    useIncrease(files?.length, reset)

    const filenames = _.join(
        _.map(filesToUpload, file => file.name),
        ", "
    )

    return (
        <>
            <LER list={files} loading={loading} error={error} hideEmpty>
                <Description />
                <Card className="card-table">
                    <Card.Body>
                        <Table
                            list={files}
                            rowClassNames={{
                                "audit-file-deleted": rowData =>
                                    rowData[C.TABLE_KEYS.DELETED]
                            }}
                            tableData={{
                                downloadFile,
                                canEdit,
                                updateDescription
                            }}
                        />
                    </Card.Body>
                </Card>
            </LER>
            {canEdit && (
                <div className="audit-files-new">
                    <h4>Upload New File(s)</h4>
                    <Form.File
                        multiple
                        custom
                        label={filenames || "Choose files"}
                        onChange={event => {
                            setFilesToUpload(event.target.files)
                        }}
                    />

                    {!_.isEmpty(filesToUpload) && (
                        <>
                            <textarea
                                rows={1}
                                value={descText}
                                placeholder="Description"
                                className="form-control"
                                onChange={event =>
                                    setDescText(event.target.value)
                                }
                            />
                            {filesToUpload.length > 1 && !!descText && (
                                <p className="audit-files-warning">
                                    All files in this upload batch will use this
                                    description. You can change them
                                    individually afterwards.
                                </p>
                            )}
                            <div className="audit-file-buttons">
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() =>
                                        uploadFiles(filesToUpload, descText)
                                    }
                                >
                                    Upload
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={reset}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
            {!canEdit && !files && emptyMessage && <div>{emptyMessage}</div>}
        </>
    )
}
