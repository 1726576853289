import { connect } from "react-redux"
import { reduxForm } from "redux-form"

import fetcher, { getResourceData } from "../../../core/fetcher"
import { urlWithParams } from "../../../utils"
import { ApiTypes } from "../../../api"
import { FILTER_OPTIONS } from "../../../constants"
import { practiceTagsSelector } from "../../tagSelectors"
import { practiceInitialValuesSelector } from "../../modalSelectors"
import * as C from "../../constants"
import * as actions from "../../actions"
import { PracticeModal } from "./PracticeModal"

const editPracticeTagsSelector = practiceTagsSelector(C.EDIT_PRACTICE_UNIT_FORM)

const EditPracticeModalForm = reduxForm({
    form: C.EDIT_PRACTICE_UNIT_FORM,
    enableReinitialize: true
})(PracticeModal)

const EditPracticeModalConnect = connect(
    state => ({
        label: "Save",
        regionOptions: getResourceData(
            FILTER_OPTIONS,
            data => data.regions
        )(state),
        tags: editPracticeTagsSelector(state),
        initialValues: practiceInitialValuesSelector(state)
    }),
    {
        onHide: actions.hideEditPracticeModal,
        onSubmit: actions.updatePractice
    }
)(EditPracticeModalForm)

export default fetcher({
    name: C.PRACTICE_TAGS,
    method: ApiTypes.GET,
    endpoint: state =>
        urlWithParams("api/tags/practices", {
            unitIds: [state[C.ADMIN_NAME].selectedPractice.id]
        })
})(EditPracticeModalConnect)
