import * as C from "./constants"

export const updatePatientInfo = payload => ({
    type: C.UPDATE_PATIENT_INFO,
    payload
})
export const updatePatientInfoSuccess = () => ({
    type: C.UPDATE_PATIENT_INFO_SUCCESS
})
export const showEditPatientInfo = () => ({ type: C.SHOW_EDIT_PATIENT_INFO })
export const hideEditPatientInfo = () => ({ type: C.HIDE_EDIT_PATIENT_INFO })

export const deactivatePatient = payload => ({
    type: C.DEACTIVATE_CURRENT_PATIENT,
    payload
})
export const showDeactivatePatient = () => ({ type: C.SHOW_DEACTIVATE_PATIENT })
export const hideDeactivatePatient = () => ({ type: C.HIDE_DEACTIVATE_PATIENT })

export const reactivatePatient = () => ({
    type: C.REACTIVATE_CURRENT_PATIENT
})
