import React from "react"
import SearchBar from "../../core/SearchBar"

const SearchBox = props => (
    <SearchBar
        id="search"
        buttonType="submit"
        className="form-group"
        {...props}
    />
)

export default SearchBox
