export const PAGE_MOUNTED = "PAGE_MOUNTED"
export const SET_PATIENT_ID = "SET_PATIENT_ID"
export const STORE_NAV_DIMENSIONS = "STORE_NAV_DIMENSIONS"

export const NOTIFICATION_CONFIG = {
    Containers: {
        tl: {
            width: "auto",
            left: 0,
            right: 0
        },
        DefaultStyle: {
            top: 50
        }
    },
    NotificationItem: {
        DefaultStyle: {
            margin: "10px 5px 2px 1px"
        },
        info: {
            borderTop: 0,
            color: "white",
            padding: 0
        }
    },
    Title: {
        info: {
            color: "black"
        },
        warning: {
            color: "#ff995c"
        }
    },
    Action: {
        info: {
            backgroundColor: "#4b8fdd",
            color: "#ffffff"
        }
    }
}
