import * as components from "./components"
import * as constants from "./constants"
import reducer from "./reducer"
import * as saga from "./saga"

/* I used to think that this module should be merged into the `table` module. Then I tried it, and it didn't work.
 * The problem is that the two modules have separate stores that do different things.
 * `table` stores the current state of the table, including all of the possible columns. Each column is an object with several fields. i.e. [{key, label, selected}, ...]
 * `table_settings` stores the data fetched from the database about the user's preferences for which columns to show in each table, expressed as a list containing the names of the selected columns. i.e. [key1, key2, ...]
 * A key consideration is that `table_settings` is fetched on initial load, whereas `table` is loaded piecemeal as each table is mounted.
 * This makes it difficult to keep both of them in the same store. Better to keep them separate after all.
 */
export default { components, constants, reducer, saga }
