import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import { urlWithParams } from "../../utils"
import { moduleEnabledSelector } from "../../moduleUtils"
import { MODULE_IDS } from "../../constants"
import * as C from "../constants"
import * as reduxActions from "../actions"
import { filtersSelector, tocsSelector } from "../selectors"
import { addTimestamp } from "../helpers"
import TOC from "./TocList"

export default fetcher(
    {
        name: C.NAME,
        endpoint: state => urlWithParams("/api/toc", filtersSelector(state)),
        paginationKey: "tocs",
        acceptResponse: addTimestamp
    },
    reduxActions
)(
    connect(
        (state, { isSmall }) => ({
            ...state[C.NAME],
            data: tocsSelector(state),
            canRead: moduleEnabledSelector(MODULE_IDS.TCM_READ)(state),
            canEdit: moduleEnabledSelector(MODULE_IDS.TCM_WRITE)(state)
        }),
        reduxActions
    )(TOC)
)
