import React from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import fetcher, { getMetaData, getResourceData } from "../../core/fetcher"
import { ApiTypes } from "../../api"
import { getSpaceLeftBy } from "../../utils"
import * as C from "../constants"
import Conditions from "./Conditions"
import Exclusions from "./Exclusions"
import GenConditions from "./GenConditions"

const ConditionsTab = ({
    hccConditions = [],
    genConditions = [],
    exclusions = [],
    conditionsMeta,
    exclusionsMeta
}) => (
    <Card id="conditions">
        <Conditions
            data={hccConditions}
            {...conditionsMeta}
            maxLength={getSpaceLeftBy([...genConditions, ...exclusions])}
        />
        <GenConditions
            data={genConditions}
            {...conditionsMeta}
            maxLength={getSpaceLeftBy([...hccConditions, ...exclusions])}
        />
        <Exclusions
            data={exclusions}
            {...exclusionsMeta}
            maxLength={getSpaceLeftBy([...hccConditions, ...genConditions])}
        />
    </Card>
)

const flagRemovedConditions = cond => ({
    ...cond,
    [C.TABLE_KEYS.REMOVED]: !!cond[C.TABLE_KEYS.REMOVED_DT]
})

export default fetcher({
    name: C.NAME,
    endpoint: state => `/api/patients/${state.poc_id}/conditions`,
    method: ApiTypes.GET,
    acceptResponse: data => ({
        ...data,
        hccConditions: data.hccConditions?.map(flagRemovedConditions) ?? [],
        genConditions: data.genConditions?.map(flagRemovedConditions) ?? []
    })
})(
    fetcher({
        name: C.EXCLUSIONS,
        endpoint: ({ poc_id }) => `/api/patients/${poc_id}/exclusions`,
        method: ApiTypes.GET
    })(
        connect(state => ({
            ...getResourceData(C.NAME)(state),
            ...getResourceData(C.EXCLUSIONS)(state),
            conditionsMeta: getMetaData(C.NAME)(state),
            exclusionsMeta: getMetaData(C.EXCLUSIONS)(state)
        }))(ConditionsTab)
    )
)
