import * as PLC from "../patient_list/constants"

export const NAME = "TOC"
export const TOC_TCM = "TOC_TCM"
export const TOC_FILTERS_FORM = "TOC_FILTERS_FORM"
export const SAVE_TOC_FILTERS = "SAVE_TOC_FILTERS"
export const EXPORT_TOC = "EXPORT_TOC"
export const PRINT_TOC = "PRINT_TOC"

export const TABLE_KEYS = {
    PATIENT_NAME: "patName",
    PATIENT_ID: "patientId",
    EVENT_TYPE: "eventType",
    TRANSITION_DATE: "admitDt",
    DISCHARGE_DATE: "dischargeDt",
    LENGTH_OF_STAY: "duration",
    PATIENT_DOB: "patDob",
    PATIENT_DOD: "patDod",
    PATIENT_PCP: "providerName",
    PRACTICE_NAME: "practiceUnitName",
    TAGS: "tags",
    TRANSITION_FACILITY: "facilityName",
    TRANSITION_DIAGNOSIS: "diagDesc",
    GRACE_PERIOD: "patInGracePrd",
    BILLABLE: "isBillable",
    EDITABLE: "editable",
    RISK_RANK: "patRiskNum",
    RISK_RANK_LABEL: "patRiskRank",
    AT_RISK: "patAtRisk",
    LACE_SCORE: "patLaceScore",
    LACE_RISK: "patLaceRisk",
    LOAD_DATE: "loadDt",
    ADMIT_DESCRIPTION: "admitStatusDesc",
    DISCHARGE_DESCRIPTION: "dischargeStatusDesc",
    PRODUCT_CLASS: "productClassName",
    PAYER_ID: "payerId",
    PAYER_NAME: "payerName",
    PAYER_IDS: "payerIds",
    CMI: "patCmIncentive",
    CM_IDS: "cmUserIds",
    CM_NAMES: "cmNames",
    GEN_CONDITIONS: "genConditions",
    LAST_APT_DT: "patLastAptDt",
    NEXT_APT_DT: "patNextAptDt",
    TCM: "tcm",
    FLAG_ICONS: "flagIcons",
    FLAG_COLORS: "flagColors",
    FLAG_IMAGES: "flagImages",
    FLAG_DESCRIPTIONS: "flagDescriptions",
    CCDA: "hasCda"
}

export const LABEL_TRANSLATION = {
    [TABLE_KEYS.PATIENT_NAME]: "Name",
    [TABLE_KEYS.EVENT_TYPE]: "Event Type",
    [TABLE_KEYS.TRANSITION_DATE]: "Admission",
    [TABLE_KEYS.DISCHARGE_DATE]: "Discharge",
    [TABLE_KEYS.LENGTH_OF_STAY]: "LOS",
    [TABLE_KEYS.PATIENT_DOB]: "DOB",
    [TABLE_KEYS.RISK_RANK]: "Risk Rank",
    [TABLE_KEYS.FLAG_ICONS]: "At Risk",
    [TABLE_KEYS.LACE_SCORE]: "LACE Score",
    [TABLE_KEYS.PRODUCT_CLASS]: "Product Class",
    [TABLE_KEYS.PAYER_NAME]: "Payer",
    [TABLE_KEYS.PAYER_IDS]: "Coverage",
    [TABLE_KEYS.PATIENT_PCP]: "PCP",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice",
    [TABLE_KEYS.TRANSITION_FACILITY]: "Facility",
    [TABLE_KEYS.TRANSITION_DIAGNOSIS]: "Diagnosis",
    [TABLE_KEYS.ADMIT_DESCRIPTION]: "Admission Description",
    [TABLE_KEYS.DISCHARGE_DESCRIPTION]: "Discharge Description",
    [TABLE_KEYS.LOAD_DATE]: "Load Date",
    [TABLE_KEYS.CMI]: "CMI",
    [TABLE_KEYS.CM_NAMES]: "Care Manager(s)",
    [TABLE_KEYS.GEN_CONDITIONS]: "General Conditions",
    [TABLE_KEYS.TAGS]: "Tags",
    [TABLE_KEYS.LAST_APT_DT]: "Last Appt",
    [TABLE_KEYS.NEXT_APT_DT]: "Next Appt",
    [TABLE_KEYS.EDITABLE]: "TCM",
    [TABLE_KEYS.CCDA]: "CCDA"
}

export const HOVER_TRANSLATION = {
    [TABLE_KEYS.EVENT_TYPE]: "Transition of Care Type",
    [TABLE_KEYS.TRANSITION_DATE]: "Admission Date",
    [TABLE_KEYS.DISCHARGE_DATE]: "Discharge Date",
    [TABLE_KEYS.PATIENT_DOB]: "Date of Birth",
    [TABLE_KEYS.CMI]: "Care Management Incentive",
    [TABLE_KEYS.LAST_APT_DT]: "Last Appointment",
    [TABLE_KEYS.NEXT_APT_DT]: "Next Appointment",
    [TABLE_KEYS.EDITABLE]: "Edit TCM"
}

export const FILTERS = {
    ...PLC.FILTERS,
    PATIENT_NAME: "patientName",
    ADMIT_DT_FROM: "admitDtFrom",
    ADMIT_DT_TO: "admitDtTo",
    DISCHARGE_DT_FROM: "dischargeDtFrom",
    DISCHARGE_DT_TO: "dischargeDtTo",
    FACILITY: "facilityIds",
    EVENT_TYPE: "eventType",
    GEN_CONDITIONS: "genConditions",
    DISCHARGED: "discharged",
    ADMIT_DESCRIPTION: "admitDescription",
    DISCHARGE_DESCRIPTION: "dischargeDescription",
    DECEASED: "deceased",
    STATUS: "tcmStatus"
}
export const FILTER_TRANSLATION = {
    ...PLC.FILTER_TRANSLATION,
    [FILTERS.PATIENT_NAME]: "Patient Name",
    [FILTERS.ADMIT_DT_FROM]: "Admit Date From",
    [FILTERS.ADMIT_DT_TO]: "Admit Date To",
    [FILTERS.DISCHARGE_DT_FROM]: "Discharge Date From",
    [FILTERS.DISCHARGE_DT_TO]: "Discharge Date To",
    [FILTERS.FACILITY]: "Facilities",
    [FILTERS.EVENT_TYPE]: "Encounter Type(s)",
    [FILTERS.GEN_CONDITIONS]: "General Condition(s)",
    [FILTERS.DISCHARGED]: "Discharged",
    [FILTERS.ADMIT_DESCRIPTION]: "Admission Description",
    [FILTERS.DISCHARGE_DESCRIPTION]: "Discharge Description",
    [FILTERS.STATUS]: "Status"
}

export const DATE_FILTERS = [
    FILTERS.ADMIT_DT_FROM,
    FILTERS.ADMIT_DT_TO,
    FILTERS.DISCHARGE_DT_FROM,
    FILTERS.DISCHARGE_DT_TO
]

export const EVENT_TYPES = [
    { value: "IP", label: "Inpatient Facility (IP)" },
    { value: "ED", label: "Emergency Department (ED)" },
    { value: "SN", label: "Skilled Nursing Facility (SN)" },
    { value: "OB", label: "Observation (OB)" }
]

export const TASK_STATUS = {
    CREATED: "CREATED",
    RUNNING: "RUNNING",
    COMPLETE: "COMPLETE",
    FAILED: "FAILED"
}

export const initialState = { filters: { [FILTERS.DISCHARGE_DESCRIPTION]: [] } } // need to specify an initial value, or else it'll get mad about switching from an uncontrolled to a controlled input
