import * as TCM_CONSTANTS from "../tcm/constants"

export const NAME = "ATTENTION"

export const TCM_POPOVER_FORM = "TCM_POPOVER_FORM"
export const PRIORITY_ATTENTION_TCM_FORM = "PRIORITY_ATTENTION_TCM"

export const LOAD_PA_TCM = "LOAD_PA_TCM"
export const SAVE_PA_TCM = "SAVE_PA_TCM"
export const TCM_UPDATE_ERROR = "TCM_UPDATE_ERROR"

export const REMOVE_PA = "REMOVE_PA"
export const RESTORE_PA = "RESTORE_PA"

export const TCM_LOOKUP = TCM_CONSTANTS.TCM_LOOKUP

export const TABLE_KEYS = {
    TCM_TRACKER_ID: "transitionId",
    EVENT: "event",
    ADMIT_DATE: "admitDt",
    DISCHARGE_DATE: "dischargeDt",
    DISCHARGE_FACILITY: "facilityName",
    DIAGNOSIS: "diagnosis",
    EDIT_TCM: "editTcm",
    EDITABLE: "isEditable",
    REMOVE: "remove",
    REMOVED: "removed"
}

export const TRANSLATIONS = {
    [TABLE_KEYS.EVENT]: "Event",
    [TABLE_KEYS.ADMIT_DATE]: "Admit",
    [TABLE_KEYS.DISCHARGE_DATE]: "Discharge",
    [TABLE_KEYS.DISCHARGE_FACILITY]: "Facility",
    [TABLE_KEYS.DIAGNOSIS]: "Message / Status / Diagnosis",
    [TABLE_KEYS.EDIT_TCM]: "TCM",
    [TABLE_KEYS.REMOVE]: "Remove"
}

export const ENTRY_FIELDS = [
    TCM_CONSTANTS.TABLE_KEYS.FOLLOW_UP,
    TCM_CONSTANTS.TABLE_KEYS.MED_RECONCILIATION,
    TCM_CONSTANTS.TABLE_KEYS.OFFICE_VISIT,
    TCM_CONSTANTS.TABLE_KEYS.BILLED
]

export const TCM_TRANSLATION = {
    [TCM_CONSTANTS.TABLE_KEYS.FOLLOW_UP]: "Phone Call",
    [TCM_CONSTANTS.TABLE_KEYS.MED_RECONCILIATION]: "Medication Reconciliation",
    [TCM_CONSTANTS.TABLE_KEYS.OFFICE_VISIT]: "Office Visit",
    [TCM_CONSTANTS.TABLE_KEYS.BILLED]: "Billed"
}
