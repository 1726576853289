import React from "react"
import { Row } from "react-bootstrap"
import { reduxForm } from "redux-form"
import * as C from "../constants"
import TabChooser from "./TabChooser"
import Filters from "./Filters"

const UtilizationDetailsForm = ({ tab }) => (
    <form className="card-header backgroundBlue utilization-details-filters">
        <Row>
            <Filters tab={tab} />
        </Row>
    </form>
)

export default reduxForm({
    form: C.UTILIZATION_DETAILS_FORM
})(UtilizationDetailsForm)
