import _ from "lodash"
import { createSelector } from "reselect"
import { getMetaData, getResourceData } from "../core/fetcher"
import { moduleEnabledSelector } from "../moduleUtils"
import { MODULE_IDS } from "../constants"
import * as C from "./constants"

const PAYER_ID = "payerId"

export const canDeactivate = createSelector(
    moduleEnabledSelector(MODULE_IDS.DEACTIVATE_PATIENT),
    getResourceData(C.NAME, data => data[C.KEYS.COVERAGES]),
    getMetaData(C.NAME),
    (moduleEnabled, coverages, { loading, error }) =>
        moduleEnabled &&
        !loading &&
        !error &&
        (_.isEmpty(coverages) ||
            coverages.every(coverage => !coverage[PAYER_ID]))
)
