import React from "react"
import { Hoverlay } from "../../core/Hoverlay"

const GracePeriod = props => (
    <Hoverlay className="tooltip-orange" tooltip="In grace period">
        <span className="fa indicator-grace_period" {...props} />
    </Hoverlay>
)

export default GracePeriod
