import {
    DischargeTimestamp,
    FormattedDate,
    ListCell,
    LongCell,
    PatientName,
    Coverage,
    RiskRank,
    TimestampDate,
    formatYesNo,
    Payer
} from "../core/CellRenderers"
import CDALink from "../tcm/components/CDALink"
import { AtRisk } from "../indicators"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"
import TocTCMPopover from "./components/TocTCMPopover"
import { getLaceIndex, LaceScore } from "./components/LaceScore"

// TODO these constants could be useful in a lot of places too. Move to a higher-level constants file.
const DATE_WIDTH = 100
const TIMESTAMP_WIDTH = 160

const column = (key, opts) =>
    columnBuilder(C.LABEL_TRANSLATION)(key, {
        hover: C.HOVER_TRANSLATION[key],
        ...opts
    })

const formatLengthOfStay = hours =>
    !hours
        ? ""
        : hours === 1
        ? "1 hour"
        : hours < 24
        ? hours + " hours"
        : hours < 48
        ? "1 day"
        : Math.floor(hours / 24) + " days"

export const TABLE_COLUMNS = [
    column(C.TABLE_KEYS.PATIENT_NAME, {
        cellRenderer: PatientName(
            C.TABLE_KEYS.PATIENT_ID,
            C.TABLE_KEYS.BILLABLE
        ),
        width: 200,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.EVENT_TYPE, {
        width: 80
    }),
    column(C.TABLE_KEYS.TRANSITION_DATE, {
        cellRenderer: TimestampDate,
        width: TIMESTAMP_WIDTH
    }),
    column(C.TABLE_KEYS.DISCHARGE_DATE, {
        cellRenderer: DischargeTimestamp(C.TABLE_KEYS.PATIENT_DOD),
        width: TIMESTAMP_WIDTH
    }),
    column(C.TABLE_KEYS.LENGTH_OF_STAY, {
        hover: "Length of Stay",
        cellFormatter: formatLengthOfStay,
        selected: false,
        width: 75
    }),
    column(C.TABLE_KEYS.CCDA, {
        cellRenderer: CDALink,
        width: 65
    }),
    column(C.TABLE_KEYS.PATIENT_DOB, {
        cellRenderer: FormattedDate,
        width: DATE_WIDTH
    }),
    column(C.TABLE_KEYS.RISK_RANK, {
        cellRenderer: RiskRank,
        columnData: {
            patientKey: C.TABLE_KEYS.PATIENT_ID,
            rankKey: C.TABLE_KEYS.RISK_RANK_LABEL
        },
        width: 100
    }),
    column(C.TABLE_KEYS.LACE_SCORE, {
        selected: false,
        dataGetter: getLaceIndex,
        cellRenderer: LaceScore,
        width: 100
    }),
    column(C.TABLE_KEYS.FLAG_ICONS, {
        selected: true,
        align: ALIGNMENTS.CENTER,
        cellRenderer: AtRisk(C.TABLE_KEYS.AT_RISK),
        width: 70
    }),
    column(C.TABLE_KEYS.PAYER_NAME, {
        selected: false,
        idField: C.TABLE_KEYS.PAYER_ID,
        cellRenderer: Payer,
        width: 200,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRODUCT_CLASS, {
        selected: false,
        width: 130,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PAYER_IDS, {
        selected: false,
        cellRenderer: Coverage,
        width: 140
    }),
    column(C.TABLE_KEYS.PATIENT_PCP, {
        width: 180,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        width: 180,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.TAGS, {
        width: 180,
        flexGrow: 1,
        cellRenderer: ListCell
    }),
    column(C.TABLE_KEYS.TRANSITION_FACILITY, {
        width: 270,
        flexGrow: 2
    }),
    column(C.TABLE_KEYS.TRANSITION_DIAGNOSIS, {
        width: 270,
        flexGrow: 2,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.ADMIT_DESCRIPTION, {
        selected: false,
        width: 270,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.DISCHARGE_DESCRIPTION, {
        selected: false,
        width: 270,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.LOAD_DATE, {
        selected: false,
        cellRenderer: TimestampDate,
        width: TIMESTAMP_WIDTH
    }),
    column(C.TABLE_KEYS.CMI, {
        selected: false,
        cellFormatter: formatYesNo,
        width: 60
    }),
    column(C.TABLE_KEYS.CM_NAMES, {
        selected: false,
        cellRenderer: ListCell,
        width: 180,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.GEN_CONDITIONS, {
        selected: false,
        width: 160,
        flexGrow: 1,
        cellRenderer: ListCell
    }),
    column(C.TABLE_KEYS.LAST_APT_DT, {
        cellRenderer: TimestampDate,
        width: TIMESTAMP_WIDTH
    }),
    column(C.TABLE_KEYS.NEXT_APT_DT, {
        cellRenderer: TimestampDate,
        width: TIMESTAMP_WIDTH
    }),
    column(C.TABLE_KEYS.EDITABLE, {
        selected: true,
        width: 40,
        cellRenderer: TocTCMPopover
    })
]
