import React from "react"
import { connect } from "react-redux"
import { Button, Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import { Field, getFormValues, reduxForm } from "redux-form"
import { SearchBarField } from "../../core/SearchBar"
import { SelectField } from "../../core/CustomFields"
import LEHOC from "../../core/LoadingErrorHOC"
import { tableFactory } from "../../table"
import { matchesSearch, objectPop } from "../../utils"
import * as actions from "../actions"
import * as C from "../constants"
import CellRenderer from "./CellRenderer"

const { Table, Settings, Description } = tableFactory({
    name: C.LOCATION_INFO_TABLE,
    columns: C.TABLE_COLUMNS,
    sortBy: [C.TABLE_KEYS.LOCATION_NAME],
    sortDirection: [SortDirection.ASC]
})

const LocationList = ({
    communityLocations,
    counties,
    formValues,
    services,
    setLocationModeAdd
}) => {
    const selectFormValues = _.pickBy(formValues) // remove empties
    const locationName = objectPop(
        selectFormValues,
        C.FILTER_LOCATIONS_FIELDS.NAME
    )

    const list = (communityLocations || [])
        .filter(item =>
            matchesSearch(item[C.TABLE_KEYS.LOCATION_NAME], locationName)
        )
        .filter(item =>
            _.every(selectFormValues, (value, key) => item[key].includes(value))
        )

    const addLocation = () =>
        setLocationModeAdd({
            initialValues: {
                ..._.mapValues(selectFormValues, value => [value]),
                [C.ADD_COMMUNITY_LOCATION_FIELDS.NAME]: locationName,
                [C.ADD_COMMUNITY_LOCATION_FIELDS.COUNTRY]: C.USA,
                [C.ADD_COMMUNITY_LOCATION_FIELDS.STATE]: C.MICHIGAN
            }
        })

    return (
        <Card className="card-table" style={{ border: "none" }}>
            <Description />
            <div className="location-controls">
                <div className="location-filters">
                    <Field
                        name={C.FILTER_LOCATIONS_FIELDS.NAME}
                        component={SearchBarField}
                        placeholder="Search..."
                    />
                    <Field
                        name={C.FILTER_LOCATIONS_FIELDS.COUNTY}
                        component={SelectField}
                        options={counties}
                        placeholder="County"
                    />
                    <Field
                        name={C.FILTER_LOCATIONS_FIELDS.SERVICE}
                        component={SelectField}
                        options={services}
                        placeholder="Service"
                    />
                </div>

                <Button variant="secondary" size="sm" onClick={addLocation}>
                    Add Custom Location
                </Button>
                <Settings style={{ marginRight: 7 }} />
            </div>
            <Card.Body>
                <LEHOC loading={false} error={false} list={list}>
                    <Table list={list} cellRenderer={CellRenderer} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}

export default reduxForm({ form: C.FILTER_LOCATIONS_FORM })(
    connect(
        state => ({
            formValues: getFormValues(C.FILTER_LOCATIONS_FORM)(state) || {}
        }),
        actions
    )(LocationList)
)
