import { connect } from "react-redux"
import { PivotCell } from "../../core/CellRenderers"
import { productGroupSelector } from "../selectors"
import * as C from "../constants"

export default connect((state, { rowData }) => {
    const productGroup = productGroupSelector(state)(rowData)
    return {
        hoverlayProps: {
            tooltip: productGroup[C.TABLE_KEYS.PRODUCT_GROUP_DESC],
            tooltipColor: productGroup[C.TABLE_KEYS.PRODUCT_GROUP_COLOR]
        }
    }
})(PivotCell)
