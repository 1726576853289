import _ from "lodash"
import { intersectionExists, matchesSearch } from "../utils"

// not technically a selector, but close enough
export const filterPracticeUnits = (practice_units, filter, regionIds = []) =>
    _.filter(practice_units, unit => {
        const matchesPractice = matchesSearch(unit.practiceName, filter)
        const matchesPhysician = unit.physicians.find(physician =>
            matchesSearch(physician.providerName, filter)
        )
        const inRegions =
            _.isEmpty(regionIds) ||
            intersectionExists(unit.regionIds, regionIds)
        return (matchesPractice || matchesPhysician) && inRegions
    })
