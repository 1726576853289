import _ from "lodash"
import { SortDirection } from "react-virtualized"

import { mapValuesToObject, objToValueLabel, valueLabelToObj } from "../utils"
import { LOCAL, POPULATION_VALUES, YES_NO_VALUES } from "../constants"

export {
    POPULATION_VALUES,
    POPULATION_OPTIONS,
    YES_NO_OPTIONS,
    YES_NO_VALUES
} from "../constants"

export const NAME = "PATIENT_LIST"
export const PATIENT_COUNT = "PATIENT_COUNT"
export const PATIENT_LIST_COST_CHART = "PATIENT_LIST_COST_CHART"

// FORMS
export const GENERATE_PATIENT_LIST_FORM = "GENERATE_PATIENT_LIST_FORM"
export const PATIENT_LIST_FILTER_FORM = "PATIENT_LIST_FILTER_FORM"

// ACTIONS
export const GO_TO_PATIENT_FILTERS = "GO_TO_PATIENT_FILTERS" // This updates the URL, which eventually causes a SET_PATIENT_FILTER to be dispatched.
export const SET_PATIENT_FILTER = "SET_PATIENT_FILTER" // This updates the stored filters
export const EXPORT_PATIENT_LIST = "EXPORT_PATIENT_LIST"
export const PRINT_PATIENT_LIST = "PRINT_PATIENT_LIST"
export const HIDE_GENERATE_PL = "HIDE_GENERATE_PL"
export const SHOW_GENERATE_PL = "SHOW_GENERATE_PL"

// FILTERS AND COLUMNS AND ETC
export const FILTERS_SECTION = "filter"

export const FILTER_TYPES = {
    PATIENT_SUMMARY: "SUMMARY",
    PATIENT_MEASURES: "DETAIL"
}
export const FILTER_TYPES_INVERTED = _.invert(FILTER_TYPES)

export const FILTERS = {
    TYPE: "type",
    FUZZY_SEARCH: "fuzzySearch",
    PCPS: "pcps",
    PRACTICE_UNITS: "units",
    REGIONS: "regions",
    PAYERS: "payers",
    SOURCES: "sources",
    PRODUCTS: "productClasses",
    PRODUCT_GROUPS: "productGroups",
    TAGS: "tags",
    DOB_FROM: "dobFrom",
    DOB_TO: "dobTo",
    LAST_SERVICE_FROM: "measureLastServiceFrom",
    LAST_SERVICE_TO: "measureLastServiceTo",
    PATIENT_LAST_SERVICE_FROM: "patientLastServiceFrom",
    PATIENT_LAST_SERVICE_TO: "patientLastServiceTo",
    INCLUDE_UNSERVICED: "includeUnserviced",
    GENDER: "gender",
    RACE: "races",
    ETHNICITY: "ethnicities",
    CARE_MANAGEMENT: "careManagement",
    CARE_MANAGERS: "careManagers",
    RISK_RANK: "riskRank",
    RISK_TYPE: "riskType",
    ACUTE_ENCOUNTERS: "acuteEncounters",
    DUE_DATE_FROM: "dueDateFrom",
    DUE_DATE_TO: "dueDateTo",
    SCOPE: "scope",
    MEASURES: "measures",
    HCC_CONDITIONS: "hccConditions",
    GEN_CONDITIONS: "genConditions",
    HCC_CONDITIONS_MET: "hccConditionsMet",
    COMPLIANT: "compliant",
    COST: "cost",
    COST_MIN: "costMin",
    COST_MAX: "costMax",
    COST_SPAN: "costSpan",
    LAST_APPT_DATE_FROM: "lastApptDateFrom",
    LAST_APPT_DATE_TO: "lastApptDateTo",
    NEXT_APPT_DATE_FROM: "nextApptDateFrom",
    NEXT_APPT_DATE_TO: "nextApptDateTo",
    NEXT_APPT_DATE_DYNAMIC: "nextApptDateDynamic",
    APPOINTMENT_PROVIDERS: "appointmentProviders",
    APPOINTMENT_TYPES: "appointmentTypes",
    APPOINTMENT_STATUSES: "appointmentStatuses",
    CM_INCENTIVE: "patCmIncentive",
    POPULATION: "population",
    DECEASED: "deceased",
    GRACE_PERIOD: "patInGracePrd",
    INCENTIVE_STATUS: "measureIncentiveStatus",
    SEARCH_PATIENT_ID: "allowPatientId",
    SEARCH_CONTRACT_NUMBER: "allowContractNumber"
}

export const FILTER_TRANSLATION = {
    [FILTERS.FUZZY_SEARCH]: "Patient Name",
    [FILTERS.PCPS]: "Primary Care Provider(s)",
    [FILTERS.PRACTICE_UNITS]: "Practice Unit(s)",
    [FILTERS.REGIONS]: "Practice Tag(s)",
    [FILTERS.PAYERS]: "Payer(s)",
    [FILTERS.SOURCES]: "Other Coverage(s)",
    [FILTERS.PRODUCTS]: "Product Class(es)",
    [FILTERS.PRODUCT_GROUPS]: "Product Group(s)",
    [FILTERS.TAGS]: "Patient Tag(s)",
    [FILTERS.DOB_FROM]: "DOB From",
    [FILTERS.DOB_TO]: "DOB To",
    [FILTERS.LAST_SERVICE_FROM]: "Measure Last Service From",
    [FILTERS.LAST_SERVICE_TO]: "Measure Last Service To",
    [FILTERS.PATIENT_LAST_SERVICE_FROM]: "Patient Last Service From",
    [FILTERS.PATIENT_LAST_SERVICE_TO]: "Patient Last Service To",
    [FILTERS.INCLUDE_UNSERVICED]: "Include patients with no services?",
    [FILTERS.GENDER]: "Gender",
    [FILTERS.RACE]: "Race",
    [FILTERS.ETHNICITY]: "Ethnicity",
    [FILTERS.CARE_MANAGEMENT]: "Care Management",
    [FILTERS.CARE_MANAGERS]: "Care Manager(s)",
    [FILTERS.RISK_RANK]: "Risk Rank(s)",
    [FILTERS.RISK_TYPE]: "Risk Type(s)",
    [FILTERS.ACUTE_ENCOUNTERS]: "Acute Encounters",
    [FILTERS.DUE_DATE_FROM]: "Measure Due Date From",
    [FILTERS.DUE_DATE_TO]: "Measure Due Date To",
    [FILTERS.SCOPE]: "Scope",
    [FILTERS.TYPE]: "Patient List Type",
    [FILTERS.MEASURES]: "Measures",
    [FILTERS.HCC_CONDITIONS]: "HCC Conditions",
    [FILTERS.GEN_CONDITIONS]: "General Conditions",
    [FILTERS.HCC_CONDITIONS_MET]: "HCC Condition Compliance",
    [FILTERS.COMPLIANT]: "Measure Compliance",
    [FILTERS.COST]: "Cost",
    [FILTERS.COST_MIN]: "Min Cost",
    [FILTERS.COST_MAX]: "Max Cost",
    [FILTERS.COST_SPAN]: "Cost Period",
    [FILTERS.LAST_APPT_DATE_FROM]: "Last Appointment Date From",
    [FILTERS.LAST_APPT_DATE_TO]: "Last Appointment Date To",
    [FILTERS.NEXT_APPT_DATE_FROM]: "Next Appointment Date From",
    [FILTERS.NEXT_APPT_DATE_TO]: "Next Appointment Date To",
    [FILTERS.NEXT_APPT_DATE_DYNAMIC]: "Next Appointment Date",
    [FILTERS.APPOINTMENT_PROVIDERS]: "Next Appointment Provider",
    [FILTERS.APPOINTMENT_TYPES]: "Next Appointment Type",
    [FILTERS.APPOINTMENT_STATUSES]: "Next Appointment Status",
    [FILTERS.CM_INCENTIVE]: "CM Incentive",
    [FILTERS.POPULATION]: "Population",
    [FILTERS.DECEASED]: "Deceased",
    [FILTERS.GRACE_PERIOD]: "Patient in Grace Period",
    [FILTERS.INCENTIVE_STATUS]: "Incentive Status",
    [FILTERS.SEARCH_PATIENT_ID]: "/ Patient ID",
    [FILTERS.SEARCH_CONTRACT_NUMBER]: "/ Contract #"
}

/* Add nested version of the filter translations.
 * This looks pretty dumb, but it has a purpose: all of the form's filter values are stored in a single "filter" object field in the formValues.
 * That would be dumb too, *except* that the same filter panel gets reused in the campaigns module as part of a bigger form. And in *that* form, it's critical that the filters stay isolated from the other values, so that the filters can be wrapped up as a JSON string and converted to SQL.
 */
FILTER_TRANSLATION[FILTERS_SECTION] = { ...FILTER_TRANSLATION }

export const TABLE_KEYS = {
    PAYER_ID: "payerId",
    SOURCE_ID: "sourceId",
    PATIENT_ID: "patientId",
    PATIENT_NAME: "patName",
    PATIENT_DOB: "patDob",
    PATIENT_DOD: "patDod",
    PATIENT_GENDER: "patGender",
    PATIENT_PCP: "providerName",
    PATIENT_PAYER: "payerName",
    PATIENT_PRODUCT_CLASS: "productClassName",
    PATIENT_PRODUCT_GROUP: "productGroupName",
    PRODUCT_CLASSES: "productClassNames",
    PRODUCT_GROUPS: "productGroupNames",
    PATIENT_ADDRESS: "patAddress", // composite column
    PATIENT_ADDRESS_1: "patAddress1",
    PATIENT_ADDRESS_2: "patAddress2",
    PATIENT_CITY: "patCity",
    PATIENT_STATE: "patState",
    PATIENT_ZIP: "patZip",
    PATIENT_PHONE: "patPhone",
    PATIENT_CARE_MANAGEMENT: "patActCm",
    PATIENT_IP_COUNT: "patIpCnt",
    PATIENT_ER_COUNT: "patErCnt",
    PATIENT_SN_COUNT: "patSnCnt",
    PATIENT_OB_COUNT: "patObCnt",
    PATIENT_MEASURE_COMPLIANCE: "compliant",
    PATIENT_MEASURE_COMPLIANCE_FRACTION: "patMeasureCompValue",
    PATIENT_MEASURE_COMPLIANCE_NUMERATOR: "patMeasureCompNumer",
    PATIENT_MEASURE_COMPLIANCE_DENOMINATOR: "patMeasureCompDenom",
    PATIENT_RISK_RANK: "patRiskRank",
    PATIENT_RISK_NUMBER: "patRiskNum",
    PATIENT_RISK_TYPE: "patRiskType",
    PATIENT_RISK_TYPE_NUMBER: "patRiskTypeNum",
    PATIENT_LAST_SERVICE_DATE: "patLastSrvDt",
    PATIENT_NEXT_DUE_DATE: "patDueDt",
    PATIENT_LAST_APPOINTMENT_DATE: "patLastAptDt",
    PATIENT_NEXT_APPOINTMENT_DATE: "patNextAptDt",
    PATIENT_NEXT_APPOINTMENT_PROVIDER_ID: "patNextAptProvId",
    PATIENT_NEXT_APPOINTMENT_PROVIDER: "patNextAptProvider",
    PATIENT_NEXT_APPOINTMENT_TYPE: "patNextAptType",
    PATIENT_NEXT_APPOINTMENT_STATUS: "patNextAptStatus",
    PATIENT_NEXT_APPOINTMENT_NOTE: "patNextAptNote",
    MEASURE_CATEGORY_NAME: "measureCategoryName",
    MEASURE_SUBCATEGORY_NAME: "measureSubcategoryName",
    MEASURE_NAME: "measureName",
    MEASURE_ID: "measureId",
    MEASURE_LINK: "link",
    MEASURE_LAST_SERVICE_DATE: "measureLastSrvDt",
    MEASURE_NEXT_DUE_DATE: "measureNextDueDt",
    MEASURE_LAST_VALUE: "displayValue",
    MEASURE_HISTORY: "measureHistory",
    HCC_CONDITIONS: "hccConditions",
    HCC_CONDITION_NAMES: "hccConditionNames",
    HCC_CONDITIONS_MET: "hccConditionsMet",
    GEN_CONDITIONS: "genConditions",
    GEN_CONDITION_NAMES: "genConditionNames",
    CM_INCENTIVE: "patCmIncentive",
    MEASURE_INCENTIVIZED: "measureIncentivized",
    INCENTIVIZED_COUNT: "patIncentivizedCount",
    INCENTIVE_STATUS: "measureIncentiveStatus",
    INCENTIVE_PAYERS: "measureIncentivePayers",
    INCENTIVE_PAYER_IDS: "measureIncentivePayerIds",
    INCENTIVE_PAYER_NAMES: "measureIncentivePayerNames",
    INCENTIVE_PAYER_COLORS: "measureIncentivePayerColors",
    CARE_MANAGER_USER_IDS: "cmUserIds",
    CARE_MANAGER_NAMES: "cmNames",
    PRACTICE_UNIT_ID: "practiceUnitId",
    PRACTICE_NAME: "practiceName",
    TAG_IDS: "tags",
    TAG_NAMES: "tagNames",
    COST_YTD: "costYtd",
    COST_12_MONTH: "costTwelveMonth",
    PY_AGE: "pyAge",
    MRN: "mrn",
    SUPP_ELIG: "suppElig",
    SUPP_IDS: "suppIds",
    PAYER_IDS: "payerIds",
    PAYER_NAMES: "payerNames",
    PAYER_COLORS: "payerColors",
    SOURCE_IDS: "sourceIds",
    SOURCE_NAMES: "sourceNames",
    CONTRACT_NUMBERS: "patContractNumbers",
    PRODUCT_IDS: "productClassIds",
    PRODUCT_NAMES: "productClassNames",
    PRODUCT_GROUP_IDS: "productGroupIds",
    PRODUCT_GROUP_NAMES: "productGroupNames",
    GRACE_PERIOD: "patInGracePrd"
}

export const PATIENT_TRANSLATION = {
    [TABLE_KEYS.PATIENT_NAME]: "Name",
    [TABLE_KEYS.PATIENT_ID]: "Patient ID",
    [TABLE_KEYS.PATIENT_DOB]: "DOB",
    [TABLE_KEYS.PATIENT_DOD]: "DOD",
    [TABLE_KEYS.PATIENT_GENDER]: "Gender",
    [TABLE_KEYS.PATIENT_PCP]: "PCP",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice",
    [TABLE_KEYS.PATIENT_PAYER]: "Primary Payer",
    [TABLE_KEYS.PATIENT_PRODUCT_CLASS]: "Primary Product Class",
    [TABLE_KEYS.PATIENT_PRODUCT_GROUP]: "Primary Product Group",
    [TABLE_KEYS.PAYER_IDS]: "Coverage",
    [TABLE_KEYS.CONTRACT_NUMBERS]: "Contract Numbers",
    [TABLE_KEYS.PRODUCT_CLASSES]: "Product Classes",
    [TABLE_KEYS.PRODUCT_GROUPS]: "Product Groups",
    [TABLE_KEYS.PATIENT_RISK_NUMBER]: "Risk Rank",
    [TABLE_KEYS.HCC_CONDITIONS]: "HCC Conditions",
    [TABLE_KEYS.HCC_CONDITION_NAMES]: "HCC Conditions",
    [TABLE_KEYS.HCC_CONDITIONS_MET]: "Cond. Compliance",
    [TABLE_KEYS.GEN_CONDITIONS]: "General Conditions",
    [TABLE_KEYS.GEN_CONDITION_NAMES]: "General Conditions",
    [TABLE_KEYS.CARE_MANAGER_NAMES]: "Care Manager",
    [TABLE_KEYS.CARE_MANAGER_USER_IDS]: "Care Manager",
    [TABLE_KEYS.TAG_IDS]: "Patient Tags",
    [TABLE_KEYS.TAG_NAMES]: "Patient Tags",
    [TABLE_KEYS.PY_AGE]: "PY Age",
    [TABLE_KEYS.MRN]: "MRN",

    [TABLE_KEYS.PATIENT_ADDRESS]: "Address",
    [TABLE_KEYS.PATIENT_PHONE]: "Phone",
    [TABLE_KEYS.INCENTIVIZED_COUNT]: "Incentivized",
    [TABLE_KEYS.INCENTIVE_STATUS]: "Incentive Status",
    [TABLE_KEYS.INCENTIVE_PAYERS]: "Incentive Payers",
    [TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_FRACTION]: "Measures Met",
    [TABLE_KEYS.CM_INCENTIVE]: "CMI",
    [TABLE_KEYS.PATIENT_IP_COUNT]: "IP",
    [TABLE_KEYS.PATIENT_ER_COUNT]: "ED",
    [TABLE_KEYS.PATIENT_SN_COUNT]: "SNF",
    [TABLE_KEYS.PATIENT_OB_COUNT]: "OBS",
    [TABLE_KEYS.PATIENT_LAST_SERVICE_DATE]: "Last Service Date",
    [TABLE_KEYS.PATIENT_LAST_APPOINTMENT_DATE]: "Last Appt Date",
    [TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_DATE]: "Next Appt Date",
    [TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_PROVIDER]: "Next Appt Provider",
    [TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_TYPE]: "Next Appt Type",
    [TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_STATUS]: "Next Appt Status",
    [TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_NOTE]: "Next Appt Note",
    [TABLE_KEYS.COST_YTD]: "Cost (Year\xa0to Date)", // non-breaking space to prevent overflow in column settings
    [TABLE_KEYS.COST_12_MONTH]: "Cost (Last 12 Months)",

    [TABLE_KEYS.MEASURE_NAME]: "Measure",
    [TABLE_KEYS.MEASURE_INCENTIVIZED]: "Incentivized",
    [TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE]: "Compliance",
    [TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_FRACTION]: "All Compliance",
    [TABLE_KEYS.MEASURE_LAST_SERVICE_DATE]: "Last Service Date",
    [TABLE_KEYS.MEASURE_LAST_VALUE]: "Last Service Value",
    [TABLE_KEYS.MEASURE_HISTORY]: "Measure History",
    [TABLE_KEYS.SUPP_ELIG]: "Supplemental Eligibility",
    [TABLE_KEYS.SUPP_IDS]: "Supplemental IDs"
}

export const LIST_FILTER_VALUES = [
    FILTERS.PRACTICE_UNITS,
    FILTERS.PCPS,
    FILTERS.PAYERS,
    FILTERS.PRODUCTS,
    FILTERS.ACUTE_ENCOUNTERS,
    FILTERS.MEASURES,
    FILTERS.RISK_RANK,
    FILTERS.RISK_TYPE,
    FILTERS.HCC_CONDITIONS,
    FILTERS.GEN_CONDITIONS,
    FILTERS.TAGS,
    FILTERS.CARE_MANAGERS
]

export const DYNAMIC_DATES = {
    TODAY: "Today",
    TOMORROW: "Tomorrow",
    NEXT_BUSINESS_DAY: "Next Business Day",
    THIS_WEEK: "This Week",
    NEXT_7_DAYS: "Next 7 Days",
    NEXT_WEEK: "Next Week",
    THIS_MONTH: "This Month",
    NEXT_MONTH: "Next Month",
    NEXT_30_DAYS: "Next 30 Days"
}
export const DYNAMIC_DATE_OPTIONS = objToValueLabel(DYNAMIC_DATES)

export const ACUTE_ENCOUNTERS_OPTIONS = [
    { label: "ED", value: "erCount" },
    { label: "IP", value: "ipCount" },
    { label: "SNF", value: "snCount" },
    { label: "OBS", value: "obCount" }
]
export const INCENTIVE_STATUS_OPTIONS = [
    { label: "Both compliant", value: "MM" },
    { label: "Only Health Focus compliant", value: "MX" },
    { label: "Only payer compliant", value: "XM" },
    { label: "Neither compliant", value: "XX" }
]
export const INCENTIVE_STATUS_LABELS = valueLabelToObj(INCENTIVE_STATUS_OPTIONS)

export const blankValues = {
    ...mapValuesToObject(FILTERS, () => null),
    ...mapValuesToObject(LIST_FILTER_VALUES, () => []),

    // these are filters that are purely binary in nature, and don't have null or empty options
    [FILTERS.SCOPE]: LOCAL,
    [FILTERS.TYPE]: FILTER_TYPES.PATIENT_SUMMARY,
    [FILTERS.POPULATION]: POPULATION_VALUES.ALL
}

export const initialValues = {
    ...blankValues,
    [FILTERS.DECEASED]: YES_NO_VALUES.NO
}

export const SUMMARY_DEFAULT_SORT = {
    sortBy: [TABLE_KEYS.PATIENT_NAME],
    sortDirection: [SortDirection.ASC]
}
export const MEASURES_DEFAULT_SORT = {
    sortBy: [
        TABLE_KEYS.PATIENT_NAME,
        TABLE_KEYS.MEASURE_INCENTIVIZED,
        TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE
    ],
    sortDirection: [SortDirection.ASC, SortDirection.DESC, SortDirection.DESC]
}
