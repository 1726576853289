import React from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import { connect } from "react-redux"
import classNames from "classnames"

import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import fetcher from "../../core/fetcher"
import { tieredTableFactory, getRowClass } from "../../table"
import { PERIOD_MONTH_IDS } from "../../pickers/constants"
import { ButtonGroup, constants as PC } from "../../pickers"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import { CATEGORY_COLOR_NAMES } from "../../cost/constants"

import { patientTableDataSelector } from "../selectors"
import { PATIENT_COST_COLUMNS } from "../columns"
import * as actions from "../actions"
import * as C from "../constants"

const { Table, Settings, Description } = tieredTableFactory({
    name: C.TABLE_NAME,
    columns: PATIENT_COST_COLUMNS,
    sortBy: C.TABLE_KEYS.PATH,
    sortDirection: [SortDirection.ASC]
})

const getTableRowClass = rowData => {
    const path = rowData[C.TABLE_KEYS.PATH] || []
    const length = path.length
    const color = CATEGORY_COLOR_NAMES[rowData[C.TABLE_KEYS.CATEGORY_NAME]]
    return {
        ...getRowClass(3)(rowData),
        [`row-category-${color}`]: color && length === 2
    }
}

export const PatientCostTable = ({
    className,
    setPeriod,
    data,
    loading,
    error,
    ...props
}) => (
    <Card className={classNames("card-table patient-cost-table", className)}>
        <Card.Header>
            <CardTitle>Patient Costs</CardTitle>
            <div>
                <ButtonGroup
                    value={props[C.PERIOD]}
                    onClick={setPeriod}
                    options={PC.PERIOD_OPTIONS}
                />
                <Settings />
            </div>
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC list={data} loading={loading} error={error}>
                <Table
                    initialExpandedLevel={1}
                    list={data}
                    getRowClass={getTableRowClass}
                />
            </LEHOC>
        </Card.Body>
    </Card>
)

export default fetcher({
    name: C.TABLE_NAME,
    method: ApiTypes.GET,
    endpoint: state =>
        urlWithParams(
            `/api/patients/${state.poc_id}/cost`,
            PERIOD_MONTH_IDS[state[C.NAME][C.PERIOD]]
        )
})(
    connect(
        (state, { showModal }) => ({
            ...state[C.NAME],
            data: patientTableDataSelector(state).map(td => ({
                ...td,
                onClick: showModal
            }))
        }),
        actions
    )(PatientCostTable)
)
