import React from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Field, reduxForm } from "redux-form"
import moment from "moment"
import _ from "lodash"
import { SelectField, LabelledDatePicker } from "../../core/CustomFields"
import { localizeValidator, required } from "../../core/validators"
import { DATE_STRING } from "../../dates"
import * as C from "../constants"
import { getIsNew } from "../helpers"
import { PatientSelectField } from "./PatientSelectField"

const FORM_ID = "add-tags-form" // note that this is not the same as the form name. This is just the id of the DOM element.

export const startDateValidator = localizeValidator((value, allValues) => {
    if (!value) {
        return undefined
    }

    const endDate = allValues[C.TABLE_KEYS.END_DATE]
    const isNew = getIsNew(allValues)
    const today = moment().endOf("day")
    const datePicked = moment(value, DATE_STRING, true)
    // XXX not 100% sure I've got the right date format. Should either of these be UTC?

    if (isNew && datePicked.isAfter(today)) {
        return "must not be in the future"
    }
    if (isNew && endDate && datePicked.isSameOrAfter(endDate)) {
        return "must be before end date"
    }
    if (!isNew && endDate && datePicked.isAfter(endDate)) {
        return "must not be after end date"
    }
    return undefined
})

export const endDateValidator = localizeValidator((value, allValues) => {
    if (!value) {
        return undefined
    }

    const startDate = allValues[C.TABLE_KEYS.START_DATE]
    const isNew = getIsNew(allValues)
    const today = moment().endOf("day")
    const datePicked = moment(value, DATE_STRING, true)
    // XXX not 100% sure I've got the right date format. Should either of these be UTC?

    if (isNew && datePicked.isSameOrBefore(today)) {
        return "must be in the future"
    }
    if (isNew && startDate && datePicked.isSameOrBefore(startDate)) {
        return "must be after start date"
    }
    if (!isNew && startDate && datePicked.isBefore(startDate)) {
        return "must not be before start date"
    }
    return undefined
})

export const AddTagsForm = ({
    handleSubmit,
    tagOptions,
    patientOptions,
    pickedTags,
    taggedPatientIds,
    initialValues,
    invalid,
    pristine
}) => {
    const unpickedTagOptions = tagOptions.filter(
        tag => !pickedTags || !pickedTags.has(tag.value)
    )
    const noTagOptions = _.isEmpty(unpickedTagOptions)
    const tagAlreadySet = initialValues[C.FILTERS.TAG] !== undefined
    const patientAlreadySet = initialValues[C.FILTERS.PATIENT] !== undefined
    const isNew = getIsNew(initialValues)

    return (
        <React.Fragment>
            <Modal.Body>
                <form id={FORM_ID} onSubmit={handleSubmit}>
                    <Field
                        name={C.FILTERS.TAG}
                        component={SelectField}
                        options={isNew ? unpickedTagOptions : tagOptions}
                        validate={required}
                        disabled={!isNew || tagAlreadySet || noTagOptions}
                        required
                        notClearable
                        placeholder={
                            isNew && noTagOptions
                                ? "The patient already has every tag in the system."
                                : undefined
                        }
                    />
                    <Field
                        name={C.FILTERS.PATIENT}
                        component={
                            patientAlreadySet ? SelectField : PatientSelectField
                        }
                        options={patientAlreadySet && patientOptions} // if we're using a PatientSelect then we don't need to supply options, because that component supplies the options itself
                        validate={required}
                        disabled={patientAlreadySet}
                        selectedPatientIds={taggedPatientIds}
                        required
                        notClearable
                    />
                    <Row>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.START_DATE}
                                component={LabelledDatePicker}
                                validDates={startDateValidator}
                                validate={[required, startDateValidator]}
                                required
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name={C.FILTERS.END_DATE}
                                component={LabelledDatePicker}
                                validDates={endDateValidator}
                                validate={endDateValidator}
                                disableToday={isNew}
                            />
                        </Col>
                    </Row>

                    {!isNew && (
                        <p>
                            To delete a tag, set its end date to today's date.
                        </p>
                    )}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    type="submit"
                    form={FORM_ID}
                    disabled={invalid || pristine}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </React.Fragment>
    )
}

export default reduxForm({ form: C.NAME })(AddTagsForm)
