import React from "react"
import { connect } from "react-redux"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Field, reduxForm } from "redux-form"
import { required } from "../../core/validators"
import { SelectField, TextInput } from "../../core/CustomFields"
import { closeModal, updateStatus } from "../actions"
import {
    recordSelector,
    statusInitialValuesSelector,
    statusOptionsSelector
} from "../selectors"
import * as C from "../constants"
import { getTitle } from "../helpers"

export const StatusModal = ({
    closeModal,
    handleSubmit,
    invalid,
    pristine,
    modalOpen,
    statusOptions,
    reasonOptions,
    record
}) => (
    <Modal
        show={modalOpen}
        onHide={closeModal}
        className="audits-modal audit-status"
        size="md"
    >
        <Modal.Header closeButton>
            <Modal.Title>{getTitle("Update Audit Status", record)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row as="form">
                <Col md={5}>
                    <Field
                        name={C.TABLE_KEYS.STATUS}
                        component={SelectField}
                        options={statusOptions}
                        validate={required}
                        notClearable
                        required
                    />
                </Col>
                <Col md={7}>
                    <Field
                        name={C.TABLE_KEYS.STATUS_REASON}
                        component={SelectField}
                        options={reasonOptions}
                        disabled={reasonOptions.length <= 1}
                        placeholder={
                            reasonOptions.length
                                ? "Select reason..."
                                : "No reason needed"
                        }
                    />
                </Col>
                <Col>
                    <Field
                        name={C.TABLE_KEYS.STATUS_NOTE}
                        component={TextInput}
                    />
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Cancel
            </Button>
            <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={invalid || pristine}
            >
                Update
            </Button>
        </Modal.Footer>
    </Modal>
)

export default connect(
    state => {
        return {
            ...statusOptionsSelector(state),
            modalOpen: state[C.NAME].statusModalOpen,
            record: recordSelector(state),
            initialValues: statusInitialValuesSelector(state)
        }
    },
    { closeModal, onSubmit: updateStatus }
)(
    reduxForm({
        form: C.PA_STATUS_FORM,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true
    })(StatusModal)
)
