import React from "react"
import { PayerIcon } from "../../indicators"

export const PayerProduct = ({
    payerName,
    payerLogo,
    productName,
    ...props
}) => (
    <span {...props}>
        {payerName}
        {payerLogo && <PayerIcon logo={payerLogo} />}
        {payerName && productName && " – "}
        {productName}
    </span>
)
