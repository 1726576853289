import React from "react"
import { SortDirection } from "react-virtualized"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { bar } from "billboard.js"
import _ from "lodash"
import FilterHeader from "../../core/FilterHeader"
import DashboardFilters from "../../dashboard_filters/components"
import { getInitialValues } from "../../cost/helpers"
import { CostForm } from "../../cost/components/CostFiltersForm"
import * as CC from "../../cost/constants"
import { tableFactory } from "../../table"
import { formatDollarsCents, MAX_PRECISION } from "../../numbers"
import * as C from "../constants"
import { COST_COLUMNS, UTILIZATION_COLUMNS } from "../columns"
import PrintModal from "./PrintModal"
import { CostUtilizationTab } from "./CostUtilizationTab"

const createTable = (name, columns) =>
    tableFactory({
        name,
        columns,
        sortBy: [C.TABLE_KEYS.MONTH_ID],
        sortDirection: [SortDirection.ASC]
    })

const tables = {
    [C.TABS.COST]: createTable(C.CU_COST_TABLE, COST_COLUMNS),
    [C.TABS.UTILIZATION]: createTable(
        C.CU_UTILIZATION_TABLE,
        UTILIZATION_COLUMNS
    )
}

// we use this function instead of a wrapper component because React Bootstrap's Tabs component is very finicky about what it'll allow as its children.
const getTab = (tab, { tableData, chartData, ...props }) => (
    <Tab eventKey={tab} title={C.TAB_TITLES[tab]}>
        <CostUtilizationTab
            {...props}
            {...tables[tab]}
            tableData={tableData[tab]}
            chartData={chartData[tab][props.view]}
            id={tab}
        />
    </Tab>
)

export class CostUtilization extends React.Component {
    state = {
        view: CC.COST_VIEWS.RELATIVE,
        tab: C.TABS.COST
    }

    setTab = tab => this.setState({ tab })
    setView = view => this.setState({ view })

    setFilters = filters =>
        this.props.setFilters({
            filters,
            categoryTree: this.props.categoryTree
        })
    setCategoryFilter = stamp =>
        this.setFilters({
            ...this.props.filters,
            [C.FILTERS.CATEGORIES]: [stamp]
        })

    getInitialValues = () =>
        getInitialValues(this.props.filters, this.props.categoryTree)

    render() {
        const sharedProps = {
            ...this.props,
            ...this.state,
            setView: this.setView
        }

        return (
            <div className="cost-utilization">
                <Row>
                    <Col
                        className="page-header cost-utilization-header"
                        xs={10}
                    >
                        <h1>Cost & Utilization Report</h1>
                        <DashboardFilters
                            title="Edit Cost Filters"
                            form={C.UTILIZATION_FILTERS_FORM}
                            categoryNodes={this.props.categoryNodes}
                            formComponent={CostForm}
                            lookup={this.props.filterLookup}
                            initialValues={this.getInitialValues()}
                            emptyValues={C.initialState.filters}
                            setFilters={this.setFilters}
                            categoryTree={this.props.categoryTree}
                        />
                    </Col>
                    <Col xs={2} style={{ marginTop: "15px" }}>
                        <PrintModal
                            initialValues={{
                                ...C.PRINT_INITIAL_VALUES,
                                [C.FILTERS.EXPORT_RELATIVE]:
                                    this.state.view === CC.COST_VIEWS.RELATIVE,
                                [C.FILTERS.EXPORT_ABSOLUTE]:
                                    this.state.view === CC.COST_VIEWS.ABSOLUTE
                            }}
                            view={this.state.view}
                            onSubmit={this.props.printPdf}
                        />
                    </Col>
                    <Col
                        xs={12}
                        style={{
                            marginTop: -10,
                            marginBottom: 10
                        }}
                    >
                        <FilterHeader
                            filters={this.props.filters}
                            form={C.UTILIZATION_FILTERS_FORM}
                            lookup={this.props.filterLookup}
                            style={{ marginLeft: 2 }}
                        />
                    </Col>
                </Row>
                <Tabs
                    id="cost-utilization-tabs"
                    defaultActiveKey={C.TABS.COST}
                    activeKey={this.state.tab}
                    onSelect={this.setTab}
                >
                    {getTab(C.TABS.COST, {
                        ...sharedProps,
                        title: "Monthly Costs",
                        chartProps: {
                            type: bar(),
                            yFormat: formatDollarsCents,
                            tooltipFormat: formatDollarsCents,
                            onBarClick: this.setCategoryFilter
                        },
                        hasViews: true
                    })}
                    {getTab(C.TABS.UTILIZATION, {
                        ...sharedProps,
                        title: "Monthly Utilization",
                        chartProps: {
                            type: bar(),
                            onBarClick: this.setCategoryFilter,
                            tooltipFormat: u => _.round(u, MAX_PRECISION)
                        },
                        hasViews: true
                    })}
                </Tabs>
            </div>
        )
    }
}
