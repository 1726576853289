import React from "react"
import { Badge } from "react-bootstrap"
import _ from "lodash"
import * as C from "../constants"
import { Hoverlay } from "../../core/Hoverlay"

export const TagBadges = ({ data: { tags = [] } }) =>
    _.isEmpty(tags) ? (
        <div className="tag-badges-spacer" />
    ) : (
        <div className="tag-badges">
            <h4>Patient Tags:</h4>
            <ul>
                {tags.map(tag => (
                    <Hoverlay
                        key={tag[C.TABLE_KEYS.TAG_ID]}
                        tooltip={
                            tag[C.TABLE_KEYS.COMMENT] ?? tag[C.TABLE_KEYS.TAG]
                        }
                        className="tooltip-blue"
                        trigger="click"
                    >
                        <Badge variant="primary" as="li" pill>
                            {tag[C.TABLE_KEYS.TAG]}
                        </Badge>
                    </Hoverlay>
                ))}
            </ul>
        </div>
    )
