import { all, call, put, select, takeEvery } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"
import { reset } from "redux-form"
import moment from "moment"
import { getResourceData, modifyResource } from "../core/fetcher"
import { findAndUpdate, urlWithParams } from "../utils"
import {
    ApiTypes,
    downloadFile,
    uploadFiles,
    warnAboutPdfColumns
} from "../api"
import {
    ERROR_NOTIFICATION,
    notifySimpleError,
    savingNotification,
    SUCCESS_NOTIFICATION,
    tryWithNotifications,
    UID_SAVING
} from "../notifications"
import { getSortedColumns } from "../selectorUtils"
import { TIMESTAMP_STRING, TIMESTAMP_STRING_COMPACT } from "../dates"
import { FILTER_OPTIONS } from "../constants"
import * as C from "./constants"
import { filtersSelector } from "./selectors"
import { closeModal, stopSavingFile, stopSavingNote } from "./actions"

const updateSubRecords = (record, subRecords, subRecordsField, countField) => ({
    ...record,
    [subRecordsField]: subRecords,
    [countField]: _.filter(subRecords, sr => !sr[C.TABLE_KEYS.DELETED]).length
})

const updateSubRecord = (
    recordId,
    fieldId,
    subRecordsField,
    idField,
    countField,
    update
) =>
    modifyResource({
        name: C.NAME,
        dataTransform: data => ({
            ...data,
            audits: findAndUpdate(
                data.audits,
                record =>
                    updateSubRecords(
                        record,
                        findAndUpdate(
                            record[subRecordsField] || [],
                            update,
                            idField,
                            fieldId
                        ),
                        subRecordsField,
                        countField
                    ),
                C.TABLE_KEYS.RECORD_ID,
                recordId
            )
        })
    })

const addSubRecords = (recordId, subRecordsField, countField, newRecords) =>
    modifyResource({
        name: C.NAME,
        dataTransform: data => ({
            ...data,
            audits: findAndUpdate(
                data.audits,
                record =>
                    updateSubRecords(
                        record,
                        [...(record[subRecordsField] ?? []), ...newRecords],
                        subRecordsField,
                        countField
                    ),
                C.TABLE_KEYS.RECORD_ID,
                recordId
            )
        })
    })

function* updateFile(recordFileId, update) {
    const recordId = yield select(state => state[C.NAME].recordId)
    yield put(
        updateSubRecord(
            recordId,
            recordFileId,
            C.TABLE_KEYS.FILES,
            C.TABLE_KEYS.RECORD_FILE_ID,
            C.TABLE_KEYS.FILE_COUNT,
            update
        )
    )
}

function* updateNote(recordNoteId, update) {
    const recordId = yield select(state => state[C.NAME].recordId)
    yield put(
        updateSubRecord(
            recordId,
            recordNoteId,
            C.TABLE_KEYS.NOTES,
            C.TABLE_KEYS.RECORD_NOTE_ID,
            C.TABLE_KEYS.NOTE_COUNT,
            update
        )
    )
}

// files

function* uploadS3Files({ recordId, files, description }) {
    const formData = new FormData()
    formData.append(C.TABLE_KEYS.DESCRIPTION, description)

    for (const file of files) {
        formData.append(C.TABLE_KEYS.FILES, file)
    }

    const filenames = _.join(
        _.map(files, file => `"${file.name}"`),
        ", "
    )

    try {
        yield put(
            Notifications.info(
                savingNotification({
                    message: `Uploading file${
                        files.length > 1 ? "s:" : ""
                    } ${filenames}...`
                })
            )
        )
        const response = yield* uploadFiles(
            `/api/patient_audit/${recordId}/files`,
            formData
        ) // using uploadFiles instead of a more normal fetch is why we can't use tryWithNotifications here

        if (!_.isEmpty(response.files)) {
            // add username to returned file records
            const userName = yield select(
                state => state.authentication.userName
            )
            const newFiles = response.files.map(file => ({
                ...file,
                [C.TABLE_KEYS.CREATED_BY_NAME]: userName
            }))

            // add those file records to the audit record
            yield put(
                addSubRecords(
                    recordId,
                    "files",
                    C.TABLE_KEYS.FILE_COUNT,
                    newFiles
                )
            )

            yield put(
                Notifications.success({
                    ...SUCCESS_NOTIFICATION,
                    message: "Upload complete."
                })
            )
        }

        for (const filename in response.failures) {
            yield put(
                Notifications.error({
                    ...ERROR_NOTIFICATION,
                    title: `Failed to upload ${filename}`,
                    message: response.failures[filename]
                })
            )
        }
    } catch (error) {
        console.error(error)
        yield notifySimpleError("Upload failed.")(error)
    } finally {
        yield put(Notifications.hide(UID_SAVING))
        yield put(stopSavingFile(0))
    }
}

function* downloadS3File({ record }) {
    yield* downloadFile(
        record[C.TABLE_KEYS.FILE_NAME],
        `/api/patient_audit/file/${record[C.TABLE_KEYS.RECORD_FILE_ID]}`,
        record[C.TABLE_KEYS.FILE_NAME],
        { pendingMessage: `Downloading ${record[C.TABLE_KEYS.FILE_NAME]}...` }
    )
}

function* deleteFile({ recordFileId }) {
    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/file/${recordFileId}`,
            method: ApiTypes.DELETE
        },
        function*() {
            yield* updateFile(recordFileId, {
                [C.TABLE_KEYS.DELETED]: true,
                [C.TABLE_KEYS.DELETED_DATE]: moment().format(TIMESTAMP_STRING)
            })
        }
    )
    yield put(stopSavingFile(recordFileId))
}

function* restoreFile({ recordFileId }) {
    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/file/${recordFileId}/restore`,
            method: ApiTypes.PUT
        },
        function*() {
            yield* updateFile(recordFileId, {
                [C.TABLE_KEYS.DELETED]: false,
                [C.TABLE_KEYS.DELETED_DATE]: undefined
            })
        }
    )
    yield put(stopSavingFile(recordFileId))
}

function* updateFileDesc({ recordFileId, description }) {
    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/file/${recordFileId}`,
            method: ApiTypes.PUT,
            body: { description }
        },
        function*() {
            yield* updateFile(recordFileId, {
                [C.TABLE_KEYS.DESCRIPTION]: description,
                [C.TABLE_KEYS.RECENTLY_UPDATED]: true
            })
        }
    )
    yield put(stopSavingFile(recordFileId))
}

// notes

function* newNote({ recordId, note, important }) {
    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/${recordId}/notes`,
            method: ApiTypes.POST,
            body: { note, important }
        },
        function*(response) {
            yield put(
                addSubRecords(
                    recordId,
                    C.TABLE_KEYS.NOTES,
                    C.TABLE_KEYS.NOTE_COUNT,
                    [response]
                )
            )
        }
    )
    yield put(stopSavingNote(0))
}

function* editNote({ recordNoteId, note, important }) {
    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/note/${recordNoteId}`,
            method: ApiTypes.PUT,
            body: { note, important }
        },
        function*() {
            yield* updateNote(recordNoteId, {
                [C.TABLE_KEYS.NOTE]: note,
                [C.TABLE_KEYS.HIGH_IMPORTANCE]: important,
                [C.TABLE_KEYS.RECENTLY_UPDATED]: true
            })
        }
    )
    yield put(stopSavingNote(recordNoteId))
}

function* removeNote({ recordNoteId }) {
    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/note/${recordNoteId}`,
            method: ApiTypes.DELETE
        },
        function*() {
            yield* updateNote(recordNoteId, {
                [C.TABLE_KEYS.DELETED]: true,
                [C.TABLE_KEYS.DELETED_DATE]: moment().format(TIMESTAMP_STRING)
            })
        }
    )
    yield put(stopSavingNote(recordNoteId))
}

function* restoreNote({ recordNoteId }) {
    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/note/${recordNoteId}/restore`,
            method: ApiTypes.PUT
        },
        function*() {
            yield* updateNote(recordNoteId, {
                [C.TABLE_KEYS.DELETED]: false,
                [C.TABLE_KEYS.DELETED_DATE]: undefined
            })
        }
    )
    yield put(stopSavingNote(recordNoteId))
}

function* updateStatus({ payload }) {
    const recordId = yield select(state => state[C.NAME].recordId)
    const auth = yield select(state => state.authentication)

    yield* tryWithNotifications(
        {
            url: `/api/patient_audit/${recordId}/status`,
            method: ApiTypes.PUT,
            body: payload,
            message: "Updating status..."
        },
        function*(updateTime) {
            const audits = yield select(
                getResourceData(C.NAME, data => data.audits)
            )
            const filterOptions = yield select(getResourceData(FILTER_OPTIONS))
            const status = _.find(filterOptions.auditStatuses, {
                value: payload[C.TABLE_KEYS.STATUS]
            })
            const reason = _.find(filterOptions.auditStatusReasons, {
                value: payload[C.TABLE_KEYS.STATUS_REASON]
            })

            // update record
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: data => ({
                        ...data,
                        audits: findAndUpdate(
                            data.audits,
                            {
                                [C.TABLE_KEYS.STATUS]: status.label,
                                [C.TABLE_KEYS.STATUS_DESC]: status.description,
                                [C.TABLE_KEYS.STATUS_REASON]: reason?.label,
                                [C.TABLE_KEYS.STATUS_NOTE]:
                                    payload[C.TABLE_KEYS.STATUS_NOTE],
                                [C.TABLE_KEYS.CLOSED]: status.closed,
                                [C.TABLE_KEYS.CREATED_DATE]: updateTime,
                                [C.TABLE_KEYS.CREATED_BY_ID]: auth.userId,
                                [C.TABLE_KEYS.CREATED_BY_NAME]: auth.userName,
                                [C.TABLE_KEYS.RECENTLY_UPDATED]: true
                            },
                            C.TABLE_KEYS.RECORD_ID,
                            recordId
                        )
                    })
                })
            )

            // update counts
            const oldStatus = _.find(audits, {
                [C.TABLE_KEYS.RECORD_ID]: recordId
            })?.[C.TABLE_KEYS.STATUS]
            const newStatus = status.label

            if (oldStatus !== newStatus) {
                yield put(
                    modifyResource({
                        name: C.PATIENT_AUDIT_COUNTS,
                        dataTransform: data => ({
                            ...data,
                            [oldStatus]: (data[oldStatus] ?? 1) - 1, // default to 1 so it'll become 0
                            [newStatus]: (data[newStatus] ?? 0) + 1
                        })
                    })
                )
            }

            // ok, done
            yield put(closeModal())
            yield put(reset(C.PA_STATUS_FORM))
        },
        null,
        {
            reader: response => response.text()
        }
    )
}

function* downloadAudits(extension) {
    const columns = yield select(getSortedColumns(C.NAME))
    const filters = yield select(filtersSelector)
    // TODO

    const params = { ...columns, ...filters }
    const endpoint = urlWithParams(`/api/patient_audit/${extension}`, params)
    const filename = `patient_audits_${moment().format(
        TIMESTAMP_STRING_COMPACT
    )}.${extension}`
    yield* warnAboutPdfColumns(extension, params)
    yield* downloadFile(
        `Patient Audits ${_.upperCase(extension)}`,
        endpoint,
        filename
    )
}

function* exportAudits() {
    yield* downloadAudits("csv")
}
function* printAudits() {
    yield* downloadAudits("pdf")
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.UPLOAD_PA_FILES, uploadS3Files)
        }),
        call(function*() {
            yield takeEvery(C.DOWNLOAD_PA_FILE, downloadS3File)
        }),
        call(function*() {
            yield takeEvery(C.DELETE_PA_FILE, deleteFile)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_PA_FILE, restoreFile)
        }),
        call(function*() {
            yield takeEvery(C.UPDATE_PA_FILE_DESC, updateFileDesc)
        }),
        call(function*() {
            yield takeEvery(C.NEW_PA_NOTE, newNote)
        }),
        call(function*() {
            yield takeEvery(C.UPDATE_PA_NOTE, editNote)
        }),
        call(function*() {
            yield takeEvery(C.REMOVE_PA_NOTE, removeNote)
        }),
        call(function*() {
            yield takeEvery(C.RESTORE_PA_NOTE, restoreNote)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_PATIENT_AUDITS, exportAudits)
        }),
        call(function*() {
            yield takeEvery(C.PRINT_PATIENT_AUDITS, printAudits)
        }),
        call(function*() {
            yield takeEvery(C.UPDATE_PA_STATUS, updateStatus)
        })
    ])
}
