import React from "react"
import { UndoButton } from "../../core/UndoRemoveButtons"
import * as C from "../constants"

export const TagActionsCell = ({ rowData, tableData }) =>
    tableData.disableEdit ? null : rowData.removed ? (
        <UndoButton
            id={rowData[C.TABLE_KEYS.PATIENT_TAG_ID]}
            undo={() => tableData.onUndo(rowData)}
        />
    ) : (
        <React.Fragment>
            <button
                type="button"
                className="btn-blank"
                title="Edit tag"
                onClick={() => tableData.onEdit(rowData)}
            >
                <i className="fa fa-edit" />
            </button>
            <button
                type="button"
                className="btn-blank"
                title="Remove tag"
                onClick={() => tableData.onRemove(rowData)}
            >
                <i className="fa fa-trash-o" />
            </button>
        </React.Fragment>
    )
