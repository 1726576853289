import _ from "lodash"
import fetcher from "../../core/fetcher"
import * as QC from "../../qsr/constants"
import { ApiTypes } from "../../api"
import { MEASURES } from "../../constants"

export default fetcher({
    name: MEASURES,
    endpoint: "/api/measures",
    method: ApiTypes.GET,
    acceptResponse: ({ measures }) => ({
        measures,
        measuresMap: _.keyBy(measures, QC.TABLE_KEYS.MEASURE_ID)
    })
})(() => null)
