import React from "react"
import { Card, Image } from "react-bootstrap"
import auth from "../modules/authentication"
import logo from "../assets/images/logo.gif"
import Footer from "../modules/core/Footer"
import SiteTitle from "../modules/core/SiteTitle"

const LoginPage = () => (
    <div id="main-content" className="login">
        <SiteTitle title="Login" />
        <Card body>
            <div className="center-block logos">
                <Image
                    src={logo}
                    alt="Health Focus Population Health Registry"
                />
                <auth.components.ClientLogo />
            </div>
            <auth.components.Login />
        </Card>
        <Footer />
    </div>
)

export default LoginPage
