import { connect } from "react-redux"

import fetcher from "../../core/fetcher"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import { QsrDashboardSelector } from "../selectors"
import { QSRDashboard } from "./QSRDashboard"

export default fetcher({
    name: C.NAME,
    endpoint: state => {
        const moduleState = state[C.NAME]
        return urlWithParams(
            `/api/qsr/dashboard/${moduleState.aggregation}`,
            moduleState.filters
        )
    },
    method: ApiTypes.GET
})(
    connect(
        state => ({
            ...state[C.NAME],
            qsrs: QsrDashboardSelector(state)
        }),
        actions
    )(QSRDashboard)
)
