import { columnBuilder } from "../table"
import MeasureCompliance from "../indicators/components/MeasureCompliance"
import {
    Check,
    DollarsCentsZeroBlank,
    FormattedDate,
    LongCell,
    PatientName,
    Payer,
    TelephoneCell
} from "../core/CellRenderers"
import {
    after1900,
    afterDateField,
    todayOrAfter,
    todayOrBefore
} from "../core/validators"
import { ALIGNMENTS, MODULE_IDS } from "../constants"
import { SERVER_DATE_STRING } from "../dates"
import * as C from "./constants"
import {
    getRedocNumFields,
    getRedocRafFields,
    redocGetter,
    redocRemainingGetter
} from "./helpers"
import {
    DateCell,
    EligibleDate,
    MeasureHistoryCell,
    RedocCell,
    RedocRemainingCell
} from "./components/CellRenderers"

const NAME_WIDTH = 200
const DATE_WIDTH = 110
const DATE_INPUT_WIDTH = 175

const column = columnBuilder(C.TABLE_TRANSLATION)

export default [
    column(C.TABLE_KEYS.PATIENT_NAME, {
        cellRenderer: PatientName(C.TABLE_KEYS.PATIENT_ID),
        width: NAME_WIDTH,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PATIENT_ID, { width: 85, selected: false }),
    column(C.TABLE_KEYS.PATIENT_GENDER, { width: 90, selected: false }),
    column(C.TABLE_KEYS.PATIENT_DOB, {
        cellRenderer: FormattedDate,
        width: DATE_WIDTH
    }),
    column(C.TABLE_KEYS.PHONE, {
        cellRenderer: TelephoneCell,
        width: 120
    }),
    column(C.TABLE_KEYS.SOURCE_NAME, {
        idField: C.TABLE_KEYS.SOURCE_ID,
        cellRenderer: Payer,
        width: 85,
        selected: false
    }),
    column(C.TABLE_KEYS.PROVIDER_NAME, {
        idField: C.TABLE_KEYS.PROVIDER_ID,
        cellRenderer: LongCell,
        width: NAME_WIDTH,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.NON_PHYSICIAN_PCP, {
        cellRenderer: Check,
        align: ALIGNMENTS.CENTER,
        width: 150,
        selected: false
    }),
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        idField: C.TABLE_KEYS.PRACTICE_ID,
        cellRenderer: LongCell,
        width: NAME_WIDTH,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.PAYER_NAME, {
        idField: C.TABLE_KEYS.PAYER_ID,
        cellRenderer: Payer,
        width: NAME_WIDTH,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRODUCT_CLASS_NAME, {
        idField: C.TABLE_KEYS.PRODUCT_CLASS_ID,
        width: 105,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.EFF_DATE, {
        cellRenderer: FormattedDate,
        width: DATE_WIDTH,
        selected: false
    }),
    column(C.TABLE_KEYS.END_DATE, {
        cellRenderer: FormattedDate,
        width: DATE_WIDTH,
        selected: false
    }),
    column(C.TABLE_KEYS.CONTRACT_NUMBER, { width: 135, selected: false }),
    column(C.TABLE_KEYS.MBI, { width: 80, selected: false }),
    column(C.TABLE_KEYS.MEASURE_COMPLIANCE, {
        align: ALIGNMENTS.CENTER,
        cellRenderer: MeasureCompliance,
        width: 160
    }),
    column(C.TABLE_KEYS.SERVICE_DATE, {
        cellRenderer: DateCell,
        columnData: {
            validate: [
                todayOrBefore,
                after1900,
                afterDateField(C.TABLE_KEYS.SERVICE_DATE, {
                    startDateFormat: SERVER_DATE_STRING,
                    errorMessage: "Must not be before previous Last AWV Date"
                })
            ]
        },
        className: "column-widget",
        width: DATE_INPUT_WIDTH
    }),
    column(C.TABLE_KEYS.SERVICE_CODE, { width: 90, selected: false }),
    column(C.TABLE_KEYS.FLAG_MANUAL, {
        cellRenderer: Check,
        align: ALIGNMENTS.CENTER,
        width: 100,
        selected: false
    }),
    column(C.TABLE_KEYS.FLAG_EMR, {
        cellRenderer: Check,
        align: ALIGNMENTS.CENTER,
        width: 85,
        selected: false
    }),
    column(C.TABLE_KEYS.FLAG_PAYER, {
        cellRenderer: Check,
        align: ALIGNMENTS.CENTER,
        width: 90,
        selected: false
    }),
    column(C.TABLE_KEYS.PCP_RENDERED, {
        cellRenderer: Check,
        align: ALIGNMENTS.CENTER,
        width: 120,
        selected: false
    }),
    column(C.TABLE_KEYS.RENDERING_PROVIDER_NPI, {
        width: 175,
        selected: false
    }),
    column(C.TABLE_KEYS.RENDERING_PROVIDER_NAME, {
        cellRenderer: LongCell,
        width: NAME_WIDTH,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.RENDERING_PRACTICE_NAME, {
        cellRenderer: LongCell,
        width: NAME_WIDTH,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.AWV_ELIGIBLE_DATE, {
        cellRenderer: EligibleDate,
        width: 140
    }),
    column(C.TABLE_KEYS.AWV_SCHEDULED_DATE, {
        width: DATE_INPUT_WIDTH,
        cellRenderer: DateCell,
        columnData: {
            validate: [todayOrAfter, after1900]
        },
        className: "column-widget"
    }),
    column(C.TABLE_KEYS.AWV_APPOINTMENT_TYPE, {
        cellRenderer: LongCell,
        width: 180
    }),
    column(C.TABLE_KEYS.AWV_APPOINTMENT_NOTE, {
        cellRenderer: LongCell,
        width: 180,
        selected: false
    }),
    column(C.TABLE_KEYS.COST_YTD, {
        module: MODULE_IDS.COST_UTILIZATION,
        cellRenderer: DollarsCentsZeroBlank,
        width: 100,
        align: ALIGNMENTS.RIGHT,
        selected: false
    }),
    column(C.TABLE_KEYS.COST_12_MONTH, {
        module: MODULE_IDS.COST_UTILIZATION,
        cellRenderer: DollarsCentsZeroBlank,
        width: 140,
        align: ALIGNMENTS.RIGHT,
        selected: false
    }),
    column(C.TABLE_KEYS.PATIENT_HCC, { width: 100, selected: false }),
    column(C.TABLE_KEYS.RISK_NUM, {
        hover: "Patient HCC Stratification",
        cellRenderer: ({ rowData }) => rowData[C.TABLE_KEYS.RISK_RANK],
        width: 120,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_NUM, {
        hover: "HCC Re-Documentation number",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocNumFields },
        cellRenderer: RedocCell,
        dataGetter: redocGetter,
        width: 110,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_RAF, {
        hover: "HCC Re-Documentation RAF",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocRafFields },
        cellRenderer: RedocCell,
        dataGetter: redocGetter,
        width: 130,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_NUM_REMAINING, {
        hover: "HCC Re-Documentation number remaining",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocNumFields },
        cellRenderer: RedocRemainingCell,
        dataGetter: redocRemainingGetter,
        width: 180,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_RAF_REMAINING, {
        hover: "HCC Re-Documentation RAF remaining",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocRafFields },
        cellRenderer: RedocRemainingCell,
        dataGetter: redocRemainingGetter,
        width: 195,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.MEASURE_HISTORY, {
        module: MODULE_IDS.POC_MEASURES,
        cellRenderer: MeasureHistoryCell,
        className: "popup-history",
        width: 120,
        align: ALIGNMENTS.CENTER,
        disableSort: true,
        selected: false
    })
]
