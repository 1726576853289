import React from "react"
import { connect } from "react-redux"
import { MeasureInfoLink } from "../../core/MeasureInfoLink"
import { Hoverlay } from "../../core/Hoverlay"
import * as reduxActions from "../../patient_list/actions"
import * as QC from "../../qsr/constants"
import { PatientListLink } from "./PatientListLink"
import * as C from "../constants"

export const MeasureNameLinkCell = props => (
    <Hoverlay tooltip={props.cellData} wrapperClassName="cell-inner-ellipsis">
        <PatientListLink {...props} />
        <MeasureInfoLink link={props.rowData[QC.TABLE_KEYS.LINK]} />
    </Hoverlay>
)

export default connect(
    (state, { columnData: { store, ...columnData } }) => ({
        ...state[store || C.NAME],
        ...columnData
    }),
    reduxActions
)(MeasureNameLinkCell)
