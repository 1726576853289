import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import patient_audits from "../modules/patient_audits"

export const PatientAuditsPage = () => (
    <>
        <SiteTitle title="Patient Audit Tracker" />
        <Breadcrumbs
            items={[["Dashboard", "/dashboard"], ["Patient Audit Tracker"]]}
        />
        <header className="patient_audit-page_header">
            <h1>Patient Audit Tracker</h1>
            <patient_audits.components.AuditsCounter />
        </header>
        <patient_audits.components.default />
    </>
)

export default PatientAuditsPage
