import React, { useState } from "react"
import { connect } from "react-redux"
import { Form } from "react-bootstrap"
import classNames from "classnames"
import { useFocus } from "../../hooks"
import { Hoverlay } from "../../core/Hoverlay"
import { canWriteTcmSelector } from "../selectors"
import { updateTCM } from "../actions"
import * as C from "../constants"

import spinner from "../../../assets/images/loading.gif"

// TODO this is very similar to Dropdown, and to a lesser extent to DateEntry... we might be able to combine the shared functionality into a single component.
// TODO we could probably extract the component proper to /core now that it's been generalized

export const TextInputCell = ({
    canWrite,
    cellData,
    update,
    placeholder = "Comment"
}) => {
    const [loading, setLoading] = useState(false)
    const [value, updateValue] = useState(cellData || "")
    const [showTooltip, setShowTooltip] = useState(false)

    const { focused, setFocus, focusRef } = useFocus()

    const handleSubmit = e => {
        setShowTooltip(false)

        const value = e.target.value || null

        if (value === cellData) {
            return
        }

        setLoading(true)
        update(value)
    }

    if (!canWrite) {
        return <Hoverlay>{cellData}</Hoverlay>
    }

    return focused ? (
        <Hoverlay
            wrapperClassName="tcm-input-text-wrapper"
            tooltip={value}
            show={showTooltip} // normal triggers are a bit too flighty here. We want to show the tooltip iff we're focused on the input, period.
        >
                    <Form.Control
                        ref={focusRef}
                        type="text"
                        autoComplete="off"
                        placeholder={placeholder}
                        defaultValue={cellData || ""}
                        onBlur={handleSubmit}
                onFocus={() => {
                    setShowTooltip(true)
                    setTimeout(() => {
                        // move cursor to the end. This has to be in a timeout because Chrome calls onFocus before it places the cursor
                        focusRef.current.setSelectionRange(100000, 100000)
                        // scroll the cursor into view
                        focusRef.current.scrollLeft =
                            focusRef.current.scrollWidth
                    }, 0)
                }}
                        onChange={e => updateValue(e.target.value)}
                        disabled={loading}
                        className={classNames("tcm-input-text", {
                            loading
                        })}
                        // TODO blur if you hit Enter
                    />
                    {loading && (
                        <div className="text-loading">
                            <img src={spinner} alt="Saving..." />
                        </div>
                    )}
        </Hoverlay>
            ) : (
                <span
                    className={classNames(
                        "tcm-input-text form-control",
                        !cellData && "placeholder"
                    )}
                    tabIndex={0}
                    onFocus={() => {
                        setShowTooltip(true)
                        setFocus()
                    }}
                >
                    {cellData || placeholder}
                </span>
    )
}

export default connect(
    (state, { cellData }) => ({
        key: cellData,
        canWrite: canWriteTcmSelector(state)
    }),
    (dispatch, { cellData, rowData, dataKey }) => ({
        update: value =>
            dispatch(
                updateTCM({
                    tcmId: rowData[C.TCM_LOOKUP[dataKey]],
                    field: dataKey,
                    transitionId: rowData[C.TABLE_KEYS.TCM_TRACKER_ID],
                    value
                })
            )
    })
)(TextInputCell)
