import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import { urlWithParams } from "../../utils"
import * as cmActions from "../../care_management/actions"
import * as actions from "../actions"
import * as C from "../constants"
import OutreachDashboard from "./OutreachDashboard"

export default fetcher({
    name: C.NAME,
    endpoint: state =>
        urlWithParams("/api/outreach/dashboard", state[C.NAME].filters),
    method: ApiTypes.GET
})(
    connect(
        state => ({
            ...state[C.NAME],
            anchorDate: state.server_status.anchorDate
        }),
        {
            ...actions,
            ...cmActions
        }
    )(OutreachDashboard)
)
