import React from "react"
import _ from "lodash"
import { ApiTypes } from "../../api"
import { EMPTY_OBJECT } from "../../utils"
import fetcher from "../../core/fetcher"
import { TCMPopover, TCMPopoverForm } from "../../attention/components"
import * as C from "../constants"

const TCMPopoverLoader = ({ data, ...props }) => (
    <TCMPopoverForm tcm={data.tcm || EMPTY_OBJECT} {...props} />
)

// putting the fetcher inside the popover so that it doesn't mount until the popover is opened
const TCMPopoverFetcher = fetcher({
    name: C.TOC_TCM,
    endpoint: (state, { transitionId }) => `/api/tcm/${transitionId}`,
    acceptResponse: ({ tcms }) => ({
        tcm: _.head(tcms)
    }),
    method: ApiTypes.GET,
    persistResource: true
})(TCMPopoverLoader)

export const TocTCMPopover = ({ cellData, rowData }) =>
    cellData ? (
        <TCMPopover
            Component={TCMPopoverFetcher}
            transitionId={rowData.transitionId}
            dischargeDt={rowData[C.TABLE_KEYS.DISCHARGE_DATE]}
        />
    ) : null
export default TocTCMPopover
