import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SET_COST_REPORT_VIEW]: (state, action) => ({
        ...state,
        view: action.payload
    }),
    [C.SET_COST_REPORT_FILTERS]: (state, action) => ({
        ...state,
        filters: action.payload.filters
    })
})

export default reducer
