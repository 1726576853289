import React, { useState } from "react"
import classNames from "classnames"

// displays a fallback image if the original one is missing
export const ImageFallback = ({
    src,
    fallbackSrc,
    className,
    alt = "",
    ...props
}) => {
    const [fallback, setFallback] = useState(null)
    return (
        <img
            {...props}
            alt={alt}
            src={fallback ?? src}
            onError={() => setFallback(fallbackSrc)}
            className={classNames(className, { fallback })}
        />
    )
}
