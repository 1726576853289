import React from "react"
import _ from "lodash"
import {
    DollarsCents,
    FractionalDollarsCentsCell,
    FractionalNumberCell,
    LongNumber,
    Percent,
    PivotCell
} from "../core/CellRenderers"
import { Hoverlay } from "../core/Hoverlay"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"

// Cost Report Table

const column = columnBuilder(C.TABLE_TRANSLATION)

export const SHARED_COLUMNS = [
    column(C.TABLE_KEYS.PATIENT_COUNT, {
        cellRenderer: FractionalNumberCell,
        width: 130,
        align: ALIGNMENTS.RIGHT,
        hover: "Patient Count (average)"
    }),
    column(C.TABLE_KEYS.EPISODE_COUNT, {
        cellRenderer: LongNumber,
        align: ALIGNMENTS.RIGHT,
        width: 100
    }),
    column(C.TABLE_KEYS.TOTAL_COST, {
        cellRenderer: DollarsCents,
        align: ALIGNMENTS.RIGHT,
        width: 150
    }),
    column(C.TABLE_KEYS.TOTAL_ALLOCATION, {
        cellRenderer: DollarsCents,
        align: ALIGNMENTS.RIGHT,
        width: 150
    }),
    column(C.TABLE_KEYS.PMPM_COST, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Cost Per Member Per Month",
        align: ALIGNMENTS.RIGHT,
        width: 120
    }),
    column(C.TABLE_KEYS.PMPM_ALLOCATION, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Allocation Per Member Per Month",
        align: ALIGNMENTS.RIGHT,
        width: 140
    }),
    column(C.TABLE_KEYS.PMPM_NETWORK, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Network-wide Cost Per Member Per Month",
        align: ALIGNMENTS.RIGHT,
        width: 160
    }),
    column(C.TABLE_KEYS.TARGET, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Target Expenditure Per Member Per Month",
        align: ALIGNMENTS.RIGHT,
        width: 120
    }),
    column(C.TABLE_KEYS.ALLOCATION_PERCENT, {
        cellRenderer: Percent,
        hover: "Ratio of total cost to allocation",
        align: ALIGNMENTS.RIGHT,
        width: 140
    }),
    column(C.TABLE_KEYS.NETWORK_PERCENT, {
        cellRenderer: Percent,
        hover: "Ratio of total cost to network cost",
        align: ALIGNMENTS.RIGHT,
        width: 140
    })

    // extra columns only used during development to make sure the numbers all add up
    /*column(C.TABLE_KEYS.TOTAL_NETWORK, {
        cellRenderer: DollarsCents,
        width: 150
    }),
    column(C.TABLE_KEYS.MEMBER_MONTHS, {
        width: 100
    }),
    column(C.TABLE_KEYS.NETWORK_MEMBER_MONTHS, {
        width: 100
    }),
    column(C.TABLE_KEYS.NETWORK_EPISODE_COUNT, {
        width: 100
    })*/
]

const CATEGORY_PIVOTS = [
    column(C.TABLE_KEYS.CATEGORY_NAME, {
        idField: C.TABLE_KEYS.CATEGORY_ID,
        canCollapse: true,
        width: 190
    }),
    column(C.TABLE_KEYS.SUBCATEGORY_NAME, {
        idField: C.TABLE_KEYS.SUBCATEGORY_ID,
        canCollapse: true,
        width: 140
    })
]
const PPP_PIVOTS = [
    column(C.TABLE_KEYS.PAYER_NAME, {
        idField: C.TABLE_KEYS.PAYER_ID,
        canCollapse: true,
        width: 185
    }),
    column(C.TABLE_KEYS.PRODUCT_NAME, {
        idField: C.TABLE_KEYS.PRODUCT_ID,
        canCollapse: true,
        width: 105
    }),
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        idField: C.TABLE_KEYS.PRACTICE_ID,
        canCollapse: true,
        width: 215
    }),
    column(C.TABLE_KEYS.PROVIDER_NAME, {
        idField: C.TABLE_KEYS.PROVIDER_ID,
        canCollapse: true,
        width: 205
    })
]

const markPivot = col => ({ ...col, cellRenderer: PivotCell, canPivot: true })

export const CATEGORY_COLUMNS = [
    ...CATEGORY_PIVOTS.map(markPivot),
    ...PPP_PIVOTS,
    ...SHARED_COLUMNS
]
export const PPP_COLUMNS = [
    ...PPP_PIVOTS.map(markPivot),
    ...CATEGORY_PIVOTS,
    ...SHARED_COLUMNS
]

const MEMBER_MONTH_SUM_VIEWS = {
    [C.VIEWS.CATEGORY]: depth => depth >= 2,
    [C.VIEWS.PAYER_PRACTICE_PROVIDER]: depth => depth < 4
}

export const COST_COLUMN_AGGREGATORS = {
    [C.TABLE_KEYS.EPISODE_COUNT]: _.sum,
    [C.TABLE_KEYS.NETWORK_EPISODE_COUNT]: _.sum,
    [C.TABLE_KEYS.TOTAL_COST]: _.sum,
    [C.TABLE_KEYS.TOTAL_ALLOCATION]: _.sum,
    [C.TABLE_KEYS.TOTAL_NETWORK]: _.sum,
    [C.TABLE_KEYS.MEMBER_MONTHS]: (memberMonths, children, { view, depth }) => {
        return MEMBER_MONTH_SUM_VIEWS[view](depth)
            ? _.sum(memberMonths)
            : _.max(memberMonths)
    }
}

// Procedures Table

const CLASS_HIDE = "hide-over-depth-1"

const LongCell = ({ cellData }) => <Hoverlay>{cellData}</Hoverlay>
const SimpleCell = ({ cellData }) => <React.Fragment>{cellData}</React.Fragment>

const procedureColumn = columnBuilder(C.TABLE_TRANSLATION, {
    cellRenderer: LongCell
})

export const PROCEDURE_COLUMNS = [
    procedureColumn(C.TABLE_KEYS.CATEGORY_NAME, {
        width: 100,
        cellRenderer: SimpleCell,
        className: CLASS_HIDE
    }),
    procedureColumn(C.TABLE_KEYS.SUBCATEGORY_NAME, {
        width: 130,
        className: CLASS_HIDE
    }),
    procedureColumn(C.TABLE_KEYS.PROCEDURE_NAME, {
        width: 180,
        flexGrow: 2,
        className: CLASS_HIDE
    }),
    procedureColumn(C.TABLE_KEYS.SERVICING_PROVIDER_NAME, {
        width: 150,
        flexGrow: 1
    }),
    procedureColumn(C.TABLE_KEYS.TOTAL_COST, {
        width: 100,
        cellRenderer: DollarsCents
    }),
    procedureColumn(C.TABLE_KEYS.AVERAGE_COST, {
        width: 110,
        cellRenderer: DollarsCents
    }),
    procedureColumn(C.TABLE_KEYS.EVENT_COUNT, {
        width: 70,
        cellRenderer: SimpleCell
    })
]
