import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import { isExternal } from "../../authentication/selectors"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import CommunityLinkages from "./CommunityLinkages"

export default fetcher({
    name: C.COMMUNITY_LINKAGES,
    endpoint: state => `/api/patients/${state.poc_id}/linkages`,
    method: ApiTypes.GET
})(connect(state => ({ isExternal: isExternal(state) }))(CommunityLinkages))
