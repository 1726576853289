import _ from "lodash"
import moment from "moment"
import { createSelector } from "reselect"
import { getResourceData, modifyData } from "../core/fetcher"
import { filtersCombiner } from "../toc/selectors"
import { moduleEnabledSelector } from "../moduleUtils"
import { FILTER_OPTIONS, MODULE_IDS } from "../constants"
import * as C from "./constants"
import { optionsMapSelector } from "../selectorUtils"

// these TCMs represent the values of dropdowns, but are received as strings instead of integers. We need to convert them before accepting.
export const acceptResponse = modifyData("tcms", tcm => ({
    ...tcm,
    ..._.mapValues(
        _.pick(tcm, [C.TABLE_KEYS.CONCLUSION, C.TABLE_KEYS.POST_FOLLOW_UP]),
        Number
    )
}))

export const tcmsSelector = createSelector(
    getResourceData(C.NAME, data => data.tcms),
    optionsMapSelector(data => data.tags),
    state => state.server_status.anchorDate,
    (tcms = [], tags, anchorDate) => {
        const anchorMoment = moment(anchorDate)
        return tcms.map(tcm => ({
            ...tcm,
            [C.TABLE_KEYS.PY_AGE]: anchorMoment.diff(
                moment(tcm[C.TABLE_KEYS.PATIENT_DOB]),
                "years"
            ),
            [C.TABLE_KEYS.AT_RISK]: _.zip(
                tcm[C.TABLE_KEYS.FLAG_ICONS],
                tcm[C.TABLE_KEYS.FLAG_COLORS],
                tcm[C.TABLE_KEYS.FLAG_DESCRIPTIONS]
            ).map(([icon, color, description]) => ({
                icon,
                color,
                description
            })),
            [C.TABLE_KEYS.TAGS]: _.compact(_.at(tags, tcm[C.TABLE_KEYS.TAGS]))
        }))
    }
)

export const filtersSelector = createSelector(
    state => state[C.NAME].filters,
    filtersCombiner
)

export const canWriteTcmSelector = moduleEnabledSelector(MODULE_IDS.TCM_WRITE)
