import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import settings from "../modules/site_settings"
import { GuideLink } from "../modules/indicators"

const pageTitle = "Site Settings"

const SettingsPage = () => (
    <>
        <SiteTitle title={pageTitle} />
        <Breadcrumbs items={[["Dashboard", "/dashboard"], [pageTitle]]} />
        <h1>
            {pageTitle}
            <GuideLink page="view-site-settings" />
        </h1>

        <settings.components.default />
    </>
)

export default SettingsPage
