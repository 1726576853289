import React, { useMemo } from "react"
import { connect, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getResourceData } from "../../core/fetcher"
import * as C from "../constants"

export const TableDescription = ({ children, Component = "div" }) =>
    children ? (
        <Component className="table-description">{children}</Component>
    ) : null

const descriptionSelector = tableName =>
    createSelector(getResourceData(C.TABLE_DESCRIPTIONS), data => ({
        children: data.descriptions?.[tableName]
    }))

export const connectDescription = tableName =>
    connect(descriptionSelector(tableName))

// use this one if you need a TableDescription outside a tableFactory context
// If you specify the `children` prop, it'll act as a fallback if there's no entry for this table in the database.
const ConnectedDescription = ({ tableName, children, ...props }) => {
    const selector = useMemo(() => descriptionSelector(tableName), [])
    const { children: newChildren } = useSelector(selector)

    return (
        <TableDescription {...props}>
            {newChildren ?? children}
        </TableDescription>
    )
}
export default ConnectedDescription
