import React from "react"
import { Field, reduxForm } from "redux-form"
import { Button } from "react-bootstrap"
import Loading from "../../core/Loading"
import { PasswordField, TextInput } from "../../core/CustomFields"
import * as C from "../constants"
import AssistanceText from "./AssistanceText"

export const LoginForm = ({ handleSubmit, auth }) => (
    <form>
        <Field
            name={C.LOGIN_FIELDS.USERNAME}
            component={TextInput}
            autoComplete="username"
            required
        />
        <Field
            name={C.LOGIN_FIELDS.PASSWORD}
            component={PasswordField}
            autoComplete="password"
            required
        />
        <AssistanceText />
        <div className="center-block">
            {auth.loading ? (
                <Loading />
            ) : (
                <Button
                    variant="primary"
                    className="btn-login"
                    type="submit"
                    onClick={handleSubmit}
                >
                    Login
                </Button>
            )}
        </div>
    </form>
)

export default reduxForm({ form: C.LOGIN_FORM })(LoginForm)
