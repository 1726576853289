import React, { Component } from "react"
import { connect } from "react-redux"
import AsyncSelect from "react-select/async"
import { transformPatient } from "../batch_print/selectors"
import { ApiTypes, createFetch } from "../api"
import { usToIsoDate } from "../dates"

import { authTokensSelector } from "../authentication/selectors"

const DATE_PATTERN = /\d\d\/\d\d\/\d\d\d\d/
const BASE_URL = "/api/patients?scope=LOCAL&type=SUMMARY&limit=50"

class PatientSelect extends Component {
    bySelectedPatients = item =>
        !(this.props.selectedPatients || []).includes(item) && // note that if we're inside a form, we won't have a selectedPatients prop... but it's probably single-select anyway
        !(this.props.selectedPatientIds || []).includes(item.value) // sometimes we'll get the patients as a list of ids instead of options

    getOptions = _.throttle(
        fuzzySearch => {
            const matchDate = fuzzySearch.match(DATE_PATTERN)
            const date = usToIsoDate((matchDate || [])[0])
            const url = date
                ? `${BASE_URL}&dobFrom=${date}&dobTo=${date}`
                : `${BASE_URL}&fuzzySearch=${fuzzySearch}`

            return createFetch({
                url,
                method: ApiTypes.GET,
                authentication: this.props.authentication
            })
                .then(res => res.json())
                .then(({ patients }) => patients.map(transformPatient))
        },
        500,
        { trailing: true }
    )

    render() {
        return (
            <AsyncSelect
                className="dropdown"
                isMulti={!this.props.single}
                isClearable={false}
                backspaceRemovesValue={false}
                controlShouldRenderValue={!!this.props.single}
                closeMenuOnSelect={!!this.props.single}
                openOnFocus
                cacheOptions
                defaultOptions
                hideSelectedOptions
                loadOptions={this.getOptions}
                onChange={this.props.onChange}
                value={this.props.selectedPatients}
                filterOption={this.bySelectedPatients}
            />
        )
    }
}

export default connect(state => ({
    authentication: authTokensSelector(state)
}))(PatientSelect)
