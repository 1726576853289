import React, { useEffect, useRef, useState } from "react"
import BaseSelect from "react-select"
import Creatable from "react-select/creatable"
import classNames from "classnames"
import { findByValue, getValues, isNilOrEmpty } from "../utils"

const closeMenuOnScroll = event =>
    !event.target.classList?.contains("select__menu-list") // always close the menu on any kind of scroll, except scrolling inside the menu itself. Otherwise, if you have a dropdown inside a scrollable container, you can scroll the select out of visibility, but the menu will still be visible.

const getValue = item => (item ? item.value : null)

const customOption = value => ({
    label: value,
    value
})

const findOption = (value, options, custom) =>
    findByValue(options, value) || (custom ? customOption(value) : null)

const getSelectedOptions = (
    value,
    { clearWhenDisabled, disabled, options, multi },
    custom
) => {
    if (clearWhenDisabled && disabled) {
        return null // returning 'null' because passing 'undefined' to the Select keeps the previous value. Only 'null' clears it.
    }
    if (isNilOrEmpty(value)) {
        return undefined // fall back to the default value, if any
    }
    if (multi) {
        return value
            .map(val => findOption(val, options, custom))
            .filter(x => !!x)
    }
    return findOption(value, options, custom)
}

const getOnChange = (onChange, multi) => newSelected =>
    onChange?.(multi ? getValues(newSelected) : getValue(newSelected))

export const Select = (props, forwardedRef) => {
    const {
        className,
        disabled,
        multi,
        loading,
        defaultValue,
        value,
        onChange
    } = props

    const ref = forwardedRef ?? useRef()

    return (
    <BaseSelect
        ref={ref}
            menuPortalTarget={document.body}
        classNamePrefix="select"
        menuPlacement="auto"
        tabSelectsValue={false}
        menuShouldScrollIntoView={true}
        captureMenuScroll={true}
        onBlur={event => event.preventDefault()} // gets overridden in SelectField
        {...props}
        isMulti={multi}
        isDisabled={disabled}
        isLoading={loading}
        className={classNames("select", className)}
        defaultValue={getSelectedOptions(defaultValue, props, false)}
        value={getSelectedOptions(value, props, false)}
        onChange={getOnChange(onChange, multi)}
            closeMenuOnScroll={closeMenuOnScroll}
    />
)
}

export default React.forwardRef(Select)

export const CreatableSelect = props => {
    const {
        className,
        disabled,
        loading,
        multi,
        onChange,
        options: defaultOptions,
        defaultValue,
        value
    } = props

    const ref = useRef()

    const [options, setOptions] = useState([]) // store options in state because users can add new options
    useEffect(() => {
        setOptions(defaultOptions || [])
    }, [defaultOptions]) // reset options if default options change

    return (
        <Creatable
            ref={ref}
            menuPortalTarget={document.body}
            allowCreateWhileLoading
            classNamePrefix="select"
            createOptionPosition="first"
            placeholder="Select, or enter new value..."
            {...props}
            isMulti={multi}
            isDisabled={disabled}
            isLoading={loading}
            className={classNames("select select-creatable", className)}
            defaultValue={getSelectedOptions(defaultValue, props, true)}
            value={getSelectedOptions(value, props, true)}
            onChange={getOnChange(onChange, multi)}
            onCreateOption={newValue => {
                setOptions([...options, customOption(newValue)])
                onChange(multi ? [...value, newValue] : newValue)
            }}
            closeMenuOnScroll={closeMenuOnScroll}
        />
    )
}
