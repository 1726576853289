import { connect } from "react-redux"
import { getFormSyncErrors } from "redux-form"
import { getResourceDataAsObject } from "../../core/fetcher"
import { FILTER_OPTIONS } from "../../constants"
import * as C from "../constants"
import * as reduxActions from "../actions"
import AddPatientModal from "./AddPatientModal"

export default connect(
    state => ({
        open: state[C.ADD_NEW_PATIENT].open,
        options: getResourceDataAsObject(FILTER_OPTIONS)(state),
        errors: getFormSyncErrors(C.ADD_NEW_PATIENT_FORM)(state)
    }),
    reduxActions
)(AddPatientModal)
