import React, { useEffect, useMemo, useState } from "react"
import { Card, Tab, Tabs } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import SearchBar from "../../core/SearchBar"
import Select from "../../core/Select"
import { filterPracticeUnits } from "../selectors"
import PracticeUnit from "./PracticeUnit"

const TABS = [
    { isSpecialist: false, field: "pcpUnits", title: "PCPs" },
    { isSpecialist: true, field: "specialistUnits", title: "Specialists" }
]

const DirectoryCard = ({
    error,
    list,
    loading,
    options,
    filter,
    setFilter,
    regionIds,
    setRegionIds
}) => {
    const units = useMemo(() => filterPracticeUnits(list, filter, regionIds), [
        list,
        filter,
        regionIds
    ])

    return (
        <Card id="directory" className="directory">
            <Card.Header>
                <Card.Title as="h2">
                    Practice Units &amp; Practitioners
                </Card.Title>
                <div className="form-group directory-filters">
                    <SearchBar
                        className="directory-filter-search"
                        placeholder="Filter"
                        value={filter}
                        onChange={setFilter}
                    />
                    <Select
                        options={options}
                        value={regionIds}
                        onChange={setRegionIds}
                        className="directory-filter-region"
                        placeholder="Select practice tags..."
                        multi
                    />
                </div>
            </Card.Header>
            <Card.Body id="physician-list">
                <LEHOC loading={loading} error={error} list={units}>
                    <ul className="physician-list dataTable table table-striped table-hover table-wrapper">
                        {units.map(unit => (
                            <PracticeUnit
                                key={unit.practiceUnitId}
                                unit={unit}
                                filter={filter}
                            />
                        ))}
                    </ul>
                </LEHOC>
            </Card.Body>
        </Card>
    )
}

export const Directory = ({ data, error, loading, regionOptions }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [filter, setFilter] = useState("")
    const [regionIds, setRegionIds] = useState([])

    return (
        <Tabs defaultActiveKey={0} id="physician-list-tabs" justify>
            {TABS.map(({ field, isSpecialist, title }, index) => (
                <Tab key={index} eventKey={index} title={title}>
                    <DirectoryCard
                        options={regionOptions}
                        loading={loading}
                        error={error}
                        list={data[field]}
                        filter={filter}
                        setFilter={setFilter}
                        regionIds={regionIds}
                        setRegionIds={setRegionIds}
                    />
                </Tab>
            ))}
        </Tabs>
    )
}

export default Directory
