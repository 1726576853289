import _ from "lodash"
import moment from "moment"
import { displayMonths, MONTH_STRING, numerateMonth } from "../dates"
import { objToValueLabel } from "../utils"

export const PERIODS = {
    CURRENT_YEAR: "CURRENT_YEAR",
    LAST_12_MONTHS: "LAST_12_MONTHS"
}

export const PERIOD_TRANSLATION = {
    [PERIODS.CURRENT_YEAR]: "Current Year",
    [PERIODS.LAST_12_MONTHS]: "Last 12 Months"
}
export const PERIOD_OPTIONS = objToValueLabel(PERIOD_TRANSLATION)

const lastMonth = moment()
    .subtract(1, "month")
    .startOf("month")
const startOfYear = moment(lastMonth).startOf("year")
const oneYearAgo = moment()
    .subtract(1, "year")
    .startOf("month")

export const PERIOD_MONTHS = {
    [PERIODS.CURRENT_YEAR]: {
        startMonthId: startOfYear,
        endMonthId: lastMonth
    },
    [PERIODS.LAST_12_MONTHS]: {
        startMonthId: oneYearAgo,
        endMonthId: lastMonth
    }
}
export const PERIOD_MONTH_STRINGS = _.mapValues(PERIOD_MONTHS, period =>
    _.mapValues(period, m => m.format(MONTH_STRING))
)
export const PERIOD_MONTH_IDS = _.mapValues(PERIOD_MONTHS, period =>
    _.mapValues(period, numerateMonth)
)
export const PERIOD_DISPLAY = _.mapValues(
    PERIOD_MONTHS,
    ({ startMonthId, endMonthId }) => displayMonths(startMonthId, endMonthId)
)

export const VIEWS = {
    NETWORK: "network",
    PRACTICE: "practice",
    PROVIDER: "provider"
}
export const VIEW_TRANSLATION = {
    [VIEWS.NETWORK]: "Network",
    [VIEWS.PRACTICE]: "Practice",
    [VIEWS.PROVIDER]: "Provider"
}
export const VIEW_OPTIONS = objToValueLabel(VIEW_TRANSLATION)
