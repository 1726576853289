import { TimestampDate } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"
import {
    ClearAlert,
    ColorCell,
    ColoredTooltipCell
} from "./components/CellRenderers"

const TIMESTAMP_WIDTH = 150

const column = columnBuilder(C.TRANSLATION)
export const COLUMNS = [
    column(C.TABLE_KEYS.TYPE, { width: 120 }),
    column(C.TABLE_KEYS.COLOR, { cellRenderer: ColorCell, width: 100 }),
    column(C.TABLE_KEYS.START_DATE, {
        cellRenderer: TimestampDate,
        width: TIMESTAMP_WIDTH
    }),
    column(C.TABLE_KEYS.END_DATE, {
        cellRenderer: TimestampDate,
        width: TIMESTAMP_WIDTH
    }),
    column(C.TABLE_KEYS.MESSAGE, {
        cellRenderer: ColoredTooltipCell,
        width: 500,
        flexGrow: 2
    }),
    column(C.TABLE_KEYS.CLEAR_ALERT, {
        cellRenderer: ClearAlert,
        width: 60,
        align: ALIGNMENTS.CENTER
    })
]
