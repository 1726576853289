import React, { Component } from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import _ from "lodash"

import * as C from "../../../../constants"
import { idLookUpSelector } from "../../../../selectors"
import { getAllDetails, getIdName, getValuesAndMeta } from "../../../../helpers"
import definitions from "./definitions"

export class AutoCalculatedDate extends Component {
    state = { error: false }

    componentDidUpdate(prevProps) {
        const {
            dateInput,
            prevValue,
            nextValue,
            definition
        } = this.autoCalcProps(prevProps)

        if (!definition) {
            // this isn't even an autocalc measure
            return
        }

        const details = getAllDetails(
            prevProps.values,
            this.props.values,
            definition,
            this.props.idLookup
        )
        const { date, error } = getValuesAndMeta(details)

        if (error !== this.state.error) {
            this.setState({
                error
            })
        }

        const valuesAreDifferent = prevValue !== nextValue
        const datesAreDifferent =
            (!!date || !!dateInput.value) && date !== dateInput.value // have to check because dateInput.value will be "" if it's empty, while date will be null

        if (_.isNil(nextValue)) {
            // if there's no calculated value (due to not having the relevant measures filled out yet, or due to the formula actively declining to return a value), we don't show a date at all.
            if (valuesAreDifferent) {
                // still might need to change from undefined to null or vice versa, though
                dateInput.onChange(nextValue)
            }
            return
        }

        if (valuesAreDifferent || datesAreDifferent) {
            dateInput.onChange(date || undefined)
        }
    }

    autoCalcProps = prevProps => {
        const name = getIdName(this.props.measure.id)
        return {
            dateInput: this.props.input,
            prevValue: prevProps ? prevProps.values[name] : null,
            nextValue: this.props.values[name],
            definition: definitions[name]
        }
    }

    render() {
        return (
            <p className="form-control-static">
                <span className={classNames(this.state.error && "error_label")}>
                    {this.props.input.value || "Auto Calculated"}
                </span>
            </p>
        )
    }
}

export default connect(state => ({
    values: getFormValues(C.POC_MEASURE_FORM)(state),
    idLookup: idLookUpSelector(state)
}))(AutoCalculatedDate)
