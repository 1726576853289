import * as C from "./constants"
import { modifyData, removeFromData } from "../core/fetcher"

export const getErrorKey = (field, transitionId) => `${field}-${transitionId}`

export const removeLocalTcm = id =>
    removeFromData("tcms", C.TABLE_KEYS.TCM_TRACKER_ID, id)

export const updateLocalTcm = newValues => id =>
    modifyData("tcms", newValues, C.TABLE_KEYS.TCM_TRACKER_ID, id)
