import { connect } from "react-redux"
import { initialize } from "redux-form"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import { Alerts } from "./Alerts"

export default fetcher({
    name: C.NAME,
    endpoint: "/alert",
    method: ApiTypes.GET
})(
    connect(null, {
        ...actions,
        initialize
    })(Alerts)
)
