import React, { Component } from "react"
import { Card, Collapse } from "react-bootstrap"
import CardTitle from "../../../core/CardTitle"
import { MeasureSubcategory } from "./MeasureSubcategory"

export default class MeasureCategory extends Component {
    state = { open: true }
    handleToggle = () => this.setState({ open: !this.state.open })

    render() {
        const {
            category: { name, incentivized, subCategories },
            actions,
            disabled
        } = this.props
        return (
            <Card>
                <Card.Header role="tab">
                    <CardTitle>
                        <button
                            onClick={this.handleToggle}
                            aria-expanded={this.state.open}
                        >
                            {name}
                            {incentivized !== 0 && <mark>{incentivized}</mark>}
                            <span className="toggle pull-right" />
                        </button>
                    </CardTitle>
                </Card.Header>
                <Collapse
                    className="card-collapse"
                    in={this.state.open}
                    aria-expanded={this.state.open}
                >
                    <Card.Body>
                        {subCategories.map(subcategory => (
                            <MeasureSubcategory
                                key={subcategory.name}
                                subcategory={subcategory}
                                actions={actions}
                                disabled={disabled}
                            />
                        ))}
                    </Card.Body>
                </Collapse>
            </Card>
        )
    }
}
