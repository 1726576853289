import React from "react"
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc"
import classNames from "classnames"

const DragHandle = SortableHandle(() => (
    <span className="fa fa-bars drag-handle" />
)) // This can be any component you want

const SortableItem = SortableElement(({ column, toggleItem }) => (
    <div
        className={classNames(
            "table_settings_item",
            "wrapper",
            column.forceSelected && "force-selected",
            column.selected && "selected"
        )}
        onClick={() => column.forceSelected || toggleItem(column.key)}
    >
        <DragHandle />
        {column.label}
        <div />
    </div>
))

const Spacer = SortableElement(() => <div className="column-list-spacer" />)

// noinspection JSValidateTypes
export const SortableList = SortableContainer(
    ({ items = [], freezeItems = [], toggleItem, noFreeze }) => (
        <div className="table_settings">
            {noFreeze || (
                <React.Fragment>
                    {freezeItems.map((column, index) => (
                        <SortableItem
                            toggleItem={toggleItem}
                            key={`item-${index}`}
                            index={index}
                            column={column}
                        />
                    ))}
                    <Spacer index={freezeItems.length} />
                </React.Fragment>
            )}
            {items.map((column, index) => {
                const trueIndex = freezeItems.length + index + !noFreeze // +1 for the spacer if freezing is active
                return (
                    <SortableItem
                        toggleItem={toggleItem}
                        key={`item-${trueIndex}`}
                        index={trueIndex}
                        column={column}
                    />
                )
            })}
        </div>
    )
)
