import { getFormValues, isInvalid } from "redux-form"
import { connect } from "react-redux"
import { getResourceData } from "../../../core/fetcher"
import { FILTER_OPTIONS } from "../../../constants"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import CareManagerModal from "./CareManagerModal"

export default connect(state => {
    const { careManagers, reasons } = getResourceData(FILTER_OPTIONS)(state)
    return {
        ...state[C.NAME],
        formValues: getFormValues(C.EDIT_CARE_MANAGER_FORM)(state),
        invalid: isInvalid(C.EDIT_CARE_MANAGER_FORM)(state),
        careManagers,
        reasons
    }
}, reduxActions)(CareManagerModal)
