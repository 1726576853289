import _ from "lodash"
import { LongCell } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"
import {
    AddToCampaign,
    RemoveFromCampaign,
    CampaignName,
    PinUnpin,
    HideCampaign,
    EditCampaign,
    DeleteCampaign
} from "./components/cell_renderer"

const restoreHidden = item =>
    item.key !== C.TABLE_KEYS.HIDDEN_VISIBILITY
        ? item
        : { ...item, label: "Restore" }

const column = columnBuilder(C.CAMPAIGN_TRANSLATION, {
    flexGrow: 1
})

const COLUMN_PINNED = column(C.TABLE_KEYS.PINNED, {
    width: 40,
    flexGrow: 0,
    label: "",
    style: { padding: 0 }, // ensures that the icon stays centered, without any extra padding to mess it up
    align: ALIGNMENTS.CENTER,
    cellRenderer: PinUnpin,
    disableSort: true
})

const TABLE_COLUMNS = [
    COLUMN_PINNED,
    column(C.TABLE_KEYS.CAMPAIGN_NAME, {
        width: 200,
        flexGrow: 5,
        cellRenderer: CampaignName
    }),
    column(C.TABLE_KEYS.CAMPAIGN_DESCRIPTION, {
        width: 200,
        flexGrow: 5,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.CAMPAIGN_OWNER, {
        width: 100,
        flexGrow: 2
    }),
    column(C.TABLE_KEYS.EDIT_CAMPAIGN, {
        width: 40,
        cellRenderer: EditCampaign,
        disableSort: true
    }),
    column(C.TABLE_KEYS.DELETE_CAMPAIGN, {
        width: 50,
        cellRenderer: DeleteCampaign,
        disableSort: true
    }),
    column(C.TABLE_KEYS.HIDDEN_VISIBILITY, {
        width: 50,
        cellRenderer: HideCampaign,
        disableSort: true
    })
]

export const CAMPAIGN_COLUMNS = {
    [C.SECTIONS.FAVOURITES]: [
        ...TABLE_COLUMNS.filter(
            item => item.key !== C.TABLE_KEYS.HIDDEN_VISIBILITY
        ),
        column(C.VISIBILITY_SCOPE, {
            width: 50,
            flexGrow: 1,
            cellFormatter: _.capitalize
        })
    ],
    [C.SECTIONS.CREATED]: TABLE_COLUMNS,
    [C.SECTIONS.HVPA_WIDE]: TABLE_COLUMNS,
    [C.SECTIONS.SHARED]: TABLE_COLUMNS,
    [C.SECTIONS.HIDDEN]: TABLE_COLUMNS.map(restoreHidden).filter(
        item =>
            item.key !== C.TABLE_KEYS.EDIT_CAMPAIGN &&
            item.key !== C.TABLE_KEYS.DELETE_CAMPAIGN &&
            item.key !== C.TABLE_KEYS.PINNED
    )
}

export const FAVORITE_CAMPAIGN_COLUMNS = [
    COLUMN_PINNED,
    column(C.TABLE_KEYS.CAMPAIGN_NAME, {
        label: "Campaign",
        width: 100,
        flexGrow: 1,
        cellRenderer: CampaignName
    }),
    column(C.TABLE_KEYS.CAMPAIGN_DESCRIPTION, {
        width: 200,
        flexGrow: 2,
        cellRenderer: LongCell,
        disableSort: true
    })
]

const BASE_PATIENT_CAMPAIGN_TABLE_COLUMNS = [
    column(C.TABLE_KEYS.CAMPAIGN_NAME, {
        label: "Campaign",
        width: 50,
        cellRenderer: CampaignName,
        disableSort: true
    }),
    column(C.TABLE_KEYS.CAMPAIGN_OWNER, {
        width: 50,
        disableSort: true
    })
]
export const PATIENT_CAMPAIGN_TABLE_COLUMNS = [
    ...BASE_PATIENT_CAMPAIGN_TABLE_COLUMNS,
    column(C.TABLE_KEYS.MANUALLY_ADDED, {
        label: "Remove",
        width: 120,
        flexGrow: 0,
        disableSort: true,
        cellRenderer: RemoveFromCampaign
    })
]
export const PATIENT_OTHER_CAMPAIGN_TABLE_COLUMNS = [
    ...BASE_PATIENT_CAMPAIGN_TABLE_COLUMNS,
    column(C.TABLE_KEYS.MANUALLY_ADDED, {
        label: "Add",
        width: 120,
        flexGrow: 0,
        disableSort: true,
        cellRenderer: AddToCampaign
    })
]
