import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { getResourceDataAsObject } from "../../../core/fetcher"
import { PayerIcon } from "../../../indicators"
import { SETTINGS } from "../../../constants"

export const OrgIcon = ({ orgName, logo, className, dispatch, ...props }) =>
    logo ? (
        <PayerIcon
            logo={logo}
            alt={`${orgName}-wide`}
            className={classNames("pin-icon-org", className)}
            {...props}
        />
    ) : (
        <span className="fa fa-thumb-tack pin-icon-fixed" />
    )

export default connect(state => {
    const organization = getResourceDataAsObject(
        SETTINGS,
        data => data.organization
    )(state)
    return {
        orgName: organization.abbrevName || "Network",
        logo: organization.logo
    }
})(OrgIcon)
