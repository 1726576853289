import React from "react"
import { Hoverlay } from "../../core/Hoverlay"
import { formatDollars, formatDollarsCents } from "../../numbers"

export const MoneyBreakdown = ({
    rowData,
    columnData: { availableKey, earnedKey }
}) => {
    const moneyAvailable = rowData[availableKey]
    const moneyEarned = rowData[earnedKey]
    const moneyLeft = moneyAvailable - moneyEarned
    return (
        <Hoverlay
            tooltip={
                <React.Fragment>
                    <span>
                        Revenue Available: {formatDollarsCents(moneyAvailable)}
                    </span>
                    <br />
                    <span>
                        Revenue Earned: {formatDollarsCents(moneyEarned)}
                    </span>
                    <br />
                    <span>
                        Revenue Remaining: {formatDollarsCents(moneyLeft)}
                    </span>
                </React.Fragment>
            }
        >
            <div className="bar-indicator">
                <div
                    className="money_earned"
                    style={{
                        width: `${
                            moneyAvailable === 0
                                ? 0
                                : (moneyEarned / moneyAvailable) * 100
                        }%`
                    }}
                >
                    <span>{formatDollars(moneyEarned)}</span>
                </div>
                <div
                    className="money_left"
                    style={{
                        width: `calc(${
                            moneyAvailable === 0
                                ? 0
                                : (moneyLeft / moneyAvailable) * 100
                        }% + ${moneyEarned === 0 ? "0px" : "4px"})`,
                        marginLeft: moneyEarned === 0 ? undefined : "-4px"
                    }}
                >
                    <span>{formatDollars(moneyLeft)}</span>
                </div>
            </div>
        </Hoverlay>
    )
}
