import { connect } from "react-redux"
import { undoTransformFilter } from "../../../transformFilter"
import { ApiTypes } from "../../../api"
import fetcher from "../../../core/fetcher"
import { setPatientFilter } from "../../../patient_list/actions"

import * as C from "../../constants"
import * as reduxActions from "../../actions"
import {
    campaignIsModifiedSelector,
    tableFactorySelector
} from "../../selectors"
import { CampaignPage } from "./CampaignPage"

export default fetcher({
    name: C.CAMPAIGN_PAGE,
    endpoint: state => `/api/campaigns/${state[C.NAME][C.CURRENT_CAMPAIGN]}`,
    method: ApiTypes.GET,
    acceptResponse: data => ({
        ...data,
        columns: data.columns ?? undefined, // normally it comes back as null if no columns were specified, but if it's undefined it's easier to override
        frozenColumns: data.frozenColumns ?? undefined,
        campaign: {
            ...data.campaign,
            filter: undoTransformFilter(JSON.parse(data.campaign.filterJson)),
            filterJson: undefined
        }
    })
})(
    connect(
        state => ({
            currentUser: state.authentication.loginId,
            isModified: campaignIsModifiedSelector(state),
            ...tableFactorySelector(state)
        }),
        { ...reduxActions, setPatientFilter }
    )(CampaignPage)
)
