import * as C from "./constants"

export const printQSR = payload => ({ type: C.PRINT_QSR, payload })
export const exportQSR = payload => ({ type: C.EXPORT_QSR, payload })

export const setFilters = payload => ({ type: C.SET_QSR_FILTERS, payload })
export const setChartMeasure = payload => ({
    type: C.SET_QSR_CHART_MEASURE,
    payload
})
