import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { getResourceData, modifyResource } from "../core/fetcher"
import { ApiTypes } from "../api"
import { tryWithNotifications } from "../notifications"
import { usToIsoDate } from "../dates"
import * as C from "./constants"
import {
    hideDeactivatePatient,
    showDeactivatePatient,
    showEditPatientInfo,
    updatePatientInfoSuccess
} from "./actions"
import {
    deactivatedSuccess,
    reactivatedSuccess
} from "./components/notifications"

export function* updatePatientInfo(action) {
    const poc_id = yield select(state => state.poc_id)

    yield tryWithNotifications(
        {
            url: `/api/patients/${poc_id}/info`,
            method: ApiTypes.PUT,
            body: action.payload,
            message: null // don't show a message while saving
        },
        function*(body) {
            yield put(updatePatientInfoSuccess())
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: () => body
                })
            )
        },
        function*() {
            yield put(showEditPatientInfo()) // to stop the loading
            return "Failed to update patient info"
        }
    )
}

export function* deactivatePatient(action) {
    const poc_id = yield select(state => state.poc_id)
    const patientName = yield select(
        getResourceData(C.NAME, data => data[C.KEYS.NAME])
    )

    yield* tryWithNotifications(
        {
            url: `/api/patients/${poc_id}`,
            method: ApiTypes.DELETE,
            body: {
                ...action.payload,
                [C.KEYS.EFF_DATE]: usToIsoDate(action.payload[C.KEYS.EFF_DATE])
            }
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: data => ({
                        ...data,
                        [C.KEYS.DEACTIVATED]: true
                    })
                })
            )

            yield put(hideDeactivatePatient())
            return yield deactivatedSuccess(patientName)
        },
        function*() {
            yield put(showDeactivatePatient()) // to stop the loading
            return "Failed to deactivate patient."
        }
    )
}

export function* reactivatePatient() {
    const poc_id = yield select(state => state.poc_id)
    const patientName = yield select(
        getResourceData(C.NAME, data => data[C.KEYS.NAME])
    )

    yield* tryWithNotifications(
        {
            url: `/api/patients/${poc_id}`,
            method: ApiTypes.PUT,
            needsBody: false
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: data => ({
                        ...data,
                        [C.KEYS.DEACTIVATED]: false
                    })
                })
            )

            return yield reactivatedSuccess(patientName)
        },
        "Failed to reactivate patient."
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.UPDATE_PATIENT_INFO, updatePatientInfo)
        }),
        call(function*() {
            yield takeEvery(C.DEACTIVATE_CURRENT_PATIENT, deactivatePatient)
        }),
        call(function*() {
            yield takeEvery(C.REACTIVATE_CURRENT_PATIENT, reactivatePatient)
        })
    ])
}
