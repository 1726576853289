import _ from "lodash"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { modifyData, modifyResource } from "../core/fetcher"
import { tryWithNotifications } from "../notifications"
import {
    deactivatedSuccess,
    reactivatedSuccess
} from "../info/components/notifications"
import { ApiTypes } from "../api"
import * as C from "./constants"

function* setPatientActiveStatus(rowData, active) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: modifyData(
                "patients",
                { [C.TABLE_KEYS.ACTIVE]: active },
                C.TABLE_KEYS.PATIENT_ID,
                rowData
            )
        })
    )
}

function* deactivatePatient(action) {
    const [patientId, patientName, reasonId, effDt] = _.at(action.payload, [
        C.TABLE_KEYS.PATIENT_ID,
        C.TABLE_KEYS.PATIENT_NAME,
        C.TABLE_KEYS.REASON_ID,
        C.TABLE_KEYS.EFF_DATE
    ])

    yield* tryWithNotifications(
        {
            url: `/api/patients/${patientId}`,
            method: ApiTypes.DELETE,
            body: {
                [C.TABLE_KEYS.REASON]: reasonId,
                [C.TABLE_KEYS.EFF_DATE]: effDt
            }
        },
        function*() {
            yield* setPatientActiveStatus(action.payload, false)
            return yield deactivatedSuccess(patientName)
        },
        "Failed to deactivate patient."
    )
}

function* reactivatePatient(action) {
    const [patientId, patientName] = _.at(action.payload, [
        C.TABLE_KEYS.PATIENT_ID,
        C.TABLE_KEYS.PATIENT_NAME
    ])

    yield* tryWithNotifications(
        {
            url: `/api/patients/${patientId}`,
            method: ApiTypes.PUT,
            needsBody: false
        },
        function*() {
            yield* setPatientActiveStatus(action.payload, true)
            return yield reactivatedSuccess(patientName)
        },
        "Failed to reactivate patient."
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.DEACTIVATE_PATIENT, deactivatePatient)
        }),
        call(function*() {
            yield takeEvery(C.REACTIVATE_PATIENT, reactivatePatient)
        })
    ])
}
