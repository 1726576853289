import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import { canWriteTcmSelector } from "../../tcm/selectors"
import * as C from "../constants"
import { defaultOrderBys, PriorityAttention } from "./PriorityAttention"

export { default as TCMPopover } from "./TCMPopover"
export { default as TCMPopoverForm } from "./TCMPopoverForm"

const PriorityAttentionWrapper = connect(state => ({
    canEdit: canWriteTcmSelector(state)
}))(PriorityAttention)

export default fetcher({
    name: C.NAME,
    endpoint: state => `/api/patients/${state.poc_id}/tcm`,
    defaultOrderBys,
    paginationKey: "tcms"
})(PriorityAttentionWrapper)
//
