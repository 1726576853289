import React from "react"
import { Link } from "react-router-dom"
import { Hoverlay } from "../../../core/Hoverlay"

export const ReportListRow = ({ item, isSelected }) => (
    <li className="report-name">
        <Hoverlay
            disabled={!item.description && item.public}
            tooltip={
                <>
                    {item.description}
                    {item.description && !item.public && <br />}
                    {!item.public && "Only visible to superusers."}
                </>
            }
            placement="right"
        >
            <Link
                to={`/reports/library/${item.reportId}`}
                style={
                    isSelected
                        ? {
                              fontWeight: "bold",
                              textDecoration: "none",
                              cursor: "default"
                          }
                        : {
                              cursor: "pointer"
                          }
                }
            >
                {item.reportName}
            </Link>
            {item.public || <span className="fa fa-lock" />}
        </Hoverlay>
    </li>
)
