import { createReducer } from "../utils"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.SHOW_EDIT_CARE_MANAGER]: (state, action) => ({
        ...state,
        show: true,
        loading: false,
        initialValues: action.payload || {},
        isUpdate: !!action.payload
    }),
    [C.STOP_SAVING_CARE_MANAGER]: state => ({
        ...state,
        loading: false
    }),
    [C.HIDE_EDIT_CARE_MANAGER]: state => ({
        ...state,
        show: false,
        loading: false
    }),
    [C.SAVE_CARE_MANAGER]: state => ({
        ...state,
        show: true,
        loading: true
    }),
    [C.UPDATE_INITIAL_VALUES]: (state, action) => ({
        ...state,
        initialValues: action.payload || {}
    })
})

export default reducer
