import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Card } from "react-bootstrap"
import classNames from "classnames"
import { CheckboxField } from "../../core/CustomFields"
import UndoRemoveButtons from "../../core/UndoRemoveButtons"
import { SimpleModal } from "../../core/SimpleModal"
import {
    dateFormat,
    TIMESTAMP_STRING,
    TIMESTAMP_STRING_PRETTY
} from "../../dates"
import { useIncrease } from "../../hooks"
import { canEditSelector, recordSelector } from "../selectors"
import { getTitle } from "../helpers"
import * as actions from "../actions"
import * as C from "../constants"

const getNoteAuthor = props =>
    props[C.TABLE_KEYS.CREATED_BY_NAME] ||
    (props[C.TABLE_KEYS.CREATED_BY_ID] === 0 ? "Admin" : "Unknown")

export const Note = ({
    buttonLabel = "Save changes",
    cancel = () => {},
    canEdit,
    forceEdit,
    className,
    note,
    remove,
    restore,
    submit,
    saving,
    stopSaving
}) => {
    const [createdDate, deleted, text, title, isImportant] = _.at(note, [
        C.TABLE_KEYS.CREATED_DATE,
        C.TABLE_KEYS.DELETED,
        C.TABLE_KEYS.NOTE,
        C.TABLE_KEYS.CREATED_BY_NAME,
        C.TABLE_KEYS.HIGH_IMPORTANCE
    ])

    const [noteText, setNoteText] = useState(text)
    const [important, setImportant] = useState(isImportant)
    const [editing, setEditing] = useState(!!forceEdit)

    useEffect(() => {
        setEditing(forceEdit)
        stopSaving()
    }, [deleted, text, isImportant])

    const unchanged = text === noteText && isImportant === important
    const showInputs = canEdit && editing && !saving

    return (
        <li
            className={classNames("audit-note", className, {
                "audit-note-deleted": deleted,
                "audit-note-important": important
            })}
        >
            <div className="audit-note-header">
                <h4>{title}</h4>
                {showInputs && (
                    <CheckboxField
                        label="High importance?"
                        labelRight
                        input={{
                            value: important,
                            onClick: () => setImportant(!important)
                        }}
                    />
                )}
                <span className="audit-note-date">{createdDate}</span>
            </div>
            {showInputs ? (
                <>
                    <textarea
                        rows={1}
                        value={noteText}
                        className="form-control"
                        onChange={event => setNoteText(event.target.value)}
                    />
                    <Button
                        size="sm"
                        disabled={!noteText || unchanged}
                        onClick={() => {
                            // setSaving(true)
                            submit(noteText, important)
                        }}
                    >
                        {buttonLabel}
                    </Button>
                </>
            ) : (
                <p>{noteText}</p>
            )}
            <div className="audit-note-buttons">
                {!canEdit ? null : showInputs ? (
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                            cancel()
                            setEditing(false)
                            setNoteText(text)
                            setImportant(isImportant)
                        }}
                    >
                        Cancel
                    </Button>
                ) : (
                    <>
                        {!saving && !deleted && (
                            <button
                                className="btn-blank"
                                onClick={() => setEditing(true)}
                            >
                                <span className="fa fa-edit" />
                            </button>
                        )}
                        <UndoRemoveButtons
                            rowData={note}
                            saving={saving}
                            field={C.TABLE_KEYS.RECORD_NOTE_ID}
                            statusField={C.TABLE_KEYS.DELETED}
                            remove={remove}
                            undo={restore}
                        />
                    </>
                )}
            </div>
        </li>
    )
}

export const NotesModal = ({
    canEdit,
    closeModal,
    notesModalOpen,
    notesSaving,
    record,
    newNote,
    updateNote,
    removeNote,
    restoreNote,
    stopSavingNote,
    userId
}) => {
    const [addingNew, setAddingNew] = useState(false)
    const notes = record[C.TABLE_KEYS.NOTES]

    // if a new note is added, stop editing
    useIncrease(notes?.length, () => {
        setAddingNew(false)
    })

    return (
        <SimpleModal
            className="audits-modal audit-notes"
            title={getTitle("Patient Audit Notes", record)}
            show={notesModalOpen}
            closeModal={closeModal}
        >
            {_.isEmpty(notes) || (
                <Card as="ol">
                    {_.map(notes, note => (
                        <Note
                            key={note[C.TABLE_KEYS.RECORD_NOTE_ID]}
                            saving={notesSaving.includes(
                                note[C.TABLE_KEYS.RECORD_NOTE_ID]
                            )}
                            stopSaving={stopSavingNote}
                            note={{
                                ...note,
                                [C.TABLE_KEYS.CREATED_DATE]: dateFormat(
                                    note[C.TABLE_KEYS.CREATED_DATE],
                                    TIMESTAMP_STRING,
                                    TIMESTAMP_STRING_PRETTY
                                ),
                                [C.TABLE_KEYS.CREATED_BY_NAME]: getNoteAuthor(
                                    note
                                )
                            }}
                            canEdit={
                                canEdit &&
                                note[C.TABLE_KEYS.CREATED_BY_ID] === userId
                            }
                            submit={(noteText, important) =>
                                updateNote(
                                    note[C.TABLE_KEYS.RECORD_NOTE_ID],
                                    noteText,
                                    important
                                )
                            }
                            remove={removeNote}
                            restore={restoreNote}
                        />
                    ))}
                </Card>
            )}

            {!canEdit ? null : addingNew ? (
                <Note
                    className="audit-note-new"
                    note={{
                        [C.TABLE_KEYS.CREATED_DATE]: "Now",
                        [C.TABLE_KEYS.CREATED_BY_NAME]: "New Note"
                    }}
                    saving={notesSaving.includes(0)}
                    stopSaving={stopSavingNote}
                    canEdit={true}
                    forceEdit={true}
                    submit={(noteText, important) =>
                        newNote(
                            record[C.TABLE_KEYS.RECORD_ID],
                            noteText,
                            important
                        )
                    }
                    cancel={() => setAddingNew(false)}
                />
            ) : (
                <Button
                    className="add-new-note"
                    onClick={() => setAddingNew(true)}
                >
                    Add New Note
                </Button>
            )}
        </SimpleModal>
    )
}

export default connect(
    state => ({
        ...state[C.NAME],
        record: recordSelector(state),
        canEdit: canEditSelector(state),
        userId: state.authentication.userId
    }),
    actions
)(NotesModal)
