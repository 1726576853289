import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import awv from "../modules/awv"

export const AwvPage = () => (
    <>
        <SiteTitle title="Annual Wellness Visit" />
        <Breadcrumbs items={[["Dashboard", "/dashboard"], ["AWV Worklist"]]} />
        <header className="awv-page_header">
            <h1>Annual Wellness Visit</h1>
            <awv.components.AwvCounter />
        </header>
        <awv.components.default />
    </>
)

export default AwvPage
