import { connect } from "react-redux"
import fetcher from "../../../core/fetcher"
import { ApiTypes } from "../../../api"
import { practicesSelector } from "../../selectors"
import * as C from "../../constants"
import PracticePanel from "./PracticePanel"

export default connect(state => ({
    ...state[C.ADMIN_NAME],
    practices: practicesSelector(state)
}))(
    fetcher({
        name: C.PRACTICE_UNITS,
        endpoint: "/api/practice_units",
        method: ApiTypes.GET
    })(PracticePanel)
)
