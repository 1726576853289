import React, { Component } from "react"
import { Button } from "react-bootstrap"
import _ from "lodash"
import classNames from "classnames"
import Loading from "../../core/Loading"
import { filterBySearch } from "../../utils"
import { PermissionItem } from "./PermissionItem"
import * as C from "../constants"
import { ACCESS_PRIVILEGES } from "../constants"

const getRowClassName = index => (index % 2 === 0 ? "oddRow" : "evenRow") // reversed to have grey at the top, so a 1-row table doesn't look weird

export class SourceColumn extends Component {
    getSelected = () =>
        filterBySearch(
            this.props.selected[undefined] || [],
            item => item.label,
            this.props.filter
        )

    getList = () => {
        const { disableView, disableEdit, set } = this.props
        const selected = this.getSelected()

        const buttons = _.compact([
            !disableView && C.READ_BUTTON,
            !disableEdit && C.EDIT_BUTTON
        ])

        return selected.map((item, index) => (
            <PermissionItem
                key={item.id}
                item={item}
                buttons={buttons}
                onClick={set}
                className={getRowClassName(index)}
            />
        ))
    }

    render() {
        const { disableView, loading, onFilterChange, filter } = this.props
        return (
            <div className="permission_column">
                <div className="permission_column-header">
                    <input
                        value={filter}
                        onChange={onFilterChange}
                        className="form-control"
                        type="text"
                        placeholder="Search ..."
                    />
                    <div className="permission_column-header-buttons">
                        {!disableView && (
                            <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={this.props.setAll(
                                    undefined,
                                    ACCESS_PRIVILEGES.READ
                                )}
                            >
                                All Access
                            </Button>
                        )}
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={this.props.setAll(
                                undefined,
                                ACCESS_PRIVILEGES.EDIT
                            )}
                        >
                            All Edit
                        </Button>
                    </div>
                </div>
                <div
                    className="overflow_container"
                    style={{
                        marginBottom: _.isEmpty(this.getSelected()) ? -10 : 0
                    }}
                >
                    {loading ? <Loading /> : this.getList()}
                </div>
            </div>
        )
    }
}

class BaseColumn extends Component {
    getSelected = () =>
        this.props.selected[this.props.visibleWithPrivilege] || []

    getList = () => {
        const { disableView, set, privilegeAfterChange } = this.props

        const buttons = _.compact([
            C.CLEAR_BUTTON,
            !disableView && C.PERMISSION_BUTTONS[privilegeAfterChange]
        ])

        return this.getSelected().map((item, index) => (
            <PermissionItem
                key={item.id}
                item={item}
                buttons={buttons}
                onClick={set}
                className={getRowClassName(index)}
            />
        ))
    }

    render() {
        const {
            loading,
            title,
            changeLabel,
            visibleWithPrivilege,
            privilegeAfterChange,
            setAll,
            disableView
        } = this.props
        const list = this.getList()
        const iconClass = (C.PERMISSION_BUTTONS[visibleWithPrivilege] || {})
            .className
        return (
            <div className="permission_column">
                <div className="permission_column-header">
                    <h4>
                        <span className={classNames("fa", iconClass)} />
                        {title}
                    </h4>
                    {
                        <div className="permission_column-header-buttons">
                            <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={setAll(
                                    visibleWithPrivilege,
                                    undefined
                                )}
                            >
                                Remove All
                            </Button>
                            {!!disableView || (
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={setAll(
                                        visibleWithPrivilege,
                                        privilegeAfterChange
                                    )}
                                >
                                    {changeLabel || "Change all"}
                                </Button>
                            )}
                        </div>
                    }
                </div>
                <div
                    className="overflow_container"
                    style={{
                        marginBottom: _.isEmpty(this.getSelected()) ? -10 : 0
                    }}
                >
                    {loading ? <Loading /> : list}
                </div>
            </div>
        )
    }
}

export const ViewColumn = props => (
    <BaseColumn
        {...props}
        title="Access"
        visibleWithPrivilege={ACCESS_PRIVILEGES.READ}
        privilegeAfterChange={ACCESS_PRIVILEGES.EDIT}
        changeLabel="Upgrade All"
    />
)
export const EditColumn = props => (
    <BaseColumn
        {...props}
        title="Edit"
        visibleWithPrivilege={ACCESS_PRIVILEGES.EDIT}
        privilegeAfterChange={ACCESS_PRIVILEGES.READ}
        changeLabel="Downgrade All"
    />
)
