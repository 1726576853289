import moment from "moment"
import _ from "lodash"
import * as C from "../../../../../info/constants"
import {
    CHILD_LOWER_AGE_LIMIT_INCLUSIVE,
    CHILD_UPPER_AGE_LIMIT_INCLUSIVE
} from "../../../../../../configuration"
import { ApiTypes, getDefaultHeaders } from "../../../../../api"
import { intersectionCount, urlWithParams } from "../../../../../utils"

const defaultAutoCalc = {
    requiredIds: [],
    optionalIds: [],
    requiredDemographics: [],
    formula: () => "",
    noValueText: "Auto Calculated"
}

const getAge = dob => moment().diff(dob, "years", true)
const getAgeMonths = dob => moment().diff(dob, "months", true)

const getBmi = async (params, authentication) => {
    const endpoint = urlWithParams("/api/measures/bmi", params)
    const response = await fetch(endpoint, {
        method: ApiTypes.GET,
        headers: getDefaultHeaders(authentication)
    })
    return response.ok
        ? await response.json()
        : { error: "BMI calculation failed." }
}

const blood_pressure_controlled = {
    ...defaultAutoCalc,
    requiredIds: [120],
    formula: values => {
        const [bp] = values
        return bp
    }
}

const hypertension_blood_pressure_controlled = {
    ...defaultAutoCalc,
    requiredIds: [120],
    formula: values => {
        const [bp] = values
        return bp
    }
}

const hemoglobin_a1c_less_than_7 = {
    ...defaultAutoCalc,
    requiredIds: [70],
    formula: values => {
        const [a1c] = values
        return Number(a1c) < 7 ? a1c : null
    }
}

const hemoglobin_a1c_less_than_8 = {
    ...defaultAutoCalc,
    requiredIds: [70],
    formula: values => {
        const [a1c] = values
        return Number(a1c) < 8 ? a1c : null
    }
}

const hemoglobin_a1c_less_than_9 = {
    ...defaultAutoCalc,
    requiredIds: [70],
    formula: values => {
        const [a1c] = values
        return Number(a1c) <= 9 ? a1c : null
    }
}

const medical_attention_for_nephropath = {
    ...defaultAutoCalc,
    optionalIds: [104, 1],
    formula: values => {
        const [urine_protein_test, ace_arb_medication_filled] = values
        return urine_protein_test || ace_arb_medication_filled
    }
}

const diabetes_monitoring = {
    ...defaultAutoCalc,
    requiredIds: [70, 74],
    formula: values => {
        const [a1c, ldl] = values
        return a1c && ldl
    }
}

// CANNOT FIND DIGOXIN IN ODS!!!!!!!!!!
const total_medication_monitoring_rate = {
    ...defaultAutoCalc,
    requiredIds: [1, 17, 18],
    formula: values => {
        const [ace_arb, digoxin, diuretics] = values
        return ace_arb && digoxin && diuretics
    }
}

const cervical_cancer_screening_for_women = {
    ...defaultAutoCalc,
    optionalIds: [35, 36],
    requiredDemographics: [C.KEYS.DOB],
    formula: values => {
        const [cc, cc_hpv, dob] = values
        const age = getAge(dob)
        return cc || (age >= 30 && cc_hpv)
    }
}

const adolescent_immunizations_combo_2 = {
    ...defaultAutoCalc,
    requiredIds: [2, 4, 5],
    formula: values => {
        const [hpv, meningococcal, tdap] = values
        return hpv && meningococcal && tdap
    }
}

const childhood_immunization_combo_3 = {
    ...defaultAutoCalc,
    requiredIds: [37, 39, 40, 43, 44, 45, 47],
    formula: values => {
        const [DTAP, HEPB, HIB, IPV, MMR, PCV, VZV] = values
        return DTAP && HEPB && HIB && IPV && MMR && PCV && VZV
    }
}

const childhood_immunization_combo_10 = {
    ...defaultAutoCalc,
    requiredIds: [37, 38, 39, 40, 42, 43, 44, 45, 46, 47],
    formula: values => {
        const [
            DTAP,
            HEPA,
            HEPB,
            HIB,
            INFLUENZA,
            IPV,
            MMR,
            PCV,
            RV,
            VZV
        ] = values
        return (
            DTAP &&
            HEPA &&
            HEPB &&
            HIB &&
            INFLUENZA &&
            IPV &&
            MMR &&
            PCV &&
            RV &&
            VZV
        )
    }
}

const metabolic_monitoring_for_kids = {
    ...defaultAutoCalc,
    optionalIds: [28, 70, 74, 101],
    formula: values => {
        const [blood_glucose, A1c, ldl, cholesterol] = values
        return (blood_glucose || A1c) && (ldl || cholesterol)
    }
}

const adult_BMI = {
    ...defaultAutoCalc,
    requiredIds: [66, 106],
    requiredDemographics: [C.KEYS.DOB],
    formula: (values, authentication) => {
        const [height, weight] = values
        return getBmi({ height, weight }, authentication).then(b => b.bmi)
    }
}

const BMI_percentile = {
    ...defaultAutoCalc,
    requiredIds: [66, 106],
    requiredDemographics: [C.KEYS.DOB, C.KEYS.GENDER],
    formula: (values, authentication) => {
        const [height, weight, dob, genders] = values
        const ageMonths = getAgeMonths(dob)
        if (ageMonths > CHILD_UPPER_AGE_LIMIT_INCLUSIVE) {
            return {
                error: `BMI Percentile can only be calculated for a patient with an age no greater than ${CHILD_UPPER_AGE_LIMIT_INCLUSIVE} months.`
            }
        }
        if (ageMonths < CHILD_LOWER_AGE_LIMIT_INCLUSIVE) {
            return {
                error: `BMI Percentile can only be calculated for a patient with an age of at least ${CHILD_LOWER_AGE_LIMIT_INCLUSIVE} months.`
            }
        }
        if (intersectionCount(C.BMI_GENDERS, genders) !== 1) {
            // patient is both or neither of male and female
            return {
                error:
                    "BMI Percentile can only be calculated for a patient with either a male or female gender."
            }
        }
        return getBmi(
            { gender: _.head(genders), ageMonths, height, weight },
            authentication
        )
    }
}

const adolescent_weight_assessment = {
    ...defaultAutoCalc,
    requiredIds: [32],
    formula: values => {
        const [bmi_percentile] = values
        return !!bmi_percentile
    }
}

const adult_BMI_assessment = {
    ...defaultAutoCalc,
    optionalIds: [8, 32],
    requiredDemographics: [C.KEYS.DOB],
    formula: values => {
        const [adult_bmi, child_bmi, dob] = values
        const ageMonths = getAgeMonths(dob)
        return (
            (adult_bmi && ageMonths > CHILD_UPPER_AGE_LIMIT_INCLUSIVE) ||
            (child_bmi && ageMonths <= CHILD_UPPER_AGE_LIMIT_INCLUSIVE)
        )
    }
}

const definitions = {
    id_29: blood_pressure_controlled,
    id_153: hypertension_blood_pressure_controlled,
    id_67: hemoglobin_a1c_less_than_7,
    id_68: hemoglobin_a1c_less_than_8,
    id_69: hemoglobin_a1c_less_than_9,
    id_77: medical_attention_for_nephropath,
    id_54: diabetes_monitoring,
    id_103: total_medication_monitoring_rate,
    id_34: cervical_cancer_screening_for_women,
    id_3: adolescent_immunizations_combo_2,
    id_166: childhood_immunization_combo_3,
    id_41: childhood_immunization_combo_10,
    id_82: metabolic_monitoring_for_kids,
    id_8: adult_BMI,
    id_32: BMI_percentile,
    id_6: adolescent_weight_assessment,
    id_7: adult_BMI_assessment
}
export default definitions
