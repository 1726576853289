import * as C from "./constants"

export const pageMounted = () => ({ type: C.PAGE_MOUNTED })
export const setPocId = payload => ({
    type: C.SET_PATIENT_ID,
    payload
})

export const storeNavDimensions = (width, height) => ({
    type: C.STORE_NAV_DIMENSIONS,
    width,
    height
})
