export const NAME = "PATIENT_RISK"
export const ADD_RISK_FORM = "ADD_RISK_FORM"

export const ADD_RISK_DETAILS = "ADD_RISK_DETAILS"
export const SHOW_ADD_RISK_FORM = "SHOW_ADD_RISK_FORM"
export const HIDE_ADD_RISK_FORM = "HIDE_ADD_RISK_FORM"
export const STOP_SAVING = "STOP_SAVING"

export { FILTER_OPTIONS } from "../constants"

export const initialState = { show: false, loading: false }

export const TABLE_KEYS = {
    SOURCE: "sourceName",
    TYPE_DESC: "riskTypeDesc",
    TYPE: "riskTypeId",
    RESULT: "riskResult",
    DATE: "riskLastDate",
    RANK: "riskRank",
    USER: "userLoginId",
    INFO: "methodology"
}

export const RISK_TRANSLATION = {
    [TABLE_KEYS.SOURCE]: "Source",
    [TABLE_KEYS.TYPE_DESC]: "Type",
    [TABLE_KEYS.TYPE]: "Type",
    [TABLE_KEYS.RESULT]: "Result",
    [TABLE_KEYS.DATE]: "Date",
    [TABLE_KEYS.RANK]: "Stratification",
    [TABLE_KEYS.USER]: "User",
    [TABLE_KEYS.INFO]: "Info"
}
