import React from "react"
import _ from "lodash"
import { Card, Row, Col } from "react-bootstrap"
import * as C from "../constants"
import PatientCostChart from "./PatientCostChart"
import PatientCostTable from "./PatientCostTable"
import PatientCostModal from "./PatientCostModal"

export class PatientCosts extends React.Component {
    state = { show: false }

    showModal = rowData => {
        const modalFilters = _.pick(rowData, C.MODAL_FILTERS)
        this.props.setModalFilters(modalFilters)
        this.setState({ show: true })
    }
    hideModal = () => this.setState({ show: false })

    render() {
        return (
            <Row as={Card} className="patient-costs card-table">
                <Col lg={6}>
                    <PatientCostTable showModal={this.showModal} />
                </Col>
                <Col lg={6}>
                    <PatientCostChart />
                </Col>
                <PatientCostModal
                    show={this.state.show}
                    onHide={this.hideModal}
                />
            </Row>
        )
    }
}
