import React from "react"
import classNames from "classnames"
import { Field } from "redux-form"
import spinner from "../../../assets/images/loading.gif"
import { Check } from "../../core/CellRenderers"
import { CheckboxField, TextInput } from "../../core/CustomFields"
import { Hoverlay } from "../../core/Hoverlay"
import { uniqueName } from "../validators"
import * as C from "../constants"

const TagNameInput = props => (
    <React.Fragment>
        {/*We aren't putting the TextInput inside the hoverlay because whenever the hoverlay appears or disappears, we lose focus on the input*/}
        <TextInput {...props} autoFocus />
        <Hoverlay
            tooltip={props.meta.error}
            placement="bottom"
            className="tooltip-red"
            show={props.meta.invalid}
        >
            <div style={{ width: "100%" }} />
            {/*this is a spacer to ensure that the tooltip is positioned correctly*/}
        </Hoverlay>
    </React.Fragment>
)

export const TagName = ({ cellData, rowData }) =>
    rowData.editing ? (
        <Field
            name={C.FORM_FIELDS.TAG_NAME}
            component={TagNameInput}
            validate={uniqueName}
            placeholder="Tag name"
            notClearable
        />
    ) : (
        cellData
    )

const CheckField = props => <CheckboxField {...props} className="btn-blank" />

export const PublicCheckbox = ({ cellData, rowData }) =>
    rowData.editing ? (
        <Field name={C.FORM_FIELDS.PUBLIC} component={CheckField} />
    ) : (
        <Check cellData={cellData} />
    )

export const ActionButton = ({ onClick, className, disabled, title }) => (
    <Hoverlay tooltip={title} disabled={!title}>
        <button
            className="btn-blank"
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <span className={classNames("fa", className)} />
        </button>
    </Hoverlay>
)

export const ActionButtons = ({ rowData }) => {
    if (rowData.disableEdit) {
        return null
    }

    if (rowData.saving) {
        return <img src={spinner} alt="Saving..." />
    }

    if (!rowData.editing) {
        return (
            <ActionButton
                onClick={rowData.editTag}
                className="fa-edit"
                title="Edit"
            />
        )
    }

    return (
        <React.Fragment>
            <ActionButton
                disabled={rowData.disableSave}
                onClick={rowData.submit}
                className="fa-save"
                title="Save"
            />
            <ActionButton
                onClick={rowData.cancelEdit}
                className="fa-cancel"
                title="Cancel"
            />
            {rowData[C.TABLE_KEYS.TAG_ID] !== C.NEW_TAG_ID && (
                <ActionButton
                    onClick={rowData.deleteTag}
                    className="fa-trash-o"
                    title="Delete"
                />
            )}
        </React.Fragment>
    )
}
