import React from "react"
import { hyphenate } from "../../../../../utils"
import { dateFormat, conditionalTimestampFormat } from "../../../../../dates"
import * as C from "../../../../constants"

const DisplayRow = ({
    oddRow,
    userSource,
    serviceCodeSystem,
    serviceCode,
    value,
    serviceDt,
    logDt,
    restricted,
    allowEdit,
    startEdit,
    deleteItem,
    dataType
}) => (
    <tr className={`${oddRow ? "oddRow" : ""} historical_row`}>
        <td>{hyphenate(userSource)}</td>
        <td>{serviceCodeSystem}</td>
        <td className="dont-break-out">{serviceCode}</td>
        <td>{value}</td>
        <td>{dateFormat(serviceDt)}</td>
        <td>{conditionalTimestampFormat(logDt)}</td>
        {restricted ? (
            <td>Restricted</td>
        ) : (
            <td className="historical_row-buttons">
                {allowEdit &&
                dataType !== C.CHECKBOX && ( // checkboxes should never be editable, as they're unary and don't have other possible values.
                        <span
                            className="fa fa-pencil"
                            onClick={startEdit}
                            title="Edit"
                        />
                    )}
                <span className="fa fa-trash-o" onClick={deleteItem} />
            </td>
        )}
    </tr>
)

export default DisplayRow
