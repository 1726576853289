import * as C from "./constants"

export const openLocationModal = ({ allowSelect }) => ({
    type: C.OPEN_LOCATION_MODAL,
    payload: { allowSelect: allowSelect || false }
})
export const closeLocationModal = () => ({ type: C.CLOSE_LOCATION_MODAL })
export const setLocationModeSelect = () => ({ type: C.LOCATION_MODE_SELECT })
export const setLocationModeAdd = payload => ({
    type: C.LOCATION_MODE_ADD,
    payload
})
export const setLocationModeEdit = payload => ({
    type: C.LOCATION_MODE_EDIT,
    payload
})
export const saveLocation = payload => ({ type: C.SAVE_LOCATION, payload })
