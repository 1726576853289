import React from "react"
import classNames from "classnames"
import filterInvalidProps from "filter-invalid-dom-props"
import { arePropsBasicallyEqual } from "../helpers"

export const GridCellRenderer = ({
    children,
    className,
    noWrapper,
    ...props
}) => (
    <div
        {...filterInvalidProps(props)}
        className={classNames("grid-cell", className)}
    >
        {noWrapper ? (
            children
        ) : (
            <div className="grid-cell-inner">{children}</div>
        )}
        {/* plain text has to be wrapped in another element to ensure overflow gets ellipsed properly. Certain other types of element also need wrappers; it's hard to tell a priori which do or don't, so we just always wrap instead.*/}
    </div>
)

export default React.memo(GridCellRenderer, arePropsBasicallyEqual)
