import React from "react"
import { Button } from "react-bootstrap"

export const AddComment = ({ onSubmit }) => (
    <form onSubmit={onSubmit}>
        <div className="form-group">
            <textarea
                id="commentInput"
                className="form-control"
                maxLength={5000}
            />
        </div>
        <div className="form-group">
            <Button variant="primary" type="submit" className="btn-block">
                Add Comment
            </Button>
        </div>
    </form>
)
