import React, { Component } from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import { FormGroup } from "react-bootstrap"
import _ from "lodash"
import { getResourceData } from "../../../../../core/fetcher"
import { NAME as INFO_NAME } from "../../../../../info/constants"
import { InfoIndicator } from "../../../../../indicators"
import { idLookUpSelector } from "../../../../selectors"
import * as C from "../../../../constants"
import {
    formatPercentile,
    getAllDetails,
    getIdName,
    getValuesAndMeta
} from "../../../../helpers"
import definitions from "./definitions"
import { authTokensSelector } from "../../../../../authentication/selectors"

const AutocalcCheckbox = ({ checked }) => (
    <label className="checkbox">
        <input type="checkbox" checked={checked} disabled readOnly />
        {!checked && "Not"} Completed (Auto Calculated)
    </label>
)

export class AutoCalculated extends Component {
    componentDidMount() {
        this.componentDidUpdate({})
    }

    // noinspection JSCheckFunctionSignatures
    componentDidUpdate(prevProps) {
        const definition = definitions[this.props.input.name]
        if (!definition) {
            return
        }

        const details = getAllDetails(
            prevProps.values,
            this.props.values,
            definition,
            this.props.idLookup
        )
        const { update, clear, values } = getValuesAndMeta(details)

        const demographicInfo = _.map(
            definition.requiredDemographics,
            key => this.props.patientInfo[key]
        )

        if (update) {
            const calculatedResult = definition.formula(
                [...values, ...demographicInfo],
                this.props.authentication
            )
            // TODO really we should pass the arguments as an object rather than an array. Right now if a value is missing, everything after it gets slid to the left one space, with potentially weird results.
            //  but it's understandable why we didn't, since then we'd have to give each ID or demographic entry a key. which would be a lot of work.
            //  maybe if we just split values and demographicInfo into two separate arguments?

            // in the form, an undefined or missing value means there's no data entered about the measure; a null value means that a value was calculated by the formula, and it determined that nothing should be displayed in these circumstances.
            Promise.resolve(calculatedResult).then(value =>
                this.props.input.onChange(value)
            )
        } else if (clear && this.props.input.value !== undefined) {
            // specifically checking against undefined because the value could also be null, which has a different meaning
            this.props.input.onChange(undefined)
        }
    }

    getDisplayValue = (definition, input) => {
        const { requiredIds, noValueText } = definition
        const requiredValues = requiredIds.map(id => {
            const idName = getIdName(id)
            const newValue = this.props.values[idName]
            const lastValue = (this.props.idLookup[idName] || {}).numericVal // XXX should we include textVal? Probably not... but make sure
            return _.isNil(newValue) ? lastValue : newValue
        })
        const valueExists = _.every(requiredValues)

        if (valueExists) {
            const value = input.value

            if (input.name === "id_32") {
                // BMI percentile hardcoded

                if (value.error) {
                    return (
                        <span>
                            Unknown <InfoIndicator tooltip={value.error} />
                        </span>
                    )
                } else if (!_.isNil(value.percentile)) {
                    return formatPercentile(value)
                }
            } else if (this.props.dataType === C.CHECKBOX) {
                if (value || value === false) {
                    // actually false, not just falsey
                    return <AutocalcCheckbox checked={value} />
                }
            } else if (this.props.dataType === C.ENUMERATION) {
                return this.props.dropListOptions.reduce(
                    (acc, cur) => (cur.id === value ? cur.name : acc),
                    null
                )
            } else if (value) {
                return (
                    <span>
                        {value} {this.props.unitLabel}
                    </span>
                )
            }
        }
        return noValueText
    }

    render() {
        const input = this.props.input
        const definition = definitions[input.name]
        if (!definition) {
            console.error(
                `Auto-calculated definition for measure ${input.name} does not exist`
            )
            return <div />
        }
        return (
            <FormGroup>
                <p className="form-control-static">
                    {this.getDisplayValue(definition, input)}
                </p>
            </FormGroup>
        )
    }
}

export default connect(state => ({
    values: getFormValues(C.POC_MEASURE_FORM)(state),
    patientInfo: getResourceData(INFO_NAME)(state),
    idLookup: idLookUpSelector(state),
    authentication: authTokensSelector(state)
}))(AutoCalculated)
