// Hide a specified TCM column unless at least one entry is editable.
// Returns true if the column should be shown.
export const hideIfNoneEditable = ({ hideColumns, editableKey, canEdit }) => (
    column,
    list
) => {
    const isHideableColumn = hideColumns.includes?.(column.key)
    const editableEntryExists =
        !editableKey || list?.some(row => row[editableKey]) // if none specified, then don't worry about it
    return !isHideableColumn || (editableEntryExists && canEdit)
}
