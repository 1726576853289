import { createReducer } from "../utils"
import * as actionTypes from "./actionTypes"

const initialState = {
    show: false,
    hasError: false,
    saving: false,
    updateCount: 0
}

const reducer = createReducer(initialState, {
    [actionTypes.SHOW_MY_PROFILE]: state => {
        return {
            ...state,
            show: true,
            updateCount: state.updateCount + 1
        }
    },
    [actionTypes.HIDE_MY_PROFILE]: state => {
        return {
            ...state,
            show: false
        }
    },
    [actionTypes.FETCH_MY_PROFILE]: state => {
        return {
            ...state,
            hasError: false
        }
    },
    [actionTypes.FETCH_MY_PROFILE_SUCCESS]: state => {
        return {
            ...state,
            hasError: false
        }
    },
    [actionTypes.FETCH_MY_PROFILE_FAILURE]: () => {
        return {
            ...initialState,
            hasError: true
        }
    },
    [actionTypes.UPDATE_MY_PROFILE]: state => {
        return {
            ...state,
            hasError: false,
            saving: true
        }
    },
    [actionTypes.UPDATE_MY_PROFILE_SUCCESS]: state => {
        return {
            ...state,
            hasError: false,
            saving: false
        }
    },
    [actionTypes.UPDATE_MY_PROFILE_FAILURE]: state => {
        return {
            ...state,
            hasError: true,
            saving: false
        }
    }
})

export default reducer
