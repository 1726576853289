import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import { AuditFiles } from "../../patient_audits/components/AuditFiles"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import { canEditSelector } from "../selectors"

export default fetcher({
    name: C.NAME,
    endpoint: "/api/documents",
    method: ApiTypes.GET
})(
    connect(
        (state, { data }) => ({
            files: data.documents,
            canEdit: canEditSelector(state),
            emptyMessage:
                "There are no shared documents, and you don't have permission to upload new ones."
        }),
        actions
    )(AuditFiles)
)
