import fetcher from "../../core/fetcher"
import { transformFilterDropdowns } from "../../transformFilter"
import { FILTER_OPTIONS } from "../../constants"
import { ApiTypes } from "../../api"

export default fetcher({
    name: FILTER_OPTIONS,
    endpoint: "/api/filter/info",
    method: ApiTypes.GET,
    acceptResponse: transformFilterDropdowns
})(() => null)
