import React from "react"
import Toggle from "../../core/Toggle"
import * as C from "../constants"

// TODO this should be focusable

export const TitleToggle = ({ filters, setFilters }) => (
    <Toggle
        name="pivotType"
        className="patient-list-pivot"
        checked={filters[C.FILTERS.TYPE] === C.FILTER_TYPES.PATIENT_MEASURES}
        onChange={() =>
            setFilters({
                ...filters,
                [C.FILTERS.TYPE]:
                    filters[C.FILTERS.TYPE] === C.FILTER_TYPES.PATIENT_SUMMARY
                        ? C.FILTER_TYPES.PATIENT_MEASURES
                        : C.FILTER_TYPES.PATIENT_SUMMARY
            })
        }
    />
)
