import React, { useMemo } from "react"
import { Field } from "redux-form"
import {
    maxVal,
    minVal,
    mustBeANumber,
    reasonableVariance
} from "../../../../core/validators"
import { NumericField } from "./CustomFields"

export const NumericWrapper = ({
    numericVal,
    errorFactor,
    warningFactor,
    minValue,
    maxValue,
    ...props
}) => {
    const errorValidator = useMemo(
        () => [
            mustBeANumber,
            maxVal(maxValue ?? undefined),
            minVal(minValue ?? undefined),
            reasonableVariance(errorFactor, numericVal, "dramatically")
        ],
        [numericVal, errorFactor, minValue, maxValue]
    )
    const warningValidator = useMemo(
        () => reasonableVariance(warningFactor, numericVal, "significantly"),
        [numericVal, warningFactor]
    )

    return (
        <Field
            {...props}
            component={NumericField}
            validate={errorValidator}
            warn={warningValidator}
        />
    )
}

export default NumericWrapper
