import React, { Component } from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import _ from "lodash"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import { tableFactory } from "../../table"
import * as C from "../constants"
import { TABLE_COLUMNS } from "../columns"

export const defaultOrderBys = {
    sortBy: [C.TABLE_KEYS.EVENT_DATE],
    sortDirection: [SortDirection.DESC]
}

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    ...defaultOrderBys
})

export default class Admissions extends Component {
    // noinspection JSCheckFunctionSignatures
    shouldComponentUpdate(nextProps) {
        return !_.isEqual(this.props.data, nextProps.data)
    }

    render() {
        const {
            loading,
            error,
            data: { admissions },
            paginationFunctions
        } = this.props
        return (
            <Card id="er-ip-admissions" className="card-table">
                <Card.Header id="admissions-list-header">
                    <CardTitle>
                        ED and Inpatient Admissions{" "}
                        {admissions && admissions.length === 0
                            ? " - No Data"
                            : ""}
                    </CardTitle>
                    <Settings />
                </Card.Header>
                <Description />
                <Card.Body
                    style={_.isEmpty(admissions) ? { display: "none" } : {}}
                >
                    <LEHOC loading={loading} error={error} list={admissions}>
                        <Table {...paginationFunctions} list={admissions} />
                    </LEHOC>
                </Card.Body>
            </Card>
        )
    }
}
