import { createReducer } from "../utils"
import * as actionTypes from "./actionTypes"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [actionTypes.FETCH_PRACTICES_SUCCESS]: (state, action) => {
        return {
            ...state,
            practices: action.data
        }
    },
    [actionTypes.SELECTED_PRACTICES_CHANGE]: (state, action) => {
        return {
            ...state,
            selectedPractices: action.data
        }
    },
    [actionTypes.FETCH_USERS_SUCCESS]: (state, action) => {
        return {
            ...state,
            users: action.data
        }
    },
    [actionTypes.SHOW_ADD_PRACTICE_MODAL]: state => {
        return {
            ...state,
            addPracticeModalOpen: true
        }
    },
    [actionTypes.HIDE_ADD_PRACTICE_MODAL]: state => {
        return {
            ...state,
            addPracticeModalOpen: false
        }
    },
    [actionTypes.SHOW_EDIT_PRACTICE_MODAL]: (state, action) => {
        return {
            ...state,
            editPracticeModalOpen: true,
            selectedPractice: {
                name: action.payload.practiceUnitName,
                id: action.payload.practiceUnitId,
                regionId: action.payload.regionId
            }
        }
    },
    [actionTypes.HIDE_EDIT_PRACTICE_MODAL]: state => {
        return {
            ...state,
            editPracticeModalOpen: false
        }
    },
    [actionTypes.SHOW_ADD_USER_MODAL]: state => {
        return {
            ...state,
            selectedUserId: C.NEW_USER_ID,
            addUserModalOpen: true
        }
    },
    [actionTypes.HIDE_ADD_USER_MODAL]: state => {
        return {
            ...state,
            selectedUserId: undefined,
            addUserModalOpen: false
        }
    },
    [actionTypes.SHOW_EDIT_USER_MODAL]: (state, action) => {
        return {
            ...state,
            selectedUserUpdateCount: state.selectedUserUpdateCount + 1,
            selectedUserId: action.selectedUserId,
            editUserModalOpen: true
        }
    },
    [actionTypes.HIDE_EDIT_USER_MODAL]: state => {
        return {
            ...state,
            selectedUserId: undefined,
            editUserModalOpen: false
        }
    },
    [actionTypes.ADD_PRACTICE]: state => {
        return {
            ...state,
            saving: true
        }
    },
    [actionTypes.ADD_PRACTICE_SUCCESS]: state => {
        return {
            ...state,
            saving: false
        }
    },
    [actionTypes.UPDATE_PRACTICE]: state => {
        return {
            ...state,
            saving: true
        }
    },
    [actionTypes.UPDATE_PRACTICE_SUCCESS]: state => {
        return {
            ...state,
            saving: false
        }
    },
    [actionTypes.UPDATE_PRACTICE_FAILURE]: state => {
        return {
            ...state,
            saving: false
        }
    },
    [actionTypes.ADD_USER]: state => {
        return {
            ...state,
            saving: true
        }
    },
    [actionTypes.ADD_USER_SUCCESS]: state => {
        return {
            ...state,
            userTableUpdateCount: state.userTableUpdateCount + 1,
            saving: false
        }
    },
    [actionTypes.ADD_USER_FAILURE]: state => {
        return {
            ...state,
            saving: false
        }
    },
    [actionTypes.UPDATE_USER]: state => {
        return {
            ...state,
            saving: true
        }
    },
    [actionTypes.UPDATE_USER_FAILURE]: state => {
        return {
            ...state,
            saving: false
        }
    },
    [actionTypes.UPDATE_USER_SUCCESS]: state => {
        return {
            ...state,
            userTableUpdateCount: state.userTableUpdateCount + 1,
            saving: false
        }
    },
    [actionTypes.FETCH_USER]: (state, action) => {
        return {
            ...state,
            selectedUserId: action.userId
        }
    },
    [actionTypes.SHOW_CONFIRM_MODAL]: (state, action) => {
        return {
            ...state,
            confirmModalOpen: true,
            confirmMessage: action.confirmMessage
        }
    },
    [actionTypes.HIDE_CONFIRM_MODAL]: state => {
        return {
            ...state,
            confirmModalOpen: false
        }
    }
})

export default reducer
