import React from "react"

export const IncentivizedTooltip = ({
    incentiveModuleActive,
    incentiveStatus,
    incentivizedByPayer,
    suppElig,
    suppIds
}) => (
    <>
        {incentiveModuleActive && incentiveStatus === "X" && (
            <div className="compliance-warning">
                Not reported as <br />
                compliant by any payers!
            </div>
        )}
        <div>
            <h5>Incentivized by</h5>
            <ul>
                {incentivizedByPayer.map((payer, index) => (
                    <li key={index}>
                        <span
                            className="fa fa-circle"
                            style={{
                                color: payer.color,
                                marginRight: 5
                            }}
                        />
                        {payer.payerName}
                    </li>
                ))}
            </ul>
        </div>
        {incentiveModuleActive && suppElig && (
            <div>
                <h5>Eligible for file submission?</h5>
                {suppElig ? "Yes" : "No"}
            </div>
        )}
        {incentiveModuleActive && suppIds && (
            <div>
                <h5>Supplemental IDs</h5>
                <ul>
                    {suppIds?.map((suppId, i) => (
                        <li key={i}>{suppId}</li>
                    ))}
                </ul>
            </div>
        )}
    </>
)
