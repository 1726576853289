const matchLocation = (toMatch, location) =>
    location &&
    location.pathname &&
    location.pathname.includes(toMatch.toString())

export const matchNavItem = (toMatch, location) =>
    !!toMatch &&
    (Array.isArray(toMatch)
        ? toMatch.some(m => matchLocation(m, location))
        : matchLocation(toMatch, location))
