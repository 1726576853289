import React from "react"
import _ from "lodash"
import classNames from "classnames"
import { Hoverlay } from "../../core/Hoverlay"
import { TABLE_KEYS } from "../../cost/constants"
import { formatDollarsCents } from "../../numbers"

export const PerformanceChart = ({ rowData }) => {
    const allocation = Number(rowData[TABLE_KEYS.PMPM_ALLOCATION])
    const cost = Number(rowData[TABLE_KEYS.PMPM_COST])

    const fractionSpent = cost / allocation
    const percentSpent = _.isFinite(fractionSpent)
        ? Math.round(fractionSpent * 100)
        : 100
    const costPortion = _.isFinite(fractionSpent)
        ? Math.min(1, fractionSpent) * 100
        : 100
    const allocationPortion = fractionSpent
        ? Math.min(1, 1 / fractionSpent) * 100
        : 100

    const success = fractionSpent <= 1
    const costFlexBasis = success ? costPortion : 100 - allocationPortion
    const allocationFlexBasis = success ? 100 - costPortion : allocationPortion
    const bothZero = allocation === 0 && cost === 0

    return (
        <Hoverlay
            overlayProps={{ className: "cost-indicator-tooltip" }}
            tooltip={
                <React.Fragment>
                    Cost per member per month: {formatDollarsCents(cost)}
                    <br />
                    Allocated per member per month:{" "}
                    {formatDollarsCents(allocation)}
                    <br />
                    Spent <strong>{percentSpent}%</strong> of allocated funds
                </React.Fragment>
            }
        >
            <div className="cost-indicator">
                <div
                    className={classNames("bar cost", {
                        success
                    })}
                    style={{
                        width: `${costPortion}%`,
                        display: bothZero ? "none" : "block"
                    }}
                />
                <div
                    className="bar allocation"
                    style={{
                        width: `${allocationPortion}%`,
                        zIndex: cost > allocation ? 1 : 0
                    }}
                />

                <div className="numbers">
                    <div
                        style={{
                            display: bothZero ? "none" : "block",
                            order: success ? -1 : 1,
                            flexBasis: `${costFlexBasis}%`
                        }}
                    >
                        {formatDollarsCents(cost)}
                    </div>
                    <div
                        style={{
                            flexBasis: `${allocationFlexBasis}%`,
                            textAlign: bothZero ? "center" : "right"
                        }}
                    >
                        {formatDollarsCents(allocation)}
                    </div>
                </div>
            </div>
        </Hoverlay>
    )
}
export default React.memo(
    PerformanceChart,
    ({ rowData }, { rowData: newRowData }) => _.isEqual(rowData, newRowData)
)
