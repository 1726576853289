import moment from "moment"
import _ from "lodash"

export const START_OF_TIME = "1753-01-01"
export const END_OF_TIME = "9999-12-31"
export const EARLIEST_DATE = "01/01/1900"
export const START_OF_TIME_MOMENT = moment(START_OF_TIME)
export const END_OF_TIME_MOMENT = moment(END_OF_TIME)

export const DATE_STRING = "MM/DD/YYYY"
export const SERVER_DATE_STRING = "YYYY-MM-DD"
export const MONTH_STRING = "YYYY/MM" // or we can switch back to MM/YYYY if that's better
export const RAW_MONTH_STRING = "YYYYMM"
export const YEAR_STRING = "YYYY"
export const TIME_STRING = "hh:mm a"
export const TIME_STRING_NO_AMPM = "hh:mm"
export const TIMESTAMP_STRING = "YYYY-MM-DDTHH:mm:ss.SSSSS"
export const TIMESTAMP_STRING_NO_MS = "YYYY-MM-DDTHH:mm:ss"
export const TIMESTAMP_STRING_REPORTS = "YYYY-MM-DD HH:mm:ss.SSS" // only used in the report library
export const TIMESTAMP_STRING_COMPACT = "YYYYMMDDHHmmss"
export const TIMESTAMP_STRING_PRETTY = "MM/DD/YYYY, h:mm a"
export const TIMESTAMP_STRING_FIELD = "MM/DD/YYYY hh:mm a"

export const parseDate = (dateString, format = DATE_STRING) => {
    if (!dateString) {
        // '' and null would already return null, but undefined wouldn't. So we catch that here.
        return null
    }
    const parsed = moment(dateString, format, true)
    return parsed.isValid() ? parsed : null
}

export const dateFormat = (
    date,
    fromFormat = SERVER_DATE_STRING,
    toFormat = DATE_STRING,
    utc = true
) => {
    const momentDate = utc
        ? moment.utc(date, fromFormat, true)
        : moment(date, fromFormat, true)

    if (isBlankDate(momentDate)) {
        // If you end up here and don't know why, you might be looking at a timestamp instead of a date. Use timestampFormat, below.
        return ""
    }

    return _.isFunction(toFormat) // This could be done with utils.callOrTake(), but utils imports this file already so that would cause a circular dependency
        ? momentDate.format(toFormat(momentDate))
        : momentDate.format(toFormat)
}

export const timestampFormat = timestamp =>
    dateFormat(
        timestamp,
        TIMESTAMP_STRING,
        TIMESTAMP_STRING_PRETTY,
        false // timestamps have a built-in timezone already
    ) ||
    dateFormat(
        timestamp,
        TIMESTAMP_STRING_NO_MS,
        TIMESTAMP_STRING_PRETTY,
        false
    ) // some timestamps show up without the milliseconds

export const conditionalTimestampFormat = timestamp =>
    dateFormat(
        timestamp,
        TIMESTAMP_STRING,
        momentDate =>
            momentDate.isBefore(moment().startOf("day"))
                ? DATE_STRING
                : TIMESTAMP_STRING_PRETTY,
        false
    )

export const isoToUsDate = date =>
    dateFormat(date, SERVER_DATE_STRING, DATE_STRING)
export const usToIsoDate = date =>
    dateFormat(date, DATE_STRING, SERVER_DATE_STRING)

export const getCurrentYear = () => Number(moment().format(YEAR_STRING))

export const YEAR_OPTIONS = [
    getCurrentYear(),
    getCurrentYear() - 1,
    getCurrentYear() - 2
]

export const MONTH_OPTIONS = _.range(1, 13) // i.e. 1 through 12
    .map(item => ({
        value: item,
        label: moment(item, "M").format("MMM")
    }))
export const MONTH_LABELS = _.zipObject(
    MONTH_OPTIONS.map(month => month.value),
    MONTH_OPTIONS.map(month => month.label)
)

export const displayMonths = (start, end) =>
    `${dateFormat(start, MONTH_STRING, MONTH_STRING)} to ${dateFormat(
        end,
        MONTH_STRING,
        MONTH_STRING
    )}`

export const numerateMonth = month =>
    Number(dateFormat(month, MONTH_STRING, RAW_MONTH_STRING))
export const denumerateMonth = monthId =>
    moment.utc(String(monthId), RAW_MONTH_STRING)

export const isBlankDate = date => {
    const momentDate = moment.isMoment(date) ? date : moment(date)
    return (
        !momentDate.isValid() ||
        momentDate.isSameOrAfter(END_OF_TIME, "day") ||
        momentDate.isSameOrBefore(START_OF_TIME, "day")
    )
}

export const getMaxDate = (dates, format) =>
    moment.max(
        dates
            .map(date => moment(date, format))
            .map(date => (isBlankDate(date) ? START_OF_TIME_MOMENT : date))
    )

export const getMaxDateColumn = (...dateColumns) => row =>
    getMaxDate(_.at(row, dateColumns), TIMESTAMP_STRING)

export const getStartOfYear = anchorDate =>
    moment(anchorDate)
        .startOf("year")
        .format(DATE_STRING)
