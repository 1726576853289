import { formValueSelector } from "redux-form"
import { createSelector } from "reselect"
import { urlWithParams } from "../../../../utils"
import { ApiTypes } from "../../../../api"
import fetcher from "../../../../core/fetcher"
import * as C from "../../../constants"
import { TagsPage } from "../../TagsPage"

const endpointSelector = createSelector(
    (state, formName) =>
        formValueSelector(formName)(state, C.USER_FIELDS.USER_UNITS), // this may not cache properly, but nbd here
    unitIds => urlWithParams("api/tags/practices", { unitIds })
)

export default fetcher({
    name: C.USER_PRACTICE_TAGS,
    method: ApiTypes.GET,
    endpoint: (state, ownProps) => endpointSelector(state, ownProps.form)
})(TagsPage)
