import React from "react"
import LEHOC from "../../core/LoadingErrorHOC"
import { dateFormat } from "../../dates"
import { PayerIcon } from "../../indicators"

// TODO refactor into multiple files

export const IncentivizedProduct = ({
    index,
    payerLogo,
    patCmIncentive,
    payerName,
    patEligFromDt,
    patEligToDt,
    patContractNumbers
}) => (
    <tr className={`${index % 2 === 0 ? "evenRow" : "oddRow"}`}>
        <td className="centered">
            <PayerIcon logo={payerLogo} />
            {patCmIncentive ? "Yes" : "No"}
        </td>
        <td>{payerName}</td>
        <td>{dateFormat(patEligFromDt)}</td>
        <td>{dateFormat(patEligToDt)}</td>
        <td>{(patContractNumbers || []).join(", ")}</td>
    </tr>
)

export const ScrollablePatientProducts = ({ data }) => (
    <div className="scrollable-table-container plDuePopup">
        <table className="popover-table">
            <thead>
                <tr>
                    <th className="centered">Incentivized</th>
                    <th>Payer</th>
                    <th>Eligible From</th>
                    <th>Eligible To</th>
                    <th>Contract #</th>
                </tr>
            </thead>
            <tbody>
                {data.products &&
                    data.products.map((item, index) => (
                        <IncentivizedProduct
                            key={item.patientDemogId}
                            index={index}
                            {...item}
                        />
                    ))}
            </tbody>
        </table>
    </div>
)

export default ({ loading, error, data }) => (
    <LEHOC loading={loading} error={error} list={data}>
        <ScrollablePatientProducts data={data} />
    </LEHOC>
)
