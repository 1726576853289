import React, { useEffect } from "react"

/** This HOC touches every (specified) field in a form on mount.
 *
 * Sometimes we want forms to display all errors immediately, instead of waiting for fields to be
 * individually touched. This is useful if you're loading some existing values into a form, say
 * for editing a user or patient, but there might be invalid values in those initial values.
 * You can do this for individual fields by passing the autoUpdate prop, but this does it in bulk.
 *
 * This component should be either just inside a reduxForm HOC, or have a touch() prop passed in some other way.
 *
 * @param WrappedComponent the component containing the fields to touch
 * @param fields Normally this is a FIELDS object from a constants file, though an array of field
 * names would also work.
 */
export const formToucher = (WrappedComponent, fields) => props => {
    useEffect(() => {
        props.touch(..._.values(fields))
    }, [])
    return <WrappedComponent {...props} />
}
