import React from "react"
import _ from "lodash"
import { Link } from "react-router-dom"
import {
    LongCell,
    MultilineList,
    formatYesNo
} from "../../../core/CellRenderers"
import * as C from "../../constants"

export const CellRenderer = ({ cellData, dataKey, rowData, columnData }) => {
    if (_.isNil(cellData)) {
        return null
    } else if (_.isArray(cellData)) {
        return <MultilineList cellData={cellData} />
    } else if (dataKey === C.PATIENT_NAME && rowData[C.PATIENT_ID]) {
        return <Link to={`/poc/${rowData[C.PATIENT_ID]}`}>{cellData}</Link>
    } else if (C.BOOLEAN_TYPES.includes(columnData.type)) {
        return formatYesNo(cellData)
    } else if (columnData.type === C.TEXT) {
        return <LongCell cellData={cellData} />
    } else {
        return cellData
    }
}
