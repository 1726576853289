import React from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import classNames from "classnames"
import _ from "lodash"
import Select from "../../core/Select"
import fetcher from "../../core/fetcher"
import LEHOC from "../../core/LoadingErrorHOC"
import { CostChart } from "../../cost/components/CostChart"
import { PERIOD_MONTH_IDS } from "../../pickers/constants"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import { patientChartDataSelector, payerOptionsSelector } from "../selectors"

export class PatientCostChart extends React.Component {
    handlePayerChange = ({ value }) => {
        const payerPath = value.split("-")
        const payload = {
            [C.TABLE_KEYS.PAYER_ID]: Number(payerPath[0]) || null,
            [C.TABLE_KEYS.PRODUCT_ID]: Number(payerPath[1]) || null
        } // the fallback is to just not filter on payer or product ID at all, hence the nulls
        return this.props.setChartFilters(payload)
    }

    getDefaultValue = () => {
        const [payerId, productId] = _.at(this.props, [
            C.TABLE_KEYS.PAYER_ID,
            C.TABLE_KEYS.PRODUCT_ID
        ])

        return payerId && productId
            ? this.props.payerOptions.find(
                  option => option.value === `${payerId}-${productId}`
              )
            : C.ALL_PAYERS_OPTION
    }

    render() {
        const {
            payerOptions,
            className,
            loading,
            error,
            months,
            categories
        } = this.props

        return (
            <Card
                className={classNames(
                    "card-table patient-cost-chart",
                    className
                )}
            >
                    <Card.Header>
                    <Card.Title as="h3">Monthly Spending Breakdown</Card.Title>
                        {payerOptions.length > 1 && (
                            <Select
                                className="payer-select"
                                defaultValue={this.getDefaultValue()}
                                options={[C.ALL_PAYERS_OPTION, ...payerOptions]}
                                onChange={this.handlePayerChange}
                            />
                        )}
                    </Card.Header>
                <LEHOC loading={loading} error={error}>
                    <Card.Body>
                        <CostChart categories={categories} months={months} />
                    </Card.Body>
                </LEHOC>
            </Card>
        )
    }
}

export default fetcher({
    name: C.PATIENT_COST_CHART,
    endpoint: state =>
        urlWithParams(`/api/patients/${state.poc_id}/cost/chart`, {
            ...state[C.NAME].chartFilters,
            ...PERIOD_MONTH_IDS[state[C.NAME][C.PERIOD]]
        }),
    method: ApiTypes.GET
})(
    connect(
        state => ({
            ...state[C.NAME].chartFilters,
            ...patientChartDataSelector(state),
            payerOptions: payerOptionsSelector(state)
        }),
        actions
    )(PatientCostChart)
)
