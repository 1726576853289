import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import * as reduxActions from "../actions"
import * as C from "../constants"
import Comments from "./Comments"

export default fetcher(
    {
        name: C.NAME,
        endpoint: state => `/api/patients/${state.poc_id}/comments`,
        method: ApiTypes.GET
    },
    reduxActions
)(Comments)
