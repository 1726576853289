import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { SortDirection } from "react-virtualized"

import FilterHeader from "../../core/FilterHeader"
import CardTitle from "../../core/CardTitle"
import { ExportIcon } from "../../core/Icons"
import { getPivotNameKeys } from "../../table/helpers"
import DashboardFilters from "../../dashboard_filters/components"
import { GuideLink } from "../../indicators"
import { tieredTableFactory } from "../../table"

import * as C from "../constants"
import { QSR_COLUMNS } from "../columns"
import CoverageReference from "./CoverageReference"
import PerformanceChart from "./PerformanceChart"
import QSRBody from "./QSRBody"
import PrintModal from "./PrintModal"
import { QSRFilters } from "./QSRFilters"

const PIVOT_COLUMNS = getPivotNameKeys(QSR_COLUMNS)

const { Settings, Table, Description } = tieredTableFactory({
    name: C.QUALITY_SUMMARY_REPORT_TABLE,
    columns: QSR_COLUMNS,
    sortBy: PIVOT_COLUMNS,
    sortDirection: Array(PIVOT_COLUMNS.length).fill(SortDirection.ASC)
})

const QSR = props => {
    const {
        activeFilters,
        error,
        exportQSR,
        filters,
        loading,
        lookup,
        rows
    } = props

    const [expanded, setExpanded] = useState(new Set())
    useEffect(() => setExpanded(new Set()), [filters])

    return (
        <>
            <div className="page-header qsr-header">
                <h1 style={{ display: "inline-block" }}>
                    Quality Summary Report
                    <GuideLink page="view-quality-summary-report" />
                </h1>
                <DashboardFilters
                    {...props}
                    title="Edit Quality Summary Report Filters"
                    form={C.QUALITY_SUMMARY_FORM}
                    formComponent={QSRFilters}
                    favoritesIgnoreFields={[C.FILTERS.AS_OF_DATE]}
                    initialValues={filters}
                />
                <PrintModal qsrState={{ expanded }} />
            </div>
            <Card className="card-table qsr">
                <Card.Header>
                    <CardTitle>Quality Measures</CardTitle>
                    <div className="card-header-group">
                        <ExportIcon
                            onClick={() => exportQSR({ expanded })}
                            style={{
                                verticalAlign: "middle"
                            }}
                        />
                        <Settings />
                    </div>
                    <FilterHeader
                        filters={filters}
                        lookup={lookup}
                        translation={C.QSR_TRANSLATION}
                    />
                </Card.Header>
                <Description />
                <QSRBody
                    loading={loading}
                    error={error}
                    rows={rows}
                    activeFilters={activeFilters}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    Table={Table}
                />
            </Card>
            <Row className="row-equal-columns qsr-supplements">
                <Col xs={{ span: 12, order: 2 }} xl={{ span: 6, order: 1 }}>
                    <CoverageReference />
                </Col>
                <Col xs={{ span: 12, order: 1 }} xl={{ span: 6, order: 2 }}>
                    <PerformanceChart />
                </Col>
            </Row>
        </>
    )
}
export default QSR
