import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"

export const ClearFiltersButton = () => (
    <Button
        as={Link}
        to={location.pathname}
        className="clear-filters"
        size="xs"
        variant="light"
        replace
    >
        Reset Filters
    </Button>
)
