import React from "react"
import classNames from "classnames"
import { Hoverlay } from "../../core/Hoverlay"

const InfoIndicator = ({ as: As = "span", innerProps = {}, ...props }) => (
    <Hoverlay className="tooltip-blue" placement="right" {...props}>
        <As
            {...innerProps}
            className={classNames("fa indicator-info", innerProps.className)}
        />
    </Hoverlay>
)
export default InfoIndicator
