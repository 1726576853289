import React from "react"
import { connect } from "react-redux"
import { getFormValues, isInvalid } from "redux-form"

import { getMetaData, getResourceData } from "../../../core/fetcher"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import AddRiskModal from "./AddRiskModal"

const AddRisk = props => {
    return (
        <span>
            <span
                className="add_risk-button fa fa-plus-circle"
                onClick={props.showRiskModal}
            />
            <AddRiskModal {...props} />
        </span>
    )
}

export default connect(state => {
    const { hfRiskType, riskRank } = getResourceData(C.FILTER_OPTIONS)(state)
    const metadata = getMetaData(C.FILTER_OPTIONS)(state)
    const store = state[C.NAME]
    const loading = (metadata && metadata.loading) || (store && store.loading)
    return {
        ...store,
        metadata: {
            ...metadata,
            loading
        },
        formValues: getFormValues(C.ADD_RISK_FORM)(state),
        invalid: isInvalid(C.ADD_RISK_FORM)(state),
        hfRiskType,
        riskRank
    }
}, reduxActions)(AddRisk)
