import React from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import SearchBar from "../../core/SearchBar"
import fetcher from "../../core/fetcher"
import { ExportIcon } from "../../core/Icons"
import { costFiltersSelector } from "../../cost/selectors"
import { tieredTableFactory, getRowClass } from "../../table"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import { PROCEDURE_COLUMNS } from "../columns"
import * as actions from "../actions"
import * as C from "../constants"
import { proceduresDataSelector } from "../selectors"

const { Table, Settings, Description } = tieredTableFactory({
    name: C.PROCEDURES_TABLE,
    columns: PROCEDURE_COLUMNS,
    sortBy: [
        C.TABLE_KEYS.CATEGORY_NAME,
        C.TABLE_KEYS.SUBCATEGORY_NAME,
        C.TABLE_KEYS.PROCEDURE_NAME,
        C.TABLE_KEYS.SERVICING_PROVIDER_NAME
    ]
})

export const ProceduresTable = ({
    procedures,
    loading,
    error,
    onSearch,
    exportProcedures
}) => {
    return (
        <Card className="card-table procedures-table">
            <Card.Header>
                <Card.Title>Procedures</Card.Title>
                <SearchBar
                    placeholder="Search for Procedure Name"
                    onChange={onSearch}
                    cosmeticButton
                />
                <ExportIcon
                    style={{ whiteSpace: "nowrap" }}
                    onClick={exportProcedures}
                />
                <Settings />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC list={procedures} loading={loading} error={error}>
                    <Table list={procedures} getRowClass={getRowClass(2)} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}

export default fetcher({
    name: C.PROCEDURES_TABLE,
    method: ApiTypes.GET,
    endpoint: state =>
        urlWithParams(
            "/api/cost/procedures",
            costFiltersSelector(C.NAME)(state)
        )
})(
    connect(
        (state, { searchTerm }) => ({
            procedures: proceduresDataSelector(state, searchTerm)
        }),
        actions
    )(ProceduresTable)
)
