import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { moduleEnabledSelector } from "../../moduleUtils"
import { MODULE_IDS } from "../../constants"
import * as reduxActions from "../actions"

const CareManagerButton = ({
    careManager,
    className,
    enabled,
    showCareManagerModal,
    style
}) =>
    enabled && (
        <span
            className={classNames("care_manager-button fa", className)}
            style={style}
            onClick={() => showCareManagerModal(careManager)}
        />
    )

export default connect(
    state => ({
        enabled: moduleEnabledSelector(MODULE_IDS.CARE_MANAGEMENT)(state)
    }),
    reduxActions
)(CareManagerButton)
