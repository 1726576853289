import React, { PureComponent } from "react"
import ReactTable from "react-table"
import { Field } from "redux-form"
import _ from "lodash"

import { ValidationError } from "../../../../core/ValidationError"
import LEHOC from "../../../../core/LoadingErrorHOC"
import { TableDescription } from "../../../../table"
import { EDIT_USER_PRACTICE_COLUMNS } from "../../../columns"
import { isUserInPractice } from "../../../helpers"
import * as C from "../../../constants"

class UserPracticePage extends PureComponent {
    toggleVisible = practiceUnitId => {
        const { selectedUser = {}, touch, change } = this.props

        const userUnits = _.uniq(selectedUser[C.USER_FIELDS.USER_UNITS])
        const adminUnits = _.uniq(selectedUser[C.USER_FIELDS.ADMIN_UNITS]) // not really expecting duplicates, but _.uniq() also makes us a new array to work with
        let primaryPractice = selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE]

        if (userUnits.includes(practiceUnitId)) {
            _.pull(userUnits, practiceUnitId)
            _.pull(adminUnits, practiceUnitId)

            if (primaryPractice === practiceUnitId) {
                primaryPractice = 0
            }
        } else {
            userUnits.push(practiceUnitId)

            if (!primaryPractice) {
                // no primary practice was set, so this can be the one
                primaryPractice = practiceUnitId
            }
        }

        change(C.USER_FIELDS.ADMIN_UNITS, adminUnits)
        change(C.USER_FIELDS.USER_UNITS, userUnits)
        change(C.USER_FIELDS.PRIMARY_PRACTICE, primaryPractice)
        touch(C.USER_FIELDS.ADMIN_UNITS)
        touch(C.USER_FIELDS.USER_UNITS)
        touch(C.USER_FIELDS.PRIMARY_PRACTICE)
    }

    toggleAdmin = practiceUnitId => {
        const { selectedUser = {}, touch, change } = this.props

        const adminUnits = [...selectedUser[C.USER_FIELDS.ADMIN_UNITS]]

        if (adminUnits.includes(practiceUnitId)) {
            _.pull(adminUnits, practiceUnitId)
        } else {
            adminUnits.push(practiceUnitId)
        }

        change(C.USER_FIELDS.ADMIN_UNITS, adminUnits)
        touch(C.USER_FIELDS.ADMIN_UNITS)
    }

    setPrimary = primaryPractice => {
        this.props.change(C.USER_FIELDS.PRIMARY_PRACTICE, primaryPractice)
        this.props.touch(C.USER_FIELDS.PRIMARY_PRACTICE)
    }

    // resolve data //
    getExtraRowData = () => {
        const {
            toggleVisible,
            toggleAdmin,
            setPrimary,
            props: { editingSuperuser, selectedUser = {}, initialValues = {} }
        } = this

        return {
            toggleVisible,
            toggleAdmin,
            setPrimary,
            editingSuperuser,
            [C.USER_FIELDS.USER_UNITS]:
                selectedUser[C.USER_FIELDS.USER_UNITS] || [],
            [C.USER_FIELDS.ADMIN_UNITS]:
                selectedUser[C.USER_FIELDS.ADMIN_UNITS] || [],
            [C.USER_FIELDS.PRIMARY_PRACTICE]:
                selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE],

            // we include these for sorting purposes
            originalUserUnits: initialValues[C.USER_FIELDS.USER_UNITS] || [],
            originalAdminUnits: initialValues[C.USER_FIELDS.ADMIN_UNITS] || [],
            originalPrimaryPractice:
                initialValues[C.USER_FIELDS.PRIMARY_PRACTICE]
        }
    }

    getActivePractices = () => {
        const extraRowData = this.getExtraRowData()
        return _.map(this.props.practices, practice => ({
            ...practice,
            ...extraRowData,

            // locks that can't be applied in advance so easily, because they depend on form values
            // TODO well actually we totally could put this in a selector, but we haven't yet
            lockPrimaryPractice:
                practice.lockPrimaryPractice ??
                (isUserInPractice(this.props.selectedUser, practice)
                    ? undefined
                    : "The user is not in this practice."),
            lockAdmin: this.props.editingSuperuser
                ? "Superusers can always administrate all practices."
                : isUserInPractice(this.props.selectedUser, practice)
                ? undefined
                : "The user is not in this practice."
        }))
    }

    render() {
        const { practices, loading, initialValues = {} } = this.props
        const isLoading =
            loading ||
            practices.length === 0 ||
            initialValues[C.USER_FIELDS.USER_UNITS] === null

        // we can't add the extra fields in resolveData because sorting happens before data resolution
        const data = this.getActivePractices()

        return (
            <div className="admin-edit-practices">
                <TableDescription
                    tableName={C.EDIT_USER_PRACTICES}
                    Component="p"
                />
                <LEHOC loading={isLoading}>
                    <ReactTable
                        data={data}
                        noDataText="No Practice Units found!"
                        columns={EDIT_USER_PRACTICE_COLUMNS}
                        defaultSorted={[
                            {
                                id: C.EDIT_USER_PRACTICE_KEYS.ADMIN,
                                desc: true
                            },
                            {
                                id: C.EDIT_USER_PRACTICE_KEYS.VISIBLE,
                                desc: true
                            },
                            {
                                id: C.EDIT_USER_PRACTICE_KEYS.PRACTICE_NAME,
                                desc: false
                            }
                        ]}
                        defaultPageSize={practices.length}
                        showPagination={false}
                    />
                </LEHOC>
                <div>
                    <Field
                        name={C.USER_FIELDS.USER_UNITS}
                        component={ValidationError}
                    />
                    <Field
                        name={C.USER_FIELDS.PRIMARY_PRACTICE}
                        component={ValidationError}
                    />
                </div>
            </div>
        )
    }
}

export default UserPracticePage
