import { createSelector } from "reselect"
import _ from "lodash"

import { getResourceData } from "../core/fetcher"
import { tableFiltersSelector } from "../site_settings/selectors"
import {
    addPath,
    getColumnKeys,
    getCopyFields,
    getSelectedColumns
} from "../table/helpers"
import { TABLE_STORE } from "../table/constants"
import { MONTH_LABELS } from "../dates"
import { FILTER_OPTIONS, PATH, POPULATION_VALUES } from "../constants"
import {
    joinTruthy,
    sizeExactly1,
    urlWithParams,
    valueLabelToObj
} from "../utils"
import { easyFormValueSelector } from "../selectorUtils"
import { getTableFilters } from "./helpers"
import * as C from "./constants"

// various utilities

const aggregate = (rows, depth, columnKeys) => {
    // aggregate the numeric fields
    const sums = _.mapValues(C.COLUMN_AGGREGATORS, (aggregator, key) =>
        aggregator(
            rows.map(row => row[key]),
            rows
        )
    )

    // copy over the non-numeric fields
    const copyFields = getCopyFields(columnKeys, depth)
    const copy = _.isEmpty(rows) ? {} : _.pick(_.head(rows), copyFields)
    const path = _.at(_.head(rows), columnKeys.pivotIdKeys.slice(0, depth))
    return { path, ...copy, ...sums }
}

// hide measure options that don't appear anywhere in the unfiltered data
const measureOptionsSelector = createSelector(
    getResourceData(FILTER_OPTIONS, data => data.measures),
    getResourceData(C.QSR_OPTIONS, data => data[C.FILTERS.MEASURES]),
    easyFormValueSelector(C.QUALITY_SUMMARY_FORM, C.FILTERS.MEASURES),
    (options, validMeasureIds, selectedMeasureIds) => {
        if (_.isEmpty(options) || _.isEmpty(validMeasureIds)) {
            // probably they just haven't arrived yet
            return options
        }

        const validOptionsSet = new Set(validMeasureIds)
        const selectedOptionsSet = new Set(selectedMeasureIds) // if you change the end date or incentivized filters so a measure is no longer a valid option, it should still stay selected until removed
        const filteredOptions = _.filter(
            options,
            ({ value }) =>
                validOptionsSet.has(value) || selectedOptionsSet.has(value)
        )

        return _.sortBy(filteredOptions, o => o.label)
    }
)

// measures for the chart dropdown. Main difference is that it's empty when there's no data, and only shows selected measures if there are any of those.
const chartMeasureOptionsSelector = createSelector(
    getResourceData(FILTER_OPTIONS, data => data.measures),
    getResourceData(C.QSR_OPTIONS, data => data[C.FILTERS.MEASURES]),
    state => state[C.NAME].filters?.[C.FILTERS.MEASURES],
    (options = [], validMeasureIds, selectedMeasureIds) => {
        const measureSet = new Set(
            _.isEmpty(selectedMeasureIds) ? validMeasureIds : selectedMeasureIds
        )
        return options.filter(({ value }) => measureSet.has(value))
    }
)

// the actual options selector

export const optionsSelector = createSelector(
    getResourceData(FILTER_OPTIONS),
    measureOptionsSelector,
    chartMeasureOptionsSelector,
    (options, measures, chartMeasures) => ({
        ...options,
        measures,
        chartMeasures
    })
)

// supplementary selectors for QSRs

export const qsrInitialValuesSelector = createSelector(
    tableFiltersSelector(C.QUALITY_SUMMARY_FORM),
    defaultFilters => ({
        ...C.initialValues,
        ...defaultFilters
    })
)

export const qsrFiltersSelector = createSelector(
    qsrInitialValuesSelector, // need to get the table filters from Settings included
    state => state[C.NAME].filters,
    (initialValues, filters) => ({ ...initialValues, ...filters })
)

export const qsrChartFiltersSelector = createSelector(
    qsrFiltersSelector,
    getTableFilters
)

export const qsrYearSelector = createSelector(
    qsrChartFiltersSelector,
    filters => filters[C.FILTERS.YEAR]
)

// the actual QSR selectors

export const lookupSelector = createSelector(
    getResourceData(FILTER_OPTIONS),
    options => ({
        ..._.mapValues(C.FILTER_MAPPING, filterField =>
            valueLabelToObj(options[filterField])
        ),
        [C.FILTERS.MONTH]: MONTH_LABELS,
        [C.FILTERS.POPULATION]: POPULATION_VALUES
    })
)

const qsrColumnsSelector = createSelector(
    state => state[TABLE_STORE][C.QUALITY_SUMMARY_REPORT_TABLE],
    ({ columns = [], frozenColumns = [] } = {}) => [
        ...frozenColumns,
        ...columns
    ]
)

// 'active' in this case means "there's only one value for this filter, so you don't need to display a column for it"
export const activeQsrFiltersSelector = createSelector(
    qsrFiltersSelector,
    qsrColumnsSelector,
    (filters, columns) =>
        getSelectedColumns(columns)
            .filter(col => sizeExactly1(filters[col.filterField]))
            .map(col => col.key)
)

export const qsrRowsSelector = createSelector(
    getResourceData(C.NAME, data => data.qsrs),
    qsrColumnsSelector,
    activeQsrFiltersSelector,
    (qsrs, columns, activeFilters) => {
        // get the list of columns that can potentially be shown
        const noActiveFilterColumns = getSelectedColumns(columns).filter(
            col => !activeFilters.includes(col.key)
        )
        const columnKeys = getColumnKeys(noActiveFilterColumns)

        // mark everything with paths and then aggregate it
        const pathed = (qsrs || []).map(qsr => addPath(qsr, columnKeys))
        const grouped = _.dropRight([
            ...columnKeys.pivotIdKeys,
            ...columnKeys.otherIdKeys // currently there are no collapsible non-pivot columns, but we do this in case there are some later
        ]) // we don't group by the final piece of the path
            .map(
                (field, i) =>
                    _.groupBy(pathed, row => row[PATH]?.slice(0, i + 1)) // +1 because i starts from 0, but path lengths start from 1
            )
        const aggregated = grouped.flatMap((groups, i) =>
            Object.values(groups).map(group =>
                aggregate(group, i + 1, columnKeys)
            )
        )

        return [...aggregated, ...pathed]
    }
)

export const qsrEndpointSelector = createSelector(qsrFiltersSelector, filters =>
    urlWithParams(`/api/qsr`, getTableFilters(filters))
)

export const getQsrChartData = (
    { networkData, practiceData, providerData },
    transformKey = key => key,
    transformName = name => name,
    className
) => {
    const practices = _.groupBy(practiceData, C.TABLE_KEYS.PRACTICE_ID)
    const providers = _.groupBy(providerData, C.TABLE_KEYS.PROVIDER_ID)

    const axisKey = transformKey(C.BB_KEYS.X)

    const names = {}
    const classes = {}
    const xs = {}
    const columns = [
        // start with main axis
        [axisKey, ...networkData.map(item => item.repMonNumeric.toString())]
    ]

    const makeColumn = (series, key, id, label, columnClassName) => {
        const finalKey = joinTruthy([transformKey(key), id], "-")

        names[finalKey] = transformName(label || C.BB_LABELS[key] || key)
        xs[finalKey] = axisKey
        columns.push([
            finalKey,
            ..._.sortBy(series, C.TABLE_KEYS.REP_AS_OF_DT).map(item =>
                _.round(item[key], 2)
            )
        ])

        if (className || columnClassName) {
            classes[finalKey] = ["", className, columnClassName].join(" ")
            // this will give us a class attr that looks like "bb-target- class-name column-class-name"
            // which is a bit of a hack, but very handy
        }
    }

    // required columns
    makeColumn(networkData, C.BB_KEYS.GOAL_RATE, null, null, "no-circles")
    makeColumn(networkData, C.BB_KEYS.HVPA_RATE)

    _.values(practices).forEach(months => {
        makeColumn(
            months,
            C.BB_KEYS.PRACTICE_RATE,
            months[0][C.TABLE_KEYS.PRACTICE_ID],
            months[0][C.TABLE_KEYS.PRACTICE_NAME]
        )
    })
    _.values(providers).forEach(months => {
        makeColumn(
            months,
            C.BB_KEYS.PROVIDER_RATE,
            months[0][C.TABLE_KEYS.PROVIDER_ID],
            months[0][C.TABLE_KEYS.PROVIDER_NAME]
        )
    })

    return {
        columns,
        classes,
        names,
        xs
    }
}

const getPriorKey = id => `prior-${id}` // if you change this, update the "background: repeating-linear-gradient" rule for .qsr-performance-chart .bb-tooltip in theme.scss.

const periodName = period => label => `${label} (${period})`

export const chartDataSelector = createSelector(
    (state, showPrior) => showPrior,
    qsrYearSelector,
    getResourceData(C.PERFORMANCE_NAME),
    (showPrior, period, { currentYear, priorYear }) => {
        const currentData = getQsrChartData(
            currentYear,
            id => id,
            showPrior ? periodName(Number(period)) : name => name
        )
        const priorData = getQsrChartData(
            priorYear,
            getPriorKey,
            periodName(Number(period) - 1),
            "prior"
        )
        const columns = _.compact(
            _.flatten(
                _.zip(currentData.columns, showPrior ? priorData.columns : []) // alternating to keep the prior year next to the current year for each metric
            )
        )

        return {
            ..._.merge({}, currentData, priorData),
            columns
        }
    }
)

const productGroupLookupSelector = createSelector(
    getResourceData(FILTER_OPTIONS, data => data.productGroups),
    productGroups => _.keyBy(productGroups, C.TABLE_KEYS.PRODUCT_GROUP_ID)
)

export const productGroupSelector = createSelector(
    productGroupLookupSelector,
    lookup => rowData => lookup[rowData[C.TABLE_KEYS.PRODUCT_GROUP_ID]] || {}
)
