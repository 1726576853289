import * as PC from "../patient_tags/constants"

export const NAME = "USER_TAGS"
export const USER_TAGS_TABLE = "USER_TAGS_TABLE"
export const TAG_PATIENTS_TABLE = "TAG_PATIENTS_TABLE"
export const TAG_USERS_TABLE = "TAG_USERS_TABLE"
export const TAG_DETAILS = "TAG_DETAILS"
export const EDIT_TAG_FORM = "EDIT_TAG_FORM"
export const TAG_USERS_FORM = "TAG_USERS_FORM"

export const NEW_TAG_ID = 0 // tag id 0 is the marker for a temporary new row. Used to be -1, but now system-defined tags have negative IDs.

// action names
export const SHOW_USER_TAGS_MODAL = "SHOW_USER_TAGS_MODAL"
export const HIDE_USER_TAGS_MODAL = "HIDE_USER_TAGS_MODAL"
export const SELECT_TAG = "SELECT_TAG"
export const EDIT_TAG = "EDIT_TAG"
export const CANCEL_EDIT_TAG = "CANCEL_EDIT_TAG"
export const SAVE_TAG = "SAVE_TAG"
export const CANCEL_SAVE_TAG = "CANCEL_SAVE_TAG"
export const SAVE_TAG_COMPLETE = "SAVE_TAG_COMPLETE"
export const DELETE_TAG = "DELETE_TAG"
export const DELETE_TAG_COMPLETE = "DELETE_TAG_COMPLETE"
export const SET_TAG_PRIVILEGES = "SET_TAG_PRIVILEGES"
export const SET_TAG_PRIVILEGES_COMPLETE = "SET_TAG_PRIVILEGES_COMPLETE"

export const TABLE_KEYS = {
    ...PC.TABLE_KEYS,
    TAG_ID: "tagId",
    TAG_NAME: "tagName",
    OWNER_ID: "ownerId",
    OWNER: "ownerName",
    OWNER_USERNAME: "ownerUsername",
    PATIENT_ID: "patientId",
    PATIENT_NAME: "patientName",
    PATIENT_COUNT: "patientCount",
    PUBLIC: "isPublic",
    CREATED_DATE: "createdDate",
    PRACTICE: "practiceName",
    PRACTICE_ID: "practiceUnitId",
    PRACTICE_IDS: "practiceUnitIds",
    PRACTICE_NAME: "practiceUnitName",
    ACCESS_LEVEL: "accessLevel",
    TAG_ACCESS: "canAccessTag",
    PRIVILEGE: "privilege",
    SOURCE: "source",
    SOURCE_DATA: "sourceData",
    ACTIONS: "actions"
}

export const TABLE_TRANSLATION = {
    ...PC.TABLE_TRANSLATION,
    [TABLE_KEYS.TAG_NAME]: "Tag",
    [TABLE_KEYS.OWNER]: "Owner",
    [TABLE_KEYS.PATIENT_NAME]: "Patient",
    [TABLE_KEYS.PATIENT_COUNT]: "Patients",
    [TABLE_KEYS.PUBLIC]: "Public",
    [TABLE_KEYS.CREATED_DATE]: "Date Created",
    [TABLE_KEYS.PRACTICE]: "Primary Practice",
    [TABLE_KEYS.ACCESS_LEVEL]: "Role",
    [TABLE_KEYS.TAG_ACCESS]: "Can Access",
    [TABLE_KEYS.PRIVILEGE]: "Privilege",
    [TABLE_KEYS.ACTIONS]: "Actions"
}

export const FORM_FIELDS = {
    PUBLIC: "isPublic",
    TAG_NAME: "tagName",
    OWNER_ID: "ownerId",
    USER_PRIVILEGES: "userPrivileges",
    PRACTICE_PRIVILEGES: "practicePrivileges"
}

export const initialState = {
    open: false,
    editing: false,
    saving: false,
    savingUsers: [],
    savingPractices: [],
    currentTag: {
        /*[TABLE_KEYS.TAG_ID]: undefined,
        [TABLE_KEYS.TAG_NAME]: undefined,
        [TABLE_KEYS.PUBLIC]: undefined,
        [TABLE_KEYS.OWNER_ID]: undefined*/
        // those are the fields that will go into currentTag, but we don't actually need to specify them right now
    }
}
