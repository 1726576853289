import React from "react"
import bb from "billboard.js"
import * as C from "../constants"

export class UtilizationChart extends React.Component {
    getId = () => "cost-utilization-chart-" + this.props.id

    buildChart() {
        const type = this.props.type

        const xFormat = this.props.xFormat || "%b" // i.e. month abbreviated
        const yFormat = this.props.yFormat || undefined
        const tooltipFormat = this.props.tooltipFormat || undefined
        const tooltipTitleFormat =
            this.props.tooltipTitleFormat || C.DATE_FORMATTERS.MONTH

        const columns = this.props.columns || []
        const rows = this.props.rows || []
        const names = this.props.names || {}

        const xAxisType = type === "bar" ? "timeseries" : undefined
        const groups = type === "bar" ? [columns] : undefined

        const onBarClick = this.props.onBarClick || (() => null)

        bb.generate({
            height: this.props.height,
            bindto: `#${this.getId()}`,
            axis: {
                x: {
                    type: xAxisType,
                    tick: { format: xFormat, culling: false }
                },
                y: { tick: { format: yFormat } }
            },
            grid: { y: { show: true } },
            tooltip: {
                grouped: false,
                format: {
                    title: tooltipTitleFormat,
                    value: tooltipFormat
                }
            },
            data: {
                x: "x",
                type,
                groups,
                names,
                rows: [
                    ["x", ...columns], // header row
                    ...rows
                ],
                onclick: bar => onBarClick(bar.id)
            }
        })
    }

    // noinspection JSCheckFunctionSignatures
    componentDidMount() {
        this.buildChart()
    }

    // noinspection JSCheckFunctionSignatures
    shouldComponentUpdate(nextProps) {
        return nextProps.activeTab === nextProps.id // only update if this is the active tab
    }

    // noinspection JSCheckFunctionSignatures
    componentDidUpdate(prevProps) {
        this.buildChart()
    }

    render() {
        return (
            <div
                className="cost-utilization-chart"
                id={this.getId()}
                style={{ height: this.props.height }}
            />
        )
    }
}
