import React, { useState } from "react"
import { SortDirection } from "react-virtualized"
import { Button, Card } from "react-bootstrap"
import moment from "moment"
import _ from "lodash"
import LEHOC from "../../core/LoadingErrorHOC"
import { isoToUsDate } from "../../dates"
import { tableFactory } from "../../table"
import * as C from "../constants"
import { COLUMNS } from "../columns"
import { AddTagsModal } from "./AddTagsModal"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: COLUMNS,
    sortBy: [C.TABLE_KEYS.TAG],
    sortDirection: [SortDirection.ASC]
})

const initialValues = {
    [C.FILTERS.START_DATE]: moment()
}

export const Tags = ({
    tags,
    loading,
    error,
    disableEdit,
    removeTag,
    restoreTag,
    ...props
}) => {
    const [showModal, setShowModal] = useState(false)
    const hideModal = () => setShowModal(false)

    const [selectedTag, setSelectedTag] = useState(initialValues)
    const editTag = row => {
        setSelectedTag({
            ...row,
            [C.FILTERS.PATIENT]: props.patientOptions[0].value, // should be exactly one item in the list
            [C.FILTERS.START_DATE]: isoToUsDate(row[C.TABLE_KEYS.START_DATE]),
            [C.FILTERS.END_DATE]: isoToUsDate(row[C.TABLE_KEYS.END_DATE])
        }) // if the object structure changes, make sure the field names still match up with the filter names. If they don't, you'll have to convert them manually.
        setShowModal(true)
    }

    const pickedTags = new Set(_.map(tags, C.TABLE_KEYS.TAG_ID))

    return (
        <Card className="card-table patient-tags">
            <Card.Header>
                <Card.Title>Patient Tags</Card.Title>

                {disableEdit || (
                    <React.Fragment>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => editTag(initialValues)}
                        >
                            Add Tag
                        </Button>
                        <AddTagsModal
                            {...props}
                            pickedTags={pickedTags}
                            show={showModal}
                            initialValues={selectedTag}
                            onHide={hideModal}
                        />
                    </React.Fragment>
                )}

                <Settings />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC list={tags} loading={loading} error={error}>
                    <Table
                        list={tags}
                        rowClassNames={{
                            removed_row: rowData => rowData.removed
                        }}
                        tableData={{
                            disableEdit,
                            onEdit: editTag,
                            onRemove: removeTag,
                            onUndo: restoreTag
                        }}
                    />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}
