import fetcher from "../../core/fetcher"
import * as C from "../constants"
import Admissions, { defaultOrderBys } from "./Admissions"

export default fetcher({
    name: C.NAME,
    endpoint: state => `/api/patients/${state.poc_id}/admissions`,
    defaultOrderBys,
    paginationKey: "admissions"
})(Admissions)
