import React from "react"
import classNames from "classnames"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"

const displayIdentifier = identifier => identifier.replaceAll?.(":", ": ")

export const Identifiers = ({ cellData, rowData }) => {
    if (_.isEmpty(cellData)) {
        return null
    }

    const primary = rowData?.[C.TABLE_KEYS.PRIMARY_IDENTIFIER]
    const sorted = _.sortBy(cellData, identifier => identifier !== primary) // always sorts asc, so we do "!==" to put primary first

    const content = sorted.map((identifier, key) => (
        <li
            key={key}
            className={classNames({
                "identifier-primary": identifier === primary
            })}
        >
            {displayIdentifier(identifier)}
        </li>
    ))

    return (
        <Hoverlay
            className="identifiers-tooltip tooltip-list"
            tooltip={<ul>{content}</ul>}
        >
            <ul className="identifiers">{content}</ul>
        </Hoverlay>
    )
}
