import _ from "lodash"
import moment from "moment"
import { FORCE_LOGOUT, LOGOUT } from "./authentication/constants"
import { TIMESTAMP_STRING_COMPACT } from "./dates"
import JSONCrush from "jsoncrush"
import React from "react"

// REDUCER CREATION //

const isLogout = action => [LOGOUT, FORCE_LOGOUT].includes(action.type)

export const createReducer = (initialState, handlers) => (
    state = initialState,
    action
) => {
    if (handlers.hasOwnProperty(action.type)) {
        // we've got a handler. use it to update the state
        return handlers[action.type](state, action)
    } else if (isLogout(action)) {
        // on logout, reset the state
        return initialState
    } else {
        // otherwise, we have nothing to do here
        return state
    }
}

// SOME USEFUL CONSTANTS //

export const EMPTY_OBJECT = Object.freeze({})
export const EMPTY_LIST = Object.freeze([])
export const EMPTY_STRING = Object.freeze("") // strings don't really need to be frozen, but this drives it home

// MISC //

export const asArray = value =>
    _.isNil(value) ? EMPTY_LIST : _.castArray(value)

export const objectPop = (obj, key) => {
    const value = obj[key]
    delete obj[key]
    return value
}

export const defaultOrBlank = (value, defaultValue) =>
    value === defaultValue ? "" : defaultValue ?? ""

export const isNilOrBlank = value =>
    _.isNil(value) || _.isNaN(value) || value === ""
export const isNilOrEmpty = value =>
    _.isNil(value) ||
    _.isNaN(value) ||
    (_.isArrayLike(value) && _.isEmpty(value))

export const sizeExactly1 = collection => _.size(collection) === 1

export const getValues = items => _.map(items, item => item.value)

export const filterByValue = (options, value) => _.filter(options, { value })

export const findByValue = (options, value) => _.find(options, { value })

export const takeUntil = (items, value) =>
    _.takeWhile(items, item => item !== value)

export const matchesSearch = (string = "", searchTerm = "") =>
    string.toLowerCase().includes(searchTerm.toLowerCase())

export const filterBySearch = (items = [], key, searchTerm) =>
    items.filter(item => {
        const field = _.isFunction(key) ? key(item) : (item || {})[key]
        return matchesSearch(field, searchTerm)
    })

/* Lazily invoke functions with the supplied args until one returns something truthy, then return that result.
 * I'd have thought lodash would have something that does this, but I couldn't find anything.
 * _.cond comes close, but returns a predefined value.
 * (functions, ...args) => _.find(_.over(functions)(...args)) is even closer, but it isn't lazy.
 */
export const callUntilTruthy = (functions, ...args) => {
    for (const func of functions) {
        const value = func?.(...args)
        if (value) return value
    }
    return undefined
}

export const intersectionCount = (...lists) => _.intersection(...lists).length
export const intersectionExists = (...lists) => !!intersectionCount(...lists)

export const sameItems = (...lists) => _.isEmpty(_.xor(...lists))

export const sortedUnique = (...arrays) =>
    _.sortBy(
        _.uniqBy(_.compact(arrays).flat(), ({ id }) => id),
        ({ label }) => label
    )

export const keepEach = (values, masks) =>
    _.zip(values, masks)
        .filter(([, mask]) => mask)
        .map(([value]) => value)

export const partitionObject = (obj, predicate) => {
    return _.partition(Object.entries(obj), ([key, value]) =>
        predicate(value, key)
    ).map(_.fromPairs)
}

// TODO rename this. It's not clear that it's basically getFilterValuesFromRow
//  I suppose it could be reworked to something more generic. Like, the thing that separates this from omitEmpty() below is the asArray call and the filterMapping, but I think those could be added to an omitEmpty call without too much trouble
export const getFilterValues = (fields, filterMapping) =>
    _.pickBy(
        _.mapValues(filterMapping, fieldName =>
            _.compact(asArray(fields[fieldName]))
        ),
        a => !_.isEmpty(a)
    )

export const mergeObjects = objects => Object.assign({}, ...objects)

export const tableHeightForLength = length => _.floor(40 + length * 35)

export const getSpaceLeftBy = (list = [], max = 5, min = 3) =>
    Math.max(max - list.length, min)

// FUNCTION CALLERS WITH FALLBACKS FOR NON-FUNCTIONS //

export const callOrFallback = (item, funcOrOther, fallback) =>
    _.isFunction(funcOrOther) ? funcOrOther(item) : fallback(item, funcOrOther)
export const callOrTake = (item, funcOrValue) =>
    callOrFallback(item, funcOrValue, () => funcOrValue) // return funcOrValue(item) or funcOrValue
export const callOrGet = (item, funcOrKey) =>
    callOrFallback(item, funcOrKey, () => item[funcOrKey]) // return funcOrKey(item) or item[funcOrKey]
export const callOrLookup = (item, funcOrDict) =>
    callOrFallback(item, funcOrDict, () => funcOrDict[item]) // return funcOrDict(item) or funcOrDict[item]
export const callOrMatch = (item, funcOrValue) =>
    callOrFallback(item, funcOrValue, () => item === funcOrValue) // return funcOrValue(item) or item === funcOrValue
export const getFieldOrSelf = (value, key) =>
    _.isObject(value) ? callOrGet(value, key) : value // return key(value) or value[key] or value

// UPDATE SPECIFIC ITEM IN LIST //

/**
 * Search a collection for objects with a particular field matching some value, and update them with new values.
 *
 * @param items A collection of items, usually an array of objects, which we intend to search.
 * @param update A function to create a new item from an old, an object to merge into the old item, or a value to replace it.
 * @param key See below.
 * @param value There are several valid configurations for the key and value props.
 * - key is primitive, value is primitive: update if item[key] === value.
 * - key is primitive, value is object: update if item[key] === value[key].
 * - key is primitive, value is undefined: update if item === key.
 * - both are undefined: just update everything.
 * - key is a function: update if key(item) is truthy. Ignore value.
 * - key is an object: update if item matches key as per _.isMatch. Ignore value.
 *
 * @returns An array containing the contents of `items`, but with the matching elements updated as specified.
 */
export const findAndUpdate = (items, update, key, value) =>
    _.map(items, item =>
        !getMatch(item, key, value)
            ? item
            : _.isFunction(update)
            ? update(item)
            : _.isObject(update)
            ? { ...item, ...update }
            : update
    )

// only for use in findAndUpdate
// XXX Could probably do some of this with _.iteratee?
const getMatch = (item, key, value) => {
    if (_.isUndefined(key) && _.isUndefined(value)) {
        return true // just doing a straight update to everything, I guess.
    }

    if (!_.isUndefined(value)) {
        return item[key] === getFieldOrSelf(value, key)
    }
    if (_.isFunction(key)) {
        return key(item)
    }
    if (_.isObject(key)) {
        return _.isMatch(item, key)
    }
    return item === key
}

// CREATE OBJECT FROM COLLECTION //

// noinspection JSValidateTypes
/**
 * Transforms a collection (usually an array) of items into an object, with keys and values based on the items and indices
 *
 * @param items A collection of objects, usually an array
 * @param keyGetter A property name or a function (item, [index]) => key to produce the key for each entry of the object.
 * @param valueGetter A property name or a function (item, [index]) => value to produce the value for each entry of the object.
 */
export const itemsToObject = (items, keyGetter, valueGetter) =>
    _.zipObject(_.map(items, keyGetter), _.map(items, valueGetter))

/**
 * Maps an object to a new object, with the new keys and values derived from the original keys and values respectively.
 * All parameters are required, because if they weren't you could just use _.mapValues or _.mapKeys
 *
 * @param object The object to be mapped
 * @param keyGetter A function to map the original key to the new key.
 * @param valueGetter A function to map the original value to the new value.
 */
export const mapObject = (object, keyGetter, valueGetter) =>
    _.zipObject(
        _.map(_.keys(object), keyGetter),
        _.map(_.values(object), valueGetter)
    )

// LABEL-VALUES CONVERSION //

// you usually want this function, not the one below
export const objToValueLabel = obj =>
    _.toPairs(obj).map(([value, label]) => ({
        label,
        value
    }))
export const objToLabelValue = obj =>
    _.toPairs(obj).map(([label, value]) => ({
        label,
        value
    }))
export const labelValueToObj = list =>
    itemsToObject(
        list,
        ({ label }) => label,
        ({ value }) => value
    )
export const valueLabelToObj = list =>
    itemsToObject(
        list,
        ({ value }) => value,
        ({ label }) => label
    )

export const arrayToLabelValue = arr =>
    Object.entries(arr).map(([value, label]) => ({
        label,
        value: Number(value) // because for some reason array indices are actually strings, not integers
    }))

export const itemsToLabelValue = (
    items,
    labelGetter = _.identity,
    valueGetter = _.identity
) => items.map(item => ({ label: labelGetter(item), value: valueGetter(item) }))

export const mapValuesToObject = (source, valueGetter = x => x) => {
    const keys = Object.values(source)
    return _.zipObject(keys, keys.map(valueGetter))
}

// TEXT PROCESSING //

const MIN_LINE = 3 // minimum length for the last line when hyphenating

const hyphenateWord = str => {
    if (str.length < MIN_LINE * 2) {
        // you can't really hyphenate anything smaller than that
        return str
    }

    const start = str.slice(0, -MIN_LINE)
    const end = str.slice(-MIN_LINE) // last line can't be too short or it'll look bad

    return start
        .split("")
        .concat(end)
        .join("\u00AD") // soft hyphen; hides until it's needed
}

// Note that the line-break functionality can be done by just using the .dont-break-out class, instead of all this manipulation. You only need this function if you really want hyphens or balanced line lengths
export const hyphenate = str =>
    str
        .split()
        .map(hyphenateWord)
        .join(" ")

export const joinTruthy = (values, separator = " ") =>
    _.compact(values)
        .join(separator)
        .trim()

export const joinPath = (...strs) => {
    const hasLeadingSlash = strs[0]?.length > 1 && _.head(strs[0]) === "/"
    // if there's a leading slash on the first item, we do need to keep that. Unless it's only a slash.
    return (
        (hasLeadingSlash ? "/" : "") +
        strs.map(str => _.trim(str, "/")).join("/")
    )
}

export const joinLines = lines => {
    const joined = _.flatMap(lines, (line, i) => [line, <br key={i} />])
    joined.pop() // remove the extra trailing <br/>
    return joined
}

export const conditionalParens = (contents, condition = contents) => {
    const inParens = contents ? `(${contents})` : ""
    return condition ? inParens : contents
}

export const displayWithConditionalParens = (main, secondary) => {
    const secondaryFinal = conditionalParens(secondary, main) // secondary gets parens if the main one is present, otherwise it's alone
    return joinTruthy([main, secondaryFinal])
}

// If val is nil, return fallback. Otherwise return func(val) (plus optional args)
export const fallbackIfNil = (func, fallback = "", nilBy = _.identity) => (
    val,
    ...args
) => (isNilOrBlank(nilBy(val)) ? fallback : func(val, ...args))

export const blankIfNil = func => fallbackIfNil(func) // mostly an alias, but this version can't accidentally take extra args

const format7DigitPhone = n => `${n.slice(0, 3)} - ${n.slice(3, 7)}`
const format10DigitPhone = n =>
    `(${n.slice(0, 3)}) ` + format7DigitPhone(n.slice(3))
const format11DigitPhone = n =>
    `${n.slice(0, 1)} ` + format10DigitPhone(n.slice(1))

export const phoneFormat = blankIfNil(n => {
    const normalized = n.toString().replace(/\D/g, "") // form into a numeric string

    switch (normalized.length) {
        case 7:
            return format7DigitPhone(normalized)
        case 10:
            return format10DigitPhone(normalized)
        case 11:
            return format11DigitPhone(normalized)
        default:
            return normalized
    }
})

// FILTERS //

export const isEmptyFilter = value =>
    _.isNil(value) || (_.isObject(value) && _.isEmpty(value))

export const omitEmpty = obj => _.omitBy(obj, isEmptyFilter)

export const compactFilters = filters =>
    _.omitBy(filters, (value, key) => isEmptyFilter(value) || key === "type") // can't import from patient_list.constants because that already imports this file

// URL PARAMS MANAGEMENT //

// noinspection JSCheckFunctionSignatures
export const urlWithParams = (url, params) =>
    `${url}?${new URLSearchParams(omitEmpty(params))}`

export const urlParamsObject = (
    params // can be a string or an object
) => {
    const p = _.isPlainObject(params) ? omitEmpty(params) : params
    return Object.fromEntries(new URLSearchParams(p))
}

export const crush = filters => {
    const compacted = omitEmpty(filters)
    const jsonned = JSON.stringify(compacted)
    return JSONCrush.crush(jsonned)
}
export const uncrush = crushedString => {
    const uncrushed = JSONCrush.uncrush(crushedString)
    return JSON.parse(uncrushed)
}

export const encodeFiltersInUrl = (pathname, filters, noDefaults) => {
    if (_.isEmpty(filters)) return pathname
    return `${pathname}?noDefaults=${
        noDefaults ? 1 : ""
    }&filters=${encodeURIComponent(crush(filters))}`
}
// the param is "noDefaults" instead of "defaults" because if someone goes to the URL with no params set, we would want the default filters to be applied

// FILENAMES AND DOWNLOAD MESSAGES //

export const getFilename = (prefix, extension) => {
    const timestamp = moment().format(TIMESTAMP_STRING_COMPACT)
    return `${prefix}_${timestamp}.${extension}`
}

// COLORS

// only accepts full hex strings. You can include opacity values if you like though.
export const hexToRGB = hex => {
    const trimmed = _.trimStart(hex, "#")
    return _.chunk(trimmed, 2).map(chunk => parseInt(chunk.join(""), 16))
}

const CONTRAST_THRESHOLD = 256 / 2

export const isLightContrast = color => {
    const [r, g, b] = hexToRGB(color)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000 // same calculation as Bootstrap uses
    return brightness > CONTRAST_THRESHOLD
}

// accepts color hex values as strings, or a boolean isLight
export const getContrastTextColor = value => {
    const isLight = typeof value === "string" ? isLightContrast(value) : !!value
    return isLight ? "#000000" : "#ffffff"
}
