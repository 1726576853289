import React from "react"
import { Col, Row } from "react-bootstrap"
import SiteTitle from "../../core/SiteTitle"
import Breadcrumbs from "../../core/Breadcrumbs"
import ReportList from "./report_list"
import ReportPanel from "./report_panel"
import { NoReportSelected } from "./NoReportSelected"
import { GuideLink } from "../../indicators"

class ReportLibrary extends React.Component {
    render() {
        const reportId =
            this.props.match && Number(this.props.match.params.reportId)
        return (
            <>
                <SiteTitle title="Reports Library" />
                <Breadcrumbs
                    items={[["Dashboard", "/dashboard"], ["Reports"]]}
                />
                <h1>
                    Reports Library
                    <GuideLink page="view-reports-library" />
                </h1>
                <Row>
                    <Col md={3} className="report-list-column">
                        <ReportList reportId={reportId} />
                    </Col>
                    <Col md={9} className="report-panel-column">
                        {reportId ? (
                            <ReportPanel reportId={reportId} />
                        ) : (
                            <NoReportSelected />
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default ReportLibrary
