import React from "react"
import { SortDirection } from "react-virtualized"
import { Card } from "react-bootstrap"
import { tableFactory } from "../../table"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import * as C from "../constants"
import TABLE_COLUMNS from "../columns"
import AddRisk from "./add_risk"

export const defaultOrderBys = {
    sortBy: [C.TABLE_KEYS.DATE],
    sortDirection: [SortDirection.DESC]
}

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    ...defaultOrderBys
})

const Risk = ({ loading, error, data: { patientRiskRanks } }) => (
    <Card id="patient-risk" className="card-table">
        <Card.Header>
            <CardTitle>Risk</CardTitle>
            <AddRisk />
            <Settings />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={patientRiskRanks}>
                <Table list={patientRiskRanks} />
            </LEHOC>
        </Card.Body>
    </Card>
)
export default Risk
