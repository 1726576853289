import * as C from "./constants"

export const saveComment = text => ({ type: C.SAVE_COMMENT, text })

export const deleteComment = userCommentId => ({
    type: C.DELETE_COMMENT,
    userCommentId
})
export const restoreComment = userCommentId => ({
    type: C.RESTORE_COMMENT,
    userCommentId
})
