import _ from "lodash"

const sna_domain_request = (fields, surveyQuestions, surveyQuestionNames) => {
    const needId = "id_137_sub_147"
    const index = surveyQuestionNames.findIndex(sqn => sqn === needId)
    const value = _.get(fields, [needId, "input", "value"])
    const option = _.get(surveyQuestions, [index, "dropListOptions"], []).find(
        option => option.id === value
    )
    return { value, result: option, hideValue: true } // no max needed
}

export default {
    id_137_sub_154: sna_domain_request
}
