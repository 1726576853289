import React from "react"
import { Link } from "react-router-dom"

const LogoutLink = ({ logoutAction }) => (
    <li className="nav-item dropdown-item">
        <Link to="/" style={{ cursor: "pointer" }} onClick={logoutAction}>
            Logout
        </Link>
    </li>
)

export default LogoutLink
