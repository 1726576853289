import React from "react"
import { YesNoIndicator } from "../../indicators/components/YesNoIndicator"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"

export const ConditionName = ({ cellData, rowData }) => (
    <Hoverlay>
        {rowData[C.TABLE_KEYS.REMOVED] || (
            <YesNoIndicator value={rowData[C.TABLE_KEYS.MET]} />
        )}{" "}
        {cellData}
    </Hoverlay>
)
