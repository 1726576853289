import React, { useEffect } from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import DashboardFilters from "../../dashboard_filters/components"
import FilterHeader from "../../core/FilterHeader"
import LER from "../../core/LoadingErrorHOC"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import { tableFactory } from "../../table"
import { AUDIT_COLUMNS } from "../columns"
import * as C from "../constants"
import { PatientAuditFilters } from "./PatientAuditFilters"
import FilesModal from "./FilesModal"
import NotesModal from "./NotesModal"
import StatusModal from "./StatusModal"

const { Settings, Table, Description } = tableFactory({
    name: C.NAME,
    columns: AUDIT_COLUMNS,
    sortBy: [C.TABLE_KEYS.SERVICE_DATE],
    sortDirection: [SortDirection.DESC]
})

export const PatientAudits = ({
    audits,
    filters,
    setFilters,
    logAuditView,
    loading,
    error,
    paginationFunctions,
    exportAudits,
    printAudits
}) => {
    useEffect(() => {
        logAuditView()
    }, [])

    return (
        <Card className="card-table patient_audits">
            <Card.Header>
                <Card.Title>Patient Audit Tracker</Card.Title>
                <DashboardFilters
                    title="Edit Patient Audit Filters"
                    form={C.PATIENT_AUDIT_FILTERS}
                    size="lg"
                    formComponent={PatientAuditFilters}
                    initialValues={filters}
                    setFilters={setFilters}
                />
                <Settings />
                <PrintIcon onClick={printAudits} className="pull-right" />
                <ExportIcon onClick={exportAudits} className="pull-right" />
                {/* TODO one more export to return all the uploaded files */}
                <FilterHeader
                    filters={filters}
                    form={C.PATIENT_AUDIT_FILTERS}
                    lookupKeys={C.FILTER_LOOKUP_KEYS}
                />
            </Card.Header>
            <Description />
            <Card.Body>
                <LER list={audits} loading={loading} error={error}>
                    <Table {...paginationFunctions} list={audits} />
                </LER>
            </Card.Body>
            <FilesModal />
            <NotesModal />
            <StatusModal />
        </Card>
    )
}
