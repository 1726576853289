import React from "react"
import { Col, Row } from "react-bootstrap"
import { FiltersModal } from "./FiltersModal"
import { FavoriteNameField } from "./FavoriteNameField"

export const AddFavoriteModal = ({
    children,
    existingNames,
    favorites,
    formValues,
    ...props
}) => (
    <FiltersModal {...props} isFavorite>
        <Row>
            <Col>
                <FavoriteNameField existingNames={existingNames} />
            </Col>
        </Row>
        <hr className="divider" />
        {children}
    </FiltersModal>
)
