import _ from "lodash"
import { blankIfNil, fallbackIfNil } from "./utils"

export const MAX_PRECISION = 7

export const formatLongNumber = blankIfNil(number =>
    number.toLocaleString("en")
)

// this one doesn't include cents
export const formatDollars = blankIfNil(
    cost => "$" + _.round(cost).toLocaleString("en")
)

// this one does include cents
export const formatDollarsCents = blankIfNil(cost =>
    cost.toLocaleString("en", {
        style: "currency",
        currency: "USD"
    })
)

export const formatFractionalDollarsCents = blankIfNil(
    cost =>
        "$" +
        _.round(cost, MAX_PRECISION).toLocaleString(undefined, {
            minimumFractionDigits: 2
        })
)

export const displayCosts = (min, max) =>
    `${formatDollars(min)} to ${formatDollars(max)}`

/** format to a particular number of significant digits.
 * e.g. 0.111111 -> 0.11, 1.11111 -> 1.1, 100 -> 100
 */
export const formatFixedPrecision = (number, precision = 2) => {
    // 0 displays as 0.0 after the toPrecision(2), so we make it display more readably
    if (number === 0) {
        return "0"
    }

    // numbers with more digits than our precision limit display in scientific notation, which we don't want either
    const minPrecision = _.round(number).toString().length
    if (precision < minPrecision) {
        return _.round(number).toLocaleString("en")
    }

    return number
        .toPrecision(_.clamp(precision, MAX_PRECISION)) // we want to always have exactly `precision` significant digits. e.g. 9 -> 9.0, 11 -> 11. To a reasonable maximum of course
        .toLocaleString("en")
}

/** format to a particular number of digits after the decimal.
 * e.g. 0.111111 -> 0.11, 1.11111 -> 1.11, 100 -> 100.00
 */
export const formatFixedDigits = (number, digits = 2) => {
    // again, if a number is actually 0 we should display it as such
    if (number === 0) {
        return "0"
    }
    return number.toFixed(_.clamp(digits, MAX_PRECISION)).toLocaleString("en")
}

export const formatFractionalNumber = blankIfNil(formatFixedPrecision)

export const formatPercent = blankIfNil(
    percentage => formatFixedPrecision(percentage) + "%"
)

export const formatFractionAsPercent = blankIfNil(
    fraction => formatFixedPrecision(fraction * 100, 3) + "%"
)

export const formatPercentOffsetFallback = fallback =>
    fallbackIfNil(
        (percentage, precision) =>
            `${percentage < 0 ? "" : "+"}${formatPercent(
                percentage,
                precision
            )}`,
        fallback
    )
export const formatPercentOffset = formatPercentOffsetFallback()
