import { PrintField } from "./PrintField"
import * as C from "../constants"
import { ButtonGroup, constants as PC } from "../../pickers"
import React from "react"

const PeriodPicker = ({ input }) => (
    <ButtonGroup
        value={input.value}
        onClick={input.onChange}
        options={PC.PERIOD_OPTIONS}
    />
)

export const PrintCosts = ({ canAccessCosts }) =>
    canAccessCosts && (
        <PrintField name={C.PRINT_FIELDS.COST} className="print-costs">
            <PrintField
                name={C.PRINT_FIELDS.COST_PERIOD}
                component={PeriodPicker}
            />
        </PrintField>
    )
