import "babel-polyfill"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import createSagaMiddleware from "redux-saga"
import { createBrowserHistory } from "history"
import { applyMiddleware, createStore } from "redux"
import { autoRehydrate, persistStore } from "redux-persist"
import { composeWithDevTools } from "redux-devtools-extension"
import { setupFetcher } from "@healthfocus/redux-obtain"
import { ConnectedRouter, routerMiddleware } from "connected-react-router"
import alerts from "./modules/alerts"
import authentication from "./modules/authentication"
import campaigns from "./modules/campaigns"
import care_management from "./modules/care_management"
import patient_audits from "./modules/patient_audits"
import createRootReducer from "./rootReducer"
import rootSaga from "./rootSaga"
import App from "./App"

import "react-checkbox-tree-enhanced/lib/react-checkbox-tree.css"
import "billboard.js/dist/billboard.min.css"
import "./assets/sass/bootstrap.css"
import "./assets/sass/dataTables.bootstrap.css"
import "./assets/sass/ui.fancytree.css"
import "./assets/sass/style.css"
import "react-datetime/css/react-datetime.css"
import "react-virtualized/styles.css"

import "./configuration"
import { PAGINATION_REQUEST_SIZE } from "./configuration"

setupFetcher({
    paginationInitialLoadLimit: PAGINATION_REQUEST_SIZE,
    requestHeaderSelector: authentication.selectors.defaultHeadersSelector
})

const history = createBrowserHistory()
const routingMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
    createRootReducer(history),
    composeWithDevTools(
        applyMiddleware(sagaMiddleware, routingMiddleware),
        autoRehydrate()
    )
)

export const render = App => {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App store={store} />
            </ConnectedRouter>
        </Provider>,
        document.getElementById("root")
    )
}

sagaMiddleware.run(rootSaga)
persistStore(store, {
    whitelist: [
        authentication.constants.NAME,
        "applied_filters",
        alerts.constants.NAME,
        "poc_id",
        campaigns.constants.CURRENT_CAMPAIGN,
        care_management.constants.NAME,
        patient_audits.constants.PATIENT_AUDITS_VIEWED
    ]
})
render(App)

if (module.hot) {
    module.hot.accept("./App", () => {
        const NextApp = require("./App").default
        render(NextApp)
    })
}
