import * as C from "./constants"

export const goToPatientFilters = filters => ({
    type: C.GO_TO_PATIENT_FILTERS,
    filters
})

export const setPatientFilter = filters => ({
    type: C.SET_PATIENT_FILTER,
    filters
})

export const exportPatientList = () => ({ type: C.EXPORT_PATIENT_LIST })
export const printPatientList = () => ({ type: C.PRINT_PATIENT_LIST })

export const hideGeneratePL = () => ({ type: C.HIDE_GENERATE_PL })
export const showGeneratePL = () => ({ type: C.SHOW_GENERATE_PL })
