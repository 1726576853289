import React, { Component } from "react"
import { connect } from "react-redux"
import { Field } from "redux-form"
import classNames from "classnames"
import { ValidatedDatePicker } from "../../../../core/DatePicker"
import {
    after1900,
    todayOrBefore,
    validDate
} from "../../../../core/validators"
import { dateFormat, isBlankDate } from "../../../../dates"
import { MeasureCompliance } from "../../../../indicators"
import { moduleEnabledSelector } from "../../../../moduleUtils"
import { MODULE_IDS } from "../../../../constants"
import { getIdDateName, getMeasureIdName } from "../../../helpers"
import * as C from "../../../constants"
import MeasureName from "./MeasureName"
import MeasureInput from "./MeasureInput"
import History from "./History"
import ChartPopover from "./ChartPopover"
import AutoCalcDate from "./AutoCalculated/date"

export class Measure extends Component {
    onChangeDate = (event, date) => {
        if (this.props.measure.dataType === C.CHECKBOX) {
            this.props.actions.change(
                getMeasureIdName(this.props.measure),
                !!date
            )
        }
    }

    render() {
        const {
            measure,
            style,
            disabled,
            savingMeasures,
            incentiveModuleActive
        } = this.props
        const loading =
            measure && savingMeasures && savingMeasures.has(measure.id)
        const hasHistory = !isBlankDate(measure.serviceDt)

        return (
            <tr style={style} className="measure_row_tr">
                <td className="measures-name">
                    <MeasureName
                        {...measure}
                        loading={loading}
                        incentiveModuleActive={incentiveModuleActive}
                    />
                </td>
                <td className="measures-compliance">
                    <div className="measure-text form-control-static">
                        <MeasureCompliance
                            cellData={measure.compliant}
                            loading={loading}
                        />
                    </div>
                </td>
                <td className="measures-value-last">
                    <div
                        className={classNames(
                            "measure-text form-control-static",
                            { disabled: loading }
                        )}
                    >
                        {measure.hasRecentValue ? (
                            measure.displayValue
                        ) : (
                            <span />
                        )}
                    </div>
                </td>
                <td className="measures-date-last">
                    <div
                        className={classNames(
                            "measure-text form-control-static",
                            { disabled: loading }
                        )}
                    >
                        <span>
                            {measure.hasRecentValue &&
                                dateFormat(measure.serviceDt)}
                        </span>
                    </div>
                </td>
                {disabled || (
                    <React.Fragment>
                        <td className="measures-value-new">
                            <MeasureInput {...measure} disabled={loading} />
                        </td>
                        <td className="measures-date-new">
                            {measure.autoCalc &&
                            measure.dataType !== C.COMPOSITE ? (
                                <Field
                                    name={getIdDateName(measure.id)}
                                    component={AutoCalcDate}
                                    measure={measure}
                                    disabled={loading}
                                />
                            ) : (
                                <Field
                                    className="form-group"
                                    name={getIdDateName(measure.id)}
                                    component={ValidatedDatePicker}
                                    validate={[
                                        validDate,
                                        after1900,
                                        todayOrBefore
                                    ]}
                                    onChange={this.onChangeDate}
                                    notClearable
                                    noHighlighting
                                    validDates={todayOrBefore}
                                    disabled={loading}
                                                                    />
                            )}
                        </td>
                    </React.Fragment>
                )}
                <td className="measures-history popup-history">
                    <History {...measure} hasHistory={hasHistory} />
                    <ChartPopover
                        {...measure}
                        hasHistory={hasHistory}
                        measureId={measure.id}
                        offerGraph={measure.offerGraph}
                    />
                </td>
            </tr>
        )
    }
}

const incentiveModuleActive = moduleEnabledSelector(
    MODULE_IDS.INCENTIVE_TRACKING
)

export default connect(state => ({
    ...state[C.MEASURES],
    incentiveModuleActive: incentiveModuleActive(state)
}))(Measure)
