import React from "react"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { Field } from "redux-form"
import { SelectField, TextInput } from "../../../core/CustomFields"
import patient_list from "../../../patient_list"
import * as C from "../../constants"
import PermissionPanel from "./Permissions"
import ModifyStaticPatientList from "./ModifyStaticPatientList"
import { TableSettingsField } from "../../../table_settings/components/TableSettingsField"

const required = value => (!value || value === "" ? "Required" : undefined)

export const CampaignSettings = ({
    formValues,
    options,
    owner,
    superUser,
    users
}) => (
    <Tabs defaultActiveKey={1}>
        <Tab eventKey={1} title="General">
            <div className="filters">
                <Row>
                    <Col md={6} xl={4}>
                        <Field
                            name={C.TABLE_KEYS.CAMPAIGN_NAME}
                            component={TextInput}
                            validate={required}
                            required
                            noHighlighting
                            notClearable
                        />
                    </Col>
                    <Col md={6} xl={4}>
                        <Field
                            name={C.TABLE_KEYS.CAMPAIGN_DESCRIPTION}
                            component={TextInput}
                            noHighlighting
                            notClearable
                        />
                    </Col>
                    <Col md={6} xl={4}>
                        <Field
                            name={C.TABLE_KEYS.CAMPAIGN_TYPE}
                            component={SelectField}
                            noHighlighting
                            notClearable
                            options={[
                                {
                                    label: "Patient Summary",
                                    value: C.CAMPAIGN_TYPE.INFO
                                },
                                {
                                    label: "Patient Measures",
                                    value: C.CAMPAIGN_TYPE.MEASURES
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </Tab>
        <Tab eventKey={2} title="Filters">
            <div className="filters">
                <patient_list.components.FilterPanel
                    options={options}
                    formValues={formValues?.filter}
                    fixedScope="A campaign's scope can only be My Panel(s)."
                />
            </div>
        </Tab>
        <Tab eventKey={3} title="Columns">
            <div className="filters">
                <Field
                    name={C.TABLE_KEYS.COLUMNS}
                    component={TableSettingsField}
                    columnDefinitions={
                        patient_list.columns.COLUMNS_BY_TYPE[
                            formValues?.[C.TABLE_KEYS.CAMPAIGN_TYPE]
                        ]
                    }
                />
            </div>
        </Tab>
        <Tab eventKey={4} title="Privileges">
            <div className="campaign-permissions">
                <PermissionPanel
                    {...options}
                    users={users}
                    owner={owner}
                    superUser={superUser}
                    formValues={formValues}
                    pinningOptions={options.pinningOptions}
                />
            </div>
        </Tab>
        <Tab eventKey={5} title="Add Patients Manually">
            <div className="filters" style={{ minHeight: "300px" }}>
                <Field
                    name={C.TABLE_KEYS.EXTRA_PATIENTS}
                    component={ModifyStaticPatientList}
                    // no options to specify because the actual input is a PatientSelect, which fetches its own options
                />
            </div>
        </Tab>
    </Tabs>
)
