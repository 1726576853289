import React, { PureComponent } from "react"
import {
    AutoSizer,
    defaultTableCellDataGetter,
    defaultTableCellRenderer,
    InfiniteLoader,
    MultiGrid,
    SortDirection
} from "react-virtualized"
import "react-virtualized/styles.css"
import classNames from "classnames"
import memoizeOne from "memoize-one"
import _ from "lodash"

import * as CC from "../../../configuration"
import { ALIGNMENTS, PATH } from "../../constants"
import { reorderSortColumns } from "../../selectorUtils"
import { EMPTY_LIST, asArray, isNilOrBlank } from "../../utils"
import { ErrorPanel, NoResults } from "../../core/LoadingErrorHOC"
import * as C from "../constants"
import { getPivotNameKeys, stampDepth } from "../helpers"
import HeaderRenderer from "./HeaderRenderer"
import GridCellRenderer from "./GridCellRenderer"
import "./RV.css"

// HELPER FUNCTIONS

const getAlignClassName = align => `column-${align || ALIGNMENTS.LEFT}`

// noinspection JSUnresolvedVariable
const flipSortDirection = curSortDirection =>
    curSortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC

const getExpandedColumns = (columns, expanded) =>
    columns.filter(column => {
        const pivotColumns = getPivotNameKeys(columns)
        const depth = stampDepth(expanded)
        const index = _.indexOf(pivotColumns, column.key)
        const isExpanded = -1 < index && index <= depth // if index is -1, this isn't a pivot column at all
        const atDeepestLevel = pivotColumns.length <= depth // if the stamp is longer than the number of pivot columns, then we're dealing with a non-pivot collapsed column

        return !column.canCollapse || atDeepestLevel || isExpanded
    })

// THE CLASS

// noinspection JSUnresolvedFunction,JSUnresolvedVariable
export default class RV extends PureComponent {
    state = {
        show_custom_scroll: false,
        columnWidthModifiers: {},
        // highlightRow: undefined,
        hasError: false
    }
    OVERSCAN = CC.INFINITE_SCROLL_OVERSCAN
    MINIMUM_BATCH_SIZE = CC.PAGINATION_REQUEST_SIZE
    THRESHOLD = CC.PAGINATION_FETCH_THRESHOLD

    getMaxLength = () => this.props.rowCount || this.props.list[0].numRecs

    // lifecycle functions

    componentDidUpdate() {
        this.handleResize() // if we don't trigger this on each update, the table gets sized incorrectly
    }

    componentDidCatch(error) {
        // don't actually need to do anything besides displaying the error
        // but TODO maybe do a little more than just saying "something went wrong"
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    setRef = registerChild => el => {
        this.tableRef = el
        registerChild(el)
    }

    // event handlers

    handleResize = () => this.tableRef?.recomputeGridSize()

    handleScrollbarPresenceChange = ({ size, ...scrollbar }) =>
        this.setState({ scrollbarSize: size, ...scrollbar })

    loadMoreRows = props => {
        if (this.getMaxLength() > this.props.list.length) {
            this.props._loadMoreRows?.(props, {
                ...this.props,
                ...this.normalizeSort()
            })
        }
    }

    normalizeSort = () => {
        const {
            sortBy: sortByBase,
            sortDirection: sortDirectionBase
        } = this.getSorts()
        if (_.isEmpty(sortByBase)) {
            return {}
        }

        // need to filter out function-based sortBys, because those can't be converted to strings cleanly
        const [sortBy, sortDirection] = _.unzip(
            _.zip(sortByBase, sortDirectionBase).filter(
                ([col]) => !_.isFunction(col)
            )
        )
        return { sortBy, sortDirection }
    }

    onSectionRendered = memoizeOne(
        onRowsRendered => ({ rowStartIndex, rowStopIndex }) =>
            onRowsRendered({
                startIndex: rowStartIndex,
                stopIndex: rowStopIndex
            })
    )

    // set sort order

    getSortDirection = dataKey => {
        const { sortBy, sortDirection } = this.getSorts()
        const sortIndex = sortBy.indexOf(dataKey)
        if (sortIndex === -1) {
            return undefined
        }

        return sortDirection[sortIndex]
    }

    newSortOrder = ({ sortBy, sortDirection }) => {
        this.props.updateTable(this.props.tableName, {
            sortBy,
            sortDirection
        })
        if (this.getMaxLength() > this.props.list.length) {
            this.props._loadInitialRows?.({
                sortBy,
                sortDirection
            })
        }
    }

    setSort = ({ event, dataKey }) => {
        const { sortBy, sortDirection } = this.getSorts()
        const { columns, frozenColumns } = this.getColumns()

        if (
            [...frozenColumns, ...columns].find(item => item.key === dataKey)
                ?.disableSort
        ) {
            return
        }

        const index = sortBy.indexOf(dataKey)

        if (!event.shiftKey) {
            // sort by this column only
            this.newSortOrder({
                sortBy: [dataKey],
                sortDirection: [flipSortDirection(sortDirection[index])]
            })
            return
        }

        if (index === -1) {
            // not currently being sorted on, so add it to the list
            this.newSortOrder({
                sortBy: [...sortBy, dataKey],
                sortDirection: [...sortDirection, SortDirection.ASC]
            })
        } else {
            // currently being sorted on, so update its direction in the list
            const newSortDir = [...sortDirection]
            newSortDir[index] = flipSortDirection(newSortDir[index])

            this.newSortOrder({
                sortBy,
                sortDirection: newSortDir
            })
        }
        document.getSelection().removeAllRanges()
    }

    // actually sort the rows

    getSortedListMemo = memoizeOne((list, sortBy, sortDirection) =>
        this.props.tiered
            ? this.tieredSort(
                  list,
                  this.makeTieredOrderBys(sortBy, sortDirection)
              )
            : this.flatSort(list, this.makeFlatOrderBys(sortBy, sortDirection))
    )

    getSortedList = () => {
        const { sortBy, sortDirection } = this.getSorts()
        return this.getSortedListMemo(this.props.list, sortBy, sortDirection)
    }

    flatSort = (list, orderBys) =>
        _.map(list).sort((a, b) => {
            for (const { func, direction, column } of orderBys) {
                const aVal = func(a)
                const bVal = func(b)

                const aIsBigger = direction === SortDirection.ASC ? 1 : -1
                const bIsBigger = -aIsBigger
                const nullsAreHigh = C.NULLS_HIGH_COLUMNS.includes(column)
                const nullsAreLast = C.NULLS_LAST_COLUMNS.includes(column)

                // handle null and blank values. If we used _.orderBy, they'd get sorted as if they were larger than any non-null value; we want them to always be smaller instead. Any columns that *do* want to have nulls be larger should be added to NULLS_HIGH_COLUMNS.
                // if we want a column to *always* put nulls at the end, add it to C.NULLS_LAST_COLUMNS.
                if (isNilOrBlank(aVal) && isNilOrBlank(bVal)) {
                    return 0
                } else if (isNilOrBlank(aVal)) {
                    if (nullsAreLast) return 1
                    return nullsAreHigh ? aIsBigger : bIsBigger
                } else if (isNilOrBlank(bVal)) {
                    if (nullsAreLast) return -1
                    return nullsAreHigh ? bIsBigger : aIsBigger
                }

                // sort everything else
                if (aVal > bVal) {
                    return aIsBigger
                }
                if (bVal > aVal) {
                    return bIsBigger
                }
            }

            // if we get through everything and haven't returned yet, I guess they're the same
            return 0
        })

    tieredSort = (list, orderBys) => {
        const grouped = this.getGrouped(list)
        const pathed = this.getPathed(grouped)

        const totalRow = _.get(grouped, [0, 0], null) // _.get for if there's no top-level row
        return this.sortTier(pathed, orderBys)(totalRow)
    }

    getGrouped = memoizeOne(list =>
        _.groupBy(list, row => row[PATH]?.length ?? 0)
    )

    getPathed = memoizeOne(grouped =>
        _.mapValues(grouped, group =>
            _.reduce(
                group,
                (out, row) => _.setWith(out, row[PATH], row, Object),
                {}
            )
        )
    )

    sortTier = (groups, orderBys) => row => {
        const optionalRow = row ? [row] : []
        const path = row?.[PATH] || []
        return [
            ...optionalRow,
            ...this.flatSort(
                Object.values(_.get(groups, [path.length + 1, ...path], [])),
                orderBys
            ).flatMap(this.sortTier(groups, orderBys))
        ]
    }

    makeFlatOrderBys = (sortBy, sortDirection) => {
        if (_.isNil(sortBy)) {
            return []
        }
        return this.zipOrderBys(asArray(sortBy), asArray(sortDirection))
    }

    makeTieredOrderBys = (sortBy, sortDirection) => {
        if (_.isNil(sortBy)) {
            return []
        }

        const selectedColumns = this.getAllColumns()
        const sortColumns = reorderSortColumns(sortBy, selectedColumns)

        const sortMapping = _.fromPairs(_.zip(sortBy, sortDirection))
        const sortDirections = sortColumns.map(
            col => sortMapping[col] ?? SortDirection.ASC
        )

        return this.zipOrderBys(sortColumns, sortDirections)
    }

    zipOrderBys = (sortColumns, sortDirections) =>
        _.zip(sortColumns, sortDirections).map(([column, direction]) => ({
            column,
            direction,
            func: this.makeOrderBy(column)
        }))

    makeOrderBy = sortBy => {
        const selectedColumns = this.getAllColumns()
        const column = selectedColumns.find(col => col.key === sortBy) || {}

        return rowData => {
            const cellData = this.getCellData(column, rowData)
            return _.isString(cellData) ? cellData.toLowerCase() : cellData
        }
    }

    // table and column information
    // most of these don't strictly need memoization, but we do it for the sake of other memos down the line
    getSortsMemo = memoizeOne((sortBy, sortDirection) => ({
        sortBy: asArray(sortBy),
        sortDirection: asArray(
            sortDirection || _.map(sortBy, () => SortDirection.ASC)
        )
    }))
    getSorts = () =>
        this.getSortsMemo(this.props.sortBy, this.props.sortDirection)

    getColumnsMemo = memoizeOne(
        (
            columns = EMPTY_LIST,
            frozenColumns = EMPTY_LIST,
            tiered,
            expanded
        ) => {
            if (!tiered) {
                return {
                    columns,
                    frozenColumns
                }
            }

            const expandedColumns = getExpandedColumns(
                [...columns, ...frozenColumns],
                expanded
            )

            return {
                columns: _.intersection(columns, expandedColumns),
                frozenColumns: _.intersection(frozenColumns, expandedColumns)
            }
        }
    )

    getColumns = () =>
        this.getColumnsMemo(
            this.props.columns,
            this.props.frozenColumns,
            this.props.tiered,
            this.props.expanded
        )

    getAllColumns = () => {
        const { columns, frozenColumns } = this.getColumns()
        return [...frozenColumns, ...columns]
    }

    isFirstColumn = column =>
        _.values(this.getColumns())
            .map(colList => _.head(colList)?.key)
            .includes(column.key)

    isLastColumn = column =>
        _.values(this.getColumns())
            .map(colList => _.last(colList)?.key)
            .includes(column.key)

    getColumnData = _.memoize(column => ({ ...column.columnData, column })) // sometimes the renderer may need to access its own column definition. Needs to be _.memoize because there are a bunch of columns to track

    getTableData = memoizeOne(({ columns, frozenColumns, tableData }) => ({
        frozenColumns,
        columns,
        ...tableData
    }))

    getStaticData = column => ({
        columnData: this.getColumnData(column),
        tableData: this.getTableData(this.props)
    })

    getCellData = (column, rowData) => {
        const { key: dataKey, dataGetter = defaultTableCellDataGetter } = column
        return dataGetter({
            cellData: rowData[dataKey],
            dataKey,
            rowData,
            ...this.getStaticData(column)
        })
    }

    // measurement calculators

    getColumnBaseWidth = column => {
        const baseWidth =
            column.getWidth?.(this.getStaticData(column)) ??
            column.width ??
            C.COLUMN_WIDTH_DEFAULT
        const manualExtraWidth =
            this.state.columnWidthModifiers[column.key] || 0
        const firstColumnPadding = this.isFirstColumn(column)
            ? C.COL1_EXTRA_SPACE
            : 0
        return baseWidth + manualExtraWidth + firstColumnPadding
    }

    getColumnWidths = memoizeOne((selectedColumns, width) => {
        const baseColumnWidths = selectedColumns.map(this.getColumnBaseWidth)

        // table dimensions
        const baseTableWidth = _.sum(baseColumnWidths)
        const renderedWidth = baseTableWidth + this.getScrollbarWidth()
        const gap = Math.max(width - renderedWidth, 0)

        if (gap === 0) {
            // the columns already take up the table's full width or more
            return baseColumnWidths
        }

        // each column's flex value
        const columnFlexes = selectedColumns.map(col => {
            if (!_.isNil(this.state.columnWidthModifiers[col.key])) {
                // we've set the width manually; no need to flex
                return 0
            }
            const flexGrow = col.flexGrow ?? C.COLUMN_FLEX_GROW_DEFAULT
            return Math.max(flexGrow, 0) // can't have a flex-grow smaller than 0
        })
        const totalFlexGrow = _.sum(columnFlexes)

        if (totalFlexGrow === 0) {
            // none of the columns have any flex-grow
            return baseColumnWidths
        }

        // turn those flex values into concrete pixel widths
        const flexSpace = gap / totalFlexGrow // the number of extra pixels that will be assigned to something with flexGrow: 1
        const columnFlexWidths = columnFlexes.map(cf => _.floor(cf * flexSpace))

        // since we floored all the flex widths, we add any leftover pixels to the last of the flex columns
        const lastFlexIndex = _.findLastIndex(columnFlexes)
        const totalFlexPx = _.sum(columnFlexWidths)
        const extraFlexPx = gap - totalFlexPx
        columnFlexWidths[lastFlexIndex] =
            columnFlexWidths[lastFlexIndex] + extraFlexPx

        // return the sums
        return _.zipWith(baseColumnWidths, columnFlexWidths, (a, b) => a + b)
    })

    getGridRowHeight = ({ index }) =>
        index === 0 ? C.HEADER_HEIGHT : C.ROW_HEIGHT

    getScrollbarWidth = () =>
        (this.state.vertical && this.state.scrollbarSize) || 0
    getScrollbarHeight = () =>
        (this.state.horizontal && this.state.scrollbarSize) || 0

    // styles and class names

    rowClassName = index => {
        if (index < 0) {
            // header row, which has already been given a class via headerClassName
            return ""
        }

        const item = this.getSortedList()[index]

        return classNames({
            evenRow: index % 2 === 0,
            oddRow: index % 2 !== 0,
            // highlight: this.state.highlightRow === index,
            ..._.mapValues(this.props.rowClassNames, getter => !!getter(item))
        })
    }

    getGridStyle = () => ({
        height:
            C.HEADER_HEIGHT +
            C.ROW_HEIGHT * this.getSortedList().length +
            this.getScrollbarHeight(),
        minHeight: C.HEADER_HEIGHT + C.ROW_HEIGHT + this.getScrollbarHeight(),
        maxHeight: this.props.height, // TODO should probably change the name of the prop to maxHeight for clarity. And maybe rework it to be in number of rows instead of px? And it should be optional at all levels... or just put the height in props.style
        ...this.props.style
    })

    // renderers

    firstLastClassNames = column => ({
        first: this.isFirstColumn(column),
        last: this.isLastColumn(column)
    })

    renderCell = (list, columns) => ({
        columnIndex,
        rowIndex,
        parent,
        style,
        key
    }) => {
        const column = columns[columnIndex]
        const finalStyle = {
            ...style,
            ...column.style
        }
        const normalizedRowIndex = rowIndex - 1 // for some reason, in a Grid the header row is index 0 instead of -1. Rather than have row 1 correspond to element 0 of the array, we just normalize it here.

        const props = {
            key,
            style: finalStyle
        }
        return normalizedRowIndex === -1
            ? this.renderHeaderCell(column, props)
            : this.renderGridCell({
                  column,
                  columnIndex,
                  rowData: list[normalizedRowIndex],
                  rowIndex: normalizedRowIndex,
                  parent,
                  props
              })
    }

    renderHeaderCell = (column, props) => {
        const {
            align,
            disableSort,
            getHover,
            getLabel,
            headerClassName,
            hover,
            key: dataKey,
            label
        } = column
        const data = this.getStaticData(column)

        const offset = // either the manual extra width, or the flex width
            this.state.columnWidthModifiers[dataKey] ??
            props.style.width - this.getColumnBaseWidth(column)

        return (
            <HeaderRenderer
                {...props}
                onClick={event =>
                    disableSort || this.setSort({ event, dataKey })
                }
                className={classNames(
                    getAlignClassName(align),
                    headerClassName,
                    this.firstLastClassNames(column)
                )}
                title={getHover?.(data) ?? hover}
                label={getLabel?.(data) ?? label}
                disableSort={disableSort}
                sortDirection={this.getSortDirection(dataKey)}
                offset={offset}
                onResize={delta => {
                    this.setState({
                        columnWidthModifiers: {
                            ...this.state.columnWidthModifiers,
                            [dataKey]: delta
                        }
                    })
                    this.handleResize()
                }}
            />
        )
    }

    /* Grid cell renders like this:
     * Start with original value
     * -> dataGetter, which is used for sorting
     * -> cellFormatter, which turns the value into a string. Doesn't use rowData or columnData
     * -> cellRenderer, which turns the formatted value into a Component
     */
    renderGridCell = ({
        column,
        columnIndex,
        rowData,
        rowIndex,
        parent,
        props
    }) => {
        const {
            align,
            cellRenderer,
            cellFormatter = _.identity,
            className,
            disableSort,
            key: dataKey,
            noWrapper
        } = column
        const CellRenderer =
            cellRenderer || this.props.cellRenderer || defaultTableCellRenderer

        const columnData = this.getColumnData(column)
        const cellData = cellFormatter(this.getCellData(column, rowData))

        const cellRendererProps = {
            cellData,
            columnData,
            columnIndex,
            dataKey,
            rowData,
            rowIndex,
            parent,
            tableName: this.props.tableName,
            tableData: this.getTableData(this.props)
        }

        const {
            className: rowRendererClassName,
            onClick,
            style: rowStyle,
            ...rowProps
        } = this.props.rowRenderer?.({ rowData }) || {}
        const style = { ...props.style, ...rowStyle }

        return (
            <GridCellRenderer
                {...props}
                {...rowProps}
                sorts={this.getSorts()} // passing this in so the memoized component will update when sorting changes
                rowData={rowData} // ditto
                columns={this.getColumns()} // ditto
                noWrapper={noWrapper}
                style={style}
                onClick={event =>
                    onClick?.({
                        event,
                        index: rowIndex,
                        rowData
                    })
                } // if you want to support more rowRenderer events, add them here just like this one
                className={classNames(
                    rowRendererClassName,
                    className,
                    getAlignClassName(align),
                    this.rowClassName(rowIndex),
                    this.firstLastClassNames(column),
                    disableSort && "no-sort"
                )}
            >
                <CellRenderer {...cellRendererProps} />
            </GridCellRenderer>
        )
    }

    renderGrid = (registerChild, onRowsRendered, width, height) => {
        const sortedList = this.getSortedList()
        const { frozenColumns } = this.getColumns()
        const selectedColumns = this.getAllColumns()
        const columnWidths = this.getColumnWidths(selectedColumns, width)
        const estimatedColumnSize = _.floor(_.mean(columnWidths))

        // noinspection RequiredAttributes
        return (
            <MultiGrid
                ref={this.setRef(registerChild)}
                onSectionRendered={this.onSectionRendered(onRowsRendered)}
                onScrollbarPresenceChange={this.handleScrollbarPresenceChange}
                classNameTopLeftGrid="grid-header grid-frozen"
                classNameTopRightGrid="grid-header"
                classNameBottomLeftGrid="grid-body grid-frozen"
                classNameBottomRightGrid="grid-body"
                height={height}
                width={width}
                enableFixedColumnScroll
                enableFixedRowScroll
                columnCount={selectedColumns.length}
                rowCount={sortedList.length + 1} // +1 for header
                fixedColumnCount={frozenColumns.length}
                fixedRowCount={1} // just the header
                columnWidth={({ index }) => columnWidths[index]}
                estimatedColumnSize={estimatedColumnSize}
                rowHeight={this.getGridRowHeight}
                cellRenderer={this.renderCell(sortedList, selectedColumns)}
                overscanRowCount={this.OVERSCAN}
            />
        )
    }

    render() {
        const sortedList = this.getSortedList()

        return this.state.hasError ? (
            <ErrorPanel message="Something went wrong" />
        ) : _.isEmpty(this.getAllColumns()) ? (
            <NoResults message="No columns selected" />
        ) : _.isEmpty(sortedList) ? (
            <NoResults message={this.props.emptyMessage} />
        ) : (
            <InfiniteLoader
                isRowLoaded={({ index }) => !!sortedList[index]}
                loadMoreRows={this.loadMoreRows}
                rowCount={this.props.rowCount || sortedList[0]?.numRecs}
                minimumBatchSize={this.MINIMUM_BATCH_SIZE}
                threshold={this.THRESHOLD}
            >
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer
                        onResize={this.handleResize}
                        className={classNames(
                            this.props.className,
                            "grid-wrapper",
                            {
                                "tiered-table": this.props.tiered
                            }
                        )}
                        style={this.getGridStyle()}
                    >
                        {({ width, height }) =>
                            this.renderGrid(
                                registerChild,
                                onRowsRendered,
                                width,
                                height
                            )
                        }
                    </AutoSizer>
                )}
            </InfiniteLoader>
        )
    }
}
