import React from "react"
import { call, put } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"
import _ from "lodash"
import { fetchAndCheck } from "./api"
import { joinTruthy } from "./utils"
import { FetchError } from "./core/FetchError"

export const UID_SAVING = "data-saving"
export const UID_ERROR = "data-error"

const PENDING_MESSAGE = "Saving..."
export const SUCCESSFUL_SAVE_MESSAGE = "Your changes were saved successfully!"
export const GENERIC_ERROR_MESSAGE = "Failed to save your changes."

const BASE_NOTIFICATION = {
    position: "tc",
    dismissible: false,
    autoDismiss: 5
}
export const ERROR_NOTIFICATION = {
    position: "tc",
    dismissible: "both",
    autoDismiss: 0,
    title: GENERIC_ERROR_MESSAGE
}
export const SUCCESS_NOTIFICATION = {
    ...BASE_NOTIFICATION,
    dismissible: "both",
    message: SUCCESSFUL_SAVE_MESSAGE
}

/** Sends a request to the API, and posts notifications about the results.
 *
 * @param message an optional message to show while saving. If explicitly null, doesn't show a saving notification at all.
 * @param request the request to send
 * @param success a string, a React node, or an object to put in a notification on success, *or* a function returning any of those. Can be a generator function. If explicitly null, don't display a notification at all. (Which you might do if you want to display a custom notification instead of a standard one)
 * @param failure Same but on failure.
 * @param options Additional options to pass to fetchAndCheck (see api.fetchAndCheck for details)
 */
export function* tryWithNotifications(
    { message, ...request },
    success,
    failure,
    options = {}
) {
    const errorUid = UID_ERROR + "_" + request.url

    try {
        if (message !== null) {
            // null message means "don't show any saving notification"
            yield put(Notifications.hide(UID_SAVING))
            yield put(Notifications.hide(errorUid))
            yield put(Notifications.info(savingNotification({ message })))
        }

        const body = yield* fetchAndCheck(request, options)
        const result = yield* getResult(success, body, "message")
        if (result === null) {
            return
        }

        yield put(
            Notifications.success({
                ...SUCCESS_NOTIFICATION,
                ...result
            })
        )
    } catch (error) {
        if (error instanceof FetchError) {
            console.error(error.toString())

            const result = yield* getResult(failure, error, "title")
            if (result === null) {
                return
            }
            yield call(notifyError({ ...result, errorUid }), error)
        } else {
            console.error(error) // TODO put a generic error notification, so there's *some* reaction when something goes wrong
        }
    } finally {
        yield put(Notifications.hide(UID_SAVING))
    }
}

function* getResult(handler, arg, fieldIfString) {
    const result = _.isFunction(handler) ? yield* handler(arg) : handler

    if (_.isString(result) || React.isValidElement(result)) {
        return yield {
            [fieldIfString]: result
        }
    } else if (_.isObject(result)) {
        return yield result
    } else if (_.isNull(result)) {
        return yield null
    }
    return yield {} // just use the default notification for the situation
}

export const savingNotification = ({ message, ...options } = {}) => ({
    ...BASE_NOTIFICATION,
    uid: UID_SAVING,
    autoDismiss: 0,
    // we have to style it like this instead of passing in some 'info' styles in structure.constants.NOTIFICATION_CONFIG, because we use the 'info' level for both the saving notification and the generic alerts we pull from the database, but they're styled very differently. And for some reason you can't pass styles or class names in the notifications proper.
    children: (
        <div
            style={{
                borderTop: "2px solid #369cc7",
                backgroundColor: "#e8f0f4",
                color: "#41555d",
                padding: 10,
                textAlign: "left"
            }}
        >
            {message || PENDING_MESSAGE}
        </div>
    ),
    ...options
})

export const errorNotification = (options, error) => {
    const messages = _.isEmpty(error.subErrors)
        ? { message: error.message }
        : {
              message: null, // the message is included in the children, because we want it to be <strong>. If we could style it more easily that wouldn't be an issue. Though XXX I am a little tempted to just not worry about making it bold
              children: (
                  <div>
                      <strong>{error.message}</strong>:
                      <ul style={{ marginBottom: 0 }}>
                          {error.subErrors.map((error, key) => (
                              <li key={key}>
                                  {joinTruthy([error.field, error.message])}
                              </li>
                              //FIXME showing error.field isn't ideal, because it's the internal field name instead of the user-facing label
                          ))}
                      </ul>
                  </div>
              )
          }

    return {
        ...ERROR_NOTIFICATION,
        ...messages,
        ...options
    }
}

// These are for use in tryFetch or other places that don't innately dispatch notifications

export const notifyError = options =>
    function*(error) {
        const notification = errorNotification(options, error)
        yield put(Notifications.error(notification))
    }

export const notifySimpleError = (title, options) =>
    notifyError({ title, ...options })
