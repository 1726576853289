import * as C from "./constants"

export const setCmMeasure = measureId => ({
    type: C.SET_CM_MEASURE,
    measureId
})
export const setCmPatientFilters = filters => ({
    type: C.SET_CM_PATIENT_FILTERS,
    filters
})

export const exportCmPatientList = () => ({ type: C.EXPORT_CM_PATIENT_LIST })
export const printCmPatientList = () => ({ type: C.PRINT_CM_PATIENT_LIST })
