export { TABLE_DESCRIPTIONS } from "../table/constants"
export const NAME = "TABLE_SETTINGS"

export const PERSIST_SETTINGS = "PERSIST_SETTINGS"
export const FETCH_USER_PREFERENCES = "FETCH_USER_PREFERENCES"
export const FETCH_USER_PREFERENCES_SUCCESS = "FETCH_USER_PREFERENCES_SUCCESS"
export const REMOVE_USER_PREFERENCES = "REMOVE_USER_PREFERENCES"
export const ADD_FAVORITE = "ADD_FAVORITE"
export const UPDATE_FAVORITE = "UPDATE_FAVORITE"
export const REMOVE_FAVORITE = "REMOVE_FAVORITE"
export const UNREMOVE_FAVORITE = "UNREMOVE_FAVORITE"
export const SET_FAVORITE_LOADING = "SET_FAVORITE_LOADING"

export const initialState = {
    loading: true
}
