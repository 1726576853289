import React from "react"
import { COMPLIANCE } from "../../constants"
import spinner from "../../../assets/images/loading.gif"
import { Hoverlay } from "../../core/Hoverlay"

// TODO could probably unify this with YesNoIndicator somehow
// TODO get colors from CSS instead of hardcoding

export const COMPLIANCE_ATTRIBUTES = {
    [COMPLIANCE.COMPLIANT]: {
        className: "fa indicator-yes",
        color: "#00a651",
        title: "Compliant"
    },
    [COMPLIANCE.NOT_COMPLIANT]: {
        className: "fa indicator-no",
        color: "#cc0033",
        title: "Not Compliant"
    },
    [COMPLIANCE.PENDING]: {
        className: "fa indicator-pending",
        color: "#edb100",
        title: "Compliance Pending"
    }
}

const ComplianceIndicator = ({ className, color, title }) => (
    <Hoverlay tooltip={title} tooltipColor={color}>
        <span className={className} />
    </Hoverlay>
)

const MeasureCompliance = ({ cellData, loading }) =>
    loading ? (
        <img src={spinner} alt="Saving..." />
    ) : (
        <ComplianceIndicator {...COMPLIANCE_ATTRIBUTES[cellData]} />
    )
export default MeasureCompliance
