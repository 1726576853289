import React, { Component } from "react"

import { mapValuesToObject } from "../../utils"
import * as C from "../constants"
import COLUMNS from "../columns"

import Header from "./Header"
import UtilizationDetailsTable from "./UtilizationDetailsTable"
import Title from "./Title"
import { tableFactory } from "../../table"
import { Card } from "react-bootstrap"

export { Title }

// TODO allow selection of tabs via url ID hash

const getTable = tab =>
    tableFactory({
        name: tab,
        columns: COLUMNS[tab],
        ...C.DEFAULT_ORDER_BYS[tab]
    })

export default class UtilizationDetailsContainer extends Component {
    state = { tab: C.LABS }
    tables = mapValuesToObject(C.KEYS, getTable)

    setTab = tab => {
        this.setState({ tab })
    }

    render() {
        const { Table, Settings, Description } = this.tables[this.state.tab]
        return (
            <>
                <Title
                    Settings={Settings}
                    tab={this.state.tab}
                    setTab={this.setTab}
                />
                <Card className="widget card-table utilization-details">
                    <Header tab={this.state.tab} />
                    <Description />
                    <UtilizationDetailsTable
                        suffix={C.ENDPOINT_SUFFIXES[this.state.tab]}
                        Table={Table}
                    />
                </Card>
            </>
        )
    }
}
