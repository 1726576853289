import React from "react"
import { Field, reduxForm } from "redux-form"
import { validFuzzySearch } from "../../core/validators"
import { CheckboxField } from "../../core/CustomFields"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"
import { SearchScope } from "./SearchScope"
import { NavSearchBar } from "./NavSearchBar"

const NavSearchOverlay = ({ allowGlobal, fuzzySearchFlags }) => (
    <>
        {allowGlobal && (
            <Field name={C.SEARCH_FIELDS.SCOPE} component={SearchScope} />
        )}

        <Hoverlay
            tooltip="Search by patient name is always permitted"
            forceWrapper
            wrapperClassName="form-group nav-search-flag-name"
        >
            <CheckboxField
                input={{ value: true }}
                label="Search patient name?"
                labelRight
                disabled
            />
        </Hoverlay>

        {fuzzySearchFlags[C.SEARCH_FIELDS.SEARCH_PATIENT_ID] && (
            <Field
                name={C.SEARCH_FIELDS.SEARCH_PATIENT_ID}
                component={CheckboxField}
                label="Search patient ID?"
                labelRight
            />
        )}

        {fuzzySearchFlags[C.SEARCH_FIELDS.SEARCH_CONTRACT_NUMBER] && (
            <Field
                name={C.SEARCH_FIELDS.SEARCH_CONTRACT_NUMBER}
                component={CheckboxField}
                label="Search contract number?"
                labelRight
            />
        )}
    </>
)

export const NavSearch = ({ handleSubmit, ...props }) => (
    <Hoverlay
        className="nav-search-overlay"
        placement="bottom-end"
        trigger="click"
        rootClose
        isPopover
        tooltip={<NavSearchOverlay {...props} />}
    >
        <form className="navbar-form" onSubmit={handleSubmit}>
            <Field
                name={C.SEARCH_FIELDS.SEARCH}
                component={NavSearchBar}
                validate={validFuzzySearch}
            />
        </form>
    </Hoverlay>
)

export default reduxForm({
    form: C.NAV_SEARCH_FORM,
    enableReinitialize: true
})(NavSearch)
