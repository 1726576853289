import { columnBuilder } from "../table"
import * as C from "./constants"

const DATE_WIDTH = 110

const specialistColumn = columnBuilder(C.SPECIALIST_TRANSLATION)
const careManagerColumn = columnBuilder(C.CARE_MANAGER_TRANSLATION)

export const SPECIALIST_TABLE_COLUMNS = [
    specialistColumn(C.KEYS.PROVIDER_NAME, {
        flexGrow: 1
    }),
    specialistColumn(C.KEYS.SPECIALTY, {
        flexGrow: 1
    }),
    specialistColumn(C.KEYS.OFFICE, {
        flexGrow: 1
    }),
    specialistColumn(C.KEYS.RECENT_SERVICE_DATE, { width: DATE_WIDTH })
]

export const CARE_MANAGER_TABLE_COLUMNS = [
    careManagerColumn(C.KEYS.PROVIDER_NAME, {
        flexGrow: 1
    }),
    careManagerColumn(C.KEYS.EFF_DT, { width: DATE_WIDTH }),
    careManagerColumn(C.KEYS.TERM_DT, { width: DATE_WIDTH }),
    careManagerColumn(C.KEYS.REASONS, {
        flexGrow: 1
    }),
    careManagerColumn(C.KEYS.EDIT, {
        width: 40,
        disableSort: true
    })
]
