import React from "react"
import { connect } from "react-redux"
import { modifyResource } from "../../../core/fetcher"
import * as reduxActions from "../../actions"

export const _RemoveFromCampaign = ({
    cellData,
    removeCampaignPatient,
    canEdit,
    poc_id,
    campaignId
}) =>
    canEdit && cellData ? (
        <button
            className="btn btn-default btn-sm"
            onClick={() =>
                removeCampaignPatient({ campaignId, patientId: poc_id })
            }
        >
            Remove
        </button>
    ) : (
        <span style={{ color: "#999" }}>Cannot remove</span>
    )
export const RemoveFromCampaign = connect(
    (state, { rowData }) => ({ ...rowData, poc_id: Number(state.poc_id) }),
    {
        ...reduxActions,
        modifyResource
    }
)(_RemoveFromCampaign)
