import React from "react"
import { connect } from "react-redux"
import { getResourceData } from "../../core/fetcher"
import { GuideLink } from "../../indicators"
import * as C from "../constants"
import TabChooser from "./TabChooser"

const Title = ({ Settings, info, tab, setTab }) => (
    <div className="page-header utilization-details-header">
        <h1>
            Utilization Details for: <span>{info?.name || "Loading..."}</span>
            <GuideLink page="view-patient-lab-pharmacy-claims" />
        </h1>
        <TabChooser tab={tab} setTab={setTab} />
        <Settings className="utilization-details-settings" />
    </div>
)

export default connect(state => ({
    info: getResourceData(C.NAME)(state)
}))(Title)
