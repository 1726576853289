import React from "react"

import IncentivizedProduct from "./IncentivizedProduct"
import { Hoverlay } from "../../core/Hoverlay"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import * as C from "../constants"

const IncentivizedProductFetcher = fetcher({
    name: C.NAME,
    endpoint: (state, props) => `/api/patients/${props.id}/incentivized`,
    method: ApiTypes.GET,
    persistResource: true
})(IncentivizedProduct)

export const IncentivizedProductTrigger = ({ cellData, rowData }) =>
    cellData ? (
        <Hoverlay
            isPopover
            rootClose
            className="cmi-popover"
            placement="top"
            trigger="click"
            tooltip={<IncentivizedProductFetcher id={rowData[C.PATIENT_ID]} />}
        >
            <span className="button-as-link">Yes</span>
        </Hoverlay>
    ) : (
        "No"
    )

export default IncentivizedProductTrigger
