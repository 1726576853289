import React from "react"
import { SortDirection } from "react-virtualized"
import { Card } from "react-bootstrap"
import _ from "lodash"
import CardTitle from "../../../core/CardTitle"
import LEHOC from "../../../core/LoadingErrorHOC"
import { tableFactory } from "../../../table"
import * as C from "../../constants"
import { PATIENT_CAMPAIGN_TABLE_COLUMNS } from "../../columns"

const CampaignTable = tableFactory({
    name: C.PATIENT_CAMPAIGNS_NAME,
    columns: PATIENT_CAMPAIGN_TABLE_COLUMNS,
    sortBy: [C.TABLE_KEYS.CAMPAIGN_NAME],
    sortDirection: [SortDirection.ASC]
}).Table

export const PatientCampaigns = ({ data, error, loading }) => {
    const campaigns = _.filter(
        data?.campaigns,
        item => item[C.TABLE_KEYS.MEMBERSHIP]
    )
    return (
        <Card id="comments" className="card-table">
            <Card.Header>
                <CardTitle>
                    Patient's Campaign Membership{" "}
                    {loading
                        ? " - Loading..."
                        : _.isEmpty(campaigns) && " - No Data"}
                </CardTitle>
            </Card.Header>
            <Card.Body style={_.isEmpty(campaigns) ? { display: "none" } : {}}>
                <LEHOC loading={loading} error={error} list={campaigns}>
                    <CampaignTable list={campaigns} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}
