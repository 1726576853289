import { all, call, select, takeEvery } from "redux-saga/effects"
import moment from "moment"
import { ApiTypes, downloadFile } from "../api"
import { TIMESTAMP_STRING_COMPACT } from "../dates"
import { getSortedColumns } from "../selectorUtils"
import { urlWithParams } from "../utils"
import { activeReportSelector, filtersSelector } from "./selectors"
import { getTableName } from "./helpers"
import * as C from "./constants"

function* downloadReport({ reportId }, reportType) {
    const currentReport = yield select(activeReportSelector(reportId)) || {}
    const reportName = currentReport.reportName || ""

    const sortParams = yield select(getSortedColumns(getTableName(reportId)))
    const body = yield select(filtersSelector(reportId))

    const endpoint = urlWithParams(
        `/api/reports/${reportType.toLowerCase()}/${reportId}`,
        sortParams
    )
    const filename = `report_library_${moment().format(
        TIMESTAMP_STRING_COMPACT
    )}.${reportType.toLowerCase()}`

    yield* downloadFile(
        `${reportName} ${reportType.toUpperCase()}`,
        endpoint,
        filename,
        { request: { method: ApiTypes.POST, body } }
    )
}

export function* exportLibrary(action) {
    yield downloadReport(action, "csv")
}

export function* printLibrary(action) {
    yield downloadReport(action, "pdf")
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.EXPORT_LIBRARY, exportLibrary)
        }),
        call(function*() {
            yield takeEvery(C.PRINT_LIBRARY, printLibrary)
        })
    ])
}
