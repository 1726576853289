import * as C from "./constants"

export const updateTCM = payload => ({ type: C.UPDATE_TCM, payload })
export const exportTCM = () => ({ type: C.EXPORT_TCM })
export const removeTCM = transitionId => ({ type: C.REMOVE_TCM, transitionId })
export const restoreTCM = transitionId => ({
    type: C.RESTORE_TCM,
    transitionId
})
export const updateError = payload => ({ type: C.TCM_UPDATE_ERROR, payload })
export const clearError = payload => ({ type: C.TCM_CLEAR_ERROR, payload })
export const setFilters = payload => ({ type: C.SAVE_TCM_FILTERS, payload })
export const setTcmStatus = (status, transitionId) => ({
    type: C.SET_TCM_STATUS,
    status,
    transitionId
})
export const downloadCdaXml = transitionId => ({
    type: C.DOWNLOAD_CDA_XML,
    transitionId
})
export const downloadCdaHtml = transitionId => ({
    type: C.DOWNLOAD_CDA_HTML,
    transitionId
})
