import React from "react"
import { Card, Col, Row } from "react-bootstrap"

export const AddPatientFieldset = ({ children, name, className, colCount }) => (
    <Card className={className} as="fieldset">
        <legend>{name}</legend>
        <Row>
            {children.map((child, i) => (
                <Col sm={6} lg={12 / colCount} key={i}>
                    {child}
                </Col>
            ))}
        </Row>
    </Card>
)
