import React from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { matchNavItem } from "../helpers"

const NavLink = ({
    href,
    label,
    location,
    linkProps,
    matchOn,
    onClick,
    className,
    isExternal
}) => (
    <li
        className={classNames("nav-item", className, {
            active: matchNavItem(matchOn || href, location)
        })}
    >
        {href ? (
            <Link
                to={isExternal ? { pathname: href } : href}
                onClick={onClick}
                {...linkProps}
            >
                {label}
            </Link>
        ) : (
            <button onClick={onClick} {...linkProps}>
                {label}
            </button>
        )}
    </li>
)
export default NavLink
