import * as C from "./constants"

export const setFilters = filters => ({ type: C.SET_AWV_FILTERS, filters })
export const setCategories = categories => ({
    type: C.SET_AWV_CATEGORIES,
    categories
})
export const saveDate = payload => ({ type: C.SAVE_AWV_DATE, payload })
export const updateError = payload => ({ type: C.UPDATE_AWV_ERROR, payload })
export const clearError = payload => ({ type: C.CLEAR_AWV_ERROR, payload })
export const exportCsv = () => ({ type: C.EXPORT_AWV_CSV })
