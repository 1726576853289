import { columnBuilder } from "../table"
import * as PLC from "../patient_list/constants"
import * as QC from "../qsr/constants"
import * as C from "./constants"

import { DollarsCents, LongNumber, Percent } from "../core/CellRenderers"
import { formatLongNumber } from "../numbers"
import { MODULE_IDS } from "../constants"
import {
    PatientListLinkCell,
    MeasureNameLinkCell,
    MoneyBreakdown,
    RateBreakdown
} from "./cell_renderers"

const PRACTICE_NAME_NO_LINK = `${QC.TABLE_KEYS.PRACTICE_NAME}NoLink`

// columns

const column = (key, opts) =>
    columnBuilder(C.TABLE_TRANSLATION)(key, {
        headerClassName: opts.className,
        ...opts
    })

export const TABLE_COLUMNS = {
    [QC.TABLE_KEYS.MEASURE_NAME]: column(QC.TABLE_KEYS.MEASURE_NAME, {
        cellRenderer: MeasureNameLinkCell,
        className: "dashboard-measure-name",
        filterField: QC.FILTERS.MEASURES,
        idField: QC.TABLE_KEYS.MEASURE_ID,
        columnData: {
            overlay: true,
            store: C.NAME,
            extraFilters: {
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
            }
        },
        width: 250,
        flexGrow: 1
    }),
    [QC.TABLE_KEYS.PRACTICE_NAME]: column(QC.TABLE_KEYS.PRACTICE_NAME, {
        cellRenderer: PatientListLinkCell,
        filterField: QC.FILTERS.UNITS,
        idField: QC.TABLE_KEYS.PRACTICE_ID,
        columnData: {
            overlay: true,
            store: C.NAME,
            extraFilters: {
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
            }
        },
        width: 150,
        flexGrow: 1
    }),
    [QC.TABLE_KEYS.PROVIDER_NAME]: column(QC.TABLE_KEYS.PROVIDER_NAME, {
        cellRenderer: PatientListLinkCell,
        filterField: QC.FILTERS.PROVIDERS,
        idField: QC.TABLE_KEYS.PROVIDER_ID,
        columnData: {
            overlay: true,
            store: C.NAME,
            extraFilters: {
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
            }
        },
        width: 150,
        flexGrow: 1
    }),
    [PRACTICE_NAME_NO_LINK]: column(QC.TABLE_KEYS.PRACTICE_NAME, {
        width: 150,
        flexGrow: 1
    }),
    [QC.TABLE_KEYS.RATE_TO_DATE]: column(QC.TABLE_KEYS.RATE_TO_DATE, {
        hover: "Current Rate",
        cellRenderer: Percent,
        width: 75
    }),
    [QC.TABLE_KEYS.HVPA_RATE]: column(QC.TABLE_KEYS.HVPA_RATE, {
        hover: "Network Rate",
        cellRenderer: Percent,
        width: 75
    }),
    [QC.TABLE_KEYS.GOAL_RATE]: column(QC.TABLE_KEYS.GOAL_RATE, {
        hover: "Target Rate",
        cellRenderer: Percent,
        width: 75
    }),
    [QC.TABLE_KEYS.ELIGIBLE]: column(QC.TABLE_KEYS.ELIGIBLE, {
        hover: "Total Eligible",
        cellRenderer: LongNumber,
        width: 90
    }),
    [QC.TABLE_KEYS.MET]: column(QC.TABLE_KEYS.MET, {
        cellRenderer: LongNumber,
        width: 60
    }),
    [QC.TABLE_KEYS.UNMET]: column(QC.TABLE_KEYS.UNMET, {
        cellFormatter: formatLongNumber,
        cellRenderer: PatientListLinkCell,
        columnData: {
            store: C.NAME,
            extraFilters: {
                [PLC.FILTERS.COMPLIANT]: C.COMPLIANCE.NOT_COMPLIANT,
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
            }
        },
        width: 80
    }),
    [QC.TABLE_KEYS.PAYOUT_AVAILABLE]: column(QC.TABLE_KEYS.PAYOUT_AVAILABLE, {
        selected: false,
        cellRenderer: DollarsCents,
        width: 100,
        module: MODULE_IDS.INCENTIVE_TRACKING
    }),
    [QC.TABLE_KEYS.PAYOUT_EARNED]: column(QC.TABLE_KEYS.PAYOUT_EARNED, {
        selected: false,
        cellRenderer: DollarsCents,
        width: 100,
        module: MODULE_IDS.INCENTIVE_TRACKING
    }),
    [QC.TABLE_KEYS.PAYOUT_REMAINING]: column(QC.TABLE_KEYS.PAYOUT_REMAINING, {
        selected: false,
        cellRenderer: DollarsCents,
        width: 100,
        module: MODULE_IDS.INCENTIVE_TRACKING
    }),
    [QC.TABLE_KEYS.INCENTIVE_DIFFERENCE]: column(
        QC.TABLE_KEYS.INCENTIVE_DIFFERENCE,
        {
            width: 175,
            cellFormatter: formatLongNumber,
            cellRenderer: PatientListLinkCell,
            columnData: {
                store: C.NAME,
                extraFilters: {
                    [PLC.FILTERS.INCENTIVE_STATUS]: ["MX"],
                    [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
                }
            },
            selected: false,
            module: MODULE_IDS.INCENTIVE_TRACKING
        }
    ),
    [QC.TABLE_KEYS.RATE_BREAKDOWN]: column(QC.TABLE_KEYS.RATE_BREAKDOWN, {
        cellRenderer: RateBreakdown,
        columnData: {
            currentRateKey: QC.TABLE_KEYS.RATE_TO_DATE,
            goalRateKey: QC.TABLE_KEYS.GOAL_RATE,
            neededKey: QC.TABLE_KEYS.TARGET,
            metKey: QC.TABLE_KEYS.MET,
            eligibleKey: QC.TABLE_KEYS.ELIGIBLE
        },
        width: 235,
        flexGrow: 1,
        disableSort: true
    }),
    [QC.TABLE_KEYS.MONEY_BREAKDOWN]: column(QC.TABLE_KEYS.MONEY_BREAKDOWN, {
        cellRenderer: MoneyBreakdown,
        columnData: {
            availableKey: QC.TABLE_KEYS.PAYOUT_AVAILABLE,
            earnedKey: QC.TABLE_KEYS.PAYOUT_EARNED
        },
        width: 235,
        flexGrow: 1,
        disableSort: true,
        module: MODULE_IDS.INCENTIVE_TRACKING
    })
}

export const NETWORK_TABLE_COLUMNS = [
    TABLE_COLUMNS[QC.TABLE_KEYS.MEASURE_NAME],
    TABLE_COLUMNS[QC.TABLE_KEYS.RATE_TO_DATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.HVPA_RATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.GOAL_RATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.ELIGIBLE],
    TABLE_COLUMNS[QC.TABLE_KEYS.MET],
    TABLE_COLUMNS[QC.TABLE_KEYS.UNMET],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_AVAILABLE],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_EARNED],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_REMAINING],
    TABLE_COLUMNS[QC.TABLE_KEYS.INCENTIVE_DIFFERENCE],
    TABLE_COLUMNS[QC.TABLE_KEYS.RATE_BREAKDOWN],
    TABLE_COLUMNS[QC.TABLE_KEYS.MONEY_BREAKDOWN]
]
export const PRACTICE_TABLE_COLUMNS = [
    TABLE_COLUMNS[QC.TABLE_KEYS.PRACTICE_NAME],
    TABLE_COLUMNS[QC.TABLE_KEYS.RATE_TO_DATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.HVPA_RATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.GOAL_RATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.ELIGIBLE],
    TABLE_COLUMNS[QC.TABLE_KEYS.MET],
    TABLE_COLUMNS[QC.TABLE_KEYS.UNMET],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_AVAILABLE],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_EARNED],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_REMAINING],
    TABLE_COLUMNS[QC.TABLE_KEYS.INCENTIVE_DIFFERENCE],
    TABLE_COLUMNS[QC.TABLE_KEYS.RATE_BREAKDOWN],
    TABLE_COLUMNS[QC.TABLE_KEYS.MONEY_BREAKDOWN]
]
export const PROVIDER_TABLE_COLUMNS = [
    TABLE_COLUMNS[QC.TABLE_KEYS.PROVIDER_NAME],
    TABLE_COLUMNS[PRACTICE_NAME_NO_LINK],
    TABLE_COLUMNS[QC.TABLE_KEYS.RATE_TO_DATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.HVPA_RATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.GOAL_RATE],
    TABLE_COLUMNS[QC.TABLE_KEYS.ELIGIBLE],
    TABLE_COLUMNS[QC.TABLE_KEYS.MET],
    TABLE_COLUMNS[QC.TABLE_KEYS.UNMET],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_AVAILABLE],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_EARNED],
    TABLE_COLUMNS[QC.TABLE_KEYS.PAYOUT_REMAINING],
    TABLE_COLUMNS[QC.TABLE_KEYS.INCENTIVE_DIFFERENCE],
    TABLE_COLUMNS[QC.TABLE_KEYS.RATE_BREAKDOWN],
    TABLE_COLUMNS[QC.TABLE_KEYS.MONEY_BREAKDOWN]
]
