import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import { Button, Modal } from "react-bootstrap"
import { validatePasswords } from "../../admin/components/users/validateUser"
import { HiddenInput, PasswordField } from "../../core/CustomFields"
import * as MAC from "../../my_account/constants"
import * as actions from "../actions"
import * as C from "../constants"

export const ResetPasswordModal = ({
    show,
    logoutAction,
    handleSubmit,
    submitPasswordReset
}) => (
    <Modal backdrop="static" size="sm" show={show}>
        <form onSubmit={handleSubmit(submitPasswordReset)}>
            <Modal.Header>
                <Modal.Title>Password Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Your password has been reset by an administrator.</p>
                <p>Please change your password immediately.</p>

                <Field
                    name="username"
                    autoComplete="username"
                    component={HiddenInput}
                />
                <Field
                    name={MAC.USER_FIELDS.CURRENT_PASSWORD}
                    component={PasswordField}
                    autoComplete="current-password"
                />
                <Field
                    name={MAC.USER_FIELDS.NEW_PASSWORD}
                    component={PasswordField}
                />
                <Field
                    name={MAC.USER_FIELDS.CONFIRM_PASSWORD}
                    component={PasswordField}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="pull-left"
                    onClick={logoutAction}
                >
                    Log Out
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    className="btn-modal-save pull-right"
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </form>
    </Modal>
)

export default connect(
    state => ({
        show: state[C.NAME].passwordResetNeeded,
        initialValues: {
            username: state.authentication.loginId
        }
    }),
    actions
)(
    reduxForm({
        form: C.RESET_PASSWORD_FORM,
        validate: validatePasswords
    })(ResetPasswordModal)
)
