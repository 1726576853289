import React from "react"
import { Field } from "redux-form"
import { SelectField } from "../../core/CustomFields"
import * as C from "../constants"

export const OutreachFilters = ({ filterOptions: { units, regions } }) => (
    <div className="outreach-dashboard-filters">
        <Field
            name={C.FILTERS.PRACTICE}
            component={SelectField}
            options={units}
            multi
        />
        <Field
            name={C.FILTERS.PRACTICE_TAG}
            component={SelectField}
            options={regions}
            multi
        />
    </div>
)
