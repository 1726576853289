import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import fetcher from "../../../core/fetcher"
import { ApiTypes } from "../../../api"
import * as C from "../../constants"
import { PatientCampaigns } from "./PatientCampaigns"
import { OtherCampaigns } from "./OtherCampaigns"

export const PatientCampaignsCard = props => (
    <Card>
        <Card.Body as={Row} className="poc_campaigns_layout">
            <Col lg={6}>
                <PatientCampaigns {...props} />
            </Col>
            <Col lg={6}>
                <OtherCampaigns {...props} />
            </Col>
        </Card.Body>
    </Card>
)
export default fetcher({
    name: C.PATIENT_CAMPAIGNS_NAME,
    endpoint: state => `/api/patients/${state.poc_id}/campaigns`,
    method: ApiTypes.GET
})(PatientCampaignsCard)
