import React from "react"
import { Hoverlay } from "../../core/Hoverlay"
import { dateFormat } from "../../dates"
import * as C from "../constants"
import CareManagerButton from "./CareManagerButton"

const CellRenderer = ({ cellData, dataKey, rowData }) => {
    switch (dataKey) {
        case C.KEYS.RECENT_SERVICE_DATE:
        case C.KEYS.EFF_DT:
        case C.KEYS.TERM_DT:
            return <span>{cellData && dateFormat(cellData)}</span>
        case C.KEYS.EDIT:
            return (
                <CareManagerButton className="fa-edit" careManager={rowData} />
            )
        case C.KEYS.OFFICE:
        case C.KEYS.REASONS:
            return <Hoverlay>{cellData}</Hoverlay>
        case C.KEYS.PROVIDER_NAME:
            return (
                <Hoverlay
                    tooltip={[cellData, rowData.inactive && " (inactive)"]}
                >
                    {cellData}
                </Hoverlay>
            )
        default:
            return <span>{cellData}</span>
    }
}

export default CellRenderer
