import React from "react"
import { connect } from "react-redux"
import { SortDirection } from "react-virtualized"
import { SimpleModal } from "../../core/SimpleModal"
import { tableFactory } from "../../table"
import { canEditSelector, recordSelector } from "../selectors"
import { FILE_COLUMNS } from "../columns"
import { getTitle } from "../helpers"
import * as actions from "../actions"
import * as C from "../constants"
import { AuditFiles } from "./AuditFiles"

const { Table, Description } = tableFactory({
    name: C.FILES_NAME,
    columns: FILE_COLUMNS,
    sortBy: [C.TABLE_KEYS.CREATED_DATE],
    sortDirection: [SortDirection.ASC]
})

export const FilesModal = ({
    closeModal,
    filesModalOpen,
    record,
    updateFileDesc,
    uploadFiles,
    ...props
}) => (
    <SimpleModal
        className="audits-modal audit-files"
        title={getTitle("Patient Audit Files", record)}
        show={filesModalOpen}
        closeModal={closeModal}
    >
        <AuditFiles
            {...props}
            Table={Table}
            Description={Description}
            files={record[C.TABLE_KEYS.FILES]}
            updateDescription={updateFileDesc}
            uploadFiles={(filesToUpload, descText) =>
                uploadFiles(
                    record[C.TABLE_KEYS.RECORD_ID],
                    filesToUpload,
                    descText
                )
            }
            emptyMessage="There are no files, and you don't have permission to upload new ones."
        />
    </SimpleModal>
)

export default connect(
    state => ({
        ...state[C.NAME],
        record: recordSelector(state),
        canEdit: canEditSelector(state)
    }),
    actions
)(FilesModal)
