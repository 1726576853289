import * as C from "./constants"

export const loadPATCM = payload => ({ type: C.LOAD_PA_TCM, payload })
export const savePATCMEntries = (tcm, form) => ({
    type: C.SAVE_PA_TCM,
    payload: { tcm, form }
})

export const restorePA = payload => ({ type: C.RESTORE_PA, payload })
export const removePA = payload => ({ type: C.REMOVE_PA, payload })
