import moment from "moment"
import { SERVER_DATE_STRING } from "../dates"
import { createReducer } from "../utils"
import { REHYDRATE } from "../constants"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.LOGIN_REQUEST]: state => ({
        ...state,
        loading: true
    }),
    [C.LOGIN_ERROR]: state => ({
        ...state,
        loading: false
    }),
    [C.LOGIN_SUCCESS]: (state, { payload }) => ({
        ...state,
        ...payload,
        isLoggedIn: true,
        lastLogin: moment().format(SERVER_DATE_STRING),
        loading: false
    }),
    [C.DISPLAY_PASSWORD_RESET]: (state, action) => ({
        ...state,
        passwordResetNeeded: action.payload
    }),
    [C.UPDATE_PASSWORD_TOKEN]: (state, action) => ({
        ...state,
        ...action.payload
    }),
    [REHYDRATE]: (state, action) => ({
        ...action.payload.authentication,
        loading: false
    })
})

export default reducer
