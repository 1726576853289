import React from "react"
import classNames from "classnames"

export const PayerIcon = ({
    logo,
    src,
    alt,
    color,
    className,
    term45,
    ...props
}) =>
    src || logo ? (
        <img
            className={classNames("payer-icon", className, term45 && "term-45")}
            src={src || `data:image/png;base64,${logo}`}
            alt={alt || ""}
            role={alt ? undefined : "presentation"}
            {...props}
        />
    ) : color ? (
        <div
            className={classNames("payer-icon", className)}
            style={{ backgroundColor: color }}
        />
    ) : null

export default PayerIcon
