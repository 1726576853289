import React, { Component } from "react"
import { connect } from "react-redux"
import { setPocId } from "../modules/structure/actions"
import UtilizationDetailsPage from "./UtilizationDetailsPage"

export class UtilizationDetailsRedirect extends Component {
    componentDidMount() {
        this.props.setPocId(this.props.match.params.id)
        window.scrollTo(0, 0)
    }

    render() {
        return this.props.match.params.id !== this.props.poc_id ? (
            <span>Loading ...</span>
        ) : (
            <UtilizationDetailsPage {...this.props} />
        )
    }
}

export default connect(state => ({ poc_id: state.poc_id }), {
    setPocId
})(UtilizationDetailsRedirect)
