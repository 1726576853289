import { connect } from "react-redux"
import fetcher from "../../../core/fetcher"
import { ApiTypes } from "../../../api"
import { campaignsSelector } from "../../selectors"
import * as C from "../../constants"
import { Campaigns } from "./Campaigns"

const allCampaignsSelector = campaignsSelector(C.CAMPAIGN_LIST)

export default fetcher({
    name: C.CAMPAIGN_LIST,
    endpoint: "/api/campaigns",
    method: ApiTypes.GET
})(connect(state => ({ campaigns: allCampaignsSelector(state) }))(Campaigns))
