import * as C from "./constants"
import { columnBuilder } from "../table"
import { TimestampDate } from "../core/CellRenderers"
import ReprintLetter from "./components/CellRenderer"

const column = columnBuilder(C.TRANSLATION)

export const TABLE_COLUMNS = [
    column(C.TABLE_KEYS.LETTER_TYPE, {
        cellRenderer: ReprintLetter,
        width: 150,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.LETTER_USER_NAME, {
        width: 200,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRINT_DATE, {
        cellRenderer: TimestampDate,
        width: 100
    })
]
