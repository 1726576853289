import { Field } from "redux-form"
import {
    LabelledDatePicker,
    SelectField,
    TextInput
} from "../../core/CustomFields"
import * as C from "../constants"
import { BOOLEAN_OPTIONS } from "../../constants"
import { Col, Row } from "react-bootstrap"

export const PatientAuditFilters = ({ filterOptions }) => (
    <>
        <Row>
            <Col sm={6} lg={4}>
                <Field name={C.FILTERS.PATIENT_NAME} component={TextInput} />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.DECEASED}
                    component={SelectField}
                    options={BOOLEAN_OPTIONS}
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.PCPS}
                    component={SelectField}
                    options={filterOptions.pcps}
                    multi
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.PRODUCTS}
                    component={SelectField}
                    options={filterOptions.productClasses}
                    multi
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.PAYERS}
                    component={SelectField}
                    options={filterOptions.payers}
                    multi
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.UNITS}
                    component={SelectField}
                    options={filterOptions.units}
                    multi
                />
            </Col>
            <Col lg={6}>
                <Field
                    name={C.FILTERS.TAGS}
                    component={SelectField}
                    options={filterOptions.tags}
                    multi
                />
            </Col>
            <Col lg={6}>
                <Field
                    name={C.FILTERS.REGIONS}
                    component={SelectField}
                    options={filterOptions.regions}
                    multi
                />
            </Col>
        </Row>
        <hr className="divider" />
        <Row>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.AUDIT}
                    component={SelectField}
                    options={filterOptions.audits}
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.CLOSED}
                    component={SelectField}
                    options={BOOLEAN_OPTIONS}
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.STATUS}
                    component={SelectField}
                    options={filterOptions.auditStatuses}
                    multi
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.SERVICE}
                    component={SelectField}
                    options={filterOptions.auditServices}
                    multi
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.SERVICE_DATE_FROM}
                    component={LabelledDatePicker}
                />
            </Col>
            <Col sm={6} lg={4}>
                <Field
                    name={C.FILTERS.SERVICE_DATE_TO}
                    component={LabelledDatePicker}
                />
            </Col>
        </Row>
    </>
)
