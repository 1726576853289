import React, { useMemo } from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"

import { ExportIcon, PrintIcon } from "../../core/Icons"
import FilterHeader from "../../core/FilterHeader"
import fetcher from "../../core/fetcher"
import LEHOC from "../../core/LoadingErrorHOC"
import { costFiltersSelector } from "../../cost/selectors"
import { isBottomLevelRow, normalizeCostSummaries } from "../../cost/helpers"
import { ButtonGroup } from "../../pickers"
import { ApiTypes } from "../../api"
import { urlWithParams } from "../../utils"
import { tieredTableFactory, getRowClass } from "../../table"
import { CATEGORY_COLUMNS, PPP_COLUMNS } from "../columns"
import { costTableRowsSelector } from "../selectors"
import * as actions from "../actions"
import * as C from "../constants"

const viewTables = {
    [C.VIEWS.CATEGORY]: tieredTableFactory({
        name: C.COST_REPORT_CATEGORY_TABLE,
        columns: CATEGORY_COLUMNS,
        sortBy: [C.TABLE_KEYS.TOTAL_COST],
        sortDirection: [SortDirection.DESC]
    }),
    [C.VIEWS.PAYER_PRACTICE_PROVIDER]: tieredTableFactory({
        name: C.COST_REPORT_PPP_TABLE,
        columns: PPP_COLUMNS,
        sortBy: [C.TABLE_KEYS.TOTAL_COST],
        sortDirection: [SortDirection.DESC]
    })
}

const rowClassGetter = getRowClass(0)

export const CostReportTable = ({
    rows,
    fundStampLookup,
    view,
    setView,
    expanded,
    setExpanded,
    exportCostReport,
    printCostReport,
    filters,
    loading,
    error
}) => {
    const { Settings, Table, Description } = viewTables[view]
    const setExpandedView = useMemo(() => setExpanded(view), [
        setExpanded,
        view
    ])

    return (
        <Card className="card-table cost-report-table">
            <Card.Header>
                <Card.Title>Costs By {C.VIEW_TRANSLATION[view]}</Card.Title>
                <div>
                    <Settings style={{ marginRight: 12 }} />
                    <PrintIcon onClick={() => printCostReport(expanded)} />
                    <ExportIcon onClick={() => exportCostReport(expanded)} />
                    <ButtonGroup
                        value={view}
                        onClick={setView}
                        options={C.VIEW_OPTIONS}
                    />
                </div>
                <FilterHeader
                    filters={filters}
                    form={C.COST_REPORT_FILTERS_FORM}
                    lookup={fundStampLookup}
                />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC list={rows} loading={loading} error={error}>
                    <Table
                        list={rows}
                        expanded={expanded[view]}
                        onExpanded={setExpandedView}
                        isBottomLevelRow={isBottomLevelRow}
                        getRowClass={rowClassGetter}
                    />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}

export default fetcher({
    name: C.NAME,
    method: ApiTypes.GET,
    endpoint: state =>
        urlWithParams("/api/cost", costFiltersSelector(C.NAME)(state)),
    acceptResponse: ({ rows }) => ({
        rows: normalizeCostSummaries(rows)
    })
})(
    connect(
        state => ({
            ...state[C.NAME],
            rows: costTableRowsSelector(state)
        }),
        actions
    )(CostReportTable)
)
