export const NAME = "DATA_COVERAGE"

export const EXPORT_COVERAGE_CSV = "EXPORT_COVERAGE_CSV"
export const PRINT_COVERAGE_PDF = "PRINT_COVERAGE_PDF"

export const TABLE_KEYS = {
    CATEGORY: "category",
    SOURCE_NAME: "sourceDescription",
    SOURCE_ID: "sourceId",
    FILE_NAME: "fileName",
    COUNT: "rowCount",
    LOAD_DATE: "processDtm",
    ERROR: "flagError"
}

export const TRANSLATIONS = {
    [TABLE_KEYS.SOURCE_NAME]: "Source",
    [TABLE_KEYS.FILE_NAME]: "Last File Name",
    [TABLE_KEYS.COUNT]: "Record Count",
    [TABLE_KEYS.LOAD_DATE]: "Load Date",
    [TABLE_KEYS.ERROR]: "Error"
}
