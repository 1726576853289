import React from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"

// just like ButtonGroup, but with Links instead.
// also it uses a label-value model instead of a translation model

export const LinkGroup = ({
    value,
    options,
    translation,
    disabled,
    className,
    ...props
}) => (
    <div className={classNames("button-group", className)} {...props}>
        {options.map(option => (
            <Link
                key={option.value}
                disabled={disabled}
                to={option.href}
                className={classNames("btn btn-default", {
                    selected: option.value === value
                })}
            >
                {option.label}
            </Link>
        ))}
    </div>
)
