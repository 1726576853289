import * as C from "./constants"

export const setAggregation = payload => ({
    type: C.SET_COST_DASHBOARD_AGGREGATION,
    payload
})
export const setFilters = payload => ({
    type: C.SET_COST_DASHBOARD_FILTERS,
    payload
})

export const exportCsv = payload => ({
    type: C.EXPORT_COST_DASHBOARD_CSV,
    payload
})
export const printPdf = payload => ({
    type: C.PRINT_COST_DASHBOARD_PDF,
    payload
})
