import React from "react"
import classNames from "classnames"

export class CheckAllButton extends React.Component {
    allChecked = () =>
        this.props.allValues.length === this.props.value.length ||
        (this.props.noneIsAll && this.props.value.length === 0)

    handleClick = () =>
        this.allChecked() ? null : this.props.onChange(this.props.allValues)

    render() {
        return (
            <label className="check-all">
                <input type="checkbox" onClick={this.handleClick} />
                <span
                    className={classNames(
                        "fa",
                        this.allChecked() ? "fa-check-square-o" : "fa-square-o"
                    )}
                />
                All Categories
            </label>
        )
    }
}
