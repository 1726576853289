import {
    MINUTES_IDLE_BEFORE_FORCE_LOGOUT,
    NAME_OF_TEST_DATABASE,
    SECONDS_TO_WAIT_FOR_WEBSERVICE_BEFORE_ERRORING
} from "../../configuration"

export { DEFAULT_CONTACT_EMAIL } from "../../configuration"

export const NAME = "authentication"
export const ROLES = "roles"
export const LOGIN_FORM = "login"
export const RESET_PASSWORD_FORM = "RESET_PASSWORD_FORM"

export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const DISPLAY_PASSWORD_RESET = "DISPLAY_PASSWORD_RESET"
export const RESET_PASSWORD_SUBMIT = "RESET_PASSWORD_SUBMIT"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_TOKEN = "UPDATE_PASSWORD_TOKEN"
export const REFRESH_PASSWORD_TOKEN = "REFRESH_PASSWORD_TOKEN"
export const LOGOUT = "LOGOUT"
export const FORCE_LOGOUT = "FORCE_LOGOUT"
export const SYSTEM_ERROR = "SYSTEM_ERROR"
export const INACTIVITY_MINUTES = MINUTES_IDLE_BEFORE_FORCE_LOGOUT
export const MS_TO_WAIT_FOR_WEBSERVICE_RESPONSE =
    SECONDS_TO_WAIT_FOR_WEBSERVICE_BEFORE_ERRORING * 1000

export const TEST_DATABASE_NAME = NAME_OF_TEST_DATABASE

export const AUTH_REFRESH_INTERVAL = 300 * 1000 // in ms. How often to check for authentication refresh

export const initialState = {
    isLoggedIn: false,
    passwordResetNeeded: false,
    authToken: "",
    refreshToken: "",
    loginId: "",
    userId: 0,
    userName: "",
    lastLogin: "",
    loading: false,
    roles: []
}

export const LOGIN_FIELDS = {
    USERNAME: "username",
    PASSWORD: "password"
}

export const LOGIN_TRANSLATION = {
    [LOGIN_FIELDS.USERNAME]: "Login ID",
    [LOGIN_FIELDS.PASSWORD]: "Password"
}
