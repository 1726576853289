import React from "react"
import { Card } from "react-bootstrap"
import CardTitle from "../../core/CardTitle"
import { ExportIcon } from "../../core/Icons"
import LEHOC from "../../core/LoadingErrorHOC"
import { ButtonGroup } from "../../pickers"
import * as CC from "../../cost/constants"
import * as C from "../constants"
import { UtilizationChart } from "./UtilizationChart"

const CARD_HEIGHT = 450 // charts need an explicit height

export const CostUtilizationTab = ({
    title,
    hasViews,
    view,
    setView,
    loading,
    error,
    tableData,
    chartProps,
    chartData,
    id,
    tab,
    Table,
    Settings,
    Description,
    exportCsv
}) => (
    <Card className="cost-utilization-report">
        <Card className="cost-utilization-chart">
            <Card.Header>
                <Card.Title className="card-title">
                    {title}{" "}
                    {C.COST_VIEW_LABELS[id] &&
                        `- ${C.COST_VIEW_LABELS[id][view]}`}
                </Card.Title>
                {hasViews && (
                    <ButtonGroup
                        value={view}
                        onClick={setView}
                        options={CC.COST_VIEW_OPTIONS}
                    />
                )}
            </Card.Header>
            <Card.Body>
                <LEHOC
                    loading={loading}
                    error={error}
                    list={chartData}
                    height={CARD_HEIGHT}
                >
                    <UtilizationChart
                        {...chartProps}
                        {...chartData}
                        activeTab={tab}
                        id={id}
                        height={CARD_HEIGHT}
                    />
                </LEHOC>
            </Card.Body>
        </Card>

        <Card className="cost-utilization-table">
            <Card.Header>
                <CardTitle>{title} In Detail</CardTitle>

                <ExportIcon onClick={() => exportCsv(id)} />
                <Settings />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC
                    loading={loading}
                    error={error}
                    list={tableData}
                    height={CARD_HEIGHT}
                >
                    <Table height={CARD_HEIGHT} list={tableData} />
                </LEHOC>
            </Card.Body>
        </Card>
    </Card>
)
