import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import tcm from "../modules/tcm"
import user_audit from "../modules/user_audits"
import { GuideLink } from "../modules/indicators"

const TcmPage = () => (
    <user_audit.components.default page={user_audit.constants.TCM}>
        <SiteTitle title="TCM Tracker" />
        <Breadcrumbs items={[["Dashboard", "/dashboard"], ["TCM Tracker"]]} />
        <h1>
            TCM Tracker
            <GuideLink page="view-tcm-tracker" />
        </h1>
        <tcm.components.default />
    </user_audit.components.default>
)

export default TcmPage
