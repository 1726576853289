import { createSelector } from "reselect"
import _ from "lodash"
import { fieldValueSelector } from "../selectorUtils"
import * as TLC from "../table_settings/constants"
import * as C from "./constants"

export const getFavoriteFilters = (state, form) =>
    state[TLC.NAME].favorites[form]

export const getExistingNames = createSelector(
    (state, form) => form,
    getFavoriteFilters,
    fieldValueSelector(C.FILTERS.ID),
    (form, favorites, id) =>
        _.map(
            _.reject(favorites, {
                [C.TABLE_KEYS.ID]: id
            }),
            C.TABLE_KEYS.NAME
        ),
    {
        memoizeOptions: {
            maxSize: 10
        }
    }
)
