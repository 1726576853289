import _ from "lodash"
import { displayWithConditionalParens, joinTruthy } from "../utils"
import {
    FormattedDate,
    TimestampDate,
    ListCell,
    MultilineList,
    DollarsCents,
    LongCell
} from "../core/CellRenderers"
import CDALink from "../tcm/components/CDALink"
import { columnBuilder } from "../table"
import * as C from "./constants"
import { ALIGNMENTS } from "../constants"

const column = columnBuilder(C.UTILIZATION_DETAILS_COLUMNS_TRANSLATION)

// some shared columns

const PROVIDER_NAME = column(C.TABLE_KEYS.PROVIDER_NAME, {
    width: 150,
    flexGrow: 1
})
const PROVIDER_NPI = column(C.TABLE_KEYS.PROVIDER_NPI, {
    width: 100
})
const QUANTITY = column(C.TABLE_KEYS.QUANTITY, {
    hover: "The number of days or units of service",
    width: 80
})
const SOURCE = column(C.TABLE_KEYS.SOURCE_NAME, {
    width: 130,
    flexGrow: 1
})
const CODE = column(C.TABLE_KEYS.CODE, {
    dataGetter: ({ rowData }) =>
        joinTruthy([
            rowData[C.TABLE_KEYS.CODE],
            rowData[C.TABLE_KEYS.CODE_DESCRIPTION]
            // XXX might need to include CODE_SYSTEM if present?
        ]),
    width: 180,
    flexGrow: 1
})

// assign columns to tables

export default {
    [C.CLAIMS]: [
        column(C.TABLE_KEYS.SERVICE_DATE, {
            cellRenderer: FormattedDate,
            width: 110
        }),
        PROVIDER_NAME,
        PROVIDER_NPI,
        column(C.TABLE_KEYS.PROCEDURE, {
            width: 150,
            flexGrow: 2
        }),
        column(C.TABLE_KEYS.CLAIMS_DIAGNOSIS, {
            cellRenderer: MultilineList,
            width: 150,
            flexGrow: 2,
            disableSort: true
        }),
        column(C.TABLE_KEYS.PLACE_OF_SERVICE, {
            cellRenderer: LongCell,
            width: 150,
            flexGrow: 1
        }),
        column(C.TABLE_KEYS.PAYER, {
            cellRenderer: LongCell,
            width: 150
        }),
        QUANTITY,
        column(C.TABLE_KEYS.PAID_AMT, {
            cellRenderer: DollarsCents,
            align: ALIGNMENTS.RIGHT,
            width: 120
        }),
        SOURCE
    ],
    [C.LABS]: [
        column(C.TABLE_KEYS.OBSERVATION_DATE, {
            cellRenderer: FormattedDate,
            width: 100
        }),
        column(
            C.TABLE_KEYS.PROVIDER_NAMES, // if you don't have a listed provider it'll key as undefined, and that's fine
            {
                cellRenderer: ListCell,
                width: 140,
                flexGrow: 1
            }
        ),
        CODE,
        column(C.TABLE_KEYS.RESULT, {
            dataGetter: ({ rowData }) =>
                joinTruthy([
                    rowData[C.TABLE_KEYS.RESULT_OPERAND],
                    rowData[C.TABLE_KEYS.RESULT],
                    rowData[C.TABLE_KEYS.RESULT_UNITS]
                ]),
            width: 60,
            flexGrow: 1
        }),
        SOURCE
    ],
    [C.PHARMACY]: [
        column(C.TABLE_KEYS.FILLED_DATE, {
            cellRenderer: FormattedDate,
            width: 100
        }),
        PROVIDER_NAME,
        PROVIDER_NPI,
        column(
            C.TABLE_KEYS.PROPRIETARY_NAME, //or C.TABLE_KEYS.SUBSTANCE_NAME? // TODO check which to use
            {
                width: 180,
                flexGrow: 1
            }
        ),
        column(C.TABLE_KEYS.DOSE, {
            dataGetter: ({ rowData }) =>
                rowData[C.TABLE_KEYS.ACTIVE_NUMERATOR_STRENGTH],
            cellRenderer: ({ rowData }) => {
                const strength = rowData[C.TABLE_KEYS.ACTIVE_NUMERATOR_STRENGTH]
                const unit = rowData[C.TABLE_KEYS.ACTIVE_INGRED_UNIT]
                const form = rowData[C.TABLE_KEYS.DOSAGE_FORM]

                return displayWithConditionalParens(
                    joinTruthy([strength, unit]),
                    form
                )
            },
            width: 180,
            flexGrow: 1
        }),
        column(C.TABLE_KEYS.ROUTE_NAME, {
            width: 100
        }),
        QUANTITY,
        column(C.TABLE_KEYS.DAYS_SUPPLY, {
            width: 120
        }),
        column(C.TABLE_KEYS.COST, {
            width: 120
        }),
        SOURCE
    ],
    [C.MEDICATIONS]: [
        SOURCE,
        PROVIDER_NAME,
        CODE,
        column(C.TABLE_KEYS.START_DATE, {
            cellRenderer: FormattedDate,
            width: 100
        }),
        column(C.TABLE_KEYS.END_DATE, {
            cellRenderer: FormattedDate,
            width: 100
        })
    ],
    [C.ADT]: [
        column(C.TABLE_KEYS.FACILITY_NAME, { width: 180, flexGrow: 1 }),
        column(C.TABLE_KEYS.ADMIT_DATE, {
            cellRenderer: TimestampDate,
            width: 180
        }),
        column(C.TABLE_KEYS.DISCHARGE_DATE, {
            cellRenderer: TimestampDate,
            width: 180
        }),
        column(C.TABLE_KEYS.ADMIT_TYPE, { width: 180, flexGrow: 1 }),
        column(C.TABLE_KEYS.DISCHARGE_TYPE, {
            width: 180,
            flexGrow: 1
        }),
        column(C.TABLE_KEYS.CCDA, {
            cellRenderer: CDALink,
            width: 65,
            align: ALIGNMENTS.CENTER
        }),
        column(C.TABLE_KEYS.TRANSITION_TYPE, {
            width: 180,
            flexGrow: 1
        }),
        column(C.TABLE_KEYS.ADT_DIAGNOSIS_CODES, {
            dataGetter: ({ rowData }) =>
                _.zip(
                    rowData[C.TABLE_KEYS.ADT_DIAGNOSIS_CODES],
                    rowData[C.TABLE_KEYS.ADT_DIAGNOSIS_DESCRIPTIONS]
                ).map(values => joinTruthy(values)), // explicit function to prevent index from being passed in
            cellRenderer: MultilineList,
            width: 180,
            flexGrow: 1,
            disableSort: true
        })
    ]
}
