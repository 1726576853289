import { createReducer } from "../utils"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.SET_UTILIZATION_FILTERS]: (state, action) => ({
        ...state,
        filters: action.payload.filters
    })
})

export default reducer
