import React from "react"
import { connect } from "react-redux"
import * as C from "../../constants"
import * as reduxActions from "../../actions"

export const EditCampaign = ({ cellData, rowData, showEdit }) =>
    cellData && (
        <span
            className="fa fa-pencil"
            onClick={() => showEdit(rowData[C.TABLE_KEYS.CAMPAIGN_ID])}
            style={{ cursor: "pointer" }}
        />
    )

export default connect(null, reduxActions)(EditCampaign)
