import React from "react"
import { SortDirection } from "react-virtualized"
import { tableFactory } from "../../table"
import { GEN_TABLE_COLUMNS } from "../columns"
import * as C from "../constants"
import { ConditionCard } from "./ConditionCard"

const tableComponents = tableFactory({
    name: C.GEN_CONDITIONS,
    columns: GEN_TABLE_COLUMNS,
    sortBy: [
        C.TABLE_KEYS.LAST_IDENTIFIED_DATE,
        C.TABLE_KEYS.GEN_CONDITION_NAME
    ],
    sortDirection: [SortDirection.DESC, SortDirection.ASC]
})

const GenConditions = props => (
    <ConditionCard title="General Conditions" {...tableComponents} {...props} />
)

export default GenConditions
