import React from "react"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import AdminContainer from "../modules/admin/components"
import { GuideLink } from "../modules/indicators"

const AdminPage = () => (
    <>
        <SiteTitle title="User Administration" />

        <Breadcrumbs
            items={[["Dashboard", "/dashboard"], ["User Administration"]]}
        />

        <h1>
            User Administration
            <GuideLink page="view-user-admin" />
        </h1>

        <AdminContainer />
    </>
)

export default AdminPage
