import React from "react"
import { connect } from "react-redux"
import { SimpleModal } from "../../core/SimpleModal"
import { tableFactory } from "../../table"
import * as actions from "../actions"
import { COLUMNS } from "../columns"
import * as C from "../constants"
import Documents from "./Documents"

const { Table, Description } = tableFactory({ name: C.NAME, columns: COLUMNS })

export const DocumentsModal = ({ closeModal, show, ...props }) => (
    <SimpleModal
        className="audits-modal audit-files" // it's styled the same as an audits modal
        title="Shared Documents"
        show={show}
        closeModal={closeModal}
    >
        <Documents {...props} Description={Description} Table={Table} />
    </SimpleModal>
)

export default connect(state => state[C.NAME], actions)(DocumentsModal)
