import * as C from "../constants"

const RISK_RANK_VALUES = { Low: 1, Medium: 2, High: 3 }

export const getLaceIndex = ({ rowData }) =>
    (rowData[C.TABLE_KEYS.LACE_SCORE] ?? 0) * 10 +
    (RISK_RANK_VALUES[rowData[C.TABLE_KEYS.LACE_RISK]] ?? 0)
// it's probably not necessary to have a dataGetter, but I don't trust the backend to match up LACE scores and LACE risk in a consistent way

export const LaceScore = ({ cellData, rowData }) => {
    return cellData
        ? `${rowData[C.TABLE_KEYS.LACE_SCORE]} (${
              rowData[C.TABLE_KEYS.LACE_RISK]
          })`
        : ""
}
