import { connect } from "react-redux"

import fetcher, { getResourceData } from "../../core/fetcher"
import { FILTER_OPTIONS } from "../../constants"
import { ApiTypes } from "../../api"
import * as actions from "../actions"
import * as C from "../constants"
import { Coverage } from "./Coverage"

export default connect(
    state => ({
        categories: getResourceData(
            FILTER_OPTIONS,
            data => data.coverageCategories
        )(state)
    }),
    actions
)(
    fetcher({
        name: C.NAME,
        endpoint: "/api/coverage",
        method: ApiTypes.GET
    })(Coverage)
)
