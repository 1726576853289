import * as C from "./constants"

export const setFilters = payload => ({
    type: C.SET_COST_REPORT_FILTERS,
    payload
})

export const setView = payload => ({
    type: C.SET_COST_REPORT_VIEW,
    payload
})

export const printCostReport = payload => ({
    type: C.PRINT_COST_REPORT,
    payload
})

export const exportCostReport = payload => ({
    type: C.EXPORT_COST_REPORT,
    payload
})

export const exportProcedures = () => ({
    type: C.EXPORT_PROCEDURES
})
