import React, { Component } from "react"
import _ from "lodash"
import { HistoryOverlay } from "../../../../../core/HistoryOverlay"
import * as C from "../../../../constants"
import { updateLastEntry } from "../../../../transforms"
import HistoryList from "./HistoryList"

export default class PatientHistory extends Component {
    state = { show: false }

    handleShow = () => this.setState({ show: true })
    handleHide = () => {
        const historyListValues = (this.props.historyList || {}).values

        //XXX Really we should only do all this if anything actually changed, but there are several ways the latest measure could change: e.g. deletion, value edited, probably others. We'd have to track the changed status on a higher level, which would require more infrastructure that I don't want to add right now.
        // well ok, we could also just update the value immediately when the request comes back, and not do anything here. That's probably smarter.
        if (!_.isEmpty(historyListValues)) {
            const newLatestMeasure = _.head(
                _.orderBy(historyListValues, [v => v.serviceDt], ["desc"])
            )
            this.props.modifyResource({
                name: C.RELEVANT_MEASURES_TREE,
                dataTransform: data =>
                    updateLastEntry(data, {
                        ...newLatestMeasure,
                        forceReplace: true
                    })
            })
        } else if (_.isArrayLike(historyListValues)) {
            // historyList.values exists but it's empty. No history left.
            this.props.modifyResource({
                name: C.RELEVANT_MEASURES_TREE,
                dataTransform: data =>
                    updateLastEntry(data, {
                        measureId: this.props.id,
                        serviceDt: "",
                        displayValue: "",
                        numericVal: null,
                        textVal: null,
                        forceReplace: true,
                        hasRecentValue: false
                    })
            })
        }

        this.setState({ show: false })
    }

    render() {
        return !this.props.offerHistory || !this.props.hasHistory ? (
            <div />
        ) : (
            <HistoryOverlay
                onClick={this.handleShow}
                onHide={this.handleHide}
                show={this.state.show}
                id={this.props.id}
            >
                <div
                    className="poc-measure-history scrollable-table-container"
                    id="poc_measure_history_popover"
                >
                    <HistoryList {...this.props} />
                </div>
            </HistoryOverlay>
        )
    }
}
