import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import Risk from "./Risk"

export default fetcher({
    name: C.NAME,
    endpoint: state => `/api/patients/${state.poc_id}/riskRanks`,
    method: ApiTypes.GET,
    persistResource: true
})(Risk)
