import { connect } from "react-redux"
import fetcher from "../../core/fetcher"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import { auditsSelector, filtersSelector } from "../selectors"
import { PatientAudits } from "./PatientAudits"

export { default as AuditsCounter } from "./AuditsCounter"

export default fetcher({
    name: C.NAME,
    endpoint: state =>
        urlWithParams("/api/patient_audit", filtersSelector(state)),
    method: ApiTypes.GET,
    paginationKey: "audits"
})(
    connect(
        (state, { data }) => ({
            ...state[C.NAME],
            ...data,
            audits: auditsSelector(state)
        }),
        actions
    )(PatientAudits)
)
