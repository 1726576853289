import React, { useMemo } from "react"
import { Card } from "react-bootstrap"
import _ from "lodash"

import DashboardFilters from "../../../dashboard_filters/components"
import { ExportIcon, PrintIcon } from "../../../core/Icons"
import { TableDescription, tableFactory } from "../../../table"
import FilterHeader from "../../../core/FilterHeader"
import LEHOC from "../../../core/LoadingErrorHOC"
import CardTitle from "../../../core/CardTitle"
import { EMPTY_OBJECT, itemsToObject } from "../../../utils"
import * as C from "../../constants"
import {
    getMaxLabel,
    getMaxName,
    getMinLabel,
    getMinName,
    getTableName
} from "../../helpers"
import { ReportFilters } from "./ReportFilters"

const getTranslation = (fields = []) => {
    const normalFields = itemsToObject(
        fields,
        field => field.name,
        field => field.name
    )
    const pairedFields = _.fromPairs(
        fields
            .filter(field => C.ALL_PAIRED_TYPES.includes(field.type))
            .flatMap(field => [
                [getMinName(field.name), getMinLabel(field.name)],
                [getMaxName(field.name), getMaxLabel(field.name)]
            ])
    )
    return { ...normalFields, ...pairedFields }
}

export const ReportPanel = props => {
    const {
        reportId,
        reportName = "",
        filters = EMPTY_OBJECT,
        setLibraryFilters,
        columns,
        data,
        description,
        error,
        loading,
        lookup,
        exportLibrary,
        printLibrary,
        paginationFunctions
    } = props

    const setFilters = newFilters => setLibraryFilters(reportId, newFilters)

    const { Table, Settings } = useMemo(
        () =>
            tableFactory({
                name: getTableName(reportId),
                columns
            }),
        [reportId, columns]
    )

    const translation = useMemo(() => getTranslation(data.fields), [
        data.fields
    ])

    return (
        <Card className="card-table report-panel">
            <Card.Header>
                <CardTitle style={{ fontSize: "20px" }}>{reportName}</CardTitle>
                <DashboardFilters
                    title={`Edit ${reportName} Filters`}
                    form={C.REPORT_LIBRARY_FILTERS_FORM}
                    formComponent={ReportFilters}
                    fields={data.fields}
                    initialValues={filters}
                    setFilters={setFilters}
                    translation={translation}
                    size="lg"
                    loading={loading}
                    error={error}
                    list={data.records}
                />
                <Settings />
                <PrintIcon
                    onClick={() => printLibrary(reportId)}
                    className="pull-right"
                />
                <ExportIcon
                    onClick={() => exportLibrary(reportId)}
                    className="pull-right"
                />
                <FilterHeader
                    filters={filters}
                    translation={_.identity}
                    lookup={lookup}
                />
            </Card.Header>
            <TableDescription>{description}</TableDescription>
            <Card.Body id="card-body">
                <LEHOC loading={loading} error={error} list={data.records}>
                    <Table
                        {...paginationFunctions}
                        rowCount={data.total}
                        list={data.records}
                    />
                </LEHOC>
            </Card.Body>
            <Card.Footer>
                {loading ? "Loading..." : (data.total ?? 0) + " results"}
            </Card.Footer>
        </Card>
    )
}
