import React, { Component } from "react"
import _ from "lodash"
import { PATH } from "../../constants"
import { connectTable, getSelectedColumnKeys } from "../helpers"
import { connectDescription, TableDescription } from "./TableDescription"
import { Settings, Table } from "./tableFactory"

export const tieredTableFactory = factoryProps => {
    const tableConnector = connectTable(factoryProps)
    const descConnector = connectDescription(factoryProps.name)

    return {
        Settings: tableConnector(Settings),
        Table: tableConnector(TieredTable),
        Description: descConnector(TableDescription)
    }
}

const getStamp = (row, maxLength) => row[PATH].slice(0, maxLength).join(".")

export class TieredTable extends Component {
    constructor(props) {
        super(props)

        if (!props.expanded) {
            const level = props.initialExpandedLevel
            const stamps =
                level > 0 ? props.list.map(row => getStamp(row, level)) : []
            const expanded = new Set(stamps)
            this.state = { expanded }
        } else {
            this.state = {}
        }
    }

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(
                getSelectedColumnKeys(prevProps.columns),
                getSelectedColumnKeys(this.props.columns)
            ) ||
            !_.isEqual(
                getSelectedColumnKeys(prevProps.frozenColumns),
                getSelectedColumnKeys(this.props.frozenColumns)
            )
        ) {
            this.setExpanded(new Set())
        }
    }

    getExpanded = () => this.props.expanded || this.state.expanded

    getRowClass = rowData => {
        const path = rowData[PATH] || []
        const rowClass = this.props.getRowClass?.(rowData) || {}
        const expanded = this.getExpanded()
        return {
            branch: !rowData.isLeaf && path.length !== 0,
            expanded: expanded.has(path.join(".")),
            ...(_.isString(rowClass) ? { [rowClass]: true } : rowClass)
        }
    }

    getRows = () =>
        this.props.list?.filter(row =>
            row[PATH]?.every((id, index) => {
                const stamp = getStamp(row, index)
                return !stamp || this.getExpanded().has(stamp)
            })
        )

    rowRenderer = ({ rowData }) => ({
        onClick: this.handleRowClick,
        className: this.getRowClass(rowData)
    })

    getNewExpanded = rowData => {
        const stamp = rowData[PATH].join(".")

        const expanded = this.getExpanded()
        return expanded.has(stamp)
            ? new Set([...expanded].filter(e => !e.startsWith(stamp)))
            : new Set(expanded).add(stamp)
    }

    handleRowClick = ({ rowData }) => {
        if (
            _.isEmpty(rowData?.[PATH]) ||
            this.props.isBottomLevelRow?.(rowData)
        ) {
            return
        }

        this.setExpanded(this.getNewExpanded(rowData))
    }

    setExpanded = expanded => {
        if (this.state.expanded) {
            this.setState({ expanded })
        } else {
            this.props.onExpanded(expanded)
        }
    }

    render() {
        const { getRowClass, list, ...props } = this.props
        const rows = this.getRows()

        return (
            <Table
                rowRenderer={this.rowRenderer}
                tiered
                {...props}
                list={rows}
            />
        )
    }
}
