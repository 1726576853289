import React from "react"
import ColCard from "../../../core/ColCard"
import AddUserModal from "./AddUserModal"
import UserTable from "./UserTable"
import EditUserModal from "./EditUserModal"

const UserPanel = props => (
    <ColCard xl={8} className="admin-users">
        <AddUserModal />
        <UserTable {...props} />
        <EditUserModal />
    </ColCard>
)

export default UserPanel
