import React from "react"
import { Modal } from "react-bootstrap"
import { getIsNew } from "../helpers"
import AddTagsForm from "./AddTagsForm"

export const AddTagsModal = ({ show, onHide, updateTag, ...props }) => (
    <Modal
        show={show}
        onHide={onHide}
        className="patient-tags-modal"
        backdropClassName="modal-backdrop-front"
    >
        <Modal.Header closeButton>
            {getIsNew(props.initialValues) ? "Add" : "Edit"} Patient Tag
        </Modal.Header>
        <AddTagsForm
            {...props}
            onSubmit={values => {
                updateTag(values)
                onHide()
            }}
        />
    </Modal>
)
