import { connect } from "react-redux"
import {
    categoryNodesSelector,
    categoryTreeSelector,
    fundStampLookupSelector
} from "../../cost/selectors"
import * as C from "../constants"
import * as actions from "../actions"
import CostReport from "./CostReport"

export default connect(
    state => ({
        ...state[C.NAME],
        categoryTree: categoryTreeSelector(state),
        categoryNodes: categoryNodesSelector(state),
        fundStampLookup: fundStampLookupSelector(state)
    }),
    actions
)(CostReport)
