import React from "react"
import classNames from "classnames"
import spinner from "../../assets/images/spinner.gif"
import spinnerSmall from "../../assets/images/loading.gif"

export const Loading = props => (
    <div className="loader spinner-loading" {...props}>
        <img src={spinner} width="100px" alt="loading" />
    </div>
)

export const Saving = ({ className, ...props }) => (
    <img
        className={classNames("spinner-saving", className)}
        src={spinnerSmall}
        alt="Saving..."
        {...props}
    />
)

export default Loading
