import { useCallback, useEffect, useRef, useState } from "react"

export const useFocus = () => {
    const focusRef = useRef()
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        if (focused) {
            focusRef?.current?.focus?.()
        }
    }, [focused])

    return { focused, setFocus: () => setFocused(true), focusRef }
    // we could allow for unfocusing too fairly easily, but I don't think it's really necessary
}

export const useIncrease = (value, func) => {
    const ref = useRef(value)
    useEffect(() => {
        if (value > ref.current) {
            func()
        }
        ref.current = value
    }, [value])
}
