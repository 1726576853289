import { createSelector } from "reselect"
import moment from "moment"
import _ from "lodash"
import { getResourceData, modifyData } from "../core/fetcher"
import { optionLookupsSelector } from "../patient_list/selectors"
import { FILTER_OPTIONS } from "../constants"
import { valueLabelToObj } from "../utils"
import { usToIsoDate } from "../dates"
import * as C from "./constants"

const tagsSelector = createSelector(
    getResourceData(FILTER_OPTIONS, data => data.tags),
    valueLabelToObj
)

const getDuration = toc => {
    if (!toc[C.TABLE_KEYS.DISCHARGE_DATE]) return null
    const discharge = moment(toc[C.TABLE_KEYS.DISCHARGE_DATE])
    const admit = moment(toc[C.TABLE_KEYS.TRANSITION_DATE])
    return discharge.diff(admit, "hours")
}

export const tocsSelector = createSelector(
    getResourceData(C.NAME),
    optionLookupsSelector,
    tagsSelector,
    (data, { genConditions }, tags) =>
        modifyData("tocs", toc => ({
            ...toc,
            [C.TABLE_KEYS.GEN_CONDITIONS]: _.map(
                toc.genConditionIds,
                id => genConditions[id]
            ),
            [C.TABLE_KEYS.LENGTH_OF_STAY]: getDuration(toc),
            [C.TABLE_KEYS.TAGS]: _.map(toc[C.TABLE_KEYS.TAGS], id => tags[id]),
            [C.TABLE_KEYS.AT_RISK]: _.zip(
                toc[C.TABLE_KEYS.FLAG_ICONS],
                toc[C.TABLE_KEYS.FLAG_COLORS],
                toc[C.TABLE_KEYS.FLAG_IMAGES],
                toc[C.TABLE_KEYS.FLAG_DESCRIPTIONS]
            ).map(([icon, color, image, description]) => ({
                icon,
                color,
                image,
                description
            }))
        }))(data)
)

export const filtersCombiner = filters => {
    // dates need to be normalized to ISO
    const dates = _.pick(filters, C.DATE_FILTERS)
    const normalizedDates = _.mapValues(dates, usToIsoDate)

    return { ...filters, ...normalizedDates }
}

export const filtersSelector = createSelector(
    state => state[C.NAME].filters,
    filtersCombiner
)
