import React, { Component } from "react"
import { Button } from "react-bootstrap"
import { AddMeasureModal } from "./AddMeasureModal"

export class AddMeasureModalContainer extends Component {
    state = { show: false }

    handleOpen = () => this.setState({ show: true })
    handleClose = () => this.setState({ show: false })
    handleSubmit = () => {
        this.props.addNewMeasures()
        this.props.updateAddMeasureFilter() // clear measure filter
        this.props.reset()
        this.handleClose()
    }

    render() {
        if (this.props.disabled) {
            return null
        }
        return (
            <>
                <Button
                    type="primary"
                    size="sm"
                    onClick={this.handleOpen}
                    className="pull-right add-measure"
                >
                    <i className="fa fa-plus-circle" /> Add Measure
                </Button>
                <AddMeasureModal
                    {...this.props}
                    {...this.state}
                    onChange={this.props.updateAddMeasureFilter}
                    onClose={this.handleClose}
                    onSubmit={this.handleSubmit}
                />
            </>
        )
    }
}
