import React from "react"
import { connect } from "react-redux"
import { FormattedDate, LongCell } from "../core/CellRenderers"
import UndoRemoveButtons from "../core/UndoRemoveButtons"
import { columnBuilder } from "../table"
import * as C from "./constants"
import { removePA, restorePA } from "./actions"
import TCMEditPopover from "./components/TCMPopover"

const UndoRemoveAttention = connect(
    () => ({
        field: C.TABLE_KEYS.TCM_TRACKER_ID,
        statusField: C.TABLE_KEYS.REMOVED
    }),
    {
        undo: restorePA,
        remove: removePA
    }
)(UndoRemoveButtons)

const column = columnBuilder(C.TRANSLATIONS)

export const TABLE_COLUMNS = [
    column(C.TABLE_KEYS.EVENT, {
        cellRenderer: ({ rowData, cellData }) => (
            <span>
                {cellData}{" "}
                {rowData.billingPossible && (
                    <span className="fa indicator-billable" />
                )}
            </span>
        ),
        width: 80
    }),
    column(C.TABLE_KEYS.ADMIT_DATE, {
        cellRenderer: FormattedDate,
        width: 100
    }),
    column(C.TABLE_KEYS.DISCHARGE_DATE, {
        cellRenderer: FormattedDate,
        width: 100
    }),
    column(C.TABLE_KEYS.DISCHARGE_FACILITY, {
        width: 200,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.DIAGNOSIS, {
        cellRenderer: LongCell,
        width: 300,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.EDIT_TCM, {
        cellRenderer: ({ rowData }) =>
            rowData[C.TABLE_KEYS.REMOVED] ||
            rowData[C.TABLE_KEYS.EVENT] === "ED" ? null : (
                <TCMEditPopover
                    tcm={rowData}
                    dischargeDt={rowData[C.TABLE_KEYS.DISCHARGE_DATE]}
                />
            ),
        width: 80,
        disableSort: true
    }),
    column(C.TABLE_KEYS.REMOVE, {
        cellRenderer: UndoRemoveAttention,
        width: 120,
        disableSort: true
    })
]
