import { all, call, select, takeLatest } from "redux-saga/effects"
import { getResourceData } from "../core/fetcher"
import * as IC from "../info/constants"
import { joinTruthy } from "../utils"
import { downloadFile } from "../api"
import { dateFormat, TIMESTAMP_STRING_COMPACT, TIMESTAMP_STRING } from "../dates"
import * as C from "./constants"

export function* getPatientLetter({ patientId, letter }) {
    const commaName = yield select(
        getResourceData(IC.NAME, data => data[IC.KEYS.NAME])
    )
    const [last, first] = commaName.split(", ", 1)
    const name = joinTruthy([first, last], "_")

    const [letterId, letterDt] = _.at(letter, [
        C.TABLE_KEYS.LETTER_ID,
        C.TABLE_KEYS.PRINT_DATE
    ])
    const timestamp = dateFormat(
        letterDt,
        TIMESTAMP_STRING,
        TIMESTAMP_STRING_COMPACT
    )

    const endpoint = `/api/patients/${patientId}/letter/${letterId}`
    const filename = `letter_${name}_${timestamp}.pdf`
    yield* downloadFile(`patient letter`, endpoint, filename)
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeLatest(C.GET_PATIENT_LETTER, getPatientLetter)
        })
    ])
}
