import * as C from "../../../constants"
import React from "react"

// This component prevents autocomplete of incorrect information on user info forms. In theory it should be enough to set autocomplete="off" on all the fields we don't want autocompleted, but in practice Chrome sometimes ignores the autocomplete attribute. Sometimes setting unique names on the input fields is enough; when it's not, we have this component to intercept Chrome's misguided attempts at autocompletion.
export const UserInfoDecoys = () => (
    <div className="input-decoys">
        <input
            name={C.USER_FIELDS.FIRST_NAME}
            type="text"
            autoComplete="first-name"
        />
        <input
            name={C.USER_FIELDS.LAST_NAME}
            type="text"
            autoComplete="last-name"
        />
        <input name={C.USER_FIELDS.EMAIL} type="text" autoComplete="email" />
        <input name={C.USER_FIELDS.CURRENT_PASSWORD} type="password" />
        <input name={C.USER_FIELDS.NEW_PASSWORD} type="password" />
        <input name={C.USER_FIELDS.CONFIRM_PASSWORD} type="password" />
    </div>
)
