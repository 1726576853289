import moment from "moment"
import { modifyData } from "../core/fetcher"
import { usToIsoDate } from "../dates"
import * as C from "./constants"

// we do this transform instead of using the original field names because they're pretty generic names that are likely to collide with other field names in translation. Also we have to do the dateFormat conversion anyway.
// TODO make CustomFields translation scope properly so that we don't have to do the first part

export const transformData = modifyData("careManagers", careManager => ({
    [C.KEYS.USER_ID]: careManager.userId,
    [C.KEYS.REASON_IDS]: careManager.reasonIds,
    [C.KEYS.EFF_DT]: moment.utc(careManager.effDt),
    [C.KEYS.TERM_DT]: moment.utc(careManager.termDt),
    [C.KEYS.PROVIDER_NAME]: careManager.providerName
}))

export const transformPayload = payload => ({
    userId: payload[C.KEYS.USER_ID],
    reasonIds: payload[C.KEYS.REASON_IDS],
    effDt: usToIsoDate(payload[C.KEYS.EFF_DT]),
    termDt: usToIsoDate(payload[C.KEYS.TERM_DT])
})
