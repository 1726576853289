import * as C from "./constants"

const getColumnKeys = columns =>
    columns.filter(({ selected }) => selected).map(({ key }) => key)

export const persistSettings = (tableName, { columns, frozenColumns }) => ({
    type: C.PERSIST_SETTINGS,
    tableName,
    payload: {
        columns: getColumnKeys(columns),
        frozenColumns: getColumnKeys(frozenColumns)
    }
})
export const fetchUserPreferences = () => ({ type: C.FETCH_USER_PREFERENCES })

export const removeUserPreference = tableName => ({
    type: C.REMOVE_USER_PREFERENCES,
    tableName
})

export const addFavorite = (form, payload) => ({
    type: C.ADD_FAVORITE,
    form,
    payload
})
export const updateFavorite = (form, payload) => ({
    type: C.UPDATE_FAVORITE,
    form,
    payload
})
export const removeFavorite = (form, id) => ({
    type: C.REMOVE_FAVORITE,
    form,
    id
})
export const unRemoveFavorite = (form, id, newId) => ({
    type: C.UNREMOVE_FAVORITE,
    form,
    id,
    newId
})

export const setFavoriteLoading = (form, id) => ({
    type: C.SET_FAVORITE_LOADING,
    form,
    id
})
