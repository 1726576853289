import { connect } from "react-redux"
import { reduxForm } from "redux-form"
import { getResourceData } from "../../../core/fetcher"
import { FILTER_OPTIONS } from "../../../constants"
import { setCampaignFilters } from "../../actions"
import { formUrlSelector } from "../../selectors"
import * as C from "../../constants"
import { EditCampaignFiltersModal } from "./EditCampaignFiltersModal"

// Like EditCampaign, but when you only have permission to edit the filters

const EditCampaignForm = reduxForm({
    form: C.CAMPAIGN_FILTERS_FORM,
    enableReinitialize: true,
    destroyOnUnmount: false
})(EditCampaignFiltersModal)

export default connect(
    state => ({
        options: getResourceData(FILTER_OPTIONS)(state),
        url: formUrlSelector(state),
        initialValues: { filter: state.applied_filters }
    }),
    { onSubmit: setCampaignFilters }
)(EditCampaignForm)
