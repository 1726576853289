import * as C from "./constants"

export const setAggregation = payload => ({
    type: C.SET_RISK_AGGREGATION,
    payload
})
export const setFilters = payload => ({ type: C.SET_RISK_FILTERS, payload })

export const printRisks = () => ({ type: C.PRINT_RISKS })
export const exportRisks = () => ({ type: C.EXPORT_RISKS })
