import React from "react"
import { Hoverlay } from "../../core/Hoverlay"
import FilterHeader from "../../core/FilterHeader"

export const FiltersCell = ({
    cellData,
    rowData: { form, formName, lookup, translation }
}) => (
    <Hoverlay
        className="filter-favorites-popover"
        isPopover
        forceWrapper
        wrapper="div"
        placement="right"
    >
        <FilterHeader
            filters={cellData}
            form={formName || form} // "formName" is correct, but if someone passes the other one that's fine too
            translation={translation}
            lookup={lookup}
        />
    </Hoverlay>
)
