import React, { useMemo } from "react"
import { Field } from "redux-form"
import classNames from "classnames"
import _ from "lodash"
import RFCheckBoxTree from "../../core/RFCheckBoxTree"
import { getValues } from "../../utils"
import * as C from "../constants"
import { PrintField } from "./PrintField"
import { ToggleField } from "./PrintLinkages"

const CheckAllButton = ({ onClick, className, text }) => (
    <button className="check-all" onClick={onClick}>
        <span className={classNames("fa", className)} />
        {text}
    </button>
)

export const PrintLetter = ({ change, dueServices, formValues }) => {
    const buttonProps = useMemo(() => {
        const setSelected = newSelected => () =>
            change(C.PRINT_FIELDS.DUE_SERVICES, newSelected)

        const allChecked =
            formValues[C.PRINT_FIELDS.DUE_SERVICES]?.length ===
            dueServices?.length

        return allChecked
            ? {
                  onClick: setSelected([]),
                  text: "Uncheck all",
                  className: "fa-square-o"
              }
            : {
                  onClick: setSelected(getValues(dueServices)),
                  text: "Check all",
                  className: "fa-check-square-o"
              }
    }, [formValues[C.PRINT_FIELDS.DUE_SERVICES], dueServices])

    return _.isEmpty(dueServices) ? (
        <PrintField name={C.PRINT_FIELDS.LETTER} className="print-field-error">
            <h5>This patient has no services due.</h5>
        </PrintField>
    ) : (
        <PrintField name={C.PRINT_FIELDS.LETTER} className="print-letter">
            <Field
                name={C.PRINT_FIELDS.ONLY_INCENTIVIZED}
                className="only-incentivized"
                component={ToggleField}
            />
            <div className="print-letter-services">
                <h5>Due Services to include:</h5>
                <CheckAllButton {...buttonProps} />
                <RFCheckBoxTree
                    nodes={dueServices}
                    name={C.PRINT_FIELDS.DUE_SERVICES}
                />
            </div>
        </PrintField>
    )
}
