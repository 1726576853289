import _ from "lodash"
import { createSelector } from "reselect"
import { getResourceData } from "../core/fetcher"
import { easyFormValueSelector } from "../selectorUtils"
import { SETTINGS } from "../constants"
import * as C from "./constants"

// UTILITY FUNCTIONS

export const isExternalAccess = accessLevel => accessLevel === C.EXTERNAL_VALUE
export const isSuperuserAccess = accessLevel =>
    accessLevel === C.SUPERUSER_VALUE

// GENERAL SELECTORS

export const roleLookupSelector = createSelector(
    getResourceData(SETTINGS, data => data.roles),
    roles => _.keyBy(roles, role => role.roleId)
)

export const getAccessLevelSelector = createSelector(
    roleLookupSelector,
    roleLookup => values =>
        roleLookup[values?.[C.USER_FIELDS.ROLE]]?.accessLevel
)

export const superuserSelector = formName =>
    createSelector(
        easyFormValueSelector(formName, C.USER_FIELDS.ROLE),
        getAccessLevelSelector,
        (formValues, getAccessLevel) =>
            isSuperuserAccess(getAccessLevel(formValues))
    )
