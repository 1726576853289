import _ from "lodash"
import { ACCESS_PRIVILEGES } from "../permission_managment/constants"
import { objToValueLabel } from "../utils"

export const ADMIN_NAME = "ADMIN"
export const PRACTICE_UNITS = "PRACTICE_UNITS"
export const USERS = "USERS"
export const ADD_PRACTICE_UNIT_FORM = "ADD_PRACTICE_UNIT_FORM"
export const EDIT_PRACTICE_UNIT_FORM = "EDIT_PRACTICE_UNIT_FORM"
export const ADD_USER_FORM = "ADD_USER_FORM"
export const ADD_USER_FORM_PROVIDERS = "ADD_USER_FORM_PROVIDERS"
export const EDIT_USER_FORM_PROVIDERS = "EDIT_USER_FORM_PROVIDERS"
export const USER_FORM_PROVIDERS = "USER_FORM_PROVIDERS"
export const EDIT_USER_FORM = "EDIT_USER_FORM"
export const EDIT_USER_PRACTICES = "EDIT_USER_PRACTICES"
export const EDIT_USER_TAGS = "EDIT_USER_TAGS"

export const SELECTED_USER = "SELECTED_USER"
export const USER_PRACTICE_TAGS = "USER_PRACTICE_TAGS"
export const PRACTICE_TAGS = "PRACTICE_TAGS"

export const ROLE_SUPERUSER = "ROLE_SUPERUSER"
export const ROLE_PRACTICE_ADMIN = "ROLE_PRACTICE_ADMIN"
export const ROLE_USER = "ROLE_USER"
export const ROLE_EXTERNAL = "ROLE_EXTERNAL"

export const TAB_INFO = "Info"
export const TAB_PRACTICES = "Practice Units"
export const TAB_PANELS = "Manageable Provider Panels"
export const TAB_TAGS = "Tags"

export const NEW_USER_ID = -1 // when you make a new user, this is their ID until one is assigned by the DB. If a user has this ID, you know they're in the process of being made

export const PRACTICE_FIELDS = {
    PRACTICE_NAME: "practiceUnitName",
    REGION_ID: "regionId",
    TAGS: "tags"
}

export const USER_FIELDS = {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    LOGIN_ID: "loginId",
    NPI: "npi",
    EMAIL: "email",
    USER_STATUS: "status",
    ACCESS_LEVEL: "accessLevel",
    ROLE: "roleId",
    USER_UNITS: "userUnits",
    ADMIN_UNITS: "adminUnits",
    PROVIDERS: "providers",
    HANDLERS: "handlers",
    PRIMARY_PRACTICE: "primaryPractice",
    TAGS: "tags",
    CONFIRM_DELETE: "confirmDelete",
    CURRENT_PASSWORD: "currentPassword",
    NEW_PASSWORD: "newPassword",
    CONFIRM_PASSWORD: "confirmPassword",
    // plus some internal-only fake fields
    PRIMARY_PRACTICE_NAME: "primaryPracticeName",
    IS_SUPERUSER: "isSuperuser",
    IS_EXTERNAL: "isExternal"
}

// which fields are in which tab
export const USER_TAB_FIELDS = {
    1: [
        USER_FIELDS.FIRST_NAME,
        USER_FIELDS.LAST_NAME,
        USER_FIELDS.LOGIN_ID,
        USER_FIELDS.NPI,
        USER_FIELDS.EMAIL,
        USER_FIELDS.USER_STATUS,
        USER_FIELDS.ACCESS_LEVEL,
        USER_FIELDS.CONFIRM_DELETE,
        USER_FIELDS.CURRENT_PASSWORD,
        USER_FIELDS.NEW_PASSWORD,
        USER_FIELDS.CONFIRM_PASSWORD
    ],
    2: [
        USER_FIELDS.USER_UNITS,
        USER_FIELDS.ADMIN_UNITS,
        USER_FIELDS.PRIMARY_PRACTICE
    ],
    3: [USER_FIELDS.PROVIDERS, USER_FIELDS.HANDLERS],
    4: [USER_FIELDS.TAGS]
}

export const PRACTICE_TABLE_KEYS = {
    PRACTICE_ID: "practiceUnitId",
    PRACTICE_NAME: "practiceUnitName",
    STATUS: "status",
    REGION_ID: "regionId",
    REGION_NAME: "regionName"
}

export const USER_TABLE_KEYS = {
    USER_ID: "userId",
    LOGIN_ID: "loginId",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    NPI: "npi",
    STATUS: "status",
    LICENSED: "licensed",
    ATTEMPTS: "numAttempts",
    ROLE_ID: USER_FIELDS.ROLE,
    USER_ROLE: "userRole",
    ACCESS_LEVEL: "accessLevel",
    PRIMARY_PRACTICE: "primaryPractice",
    LAST_LOGIN: "lastLoginDt",
    PRACTICES: "practices",
    TAGS: "tags"
}

export const USER_PRACTICE_KEYS = {
    PRACTICE_ID: PRACTICE_TABLE_KEYS.PRACTICE_ID,
    PRACTICE_NAME: PRACTICE_TABLE_KEYS.PRACTICE_NAME,
    PRACTICE_STATUS: PRACTICE_TABLE_KEYS.STATUS,
    IS_PRIMARY_PRACTICE: "isPrimaryPractice",
    IS_PRACTICE_ADMIN: "isPracticeAdmin",
    ROLE_IN_PRACTICE: "roleInPractice"
}

export const EDIT_USER_PRACTICE_KEYS = {
    PRACTICE_ID: PRACTICE_TABLE_KEYS.PRACTICE_ID,
    PRACTICE_NAME: PRACTICE_TABLE_KEYS.PRACTICE_NAME,
    PRACTICE_STATUS: PRACTICE_TABLE_KEYS.STATUS,
    VISIBLE: "visible",
    PRIMARY: "primary",
    ADMIN: "admin"
}

export const TAB_FIELDS = {
    [USER_FIELDS.USER_UNITS]: TAB_PRACTICES,
    [USER_FIELDS.ADMIN_UNITS]: TAB_PRACTICES,
    [USER_FIELDS.PRIMARY_PRACTICE]: TAB_PRACTICES,
    [USER_FIELDS.PROVIDERS]: TAB_PANELS,
    [USER_FIELDS.TAGS]: TAB_TAGS
}

export const PRACTICE_TRANSLATION = {
    [PRACTICE_FIELDS.PRACTICE_NAME]: "Name",
    [PRACTICE_FIELDS.REGION_ID]: "Practice Tags",
    [PRACTICE_FIELDS.TAGS]: "Access to Patient Tags"
}
export const ADMIN_TRANSLATION = {
    [USER_FIELDS.FIRST_NAME]: "First Name",
    [USER_FIELDS.LAST_NAME]: "Last Name",
    [USER_FIELDS.LOGIN_ID]: "User Name",
    [USER_FIELDS.NPI]: "NPI",
    [USER_FIELDS.EMAIL]: "Email",
    [USER_FIELDS.USER_STATUS]: "User Status",
    [USER_FIELDS.ACCESS_LEVEL]: "User Type",
    [USER_FIELDS.ROLE]: "User Role",
    [USER_FIELDS.CURRENT_PASSWORD]: "Current Password",
    [USER_FIELDS.NEW_PASSWORD]: "New Password",
    [USER_FIELDS.CONFIRM_PASSWORD]: "Confirm Password"
}

export const PROVIDER_COLUMNS = {
    NAME: "name",
    ACCESSIBLE: "accessible",
    CAN_ACCESS: "canAccess"
}

// XXX I'm not sure this is the clearest sorting scheme actually. Maybe skip CAN_ACCESS?
export const PROVIDER_DEFAULT_SORT = [
    { id: PROVIDER_COLUMNS.ACCESSIBLE, desc: true },
    { id: PROVIDER_COLUMNS.CAN_ACCESS, desc: true },
    { id: PROVIDER_COLUMNS.NAME, desc: false }
]
export const PROVIDER_DEFAULT_SORT_ADMIN = [
    { id: PROVIDER_COLUMNS.CAN_ACCESS, desc: true },
    { id: PROVIDER_COLUMNS.ACCESSIBLE, desc: true },
    { id: PROVIDER_COLUMNS.NAME, desc: false }
]

export const TAG_COLUMNS = {
    TAG: "tag",
    TAG_ID: "tagId",
    TAG_NAME: "tagName",
    READ: "canRead",
    EDIT: "canEdit",
    PUBLIC: "isPublic",
    PRIVILEGE: "privilege",
    SOURCE: "source",
    SOURCE_DATA: "sourceData"
}

export const EXTERNAL_VALUE = "E"
export const USER_VALUE = "U"
export const SUPERUSER_VALUE = "S"
export const ACTIVE = "A"
export const INACTIVE = "I"
export const DELETED = "D"

export const USER_STATUS = {
    [ACTIVE]: "Active",
    [INACTIVE]: "Inactive",
    [DELETED]: "Deleted"
}

export const ADD_USER_STATUS_OPTIONS = objToValueLabel(
    _.pick(USER_STATUS, [ACTIVE, INACTIVE])
)
export const EDIT_USER_STATUS_OPTIONS = objToValueLabel(USER_STATUS)

export const TAB_WARNINGS = {
    [TAB_PRACTICES]: "External users can't be assigned practices.",
    [TAB_PANELS]: "External users can't be assigned panels."
}

// TODO all of this tag stuff should probably be moved to the same place as ACCESS_PRIVILEGES
export const TAG_SOURCES = {
    PRIVATE: "PRIVATE",
    OWNER: "OWNER",
    EXTERNAL: "EXTERNAL", // this isn't a real source, but it's used occasionally to prevent external users from having edit access to other people's tags. Its position here represents its place in the order of precedence.
    SYSTEM: "SYSTEM",
    SUPERUSER: "SUPERUSER",
    PRACTICE: "PRACTICE",
    MANUAL: "MANUAL"
}

export const TAG_SOURCES_SORT_ORDER = [
    TAG_SOURCES.PRIVATE,
    TAG_SOURCES.OWNER,
    TAG_SOURCES.EXTERNAL,
    TAG_SOURCES.SYSTEM,
    TAG_SOURCES.SUPERUSER,
    TAG_SOURCES.PRACTICE,
    TAG_SOURCES.MANUAL
]
export const TAG_SOURCES_SORT_INDICES = _.invert(TAG_SOURCES_SORT_ORDER)

export const TAG_SOURCE_TEXT = {
    [TAG_SOURCES.PRIVATE]: {
        label: "Owner (Private)",
        tooltip:
            "As this private tag's owner, only you can view and edit the tag."
    },
    [TAG_SOURCES.OWNER]: {
        label: "Owner",
        tooltip: "A tag's owner can always view and edit the tag."
    },
    [TAG_SOURCES.EXTERNAL]: {
        label: "External",
        tooltip: "External users can't edit tags owned by other users."
    },
    [TAG_SOURCES.SYSTEM]: {
        label: "System",
        tooltip: {
            [ACCESS_PRIVILEGES.READ]:
                "Everyone but external users can always view system-generated tags.",
            [ACCESS_PRIVILEGES.EDIT]:
                "Superusers can always edit system-generated tags."
        }
    },
    [TAG_SOURCES.SUPERUSER]: {
        label: "Superuser",
        tooltip: "Superusers can always view all public tags."
    },
    [TAG_SOURCES.PRACTICE]: {
        label: "Practice",
        tooltip:
            "Tags granted to a practice are passed on to all users in that practice."
    },
    [TAG_SOURCES.MANUAL]: {
        label: "Shared",
        tooltip: "This tag was manually shared."
    }
}

export const PRIVILEGE_CHANGES = {
    [ACCESS_PRIVILEGES.READ]: {
        [ACCESS_PRIVILEGES.READ]: null,
        [ACCESS_PRIVILEGES.EDIT]: ACCESS_PRIVILEGES.EDIT
    },
    [ACCESS_PRIVILEGES.EDIT]: {
        [ACCESS_PRIVILEGES.READ]: null,
        [ACCESS_PRIVILEGES.EDIT]: ACCESS_PRIVILEGES.READ
    }
}

export const initialState = {
    addPracticeModalOpen: false,
    editPracticeModalOpen: false,
    addUserModalOpen: false,
    editUserModalOpen: false,
    saving: false,
    userTableUpdateCount: 0, // we increment this every time the user table needs an update, to trigger a requery from a fetcher
    selectedUserUpdateCount: 0, // ditto, but every time we need to update the selected user
    selectedPractices: [],
    selectedPractice: {
        name: "",
        id: 0,
        regionId: null
    },
    selectedUserId: undefined,
    confirmModalOpen: false,
    confirmMessage: ""
}

export const initialValues = {
    [USER_FIELDS.USER_UNITS]: [],
    [USER_FIELDS.ADMIN_UNITS]: [],
    [USER_FIELDS.PROVIDERS]: [],
    [USER_FIELDS.PRIMARY_PRACTICE]: 0,
    [USER_FIELDS.TAGS]: {},
    [USER_FIELDS.NEW_PASSWORD]: "",
    [USER_FIELDS.CONFIRM_PASSWORD]: ""
}

export const PRACTICE_ZERO = {
    [PRACTICE_TABLE_KEYS.PRACTICE_ID]: 0,
    [PRACTICE_TABLE_KEYS.STATUS]: "A",
    [PRACTICE_TABLE_KEYS.PRACTICE_NAME]: ""
}
