import { STATE_ABBREVS } from "../constants"

export const NAME = "REPORT_LIBRARY"
export const AVAILABLE_REPORTS = "AVAILABLE_REPORTS"
export const CURRENT_REPORT = "CURRENT_REPORT"

export const SET_LIBRARY_FILTERS = "SET_LIBRARY_FILTERS"
export const EXPORT_LIBRARY = "EXPORT_LIBRARY"
export const PRINT_LIBRARY = "PRINT_LIBRARY"

export const REPORT_LIBRARY_FILTERS_FORM = "REPORT_LIBRARY_FILTERS_FORM"

export const PATIENT_ID = "patient_id"
export const PATIENT_NAME = "Patient Name"

export const DATE = "DATE"
export const TIMESTAMP = "TIMESTAMP"
export const INT4 = "INT4"
export const INT8 = "INT8"
export const NUMERIC = "NUMERIC"
export const TEXT = "TEXT"

export const DATE_TYPES = [DATE, TIMESTAMP]
export const BOOLEAN_TYPES = ["BOOL", "BOOLEAN"]
export const INTEGER_TYPES = [INT4, INT8]
export const DECIMAL_TYPES = [NUMERIC]
export const ALL_NUMERIC_TYPES = [...INTEGER_TYPES, ...DECIMAL_TYPES]
export const ALL_PAIRED_TYPES = [...DATE_TYPES, ...ALL_NUMERIC_TYPES]

const COMPLIANCE = ["Compliant", "Non-Compliant"]
const STATUS = ["Completed", "Pending", "Refused"]

export const FIXED_OPTIONS = {
    "patient state": STATE_ABBREVS,
    state: STATE_ABBREVS,
    status: STATUS
}

export const DROPDOWN_OPTION_KEYS = {
    "care manager": "usersNameOnly",
    "care managers": "usersNameOnly",
    "discharge facility": "facilities",
    facility: "facilities",
    gender: "genders",
    "patient gender": "genders",
    "hcc stratification": "riskRank",
    payer: "payers",
    pcp: "pcps",
    "pcp name": "pcps",
    "pcp practice": "units",
    "pcp provider group": "units",
    "attributed pcp": "pcps",
    practice: "units",
    "practice unit": "units",
    product: "productClasses",
    provider: "pcps",
    "risk rank": "riskRank",
    source: "sources",
    "source type": "sourceTypes",
    stratification: "riskRank"
}

export const REGEX_OPTION_KEYS = {
    "^measure:": COMPLIANCE
}

export const initialState = {}
