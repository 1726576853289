import React, { useCallback } from "react"
import { arrayMove } from "react-sortable-hoc"
import _ from "lodash"
import { findAndUpdate } from "../../utils"
import { SortableList } from "./SortableList"

const selectByKey = (items, key) =>
    findAndUpdate(items, item => ({ ...item, selected: !item.selected }), {
        key
    })

const getNewFreezeCount = (freezeCount, oldIndex, newIndex) =>
    oldIndex > freezeCount && newIndex <= freezeCount
        ? freezeCount + 1
        : oldIndex < freezeCount && newIndex >= freezeCount
        ? freezeCount - 1
        : freezeCount

export const TableSettingsList = ({
    options,
    freezeOptions,
    setOptions,
    noFreeze
}) => {
    const onSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            if (noFreeze) {
                setOptions(arrayMove(options, oldIndex, newIndex), [])
                return
            }

            const freezeCount = getNewFreezeCount(
                freezeOptions.length,
                oldIndex,
                newIndex
            )

            const mergedOptions = _.concat(freezeOptions, null, options) // null stands for the spacer element
            const newOrdering = arrayMove(mergedOptions, oldIndex, newIndex)

            const newOptions = _.drop(newOrdering, freezeCount + 1) // +1 to skip the null spacer element
            const newFreezeOptions = _.take(newOrdering, freezeCount)

            setOptions(newOptions, newFreezeOptions)
        },
        [options, freezeOptions, setOptions, noFreeze]
    )

    const toggleItem = useCallback(
        key => {
            setOptions(
                selectByKey(options, key),
                selectByKey(freezeOptions, key)
            )
        },
        [options, freezeOptions, setOptions]
    )

    return (
        <SortableList
            axis="x"
            useDragHandle
            helperClass="sortableHelper"
            items={options}
            freezeItems={freezeOptions}
            onSortEnd={onSortEnd}
            toggleItem={toggleItem}
            noFreeze={noFreeze}
        />
    )
}
