import React, { useCallback } from "react"
import { Card, Col, Row } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import { AddComment } from "./AddComment"
import Comment from "./Comment"

const Comments = function({ data: { comments }, error, loading, saveComment }) {
    const submit = useCallback(
        e => {
        e.preventDefault()
        if (e.target.commentInput.value !== "") {
                saveComment(e.target.commentInput.value)
            e.target.commentInput.value = ""
        }
        },
        [saveComment]
        )

        return (
            <Card className="poc-comments">
                <Card.Header>
                    <CardTitle>Comments</CardTitle>
                </Card.Header>
                <Row className="card-body">
                    <Col md={6}>
                    <AddComment onSubmit={submit} />
                    </Col>
                    <Col md={6}>
                    <LEHOC
                        loading={loading}
                        error={error}
                        list={comments}
                    >
                        <ul className="poc-comments-list" id="posted-comments">
                            {(comments || []).map(item => (
                                <Comment key={item.userCommentId} item={item} />
                            ))}
                            </ul>
                        </LEHOC>
                    </Col>
                </Row>
            </Card>
        )
    }

export default Comments
