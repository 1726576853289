import * as C from "./constants"

export const addNewMeasures = payload => ({ type: C.ADD_NEW_MEASURES, payload })

export const saveMeasures = payload => ({ type: C.SAVE_MEASURES, payload })

export const startEditing = payload => ({ type: C.START_EDITING, payload })
export const stopEditing = payload => ({ type: C.STOP_EDITING, payload })
export const saveEdits = payload => ({ type: C.SAVE_EDITS, payload })
export const updateHistoricalItemSuccess = payload => ({
    type: C.UPDATE_HISTORICAL_ITEM,
    payload
})

export const removeHistoricalItem = payload => ({
    type: C.REMOVE_HISTORICAL_ITEM,
    payload
})

export const updateAddMeasureFilter = payload => ({
    type: C.UPDATE_ADD_MEASURE_FILTER,
    payload
})

export const markMeasureSaving = payload => ({
    type: C.MARK_MEASURE_SAVING,
    payload
})
export const markMeasureDone = payload => ({
    type: C.MARK_MEASURE_DONE,
    payload
})

export const hideSnackbar = () => ({ type: C.HIDE_SNACKBAR })
export const showSnackbar = () => ({ type: C.SHOW_SNACKBAR })
