export const FETCH_PRACTICES = "FETCH_PRACTICES"
export const FETCH_PRACTICES_SUCCESS = "FETCH_PRACTICES_SUCCESS"
export const FETCH_PRACTICES_FAILURE = "FETCH_PRACTICES_FAILURE"
export const SELECTED_PRACTICES_CHANGE = "SELECTED_PRACTICES_CHANGE"
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS"
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE"

export const ADD_PRACTICE = "ADD_PRACTICE"
export const ADD_PRACTICE_SUCCESS = "ADD_PRACTICE_SUCCESS"
export const ADD_PRACTICE_FAILURE = "ADD_PRACTICE_FAILURE"
export const SET_PRACTICE_STATUS = "SET_PRACTICE_STATUS"
export const SET_PRACTICE_STATUS_SUCCESS = "SET_PRACTICE_STATUS_SUCCESS"
export const SET_PRACTICE_STATUS_FAILURE = "SET_PRACTICE_STATUS_FAILURE"
export const UPDATE_PRACTICE = "UPDATE_PRACTICE"
export const UPDATE_PRACTICE_SUCCESS = "UPDATE_PRACTICE_SUCCESS"
export const UPDATE_PRACTICE_FAILURE = "UPDATE_PRACTICE_FAILURE"

export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAILURE = "ADD_USER_FAILURE"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"
export const FETCH_USER = "FETCH_USER"
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE"

export const SHOW_ADD_PRACTICE_MODAL = "SHOW_ADD_PRACTICE_MODAL"
export const HIDE_ADD_PRACTICE_MODAL = "HIDE_ADD_PRACTICE_MODAL"
export const SHOW_EDIT_PRACTICE_MODAL = "SHOW_EDIT_PRACTICE_MODAL"
export const HIDE_EDIT_PRACTICE_MODAL = "HIDE_EDIT_PRACTICE_MODAL"

export const SHOW_ADD_USER_MODAL = "SHOW_ADD_USER_MODAL"
export const HIDE_ADD_USER_MODAL = "HIDE_ADD_USER_MODAL"
export const SHOW_EDIT_USER_MODAL = "SHOW_EDIT_USER_MODAL"
export const HIDE_EDIT_USER_MODAL = "HIDE_EDIT_USER_MODAL"

export const SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL"
export const HIDE_CONFIRM_MODAL = "HIDE_CONFIRM_MODAL"
export const CONFIRM_YES = "CONFIRM_YES"
export const CONFIRM_NO = "CONFIRM_NO"
