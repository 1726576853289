import _ from "lodash"
import { TAG_SOURCES } from "../admin/constants"
import { callOrTake, intersectionExists } from "../utils"
import * as C from "./constants"
import { tagSortPredicates } from "./helpers"

export const updateIfMatch = (ids, idField, update = {}) => item => {
    const values = callOrTake(item, update)
    return _.includes(ids, item[idField]) ? { ...item, ...values } : item
}

const updateUserManualPrivilege = newPrivilege => privilege => {
    if (privilege[C.TABLE_KEYS.SOURCE] !== TAG_SOURCES.MANUAL) {
        return privilege
    }
    if (!newPrivilege) {
        return null
    }

    return {
        ...privilege,
        [C.TABLE_KEYS.PRIVILEGE]: newPrivilege
    }
}

const updateUserManualPrivileges = (privileges, newPrivilege) => {
    const alreadyHasManualPrivilege = _.find(
        privileges,
        privilege => privilege[C.TABLE_KEYS.PRIVILEGE] === TAG_SOURCES.MANUAL
    )

    // if the user doesn't already have a manual privilege, add one if needed
    const newManualPrivilege =
        !newPrivilege || alreadyHasManualPrivilege
            ? null
            : {
                  [C.TABLE_KEYS.PRIVILEGE]: newPrivilege,
                  [C.TABLE_KEYS.SOURCE]: TAG_SOURCES.MANUAL
              }

    // and update the existing ones too
    return _.compact([
        ..._.map(privileges, updateUserManualPrivilege(newPrivilege)),
        newManualPrivilege
    ])
}

const updateUserPracticePrivileges = (
    privileges,
    newPrivilege,
    userPracticeIds,
    newPracticeIds
) => {
    const privilegesWithPracticesRemoved = _.reject(
        privileges,
        privilege =>
            privilege[C.TABLE_KEYS.SOURCE] === TAG_SOURCES.PRACTICE &&
            _.includes(newPracticeIds, privilege[C.TABLE_KEYS.SOURCE_DATA])
    )
    if (!newPrivilege) {
        return privilegesWithPracticesRemoved
    }
    const newPracticePrivileges = _.map(
        _.intersection(userPracticeIds, newPracticeIds),
        unitId => ({
            [C.TABLE_KEYS.PRIVILEGE]: newPrivilege,
            [C.TABLE_KEYS.SOURCE]: TAG_SOURCES.PRACTICE,
            [C.TABLE_KEYS.SOURCE_DATA]: unitId
        })
    )
    return [...privilegesWithPracticesRemoved, ...newPracticePrivileges]
}

export const updateUserPrivileges = body => user => {
    const usersWereChanged = _.includes(
        body.userIds,
        user[C.TABLE_KEYS.USER_ID]
    )
    const practicesWereChanged = intersectionExists(
        body.unitIds,
        user[C.TABLE_KEYS.PRACTICE_IDS]
    )

    // manual user privileges
    const privilegesWithManual = usersWereChanged
        ? updateUserManualPrivileges(user.privileges, body.privilege)
        : user.privileges

    // practice-granted user privileges
    const privilegesWithPractices = practicesWereChanged
        ? updateUserPracticePrivileges(
              privilegesWithManual,
              body.privilege,
              user[C.TABLE_KEYS.PRACTICE_IDS],
              body.unitIds
          )
        : privilegesWithManual

    // put it all together
    const privileges = _.sortBy(privilegesWithPractices, tagSortPredicates)

    return { ...user, error: false, privileges }
}

// transform after a fetch

export const acceptTagUsers = users =>
    _.values(_.groupBy(users, C.TABLE_KEYS.USER_ID)).map(userRows => {
        const userData = _.pick(
            _.head(userRows), // every row should have the same data, so let's use the first
            [
                C.TABLE_KEYS.USER_ID,
                C.TABLE_KEYS.USER,
                C.TABLE_KEYS.ACCESS_LEVEL,
                C.TABLE_KEYS.PRACTICE,
                C.TABLE_KEYS.PRACTICE_IDS
            ]
        )
        const userPrivileges = userRows.map(row =>
            _.pick(row, [
                C.TABLE_KEYS.PRIVILEGE,
                C.TABLE_KEYS.SOURCE,
                C.TABLE_KEYS.SOURCE_DATA
            ])
        )
        const privileges = _.sortBy(
            _.filter(userPrivileges, C.TABLE_KEYS.PRIVILEGE), // don't need to include null-privilege rows now
            tagSortPredicates
        )
        return {
            ...userData,
            privileges
        }
    })
