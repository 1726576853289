import _ from "lodash"
import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SHOW_USER_TAGS_MODAL]: state => ({ ...state, open: true }),
    [C.HIDE_USER_TAGS_MODAL]: state => ({
        ...state,
        ...C.initialState
    }),
    [C.SELECT_TAG]: (state, action) => ({
        ...state,
        currentTag: _.pick(action.payload, [
            C.TABLE_KEYS.TAG_ID,
            C.TABLE_KEYS.TAG_NAME,
            C.TABLE_KEYS.PUBLIC,
            C.TABLE_KEYS.OWNER_ID
        ])
    }),
    [C.EDIT_TAG]: state => ({
        ...state,
        editing: true
    }),
    [C.CANCEL_EDIT_TAG]: state => ({
        ...state,
        editing: false,
        saving: false,
        currentTag:
            state.currentTag[C.TABLE_KEYS.TAG_ID] === C.NEW_TAG_ID
                ? {
                      ...state.currentTag,
                      [C.TABLE_KEYS.TAG_ID]: null,
                      [C.TABLE_KEYS.TAG_NAME]: null
                  }
                : state.currentTag
    }),
    [C.SAVE_TAG]: state => ({ ...state, saving: true }),
    [C.CANCEL_SAVE_TAG]: state => ({ ...state, saving: false }),
    [C.SAVE_TAG_COMPLETE]: (state, { payload }) => ({
        ...state,
        currentTag: {
            ...state.currentTag,
            ..._.pick(payload, [C.TABLE_KEYS.TAG_NAME, C.TABLE_KEYS.PUBLIC])
        }
    }),
    [C.DELETE_TAG]: state => ({
        ...state,
        saving: true
    }),
    [C.DELETE_TAG_COMPLETE]: state => ({
        ...state,
        saving: false,
        editing: false,
        currentTag: {}
    }),
    [C.SET_TAG_PRIVILEGES]: (state, action) => ({
        ...state,
        savingUsers: _.union(state.savingUsers, action.payload.userIds),
        savingPractices: _.union(state.savingPractices, action.payload.unitIds)
    }),
    [C.SET_TAG_PRIVILEGES_COMPLETE]: (state, action) => ({
        ...state,
        savingUsers: _.difference(state.savingUsers, action.payload.userIds),
        savingPractices: _.difference(
            state.savingPractices,
            action.payload.unitIds
        )
    })
})

export default reducer
