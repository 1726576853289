import React from "react"
import ColCard from "../../../core/ColCard"
import AddPracticeModal from "./AddPracticeModal"
import EditPracticeModal from "./EditPracticeModal"
import PracticeTable from "./PracticeTable"
import ConfirmModal from "./ConfirmModal"

const PracticePanel = props => (
    <ColCard xl={4} className="admin-practices">
        <AddPracticeModal
            show={props.addPracticeModalOpen}
            hideAddPracticeModal={props.hideAddPracticeModal}
            addPractice={props.addPractice}
        />
        <PracticeTable
            loading={props.loading}
            error={props.error}
            roles={props.roles}
            practices={props.practices}
            selected={props.selectedPractices}
            changeSelectedPractices={props.changeSelectedPractices}
            setPracticeStatus={props.setPracticeStatus}
            showEditPracticeModal={props.showEditPracticeModal}
            showAddPracticeModal={props.showAddPracticeModal}
        />
        <EditPracticeModal
            show={props.editPracticeModalOpen}
            hideEditPracticeModal={props.hideEditPracticeModal}
            updatePractice={props.updatePractice}
        />
        <ConfirmModal
            cancel={props.cancelConfirmation}
            confirm={props.acknowledgeConfirmation}
        />
    </ColCard>
)

export default PracticePanel
