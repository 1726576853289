import React, { useState } from "react"
import { Card } from "react-bootstrap"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import { SelectField } from "../../core/CustomFields"
import { tableFactory } from "../../table"
import { COLUMNS } from "../columns"
import * as C from "../constants"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: COLUMNS
})

export const Coverage = ({
    categories = [],
    data: { dataCoverage },
    exportCsv,
    printPdf
}) => {
    // once the categories are loaded, set the dropdown to the first one in the list
    const [value, onChange] = _.isEmpty(categories)
        ? []
        : useState(categories?.[0]?.value)

    const list = _.filter(dataCoverage, { [C.TABLE_KEYS.CATEGORY]: value })

    return (
        <Card className="data_coverage card-table">
            <Card.Header>
                <Card.Title>Data Coverage</Card.Title>
                <SelectField
                    label="Category"
                    input={{ value, onChange }}
                    options={categories}
                    notClearable
                />
                <ExportIcon onClick={() => exportCsv(value)} />
                <PrintIcon onClick={() => printPdf(value)} />
                <Settings />
            </Card.Header>
            <Description />
            <Card.Body>
                <Table list={list} key={value} />
                {/*`key` prop forces rerender when the dropdown value changes*/}
            </Card.Body>
        </Card>
    )
}
