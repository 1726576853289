// noinspection JSValidateTypes

import React, { Component } from "react"
import ReactTable from "react-table"
import { Col, Row } from "react-bootstrap"

import LEHOC from "../../../core/LoadingErrorHOC"
import { TableDescription } from "../../../table"
import { matchesSearch } from "../../../utils"
import {
    USER_COLUMNS,
    USER_LIST_PRACTICE_COLUMNS,
    USER_LIST_TAG_COLUMNS
} from "../../columns"
import * as C from "../../constants"
import SearchBox from "../SearchBox"
import AddButton from "../AddButton"
import { practiceIsValid } from "./helpers"

// const Description = TableDescription(C.USERS)

const UserDetail = ({ original }) => {
    const practices = original[C.USER_TABLE_KEYS.PRACTICES].filter(
        practiceIsValid
    )
    const tags = original[C.USER_TABLE_KEYS.TAGS]
    return (
        <Row className="admin-user-detail">
            <Col xs={7}>
                <ReactTable
                    className="card"
                    data={practices}
                    columns={USER_LIST_PRACTICE_COLUMNS}
                    pageSize={practices.length}
                    showPagination={false}
                    noDataText="No practices"
                    defaultSorted={[
                        { id: C.USER_PRACTICE_KEYS.PRACTICE_NAME, desc: false }
                    ]}
                />
            </Col>
            <Col xs={5}>
                <ReactTable
                    className="card"
                    data={tags}
                    columns={USER_LIST_TAG_COLUMNS}
                    pageSize={tags.length}
                    showPagination={false}
                    noDataText="No tags"
                    defaultSorted={[
                        { id: C.TAG_COLUMNS.TAG_NAME, desc: false }
                    ]}
                />
            </Col>
        </Row>
    )
}

class UserTable extends Component {
    state = {
        filter: ""
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.users !== nextProps.users ||
            this.props.loading !== nextProps.loading ||
            this.props.error !== nextProps.error ||
            this.state.filter !== nextState.filter
        )
    }

    resolveData = () => {
        const { users, showEditUserModal } = this.props

        return this.filterData(users).map(row => ({
            ...row,
            showEditUserModal
        }))
    }

    // FIXME this doesn't work if you search for more than one word, e.g. first name and last
    filterData = data => {
        const { filter } = this.state
        return filter
            ? data.filter(
                  patient =>
                      matchesSearch(patient.firstName, filter) ||
                      matchesSearch(patient.lastName, filter) ||
                      matchesSearch(patient.loginId, filter)
              )
            : data
    }

    setFilter = filter => {
        if (filter === this.state.filter) {
            return
        }
        this.setState({ filter })
    }

    render() {
        const filteredData = this.resolveData()

        return (
            <React.Fragment>
                <TableDescription tableName={C.USERS} />
                <div className="admin-controls">
                    <AddButton
                        label="USER"
                        onClick={this.props.showAddUserModal}
                    />
                    <SearchBox
                        onChange={this.setFilter}
                        placeholder="Search for User"
                        cosmeticButton
                    />
                </div>
                <LEHOC loading={this.props.loading} error={this.props.error}>
                    <ReactTable
                        className="admin-users-table"
                        data={filteredData}
                        noDataText="No Users found!"
                        columns={USER_COLUMNS}
                        SubComponent={UserDetail}
                        defaultSorted={[
                            { id: "firstName", desc: false },
                            { id: "lastName", desc: false }
                        ]}
                        pageSize={filteredData.length}
                        showPagination={false}
                    />
                </LEHOC>
            </React.Fragment>
        )
    }
}

export default UserTable
