import _ from "lodash"
import * as C from "./constants"

const { TAG_NAME, PUBLIC } = C.FORM_FIELDS
const { TAG_ID, OWNER_ID } = C.TABLE_KEYS

const toLower = (str = "") => str.toLowerCase()

export const uniqueName = (tagName, allValues, props) => {
    const [tagId, ownerId, isPublic] = _.at(allValues, [
        TAG_ID,
        OWNER_ID,
        PUBLIC
    ])
    const { tags } = props.data
    const tagNameLower = toLower(tagName)

    if (_.isEmpty(tagName)) {
        return "Tag name is required"
    }

    if (
        !!isPublic &&
        tags.some(
            tag =>
                tag[PUBLIC] &&
                tag[TAG_ID] !== tagId &&
                toLower(tag[TAG_NAME]) === tagNameLower
        )
    ) {
        return "There is already a public tag with this name"
    }

    // private tags have slightly different requirements
    if (
        !isPublic &&
        tags.some(
            tag =>
                !tag[PUBLIC] &&
                tag[TAG_ID] !== tagId &&
                tag[OWNER_ID] === ownerId &&
                toLower(tag[TAG_NAME]) === tagNameLower
        )
    ) {
        return "You already have a private tag with this name"
    }
}
