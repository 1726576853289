import moment from "moment"
import { DATE_STRING, SERVER_DATE_STRING } from "../dates"
import * as C from "./constants"

const PENDING_ID = 147

export const validateNewLinkage = (values, communityLinkages) => {
    if (!values || !values[C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY])
        return "Category is Required"
    if (!values[C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION])
        return "Location is Required"
    if (!values[C.ADD_COMMUNITY_LINKAGE_FIELDS.STATUS])
        return "Status is Required"
    if (!values[C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE]) return "Date is Required"

    const sameCategory = communityLinkages.filter(
        item =>
            item.srvCatCodeDescId ===
            values[C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY]
    )[0]

    if (sameCategory && sameCategory.srvStatusCodeDescId === PENDING_ID) {
        return `${sameCategory.srvCatDescription} is already pending`
    }

    if (
        sameCategory &&
        sameCategory.communityLocationId ===
            values[C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION] &&
        moment
            .utc(sameCategory.srvDt, SERVER_DATE_STRING)
            .isSame(
                moment.utc(
                    values[C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE],
                    DATE_STRING
                )
            )
    ) {
        return `'${sameCategory.srvCatDescription} - ${
            sameCategory.communityLocation
        } - ${values[C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE]}' already exists`
    }
    return null
}
