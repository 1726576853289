import React from "react"
import { Card } from "react-bootstrap"

export const NoReportSelected = () => (
    <Card className="card-table">
        <Card.Body
            style={{
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <span style={{ fontSize: "18px" }}>No Report Selected</span>
        </Card.Body>
    </Card>
)
