import { connect } from "react-redux"
import * as reduxActions from "../actions"
import * as C from "../constants"
import SelectCell from "./SelectCell"

export const SelectStatusCell = ({ setTcmStatus, ...props }) => {
    const handleSubmit = status =>
        setTcmStatus(status, props.rowData[C.TABLE_KEYS.TCM_TRACKER_ID])
    return <SelectCell {...props} onSubmit={handleSubmit} />
}

export default connect(null, reduxActions)(SelectStatusCell)
