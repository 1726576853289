import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SET_LIBRARY_FILTERS]: (state, { reportId, filters }) => ({
        ...state,
        [reportId]: filters
    })
})

export default reducer
