import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { Hoverlay } from "../../core/Hoverlay"
import * as actions from "../../cost_report/actions"
import { categoryTreeSelector } from "../../cost/selectors"
import * as C from "../constants"

const CostReportLink = ({
    cellData,
    rowData,
    setFilters,
    filters,
    categoryTree,
    ...props
}) => (
    <Hoverlay tooltip={cellData}>
        <Link
            to="/reports/cost"
            onClick={() => {
                const { tableKey, filter } = C.ID_FIELD_LOOKUP[props.dataKey]
                return setFilters({
                    filters: {
                        ...filters,
                        ...C.PERIOD_MONTH_STRINGS[filters[C.FILTERS.PERIOD]],
                        [filter]: [rowData[tableKey]]
                    },
                    categoryTree
                })
            }}
        >
            {cellData}
        </Link>
    </Hoverlay>
)

export default connect(
    state => ({ ...state[C.NAME], categoryTree: categoryTreeSelector(state) }),
    actions
)(CostReportLink)
