import { connect } from "react-redux"
import { reduxForm } from "redux-form"

import { getResourceData } from "../../../core/fetcher"
import { FILTER_OPTIONS } from "../../../constants"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import { EditDemographicsModal } from "./EditDemographicsModal"

export default connect(
    state => ({
        ...state[C.NAME],
        options: getResourceData(FILTER_OPTIONS)(state),
        initialValues: getResourceData(C.NAME)(state)
    }),
    reduxActions
)(
    reduxForm({
        form: C.EDIT_PATIENT_DEMOGRAPHICS_FORM,
        enableReinitialize: true
    })(EditDemographicsModal)
)
