import { DollarsCents, LongNumber, Percent } from "../core/CellRenderers"
import {
    PatientListLinkCell,
    MoneyBreakdown,
    RateBreakdown
} from "../qsr_dashboard/cell_renderers"
import * as PLC from "../patient_list/constants"
import { columnBuilder } from "../table"
import { formatLongNumber } from "../numbers"
import { ALIGNMENTS, MODULE_IDS } from "../constants"
import * as C from "./constants"

const column = columnBuilder(C.TABLE_TRANSLATION)

const SHARED_COLUMNS = [
    column(C.TABLE_KEYS.RATE_TO_DATE, {
        cellRenderer: Percent,
        width: 75
    }),
    column(C.TABLE_KEYS.NETWORK_RATE, {
        cellRenderer: Percent,
        width: 75
    }),
    column(C.TABLE_KEYS.GOAL_RATE, {
        cellRenderer: Percent,
        width: 75
    }),
    column(C.TABLE_KEYS.ELIGIBLE, {
        hover: "Total Eligible",
        cellRenderer: LongNumber,
        width: 90
    }),
    column(C.TABLE_KEYS.MET, {
        cellRenderer: LongNumber,
        width: 60
    }),
    column(C.TABLE_KEYS.UNMET, {
        cellFormatter: formatLongNumber,
        cellRenderer: PatientListLinkCell,
        columnData: {
            store: C.NAME,
            extraFilters: ({ rowData }) => ({
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_SUMMARY,
                [PLC.FILTERS.HCC_CONDITIONS_MET]: C.COMPLIANCE.NOT_COMPLIANT,
                [PLC.FILTERS.HCC_CONDITIONS]: [
                    rowData[C.TABLE_KEYS.CONDITION_ID]
                ]
            })
        },
        width: 80
    }),
    column(C.TABLE_KEYS.RAF_AVAILABLE, {
        selected: false,
        hover: "Risk Adjustment Factor Available",
        width: 115
    }),
    column(C.TABLE_KEYS.RAF_EARNED, {
        selected: false,
        hover: "Risk Adjustment Factor Earned",
        width: 100
    }),
    column(C.TABLE_KEYS.RAF_REMAINING, {
        selected: false,
        hover: "Risk Adjustment Factor Remaining",
        width: 120
    }),
    column(C.TABLE_KEYS.RAF_AVAILABLE_USD, {
        module: MODULE_IDS.RISK_REVENUE,
        selected: false,
        hover: "Risk Adjustment Factor Dollars Remaining",
        cellRenderer: DollarsCents,
        width: 120,
        align: ALIGNMENTS.RIGHT
    }),
    column(C.TABLE_KEYS.RAF_EARNED_USD, {
        module: MODULE_IDS.RISK_REVENUE,
        selected: false,
        hover: "Risk Adjustment Factor Dollars Remaining",
        cellRenderer: DollarsCents,
        width: 120,
        align: ALIGNMENTS.RIGHT
    }),
    column(C.TABLE_KEYS.RAF_REMAINING_USD, {
        module: MODULE_IDS.RISK_REVENUE,
        selected: false,
        hover: "Risk Adjustment Factor Dollars Remaining",
        cellRenderer: DollarsCents,
        width: 130,
        align: ALIGNMENTS.RIGHT
    }),
    column(C.TABLE_KEYS.INCENTIVE_DIFFERENCE, {
        width: 175,
        cellFormatter: formatLongNumber,
        cellRenderer: PatientListLinkCell,
        columnData: {
            extraFilters: ({ rowData }) => ({
                [PLC.FILTERS.INCENTIVE_STATUS]: ["MX"],
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_SUMMARY,
                [PLC.FILTERS.HCC_CONDITIONS]: [
                    rowData[C.TABLE_KEYS.CONDITION_ID]
                ]
            })
        },
        selected: false
    }),
    column(C.TABLE_KEYS.RATE_BREAKDOWN, {
        cellRenderer: RateBreakdown,
        columnData: {
            currentRateKey: C.TABLE_KEYS.RATE_TO_DATE,
            goalRateKey: C.TABLE_KEYS.GOAL_RATE,
            neededKey: C.TABLE_KEYS.NEEDED,
            metKey: C.TABLE_KEYS.MET,
            eligibleKey: C.TABLE_KEYS.ELIGIBLE
        },
        width: 235,
        flexGrow: 1,
        disableSort: true
    }),
    column(C.TABLE_KEYS.MONEY_BREAKDOWN, {
        module: MODULE_IDS.RISK_REVENUE,
        cellRenderer: MoneyBreakdown,
        columnData: {
            availableKey: C.TABLE_KEYS.RAF_AVAILABLE_USD,
            earnedKey: C.TABLE_KEYS.RAF_EARNED_USD
        },
        width: 235,
        flexGrow: 1,
        disableSort: true
    })
]

export const NETWORK_COLUMNS = [
    column(C.TABLE_KEYS.CONDITION_NAME, {
        cellRenderer: PatientListLinkCell,
        columnData: {
            overlay: true,
            store: C.NAME,
            extraFilters: ({ rowData }) => ({
                [PLC.FILTERS.HCC_CONDITIONS]: [
                    rowData[C.TABLE_KEYS.CONDITION_ID]
                ], // note that this is wrapped in a list
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_SUMMARY
            })
        },
        className: "dashboard-condition-name",
        width: 250,
        flexGrow: 1
    }),
    ...SHARED_COLUMNS
]

export const PRACTICE_COLUMNS = [
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        cellRenderer: PatientListLinkCell,
        columnData: {
            overlay: true,
            store: C.NAME,
            extraFilters: {
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_SUMMARY
            }
        },
        width: 150,
        flexGrow: 1
    }),
    ...SHARED_COLUMNS
]

export const PROVIDER_COLUMNS = [
    column(C.TABLE_KEYS.PROVIDER_NAME, {
        cellRenderer: PatientListLinkCell,
        columnData: {
            overlay: true,
            store: C.NAME,
            extraFilters: {
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_SUMMARY
            }
        },
        width: 150,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        width: 150,
        flexGrow: 1
    }),
    ...SHARED_COLUMNS
]
