import React from "react"
import { Hoverlay } from "../../core/Hoverlay"

const NotAMember = ({ message, ...props }) => (
    <Hoverlay className="tooltip-orange" tooltip={message || "Not a Member"}>
        <span className="fa indicator-not_a_member" {...props} />
    </Hoverlay>
)

export default NotAMember
