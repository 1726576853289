import { all, call, select, takeEvery } from "redux-saga/effects"
import { getSortedColumns } from "../selectorUtils"
import { getFilename, urlWithParams } from "../utils"
import * as C from "./constants"
import { getTableName } from "./helpers"
import { downloadFile } from "../api"

const getRiskDetails = (state, columnMapping = {}) => {
    const { filters, aggregation } = state[C.NAME]
    const tableName = getTableName(aggregation)
    const sortedColumns = getSortedColumns(
        tableName,
        undefined,
        col => columnMapping[col.key] || col.key
    )(state)
    return {
        ...filters,
        aggregation,
        ...sortedColumns
    }
}

function* getDashboardRisksReport(extension, columnMapping) {
    const details = yield select(getRiskDetails, columnMapping)

    const endpoint = urlWithParams(
        `/api/risk/dashboard/${details.aggregation}/${extension}`,
        details
    )
    yield* downloadFile(
        `Risk ${details.aggregation} summary ${extension.toUpperCase()}`,
        endpoint,
        getFilename(`risk_dashboard_${details.aggregation}`, extension)
    )
}

export function* printRisks() {
    yield* getDashboardRisksReport("pdf")
}

export function* exportRisks() {
    yield* getDashboardRisksReport("csv", {
        [C.TABLE_KEYS.RATE_BREAKDOWN]: [
            C.TABLE_KEYS.RATE_TO_DATE,
            C.TABLE_KEYS.GOAL_RATE,
            C.TABLE_KEYS.MET,
            C.TABLE_KEYS.ELIGIBLE
        ],
        [C.TABLE_KEYS.MONEY_BREAKDOWN]: [
            C.TABLE_KEYS.RAF_EARNED_USD,
            C.TABLE_KEYS.RAF_AVAILABLE_USD,
            C.TABLE_KEYS.RAF_REMAINING_USD
        ]
    })
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.PRINT_RISKS, printRisks)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_RISKS, exportRisks)
        })
    ])
}
