import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(
    {},
    {
        [C.SET_CMI_FILTERS]: (state, action) => ({
            ...state,
            filters: action.payload
        })
    }
)

export default reducer
