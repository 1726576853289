import React from "react"
import { SortDirection } from "react-virtualized"
import { Card } from "react-bootstrap"
import _ from "lodash"
import CardTitle from "../../core/CardTitle"
import LEHOC from "../../core/LoadingErrorHOC"
import { PrintPoc } from "../../batch_print/components"
import * as BC from "../../batch_print/constants"
import { tableFactory } from "../../table"
import { TABLE_COLUMNS } from "../columns"
import * as C from "../constants"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    sortBy: [C.TABLE_KEYS.PRINT_DATE],
    sortDirection: [SortDirection.DESC]
})

export default ({ loading, error, showPrint, data: { letters } }) => {
    return (
        <Card className="card-table poc-letters">
            <Card.Header className="card-header-group">
                <CardTitle>
                    Reminder Letter Details
                    {_.isEmpty(letters) ? " - No Data" : ""}
                </CardTitle>
                {showPrint && (
                    <PrintPoc
                        title="Print New Letter"
                        initialValues={{ [BC.PRINT_FIELDS.LETTER]: true }}
                    />
                )}
                <Settings className="poc-letter-settings" />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC loading={loading} error={error} list={letters}>
                    <Table list={letters} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}
