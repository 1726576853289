import React from "react"
import classNames from "classnames"
import { Card, Col, Collapse, Fade, Row } from "react-bootstrap"
import CardTitle from "../../core/CardTitle"
import PieChart from "./PieChart"
import BarChart from "./BarChart"
import Toggle from "../../core/Toggle"

export const ProductCharts = ({
    loading,
    error,
    show,
    onPivot,
    onUpdate,
    onShow,
    barData,
    pieData,
    headers,
    hidden,
    names,
    colors,
    toggle,
    barRef,
    pieRef
}) => (
    <Card id="product-payer-chart" className="patient-list-chart">
        <Card.Header>
            <button onClick={onShow} aria-expanded={show}>
                <span className="card-collapse-clickable">
                    {show ? (
                        <i className="fa fa-caret-up" />
                    ) : (
                        <i className="fa fa-caret-down" />
                    )}
                </span>
                <CardTitle>Product Information</CardTitle>
            </button>
            <Fade in={show} className="pull-right">
                <div>
                    <Toggle
                        className="pull-right"
                        name="pp_chart-pivot"
                        checked={!!toggle}
                        onChange={onPivot}
                    />
                    <label
                        className={classNames(
                            "pp_chart-pivot-label pull-right"
                        )}
                        htmlFor="pp_chart-pivot"
                    >
                        Pivot Bar Chart
                    </label>
                </div>
            </Fade>
        </Card.Header>
        <Collapse in={show}>
            <Row>
                <Col md={4}>
                    <PieChart
                        loading={loading}
                        error={error}
                        data={pieData}
                        headers={headers}
                        names={names}
                        colors={colors}
                        onUpdate={onUpdate}
                        forwardRef={pieRef}
                    />
                </Col>
                <Col md={8}>
                    <BarChart
                        loading={loading}
                        error={error}
                        data={barData}
                        headers={headers}
                        hidden={hidden}
                        names={names}
                        colors={colors}
                        onUpdate={onUpdate}
                        forwardRef={barRef}
                    />
                </Col>
            </Row>
        </Collapse>
    </Card>
)
