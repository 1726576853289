import React, { Component } from "react"
import { connect } from "react-redux"
import { change, Field, Fields } from "redux-form"
import classNames from "classnames"
import _ from "lodash"
import { SelectField } from "../../../../core/CustomFields"
import { findByValue } from "../../../../utils"
import { getSubmeasureIdName } from "../../../helpers"
import survey_definitions from "./AutoCalculated/survey_definitions"

export class SurveyFields extends Component {
    constructor(props) {
        super(props)

        const [results, questions] = _.partition(
            this.props.subMeasures,
            sm => sm.autoCalc
        )

        this.surveyQuestions = questions
        this.surveyQuestionNames = questions.map(this.getQuestionSubMeasureName)
        this.surveyResult = _.head(results) //should only have one item
        this.resultId = this.getQuestionSubMeasureName(this.surveyResult)
    }

    componentDidUpdate() {
        const updateResult = this.props[this.resultId].input.onChange

        if (this.shouldShowResults()) {
            const {
                result: resultOption,
                value: nextResValue
            } = this.getResult(this.props)
            if (!resultOption) {
                console.error(
                    'Invalid result value for survey "',
                    this.props.description + '":',
                    nextResValue
                )
                updateResult("Invalid value")
                return
            }
            const newCodeDescId = resultOption.id
            updateResult(newCodeDescId)
        } else {
            updateResult(undefined)
        }
    }

    getQuestionSubMeasureName = item =>
        getSubmeasureIdName(this.props.parentId)(item)

    getResult = fields => {
        const surveyDefinition =
            survey_definitions[this.resultId] || this.getDefaultResult
        return surveyDefinition(
            fields,
            this.surveyQuestions,
            this.surveyQuestionNames
        )
    }

    getDefaultResult = (fields, surveyQuestions, surveyQuestionNames) => {
        const values = surveyQuestions.map((cur, i) => {
            const submeasureName = surveyQuestionNames[i]
            const field = fields[submeasureName]
            const option =
                field &&
                cur.dropListOptions.find(
                    option => option.id === field.input.value
                )
            return Number(option?.value)
        })

        const value = _.sum(values)
        const max = _.last(this.surveyResult.dropListOptions)?.value
        const result = findByValue(this.surveyResult.dropListOptions, value)

        return { value, result, max }
    }

    shouldShowResults = () => {
        const surveyQuestionResults = _.pick(
            this.props,
            this.surveyQuestionNames
        )
        return _.every(surveyQuestionResults, sqr => sqr.input.value)
    }

    getResultText = () => {
        if (!this.shouldShowResults()) {
            return "Answer all questions to see the result"
        }
        const {
            result: { name },
            max,
            value,
            hideValue
        } = this.getResult(this.props)

        return hideValue
            ? name
            : !max
            ? `${name} - ${value}`
            : `${name} - ${value} / ${max}`
    }

    render() {
        return (
            <React.Fragment>
                <p style={{ padding: "20px", fontWeight: "bold" }}>
                    {this.props.description}
                </p>
                {this.surveyQuestions.map(subMeasure => (
                    <div key={subMeasure.id} className="survey_question">
                        <div className="question">{subMeasure.name}</div>
                        <SelectField
                            {...subMeasure}
                            {...this.props[
                                this.getQuestionSubMeasureName(subMeasure)
                            ]}
                            options={
                                subMeasure.dropListOptions
                                    ? subMeasure.dropListOptions.map(item => ({
                                          label: item.name,
                                          value: item.id
                                      }))
                                    : []
                            }
                        />
                        <div style={{ clear: "both" }} />
                    </div>
                ))}
                <div className="survey_question">
                    <div className="question">{this.surveyResult.name}</div>
                    <div>{this.getResultText()}</div>
                    <div style={{ clear: "both" }} />
                </div>
            </React.Fragment>
        )
    }
}

export class Survey extends Component {
    state = { open: false }
    toggle = () => this.setState({ open: !this.state.open })

    render() {
        const {
            subMeasures,
            change,
            dropListOptions,
            disabled,
            id,
            desc
        } = this.props

        return (
            <div
                className={classNames("survey-measure", {
                    disabled
                })}
            >
                <span
                    className="survey-toggle fa fa-pencil"
                    onClick={disabled ? () => {} : this.toggle}
                />
                <Field
                    {...this.props}
                    name={`id_${id}`}
                    component={SelectField}
                    options={
                        dropListOptions
                            ? dropListOptions.map(item => ({
                                  label: item.name,
                                  value: item.id
                              }))
                            : []
                    }
                />
                <div
                    className="survey"
                    style={this.state.open ? {} : { display: "none" }}
                >
                    <Fields
                        reduxFormChange={change}
                        description={desc}
                        parentId={id}
                        subMeasures={subMeasures}
                        names={subMeasures.map(getSubmeasureIdName(id))}
                        component={SurveyFields}
                    />
                </div>
            </div>
        )
    }
}

export default connect(null, { change })(Survey)
