import React from "react"
import { Hoverlay } from "../../core/Hoverlay"

const Deceased = props => (
    <Hoverlay className="tooltip-grey" tooltip="Deceased patient">
        <span className="fa indicator-deceased" {...props} />
    </Hoverlay>
)

export default Deceased
