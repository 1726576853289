import React from "react"
import { connect } from "react-redux"
import { reduxForm } from "redux-form"
import { Redirect } from "react-router-dom"
import _ from "lodash"
import {
    getMetaData,
    getResourceData,
    getResourceDataAsObject
} from "../../core/fetcher"
import LER from "../../core/LoadingErrorHOC"
import { ROLE_SUPERUSER } from "../../admin/constants"
import { FILTER_OPTIONS, SETTINGS } from "../../constants"
import * as reduxActions from "../actions"
import * as C from "../constants"
import { SiteSettings } from "./SiteSettings"

const SiteSettingsForm = reduxForm({
    form: C.SITE_SETTINGS_FORM,
    enableReinitialize: true
})(SiteSettings)

const SiteSettingsRedirect = ({ activeUserRoles, meta, ...props }) => {
    return _.includes(activeUserRoles, ROLE_SUPERUSER) ? (
        <LER {...meta}>
            <SiteSettingsForm {...props} />
        </LER>
    ) : (
        <Redirect to="/dashboard" />
    )
}

export default connect(state => {
    const settings = getResourceDataAsObject(SETTINGS)(state)
    const { modules = {}, organization = {}, roles = [] } = settings
    const { logo, ...orgData } = organization // storing the logo would slow down saving
    const moduleAccess = _.mapValues(modules, module => module.roles)

    return {
        meta: getMetaData(SETTINGS)(state),
        roles,
        modules,
        activeUserRoles: state.authentication.roles,
        productClasses: getResourceData(
            FILTER_OPTIONS,
            data => data.productClasses
        )(state),
        initialValues: {
            ...settings,
            [C.MODULES]: moduleAccess,
            [C.ORGANIZATION]: orgData
        }
    }
}, reduxActions)(SiteSettingsRedirect)
