import React from "react"
import { connect } from "react-redux"
import { getResourceData } from "../../core/fetcher"
import { FILTER_OPTIONS } from "../../constants"
import * as C from "../constants"
import * as reduxActions from "../actions"
import SelectCell from "./SelectCell"

export const SelectTCMCell = props => {
    const { dataKey, rowData, updateTCM } = props

    const handleSubmit = value =>
        updateTCM({
            tcmId: rowData[C.TCM_LOOKUP[dataKey]],
            field: dataKey,
            transitionId: rowData[C.TABLE_KEYS.TCM_TRACKER_ID],
            value
        })

    return <SelectCell {...props} onSubmit={handleSubmit} />
}

export default connect(
    (state, { cellData, columnData }) => ({
        options: getResourceData(
            FILTER_OPTIONS,
            data => data[columnData?.optionsKey]
        )(state)
    }),
    reduxActions
)(SelectTCMCell)
