import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest
} from "redux-saga/effects"
import { reset } from "redux-form"
import moment from "moment"
import _ from "lodash"
import { modifyResource } from "../core/fetcher"
import { ApiTypes, tryFetch } from "../api"
import { urlWithParams } from "../utils"
import { DATE_STRING, SERVER_DATE_STRING } from "../dates"
import { notifySimpleError, tryWithNotifications } from "../notifications"
import { idLookUpSelector } from "./selectors"
import { formatPercentile } from "./helpers"
import {
    insertNewMeasure,
    removeMeasureHistoricalItem,
    updateLastEntry,
    updateMeasureHistory,
    updateMetrics
} from "./transforms"
import * as C from "./constants"
import { hideSnackbar, markMeasureDone, markMeasureSaving } from "./actions"
import {
    measureErrorNotification,
    measureSuccessNotification
} from "./components/MeasureNotifications"

export function* newMeasures() {
    const new_measures = yield select(
        state => state.form[C.ADD_MEASURE_TREE_FORM].values.measures
    )
    const measureIds = new_measures.map(item => Number(item))
    yield* tryFetch(
        {
            url: urlWithParams(`/api/measures/detail`, { ids: measureIds })
        },
        function*(body) {
            for (let newData of body) {
                const newMeasure = { ...newData, hasRecentValue: false }
                yield put(
                    modifyResource({
                        name: C.RELEVANT_MEASURES_TREE,
                        dataTransform: data =>
                            insertNewMeasure(data, newMeasure)
                    })
                )
            }
        },
        notifySimpleError("Unable to get details for new measures")
    )
}

const formatMeasure = (
    form_values,
    lookUpId,
    patientId,
    indexedSubmeasures
) => idString => {
    const measureId = idString.slice(3)

    const formValue = form_values[idString]
    const formValueDate = form_values[`${idString}_date`]
    const serviceDt = moment
        .utc(formValueDate, DATE_STRING)
        .format(SERVER_DATE_STRING)

    const lookupItem = lookUpId[idString] || {}
    const dataType = lookupItem.dataType

    return {
        patientId,
        measureId,
        numericVal:
            idString !== "id_32" && // HARDCODED BMI PERCENTILE
            (dataType === C.NUMERIC || dataType === C.ALPHANUMERIC)
                ? Number(formValue)
                : null,
        textVal:
            idString === "id_32" // HARDCODED BMI PERCENTILE
                ? formatPercentile(formValue)
                : dataType === C.COMPOSITE || dataType === C.ALPHANUMERIC
                ? formValue
                : null,
        codeDescId:
            dataType === C.ENUMERATION
                ? formValue
                : dataType === C.CHECKBOX
                ? lookupItem.normalDropListOptions.reduce(
                      (acc, cur) => cur.id,
                      C.DEFAULT_CODE_DESC_ID
                  )
                : C.DEFAULT_CODE_DESC_ID,
        codeDesc:
            dataType === C.ENUMERATION
                ? Object.keys(lookupItem.dropListOptions).reduce(
                      (acc, cur) =>
                          Number(cur) === Number(formValue)
                              ? lookupItem.dropListOptions[cur]
                              : acc,
                      ""
                  )
                : "",
        serviceDt,
        subMeasures:
            indexedSubmeasures[measureId] &&
            indexedSubmeasures[measureId].map(sm => ({
                ...sm,
                serviceDt
            }))
    }
}

function* getMeasureValues() {
    const patientId = yield select(state => state.poc_id)
    const lookUpId = yield select(state => idLookUpSelector(state))
    const form_values = yield select(
        state => state.form[C.POC_MEASURE_FORM].values
    )
    const measures = Object.keys(form_values).filter(
        item =>
            !item.includes("_date") &&
            !item.includes("_sub_") &&
            !item.includes("_comp_") &&
            form_values[item]
    )

    const subMeasureKeys = Object.keys(form_values).filter(item =>
        item.includes("_sub_")
    )
    const listOfSubMeasures = subMeasureKeys.map(smKey => ({
        patientId,
        parentId: smKey.split("_")[1],
        measureId: smKey.split("_")[3],
        numericVal: null,
        textVal: null,
        codeDescId: form_values[smKey],
        subMeasures: null
    }))

    const compMeasureKeys = Object.keys(form_values).filter(item =>
        item.includes("_comp_")
    )

    const listOfCompMeasures = compMeasureKeys.map(compKey => ({
        patientId,
        parentId: compKey.split("_")[1],
        measureId: compKey.split("_")[3],
        numericVal: form_values[compKey],
        textVal: null,
        codeDescId: 0,
        subMeasures: null
    }))

    const indexedSubmeasures = _.groupBy(
        [...listOfSubMeasures, ...listOfCompMeasures],
        item => item.parentId
    )

    //mark measures loading
    for (const measure of measures) {
        const measureId = Number(measure.slice(3))
        yield put(markMeasureSaving(measureId))
    }

    // Format measures
    return measures.map(
        formatMeasure(form_values, lookUpId, patientId, indexedSubmeasures)
    )
}

function* markMeasuresDone(values) {
    for (const value of values) {
        const measureId = Number(value.measureId)
        yield put(markMeasureDone(measureId))
    }
}

export function* saveMeasureToServer() {
    const values = yield* getMeasureValues()
    const patientId = yield select(state => state.poc_id)

    yield put(hideSnackbar())

    yield* tryWithNotifications(
        {
            url: "/api/measures/value",
            method: ApiTypes.POST,
            body: { values }
        },
        function*({ metrics }) {
            // Update Locally
            for (let newValue of values) {
                // noinspection JSUnfilteredForInLoop
                const newMeasure = {
                    ...newValue,
                    ...metrics,
                    logDt: metrics.logDt,
                    hasRecentValue: true
                }
                yield put(
                    modifyResource({
                        name: C.RELEVANT_MEASURES_TREE,
                        dataTransform: data => updateLastEntry(data, newMeasure)
                    })
                )
            }

            yield put(
                modifyResource({
                    name: C.RELEVANT_MEASURES_TREE,
                    dataTransform: data => updateMetrics(data, metrics)
                })
            )

            yield put(reset(C.POC_MEASURE_FORM))
            yield* markMeasuresDone(values)
            return measureSuccessNotification(
                "The new measure values were saved successfully!"
            )
        },
        function*(error) {
            yield* markMeasuresDone(values)
            return measureErrorNotification(
                error,
                "Failed to save new measure values.",
                { patientId }
            )
        }
    )
}

export function* saveEdits(action) {
    const {
        measureValueLogId,
        values,
        dataType,
        dropListOptions,
        measureId,
        subMeasures
    } = action.payload
    const patientId = yield select(state => Number(state.poc_id))
    let numericVal = null
    let codeDescId = null
    let textVal = null
    switch (dataType) {
        case C.COMPOSITE:
            textVal = values[measureId]
            break
        case C.NUMERIC:
            numericVal = Number(values[measureId])
            break
        case C.CHECKBOX:
            codeDescId = dropListOptions[0].id
            break
        case C.ENUMERATION:
            codeDescId = Number(values[measureId])
            break
        default:
            numericVal = Number(values[measureId])
    }
    const data = {
        measureValueLogId,
        numericVal,
        textVal,
        codeDescId,
        subMeasures:
            subMeasures && subMeasures.map(item => ({ ...item, patientId })),
        patientId
    }

    yield put({ type: C.HIDE_SNACKBAR })

    yield* tryWithNotifications(
        {
            url: `/api/measures/history/${measureValueLogId}`,
            method: ApiTypes.PUT,
            body: { data },
            message: "Saving new value in measure history..."
        },
        function*(received) {
            const newData = {
                ...received,
                hasRecentValue: true
            }
            yield put(
                modifyResource({
                    name: C.RELEVANT_MEASURES_TREE,
                    dataTransform: data => updateLastEntry(data, newData)
                })
            )

            yield put(
                modifyResource({
                    name: C.MEASURE_HISTORY,
                    dataTransform: updateMeasureHistory(
                        received,
                        dropListOptions
                    )
                })
            )
            yield put(markMeasureDone(measureId))
            return measureSuccessNotification(
                "The measure value was updated successfully!"
            )
        },
        function*(error) {
            yield put(markMeasureDone(measureId))
            return measureErrorNotification(
                error,
                "Failed to update measure value.",
                data
            )
        }
    )
}

export function* removeMeasureHistoryItem(action) {
    const { measureValueLogId, patientId, measureId } = action.payload

    yield put({ type: C.HIDE_SNACKBAR })
    yield put(markMeasureSaving(measureId))

    yield* tryWithNotifications(
        {
            url: `/api/measures/history/${measureValueLogId}/patient/${patientId}`,
            method: ApiTypes.DELETE,
            message: "Deleting value from measure history..."
        },
        function*({ metrics }) {
            yield put(
                modifyResource({
                    name: C.MEASURE_HISTORY,
                    dataTransform: removeMeasureHistoricalItem(
                        measureValueLogId
                    )
                })
            )
            yield put(
                modifyResource({
                    name: C.RELEVANT_MEASURES_TREE,
                    dataTransform: data => updateMetrics(data, metrics)
                })
            )

            yield put(markMeasureDone(measureId))
            return measureSuccessNotification(
                "The measure value was deleted successfully!"
            )
        },
        function*(error) {
            yield put(markMeasureDone(measureId))
            return measureErrorNotification(
                error,
                "Failed to delete measure value.",
                { patientId }
            )
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeLatest(C.SAVE_MEASURES, saveMeasureToServer)
        }),
        call(function*() {
            yield takeLatest(C.ADD_NEW_MEASURES, newMeasures)
        }),
        call(function*() {
            yield takeEvery(C.SAVE_EDITS, saveEdits)
        }),
        call(function*() {
            yield takeEvery(C.REMOVE_HISTORICAL_ITEM, removeMeasureHistoryItem)
        })
    ])
}
