import React from "react"
import { Hoverlay } from "../../core/Hoverlay"

export const Progress = ({
    numberMet,
    numPatNeededForGoal,
    measureName,
    numBillCdNeededForGoal
}) => {
    let percent = (100 * numberMet) / numPatNeededForGoal
    if (percent > 100) {
        percent = 100
    }
    let color = "#00a651"
    if (percent < 99) color = "#f7931e"
    if (percent < 50) color = "#c03"
    return (
        <div>
            <div className="progress-bar">
                <div
                    style={{
                        width: `${percent}%`,
                        backgroundColor: color
                    }}
                />
            </div>
            <div className="progress-description">
                <Hoverlay
                    tooltip={`Patients that have had ${
                        numBillCdNeededForGoal === 1
                            ? "one or more"
                            : numBillCdNeededForGoal === 2
                            ? "two or more"
                            : "enough"
                    } visits.`}
                >
                    <span>
                        {numberMet} patients{" "}
                        <span className="fa fa-question-circle-o" />
                    </span>
                </Hoverlay>
                <span>out of</span>
                <Hoverlay tooltip={`Target goal set by ${measureName}`}>
                    <span>{numPatNeededForGoal} patients</span>
                </Hoverlay>
            </div>
        </div>
    )
}
