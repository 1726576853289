import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, getFormMeta } from "redux-form"
import _ from "lodash"

import { ValidationError } from "../../../../core/ValidationError"
import LEHOC from "../../../../core/LoadingErrorHOC"
import { easyFormValueSelector } from "../../../../selectorUtils"

import { superuserSelector } from "../../../helperSelectors"
import {
    panelPracticesSelector,
    panelUsersSelector
} from "../../../modalSelectors"
import * as C from "../../../constants"
import { NoAccess } from "./ProviderInputs"
import PracticeUnitProviders from "./PracticeUnitProviders"

// Note that even though this page is /called/ providers, we actually use user IDs throughout, not provider IDs.

export class UserProviderPage extends Component {
    addProviders = (field, providerIds) => {
        const newSelected = _.union(this.props[field], providerIds)
        this.props.change(field, newSelected)
        this.props.touch(field)
    }
    removeProviders = (field, providerIds) => {
        const newSelected = _.difference(this.props[field], providerIds)
        this.props.change(field, newSelected)
        this.props.touch(field)
    }

    getProviderProps() {
        const {
            providers,
            handlers,
            editingSuperuser,
            selectedUserId,
            selectedUser
        } = this.props
        return {
            providers,
            handlers,
            editingSuperuser,
            selectedUserId,
            selectedUser
        }
    }

    render() {
        const fullName = `${
            this.props.selectedUser[C.USER_FIELDS.FIRST_NAME]
        } ${this.props.selectedUser[C.USER_FIELDS.LAST_NAME]}`
        const providerProps = this.getProviderProps()

        return (
            <div className="provider-panels">
                <p>
                    Check the boxes to assign which provider panels {fullName}{" "}
                    can see
                    {this.props.selectedUser[C.USER_FIELDS.NPI] && (
                        <span>
                            , and which users can see {fullName}'s own panel
                        </span>
                    )}
                    .
                </p>
                {this.props.newUser && (
                    <p>
                        By default, a new user's panel can be seen by any
                        practice admin in their practices. Likewise, if the new
                        user is a practice admin, by default they can see the
                        panels of any provider in their practices.
                    </p>
                )}
                <p>
                    You can hover over a <NoAccess message="Like this" /> or a{" "}
                    <input
                        type="checkbox"
                        disabled={true}
                        checked={true}
                        title="Like this"
                    />{" "}
                    to see why it's disabled.
                </p>
                <LEHOC loading={this.props.loading} error={this.props.error}>
                    {_.isEmpty(this.props.practices) ? (
                        <div>No practices selected.</div>
                    ) : (
                        this.props.practices.map(practice => (
                            <PracticeUnitProviders
                                key={
                                    practice[C.PRACTICE_TABLE_KEYS.PRACTICE_ID]
                                }
                                practiceUnit={practice}
                                users={
                                    this.props.practiceUsers[
                                        practice[
                                            C.PRACTICE_TABLE_KEYS.PRACTICE_ID
                                        ]
                                    ]
                                }
                                {...providerProps}
                                onAdd={this.addProviders}
                                onRemove={this.removeProviders}
                            />
                        ))
                    )}
                </LEHOC>
                <div>
                    <Field
                        name={C.USER_FIELDS.PROVIDERS}
                        component={ValidationError}
                    />
                </div>
            </div>
        )
    }
}

export default connect((state, { form, selectedUser }) => {
    const valueSelector = easyFormValueSelector(
        form,
        C.USER_FIELDS.USER_UNITS,
        C.USER_FIELDS.ADMIN_UNITS,
        C.USER_FIELDS.PROVIDERS,
        C.USER_FIELDS.HANDLERS
    )
    const metaSelector = getFormMeta(form)
    const editingSuperuserSelector = superuserSelector(form)
    const practicesSelector = panelPracticesSelector(form)
    const practiceUsersSelector = panelUsersSelector(form)

    return {
        ...valueSelector(state),
        meta: metaSelector(state),
        practices: practicesSelector(state),
        editingSuperuser: editingSuperuserSelector(state),
        practiceUsers: practiceUsersSelector(state)
    }
})(UserProviderPage)
