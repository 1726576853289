import { PERIODS, VIEWS } from "../pickers/constants"
import { FILTERS, TABLE_KEYS } from "../cost/constants"

export { FILTERS } from "../cost/constants"
export { PERIOD_MONTH_STRINGS } from "../pickers/constants"

export const NAME = "COST_DASHBOARD"
export const COST_DASHBOARD_FILTERS_FORM = "COST_DASHBOARD_FILTERS_FORM"

export const SET_COST_DASHBOARD_AGGREGATION = "SET_COST_DASHBOARD_AGGREGATION"
export const SET_COST_DASHBOARD_FILTERS = "SET_COST_DASHBOARD_FILTERS"
export const EXPORT_COST_DASHBOARD_CSV = "EXPORT_COST_DASHBOARD_CSV"
export const PRINT_COST_DASHBOARD_PDF = "PRINT_COST_DASHBOARD_PDF"

export const ID_FIELD_LOOKUP = {
    [TABLE_KEYS.PRACTICE_NAME]: {
        tableKey: TABLE_KEYS.PRACTICE_ID,
        filter: FILTERS.PRACTICE_UNITS
    },
    [TABLE_KEYS.PROVIDER_NAME]: {
        tableKey: TABLE_KEYS.PROVIDER_ID,
        filter: FILTERS.PROVIDERS
    }
}

export const initialState = {
    aggregation: VIEWS.NETWORK,
    filters: {
        [FILTERS.PERIOD]: PERIODS.CURRENT_YEAR
    }
}
