import React from "react"
import { connect } from "react-redux"
import { reduxForm } from "redux-form"
import Loading from "../../../core/Loading"
import fetcher from "../../../core/fetcher"
import { placeColumnsByKeys } from "../../../table/helpers"
import { transformPatient } from "../../../batch_print/selectors"
import { COLUMNS_BY_TYPE } from "../../../patient_list/columns"
import { undoTransformFilter } from "../../../transformFilter"
import { ApiTypes } from "../../../api"
import { asyncValidate } from "../../helpers"
import * as C from "../../constants"
import {
    initialUnitPrivilegesSelector,
    initialUserPrivilegesSelector
} from "../../selectors"
import CampaignSettings from "../campaign_settings"

const CampaignSettingsForm = props =>
    props.loading ? <Loading /> : <CampaignSettings {...props} />

const EditCampaignForm = reduxForm({
    form: C.CAMPAIGN_FORM,
    enableReinitialize: true,
    destroyOnUnmount: false,
    asyncChangeFields: [C.TABLE_KEYS.CAMPAIGN_NAME],
    touchOnChange: true,
    asyncValidate
})(CampaignSettingsForm)

const EditCampaignConnect = connect((state, { data, saveCampaignEdits }) => ({
    initialValues: data.campaign
        ? {
              ...data,
              ...data.campaign,
              [C.TABLE_KEYS.USER_PRIVILEGES]: initialUserPrivilegesSelector(
                  state
              ),
              [C.TABLE_KEYS.UNIT_PRIVILEGES]: initialUnitPrivilegesSelector(
                  state
              ),
              [C.TABLE_KEYS.EXTRA_PATIENTS]: data[
                  C.TABLE_KEYS.EXTRA_PATIENTS
              ].map(transformPatient),
              campaign: null,
              [C.TABLE_KEYS.PIN_TYPE]: data.campaign[C.FORCE_PINNED]
                  ? "FORCE_PIN"
                  : null,
              [C.TABLE_KEYS.COLUMNS]: placeColumnsByKeys(
                  COLUMNS_BY_TYPE[data.campaign[C.TABLE_KEYS.CAMPAIGN_TYPE]],
                  data.columns || [],
                  data.frozenColumns || []
              )
          }
        : {},
    onSubmit: saveCampaignEdits
}))(EditCampaignForm)

const _EditCampaign = fetcher({
    name: C.CURRENT_CAMPAIGN,
    endpoint: (state, ownProps) => `/api/campaigns/${ownProps.campaignId}`,
    method: ApiTypes.GET,
    acceptResponse: data => ({
        ...data,
        campaign: {
            ...data.campaign,
            filter: undoTransformFilter(JSON.parse(data.campaign.filterJson))
        }
    })
})(EditCampaignConnect)

export const EditCampaign = fetcher({
    name: C.ACTIVE_USERS,
    endpoint: "/api/users/active",
    method: ApiTypes.GET,
    persistResource: true
})(
    connect((state, ownProps) => ({
        users: ownProps.data,
        usersLoading: ownProps.loading
    }))(_EditCampaign)
)

export default EditCampaign
