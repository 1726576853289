import React from "react"
import { Field } from "redux-form"
import { PrintCheckbox, PrintRadio } from "./PrintItem"
import { getTranslation } from "../../core/helpers"

const _PrintField = ({ input, meta, ...props }) => (
    <PrintCheckbox
        {...props}
        selected={input.value}
        onClick={input.onChange}
        label={props.label || getTranslation(input, meta)[input.name]}
    />
)

export const PrintField = ({ name, ...props }) => (
    <Field name={name} component={_PrintField} {...props} />
)

const _PrintRadioField = ({ input, meta, ...props }) => (
    <PrintRadio {...props} selected={input.value} onClick={input.onChange} />
)

export const PrintRadioField = ({ name, ...props }) => (
    <Field name={name} component={_PrintRadioField} {...props} />
)
