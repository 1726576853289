import React, { Component } from "react"
import { Button, Modal } from "react-bootstrap"
import LEHOC from "../../../core/LoadingErrorHOC"
import AddRiskForm from "./AddRiskForm"

export default class AddRiskModal extends Component {
    handleSubmit = () => {
        this.props.addRiskDetails(this.props.formValues)
    }

    render() {
        const { metadata, show, invalid, riskType, hideRiskModal } = this.props
        return (
            <Modal show={show} onHide={hideRiskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Risk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LEHOC {...metadata} list={riskType}>
                        <AddRiskForm {...this.props} />
                    </LEHOC>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideRiskModal}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={this.handleSubmit}
                        disabled={invalid}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
