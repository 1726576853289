import React from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { FILTER_OPTIONS } from "../constants"
import fetcher, { getMetaData } from "./fetcher"
import { ApiTypes } from "../api"

export const formatPatientCount = ({ data, error, loading }) =>
    loading ? "Loading" : error ? "Error in" : Number(data) // if it comes back as something that's formatted wrong (particularly if it can't be a child of a React component), we'll get NaN instead of crashing

const FilterFooter = ({ loading, patientCount }) => (
    <Card.Footer>
        {loading ? null : (
            <div className="entry-count">{patientCount} Results</div>
        )}
    </Card.Footer>
)

export default fetcher({
    // name and other fetcher props must be passed down from above
    endpoint: (state, { endpoint }) => endpoint(state), // this lets us pass in an "endpoint" function from above
    method: ApiTypes.GET
})(
    connect((state, ownProps) => ({
        ...getMetaData(FILTER_OPTIONS)(state),
        patientCount: formatPatientCount(ownProps)
    }))(FilterFooter)
)
