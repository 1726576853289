export { COMPLIANCE } from "../constants"
export const NAME = "OUTREACH_DASHBOARD"

export const SET_CMI_FILTERS = "SET_CMI_FILTERS"

export const FILTERS = { PRACTICE: "units", PRACTICE_TAG: "regions" }

export const FILTERS_TRANSLATION = {
    [FILTERS.PRACTICE]: "Practice Unit",
    [FILTERS.PRACTICE_TAG]: "Practice Tag"
}
