import React from "react"
import { reduxForm } from "redux-form"
import { Button, Modal, ModalBody } from "react-bootstrap"
import { PrintField } from "../../batch_print/components/PrintField"
import * as C from "../constants"

export const PrintModal = ({
    canPrint,
    open,
    pristine,
    onShow,
    onHide,
    onSubmit
}) =>
    canPrint ? (
        <>
            <Button
                variant="primary"
                size="sm"
                className="pull-right"
                onClick={onShow}
            >
                <span className="fa fa-print" /> Print
            </Button>
            <Modal show={open} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Cost & Utilization Report</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <p>Select the tabs you want to print:</p>
                    <PrintField name={C.FILTERS.EXPORT_COST} />
                    <PrintField name={C.FILTERS.EXPORT_UTILIZATION} />

                    <p style={{ marginTop: "1.2rem" }}>
                        Include Cost and Utilization charts:
                    </p>
                    <div className="print-checkboxes-row">
                        <PrintField name={C.FILTERS.EXPORT_RELATIVE} />
                        <PrintField name={C.FILTERS.EXPORT_ABSOLUTE} />
                    </div>
                </ModalBody>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={pristine}
                        onClick={() => {
                            onSubmit()
                            onHide()
                        }}
                    >
                        <span className="fa fa-print" /> Print
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    ) : null

export class PrintModalContainer extends React.Component {
    state = { open: false }

    handleShow = () => this.setState({ open: true })
    handleHide = () => {
        this.setState({ open: false })
        this.props.reset()
    }

    render() {
        return (
            <PrintModal
                {...this.props}
                {...this.state}
                onShow={this.handleShow}
                onHide={this.handleHide}
            />
        )
    }
}

export default reduxForm({
    form: C.PRINT_UTILIZATION_FORM,
    enableReinitialize: true
})(PrintModalContainer)
