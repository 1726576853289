import React from "react"
import HoverNavDropDown from "./HoverNavDropDown"
import MyAccountLink from "./MyAccountLink"
import LogoutLink from "./LogoutLink"

export const AccountActions = ({ loginId, location, logoutAction }) => (
    <HoverNavDropDown
        location={location}
        label={
            <span>
                <span className="fa fa-user-circle-o" /> {loginId}
            </span>
        }
        id="account"
    >
        <MyAccountLink />
        <LogoutLink logoutAction={logoutAction} />
    </HoverNavDropDown>
)
