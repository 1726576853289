import React, { Component } from "react"
import { Button, Modal } from "react-bootstrap"
import Loading from "../../../core/Loading"
import * as C from "../../constants"
import EditCareManagerForm from "./CareManagerForm"

export default class CareManagerModal extends Component {
    handleSubmit = () => {
        this.props.saveCareManager({
            ...this.props.formValues,
            prevEffDt: this.props.initialValues[C.KEYS.EFF_DT],
            isUpdate: this.props.isUpdate
        })
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hideCareManagerModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change Care Manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.loading ? (
                        <Loading />
                    ) : (
                        <EditCareManagerForm
                            initialValues={this.props.initialValues}
                            careManagers={this.props.careManagers}
                            reasons={this.props.reasons}
                        />
                    )}
                </Modal.Body>
                {!this.props.loading && (
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            className="btn-modal-close"
                            onClick={this.props.hideCareManagerModal}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            className="btn-modal-save"
                            onClick={this.handleSubmit}
                            disabled={this.props.invalid}
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        )
    }
}
