import React from "react"
import campaigns from "../modules/campaigns"
import user_audit from "../modules/user_audits"

const CampaignPage = () => (
    <user_audit.components.default page={user_audit.constants.CAMPAIGNS}>
        <campaigns.components.default />
    </user_audit.components.default>
)

export default CampaignPage
