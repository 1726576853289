import React, { Component } from "react"
import { connect } from "react-redux"
import { setPocId } from "../modules/structure/actions"
import PointOfCarePage from "./PointOfCarePage"

// noinspection JSCheckFunctionSignatures
export class PointOfCareRedirect extends Component {
    componentDidMount() {
        // trigger on mount if coming from outside the POC, since this component might not update on its own after mount
        this.setPocId()
    }

    componentDidUpdate() {
        // trigger on update if we travel from one POC to another, since this component will be already mounted
        this.setPocId()
    }

    setPocId = () => {
        if (this.props.match.params.id !== this.props.poc_id) {
            this.props.setPocId(this.props.match.params.id)
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.match.params.id !== this.props.poc_id ? (
            <span>Loading ...</span>
        ) : (
            <PointOfCarePage {...this.props} />
        )
    }
}

export default connect(state => ({ poc_id: state.poc_id }), {
    setPocId
})(PointOfCareRedirect)
