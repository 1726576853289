import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { components } from "react-select"
import classNames from "classnames"

import Select from "../../core/Select"
import { Hoverlay } from "../../core/Hoverlay"
import { useFocus } from "../../hooks"
import { findByValue } from "../../utils"
import { canWriteTcmSelector } from "../selectors"
import { getErrorKey } from "../helpers"
import * as C from "../constants"

export const SelectCell = ({
    canWrite,
    cellData,
    dataKey,
    error,
    options,
    onSubmit,
    rowData,
    clearError,
    isClearable = true
}) => {
    const defaultOption = useMemo(() => findByValue(options, cellData), [
        options,
        cellData
    ])

    const payload = {
        field: dataKey,
        transitionId: rowData[C.TABLE_KEYS.TCM_TRACKER_ID]
    }

    const { focused, setFocus, focusRef } = useFocus()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (loading && error) {
            setLoading(false)
        }
    }, [loading, error])

    useEffect(() => {
        return () => {
            if (error) {
                clearError(payload) // the errored value is gone at this point, so clear the error
            }
        }
    }, [error])

    const handleSubmit = value => {
        if (value === cellData) {
            return
        }
        clearError(payload)
        setLoading(true)
        onSubmit(value)
    }

    if (!canWrite) {
        return defaultOption?.label || null
    }

    const Input = focused
        ? components.Input
        : () => (
              // placeholder that password managers won't try to autofill
              <div className="tcm-dropdown-placeholder" tabIndex={0} />
          )

    const Control = props => (
        <components.Control
            {...props}
            innerProps={{
                ...props.innerProps,
                onClick: setFocus,
                onFocus: setFocus
            }} // need to make sure focus gets set if you click or focus on any subcomponent
        />
    )

    return (
        <Hoverlay
            disabled={!error}
            tooltip={error}
            className="tooltip-red"
            show
        >
            <Select
                ref={focusRef}
                defaultValue={cellData}
                options={options}
                isClearable={isClearable}
                onChange={handleSubmit}
                disabled={loading}
                loading={loading}
                className={classNames("tcm-input-dropdown", {
                    loading,
                    focused,
                    "form-field-error": error
                })}
                components={{ Input, Control }}
                            />
        </Hoverlay>
    )
}

export default connect((state, { cellData, columnData, dataKey, rowData }) => {
    return {
        ...columnData,
        key: cellData,
        canWrite: canWriteTcmSelector(state),
        error:
            state[C.NAME].errors[
                getErrorKey(dataKey, rowData[C.TABLE_KEYS.TCM_TRACKER_ID])
            ]
    }
})(SelectCell)
