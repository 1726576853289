import { createReducer } from "../utils"
import * as PC from "../patient_list/constants"
import * as C from "./constants"

export const reducer = createReducer(
    { measureId: 0, filters: PC.initialValues },
    {
        [C.SET_CM_MEASURE]: (state, { measureId }) => ({
            ...state,
            measureId
        }),
        [C.SET_CM_PATIENT_FILTERS]: (state, { filters }) => ({
            ...state,
            filters
        })
    }
)

export default reducer
