import React from "react"
import { dateFormat } from "../../dates"

export const RiskRankItem = ({
    index,
    sourceName,
    riskTypeDesc,
    riskResult,
    riskLastDate,
    riskRank,
    userLoginId
}) => (
    <tr className={`${index % 2 === 0 ? "evenRow" : "oddRow"}`}>
        <td>{sourceName}</td>
        <td>{riskTypeDesc}</td>
        <td>{riskResult}</td>
        <td>{dateFormat(riskLastDate)}</td>
        <td>{riskRank}</td>
        <td>{userLoginId}</td>
    </tr>
)
