import moment from "moment"
import { all, call, put, select, takeLatest } from "redux-saga/effects"

import { modifyResource, addToData, modifyData } from "../core/fetcher"
import { notifySimpleError, tryWithNotifications } from "../notifications"
import { ApiTypes, tryFetch } from "../api"
import { END_OF_TIME, SERVER_DATE_STRING, usToIsoDate } from "../dates"
import * as UC from "../user_tags/constants"
import * as C from "./constants"

const addToPatientCount = (tagId, number) =>
    modifyData(
        "tags",
        tag => ({
            ...tag,
            [UC.TABLE_KEYS.PATIENT_COUNT]:
                tag[UC.TABLE_KEYS.PATIENT_COUNT] + number
        }),
        C.TABLE_KEYS.TAG_ID,
        tagId
    )

const setPatientTagStatus = (
    dataField,
    patientTag,
    removed,
    changingStatus
) => {
    return modifyData(
        dataField,
        {
            ...patientTag,
            removed,
            changingStatus
        },
        C.TABLE_KEYS.PATIENT_TAG_ID,
        patientTag[C.TABLE_KEYS.PATIENT_TAG_ID]
    )
}

function* setPatientTagStatuses(patientTag, removed, changingStatus) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: setPatientTagStatus(
                "tags",
                patientTag,
                removed,
                changingStatus
            )
        })
    )
    yield put(
        modifyResource({
            name: UC.TAG_DETAILS,
            dataTransform: setPatientTagStatus(
                "patients",
                patientTag,
                removed,
                changingStatus
            )
        })
    )
}

export function* onAddPatientTag(body) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: addToData("tags", body)
        })
    )
    yield put(
        modifyResource({
            name: UC.TAG_DETAILS,
            dataTransform: addToData("patients", body)
        })
    )
    yield put(
        modifyResource({
            name: UC.USER_TAGS_TABLE,
            dataTransform: addToPatientCount(body[C.TABLE_KEYS.TAG_ID], 1)
        })
    )
}

export function* onEditPatientTag(body) {
    yield put(
        modifyResource({
            name: C.NAME,
            dataTransform: modifyData("tags", body, C.TABLE_KEYS.TAG_ID)
        })
    )
    yield put(
        modifyResource({
            name: UC.TAG_DETAILS,
            dataTransform: modifyData(
                "patients",
                body,
                UC.TABLE_KEYS.PATIENT_ID
            )
        })
    )
}

function* onDeletePatientTag(patientTag) {
    yield* setPatientTagStatuses(patientTag, true, false)

    yield put(
        modifyResource({
            name: UC.USER_TAGS_TABLE,
            dataTransform: addToPatientCount(
                patientTag[C.TABLE_KEYS.TAG_ID],
                -1
            )
        })
    )
}

function* onRestorePatientTag(patientTag) {
    yield* setPatientTagStatuses(patientTag, false, false)

    yield put(
        modifyResource({
            name: UC.USER_TAGS_TABLE,
            dataTransform: addToPatientCount(patientTag[C.TABLE_KEYS.TAG_ID], 1)
        })
    )
}

// this covers anything done through the Add/Edit Patient Tag modal
export function* updateTag(action) {
    const startDate = usToIsoDate(action.payload[C.FILTERS.START_DATE])
    const endDate = usToIsoDate(action.payload[C.FILTERS.END_DATE])
    const patientTagId = action.payload[C.TABLE_KEYS.PATIENT_TAG_ID]
    const pocId = yield select(state => state.poc_id)
    const patientId = action.payload[UC.TABLE_KEYS.PATIENT_ID] || pocId
    const isNew = !patientTagId
    const url = isNew
        ? `/api/patients/${patientId}/tags`
        : `/api/tags/patient/${patientTagId}`

    yield tryWithNotifications(
        {
            url,
            method: isNew ? ApiTypes.POST : ApiTypes.PUT,
            body: {
                ...action.payload,
                [C.FILTERS.START_DATE]: startDate,
                [C.FILTERS.END_DATE]: endDate
            }
        },
        function*(body) {
            if (body === null) {
                yield* onDeletePatientTag(action.payload)
            } else if (isNew) {
                yield* onAddPatientTag(body)
            } else {
                yield* onEditPatientTag(body)
            }
        },
        "Failed to save tag changes."
    )
}

export function* removeTag(action) {
    const patientTagId = action.payload[C.TABLE_KEYS.PATIENT_TAG_ID]
    const body = {
        ...action.payload,
        [C.TABLE_KEYS.END_DATE]: moment().format(SERVER_DATE_STRING)
    }

    yield* setPatientTagStatuses(body, true, true)

    yield* tryFetch(
        {
            url: `/api/tags/patient/${patientTagId}`,
            method: ApiTypes.PUT,
            body
        },
        function*() {
            yield* onDeletePatientTag(body)
        },
        function*(error) {
            yield notifySimpleError("Failed to remove patient tag")(error)
            yield* setPatientTagStatuses(action.payload, false, false)
        }
    )
}

function* restoreTag(action) {
    const patientTagId = action.payload[C.TABLE_KEYS.PATIENT_TAG_ID]
    const body = {
        ...action.payload,
        [C.TABLE_KEYS.END_DATE]: END_OF_TIME // could also use null, but this is a little safer
    }

    yield* setPatientTagStatuses(body, false, true)

    yield* tryFetch(
        {
            url: `/api/tags/patient/${patientTagId}`,
            method: ApiTypes.PUT,
            body
        },
        function*() {
            yield* onRestorePatientTag(body)
        },
        function*(error) {
            yield notifySimpleError("Failed to restore patient tag")(error)
            yield* setPatientTagStatuses(action.payload, true, false)
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeLatest(C.UPDATE_PATIENT_TAG, updateTag)
        }),
        call(function*() {
            yield takeLatest(C.REMOVE_PATIENT_TAG, removeTag)
        }),
        call(function*() {
            yield takeLatest(C.RESTORE_PATIENT_TAG, restoreTag)
        })
    ])
}
