import { createSelector } from "reselect"
import _ from "lodash"
import { getMetaData, getResourceData } from "../core/fetcher"
import * as CC from "../cost/constants"
import { itemsToObject, joinTruthy } from "../utils"
import { denumerateMonth, SERVER_DATE_STRING } from "../dates"
import * as C from "./constants"

const defaultTableData = {
    [C.TABS.COST]: [],
    [C.TABS.UTILIZATION]: []
}

const getMonthRows = (months, stamps, key) =>
    Object.entries(months).map(([month, stampGroups]) => [
        denumerateMonth(month).format(SERVER_DATE_STRING),
        ...stamps.map(stamp => _.get(stampGroups, [stamp, key], 0))
    ])

export const monthlyChartSelector = createSelector(
    getResourceData(C.NAME),
    getMetaData(C.NAME),
    ({ monthsChart }, { loading }) => {
        if (loading) {
            return () => []
        }

        const monthsStamped = _.sortBy(
            _.map(monthsChart, item => ({
                ...item,
                stamp: joinTruthy(
                    [
                        item[C.TABLE_KEYS.CATEGORY_ID],
                        item[C.TABLE_KEYS.SUBCATEGORY_ID]
                    ],
                    "."
                )
            })),
            [
                C.TABLE_KEYS.MONTH_ID,
                C.TABLE_KEYS.CATEGORY_ID,
                C.TABLE_KEYS.SUBCATEGORY_ID
            ]
        )

        const names = itemsToObject(
            monthsStamped,
            month => month.stamp,
            month => {
                if (
                    month[C.TABLE_KEYS.CATEGORY_NAME] ===
                    month[C.TABLE_KEYS.SUBCATEGORY_NAME]
                ) {
                    return month[C.TABLE_KEYS.CATEGORY_NAME]
                } else {
                    return joinTruthy(
                        [
                            month[C.TABLE_KEYS.CATEGORY_NAME],
                            month[C.TABLE_KEYS.SUBCATEGORY_NAME]
                        ],
                        " - "
                    )
                }
            }
        )

        const stamps = _.compact(_.keys(names))

        const monthsGrouped = _.mapValues(
            _.groupBy(monthsStamped, C.TABLE_KEYS.MONTH_ID),
            item => _.keyBy(item, "stamp")
        )

        return key => ({
            columns: stamps,
            names,
            rows: getMonthRows(monthsGrouped, stamps, key)
        })
    }
)

export const chartDataSelector = createSelector(
    monthlyChartSelector,
    chartData => ({
        [C.TABS.COST]: {
            [CC.COST_VIEWS.RELATIVE]: chartData(C.TABLE_KEYS.PMPM_COST),
            [CC.COST_VIEWS.ABSOLUTE]: chartData(C.TABLE_KEYS.TOTAL_COST)
        },
        [C.TABS.UTILIZATION]: {
            [CC.COST_VIEWS.RELATIVE]: chartData(C.TABLE_KEYS.EPISODES_PER_1000),
            [CC.COST_VIEWS.ABSOLUTE]: chartData(C.TABLE_KEYS.EPISODE_COUNT)
        }
    })
)

export const tableDataSelector = createSelector(
    getResourceData(C.NAME),
    getMetaData(C.NAME),
    (data, { loading }) => {
        if (loading) {
            return defaultTableData
        }
        const sortedData = _.sortBy(data.monthsTable)
        return {
            [C.TABS.COST]: sortedData,
            [C.TABS.UTILIZATION]: sortedData
            // they both use the same data, they just display different columns from it
        }
    }
)
