import { all, call, select, takeEvery } from "redux-saga/effects"
import { costFiltersSelector } from "../cost/selectors"
import { downloadFile } from "../api"
import { getFilename, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import { stampDepth } from "../table"
import * as C from "./constants"

function* getCostReport(extension, action) {
    const filters = yield select(costFiltersSelector(C.NAME))
    const view = yield select(state => state[C.NAME].view)
    const sortedColumns = yield select(state =>
        getSortedColumns(C.VIEW_TABLES[view])(state)
    )
    const level = stampDepth(action.payload[view]) + 1 // +1 because nothing expanded is still level 1

    yield* downloadFile(
        `Cost ${extension.toUpperCase()}`,
        urlWithParams(`/api/cost/${extension}`, {
            ...filters,
            level,
            view,
            ...sortedColumns
        }),
        getFilename("cost_report", extension)
    )
}

export function* printPdf(action) {
    yield* getCostReport("pdf", action)
}
export function* exportCsv(action) {
    yield* getCostReport("csv", action)
}
export function* exportProcedures() {
    const filters = yield select(costFiltersSelector(C.NAME))
    const columns = yield select(getSortedColumns(C.PROCEDURES_TABLE))

    yield* downloadFile(
        `Procedures CSV`,
        urlWithParams(`/api/cost/procedures/csv`, { ...filters, ...columns }),
        getFilename("procedures", "csv")
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.PRINT_COST_REPORT, printPdf)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_COST_REPORT, exportCsv)
        }),
        call(function*() {
            yield takeEvery(C.EXPORT_PROCEDURES, exportProcedures)
        })
    ])
}
