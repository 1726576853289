import React from "react"
import { Col, Row } from "react-bootstrap"
import DashboardFilters from "../../dashboard_filters/components"
import { CostForm } from "../../cost/components/CostFiltersForm"
import { getInitialValues } from "../../cost/helpers"
import * as C from "../constants"
import ProceduresTable from "./ProceduresTable"
import CostReportTable from "./CostReportTable"
import CostReportChart from "./CostReportChart"

const BOTTOM_ROW_HEIGHT = 320

class CostReport extends React.Component {
    state = {
        expanded: {
            [C.VIEWS.CATEGORY]: new Set(),
            [C.VIEWS.PAYER_PRACTICE_PROVIDER]: new Set()
        },
        procedureSearch: ""
    }

    // noinspection JSCheckFunctionSignatures
    componentDidUpdate(prevProps) {
        // using componentDidUpdate rather than a key because we only want to reset state.expanded, not e.g. table sorting
        if (prevProps.filters !== this.props.filters) {
            this.setState({
                expanded: {
                    [C.VIEWS.CATEGORY]: new Set(),
                    [C.VIEWS.PAYER_PRACTICE_PROVIDER]: new Set()
                }
            })
        }
    }

    setExpanded = view => expanded =>
        this.setState({
            expanded: { ...this.state.expanded, [view]: expanded }
        })
    setFilters = filters =>
        this.props.setFilters({
            filters,
            categoryTree: this.props.categoryTree
        })
    setProcedureSearch = procedureSearch => this.setState({ procedureSearch })

    getInitialValues = () =>
        getInitialValues(this.props.filters, this.props.categoryTree)

    render() {
        const { categoryNodes, view } = this.props
        return (
            <>
                <Row>
                    <Col xs={10} className="page-header cost-report-header">
                        <h1>Cost Report</h1>
                        <DashboardFilters
                            title="Edit Cost Filters"
                            form={C.COST_REPORT_FILTERS_FORM}
                            categoryNodes={categoryNodes}
                            formComponent={CostForm}
                            lookup={this.props.fundStampLookup}
                            initialValues={this.getInitialValues()}
                            emptyValues={C.initialState.filters}
                            setFilters={this.setFilters}
                            categoryTree={this.props.categoryTree}
                        />
                    </Col>
                </Row>
                <CostReportTable
                    view={view}
                    {...this.state}
                    setExpanded={this.setExpanded}
                    fundStampLookup={this.props.fundStampLookup}
                />
                <Row className="cost-supplements row-equal-columns">
                    <Col lg={7}>
                        <ProceduresTable
                            searchTerm={this.state.procedureSearch}
                            onSearch={this.setProcedureSearch}
                        />
                    </Col>
                    <Col lg={5}>
                        <CostReportChart />
                    </Col>
                </Row>
            </>
        )
    }
}

export default CostReport
