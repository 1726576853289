import * as C from "./constants"

export const setFilters = filters => ({
    type: C.SET_PATIENT_AUDIT_FILTERS,
    filters
})
export const setStatusFilter = filter => ({
    type: C.SET_PATIENT_AUDIT_STATUS_FILTER,
    filter
})
export const setAuditFilter = filter => ({
    type: C.SET_PATIENT_AUDIT_AUDIT_FILTER,
    filter
})

export const closeModal = () => ({
    type: C.CLOSE_PA_MODAL
})
export const showFilesModal = recordId => ({
    type: C.SHOW_PA_FILES_MODAL,
    recordId
})
export const showNotesModal = recordId => ({
    type: C.SHOW_PA_NOTES_MODAL,
    recordId
})
export const showStatusModal = recordId => ({
    type: C.SHOW_PA_STATUS_MODAL,
    recordId
})

export const uploadFiles = (recordId, files, description) => ({
    type: C.UPLOAD_PA_FILES,
    recordId,
    files,
    description
})
export const downloadFile = record => ({
    type: C.DOWNLOAD_PA_FILE,
    record
})
export const deleteFile = recordFileId => ({
    type: C.DELETE_PA_FILE,
    recordFileId
})
export const restoreFile = recordFileId => ({
    type: C.RESTORE_PA_FILE,
    recordFileId
})
export const updateFileDesc = (recordFileId, description) => ({
    type: C.UPDATE_PA_FILE_DESC,
    recordFileId,
    description
})
export const stopSavingFile = recordFileId => ({
    type: C.STOP_SAVING_PA_FILE,
    recordFileId
})

export const newNote = (recordId, note, important) => ({
    type: C.NEW_PA_NOTE,
    recordId,
    note,
    important
})
export const updateNote = (recordNoteId, note, important) => ({
    type: C.UPDATE_PA_NOTE,
    recordNoteId,
    note,
    important
})
export const removeNote = recordNoteId => ({
    type: C.REMOVE_PA_NOTE,
    recordNoteId
})
export const restoreNote = recordNoteId => ({
    type: C.RESTORE_PA_NOTE,
    recordNoteId
})
export const stopSavingNote = recordNoteId => ({
    type: C.STOP_SAVING_PA_NOTE,
    recordNoteId
})

export const updateStatus = payload => ({
    type: C.UPDATE_PA_STATUS,
    payload
})

export const exportAudits = () => ({ type: C.EXPORT_PATIENT_AUDITS })
export const printAudits = () => ({ type: C.PRINT_PATIENT_AUDITS })

export const logAuditView = () => ({ type: C.LOG_AUDIT_VIEW })
