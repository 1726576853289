import React, { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import { line } from "billboard.js"
import _ from "lodash"
import fetcher from "../../../../core/fetcher"
import Chart from "../../../../core/Chart"
import LER from "../../../../core/LoadingErrorHOC"
import { itemsToObject } from "../../../../utils"
import { ApiTypes } from "../../../../api"
import * as C from "../../../constants"

const CONFIG = {
    axis: {
        x: {
            type: "timeseries",
            tick: {
                format: "%m/%d/%Y",
                culling: false,
                fit: false // otherwise we can get some weird spacing and overlaps
            },
            clipPath: false
        }
    },
    padding: { right: 25, unit: "px" },
    grid: { y: { show: true } }
}

const getX = i => `x${i}`

const MeasureChart = ({ data, error, loading, unitLabel }) => {
    const chartData = useMemo(
        () => ({
            type: line(),
            xs: itemsToObject(
                data.lines,
                line => line.measureName,
                (line, i) => getX(i)
            ),
            columns: [
                ..._.map(data.lines, (line, i) => [
                    getX(i),
                    ...line.dataPoints.map(dp => dp.serviceDt)
                ]),
                ..._.map(data.lines, line => [
                    line.measureName,
                    ...line.dataPoints.map(dp => dp.value)
                ])
            ]
        }),
        [data]
    )

    const config = useMemo(() => {
        const formatValue = val => val + " " + unitLabel
        return _.merge(CONFIG, {
            axis: {
                y: { tick: { format: formatValue } }
            },
            tooltip: {
                format: {
                    value: formatValue
                }
            }
        })
    }, [unitLabel])

    return (
        <LER list={data.lines} loading={loading} error={error}>
            <Chart data={chartData} config={config} />
        </LER>
    )
}

const MeasureChartContainer = fetcher({
    name: C.CHART_DATA,
    endpoint: (state, ownProps) =>
        `/api/measures/history/${ownProps.measureId}/patient/${state.poc_id}/chart`,
    method: ApiTypes.GET
})(MeasureChart)

const ChartPopover = ({ hasHistory, name, offerGraph, ...props }) => {
    const [open, setOpen] = useState(false)

    return !hasHistory || !offerGraph ? (
        <div />
    ) : (
        <>
            <button className="icon-button" onClick={() => setOpen(true)}>
                <i className="fa fa-line-chart" />
            </button>
            <Modal size="lg" show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MeasureChartContainer {...props} />
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChartPopover
