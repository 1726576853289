import { all, call, put, select, takeEvery } from "redux-saga/effects"
import _ from "lodash"
import { modifyResource } from "../core/fetcher"
import qsr_dashboard from "../qsr_dashboard"
import { tryWithNotifications } from "../notifications"
import { ApiTypes } from "../api"
import { SETTINGS } from "../constants"
import * as C from "./constants"

export const createDataTransform = payload => data => {
    const newData = _.defaultsDeep({}, payload, data)
    newData.modules = { ...data[C.MODULES] }
    return newData
}

export function* submitSettings({ payload }) {
    yield* tryWithNotifications(
        {
            url: "/api/settings",
            method: ApiTypes.PUT,
            body: payload
        },
        function*() {
            yield put(
                modifyResource({
                    name: SETTINGS,
                    dataTransform: createDataTransform(payload)
                })
            )

            // it's not ideal to just reload the page here, because it reloads all our data from the API...
            // but it's not *that* expensive an operation, and updating modules is not something we do very often. And this is a lot easier than mucking around with react-router, so I think it's okay.
            window.location.reload()
        }
    )
}

export function* handleFetch(action) {
    yield* updateQsrDashboardFilters(action.data) // other tables update differently
}

export function* updateQsrDashboardFilters(data) {
    const currentFilters = yield select(state => state[C.QSR_DASHBOARD_FORM])
    const newFilters = data[C.TABLE_FILTERS][C.QSR_DASHBOARD_FORM]
    yield put(
        qsr_dashboard.actions.setFilters({
            ...currentFilters,
            ...newFilters
        })
    )
}

export function* clearCache() {
    yield* tryWithNotifications(
        {
            url: "/api/caches",
            method: ApiTypes.DELETE,
            message: "Clearing server cache..."
        },
        {
            title: "Server cache was cleared!",
            message: "The next few operations may take longer than usual.",
            autoDismiss: 10
        },
        { title: "Unable to clear server cache at this time." }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SUBMIT_SETTINGS, submitSettings)
        }),
        call(function*() {
            yield takeEvery(C.CLEAR_CACHE, clearCache)
        }),
        call(function*() {
            yield takeEvery(
                action =>
                    action.type === "@@fetcher/FETCH_SUCCESS" &&
                    action.name === SETTINGS,
                handleFetch
            )
        })
    ])
}
