import React from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import { tableFactory } from "../../table"
import { tableHeightForLength } from "../../utils"
import { SPECIALIST_TABLE_COLUMNS } from "../columns"
import * as C from "../constants"
import CellRenderer from "./CellRenderer"

const { Table, Settings, Description } = tableFactory({
    name: C.SPECIALIST_TABLE_NAME,
    columns: SPECIALIST_TABLE_COLUMNS,
    sortBy: [C.KEYS.PROVIDER_NAME],
    sortDirection: [SortDirection.ASC]
})

const Providers = ({ maxLength, metadata, providers }) => (
    <Card id="care_team_providers" className="card-table">
        <Card.Header>
            <CardTitle>Providers</CardTitle>
            <Settings />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC {...metadata} list={providers} hideEmpty>
                <Table
                    list={providers}
                    cellRenderer={CellRenderer}
                    height={tableHeightForLength(maxLength)}
                />
            </LEHOC>
        </Card.Body>
    </Card>
)
export default Providers
