import _ from "lodash"
import { createReducer } from "../utils"
import * as C from "./constants"

const initialState = {
    filters: { [C.FILTERS.STATUS]: [2, 6, 4, 5, 1, 3] }, // this ordering fits in the input nicely
    filesModalOpen: false,
    notesModalOpen: false,
    statusModalOpen: false,
    recordId: 0,
    filesSaving: [],
    notesSaving: []
}

const markFileSaving = (state, { recordFileId }) => ({
    ...state,
    filesSaving: [...state.filesSaving, recordFileId ?? 0]
})
const markNoteSaving = (state, { recordNoteId }) => ({
    ...state,
    notesSaving: [...state.notesSaving, recordNoteId ?? 0]
})

export default createReducer(initialState, {
    [C.SET_PATIENT_AUDIT_FILTERS]: (state, { filters }) => ({
        ...state,
        filters
    }),
    [C.SET_PATIENT_AUDIT_STATUS_FILTER]: (state, { filter }) => ({
        ...state,
        filters: { ...state.filters, [C.FILTERS.STATUS]: filter }
    }),
    [C.SET_PATIENT_AUDIT_AUDIT_FILTER]: (state, { filter }) => ({
        ...state,
        filters: {
            ...state.filters,
            [C.FILTERS.AUDIT]: filter
        }
    }),
    [C.CLOSE_PA_MODAL]: state => ({
        ...state,
        filesModalOpen: false,
        notesModalOpen: false,
        statusModalOpen: false,
        recordId: 0
    }),
    [C.SHOW_PA_FILES_MODAL]: (state, { recordId }) => ({
        ...state,
        filesModalOpen: true,
        recordId
    }),
    [C.SHOW_PA_NOTES_MODAL]: (state, { recordId }) => ({
        ...state,
        notesModalOpen: true,
        recordId
    }),
    [C.SHOW_PA_STATUS_MODAL]: (state, { recordId }) => ({
        ...state,
        statusModalOpen: true,
        recordId
    }),
    [C.UPLOAD_PA_FILES]: markFileSaving,
    [C.DELETE_PA_FILE]: markFileSaving,
    [C.RESTORE_PA_FILE]: markFileSaving,
    [C.STOP_SAVING_PA_FILE]: (state, { recordFileId }) => ({
        ...state,
        filesSaving: _.without(state.filesSaving, recordFileId)
    }),
    [C.NEW_PA_NOTE]: markNoteSaving,
    [C.UPDATE_PA_NOTE]: markNoteSaving,
    [C.REMOVE_PA_NOTE]: markNoteSaving,
    [C.RESTORE_PA_NOTE]: markNoteSaving,
    [C.STOP_SAVING_PA_NOTE]: (state, { recordNoteId }) => ({
        ...state,
        notesSaving: _.without(state.notesSaving, recordNoteId)
    })
})
