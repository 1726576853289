import React from "react"
import { Field } from "redux-form"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"

import { SelectField, TextInput } from "../../../core/CustomFields"
import { maxLength, required } from "../../../core/validators"
import * as C from "../../constants"
import { TagsPage } from "../TagsPage"

export const PracticeModal = props => (
    <Modal
        dialogClassName="small-modal admin-modal"
        show={props.show}
        onHide={props.onHide}
    >
        <form onSubmit={props.handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Practice Unit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Field
                    name={C.PRACTICE_FIELDS.PRACTICE_NAME}
                    component={TextInput}
                    validate={[required, maxLength(128)]}
                    required
                />
                <Field
                    name={C.PRACTICE_FIELDS.REGION_ID}
                    component={SelectField}
                    options={props.regionOptions}
                    multi
                />
                <p
                    style={{
                        marginBottom: "0.5em",
                        fontWeight: _.some(props.tags, C.TAG_COLUMNS.PRIVILEGE)
                            ? "bold"
                            : "normal"
                    }}
                >
                    Access to Patient Tags
                </p>
                <TagsPage {...props} hideMessage={true} />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="pull-left"
                    onClick={props.onHide}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    className="pull-right"
                    disabled={props.invalid}
                >
                    {props.label}
                </Button>
            </Modal.Footer>
        </form>
    </Modal>
)
