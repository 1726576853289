import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(C.initialState, {
    [C.SET_PATIENT_COST_PERIOD]: (state, action) => ({
        ...state,
        [C.PERIOD]: action.payload
    }),
    [C.SET_PATIENT_COST_CHART_FILTERS]: (state, action) => ({
        ...state,
        chartFilters: action.payload
    }),
    [C.SET_PATIENT_COST_MODAL_FILTERS]: (state, action) => ({
        ...state,
        modalFilters: action.payload
    })
})

export default reducer
