import { createReducer } from "../utils"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.OPEN_LOCATION_MODAL]: (state, action) => ({
        ...state,
        allowSelect: false,
        ...action.payload,
        show: true,
        mode: C.SELECT_MODE
    }),
    [C.CLOSE_LOCATION_MODAL]: state => ({ ...state, show: false }),
    [C.LOCATION_MODE_SELECT]: state => ({
        ...state,
        mode: C.SELECT_MODE
    }),
    [C.LOCATION_MODE_ADD]: (state, action) => ({
        ...state,
        mode: C.ADD_MODE,
        initialValues: action.payload.initialValues
    }),
    [C.LOCATION_MODE_EDIT]: (state, action) => ({
        ...state,
        mode: C.EDIT_MODE,
        initialValues: action.payload.initialValues
    })
})

export default reducer
