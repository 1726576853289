import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import filterInvalidProps from "filter-invalid-dom-props"
import { moduleEnabledSelector } from "../moduleUtils"
import * as C from "../constants"
import { Hoverlay } from "./Hoverlay"

const ICON_PROPS = {
    className: "tooltip-blue",
    trigger: ["hover", "focus"]
}

export const _PrintIcon = ({ className, show, ...props }) =>
    show && (
        <Hoverlay {...ICON_PROPS} tooltip="Print">
            <span
                {...filterInvalidProps(props)}
                className={classNames(
                    "fa clickable-icon print-icon",
                    className
                )}
            />
        </Hoverlay>
    )
export const _ExportIcon = ({ className, show, ...props }) =>
    show && (
        <Hoverlay {...ICON_PROPS} tooltip="Export">
            <span
                {...filterInvalidProps(props)}
                className={classNames(
                    "fa clickable-icon export-icon",
                    className
                )}
            />
        </Hoverlay>
    )

const enabledSelector = moduleEnabledSelector(C.MODULE_IDS.EXTRACTS)

export const PrintIcon = connect(state => ({
    show: enabledSelector(state)
}))(_PrintIcon)
export const ExportIcon = connect(state => ({
    show: enabledSelector(state)
}))(_ExportIcon)
