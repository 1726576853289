import _ from "lodash"
import * as PC from "../permission_managment/constants"
import * as AC from "../admin/constants"
import * as C from "./constants"

export const tagSortPredicates = [
    tag => PC.ACCESS_PRIVILEGES_SORT_INDICES[tag[C.TABLE_KEYS.PRIVILEGE]],
    tag => AC.TAG_SOURCES_SORT_INDICES[tag[C.TABLE_KEYS.SOURCE]]
]

export const getNewRow = props => ({
    [C.TABLE_KEYS.TAG_ID]: C.NEW_TAG_ID,
    [C.TABLE_KEYS.TAG_NAME]: "",
    [C.TABLE_KEYS.OWNER_ID]: props.userId,
    [C.TABLE_KEYS.OWNER]: props.userName,
    [C.TABLE_KEYS.OWNER_USERNAME]: props.loginId,
    [C.TABLE_KEYS.PUBLIC]: false
})

const getTopPrivileges = (tags, sortField) => {
    const sortedTags = _.sortBy(tags, [sortField, ...tagSortPredicates])
    return _.sortedUniqBy(sortedTags, sortField)
}

// the highest privilege in a list for each tagId, sub-prioritized by source
export const getTopTagPrivileges = tags =>
    getTopPrivileges(tags, C.TABLE_KEYS.TAG_ID)

// looks the same but for tag details users, which are structured differently
export const getTopUserPrivileges = users =>
    _.map(users, user => ({ ...user, ..._.head(user.privileges) }))

export const getTopPrivilege = (...privileges) =>
    _.minBy(
        privileges,
        privilege => PC.ACCESS_PRIVILEGES_SORT_INDICES[privilege]
    )

export const getTagSourceText = tagPrivilege => {
    const [source, privilege] = _.at(tagPrivilege, [
        C.TABLE_KEYS.SOURCE,
        C.TABLE_KEYS.PRIVILEGE
    ])
    const textData = AC.TAG_SOURCE_TEXT[source]

    if (!textData) {
        return {}
    }

    return {
        ...textData,
        tooltip: _.isString(textData.tooltip)
            ? textData.tooltip
            : textData.tooltip[privilege]
    }
}

// i.e. whichever sorts earliest in the privileges ranking
export const getExternalTooltip = () =>
    getTagSourceText({
        [C.TABLE_KEYS.SOURCE]: AC.TAG_SOURCES.EXTERNAL,
        [C.TABLE_KEYS.PRIVILEGE]: ""
    }).tooltip
