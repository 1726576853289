import _ from "lodash"
import React, { PureComponent } from "react"
import ReactTable from "react-table"
import selectTableHOC from "react-table/lib/hoc/selectTable"

import LEHOC from "../../../core/LoadingErrorHOC"
import { TableDescription } from "../../../table"
import { filterBySearch } from "../../../utils"
import { PRACTICE_COLUMNS } from "../../columns"
import { isDisabled } from "../../helpers"
import * as C from "../../constants"
import SearchBox from "../SearchBox"
import AddButton from "../AddButton"

const CheckboxTable = selectTableHOC(ReactTable)

const SelectInputComponent = props => (
    <input
        type="checkbox"
        checked={props.checked}
        disabled={isDisabled(props.row)}
        onChange={() => {
            props.onClick(props.row[C.PRACTICE_TABLE_KEYS.PRACTICE_ID])
        }}
    />
)

class PracticeTable extends PureComponent {
    state = {
        activePracticeIds: [],
        selectAll: 1,
        filter: ""
    }

    // lifecycle //

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevProps.practices !== this.props.practices) {
            // this will only happen once, when the fetcher is finished loading the practice units

            const activePracticeIds = _.toArray(this.props.practices) // using _.toArray because this.props.practices can be an object instead of an array when it's empty
                .filter(x => !isDisabled(x))
                .map(x => x[C.PRACTICE_TABLE_KEYS.PRACTICE_ID])
            Object.freeze(activePracticeIds) // but note that the items can still change their own fields
            this.setState({ activePracticeIds })

            this.props.changeSelectedPractices([...activePracticeIds])
        }
    }

    // filter & resolve data //

    resolveData = data => {
        const { roles, setPracticeStatus, showEditPracticeModal } = this.props
        return this.filterData(data).map(row => ({
            ...row,
            roles,
            setPracticeStatus,
            showEditPracticeModal
        }))
    }

    filterData = data => {
        if (this.state.filter) {
            return filterBySearch(
                data,
                row => row[C.PRACTICE_TABLE_KEYS.PRACTICE_NAME],
                this.state.filter
            )
        }
        return _.isEmpty(data) ? [] : data
        // using isEmpty() in case it was sent as an object, which can happen
    }

    setFilter = filter => {
        if (filter === this.state.filter) {
            return
        }

        this.setState({ filter })
    }

    // selected //

    isSelected = practiceUnitId => this.props.selected.includes(practiceUnitId)

    toggleSelected = practiceUnitId => {
        const selected = this.props.selected

        if (selected.includes(practiceUnitId)) {
            this.updateSelected(_.without(selected, practiceUnitId))
        } else {
            this.updateSelected([...selected, practiceUnitId])
        }
    }

    toggleSelectAll = () => {
        const allSelected = this.state.selectAll === 1
        const newSelected = allSelected ? [] : this.state.activePracticeIds

        this.setState({ selectAll: allSelected ? 0 : 1 })
        this.props.changeSelectedPractices(newSelected)
    }

    updateSelected = selected => {
        this.updateSelectAll(selected)
        this.props.changeSelectedPractices(selected)
    }

    updateSelectAll = (
        selected,
        activePracticeIds = this.state.activePracticeIds
    ) => {
        let selectAll = 2

        if (_.isEmpty(selected)) {
            selectAll = 0
        } else if (activePracticeIds.every(x => selected.includes(x))) {
            // TODO maybe apply the filter too? So that if I'm filtering and all the visible ones are checked, then selectAll will be checked too
            // If I do that I'll have to do something similar to toggleSelectAll
            selectAll = 1
        }
        this.setState({ selectAll })
    }

    // finally, actual rendering

    render() {
        const filteredData = this.resolveData(this.props.practices)
        return (
            <React.Fragment>
                <TableDescription tableName={C.PRACTICE_UNITS} />
                <div className="admin-controls">
                    <AddButton
                        label="PRACTICE UNIT"
                        disabled={
                            this.props.roles === undefined ||
                            !this.props.roles.includes(C.ROLE_SUPERUSER)
                        }
                        onClick={this.props.showAddPracticeModal}
                    />
                    <SearchBox
                        onChange={this.setFilter}
                        placeholder="Search for Practice Unit"
                        cosmeticButton
                    />
                </div>
                <LEHOC loading={this.props.loading} error={this.props.error}>
                    <CheckboxTable
                        className="admin-practices-table"
                        data={filteredData}
                        noDataText="No Practice Units found!"
                        columns={PRACTICE_COLUMNS}
                        defaultSorted={[
                            {
                                id: C.PRACTICE_TABLE_KEYS.PRACTICE_NAME,
                                desc: false
                            }
                        ]}
                        pageSize={filteredData.length}
                        showPagination={false}
                        isSelected={this.isSelected}
                        selectAll={
                            this.state.selectAll === 1
                        } /*FIXME this doesn't work with indeterminate inputs yet. We'll need a custom solution, possibly with refs*/
                        toggleAll={this.toggleSelectAll}
                        toggleSelection={this.toggleSelected}
                        selectType="checkbox"
                        SelectInputComponent={SelectInputComponent}
                        keyField="practiceUnitId"
                    />
                </LEHOC>
            </React.Fragment>
        )
    }
}

export default PracticeTable
