import * as C from "./constants"
import { createReducer } from "../utils"

const reducer = createReducer(C.initialState, {
    [C.SHOW_FILTERS_MODAL]: (state, { show }) => ({
        ...state,
        showFiltersModal: show
    }),
    [C.SHOW_FAVORITES_MODAL]: (state, { show }) => ({
        ...state,
        showFavoritesModal: show
    }),
    [C.SHOW_FAVORITE_NAME_MODAL]: (state, { show }) => ({
        ...state,
        showFavoriteNameModal: show
    }),
    [C.SHOW_EDIT_FAVORITE_MODAL]: (state, { show }) => ({
        ...state,
        showEditFavoriteModal: show
    }),
    [C.SAVE_FAVORITE_FILTERS]: state => ({ ...state, saving: true }),
    [C.STOP_SAVING_FILTERS]: state => ({ ...state, saving: false }),
    [C.SELECT_FAVORITE_FILTERS]: (state, { formName, payload }) => ({
        ...state,
        selectedFilters: {
            ...state.selectedFilters,
            [formName]: payload
        }
    })
})

export default reducer
