import React from "react"
import { ROLE_PRACTICE_ADMIN, ROLE_SUPERUSER } from "../../admin/constants"
import HoverNavDropDown from "./HoverNavDropDown"
import NavLink from "./NavLink"

export const AdminTools = ({ location, roles = [], clearCache }) =>
    (roles.includes(ROLE_SUPERUSER) || roles.includes(ROLE_PRACTICE_ADMIN)) && (
        <HoverNavDropDown
            label="Admin Tools"
            location={location}
            matchOn={["/settings", "/admin"]}
        >
            {roles.includes(ROLE_SUPERUSER) && (
                <NavLink
                    key="Settings"
                    label="Site Settings"
                    className="dropdown-item"
                    location={location}
                    href="/settings"
                    matchOn="/settings"
                />
            )}
            {(roles.includes(ROLE_SUPERUSER) ||
                roles.includes(ROLE_PRACTICE_ADMIN)) && (
                <NavLink
                    key="Admin"
                    label="User Administration"
                    className="dropdown-item"
                    location={location}
                    href="/admin"
                    matchOn="/admin"
                />
            )}
            {roles.includes(ROLE_SUPERUSER) && (
                <NavLink
                    key="Alerts"
                    label="Manage Alerts"
                    className="dropdown-item"
                    location={location}
                    href="/alerts"
                    matchOn="/alerts"
                />
            )}
            {roles.includes(ROLE_SUPERUSER) && (
                <NavLink
                    key="Cache"
                    label="Clear Server Cache"
                    className="dropdown-item"
                    location={location}
                    onClick={clearCache}
                />
            )}
        </HoverNavDropDown>
    )
