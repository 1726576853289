import React from "react"
import ReactTable from "react-table"
import _ from "lodash"

import LEHOC from "../../core/LoadingErrorHOC"
import SearchBar from "../../core/SearchBar"
import { filterBySearch, itemsToObject } from "../../utils"
import { TableDescription } from "../../table"
import { USER_TAG_COLUMNS } from "../columns"
import * as C from "../constants"
import { canEditTag, canReadTag } from "./users/helpers"

const getNewPrivilege = (oldPrivilege, clickedPrivilege) => {
    if (!oldPrivilege) {
        return clickedPrivilege
    }
    return C.PRIVILEGE_CHANGES[oldPrivilege][clickedPrivilege]
}

export class TagsPage extends React.Component {
    state = { search: "" }

    getTagPrivileges = () =>
        itemsToObject(
            this.props.tags,
            C.TAG_COLUMNS.TAG_ID,
            C.TAG_COLUMNS.PRIVILEGE
        )

    handleSearch = search => this.setState({ search })

    setTagAccess = (tagId, privilege) => {
        const tagAccess = this.getTagPrivileges()
        const newPrivilege = getNewPrivilege(tagAccess[tagId], privilege)
        tagAccess[tagId] = newPrivilege || undefined
        this.props.change(C.USER_FIELDS.TAGS, tagAccess)
    }

    resolveData = tags =>
        tags.map(tag => ({
            ...tag,
            [C.TAG_COLUMNS.READ]: canReadTag(tag),
            [C.TAG_COLUMNS.EDIT]: canEditTag(tag),
            setTagAccess: this.setTagAccess
        }))

    render() {
        if (
            !this.props.loading &&
            !this.props.error &&
            _.isEmpty(this.props.tags)
        ) {
            return (
                <div>
                    You don't have edit access to any tags, so you can't grant
                    tag access to others.
                </div>
            )
        }

        const tags = filterBySearch(
            this.props.tags,
            tag => tag.label,
            this.state.search
        )

        return (
            <div className="edit-user-tags">
                <TableDescription tableName={C.EDIT_USER_TAGS} Component="p" />
                {this.props.hideMessage || (
                    <p>
                        You can hover over disabled checkboxes to see why
                        they're disabled.
                    </p>
                )}

                <LEHOC loading={this.props.loading} error={this.props.error}>
                    <SearchBar
                        className="tag-search"
                        placeholder="Filter by tag name"
                        onChange={this.handleSearch}
                        cosmeticButton
                    />
                    <ReactTable
                        data={tags || []}
                        resolveData={this.resolveData}
                        columns={USER_TAG_COLUMNS}
                        defaultPageSize={tags.length}
                        showPagination={false}
                    />
                </LEHOC>
                {/*<Field name={C.USER_FIELDS.TAG_IDS} component="div" />*/}
                {/*Normally we'd have a field here, but since we don't have any validation messages to display or anything, we just change the field value in the form directly.*/}
            </div>
        )
    }
}
