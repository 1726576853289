export { STATE_OPTIONS } from "../constants"

export const NAME = "INFO"
export const COST_TOTALS = "PATIENT_COST_TOTALS"
export const UPDATE_PATIENT_INFO = "UPDATE_PATIENT_INFO"
export const UPDATE_PATIENT_INFO_SUCCESS = "UPDATE_PATIENT_INFO_SUCCESS"
export const SHOW_EDIT_PATIENT_INFO = "SHOW_EDIT_PATIENT_INFO"
export const HIDE_EDIT_PATIENT_INFO = "HIDE_EDIT_PATIENT_INFO"
export const DEACTIVATE_CURRENT_PATIENT = "DEACTIVATE_CURRENT_PATIENT"
export const SHOW_DEACTIVATE_PATIENT = "SHOW_DEACTIVATE_PATIENT"
export const HIDE_DEACTIVATE_PATIENT = "HIDE_DEACTIVATE_PATIENT"
export const REACTIVATE_CURRENT_PATIENT = "REACTIVATE_CURRENT_PATIENT"

export const EDIT_PATIENT_DEMOGRAPHICS_FORM = "EDIT_PATIENT_DEMOGRAPHICS_FORM"
export const DEACTIVATE_PATIENT_FORM = "DEACTIVATE_PATIENT_FORM"

export const DECEASED_REASON = 1

export const initialState = {
    showEdit: false,
    showDeactivate: false,
    loading: false
}

export const KEYS = {
    PATIENT_ID: "patientId",
    NAME: "patName",
    DOB: "patDob",
    DOD: "patDod",
    SEX_ID: "patBirthSexId",
    SEX_STR: "patBirthSex",
    GENDER: "patGender",
    ORIENTATION: "patOrientation",
    ADDRESS_1: "patAddress1",
    ADDRESS_2: "patAddress2",
    CITY: "patCity",
    STATE: "patState",
    ZIP: "patZip",
    PHONE: "patPhone",
    RACE: "patRace",
    RACE_ID: "patRaceId",
    ETHNICITY: "patEthnicity",
    ETHNICITY_ID: "patEthnicityId",
    CARE_MANAGEMENT: "patCmMeasures",
    PCP: "pcpName",
    COVERAGES: "coverages",
    EFF_DATE: "effDt",
    END_DATE: "endDt",
    REASON: "reason",
    DEACTIVATED: "deactivated",
    NEXT_APPOINTMENT: "patNextAptDt",
    COST_YTD: "costYtd",
    COST_12_MONTH: "costTwelveMonth",
    PAYER_NAME: "payerName",
    PAYER_LOGO: "payerLogo",
    PRODUCT_NAME: "productClassName",
    GRACE_PERIOD: "patInGracePrd"
}

export const DEMOGRAPHICS_TRANSLATION = {
    [KEYS.SEX_ID]: "Sex on Birth Certificate",
    [KEYS.GENDER]: "Gender Identity",
    [KEYS.ORIENTATION]: "Sexual Orientation",
    [KEYS.ADDRESS_1]: "Address 1",
    [KEYS.ADDRESS_2]: "Address 2",
    [KEYS.CITY]: "City",
    [KEYS.STATE]: "State",
    [KEYS.PHONE]: "Phone Number",
    [KEYS.RACE_ID]: "Race",
    [KEYS.ETHNICITY_ID]: "Ethnicity",
    [KEYS.ZIP]: "Zip Code"
}
export const DEACTIVATE_TRANSLATION = {
    [KEYS.REASON]: "Reason",
    [KEYS.EFF_DATE]: "Eff Date"
}

export const BMI_GENDERS = ["Female", "Male"]
