import moment from "moment"
import _ from "lodash"
import * as C from "./constants"

export const transformMeasures = measure => ({
    label: measure.name,
    value: measure.measureId,
    nextDueDt: moment().startOf("year") // we want all measures to show up as required, so we set the due date to the beginning of this year. (non-required = not due this year)
})

export const transformServices = item => ({
    label: item.measureName,
    value: item.measureId,
    nextDueDt: item.nextDueDt && moment.utc(item.nextDueDt)
})

export const shouldDisablePrint = formValues =>
    !formValues ||
    _.isEmpty(
        _.compact(_.at(formValues, _.values(C.TOP_LEVEL_PRINT_FIELDS)))
    ) ||
    (formValues[C.PRINT_FIELDS.LETTER] &&
        _.isEmpty(formValues[C.PRINT_FIELDS.DUE_SERVICES]))
