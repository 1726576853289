import React from "react"
import { Hoverlay } from "../../core/Hoverlay"
import PatientRiskRankInfo from "./PatientRiskRankInfo"

const PatientRiskRankInfoTrigger = props =>
    props.noData ? (
        <span />
    ) : (
        <Hoverlay
            isPopover
            rootClose
            placement="top"
            trigger="click"
            tooltip={<PatientRiskRankInfo {...props} />}
        >
            <span className="button-as-link">{props.riskRank}</span>
        </Hoverlay>
    )
export default PatientRiskRankInfoTrigger
