import * as actionTypes from "./actionTypes"

export const showAddPracticeModal = () => ({
    type: actionTypes.SHOW_ADD_PRACTICE_MODAL
})

export const hideAddPracticeModal = () => ({
    type: actionTypes.HIDE_ADD_PRACTICE_MODAL
})

export const showEditPracticeModal = payload => ({
    type: actionTypes.SHOW_EDIT_PRACTICE_MODAL,
    payload
})

export const hideEditPracticeModal = () => ({
    type: actionTypes.HIDE_EDIT_PRACTICE_MODAL
})

export const showAddUserModal = () => ({
    type: actionTypes.SHOW_ADD_USER_MODAL
})

export const hideAddUserModal = () => ({
    type: actionTypes.HIDE_ADD_USER_MODAL
})

export const showEditUserModal = userId => ({
    type: actionTypes.SHOW_EDIT_USER_MODAL,
    selectedUserId: userId
})

export const hideEditUserModal = () => ({
    type: actionTypes.HIDE_EDIT_USER_MODAL
})

export const fetchPractices = () => ({
    type: actionTypes.FETCH_PRACTICES
})

export const fetchPracticesSuccess = data => ({
    type: actionTypes.FETCH_PRACTICES_SUCCESS,
    data
})

export const fetchUsers = unitIds => ({
    type: actionTypes.FETCH_USERS,
    unitIds
})

export const fetchUsersSuccess = data => ({
    type: actionTypes.FETCH_USERS_SUCCESS,
    data
})

export const changeSelectedPractices = selectedPractices => ({
    type: actionTypes.SELECTED_PRACTICES_CHANGE,
    data: selectedPractices
})

export const addPractice = payload => ({
    type: actionTypes.ADD_PRACTICE,
    payload
})

export const addPracticeSuccess = practice => ({
    type: actionTypes.ADD_PRACTICE_SUCCESS,
    practice
})

export const addPracticeFailure = () => ({
    type: actionTypes.ADD_PRACTICE_FAILURE
})

export const setPracticeStatus = (
    practiceUnitId,
    practiceUnitName,
    status
) => ({
    type: actionTypes.SET_PRACTICE_STATUS,
    practiceUnitId,
    practiceUnitName,
    status
})

export const setPracticeStatusSuccess = (practiceUnitId, status) => ({
    type: actionTypes.SET_PRACTICE_STATUS_SUCCESS,
    practiceUnitId,
    status
})

export const setPracticeStatusFailure = () => ({
    type: actionTypes.SET_PRACTICE_STATUS_FAILURE
})

export const updatePractice = payload => ({
    type: actionTypes.UPDATE_PRACTICE,
    payload
})

export const updatePracticeSuccess = (practiceUnitId, payload) => ({
    type: actionTypes.UPDATE_PRACTICE_SUCCESS,
    practiceUnitId,
    payload
})

export const updatePracticeFailure = () => ({
    type: actionTypes.UPDATE_PRACTICE_FAILURE
})

export const addUser = data => ({
    type: actionTypes.ADD_USER,
    data
})
export const addUserSuccess = () => ({
    type: actionTypes.ADD_USER_SUCCESS
})
export const addUserFailure = () => ({
    type: actionTypes.ADD_USER_FAILURE
})

export const fetchUser = userId => ({
    type: actionTypes.FETCH_USER,
    userId
})

export const fetchUserSuccess = data => {
    return {
        type: actionTypes.FETCH_USER_SUCCESS,
        data
    }
}

export const updateUser = data => ({
    type: actionTypes.UPDATE_USER,
    data
})

export const updateUserSuccess = () => ({
    type: actionTypes.UPDATE_USER_SUCCESS
})

export const updateUserFailure = () => ({
    type: actionTypes.UPDATE_USER_FAILURE
})

export const showConfirmModal = confirmMessage => ({
    type: actionTypes.SHOW_CONFIRM_MODAL,
    confirmMessage
})

export const hideConfirmModal = () => ({
    type: actionTypes.HIDE_CONFIRM_MODAL
})

export const acknowledgeConfirmation = () => ({
    type: actionTypes.CONFIRM_YES
})

export const cancelConfirmation = () => ({
    type: actionTypes.CONFIRM_NO
})
