import { connect } from "react-redux"
import { CostChartCard } from "../../cost/components/CostChartCard"
import { costFiltersSelector } from "../../cost/selectors"
import fetcher from "../../core/fetcher"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import { costChartDataSelector } from "../selectors"
import * as C from "../constants"

const chartDataSelector = costChartDataSelector(C.COST_REPORT_CHART)

export default fetcher({
    name: C.COST_REPORT_CHART,
    endpoint: state =>
        urlWithParams("/api/cost/chart", costFiltersSelector(C.NAME)(state)),
    method: ApiTypes.GET
})(connect(chartDataSelector)(CostChartCard))
