import React from "react"
import { connect } from "react-redux"
import { modifyResource } from "../../../core/fetcher"
import * as reduxActions from "../../actions"

export const _AddToCampaign = ({
    addCampaignPatient,
    canEdit,
    poc_id,
    campaignId
}) =>
    canEdit ? (
        <button
            className="btn btn-default btn-sm"
            onClick={() =>
                addCampaignPatient({ campaignId, patientId: poc_id })
            }
        >
            Add
        </button>
    ) : (
        <span style={{ color: "#999" }}>Cannot add</span>
    )
export const AddToCampaign = connect(
    (state, { rowData }) => ({ ...rowData, poc_id: Number(state.poc_id) }),
    {
        ...reduxActions,
        modifyResource
    }
)(_AddToCampaign)
