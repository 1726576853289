import React from "react"
import { Card } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import DashboardFilters from "../../dashboard_filters/components"
import FilterHeader from "../../core/FilterHeader"
import { GuideLink } from "../../indicators"
import * as C from "../constants"
import { OutreachFilters } from "./OutreachFilters"
import OutreachTile from "./OutreachTile"

const OutreachDashboard = ({
    data,
    error,
    loading,
    anchorDate,
    setCmMeasure,
    setFilters,
    filters
}) => (
    <Card className="card-table outreach-dashboard dashboard-table">
        <Card.Header>
            <Card.Title as="h3">
                Care Management Incentive
                <GuideLink page="view-care-mgmt-incentive-dashboard" />
            </Card.Title>
            <DashboardFilters
                title="Edit Care Management Filters"
                form={C.NAME}
                formComponent={OutreachFilters}
                setFilters={setFilters}
                initialValues={filters}
            />
            <FilterHeader filters={filters} />
        </Card.Header>
        <Card.Body>
            <LEHOC loading={loading} error={error} list={data?.outreach}>
                {data?.outreach?.map((data, index) => (
                    <OutreachTile
                        key={data.measureId}
                        index={index}
                        {...data}
                        formFilters={filters}
                        anchorDate={anchorDate}
                        setCmMeasure={setCmMeasure}
                    />
                ))}
            </LEHOC>
        </Card.Body>
    </Card>
)
export default OutreachDashboard
