import {
    ADD_COMMUNITY_LINKAGE_FIELDS,
    ADD_COMMUNITY_LINKAGE_FORM
} from "../community_linkages/constants"

export { ADD_COMMUNITY_LINKAGE_FORM, ADD_COMMUNITY_LINKAGE_FIELDS }

export const COMMUNITY_LOCATIONS = "COMMUNITY_LOCATIONS"
export const FILTER_LOCATIONS_FORM = "FILTER_COMMUNITY_LOCATIONS_FORM"
export const ADD_COMMUNITY_LOCATION_FORM = "ADD_COMMUNITY_LOCATION_FORM"
export const UPDATE_COMMUNITY_LOCATION = "UPDATE_COMMUNITY_LOCATION"
export const SAVE_LOCATION = "SAVE_LOCATION"

export const LOCATION_MODAL = "LOCATION_MODAL"
export const OPEN_LOCATION_MODAL = "OPEN_LOCATION_MODAL"
export const CLOSE_LOCATION_MODAL = "CLOSE_LOCATION_MODAL"

export const LOCATION_MODE_SELECT = "LOCATION_MODE_SELECT"
export const LOCATION_MODE_ADD = "LOCATION_MODE_ADD"
export const LOCATION_MODE_EDIT = "LOCATION_MODE_EDIT"

export const SELECT_MODE = "SELECT"
export const ADD_MODE = "ADD"
export const EDIT_MODE = "EDIT"
export const initialState = {
    show: false,
    allowSelect: false,
    mode: SELECT_MODE,
    initialValues: {}
}

export const LOCATION_INFO_TABLE = "LOCATION_INFO_TABLE"

export const TABLE_KEYS = {
    LOCATION_ID: "communityLocationId",
    LOCATION_NAME: "name",
    COUNTIES: "counties",
    SOCIAL_NEEDS_DOMAIN: "socialNeeds",
    SERVICES: "services",
    REQUIREMENTS: "requirements",
    PRIMARY_CONTACT: "primaryContact",
    PHONE: "telephone",
    ACTIONS: "actions"
}

export const FILTER_LOCATIONS_FIELDS = {
    NAME: TABLE_KEYS.LOCATION_NAME,
    COUNTY: TABLE_KEYS.COUNTIES,
    SERVICE: TABLE_KEYS.SERVICES
}

export const ADD_COMMUNITY_LOCATION_FIELDS = {
    ADDRESS1: "address1",
    ADDRESS2: "address2",
    CITY: "city",
    COMMUNITY_LOCATION_ID: "communityLocationId",
    COUNTIES: "counties",
    DESCRIPTION: "description",
    EMAIL: "email",
    NAME: "name",
    PRIMARY_CONTACT: "primaryContact",
    REQUIREMENTS: "requirements",
    SERVICES: "services",
    SOCIAL_NEEDS: "socialNeeds",
    STATE: "state",
    TELEPHONE: "telephone",
    WEBSITE: "website",
    ZIP: "zip",
    COUNTRY: "country"
}

export const COMMUNITY_LOCATIONS_TRANSLATION = {
    [ADD_COMMUNITY_LOCATION_FIELDS.ADDRESS1]: "Address 1",
    [ADD_COMMUNITY_LOCATION_FIELDS.ADDRESS2]: "Address 2",
    [ADD_COMMUNITY_LOCATION_FIELDS.CITY]: "City",
    [ADD_COMMUNITY_LOCATION_FIELDS.COUNTIES]: "Counties",
    [ADD_COMMUNITY_LOCATION_FIELDS.DESCRIPTION]: "Description",
    [ADD_COMMUNITY_LOCATION_FIELDS.EMAIL]: "Email",
    [ADD_COMMUNITY_LOCATION_FIELDS.NAME]: "Location Name",
    [ADD_COMMUNITY_LOCATION_FIELDS.PRIMARY_CONTACT]: "Primary Contact",
    [ADD_COMMUNITY_LOCATION_FIELDS.REQUIREMENTS]: "Requirements",
    [ADD_COMMUNITY_LOCATION_FIELDS.SERVICES]: "Services",
    [ADD_COMMUNITY_LOCATION_FIELDS.SOCIAL_NEEDS]: "Social Needs",
    [ADD_COMMUNITY_LOCATION_FIELDS.STATE]: "State",
    [ADD_COMMUNITY_LOCATION_FIELDS.TELEPHONE]: "Phone",
    [ADD_COMMUNITY_LOCATION_FIELDS.WEBSITE]: "Website",
    [ADD_COMMUNITY_LOCATION_FIELDS.ZIP]: "Zip",
    [ADD_COMMUNITY_LOCATION_FIELDS.COUNTRY]: "Country"
}

export const TABLE_COLUMNS = [
    {
        selected: true,
        label: "Location",
        key: TABLE_KEYS.LOCATION_NAME,
        width: 150,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Counties",
        key: TABLE_KEYS.COUNTIES,
        width: 120
    },
    {
        selected: true,
        label: "Social Needs Domains",
        key: TABLE_KEYS.SOCIAL_NEEDS_DOMAIN,
        width: 175,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Services",
        key: TABLE_KEYS.SERVICES,
        width: 150,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Requirements",
        key: TABLE_KEYS.REQUIREMENTS,
        width: 120
    },
    {
        selected: true,
        label: "Primary Contact",
        key: TABLE_KEYS.PRIMARY_CONTACT,
        width: 150,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Phone",
        key: TABLE_KEYS.PHONE,
        width: 140
    },
    {
        selected: true,
        label: "Actions",
        key: TABLE_KEYS.ACTIONS,
        width: 100,
        disableSort: true
    }
]

export const MICHIGAN = "MI"

const RAW_STATE_OPTIONS = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "FA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    MICHIGAN,
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY"
]

export const STATE_OPTIONS = RAW_STATE_OPTIONS.map(state => ({
    label: state,
    value: state
}))

export const USA = "US"
const RAW_COUNTRY_OPTIONS = [USA]

export const COUNTRY_OPTIONS = RAW_COUNTRY_OPTIONS.map(state => ({
    label: state,
    value: state
}))
