import React, { Component } from "react"
import { ADD_USER_DISCLAIMER } from "../../configuration"
import { joinLines } from "../utils"

export default class UserDisclaimer extends Component {
    constructor(props) {
        super(props)

        const disclaimer = ADD_USER_DISCLAIMER.split(
            /\n\s*\n/
        ).map((line, i) => <p key={i}>{joinLines(line.split("\n"))}</p>)

        this.state = { disclaimer }
    }

    render() {
        const className = `disclaimer ${this.props.className}`

        return this.state.disclaimer.length > 1 ? (
            <details className={className}>
                <summary>Legal disclaimer</summary>
                {this.state.disclaimer}
            </details>
        ) : (
            <div className={className}>
                <h5>Legal disclaimer</h5>
                {this.state.disclaimer}
            </div>
        )
    }
}
