import React from "react"
import { connect } from "react-redux"
import { HashLink } from "react-router-hash-link"
import classNames from "classnames"
import { getNextTieredColumn } from "../../table"
import { getResourceData } from "../../core/fetcher"
import { Hoverlay } from "../../core/Hoverlay"
import { MeasureInfoLink } from "../../core/MeasureInfoLink"
import { MEASURES } from "../../constants"
import * as reduxActions from "../actions"
import * as C from "../constants"

export const MeasurePivotValue = ({
    dataKey,
    cellData,
    rowData,
    tableData,
    setChartMeasure,
    measures,
    className
}) => {
    const nextColumn = getNextTieredColumn(dataKey, tableData)

    if (!cellData || rowData[nextColumn?.key]) {
        return null
    }

    const measureId = rowData[C.TABLE_KEYS.MEASURE_ID]
    const measure = measures[measureId] || {}
    const { description, link } = measure

    return (
        <div
            className={classNames(className, "cell-inner-ellipsis", {
                "arrow-cell": nextColumn
            })}
        >
            <Hoverlay
                className="tooltip-blue"
                tooltip={description}
                disabled={!description}
            >
                <HashLink
                    to="#chart"
                    className="measure-performance-link"
                    onClick={() => setChartMeasure(measureId)}
                    smooth
                    replace
                >
                    {cellData}
                </HashLink>
            </Hoverlay>
            <MeasureInfoLink link={link} />
        </div>
    )
}

export default connect(
    state => ({
        measures: getResourceData(
            MEASURES,
            data => data.measuresMap || {}
        )(state)
    }),
    reduxActions
)(MeasurePivotValue)
