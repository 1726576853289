import { createReducer } from "../utils"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.ADD_ACTIVE_NOTIFICATION]: (state, action) => [
        ...state.filter(
            item =>
                item.alertId !== action.payload.alertId ||
                item.loginId !== action.payload.loginId
        ),
        { ...action.payload, active: true }
    ],
    [C.RNS_HIDE_NOTIFICATION]: (state, action) =>
        state.map(item =>
            item.uid === action.uid ? { ...item, active: false } : item
        ),
    [C.SET_CACHE]: (state, action) => action.payload
})

export default reducer
