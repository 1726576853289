import _ from "lodash"
import { createReducer, findAndUpdate, itemsToObject } from "../utils"
import * as DFC from "../dashboard_filters/constants"
import * as C from "./constants"

const sortLowerCase = fave => fave[DFC.TABLE_KEYS.NAME]?.toLowerCase()

const reducer = createReducer(C.initialState, {
    LOGOUT: () => C.initialState,
    FORCE_LOGOUT: () => C.initialState,
    [C.FETCH_USER_PREFERENCES_SUCCESS]: (state, { payload }) => ({
        loading: false,
        columns: itemsToObject(
            payload?.columns,
            ({ tableName }) => tableName,
            ({ columns, frozenColumns }) => ({ columns, frozenColumns })
        ),
        defaultColumns: itemsToObject(
            payload?.defaultColumns,
            ({ tableName }) => tableName,
            ({ columns, frozenColumns }) => ({ columns, frozenColumns })
        ),
        favorites: _.mapValues(payload?.favorites, favorites =>
            favorites?.map(fave => ({
                ...fave,
                [DFC.TABLE_KEYS.FILTERS]: JSON.parse(
                    fave[DFC.TABLE_KEYS.FILTERS]
                )
            }))
        )
    }),
    [C.PERSIST_SETTINGS]: (state, { tableName, payload }) => ({
        ...state,
        columns: { ...state.columns, [tableName]: payload }
    }),
    [C.REMOVE_USER_PREFERENCES]: (state, { tableName }) => ({
        ...state,
        columns: { ...state.columns, [tableName]: undefined }
    }),
    [C.ADD_FAVORITE]: (state, { form, payload }) => ({
        ...state,
        favorites: {
            ...state.favorites,
            [form]: _.sortBy(
                [...(state.favorites?.[form] || []), payload],
                sortLowerCase
            )
        }
    }),
    [C.UPDATE_FAVORITE]: (state, { form, payload }) => ({
        ...state,
        favorites: {
            ...state.favorites,
            [form]: _.sortBy(
                findAndUpdate(
                    state.favorites?.[form],
                    payload,
                    DFC.TABLE_KEYS.ID,
                    payload
                ),
                sortLowerCase
            )
        }
    }),
    [C.REMOVE_FAVORITE]: (state, { form, id }) => ({
        ...state,
        favorites: {
            ...state.favorites,
            [form]: findAndUpdate(
                state.favorites?.[form],
                {
                    [DFC.TABLE_KEYS.DELETED]: true,
                    [DFC.TABLE_KEYS.LOADING]: false
                },
                [DFC.TABLE_KEYS.ID],
                id
            )
        }
    }),
    [C.UNREMOVE_FAVORITE]: (state, { form, id, newId }) => ({
        ...state,
        favorites: {
            ...state.favorites,
            [form]: findAndUpdate(
                state.favorites?.[form],
                {
                    [DFC.TABLE_KEYS.DELETED]: false,
                    [DFC.TABLE_KEYS.LOADING]: false,
                    [DFC.TABLE_KEYS.ID]: newId
                },
                [DFC.TABLE_KEYS.ID],
                id
            )
        }
    }),
    [C.SET_FAVORITE_LOADING]: (state, { form, id }) => ({
        ...state,
        favorites: {
            ...state.favorites,
            [form]: findAndUpdate(
                state.favorites?.[form],
                { [DFC.TABLE_KEYS.LOADING]: true },
                [DFC.TABLE_KEYS.ID],
                id
            )
        }
    }),
    [DFC.REMOVE_DELETED_FAVORITES]: (state, { form }) => ({
        ...state,
        favorites: {
            ...state.favorites,
            [form]: _.reject(state.favorites?.[form], {
                [DFC.TABLE_KEYS.DELETED]: true
            })
        }
    })
})

export default reducer
