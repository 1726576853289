import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"

import Loading from "../../../core/Loading"
import SiteTitle from "../../../core/SiteTitle"
import Breadcrumbs from "../../../core/Breadcrumbs"
import { GuideLink } from "../../../indicators"
import { joinTruthy } from "../../../utils"
import * as C from "../../constants"
import CreateCampaign from "../create_campaign"
import PatientList from "../campaign_patient_list"
import EditCampaignModal, { ShowEditButton } from "../edit_campaign"

export const CampaignPage = ({ data, setPatientFilter, ...props }) => {
    const campaign = data?.campaign

    useEffect(() => {
        // set patient list filter to match campaign filter, so a user can edit it without changing the campaign
        if (campaign?.filter) {
            setPatientFilter(campaign.filter)
        }
    }, [campaign])

    if (props.error) {
        return <Redirect to="/page_not_found" />
    }
    if (!campaign) {
        // campaign will be briefly missing while loading
        return <Loading style={{ height: "400px" }} />
    }

    const campaignName = campaign?.[C.TABLE_KEYS.CAMPAIGN_NAME] || ""
    const title =
        joinTruthy(["Campaign", campaignName], ": ") +
        (props.isModified ? " (modified)" : "")

    return (
        <>
            <SiteTitle title={title} />
            <Breadcrumbs
                items={[
                    ["Dashboard", "/dashboard"],
                    ["Campaigns", "/campaigns"],
                    [campaignName]
                ]}
            />
            <div className="page-header camapign-page-header">
                <h1>
                    {title}
                    <GuideLink page="view-campaign-details" />
                </h1>
                <ShowEditButton />
                <CreateCampaign useCurrentCampaign />
            </div>
            <PatientList {...props} />
            <EditCampaignModal />
        </>
    )
}
