import * as C from "./constants"
import { Check, FormattedDate } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import ReactivateButton from "./components/ReactivateButton"
import { ALIGNMENTS } from "../constants"

const column = columnBuilder(C.TRANSLATION)

export const TABLE_COLUMNS = [
    column(C.TABLE_KEYS.PATIENT_NAME),
    column(C.TABLE_KEYS.GENDER, { width: 70, flexGrow: 0 }),
    column(C.TABLE_KEYS.DOB, {
        width: 110,
        flexGrow: 0,
        cellRenderer: FormattedDate
    }),
    column(C.TABLE_KEYS.ACTIVE, {
        width: 70,
        flexGrow: 0,
        cellRenderer: Check
    }),
    column(C.TABLE_KEYS.USER_NAME),
    column(C.TABLE_KEYS.REASON, { width: 150 }),
    column(C.TABLE_KEYS.EFF_DATE, {
        width: 120,
        flexGrow: 0,
        cellRenderer: FormattedDate
    }),
    column(C.TABLE_KEYS.PCP),
    column(C.TABLE_KEYS.PRACTICE),
    column(C.TABLE_KEYS.ACTIONS, {
        width: 80,
        flexGrow: 0,
        align: ALIGNMENTS.CENTER,
        // headerClassName: "actions",
        // style: { textAlign: "center" },
        disableSort: true,
        cellRenderer: ReactivateButton
    })
]
