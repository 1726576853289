import { connect } from "react-redux"
import UndoRemoveButtons from "../../core/UndoRemoveButtons"
import * as C from "../constants"
import { deleteFile, restoreFile } from "../actions"

export const DeleteCell = connect(
    () => ({
        field: C.TABLE_KEYS.DOCUMENT_ID,
        statusField: C.TABLE_KEYS.DELETED
    }),
    { remove: deleteFile, undo: restoreFile }
)(UndoRemoveButtons)
