import React, { useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import { placeColumnsByKeys } from "../../../table/helpers"
import * as C from "../../constants"
import { CreateCampaignModal } from "./CreateCampaignModal"
import { EMPTY_OBJECT } from "../../../utils"

export const CreateCampaignButton = ({
    usePatientList,
    users,
    units,
    appliedFilters = EMPTY_OBJECT,
    createNewCampaign,
    useCurrentCampaign,
    currentCampaign,
    defaultColumns,
    tableData,
    campaignColumnDefinitions,
    ...props
}) => {
    const [show, setShow] = useState(false)
    const showModal = () => setShow(true)
    const hideModal = () => setShow(false)

    const initialValues = useMemo(() => {
        const { type, ...otherAppliedFilters } = appliedFilters

        const commonInitialValues = {
            ...C.DEFAULT_CAMPAIGN_SETTINGS,
            [C.TABLE_KEYS.USER_PRIVILEGES]: users,
            [C.TABLE_KEYS.UNIT_PRIVILEGES]: units
        }
        if (usePatientList) {
            return {
                ...commonInitialValues,
                type,
                filter: otherAppliedFilters,
                columns: {
                    columns: tableData?.columns,
                    frozenColumns: tableData?.frozenColumns
                }
            }
        } else if (useCurrentCampaign) {
            const { campaign, columns, frozenColumns } = currentCampaign

            return {
                ...commonInitialValues,
                filter: campaign?.filter ?? {},
                columns: placeColumnsByKeys(
                    campaignColumnDefinitions,
                    columns || [],
                    frozenColumns || []
                )
            }
        } else {
            return {
                ...commonInitialValues,
                columns: defaultColumns
            }
        }
    }, [appliedFilters, currentCampaign, campaignColumnDefinitions])

    const createCampaignAndView = () =>
        createNewCampaign({
            ...props.formValues,
            navigateToCampaign: true
        })
    const createCampaignAndClose = () => {
        createNewCampaign({
            ...props.formValues,
            navigateToCampaign: false
        })
        hideModal()
    }

    return (
        <React.Fragment>
            <Button
                variant="primary"
                size="sm"
                className="add-measure pull-right"
                onClick={showModal}
            >
                <span className="fa fa-plus-circle" /> New Campaign
            </Button>
            <CreateCampaignModal
                {...props}
                show={show}
                initialValues={initialValues}
                onHide={hideModal}
                onSaveAndClose={createCampaignAndClose}
                onSaveAndView={createCampaignAndView}
            />
        </React.Fragment>
    )
}
