import { PERIODS } from "../pickers/constants"
import * as CC from "../cost/constants"

export const NAME = "PATIENT_COST"
export const TABLE_NAME = "PATIENT_COST_TABLE"
export const PATIENT_COST_CHART = "PATIENT_COST_CHART"
export const PATIENT_COST_DETAIL = "PATIENT_COST_DETAIL"

export const SET_PATIENT_COST_PERIOD = "SET_PATIENT_COST_PERIOD"
export const SET_PATIENT_COST_CHART_FILTERS = "SET_PATIENT_COST_CHART_FILTERS"
export const SET_PATIENT_COST_MODAL_FILTERS = "SET_PATIENT_COST_MODAL_FILTERS"

export const PERIOD = "period"
export const PAYERS = "payers"
export const PRODUCT_CLASSES = "productClasses"
export const PAYER_LOGOS = "payerLogos"

export const ALL_PAYERS_OPTION = {
    label: "All payers",
    value: ""
}

export const TABLE_KEYS = {
    ...CC.TABLE_KEYS,
    PAYER_LOGO: "payerLogo",
    MONTHLY_COST: "costMonthly"
}

export const MODAL_FILTERS = [
    TABLE_KEYS.PAYER_ID,
    TABLE_KEYS.PRODUCT_ID,
    TABLE_KEYS.CATEGORY_ID,
    TABLE_KEYS.SUBCATEGORY_ID
]

export const TABLE_TRANSLATION = {
    ...CC.TABLE_TRANSLATION,
    [CC.TABLE_KEYS.DISPLAY_NAME]: "Payer/Category",
    [TABLE_KEYS.MONTHLY_COST]: "Cost per Month"
}

export const initialState = {
    [PERIOD]: PERIODS.CURRENT_YEAR,
    chartFilters: {},
    modalFilters: {}
}
