import React from "react"
import { PrintRadio } from "../PrintItem"

export const BatchPrintTogether = ({ input: { onChange, value } }) => (
    <div className="batch-print-together">
        <hr />

        <PrintRadio
            id={true}
            selected={value}
            onClick={onChange}
            label="Print all reports together in a single pdf"
            style={{
                fontWeight: value ? "bold" : "normal"
            }}
        />
        <PrintRadio
            id={false}
            selected={value}
            onClick={onChange}
            label="Download individual report pdfs in a zip file"
            style={{
                fontWeight: value ? "normal" : "bold"
            }}
        />
    </div>
)
