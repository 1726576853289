import { connect } from "react-redux"
import fetcher, { getMetaData, getResourceData } from "../../core/fetcher"
import { VIEWS } from "../../pickers/constants"
import {
    categoryNodesSelector,
    categoryTreeSelector,
    fundStampLookupSelector
} from "../../cost/selectors"
import { normalizeCostSummaries } from "../../cost/helpers"
import { ApiTypes } from "../../api"
import { urlWithParams } from "../../utils"
import * as C from "../constants"
import * as actions from "../actions"
import {
    filtersSelector,
    initialValuesSelector,
    networkDataSelector,
    requestBodySelector
} from "../selectors"
import CostDashboard from "./CostDashboard"

const dataSources = {
    [VIEWS.PRACTICE]: getResourceData(C.NAME, data => data.costSummaries),
    [VIEWS.PROVIDER]: getResourceData(C.NAME, data => data.costSummaries),
    [VIEWS.NETWORK]: networkDataSelector
}

export default fetcher({
    name: C.NAME,
    endpoint: state =>
        urlWithParams(
            `/api/cost/dashboard/${state[C.NAME].aggregation}`,
            requestBodySelector(state)
        ),
    acceptResponse: ({ costSummaries }) => ({
        costSummaries: normalizeCostSummaries(costSummaries)
    }),
    method: ApiTypes.GET
})(
    connect(
        state => ({
            ...state[C.NAME],
            filters: filtersSelector(state),
            categoryTree: categoryTreeSelector(state),
            categoryNodes: categoryNodesSelector(state),
            fundStampLookup: fundStampLookupSelector(state),
            initialValues: initialValuesSelector(state),
            data: dataSources[state[C.NAME].aggregation](state),
            ...getMetaData(C.NAME)
        }),
        actions
    )(CostDashboard)
)
