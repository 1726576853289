import { VIEWS } from "../pickers/constants"
import { FILTERS, POPULATION_VALUES } from "../patient_list/constants"
export { COMPLIANCE } from "../constants"

export const NAME = "RISK_DASHBOARD"
export const RISK_FILTERS_FORM = "RISK_FILTERS_FORM"

// actions
export const SET_RISK_AGGREGATION = "SET_RISK_AGGREGATION"
export const SET_RISK_FILTERS = "SET_RISK_FILTERS"
export const PRINT_RISKS = "PRINT_RISKS"
export const EXPORT_RISKS = "EXPORT_RISKS"

export const TABLE_KEYS = {
    CONDITION_ID: "conditionId",
    CONDITION_NAME: "conditionName",
    PRACTICE_UNIT_ID: "practiceUnitId",
    PRACTICE_NAME: "practiceName",
    PROVIDER_ID: "providerId",
    PROVIDER_NAME: "providerName",
    ELIGIBLE: "numElig",
    MET: "numMet",
    UNMET: "numUnmet",
    NEEDED: "numNeeded",
    RATE_TO_DATE: "rateToDate",
    GOAL_RATE: "goalRate",
    NETWORK_RATE: "networkRate",
    RAF_AVAILABLE: "rafAvailable",
    RAF_EARNED: "rafEarned",
    RAF_REMAINING: "rafRemaining",
    RAF_AVAILABLE_USD: "rafAvailableUsd",
    RAF_EARNED_USD: "rafEarnedUsd",
    RAF_REMAINING_USD: "rafRemainingUsd",
    INCENTIVE_DIFFERENCE: "incentiveDifference",
    RATE_BREAKDOWN: "rate_breakdown",
    MONEY_BREAKDOWN: "money_breakdown"
}
export const TABLE_TRANSLATION = {
    [TABLE_KEYS.CONDITION_NAME]: "Condition",
    [TABLE_KEYS.PROVIDER_NAME]: "Provider",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice Unit",
    [TABLE_KEYS.RATE_TO_DATE]: "Current",
    [TABLE_KEYS.NETWORK_RATE]: "Network",
    [TABLE_KEYS.GOAL_RATE]: "Target",
    [TABLE_KEYS.ELIGIBLE]: "Total Elig",
    [TABLE_KEYS.MET]: "Met",
    [TABLE_KEYS.UNMET]: "Not Met",
    [TABLE_KEYS.RAF_AVAILABLE]: "RAF Available",
    [TABLE_KEYS.RAF_EARNED]: "RAF Earned",
    [TABLE_KEYS.RAF_REMAINING]: "RAF Remaining",
    [TABLE_KEYS.RAF_AVAILABLE_USD]: "RAF $Available",
    [TABLE_KEYS.RAF_EARNED_USD]: "RAF $Earned",
    [TABLE_KEYS.RAF_REMAINING_USD]: "RAF $Remaining",
    [TABLE_KEYS.RATE_BREAKDOWN]: "Closure Rate",
    [TABLE_KEYS.MONEY_BREAKDOWN]: "Revenue Adjustment",
    [TABLE_KEYS.INCENTIVE_DIFFERENCE]: "Incentive Reconciliation"
}

export const AGGREGATION_MEASURES = {
    [VIEWS.NETWORK]: TABLE_KEYS.CONDITION_ID,
    [VIEWS.PRACTICE]: TABLE_KEYS.PRACTICE_NAME,
    [VIEWS.PROVIDER]: TABLE_KEYS.PROVIDER_NAME
}

export const initialState = {
    aggregation: VIEWS.NETWORK,
    filters: { [FILTERS.POPULATION]: POPULATION_VALUES.ALL }
}
