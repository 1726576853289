import { call, put, select } from "redux-saga/effects"
import Notifications from "react-notification-system-redux"
import { getStoredState } from "redux-persist"
import fileDownload from "js-file-download"
import { FetchError } from "./core/FetchError"
import {
    forceLogoutAction,
    updatePasswordToken
} from "./authentication/actions"
import * as C from "./authentication/constants"
import _ from "lodash"

export const ApiTypes = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE"
}

export const DEFAULT_HEADERS = {
    "Content-Type": "application/json"
}
export const getAuthHeaders = ({ authToken }) => ({
    Authorization: `Bearer ${authToken}`
})
export const getDefaultHeaders = auth => ({
    ...DEFAULT_HEADERS,
    ...getAuthHeaders(auth)
})

export function* tryFetch(request, success, failure, options = {}) {
    try {
        const body = yield* fetchAndCheck(request, options)
        return success ? yield* success(body) : yield body
    } catch (error) {
        console.error(error.toString())
        return failure ? yield* failure(error) : yield error
    }
}

const getFullRequest = request => ({ method: ApiTypes.GET, ...request }) // this is a pretty reasonable default

export function* fetchAndCheck(request, options = {}) {
    const fullRequest = getFullRequest(request)

    const response = yield call(api, fullRequest)

    if (!response) {
        throw yield call(FetchError.new, fullRequest)
        // if you end up here a lot and don't know why, you may need to add `needsBody: false` to the options argument
    }

    return yield* handleResponse(response, request, options)
}

// a wrapper around fetch(), so we can test higher functions without interacting with the Fetch API directly.
function* api(request) {
    try {
        const authentication = yield select(state => state.authentication)
        return yield call(createFetch, {
            ...request,
            authentication
        })
    } catch (error) {
        // yield fetch only throws if it's aborted or there's a network error
        if (error.name === "AbortError") {
            console.warning("Request aborted: ", error)
        } else {
            console.error("Network error:", error)
        }
        return yield error
    }
}

// note that this only returns a Promise. You need to yield it to fetch the results.
export const createFetch = ({ url, method, body, authentication }) =>
    fetch(url, {
        method,
        headers: getDefaultHeaders(authentication),
        body: _.isString(body) ? body : JSON.stringify(body)
    })

export function* handleResponse(response, request, options = {}) {
    const {
        reader = r => r.json(),
        needsBody = true, // set to false if the thing we read from the response isn't actually the body per se. This is mainly the case if we're downloading a file.
        defaultReturn = null
    } = options

    const hasContent = !!response.headers?.get("content-type")

    if (response.status === 401) {
        if (!(yield* tryReauthenticate(request, options))) {
            yield put(
                forceLogoutAction({
                    title:
                        "Your authentication has expired, and you have been logged out."
                })
            )
        }
    } else if (!response.ok || response.status >= 400) {
        throw yield call(FetchError.new, getFullRequest(request), response)
    } else if (response.redirected) {
        // TODO figure out how redirects should be handled
    } else if (hasContent || !needsBody) {
        return yield call(reader, response)
    }

    return yield defaultReturn
}

// an alternate version of tryFetch specifically for downloading files
// TODO implement some of the QOL stuff from tryWithNotifications
export function* downloadFile(
    label,
    endpoint,
    fallbackFilename,
    {
        request,
        onSuccess = fileDownload,
        pendingMessage = `Generating ${label}...`,
        successMessage = `${_.upperFirst(label)} downloaded!`
    } = {}
) {
    const uid = "DOWNLOAD_" + endpoint

    try {
        yield put(
            Notifications.warning({
                message: pendingMessage,
                position: "tc",
                autoDismiss: 0,
                dismissible: false,
                uid
            })
        )

        const { blob, filename } = yield* fetchAndCheck(
            { url: endpoint, ...request },
            { reader: blobReader, needsBody: false } // it's not technically the body being read
        )
        onSuccess(blob, filename || fallbackFilename)

        yield put(Notifications.hide(uid))
        yield put(
            Notifications.success({
                message: successMessage,
                position: "tc",
                autoDismiss: 3
            })
        )
    } catch (error) {
        console.error(error.toString())
        yield put(Notifications.hide(uid))
        yield put(
            Notifications.error({
                title: "Unable to download file.",
                message: error.message,
                position: "tc",
                autoDismiss: 0
            })
        )
    }
}

export function* blobReader(response) {
    return {
        blob: yield response.blob(),
        filename: _.trim(
            parseHeader(response, "Content-Disposition")?.filename,
            '"'
        )
    }
}

const parseHeader = (response, key) => {
    const header = response.headers.get(key)
    return _.fromPairs(header?.split(";")?.map(substr => substr.split("=")))
}

export function* uploadFiles(url, body, options = {}) {
    const authentication = yield select(state => state.authentication)
    const request = {
        method: ApiTypes.POST,
        headers: getAuthHeaders(authentication), // don't set content-type
        body: body
    }
    const response = yield call(fetch, url, request)

    return yield* handleResponse(response, request, options)
}

export function* warnAboutPdfColumns(extension, sortedColumns) {
    if (extension !== "pdf" || sortedColumns.column.length < 20) {
        return
    }
    yield put(
        Notifications.warning({
            title:
                "Warning: printing 20+ columns may make the PDF look distorted.",
            position: "tc",
            autoDismiss: 10
        })
    )
}

export const openFileInNewTab = blob => {
    const blobUrl = URL.createObjectURL(blob)
    window.open(blobUrl, "_blank")
    URL.revokeObjectURL(blobUrl)
}

// Our auth token expired, but we may have a newer one in local storage. Usually that's because another tab refreshed it. Check for a newer one and retry with that.
function* tryReauthenticate(request, options) {
    const storedState = yield call(getStoredState, { whitelist: [C.NAME] })
    const storedAuth = storedState[C.NAME]
    const auth = yield select(state => state[C.NAME])

    if (!options.canReauthenticate || auth.authToken === storedAuth.authToken) {
        return false
    }

    yield put(updatePasswordToken(storedAuth))
    yield put(fetchAndCheck(request, { ...options, canReauthenticate: false })) // won't cause an infinite loop because canReauthenticate: false stops it
    return true
}
