import { connect } from "react-redux"
import { reduxForm } from "redux-form"
import fetcher, { getResourceData } from "../../core/fetcher"
import { FILTER_OPTIONS } from "../../constants"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as reduxActions from "../actions"
import { validateSelf } from "./validateUser"
import MyAccountModal from "./MyAccountModal"

const MyAccountForm = reduxForm({
    form: C.MY_PROFILE_FORM,
    enableReinitialize: true,
    validate: validateSelf
})(MyAccountModal)

const MyAccountContainer = connect(
    (state, { data }) => ({
        ...state[C.MY_PROFILE_NAME],
        initialValues: data,
        isAdmin: state.authentication.userId === 0,
        roleOptions: getResourceData(FILTER_OPTIONS, data => data.roles)(state)
    }),
    reduxActions
)(MyAccountForm)

export default fetcher({
    name: C.MY_PROFILE_NAME,
    endpoint: "/api/users/me",
    method: ApiTypes.GET,
    requestBodySelector: state => state[C.MY_PROFILE_NAME].updateCount
})(MyAccountContainer)
