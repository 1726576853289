import React from "react"
import classNames from "classnames"
import { Hoverlay } from "../../core/Hoverlay"

export const GuideLink = ({
    page,
    hash = "",
    className,
    children,
    style,
    tooltip = "Click here for a guide",
    tooltipClass,
    ...props
}) => (
    <Hoverlay
        tooltip={tooltip}
        className={classNames("tooltip-blue", tooltipClass)}
        placement="right"
    >
        {({ ref, ...triggerHandler }) => (
            <a
                className={classNames("guide-link", className)}
                href={`https://guide.healthfocus.io/${page}.html#${hash}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    cursor: tooltip || page ? "pointer" : undefined,
                    ...style
                }}
                {...props}
                {...triggerHandler}
            >
                {children && <span>{children}</span>}{" "}
                <i className="fa indicator-info" ref={ref} />
            </a>
        )}
    </Hoverlay>
)

export default GuideLink
