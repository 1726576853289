import React from "react"
import { Card } from "react-bootstrap"
import LER from "../../core/LoadingErrorHOC"
import fetcher from "../../core/fetcher"
import * as C from "../constants"
import { ApiTypes } from "../../api"

export const InactivePatientsTable = ({ Table, error, data, loading }) => (
    <Card>
        <LER loading={loading} error={error} list={data.patients}>
            <Table list={data.patients} />
        </LER>
    </Card>
)

export default fetcher({
    name: C.NAME,
    method: ApiTypes.GET,
    endpoint: "/api/patients/inactive"
    // acceptResponse: modifyData("patients", { [C.TABLE_KEYS.ACTIVE]: false })
})(InactivePatientsTable)
