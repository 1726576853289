import React, { useMemo } from "react"
import { pie } from "billboard.js"
import ProductChart from "./ProductChart"

const PieChart = ({ data, ...props }) => {
    const chartData = useMemo(
        () => ({
            ...data,
            type: pie()
        }),
        [data]
    )

    return <ProductChart {...props} data={chartData} name="pie-chart" />
}

export default PieChart
