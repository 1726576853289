import { createReducer } from "../utils"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.SHOW_BATCH_PRINT]: state => ({ ...state, show_bp: true }),
    [C.HIDE_BATCH_PRINT]: state => ({ ...state, show_bp: false }),
    [C.SHOW_POC_PRINT]: state => ({ ...state, show_poc: true }),
    [C.HIDE_POC_PRINT]: state => ({ ...state, show_poc: false }),
    [C.SUBMIT_BATCH_PRINT]: state => ({
        ...state,
        printing: true
    }),
    [C.SUBMIT_POC_PRINT]: state => ({
        ...state,
        printing: true
    }),
    [C.SUBMIT_PRINT_SUCCESS]: state => ({
        ...state,
        printing: false,
        show_bp: false,
        show_poc: false
    }),
    [C.SUBMIT_PRINT_ERROR]: state => ({
        ...state,
        printing: false
        // any modals that are open will stay open
    }),
    UPDATE_BATCH_PRINT_PERCENTAGE: (state, action) => ({
        ...state,
        downloadPercentage: action.payload
    })
})
export default reducer
