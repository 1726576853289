import fetcher from "../../../core/fetcher"
import { ApiTypes } from "../../../api"
import * as C from "../../constants"
import { ReportList } from "./ReportList"

export default fetcher({
    name: C.AVAILABLE_REPORTS,
    endpoint: "/api/reports",
    method: ApiTypes.GET
})(ReportList)
