import React from "react"
import classNames from "classnames"

export const ButtonGroup = ({
    value,
    onClick = () => {},
    options,
    disabled,
    className,
    ...props
}) => (
    <div className={classNames("button-group", className)} {...props}>
        {options.map(option => (
            <button
                type="button"
                key={option.value}
                disabled={disabled}
                onMouseDown={() => onClick(option.value)} // onClick doesn't work right if you're clicking to this button from another input, but onMouseDown does.
                className={classNames("btn btn-default", {
                    selected: option.value === value
                })}
            >
                {option.label ?? option.value}
            </button>
        ))}
    </div>
)
