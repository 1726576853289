import React from "react"
import { connect } from "react-redux"

import fetcher from "../../core/fetcher"
import { transformAndFilterFilters } from "../../transformFilter"
import { rowCountSelector } from "../../selectorUtils"
import { urlWithParams } from "../../utils"
import { tableFactory } from "../../table"

import { MEASURES_TABLE_COLUMNS, PATIENTS_TABLE_COLUMNS } from "../columns"
import * as C from "../constants"
import * as reduxActions from "../actions"
import {
    isDefaultSelector,
    patientListSelector,
    tableDataSelector,
    translationSelector
} from "../selectors"
import PatientList from "./PatientList"

export { default as Title } from "./Title"
export { default as CellRenderer } from "./CellRenderer"
export { default as FilterPanel } from "./FilterPanel"
export { default as PatientListCostChart } from "./PatientListCostChart"
export { GeneratePLFromMenu as GeneratePL } from "./GeneratePatientList"
export { ClearFiltersButton } from "./ClearFiltersButton"

const PatientSummary = tableFactory({
    name: C.FILTER_TYPES.PATIENT_SUMMARY,
    columns: PATIENTS_TABLE_COLUMNS,
    ...C.SUMMARY_DEFAULT_SORT
})

const PatientMeasures = tableFactory({
    name: C.FILTER_TYPES.PATIENT_MEASURES,
    columns: MEASURES_TABLE_COLUMNS,
    ...C.MEASURES_DEFAULT_SORT
})

export const getEndpoint = state => {
    const filter = transformAndFilterFilters(state.applied_filters)
    return urlWithParams(`/api/patients`, filter)
}

const patientCountEndpoint = state => {
    const filter = transformAndFilterFilters(state.applied_filters)
    return urlWithParams(`/api/patients/length`, filter)
}

const PatientSummaryList = fetcher({
    name: C.NAME,
    endpoint: getEndpoint,
    defaultOrderBys: C.SUMMARY_DEFAULT_SORT,
    paginationKey: "patients"
})(PatientList)

const PatientMeasureList = fetcher({
    name: C.NAME,
    endpoint: getEndpoint,
    defaultOrderBys: C.MEASURES_DEFAULT_SORT,
    paginationKey: "patients"
})(PatientList)

export const PatientListContainer = props => {
    React.useEffect(() => window.scrollTo(0, 0))

    return props.filters[C.FILTERS.TYPE] === C.FILTER_TYPES.PATIENT_SUMMARY ? (
        <PatientSummaryList {...props} {...PatientSummary} />
    ) : (
        <PatientMeasureList {...props} {...PatientMeasures} />
    )
}

export default connect(
    state => ({
        filters: state.applied_filters,
        list: patientListSelector(state),
        patientCountEndpoint,
        patientCountName: C.PATIENT_COUNT,
        rowCount: rowCountSelector(C.PATIENT_COUNT)(state),
        tableData: tableDataSelector(state),
        isDefault: isDefaultSelector(state),
        headerTranslation: translationSelector(state)
    }),
    reduxActions
)(PatientListContainer)
