import React from "react"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { getFormValues, reset } from "redux-form"
import Notifications from "react-notification-system-redux"
import { push } from "connected-react-router"
import { setPocId } from "../structure/actions"
import { ApiTypes, tryFetch } from "../api"
import { usToIsoDate } from "../dates"
import { notifySimpleError } from "../notifications"
import { hideAddPatientModal } from "./actions"
import * as C from "./constants"

export function* savePatient() {
    const pendingNotif = Notifications.warning({
        message: "Saving Patient",
        position: "tc",
        autoDismiss: 0,
        dismissible: false
    })
    yield put(pendingNotif)

    const values = yield select(state =>
        getFormValues(C.ADD_NEW_PATIENT_FORM)(state)
    )

    const request = {
        url: "/api/patients",
        method: ApiTypes.POST,
        body: {
            ...values,
            [C.ADD_NEW_PATIENT_FIELDS.DATE_OF_BIRTH]: usToIsoDate(
                values[C.ADD_NEW_PATIENT_FIELDS.DATE_OF_BIRTH]
            ),
            patCareManager: {
                userId: values[C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_ID],
                effDt: usToIsoDate(
                    values[C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_EFF_DT]
                ),
                termDt: usToIsoDate(
                    values[C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_TERM_DT]
                )
            }
        }
    }

    // noinspection JSAnnotator,JSUnresolvedVariable,BadExpressionStatementJS
    // FIXME this should use tryWithNotifications instead
    yield* tryFetch(
        request,
        function*(body) {
            yield handleSuccess(body, pendingNotif)
        },
        function*(error) {
            yield handleError(error, values, pendingNotif)
        }
    )
}

function* handleSuccess(responseBody, pendingNotif) {
    yield put(hideAddPatientModal())
    yield put(reset(C.ADD_NEW_PATIENT_FORM))

    yield hideNotification(pendingNotif)

    yield put(
        Notifications.success({
            children: (
                <div>
                    <strong>Patient saved!</strong>
                    <br />
                    The patient may not appear in patient lists immediately.
                </div>
            ),
            position: "tc",
            autoDismiss: 7
        })
    )
    const { patientId } = responseBody
    if (!patientId) {
        // PoC page isn't ready
        return
    }

    yield put(
        Notifications.success({
            message: "Redirecting to new patient's Point of Care page...",
            position: "tc",
            autoDismiss: 7
        })
    )
    yield put(setPocId(patientId))
    yield put(push(`/poc/${patientId}`))
}

function* handleError(error, formValues, pendingNotif) {
    yield hideNotification(pendingNotif)

    const patientInfo = getPatientIdentifier(formValues)

    if (error.response.status === 409) {
        yield notifySimpleError(`Error: Patient ${patientInfo} already exists`)(
            error
        )
    } else {
        yield notifySimpleError(
            `Error: Could not create patient ${patientInfo}`
        )(error)
    }
}

const getPatientIdentifier = formValues =>
    `'${formValues[C.ADD_NEW_PATIENT_FIELDS.FIRST_NAME]} ${
        formValues[C.ADD_NEW_PATIENT_FIELDS.LAST_NAME]
    } - ${formValues[C.ADD_NEW_PATIENT_FIELDS.DATE_OF_BIRTH]} - ${
        formValues[C.ADD_NEW_PATIENT_FIELDS.GENDER]
    }'`

function* hideNotification(notification) {
    yield put(Notifications.hide(notification.uid))
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SAVE_PATIENT, savePatient)
        })
    ])
}
