import React from "react"
import { connect } from "react-redux"
import { Button, Modal } from "react-bootstrap"
import Loading from "../../core/Loading"
import { PrintCheckbox } from "../../batch_print/components/PrintItem"
import { moduleEnabledSelector } from "../../moduleUtils"
import * as C from "../../constants"
import { printQSR } from "../actions"

class PrintModal extends React.Component {
    state = { open: false, report: false, dashboard: false, coverages: false }
    handleOpen = () =>
        this.setState({
            open: true,
            report: false,
            dashboard: false,
            coverages: false
        })
    handleHide = () => this.setState({ open: false })

    setReport = report => this.setState({ report })
    setDashboard = dashboard => this.setState({ dashboard })
    setCoverages = coverages => this.setState({ coverages })

    handlePrint = () => {
        if (!this.props.canPrint) return

        this.props.printQSR({
            report: this.state.report,
            dashboard: this.state.dashboard,
            coverages: this.state.coverages,
            ...this.props.qsrState
        })
        this.handleHide()
    }

    render() {
        if (!this.props.canPrint) return null

        const disablePrint =
            !this.state.report && !this.state.dashboard && !this.state.coverages
        return (
            <>
                <Button
                    variant="primary"
                    size="sm"
                    className="pull-right"
                    onClick={this.handleOpen}
                >
                    <span className="fa fa-print" /> Print
                </Button>
                <Modal show={this.state.open} onHide={this.handleHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Print</Modal.Title>
                    </Modal.Header>
                    {this.props.loading ? (
                        <Loading />
                    ) : (
                        <Modal.Body>
                            <PrintCheckbox
                                selected={this.state.report}
                                onClick={this.setReport}
                                label="Quality Summary Report"
                            />
                            <PrintCheckbox
                                selected={this.state.dashboard}
                                onClick={this.setDashboard}
                                label="Quality Summary Visuals"
                            />
                            <PrintCheckbox
                                selected={this.state.coverages}
                                onClick={this.setCoverages}
                                label="Date Coverages"
                            />
                        </Modal.Body>
                    )}
                    <Modal.Footer>
                        {!this.props.loading && (
                            <React.Fragment>
                                <Button
                                    variant="secondary"
                                    onClick={this.handleHide}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={this.handlePrint}
                                    disabled={disablePrint}
                                >
                                    <span className="fa fa-print" /> Print
                                </Button>
                            </React.Fragment>
                        )}
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default connect(
    state => ({
        canPrint: moduleEnabledSelector(C.MODULE_IDS.EXTRACTS)(state)
    }),
    {
        printQSR
    }
)(PrintModal)
