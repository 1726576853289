import React from "react"
import moment from "moment"
import LEHOC from "../../../core/LoadingErrorHOC"
import fetcher from "../../../core/fetcher"
import { dateFormat } from "../../../dates"
import { ApiTypes } from "../../../api"

const HistoricalItem = ({
    oddRow,
    communityLocation,
    srvStatusDescription,
    srvDt,
    comment,
    lastUserLoginId
}) => (
    <tr className={`${oddRow ? "oddRow" : ""} historical_row`}>
        <td>{dateFormat(srvDt)}</td>
        <td>{communityLocation}</td>
        <td>{srvStatusDescription}</td>
        <td className="comment-field" title={comment}>
            {comment}
        </td>
        <td>{lastUserLoginId}</td>
    </tr>
)

export const HistoryList = props => {
    const {
        loading,
        error,
        data: { communityLinkageHistory }
    } = props
    return (
        <LEHOC loading={loading} error={error} list={communityLinkageHistory}>
            <div>
                <table className="table popover-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Location</th>
                            <th>Status</th>
                            <th>Comment</th>
                            <th>User</th>
                        </tr>
                    </thead>
                </table>
                <div id="scrolled_table">
                    <table
                        className="popover-table"
                        style={{ tableLayout: "fixed" }}
                    >
                        <tbody>
                            {communityLinkageHistory &&
                                communityLinkageHistory
                                    .sort((a, b) =>
                                        moment.utc(a.srvDt).isBefore(b.srvDt)
                                            ? 1
                                            : -1
                                    )
                                    .map((item, index) => (
                                        <HistoricalItem
                                            key={item.communityLinkId}
                                            oddRow={index % 2 === 1}
                                            {...item}
                                        />
                                    ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LEHOC>
    )
}

export default fetcher({
    name: "COMMUNITY_LINKAGES_HISTORY",
    endpoint: (state, ownProps) =>
        `/api/patients/${state.poc_id}/linkages/${ownProps.id}/history`,
    method: ApiTypes.GET,
    persistResource: true
})(HistoryList)
