import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { modifyResource } from "../core/fetcher"
import { tryWithNotifications } from "../notifications"
import { ApiTypes } from "../api"
import { usToIsoDate } from "../dates"
import * as C from "./constants"
import * as actions from "./actions"
import { transformData, transformPayload } from "./helpers"

function* saveCareManager(action) {
    const payload = action.payload || {}
    const careManager = transformPayload(payload)

    yield put(actions.updateInitialValues(payload)) // so the values will stay in the form if saving fails

    if (!careManager.userId) {
        console.error("Cannot delete care managers!")
    }

    if (payload.isUpdate) {
        const prevEffDt = usToIsoDate(payload.prevEffDt)
        yield sendCareManagerRequest(ApiTypes.PUT, {
            careManager,
            prevEffDt
        })
    } else {
        yield sendCareManagerRequest(ApiTypes.POST, { careManager })
    }
}

function* sendCareManagerRequest(method, body) {
    const poc_id = yield select(state => state.poc_id)

    yield tryWithNotifications(
        {
            url: `/api/patients/${poc_id}/care_team`,
            method,
            body
        },
        function*(body) {
            yield put(
                modifyResource({
                    name: C.NAME,
                    dataTransform: () => transformData(body)
                })
            )
            yield put(actions.hideCareManagerModal())
        },
        function*() {
            yield put(actions.stopSavingCareManager())
            return method === ApiTypes.PUT
                ? "Failed to update care manager information."
                : "This patient already has an entry for this care manager with this start date. Please enter a different date, or edit an existing entry."
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SAVE_CARE_MANAGER, saveCareManager)
        })
    ])
}
