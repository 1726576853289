import React from "react"
import { Row, Col } from "react-bootstrap"
import _ from "lodash"
import { formatDollarsCents } from "../../numbers"
import { CodeTiles } from "./CodeTiles"
import { CodeTile } from "./CodeTile"

export const EncounterSection = ({ encounter }) => (
    <section>
        <h5>{encounter.renderingProviderName}</h5>
        <Row as="dl" className="metadata">
            <Col xs={5}>
                <dt>Total Cost</dt>:
                <dd>
                    <strong>{formatDollarsCents(encounter.costTotal)}</strong>
                </dd>
            </Col>
            <Col xs={5}>
                <dt>Service From</dt>:<dd>{encounter.startDate}</dd>
            </Col>
            <Col xs={5}>
                {_.isEmpty(encounter.drg) || (
                    <React.Fragment>
                        <dt>DRG</dt>:
                        {encounter.drg.map((drg, index) => (
                            <CodeTile key={index} {...drg} />
                        ))}
                    </React.Fragment>
                )}
            </Col>
            <Col xs={5}>
                <dt>Service To</dt>:<dd>{encounter.endDate || "(ongoing)"}</dd>
            </Col>
        </Row>
        <dl className="codes">
            <CodeTiles
                title="Procedures"
                data={encounter.procedures?.filter(p => !!p.code)}
                tilePropGetter={p => ({
                    ...p,
                    extra: (
                        <span>
                            {" - "}
                            <span className="cost">
                                {formatDollarsCents(p.cost)}
                            </span>
                        </span>
                    )
                })}
            />
            <CodeTiles
                title="Diagnoses"
                data={encounter.diagnoses}
                className="inline-tiles"
            />
            <CodeTiles
                title="UBREV"
                data={encounter.ubrev}
                className="smaller inline-tiles"
            />
        </dl>
    </section>
)
