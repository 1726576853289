import React from "react"
import _ from "lodash"
import Loading from "./Loading"

export const ErrorPanel = ({
    style,
    message = "Uh oh, an error has occurred"
}) => (
    <div className="loader ler-error" style={style}>
        {message}
    </div>
)

export const NoResults = ({ style, message = "No Results" }) => (
    <div className="loader ler-empty" style={style}>
        {message}
    </div>
)

// Note that height doesn't have to be a number. It can be anything that works as inline style, so "100%" or "10em" works just fine
const LER = ({
    loading,
    error,
    list,
    height, // this could be part of the style prop, but it's convenient this way
    style,
    errorMessage,
    emptyMessage,
    hideEmpty,
    children
}) =>
    loading ? (
        <Loading style={{ height, ...style }} />
    ) : error ? (
        <ErrorPanel style={{ height, ...style }} message={errorMessage} />
    ) : hideEmpty && _.isEmpty(list) ? null : !hideEmpty &&
      list &&
      _.isEmpty(list) ? (
        <NoResults style={{ height, ...style }} message={emptyMessage} />
    ) : (
        children
    )

export default LER
