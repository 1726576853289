import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Route, Switch } from "react-router-dom"

import Loading from "../../core/Loading"
import { getResourceDataAsObject } from "../../core/fetcher"
import { uncrush, urlParamsObject } from "../../utils"
import * as reduxActions from "../actions"
import * as C from "../constants"
import CampaignList from "./campaign_list"
import CampaignPage from "./campaign_page"
import FavoriteCampaigns from "./FavoriteCampaigns"
import CreateCampaign from "./create_campaign"
import PatientCampaigns from "./patient_campaigns"

export { CreateCampaign, FavoriteCampaigns, PatientCampaigns }

const CampaignRedirect = ({
    campaignId,
    campaign,
    location,
    setCampaignFilters,
    setCurrentCampaign,
    ...props
}) => {
    useEffect(() => {
        setCurrentCampaign(Number(props.match.params.id))
    }, [])
    useEffect(() => {
        if (!location.search) {
            setCampaignFilters(campaign?.filter)
            return
        }

        const params = urlParamsObject(location.search)
        if (!params.filters) {
            setCampaignFilters(campaign?.filter)
            return
        }

        const filters = uncrush(params.filters)
        setCampaignFilters(filters) // campaigns never merge defaults into manually-set filters
    }, [location.search])

    return campaignId ? (
        <CampaignPage {...props} />
    ) : (
        <Loading style={{ height: "400px" }} />
    )
}

const CampaignConnect = connect(
    state => ({
        campaignId: state[C.NAME][C.CURRENT_CAMPAIGN],
        campaign: getResourceDataAsObject(
            C.CAMPAIGN_PAGE,
            data => data.campaign
        )(state)
    }),
    reduxActions
)(CampaignRedirect)

export default () => (
    <Switch>
        <Route exact path="/campaigns" component={CampaignList} />
        <Route exact path="/campaigns/:id" component={CampaignConnect} />
    </Switch>
)
