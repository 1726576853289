import React from "react"
import { connect } from "react-redux"

import fetcher, { getMetaData, modifyData } from "../../core/fetcher"
import LER from "../../core/LoadingErrorHOC"
import { getPivotIdKeys } from "../../table/helpers"
import { ApiTypes } from "../../api"
import { SETTINGS } from "../../constants"
import { mapValuesToObject } from "../../utils"

import {
    lookupSelector,
    optionsSelector,
    qsrRowsSelector,
    qsrFiltersSelector,
    activeQsrFiltersSelector,
    qsrInitialValuesSelector,
    qsrEndpointSelector
} from "../selectors"
import * as actions from "../actions"
import * as C from "../constants"
import { QSR_COLUMNS } from "../columns"
import QSR from "./QSR"
import QsrOptions from "./QsrOptions"
import CoverageReference from "./CoverageReference"

export { CoverageReference, QsrOptions }

const normalizeQsrs = modifyData("qsrs", qsr => ({
    ...qsr,
    ...mapValuesToObject(getPivotIdKeys(QSR_COLUMNS), id => qsr[id] || -1)
    // the data seems to use 0 for unknown payers (and possibly others). That doesn't work well with paths, which compact out falsy values. So we need to replace all 0s in the raw data with -1s instead.
}))

const QSRFetcher = fetcher({
    name: C.NAME,
    endpoint: qsrEndpointSelector,
    method: ApiTypes.GET,
    acceptResponse: normalizeQsrs
})(QSR)

// don't want to fetch QSR until settings are loaded
const QSRFetcherWrapper = ({ loading, ...props }) => (
    <LER loading={loading}>
        <QSRFetcher {...props} />
    </LER>
)

export default connect(
    state => ({
        ...state[C.NAME],
        rows: qsrRowsSelector(state),
        options: optionsSelector(state),
        loading: getMetaData(SETTINGS)(state)?.loading,
        emptyValues: qsrInitialValuesSelector(state),
        filters: qsrFiltersSelector(state),
        activeFilters: activeQsrFiltersSelector(state),
        lookup: lookupSelector(state)
    }),
    actions
)(QSRFetcherWrapper)
