import { createReducer } from "../utils"
import * as C from "./constants"

export const measuresReducer = createReducer(C.measuresInitialState, {
    [C.MARK_MEASURE_SAVING]: (state, action) => ({
        ...state,
        savingMeasures: new Set(state.savingMeasures).add(action.payload)
    }),
    [C.MARK_MEASURE_DONE]: (state, action) => {
        const savingMeasures = new Set(state.savingMeasures)
        savingMeasures.delete(action.payload)
        return {
            ...state,
            savingMeasures
        }
    }
})

export const measureSnackbar = createReducer(C.snackbarInitialState, {
    [C.HIDE_SNACKBAR]: () => ({
        show: false
    }),
    [C.SHOW_SNACKBAR]: () => ({
        show: true
    })
})

export const addMeasureReducer = createReducer(C.addMeasureInitialState, {
    [C.UPDATE_ADD_MEASURE_FILTER]: (state, action) => ({
        ...state,
        filter: action.payload
    })
})
