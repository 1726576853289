import * as C from "./constants"
import { takeEvery } from "redux-saga/effects"
import { ApiTypes, tryFetch } from "../api"
import { urlWithParams } from "../utils"

function* logAudit({ payload: { page, ...payload } }) {
    yield* tryFetch({
        url: urlWithParams(`/api/user_audit/${page}`, payload),
        method: ApiTypes.POST
    })
    // no actual defined behaviour because the user doesn't care about user_audit
}

export function* entrySaga() {
    yield takeEvery(C.LOG_USER_AUDIT, logAudit)
}
