import fetcher from "../../core/fetcher"
import { GraphContainer } from "../.././product_graph/components"
import * as PGC from "../.././product_graph/constants"
import transformFilter from "../../transformFilter"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"

export default fetcher({
    name: PGC.PATIENT_LIST_CHART,
    endpoint: state => {
        const filter = transformFilter(state[C.NAME].filters)
        const compacted = _.pickBy(filter, v => !_.isEmpty(v))
        return urlWithParams(`/api/product/chart`, compacted)
    },
    method: ApiTypes.GET
})(GraphContainer)
