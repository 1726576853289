import React from "react"
import { Card } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import FilterFooter from "../../core/FilterFooter"
import * as C from "../constants"
import CellRenderer from "./CellRenderer"
import Header from "./Header"

const ROW_CLASS_NAMES = {
    "patient-list-deceased": rowData => rowData[C.TABLE_KEYS.PATIENT_DOD],
    "patient-list-grace_period": rowData => rowData[C.TABLE_KEYS.GRACE_PERIOD]
}

const PatientList = ({
    loading,
    error,
    list,
    Table,
    Settings,
    Description,
    paginationFunctions,
    rowCount,
    patientCountName,
    patientCountEndpoint,
    tableData,
    ...props
}) => (
    <Card id="patient-list" className="patient-list card-table">
        <Header
            {...props}
            Settings={<Settings />} //yeah, this prop is a bit weird looking. But if we don't pass Settings this way then we can end up with incorrect columns on initial load.
        />
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={list}>
                <Table
                    {...paginationFunctions}
                    list={list}
                    cellRenderer={CellRenderer}
                    rowCount={rowCount}
                    rowClassNames={ROW_CLASS_NAMES}
                    tableData={tableData}
                />
            </LEHOC>
        </Card.Body>
        <FilterFooter name={patientCountName} endpoint={patientCountEndpoint} />
    </Card>
)

export default PatientList
