import library from "../modules/reports_library"
import user_audit from "../modules/user_audits"
import React from "react"

const ReportsLibrary = props => (
    <user_audit.components.default
        page={user_audit.constants.LIBRARY}
        info={props.match && props.match.params.reportId}
    >
        <library.components.default {...props} />
    </user_audit.components.default>
)
export default ReportsLibrary
