import { connect } from "react-redux"
import {
    Coverage,
    DischargeTimestamp,
    FormattedDate,
    LongCell,
    MultilineList,
    PatientName,
    Payer,
    RiskRank,
    TimestampDate,
    formatYesNo
} from "../core/CellRenderers"
import UndoRemove from "../core/UndoRemoveButtons"
import AtRisk from "../indicators/components/AtRisk"
import { getLaceIndex, LaceScore } from "../toc/components/LaceScore"
import { columnBuilder } from "../table"
import { dateFormat } from "../dates"
import { ALIGNMENTS, MODULE_IDS } from "../constants"
import * as C from "./constants"
import { removeTCM, restoreTCM } from "./actions"
import DateCell from "./components/DateCell"
import TextInput from "./components/TextInputCell"
import SelectTCMCell from "./components/SelectTCMCell"
import SelectStatusCell from "./components/SelectStatusCell"
import CDALink from "./components/CDALink"

const DATE_WIDTH = 100
const DATETIME_WIDTH = 160
const DATEPICKER_WIDTH = 175

const UndoRemoveTCM = connect(
    () => ({
        field: C.TABLE_KEYS.TCM_TRACKER_ID,
        statusField: C.TABLE_KEYS.STATUS,
        undoableValues: [C.STATUS_VALUES.R]
    }),
    {
        remove: removeTCM,
        undo: restoreTCM
    }
)(UndoRemove)

const column = columnBuilder(C.TRANSLATIONS)

const dateColumn = columnBuilder(C.TRANSLATIONS, {
    cellRenderer: DateCell,
    width: DATEPICKER_WIDTH,
    className: "column-widget"
})

export const TABLE_COLUMNS = [
    column(C.TABLE_KEYS.PATIENT_NAME, {
        cellRenderer: PatientName(
            C.TABLE_KEYS.PATIENT_ID,
            C.TABLE_KEYS.BILLING_POSSIBLE
        ),
        width: 150,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PATIENT_DOB, {
        hover: "Date of Birth",
        cellRenderer: FormattedDate,
        width: DATE_WIDTH
    }),
    column(C.TABLE_KEYS.PY_AGE, {
        selected: false,
        hover: "Performance Year Age",
        width: 60,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.RISK_NUMBER, {
        cellRenderer: RiskRank,
        columnData: {
            patientKey: C.TABLE_KEYS.PATIENT_ID,
            rankKey: C.TABLE_KEYS.RISK_RANK
        },
        width: 100
    }),
    column(C.TABLE_KEYS.AT_RISK, {
        cellRenderer: AtRisk(C.TABLE_KEYS.AT_RISK),
        width: 70,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.LACE_SCORE, {
        selected: false,
        dataGetter: getLaceIndex,
        cellRenderer: LaceScore,
        width: 100
    }),
    column(C.TABLE_KEYS.PAYER_NAME, {
        selected: false,
        idField: C.TABLE_KEYS.PAYER_ID,
        cellRenderer: Payer,
        width: 200,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRODUCT_CLASS, {
        selected: false,
        width: 130,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PAYER_IDS, {
        selected: false,
        cellRenderer: Coverage,
        width: 140
    }),
    column(C.TABLE_KEYS.PCP, {
        width: 165,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.PRACTICE, {
        width: 165,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.CMI, {
        selected: false,
        hover: "Care Management Incentive",
        cellFormatter: formatYesNo,
        width: 60,
        flexGrow: 1,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.ADMIT_DATE, {
        selected: false,
        cellRenderer: TimestampDate,
        width: DATETIME_WIDTH
    }),
    column(C.TABLE_KEYS.ADMIT_DESCRIPTION, {
        selected: false,
        flexGrow: 2,
        width: 330,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.DISCHARGE_DATE, {
        cellRenderer: DischargeTimestamp(C.TABLE_KEYS.PATIENT_DOD),
        width: DATETIME_WIDTH
    }),
    column(C.TABLE_KEYS.DISCHARGE_FACILITY, {
        width: 180,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.DISCHARGE_DESCRIPTION, {
        selected: false,
        flexGrow: 2,
        width: 330,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.CCDA, {
        cellRenderer: CDALink,
        width: 65
    }),
    column(C.TABLE_KEYS.EVENT_TYPE, {
        selected: false,
        width: 100,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.DIAGNOSIS, {
        selected: false,
        flexGrow: 2,
        width: 330,
        cellRenderer: LongCell
    }),
    dateColumn(C.TABLE_KEYS.FOLLOW_UP),
    dateColumn(C.TABLE_KEYS.OFFICE_VISIT),
    column(C.TABLE_KEYS.COMMENT, {
        cellRenderer: TextInput,
        width: 200,
        disableSort: true,
        className: "column-widget"
    }),
    column(C.TABLE_KEYS.POST_FOLLOW_UP, {
        cellRenderer: SelectTCMCell,
        columnData: { optionsKey: "tcmFollowUps" },
        width: 300,
        className: "column-widget"
    }),
    column(C.TABLE_KEYS.CONCLUSION, {
        cellRenderer: SelectTCMCell,
        columnData: { optionsKey: "tcmConclusions" },
        width: 275,
        className: "column-widget"
    }),
    dateColumn(C.TABLE_KEYS.MED_RECONCILIATION, {
        hover: "Medication Reconciliation"
    }),
    dateColumn(C.TABLE_KEYS.BILLED, { selected: false }),
    column(C.TABLE_KEYS.BILLING_DUE, {
        selected: false,
        cellFormatter: dateFormat,
        width: 105
    }),
    column(C.TABLE_KEYS.READMIT, {
        selected: false,
        cellFormatter: dateFormat,
        width: 100
    }),
    column(C.TABLE_KEYS.STATUS, {
        selected: false,
        cellRenderer: SelectStatusCell,
        columnData: {
            options: C.STATUS_OPTIONS,
            isClearable: false
        },
        width: 180,
        className: "column-widget"
    }),
    column(C.TABLE_KEYS.REMOVE, {
        cellRenderer: UndoRemoveTCM,
        width: 85,
        align: ALIGNMENTS.CENTER,
        module: MODULE_IDS.TCM_WRITE,
        disableSort: true
    }),
    column(C.TABLE_KEYS.TAGS, {
        selected: false,
        cellRenderer: MultilineList,
        width: 200,
        disableSort: true
    })
]
