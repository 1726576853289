import React from "react"
import { Card } from "react-bootstrap"
import _ from "lodash"
import LEHOC from "../../core/LoadingErrorHOC"
import AddCommunityLinkage from "./AddCommunityLinkage"
import ViewAndUpdateCommunityLinkages from "./ViewAndUpdateCommunityLinkages"

export const CommunityLinkages = ({ data, error, loading, isExternal }) => (
    <Card className="community_linkages">
        <Card.Body>
            <LEHOC
                loading={loading}
                error={error}
                list={data.communityLinkageOptions}
            >
                <React.Fragment>
                    {!isExternal && <AddCommunityLinkage />}
                    {!isExternal && !_.isEmpty(data.communityLinkages) && (
                        <hr style={{ marginBottom: "2rem" }} />
                    )}
                    {!_.isEmpty(data.communityLinkages) && (
                        <ViewAndUpdateCommunityLinkages />
                    )}
                    {isExternal &&
                        _.isEmpty(data.communityLinkages) &&
                        "The patient has no services to display."}
                </React.Fragment>
            </LEHOC>
        </Card.Body>
    </Card>
)
export default CommunityLinkages
