import React, { useCallback } from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import { Link } from "react-router-dom"

import CardTitle from "../../core/CardTitle"
import LEHOC from "../../core/LoadingErrorHOC"
import FilterHeader from "../../core/FilterHeader"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import {
    getRowClass,
    NO_TABLE,
    tableFactory,
    tieredTableFactory
} from "../../table"
import DashboardFilters from "../../dashboard_filters/components"
import { constants as PC, ButtonGroup } from "../../pickers"
import * as PLC from "../../patient_list/constants"
import * as CC from "../../cost/constants"

import * as C from "../constants"
import { getTableName } from "../helpers"
import {
    NETWORK_TABLE_COLUMNS,
    PRACTICE_TABLE_COLUMNS,
    PROVIDER_TABLE_COLUMNS
} from "../columns"
import FiltersForm from "./FiltersForm"

const rowClassGetter = getRowClass(2)

const getTableOptions = (aggregation, columns, extra) => {
    const sortBy = CC.AGGREGATION_MEASURES[aggregation]
    return {
        name: getTableName(aggregation),
        columns,
        sortBy,
        sortDirection: [...sortBy].fill(SortDirection.ASC),
        ...extra
    }
}

const AGGREGATION_TABLES = {
    [PC.VIEWS.NETWORK]: tieredTableFactory(
        getTableOptions(PC.VIEWS.NETWORK, NETWORK_TABLE_COLUMNS)
    ),
    [PC.VIEWS.PRACTICE]: tableFactory(
        getTableOptions(PC.VIEWS.PRACTICE, PRACTICE_TABLE_COLUMNS)
    ),
    [PC.VIEWS.PROVIDER]: tableFactory(
        getTableOptions(PC.VIEWS.PROVIDER, PROVIDER_TABLE_COLUMNS)
    )
}

const CostDashboard = props => {
    const {
        loading,
        error,
        data,
        categoryTree,
        fundStampLookup,
        filters,
        setFilters,
        aggregation,
        setAggregation,
        exportCsv,
        printPdf
    } = props
    const { Settings, Table, Description } =
        AGGREGATION_TABLES[aggregation] ?? NO_TABLE

    const setDashboardFilters = useCallback(
        filters => setFilters({ filters, categoryTree }),
        [setFilters, categoryTree]
    )

    return (
        <Card className="cost-dashboard dashboard-table card-table">
            <Card.Header>
                <div className="dashboard-table-controls">
                    <div className="dashboard-table-title">
                        <CardTitle>
                            <Link to="/reports/utilization">
                                Cost & Utilization Report
                            </Link>
                        </CardTitle>

                        <DashboardFilters
                            {...props}
                            title="Edit Cost Filters"
                            form={C.COST_DASHBOARD_FILTERS_FORM}
                            formComponent={FiltersForm}
                            emptyValues={C.initialState.filters}
                            setFilters={setDashboardFilters}
                        />
                    </div>

                    <ButtonGroup
                        value={aggregation}
                        onClick={setAggregation}
                        options={PC.VIEW_OPTIONS}
                    />

                    <ExportIcon onClick={() => exportCsv(aggregation)} />
                    <PrintIcon onClick={() => printPdf(aggregation)} />
                    <Settings />
                </div>
                <FilterHeader
                    filters={filters}
                    translation={{
                        ...PLC.FILTER_TRANSLATION,
                        ...CC.FILTER_TRANSLATION
                    }}
                    lookup={fundStampLookup}
                />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC loading={loading} error={error} list={data}>
                    <Table
                        list={data}
                        getRowClass={
                            aggregation === PC.VIEWS.NETWORK
                                ? rowClassGetter
                                : undefined
                        }
                    />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}
export default CostDashboard
