import React from "react"
import { connect } from "react-redux"
import { Row } from "react-bootstrap"
import { Redirect } from "react-router-dom"

import fetcher from "../../core/fetcher"
import { USER_TAGS_TABLE } from "../../user_tags/constants"
import { intersectionExists } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as reduxActions from "../actions"
import PracticeContainer from "./practices"
import UsersContainer from "./users"

export const AdminContainer = props => {
    if (
        !intersectionExists(props.roles, [
            C.ROLE_SUPERUSER,
            C.ROLE_PRACTICE_ADMIN
        ])
    ) {
        return <Redirect to="/dashboard" />
    }
    return (
        <Row className="row-eq-height admin" style={{ marginTop: "5px" }}>
            <PracticeContainer {...props} />
            <UsersContainer {...props} />
        </Row>
    )
}

export default connect(
    state => ({
        tags: {},
        roles: state.authentication.roles
    }),
    reduxActions
)(
    fetcher({
        name: USER_TAGS_TABLE,
        endpoint: "/api/tags/user",
        method: ApiTypes.GET,
        persistResource: true
    })(AdminContainer)
)
