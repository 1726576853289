import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import PatientHistory from "../../measures/components/MeasureCategory/Measure/History"
import { FormattedDate } from "../../core/CellRenderers"
import { Hoverlay } from "../../core/Hoverlay"
import DateEntry from "../../core/DateEntry"
import { isBlankDate } from "../../dates"
import { moduleEnabledSelector } from "../../moduleUtils"
import { formatFixedDigits, formatFractionAsPercent } from "../../numbers"
import { MODULE_IDS } from "../../constants"
import * as reduxActions from "../actions"
import { getErrorKey } from "../helpers"
import * as C from "../constants"

export const EligibleDate = ({ cellData, rowData, tableData }) => {
    const { eligibleDateFallback } = tableData
    const eligibleDate = eligibleDateFallback?.isAfter(cellData)
        ? eligibleDateFallback
        : cellData

    const isEligible = rowData[C.TABLE_KEYS.AWV_ELIGIBLE]
    const formattedDate = <FormattedDate cellData={eligibleDate} />
    return (
        <Hoverlay
            disabled={isEligible}
            wrapperClassName={classNames({
                "ineligible-cell": !isEligible
            })}
            tooltip={<span>Not eligible until {formattedDate}</span>}
            forceWrapper
        >
            {formattedDate}
        </Hoverlay>
    )
}

export const DateCell = connect((state, { rowData, columnData, dataKey }) => {
    const payload = {
        patientId: rowData[C.TABLE_KEYS.PATIENT_ID],
        field: dataKey
    }
    const error = state[C.NAME].errors[getErrorKey(payload)]
    return {
        keyGetter: ({ cellData, rowData }) =>
            `${rowData[C.TABLE_KEYS.PATIENT_ID]}_${cellData}`,
        canWrite: moduleEnabledSelector(MODULE_IDS.POC_MEASURES)(state),
        disableClear:
            dataKey === C.TABLE_KEYS.SERVICE_DATE &&
            !rowData[C.TABLE_KEYS.FLAG_MANUAL] && // can't clear service dates that weren't entered manually
            !error, // you can clear errors though
        error,
        ...columnData,
        getPayload: () => payload
    }
}, reduxActions)(DateEntry)

export const MeasureHistoryCell = connect((state, { rowData }) => ({
    patientId: rowData[C.TABLE_KEYS.PATIENT_ID],
    measureId: C.AWV_MEASURE_ID,
    hasHistory: !isBlankDate(rowData[C.TABLE_KEYS.SERVICE_DATE]),
    offerHistory: true
}))(PatientHistory)

export const RedocCell = ({ rowData, columnData }) => {
    const { met, total, digits } = columnData.fieldsGetter?.(rowData) ?? {}

    if (!total) return null

    return (
        <Hoverlay tooltip={formatFractionAsPercent(met / total)}>
            {formatFixedDigits(met, digits)} /{" "}
            {formatFixedDigits(total, digits)}
        </Hoverlay>
    )
}

export const RedocRemainingCell = ({ cellData, rowData, columnData }) => {
    const { total, digits } = columnData.fieldsGetter?.(rowData)
    return total ? formatFixedDigits(cellData, digits) : null
}
