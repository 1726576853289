import React, { Component } from "react"
import { connect } from "react-redux"
import classNames from "classnames"

import Select from "../../core/Select"
import { getResourceData } from "../../core/fetcher"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import DatePicker from "../../core/DatePicker"
import { DATE_STRING, dateFormat, SERVER_DATE_STRING } from "../../dates"

import * as C from "../constants"
import { exportCSV, printPDF, updateCommunityLinkages } from "../actions"
import { getLinkages } from "../selectors"
import History from "./History"
import CommunityLinkageComment from "./CommunityLinkageComment"

class ExportCommunityLinkages extends Component {
    state = { withHistory: false }
    toggle = () => this.setState({ withHistory: !this.state.withHistory })

    render() {
        return (
            <div style={{ display: "inline-block", float: "right" }}>
                <PrintIcon
                    onClick={() =>
                        this.props.printPDF({
                            withHistory: this.state.withHistory
                        })
                    }
                />
                <ExportIcon
                    onClick={() =>
                        this.props.exportCSV({
                            withHistory: this.state.withHistory
                        })
                    }
                />
                <span
                    style={{ width: 25, marginRight: 0 }}
                    className={classNames(
                        "fa clickable-icon",
                        this.state.withHistory
                            ? "fa-check-square-o"
                            : "fa-square-o"
                    )}
                    onClick={this.toggle}
                />

                <span
                    className="clickable-icon"
                    onClick={this.toggle}
                    style={{
                        fontSize: "1.1em",
                        verticalAlign: "top",
                        userSelect: "none"
                    }}
                >
                    Include History
                </span>
            </div>
        )
    }
}

const ViewAndUpdateCommunityLinkages = ({
    communityLinkages,
    statuses,
    updateCommunityLinkages,
    printPDF,
    exportCSV
}) => {
    return (
        <div className="community_linkages-update">
            <div>
                <h4 style={{ display: "inline" }}>Existing Services</h4>
                <ExportCommunityLinkages
                    printPDF={printPDF}
                    exportCSV={exportCSV}
                />
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Service Category</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th className="community_linkage_date">Date</th>
                        <th>Comment</th>
                        <th>History</th>
                    </tr>
                </thead>
                <tbody>
                    {communityLinkages
                        .sort((a, b) =>
                            a.srvCatDescription > b.srvCatDescription ? 1 : -1
                        )
                        .map(row => (
                            <CommunityLinkageRow
                                key={row.communityLinkId}
                                {...row}
                                statuses={statuses}
                                updateCommunityLinkages={
                                    updateCommunityLinkages
                                }
                            />
                        ))}
                </tbody>
            </table>
        </div>
    )
}

class CommunityLinkageRow extends Component {
    handleStatusChange = ({ value }) =>
        value !== this.props.status &&
        this.update({
            ...this.props,
            status: value
        })
    handleDateChange = date =>
        date !== this.props.date &&
        this.update({
            ...this.props,
            date
        })
    handleCommentChange = comment =>
        comment !== this.props.comment &&
        this.update({
            ...this.props,
            comment
        })
    update = ({ status, date, comment }) => {
        this.props.updateCommunityLinkages({
            communityLinkId: this.props.communityLinkId,
            [C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY]: this.props
                .srvCatCodeDescId,
            [C.ADD_COMMUNITY_LINKAGE_FIELDS.STATUS]: status,
            [C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE]: dateFormat(
                date,
                DATE_STRING,
                SERVER_DATE_STRING
            ),
            [C.ADD_COMMUNITY_LINKAGE_FIELDS.COMMENT]: comment
        })
    }

    render() {
        const {
            comment,
            communityLocation,
            srvCatDescription,
            srvCatCodeDescId,
            date,
            status,
            statuses
        } = this.props
        return (
            <tr>
                <td>{srvCatDescription}</td>
                <td>{communityLocation}</td>
                <td>
                    <Select
                        value={status}
                        options={statuses}
                        onChange={this.handleStatusChange}
                        isClearable={false}
                    />
                </td>
                <td className="community_linkage_date">
                    <DatePicker
                        disableClear
                        noHighlighting
                        value={date}
                        onChange={this.handleDateChange}
                    />
                </td>
                <td>
                    <CommunityLinkageComment
                        value={comment}
                        onChange={this.handleCommentChange}
                    />
                </td>
                <td className="popup-history">
                    <History id={srvCatCodeDescId} />
                </td>
            </tr>
        )
    }
}

export default connect(
    state => ({
        statuses: getResourceData(
            C.COMMUNITY_LINKAGES,
            data => data.communityLinkageOptions.statuses
        )(state),
        communityLinkages: getLinkages(state)
    }),
    { printPDF, exportCSV, updateCommunityLinkages }
)(ViewAndUpdateCommunityLinkages)
