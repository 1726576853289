import React from "react"
import { Field } from "redux-form"
import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import { constants as PC, PickerField } from "../../pickers"
import { VIEWS } from "../../pickers/constants"
import { SelectField } from "../../core/CustomFields"
import CategoryTree from "../../cost/components/CategoryTree"
import * as CC from "../../cost/constants"
import { branchesToLeaves, leavesToBranches } from "../../cost/helpers"

const commonFieldProps = {
    component: SelectField,
    placeholder: "All",
    multi: true
}

const FiltersForm = ({
    aggregation,
    categoryNodes,
    categoryTree,
    filterOptions: { payers, productClasses, practiceUnits, regions }
}) => (
    <div className="cost-dashboard-filters">
        <Row>
            <Col sm={12}>
                <Field
                    name={CC.FILTERS.PERIOD}
                    component={PickerField}
                    options={PC.PERIOD_OPTIONS}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name={CC.FILTERS.PAYERS}
                    options={payers}
                    {...commonFieldProps}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name={CC.FILTERS.PRODUCTS}
                    options={productClasses}
                    {...commonFieldProps}
                    multi={false}
                    notClearable
                    // this input is single-select, but FilterHeader only accepts arrays of values, so we need to pack the value into an array when we put it in the store, and unpack it when it comes back out.
                    format={v => (_.isEmpty(v) ? v : v[0])}
                    normalize={v => [v]}
                />
            </Col>

            {aggregation === VIEWS.NETWORK ? (
                <>
                    <Col sm={6}>
                        <Field
                            name={CC.FILTERS.PRACTICE_UNITS}
                            options={practiceUnits}
                            {...commonFieldProps}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name={CC.FILTERS.REGIONS}
                            options={regions}
                            {...commonFieldProps}
                        />
                    </Col>
                </>
            ) : (
                <Col sm={12}>
                    <Field
                        name={CC.FILTERS.CATEGORIES}
                        component={CategoryTree}
                        nodes={categoryNodes}
                        normalize={value =>
                            leavesToBranches(categoryTree, value)
                        }
                        format={value => branchesToLeaves(categoryTree, value)}
                    />
                </Col>
            )}
        </Row>
    </div>
)

export default FiltersForm
