import React from "react"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"
import { GuideLink } from "../../indicators"
import AddPatientForm from "./AddPatientForm"

const AddPatientModal = ({
    errors,
    hideAddPatientModal,
    open,
    options,
    savePatient,
    showAddPatientModal
}) => (
    <Modal
        dialogClassName="large-modal"
        show={open}
        onHide={hideAddPatientModal}
    >
        <Modal.Header closeButton>
            <Modal.Title>
                Add New Patient
                <GuideLink page="add-new-patient" className="white" />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body
            style={{
                padding: "15px",
                paddingBottom: "0px",
                backgroundColor: "rgb(228, 238, 250)"
            }}
        >
            <AddPatientForm
                options={options}
                showAddPatientModal={showAddPatientModal}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={hideAddPatientModal}>
                Close
            </Button>
            <Button
                variant="primary"
                disabled={!_.isEmpty(errors)}
                className="pull-right"
                onClick={savePatient}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
)

export default AddPatientModal
