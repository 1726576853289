export const NAME = "DOCUMENTS"

// actions
export const SHOW_DOCUMENTS_MODAL = "SHOW_DOCUMENTS_MODAL"
export const HIDE_DOCUMENTS_MODAL = "HIDE_DOCUMENTS_MODAL"
export const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS"
export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT"
export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const RESTORE_DOCUMENT = "RESTORE_DOCUMENT"
export const UPDATE_DOCUMENT_DESCRIPTION = "UPDATE_DOCUMENT_DESCRIPTION"

// columns

export const TABLE_KEYS = {
    DOCUMENT_ID: "documentId",
    URI: "s3Uri",
    FILE_NAME: "fileName",
    DESCRIPTION: "description",
    CREATED_DATE: "createdDate",
    CREATED_BY: "createdBy",
    CREATED_BY_NAME: "createdByName",
    DELETED_DATE: "deletedDate",
    DELETED_BY: "deletedBy",
    DELETED: "deleted"
}

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.FILE_NAME]: "Document",
    [TABLE_KEYS.DESCRIPTION]: "Description",
    [TABLE_KEYS.CREATED_DATE]: "Last Updated",
    [TABLE_KEYS.CREATED_BY_NAME]: "Last Updated By",
    [TABLE_KEYS.DELETED]: "Delete"
}

// misc
export const initialState = { show: false }
