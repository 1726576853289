import React from "react"

const TableLegend = (
    <div>
        <h5>
            Green Dots: <span className="fa indicator-billable" />
        </h5>
        <p>
            The green dot indicates patients who have met criteria for TCM
            billing.
        </p>
        <h5 className="grace_period">Italics:</h5>
        <p>
            Italics indicate that the patient did not appear anywhere on payer
            attribution lists. However, these patients are still kept active in
            the system from 45 days in order to bridge any accidental data gap.
        </p>
    </div>
)
export default TableLegend
