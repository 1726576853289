import React from "react"
import { connect } from "react-redux"
import * as C from "../../constants"
import * as reduxActions from "../../actions"

export const HideCampaign = ({
    rowData,
    unsubscribeFromCampaign,
    resubscribeToCampaign
}) =>
    !rowData[C.CAN_UNSUBSCRIBE] ? null : rowData[C.UNSUBSCRIBED] ? (
        <span
            className="fa fa-undo"
            onClick={() => resubscribeToCampaign(rowData)}
        />
    ) : (
        <span
            className="fa fa-close"
            onClick={() => unsubscribeFromCampaign(rowData)}
        />
    )

export default connect(null, reduxActions)(HideCampaign)
