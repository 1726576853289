import { createReducer } from "../utils"
import * as C from "./constants"

export const reducer = createReducer(
    {},
    {
        [C.UPDATE_TABLE]: (state, { tableName, payload }) => ({
            ...state,
            [tableName]: { ...state[tableName], ...payload }
        })
    }
)

export default reducer
