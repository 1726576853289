import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"
import * as actions from "../actions"

export const ReactivateButton = ({
    rowData,
    deactivatePatient,
    reactivatePatient
}) => {
    const active = rowData[C.TABLE_KEYS.ACTIVE]
    return rowData[C.TABLE_KEYS.CAN_ACTIVATE] ? (
        <Hoverlay
            tooltip={active ? "Deactivate patient" : "Reactivate patient"}
            className={active ? "tooltip-red" : "tooltip-blue"}
            placement="right"
        >
            <button
                className="btn-blank"
                onClick={() =>
                    active
                        ? deactivatePatient(rowData)
                        : reactivatePatient(rowData)
                }
            >
                <span
                    className={classNames(
                        "fa",
                        active ? "fa-user-times" : "fa-user-plus"
                    )}
                />
            </button>
        </Hoverlay>
    ) : null
}

export default connect(null, actions)(ReactivateButton)
