import { connect } from "react-redux"
import { getFormSyncErrors, getFormValues, reduxForm } from "redux-form"
import _ from "lodash"

import { getMetaData, getResourceData } from "../../core/fetcher"
import { FILTER_OPTIONS } from "../../constants"
import TRANSLATION from "../../translation"

import { getExistingNames, getFavoriteFilters } from "../selectors"
import * as actions from "../actions"
import * as C from "../constants"
import { FiltersContainer } from "./FiltersContainer"

const practiceUnitsSelector = getResourceData(
    FILTER_OPTIONS,
    data => data.units
)

const onSubmit = (values, dispatch, props) => {
    const action = _.attempt(
        props.setFilters,
        _.omit(values, [C.FILTERS.ID, C.FILTERS.NAME]) // don't want to treat those fields as actual filters, if they're present
    )
    if (!_.isEqual(action?.payload, props.filters)) {
        props.selectFavoriteFilters(props.form, undefined)
    } // we're not in any favorited filter set anymore, so clear the stored value
}

export default connect(
    (state, { form }) => ({
        ...state[C.NAME],
        formValues: getFormValues(form)(state),
        errors: getFormSyncErrors(form)(state),
        filterOptions: {
            ...getResourceData(FILTER_OPTIONS)(state),
            practiceUnits: practiceUnitsSelector(state)
        },
        favorites: getFavoriteFilters(state, form),
        existingNames: getExistingNames(state, form),
        meta: getMetaData(FILTER_OPTIONS)(state),
        warn: (values, props) => {
            if (values[C.FILTERS.ID]) {
                // make it clear that even if you set a field's value, it won't be saved
                return _.mapValues(
                    _.pick(values, props.favoritesIgnoreFields),
                    (v, key) => `${TRANSLATION[form][key]} will be ignored`
                )
            }
        }
    }),
    actions
)(
    reduxForm({
        onSubmit,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        forceUnregisterOnUnmount: true,
        updateUnregisteredFields: true
    })(FiltersContainer)
)
// all form props should be passed into the dashboard_filters component instead
