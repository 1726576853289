import { connect } from "react-redux"
import UndoRemoveButtons from "../core/UndoRemoveButtons"
import { FormattedDate, LongCell } from "../core/CellRenderers"
import { columnBuilder } from "../table"
import { ALIGNMENTS } from "../constants"
import * as C from "./constants"
import {
    removeCondition,
    removeGenCondition,
    restoreCondition,
    restoreGenCondition
} from "./actions"
import { ConditionName } from "./components/CellRenderers"

const UndoRemoveCondition = connect(
    () => ({
        field: C.TABLE_KEYS.CONDITION_ID,
        statusField: C.TABLE_KEYS.REMOVED
    }),
    {
        remove: removeCondition,
        undo: restoreCondition
    }
)(UndoRemoveButtons)

const UndoRemoveGenCondition = connect(
    () => ({
        field: C.TABLE_KEYS.GEN_CONDITION_ID,
        statusField: C.TABLE_KEYS.REMOVED
    }),
    {
        remove: removeGenCondition,
        undo: restoreGenCondition
    }
)(UndoRemoveButtons)

const column = columnBuilder(C.TABLE_TRANSLATION)

const sharedColumns = [
    column(C.TABLE_KEYS.DIAGNOSIS_CODE, {
        width: 75
    }),
    column(C.TABLE_KEYS.DESCRIPTION, {
        width: 100,
        flexGrow: 3,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.CONTEXT, {
        width: 75,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.PROVIDER_NAME, {
        width: 125,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.LAST_IDENTIFIED_DATE, {
        width: 120,
        cellRenderer: FormattedDate
    })
]

export const HCC_TABLE_COLUMNS = [
    column(C.TABLE_KEYS.CONDITION_NAME, {
        width: 100,
        flexGrow: 3,
        cellRenderer: ConditionName
    }),
    column(C.TABLE_KEYS.HCC_RISK, { width: 110 }),
    ...sharedColumns,
    column(C.TABLE_KEYS.REMOVE, {
        cellRenderer: UndoRemoveCondition,
        width: 80,
        align: ALIGNMENTS.CENTER
    })
]

export const GEN_TABLE_COLUMNS = [
    column(C.TABLE_KEYS.GEN_CONDITION_NAME, {
        width: 100,
        flexGrow: 3
    }),
    ...sharedColumns,
    column(C.TABLE_KEYS.REMOVE, {
        cellRenderer: UndoRemoveGenCondition,
        width: 80,
        align: ALIGNMENTS.CENTER
    })
]

export const EXCLUSIONS_TABLE_COLUMNS = [
    column(C.TABLE_KEYS.EXCL_REASON, { width: 100, flexGrow: 1 }),
    column(C.TABLE_KEYS.MEASURES_AFFECTED, { width: 100, flexGrow: 2 })
]
