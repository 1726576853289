import React from "react"
import _ from "lodash"
import { Field } from "redux-form"
import RFCheckBoxTree from "../../core/RFCheckBoxTree"
import { Hoverlay } from "../../core/Hoverlay"
import * as C from "../constants"
import { PrintField, PrintRadioField } from "./PrintField"
import { ToggleField } from "./PrintLinkages"

const PrintServices = ({ onlyGaps, assessments, id, label, children }) => (
    <PrintRadioField
        className="poc-print-radio"
        name={C.PRINT_FIELDS.ONLY_REQUIRED}
        label={label}
        id={id}
    >
        {onlyGaps ? (
            <p className="small">
                All assessment values are included on the abbreviated report.
            </p>
        ) : (
            <>
                {children}
                {_.isEmpty(assessments) || <h5>Assessments to include:</h5>}
                <RFCheckBoxTree
                    nodes={assessments}
                    name={C.PRINT_FIELDS.ASSESSMENTS}
                />
            </>
        )}
    </PrintRadioField>
)

export const PrintAssessments = ({ assessments, formValues }) => {
    const onlyGaps = formValues[C.PRINT_FIELDS.ONLY_GAPS]

    const requiredAssessments = _.filter(
        assessments,
        assessment => !assessment?.compliant
    )

    return (
        <PrintField
            name={C.PRINT_FIELDS.POC_FORM}
            className="print-assessments"
        >
            <Hoverlay
                tooltip="Only displays last value, last date, and Met for each measure"
                trigger={["hover", "focus"]}
                placement="right"
            >
                <Field
                    name={C.PRINT_FIELDS.ONLY_GAPS}
                    component={ToggleField}
                />
            </Hoverlay>

            <h5>Services to include:</h5>

            <PrintServices
                onlyGaps={onlyGaps}
                assessments={assessments}
                label="All Services"
                id={false}
            />
            <PrintServices
                onlyGaps={onlyGaps}
                assessments={assessments}
                label="Only Required Services"
                id={true}
            >
                <p
                    className="small"
                    style={{
                        marginBottom: _.isEmpty(requiredAssessments) ? 0 : 10
                    }}
                >
                    Include only measures that are past due or due in the
                    current calendar year. Any measures due in future years will
                    be excluded.
                </p>
            </PrintServices>
        </PrintField>
    )
}
