import React from "react"
import spinner from "../../../assets/images/loading.gif"
import { LinkGroup } from "../../pickers"
import campaigns from "../../campaigns"
import CareManagementPatients from "./CareManagementPatients"

const CareManagement = props => (
    <>
        <div className="patient-list-page-header care_management-header">
            <h1>Care Management Patients - {props.measureName}</h1>
            <div className="page-header-group">
                {props.pickerLoading ? (
                    <img src={spinner} alt="Saving..." />
                ) : (
                    <LinkGroup
                        value={props.pickerValue}
                        options={props.pickerOptions}
                    />
                )}
                <campaigns.components.CreateCampaign usePatientList />
            </div>
        </div>

        <CareManagementPatients {...props} />
    </>
)

export default CareManagement
