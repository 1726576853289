import React from "react"
import { OutreachLink } from "./OutreachLink"

export const PatientsRow = ({ filters, measureId, message, numNeeded }) => (
    <tr>
        <td>
            <OutreachLink filters={filters} measureId={measureId}>
                {message}
            </OutreachLink>
        </td>
        <td>{numNeeded ?? 0}</td>
    </tr>
)
