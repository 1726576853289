import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { encodeFiltersInUrl } from "../../utils"

export const OutreachLink = ({ children, filters, measureId }) => {
    const url = useMemo(
        () => encodeFiltersInUrl("/care-management/" + measureId, filters),
        [filters, measureId]
    )
    return <Link to={url}>{children}</Link>
}
