import {
    Coverage,
    FormattedDate,
    ListCell,
    LongCell,
    MultilineList,
    PatientName,
    YesNoIndicatorCell
} from "../core/CellRenderers"
import { columnBuilder } from "../table"
import { joinTruthy } from "../utils"
import { ALIGNMENTS, MODULE_IDS } from "../constants"
import { timestampFormat } from "../dates"
import { updateFileDesc } from "./actions"
import * as C from "./constants"
import {
    DeleteCell,
    DescriptionCell,
    FileLinkCell,
    FilesCell,
    NotesCell,
    StatusCell
} from "./components/CellRenderers"

const column = columnBuilder(C.TABLE_TRANSLATION)
const DATE_WIDTH = 110
const TIMESTAMP_WIDTH = 150

export const AUDIT_COLUMNS = [
    column(C.TABLE_KEYS.PATIENT_ID, { selected: false }),
    column(C.TABLE_KEYS.PATIENT_NAME, {
        cellRenderer: PatientName(C.TABLE_KEYS.PATIENT_ID),
        width: 180,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PATIENT_DOB, {
        hover: "Date of Birth",
        cellRenderer: FormattedDate,
        width: DATE_WIDTH
    }),
    column(C.TABLE_KEYS.PATIENT_GENDER, { cellRenderer: ListCell }),
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        cellRenderer: LongCell,
        width: 200,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.PROVIDER_NAME, {
        hover: "Primary Care Provider",
        cellRenderer: LongCell,
        width: 170,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.PRODUCT_CLASS, { width: 115, selected: false }),
    column(C.TABLE_KEYS.PAYER_NAMES, {
        cellRenderer: Coverage,
        width: 80,
        selected: false
    }),
    column(C.TABLE_KEYS.TAGS, {
        cellRenderer: MultilineList,
        width: 200,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.AUDIT_NAME, { width: 120 }),
    column(C.TABLE_KEYS.RECORD_TYPE, {
        cellRenderer: LongCell,
        width: 220,
        selected: false
    }),
    column(C.TABLE_KEYS.SERVICE, {
        dataGetter: ({ rowData }) =>
            joinTruthy(
                [
                    rowData[C.TABLE_KEYS.SERVICE],
                    rowData[C.TABLE_KEYS.SERVICE_DESC]
                ],
                " - "
            ),
        cellRenderer: LongCell,
        width: 250,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.SERVICE_DATE, {
        cellRenderer: FormattedDate,
        width: DATE_WIDTH
    }),
    column(C.TABLE_KEYS.RESULT, {
        width: 120
    }),
    column(C.TABLE_KEYS.CLOSED, {
        cellRenderer: YesNoIndicatorCell,
        width: 70,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.STATUS, {
        cellRenderer: StatusCell,
        width: 120
    }),
    column(C.TABLE_KEYS.STATUS_REASON, {
        cellRenderer: LongCell,
        width: 180,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.STATUS_NOTE, {
        width: 180,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.FILES, {
        cellRenderer: FilesCell,
        width: 70,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.NOTES, {
        cellRenderer: NotesCell,
        width: 70,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.DIAGNOSIS_CODE, {
        hover: "Diagnosis Code",
        width: 95
    }),
    column(C.TABLE_KEYS.CODE_SYSTEM, { width: 110 }),
    column(C.TABLE_KEYS.SOURCE, { width: 80 }),
    column(C.TABLE_KEYS.SENT_RECORD_ID, { width: 180 }),
    column(C.TABLE_KEYS.CREATED_DATE, {
        cellFormatter: timestampFormat,
        width: TIMESTAMP_WIDTH,
        selected: false
    }),
    column(C.TABLE_KEYS.CREATED_BY_NAME, {
        cellRenderer: LongCell,
        width: 180,
        flexGrow: 1,
        selected: false
    })
]

export const FILE_COLUMNS = [
    column(C.TABLE_KEYS.FILE_NAME, { cellRenderer: FileLinkCell, width: 80 }),
    column(C.TABLE_KEYS.DESCRIPTION, {
        cellRenderer: DescriptionCell,
        columnData: { idField: C.TABLE_KEYS.RECORD_FILE_ID },
        flexGrow: 0.4
    }),
    column(C.TABLE_KEYS.CREATED_BY_NAME, {
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.CREATED_DATE, {
        cellFormatter: timestampFormat,
        width: TIMESTAMP_WIDTH,
        flexGrow: 0
    }),
    column(C.TABLE_KEYS.DELETE, {
        cellRenderer: DeleteCell,
        width: 55,
        flexGrow: 0,
        align: ALIGNMENTS.CENTER,
        module: MODULE_IDS.AUDITS_UPLOAD,
        disableSort: true
    })
]
