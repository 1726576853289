import React from "react"

export const MeasureInfoLink = ({ link }) =>
    link ? (
        <a
            className="guide-link-measure"
            href={`https://guide.healthfocus.io/measure_descriptions.html#${link}`}
            target="_blank"
        >
            <span className="fa indicator-info" />
        </a>
    ) : null
