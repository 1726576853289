import {
    FormattedDate,
    LongCell,
    Payer,
    formatYesNo
} from "../core/CellRenderers"
import { columnBuilder } from "../table"
import * as C from "./constants"
import { Identifiers } from "./components/Identifiers"
import { ALIGNMENTS } from "../constants"

const DATE_WIDTH = 110

const column = columnBuilder(C.TRANSLATIONS)

export const COVERAGE_COLUMNS = [
    column(C.TABLE_KEYS.SOURCE_NAME, {
        idField: C.TABLE_KEYS.SOURCE_ID,
        cellRenderer: Payer,
        width: 230
    }),
    column(C.TABLE_KEYS.PAYER_NAME, {
        idField: C.TABLE_KEYS.PAYER_ID,
        cellRenderer: Payer,
        width: 200
    }),
    column(C.TABLE_KEYS.PROVIDER_NAME, { cellRenderer: LongCell, width: 180 }),
    column(C.TABLE_KEYS.PRODUCT_NAME, { cellRenderer: LongCell, width: 170 }),
    column(C.TABLE_KEYS.START_DATE, {
        cellRenderer: FormattedDate,
        hover: "Effective Date",
        width: DATE_WIDTH
    }),
    column(C.TABLE_KEYS.END_DATE, {
        cellRenderer: FormattedDate,
        width: DATE_WIDTH
    }),
    column(C.TABLE_KEYS.TYPE, { width: 100 }),
    column(C.TABLE_KEYS.CM_INCENTIVE, {
        cellFormatter: formatYesNo,
        hover: "Care Management Incentive",
        align: ALIGNMENTS.CENTER,
        width: 110
    }),
    column(C.TABLE_KEYS.PRIORITY, {
        align: ALIGNMENTS.CENTER,
        width: 80
    }),
    column(C.TABLE_KEYS.IDENTIFIERS, { cellRenderer: Identifiers, width: 275 })
]
