import _ from "lodash"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { moduleColumnsSelector } from "../moduleUtils"
import { removeUserPreference } from "../table_settings/actions"
import * as TC from "../table_settings/constants"
import * as C from "./constants"
import { updateTable } from "./actions"
import { getDefaultColumns, placeColumnsByKeys } from "./helpers"

export function* initializeTable({
    payload: { columns, name, sortBy, sortDirection } // payload is factoryProps
}) {
    const validColumns = yield select(moduleColumnsSelector(columns))

    const tableSettings = yield select(
        state => state[TC.NAME].columns[name] || {}
    )
    const tableDefaults = yield select(
        state => state[TC.NAME].defaultColumns[name] || {}
    )

    const validColumnKeys = validColumns.map(({ key }) => key)
    const columnKeys = _.intersection(tableSettings.columns, validColumnKeys)
    const frozenColumnKeys = _.intersection(
        tableSettings.frozenColumns,
        validColumnKeys
    )

    if (_.isEmpty(columnKeys)) {
        yield put(
            updateTable(name, {
                sortBy,
                sortDirection,
                ...getDefaultColumns(validColumns, tableDefaults)
            })
        )
        return
    }

    yield put(
        updateTable(name, {
            sortBy,
            sortDirection,
            ...placeColumnsByKeys(validColumns, columnKeys, frozenColumnKeys)
        })
    )
}

export function* resetTable({ payload: { columns, name } }) {
    // payload is factoryProps

    const validColumns = yield select(moduleColumnsSelector(columns))

    const tableDefaults = yield select(
        state => state[TC.NAME].defaultColumns[name] || {}
    )

    yield put(removeUserPreference(name))
    yield put(updateTable(name, getDefaultColumns(validColumns, tableDefaults)))
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.INITIALIZE_TABLE, initializeTable)
        }),
        call(function*() {
            yield takeEvery(C.RESET_TABLE, resetTable)
        })
    ])
}
