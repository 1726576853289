import React from "react"
import { Card } from "react-bootstrap"
import { ExportIcon, PrintIcon } from "../../../core/Icons"
import FilterFooter from "../../../core/FilterFooter"
import LEHOC from "../../../core/LoadingErrorHOC"
import patientList from "../../../patient_list"
import { footerEndpointSelector, requestBodySelector } from "../../selectors"
import * as C from "../../constants"
import EditCampaignFilters from "../edit_campaign/EditCampaignFilters"
import FilterHeader from "./FilterHeader"

const ROW_CLASS_NAMES = {
    not_a_member: rowData => rowData[C.TABLE_KEYS.MEMBERSHIP] === false // explicitly false, not just undefined
}

export const PatientList = ({
    loading,
    error,
    list,
    paginationFunctions,
    rowCount,
    exportCampaignPatientList,
    printCampaignPatientList,
    Settings,
    Table,
    Description,
    tableData,
    isModified
}) => (
    <Card id="patient-list" className="card-table patient-list">
        <Card.Header
            className="patient-list-header"
            id="campaign-patient-list-header"
        >
            <EditCampaignFilters />
            <FilterHeader />
            {isModified && <patientList.components.ClearFiltersButton />}
            <ExportIcon onClick={exportCampaignPatientList} />
            <PrintIcon onClick={printCampaignPatientList} />
            <Settings />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={list}>
                <Table
                    {...paginationFunctions}
                    list={list}
                    cellRenderer={patientList.components.CellRenderer}
                    rowCount={rowCount}
                    rowClassNames={ROW_CLASS_NAMES}
                    tableData={{ tableData }}
                />
            </LEHOC>
        </Card.Body>
        <FilterFooter
            name={C.CAMPAIGN_PATIENT_LIST_COUNT}
            endpoint={footerEndpointSelector}
            requestBodySelector={requestBodySelector} // force update when campaign filters change
        />
    </Card>
)
