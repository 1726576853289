import React from "react"
import { connect } from "react-redux"
import { getResourceData } from "../../core/fetcher"
import { ButtonField } from "../../core/CustomFields"
import { openLocationModal } from "../../locations/actions"
import * as C from "../../locations/constants"

export const LocationField = ({
    communityLocations,
    input,
    meta,
    openLocationModal
}) => {
    const value = (communityLocations || []).filter(
        item => item.communityLocationId === input.value
    )[0]

    return (
        <div>
            <ButtonField
                input={{
                    ...input,
                    value: value ? value.name : "",
                    onClick: () => openLocationModal({ allowSelect: true })
                }}
                meta={meta}
            />
        </div>
    )
}

export default connect(
    state => ({
        communityLocations: getResourceData(
            C.COMMUNITY_LOCATIONS,
            data => data.communityLocations
        )(state)
    }),
    {
        openLocationModal
    }
)(LocationField)
