import React from "react"
import { SITE_TITLE } from "../../configuration"
import { Helmet } from "react-helmet"

const MAX_TITLE_LENGTH = 60

const SiteTitle = ({ title }) => (
    <Helmet title={`${SITE_TITLE} | ${title}`.slice(0, MAX_TITLE_LENGTH)} />
)
export default SiteTitle
