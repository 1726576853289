import { createReducer } from "../utils"
import * as C from "./constants"

const reducer = createReducer(C.initialState, {
    [C.SHOW_EDIT_PATIENT_INFO]: state => ({
        ...state,
        showEdit: true,
        loading: false
    }),
    [C.HIDE_EDIT_PATIENT_INFO]: state => ({
        ...state,
        showEdit: false,
        loading: false
    }),
    [C.UPDATE_PATIENT_INFO]: state => ({
        ...state,
        showEdit: true,
        loading: true
    }),
    [C.UPDATE_PATIENT_INFO_SUCCESS]: state => ({
        ...state,
        showEdit: false,
        loading: true // so that the form doesn't flash-reappear
    }),
    [C.SHOW_DEACTIVATE_PATIENT]: state => ({
        ...state,
        showDeactivate: true,
        loading: true
    }),
    [C.HIDE_DEACTIVATE_PATIENT]: state => ({
        ...state,
        showDeactivate: false,
        loading: false
    })
})

export default reducer
