import React from "react"
import { RiskRankItem } from "./RiskRankItem"

export const ScrollablePatientRiskRank = ({ patientRiskRanks }) => (
    <table className="popover-table">
        <thead>
            <tr>
                <th>Source</th>
                <th>Type</th>
                <th>Result</th>
                <th>Date</th>
                <th>Stratification</th>
                <th>User</th>
            </tr>
        </thead>
        <tbody>
            {patientRiskRanks.map((item, index) => (
                <RiskRankItem key={index} index={index} {...item} />
            ))}
        </tbody>
    </table>
)
