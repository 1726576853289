import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { DATE_STRING } from "../dates"

const Footer = ({ refreshDate, anchorDate }) => (
    <footer id="site-footer">
        <small className="datestamp">
            Data as of {moment.utc(refreshDate).format(DATE_STRING)}
            {anchorDate && (
                <>
                    <br />
                    Performance Year: {moment.utc(anchorDate).year()}
                </>
            )}
        </small>

        <small>
            &copy; 2017-{moment.utc(refreshDate).format("YYYY")} HF Software
            Solutions Inc. v{process.env.REACT_APP_VERSION_NUMBER}
            <br />
        </small>
        <small>
            All Rights Reserved.{" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://healthfocus.io/privacy-policy.html"
            >
                Privacy Policy
            </a>
        </small>
    </footer>
)

export default connect(state => state.server_status)(Footer)
