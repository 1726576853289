import React, { Component, useMemo } from "react"
import { connect } from "react-redux"
import { change, Field, getFormValues } from "redux-form"
import _ from "lodash"
import { SelectField } from "../../../../../core/CustomFields"
import * as C from "../../../../constants"
import {
    getCompositeSubmeasureIdName,
    getDropListOptions,
    getMeasureIdName
} from "../../../../helpers"
import validators from "./validators"
import CompositeSubMeasure from "./CompositeSubMeasure"

const getValidators = (name, props) => {
    const validator = validators[name]
    if (!validator) {
        console.warn("No composite-measure validator found for", name)
        return []
    }
    return validator(props)
}

// TODO this can easily be a functional component

class CompositeMeasures extends Component {
    // noinspection JSCheckFunctionSignatures
    componentDidUpdate(prevProps) {
        const newValue = this.props.submeasureProps
            .map(submeasure => this.props.formValues[submeasure.name])
            .filter(item => !_.isNil(item))
            .join(" / ")

        this.props.change(C.POC_MEASURE_FORM, this.props.name, newValue)
    }

    // noinspection JSCheckFunctionSignatures
    shouldComponentUpdate(nextProps) {
        return (
            this.props.disabled !== nextProps.disabled ||
            !_.isEqual(
                _.pick(this.props.formValues, this.props.formKeys),
                _.pick(nextProps.formValues, this.props.formKeys)
            )
        ) // formKeys contains the field names of both this measure and its submeasures
    }

    render() {
        return (
            <div className="measure-composite">
                <Field
                    {...this.props}
                    style={{ display: "none" }}
                    component={SelectField}
                    options={this.props.dropListOptions}
                />
                {this.props.submeasureProps.map(props => (
                    <CompositeSubMeasure
                        key={props.id}
                        {...props}
                        disabled={this.props.disabled}
                        className="submeasure-composite"
                    />
                ))}
            </div>
        )
    }
}

const CompositeMeasuresWrapper = props => {
    const name = getMeasureIdName(props)
    const submeasureProps = props.subMeasures.map(subMeasure => ({
        ...subMeasure,
        subMeasure,
        placeholder: subMeasure.name,
        nameText: subMeasure.name,
        name: getCompositeSubmeasureIdName(props.id)(subMeasure)
    }))

    const validators = useMemo(() => getValidators(name, props), [name, props])

    return (
        <CompositeMeasures
            {...props}
            name={name}
            submeasureProps={submeasureProps}
            formKeys={[name, ...submeasureProps.map(s => s.name)]}
            validate={validators}
            dropListOptions={getDropListOptions(props.dropListOptions)}
        />
    )
}

export default connect(
    state => ({
        formValues: getFormValues(C.POC_MEASURE_FORM)(state)
    }),
    { change }
)(CompositeMeasuresWrapper)
