import {
    LOGIN_FORM,
    LOGIN_TRANSLATION,
    RESET_PASSWORD_FORM
} from "./authentication/constants"
import {
    FILTER_TRANSLATION,
    PATIENT_LIST_FILTER_FORM,
    GENERATE_PATIENT_LIST_FORM
} from "./patient_list/constants"
import {
    CAMPAIGN_FORM,
    CAMPAIGN_FILTERS_FORM,
    CAMPAIGN_TRANSLATION,
    NEW_CAMPAIGN_FORM
} from "./campaigns/constants"
import { QSR_TRANSLATION, QUALITY_SUMMARY_FORM } from "./qsr/constants"
import { QSR_FILTERS_FORM } from "./qsr_dashboard/constants"
import { RISK_FILTERS_FORM } from "./risk_dashboard/constants"
import {
    DEACTIVATE_TRANSLATION,
    DEMOGRAPHICS_TRANSLATION,
    EDIT_PATIENT_DEMOGRAPHICS_FORM,
    DEACTIVATE_PATIENT_FORM
} from "./info/constants"
import {
    ADD_COMMUNITY_LINKAGE_FORM,
    ADD_COMMUNITY_LOCATION_FORM,
    COMMUNITY_LOCATIONS_TRANSLATION
} from "./locations/constants"
import { LINAKGES_TRANSLATION } from "./community_linkages/constants"
import {
    ADD_NEW_PATIENT_FORM,
    ADD_NEW_PATIENT_TRANSLATION
} from "./add_new_patient/constants"
import {
    UTILIZATION_DETAILS_FIELDS_TRANSLATION,
    UTILIZATION_DETAILS_FORM
} from "./utilization_details/constants"
import {
    CARE_MANAGER_TRANSLATION,
    EDIT_CARE_MANAGER_FORM
} from "./care_team/constants"
import { ADD_RISK_FORM, RISK_TRANSLATION } from "./patient_risk/constants"
import { TCM_POPOVER_FORM } from "./attention/constants"
import {
    FILTER_TRANSLATION as CU_TRANSLATION,
    PRINT_UTILIZATION_FORM,
    UTILIZATION_FILTERS_FORM
} from "./cost_utilization/constants"
import {
    ADMIN_TRANSLATION,
    ADD_PRACTICE_UNIT_FORM,
    ADD_USER_FORM,
    EDIT_USER_FORM,
    EDIT_PRACTICE_UNIT_FORM,
    PRACTICE_TRANSLATION
} from "./admin/constants"
import { MY_PROFILE_FORM } from "./my_account/constants"
import {
    POC_PRINT_MODAL_FORM,
    BATCH_PRINT_MODAL_FORM,
    PRINT_TRANSLATION
} from "./batch_print/constants"
import { ADD_MEASURE_TREE_FORM, POC_MEASURE_FORM } from "./measures/constants"
import {
    SITE_SETTINGS_FORM,
    SITE_SETTINGS_TRANSLATION
} from "./site_settings/constants"
import {
    NAME as CMI_FILTERS_FORM,
    FILTERS_TRANSLATION as CMI_TRANSLATION
} from "./outreach_dashboard/constants"
import { COST_DASHBOARD_FILTERS_FORM } from "./cost_dashboard/constants"
import {
    COST_REPORT_FILTERS_FORM,
    FILTER_TRANSLATION as COST_REPORT_TRANSLATION
} from "./cost_report/constants"
import { FILTER_TRANSLATION as COST_DASHBOARD_TRANSLATION } from "./cost/constants"
import {
    TOC_FILTERS_FORM,
    FILTER_TRANSLATION as TOC_TRANSLATION
} from "./toc/constants"
import { TCM_FILTERS_FORM } from "./tcm/constants"
import {
    NAME as TAG_FORM,
    FILTER_TRANSLATION as TAG_FILTER_TRANSLATION
} from "./patient_tags/constants"
import { EDIT_TAG_FORM } from "./user_tags/constants"
import {
    ADD_ALERT_FORM,
    TRANSLATION as ALERT_TRANSLATION
} from "./alerts/constants"
import {
    AWV_FILTERS_FORM,
    FILTER_TRANSLATION as AWV_TRANSLATION
} from "./awv/constants"
import {
    PATIENT_AUDIT_FILTERS,
    PA_STATUS_FORM,
    FILTER_TRANSLATION as AUDIT_TRANSLATION,
    TABLE_TRANSLATION as AUDIT_TABLE_TRANSLATION
} from "./patient_audits/constants"

// TODO it'd be nice if we could just pass these into the form directly instead of having to compile them all here
const TRANSLATION = {
    [LOGIN_FORM]: LOGIN_TRANSLATION,
    [RESET_PASSWORD_FORM]: ADMIN_TRANSLATION,
    [PATIENT_LIST_FILTER_FORM]: FILTER_TRANSLATION,
    [GENERATE_PATIENT_LIST_FORM]: FILTER_TRANSLATION,
    [CAMPAIGN_FORM]: CAMPAIGN_TRANSLATION,
    [CAMPAIGN_FILTERS_FORM]: CAMPAIGN_TRANSLATION,
    [NEW_CAMPAIGN_FORM]: CAMPAIGN_TRANSLATION,
    [QUALITY_SUMMARY_FORM]: QSR_TRANSLATION,
    [QSR_FILTERS_FORM]: QSR_TRANSLATION,
    [RISK_FILTERS_FORM]: QSR_TRANSLATION,
    [TOC_FILTERS_FORM]: TOC_TRANSLATION,
    [TCM_FILTERS_FORM]: TOC_TRANSLATION,
    [EDIT_PATIENT_DEMOGRAPHICS_FORM]: DEMOGRAPHICS_TRANSLATION,
    [DEACTIVATE_PATIENT_FORM]: DEACTIVATE_TRANSLATION,
    [ADD_NEW_PATIENT_FORM]: ADD_NEW_PATIENT_TRANSLATION,
    [ADD_COMMUNITY_LOCATION_FORM]: COMMUNITY_LOCATIONS_TRANSLATION,
    [ADD_COMMUNITY_LINKAGE_FORM]: LINAKGES_TRANSLATION,
    [UTILIZATION_DETAILS_FORM]: UTILIZATION_DETAILS_FIELDS_TRANSLATION,
    [EDIT_CARE_MANAGER_FORM]: CARE_MANAGER_TRANSLATION,
    [ADD_RISK_FORM]: RISK_TRANSLATION,
    [UTILIZATION_FILTERS_FORM]: CU_TRANSLATION,
    [PRINT_UTILIZATION_FORM]: CU_TRANSLATION,
    [MY_PROFILE_FORM]: ADMIN_TRANSLATION,
    [EDIT_USER_FORM]: ADMIN_TRANSLATION,
    [ADD_USER_FORM]: ADMIN_TRANSLATION,
    [ADD_PRACTICE_UNIT_FORM]: PRACTICE_TRANSLATION,
    [EDIT_PRACTICE_UNIT_FORM]: PRACTICE_TRANSLATION,
    [POC_PRINT_MODAL_FORM]: PRINT_TRANSLATION,
    [BATCH_PRINT_MODAL_FORM]: PRINT_TRANSLATION,
    [SITE_SETTINGS_FORM]: SITE_SETTINGS_TRANSLATION,
    [CMI_FILTERS_FORM]: CMI_TRANSLATION,
    [COST_DASHBOARD_FILTERS_FORM]: COST_DASHBOARD_TRANSLATION,
    [COST_REPORT_FILTERS_FORM]: COST_REPORT_TRANSLATION,
    [TAG_FORM]: TAG_FILTER_TRANSLATION,
    [ADD_ALERT_FORM]: ALERT_TRANSLATION,
    [AWV_FILTERS_FORM]: AWV_TRANSLATION,
    [PATIENT_AUDIT_FILTERS]: AUDIT_TRANSLATION,
    [PA_STATUS_FORM]: AUDIT_TABLE_TRANSLATION,
    // we don't want any labels on the forms after this point actually. Only included for completeness.
    [TCM_POPOVER_FORM]: {},
    [EDIT_TAG_FORM]: {},
    [POC_MEASURE_FORM]: {},
    [ADD_MEASURE_TREE_FORM]: {}
}

export default TRANSLATION
