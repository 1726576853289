import _ from "lodash"
import { connect } from "react-redux"
import { PatientListLink } from "../../qsr_dashboard/cell_renderers/PatientListLink"
import * as reduxActions from "../../patient_list/actions"
import * as PLC from "../../patient_list/constants"
import { getFilterValues } from "../../utils"
import * as C from "../constants"

const FILTER_MAPPING_INVERTED = _.invert(C.FILTER_MAPPING)

export default connect((state, { columnData }) => {
    const filters = state[C.NAME].filters
    return {
        filters: {
            ...getFilterValues(filters, FILTER_MAPPING_INVERTED),
            [PLC.FILTERS.POPULATION]: filters[C.FILTERS.POPULATION] // specified separately because this one doesn't want to be wrapped in an array
        },
        ...columnData
    }
}, reduxActions)(PatientListLink)
