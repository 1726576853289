import React, { Component } from "react"
import { connect } from "react-redux"
import { openLocationModal } from "../../locations/actions"
import * as reduxActions from "../actions"
import Page from "./Page"

export class PageContainer extends Component {
    componentDidMount() {
        this.props.pageMounted()
    }

    render() {
        return this.props.isLoggedIn ? <Page {...this.props} /> : <div />
    }
}

export default connect(
    state => ({
        isLoggedIn: state.authentication.isLoggedIn
    }),
    {
        ...reduxActions,
        openLocationModal
    }
)(PageContainer)
