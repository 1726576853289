import React, { useState } from "react"
import { Card, Collapse, Fade } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import { ButtonGroup } from "../../pickers"
import { CostChart } from "./CostChart"
import * as C from "../constants"

export const CostChartCard = ({
    categories,
    data,
    error,
    loading,
    months,
    title
}) => {
    const [view, setView] = useState(C.COST_VIEWS.ABSOLUTE)
    const [show, setShow] = useState(true)

    return (
        <Card className="card-table patient-list-chart cost-spending-chart">
            <Card.Header>
                <button
                    className="patient-list-chart-expander"
                    onClick={() => setShow(!show)}
                    aria-controls="patient-list-cost-chart"
                    aria-expanded={show}
                >
                    <span className="card-collapse-clickable">
                        {show ? (
                            <i className="fa fa-caret-up" />
                        ) : (
                            <i className="fa fa-caret-down" />
                        )}
                    </span>
                    <Card.Title as="h3">
                        {title || "Monthly Spending Breakdown"}
                    </Card.Title>
                </button>
                <Fade in={show}>
                    <ButtonGroup
                        value={view}
                        onClick={setView}
                        options={C.COST_VIEW_OPTIONS_PLAIN}
                    />
                </Fade>
            </Card.Header>
            <Collapse in={show}>
                <Card.Body id="patient-list-cost-chart">
                    <LEHOC
                        loading={loading}
                        error={error}
                        list={data.months}
                        // don't use `months` proper because that's never empty
                    >
                        <CostChart
                            categories={categories}
                            months={months[view]}
                        />
                    </LEHOC>
                </Card.Body>
            </Collapse>
        </Card>
    )
}
