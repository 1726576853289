import React from "react"
import { Field } from "redux-form"
import { Col, FormGroup } from "react-bootstrap"
import _ from "lodash"
import { Toggle } from "../../core/Toggle"
import { POPULATION_VALUES } from "../../constants"
import * as C from "../constants"

export const _ToggleField = ({ input, ...props }) => {
    const { name, value: rawValue } = input
    const stateProps = { htmlFor: name, className: "greyed_out" }
    const value = rawValue === POPULATION_VALUES.INCENTIVIZED
    const mainLabel = _.at(C.SITE_SETTINGS_TRANSLATION, name)[0]

    return (
        <FormGroup as={Col} sm={12} className="toggle-field">
            {mainLabel && <b>{mainLabel}:</b>}
            <label {...(value && stateProps)}>All Patients</label>
            <Toggle {...input} value={value} {...props} />
            <label {...(!value && stateProps)}>Incentivized Only</label>
        </FormGroup>
    )
}

const ToggleField = ({ name }) => (
    <Field
        name={name + "." + C.POPULATION}
        component={_ToggleField}
        normalize={value =>
            value ? POPULATION_VALUES.INCENTIVIZED : POPULATION_VALUES.ALL
        }
    />
)

export default ToggleField
