import * as C from "./constants"

export const showUserTagsModal = () => ({ type: C.SHOW_USER_TAGS_MODAL })
export const hideUserTagsModal = () => ({ type: C.HIDE_USER_TAGS_MODAL })

export const selectTag = payload => ({ type: C.SELECT_TAG, payload })
export const editTag = () => ({ type: C.EDIT_TAG })
export const cancelEdit = () => ({ type: C.CANCEL_EDIT_TAG })
export const saveTag = payload => ({ type: C.SAVE_TAG, payload })
export const cancelSaveTag = () => ({ type: C.CANCEL_SAVE_TAG })
export const saveTagComplete = payload => ({
    type: C.SAVE_TAG_COMPLETE,
    payload
})
export const deleteTag = payload => ({ type: C.DELETE_TAG, payload })
export const deleteTagComplete = () => ({ type: C.DELETE_TAG_COMPLETE })

export const setTagPrivileges = payload => ({
    type: C.SET_TAG_PRIVILEGES,
    payload
})
export const setTagPrivilegesComplete = payload => ({
    type: C.SET_TAG_PRIVILEGES_COMPLETE,
    payload
})
