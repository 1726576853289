import React from "react"
import { connect } from "react-redux"
import fetcher from "../modules/core/fetcher"
import { setPocId } from "../modules/structure/actions"
import { ApiTypes } from "../modules/api"
import { urlParamsObject } from "../modules/utils"
import { PATIENT_ID } from "../modules/constants"
import { ExternalRedirect } from "./ExternalRedirect"

export const AthenaRedirect = props => (
    <ExternalRedirect
        {...props}
        errorText="No patient exists with the specified Athena context ID and member ID."
    />
)

export default connect(state => ({ poc_id: state.poc_id }), {
    setPocId
})(
    fetcher({
        name: PATIENT_ID,
        endpoint: (state, props) => {
            const { member_id, context_id } = urlParamsObject(
                props.location.search
            )
            return `/api/athena?memberId=${member_id}&contextId=${context_id}`
        },
        method: ApiTypes.GET
    })(AthenaRedirect)
)
