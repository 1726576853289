import React, { Component } from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"

import LEHOC from "../../core/LoadingErrorHOC"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import FilterHeader from "../../core/FilterHeader"
import CardTitle from "../../core/CardTitle"
import DashboardFilters from "../../dashboard_filters/components"
import { FiltersForm } from "../../qsr_dashboard/components/FiltersForm"
import { tableFactory } from "../../table"
import { constants as PC, ButtonGroup } from "../../pickers"
import * as C from "../constants"
import { NETWORK_COLUMNS, PRACTICE_COLUMNS, PROVIDER_COLUMNS } from "../columns"
import { getTableName } from "../helpers"

const createTable = (aggregation, columns) =>
    tableFactory({
        name: getTableName(aggregation),
        columns,
        sortBy: [C.AGGREGATION_MEASURES[aggregation]],
        sortDirection: [SortDirection.ASC]
    })

const networkTable = createTable(PC.VIEWS.NETWORK, NETWORK_COLUMNS)
const practiceTable = createTable(PC.VIEWS.PRACTICE, PRACTICE_COLUMNS)
const providerTable = createTable(PC.VIEWS.PROVIDER, PROVIDER_COLUMNS)
// they all have to be separate because if they shared one set of column settings, then saving any column structure in Network view would hide the Practice Unit column in Practice view.

class RiskDashboard extends Component {
    getTable = () => {
        switch (this.props.aggregation) {
            case PC.VIEWS.NETWORK:
                return networkTable
            case PC.VIEWS.PRACTICE:
                return practiceTable
            case PC.VIEWS.PROVIDER:
                return providerTable
            default:
                return {}
        }
    }

    render() {
        const { Settings, Table, Description } = this.getTable()

        return (
            <Card className="risk-dashboard dashboard-table card-table">
                <Card.Header>
                    <div className="dashboard-table-controls">
                        <div className="dashboard-table-title">
                            <CardTitle>Risk Adjustment</CardTitle>
                            <DashboardFilters
                                {...this.props}
                                title="Edit Risk Filters"
                                form={C.RISK_FILTERS_FORM}
                                formComponent={FiltersForm}
                                initialValues={this.props.filters}
                                emptyValues={C.initialState.filters}
                                useConditions
                            />
                        </div>

                        <ButtonGroup
                            value={this.props.aggregation}
                            onClick={this.props.setAggregation}
                            options={PC.VIEW_OPTIONS}
                        />
                        <ExportIcon onClick={this.props.exportRisks} />
                        <PrintIcon onClick={this.props.printRisks} />
                        <Settings />
                    </div>

                    <FilterHeader filters={this.props.filters} />
                </Card.Header>
                <Description />
                <Card.Body>
                    <LEHOC
                        loading={this.props.loading}
                        error={this.props.error}
                        list={this.props.data.risks}
                    >
                        <Table list={this.props.data?.risks || []} />
                    </LEHOC>
                </Card.Body>
            </Card>
        )
    }
}

export default RiskDashboard
