import { TABLE_KEYS, FILTERS } from "../qsr/constants"
import { VIEWS } from "../pickers/constants"
import { POPULATION_VALUES } from "../constants"

export { COMPLIANCE, POPULATION_VALUES, POPULATION_OPTIONS } from "../constants"

export const DASHBOARD_TABS = "DASHBOARD_TABS"
export const NAME = "QSR_DASHBOARD"
export const QSR_FILTERS_FORM = "QSR_FILTERS_FORM"

// actions
export var SET_QSR_AGGREGATION = "SET_QSR_AGGREGATION"
export var SET_QSR_DASHBOARD_FILTERS = "SET_QSR_DASHBOARD_FILTERS"
export const PRINT_QSR_DASHBOARD = "PRINT_QSR_DASHBOARD"
export const EXPORT_QSR_DASHBOARD = "EXPORT_QSR_DASHBOARD"

export const TABLE_TRANSLATION = {
    [TABLE_KEYS.MEASURE_NAME]: "Measure",
    [TABLE_KEYS.PROVIDER_NAME]: "Provider",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice Unit",
    [TABLE_KEYS.RATE_TO_DATE]: "Current",
    [TABLE_KEYS.HVPA_RATE]: "Network",
    [TABLE_KEYS.GOAL_RATE]: "Target",
    [TABLE_KEYS.ELIGIBLE]: "Total Elig",
    [TABLE_KEYS.MET]: "Met",
    [TABLE_KEYS.UNMET]: "Not Met",
    [TABLE_KEYS.PAYOUT_AVAILABLE]: "$Available",
    [TABLE_KEYS.PAYOUT_EARNED]: "$Earned",
    [TABLE_KEYS.PAYOUT_REMAINING]: "$Remaining",
    [TABLE_KEYS.RATE_BREAKDOWN]: "Performance Rate",
    [TABLE_KEYS.MONEY_BREAKDOWN]: "Incentive Opportunity",
    [TABLE_KEYS.INCENTIVE_DIFFERENCE]: "Incentive Reconciliation"
}

export const AGGREGATION_MEASURES = {
    [VIEWS.NETWORK]: TABLE_KEYS.MEASURE_NAME,
    [VIEWS.PRACTICE]: TABLE_KEYS.PRACTICE_NAME,
    [VIEWS.PROVIDER]: TABLE_KEYS.PROVIDER_NAME
}

// initial state

export const initialState = {
    aggregation: VIEWS.NETWORK,
    filters: { [FILTERS.POPULATION]: POPULATION_VALUES.ALL }
}
