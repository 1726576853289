import React from "react"
import { SortDirection } from "react-virtualized"
import _ from "lodash"
import { tableFactory } from "../../table"
import * as C from "../constants"
import { HCC_TABLE_COLUMNS } from "../columns"
import { ConditionCard } from "./ConditionCard"

const tableComponents = tableFactory({
    name: C.NAME,
    columns: HCC_TABLE_COLUMNS,
    sortBy: [C.TABLE_KEYS.LAST_IDENTIFIED_DATE, C.TABLE_KEYS.CONDITION_NAME],
    sortDirection: [SortDirection.DESC, SortDirection.ASC]
})

const sumByRisk = metConditions =>
    +_.sumBy(metConditions, C.TABLE_KEYS.HCC_RISK).toFixed(3)

const Conditions = props => {
    const { data } = props
    const metConditions = _.filter(data, C.TABLE_KEYS.MET)
    const allMet = metConditions.length === data.length
    const headerText =
        `${metConditions.length}/${data.length} met, ` +
        `${sumByRisk(metConditions)}/${sumByRisk(data)} HCC RAF`
    const headerContent = data.length ? (
        <span>
            {" - "}
            <span style={{ color: allMet ? "#00a651" : "#cc0033" }}>
                {headerText}
            </span>
        </span>
    ) : (
        ""
    )

    return (
        <ConditionCard
            title="HCC Conditions"
            headerContent={headerContent}
            {...tableComponents}
            {...props}
        />
    )
}

export default Conditions
