import React from "react"
import { Field } from "redux-form"
import { SelectField } from "../../../../../core/CustomFields"
import * as C from "../../../../constants"
import { CheckboxField } from "../CustomFields"
import { NumericWrapper } from "../NumericWrapper"

export const CompositeSubMeasure = props => {
    switch (props.dataType) {
        case C.ALPHANUMERIC:
            return <NumericWrapper {...props} />
        case C.ENUMERATION:
            return <Field {...props} component={SelectField} />
        case C.CHECKBOX:
            return <Field {...props} component={CheckboxField} />
        default:
            return <div />
    }
}

export default CompositeSubMeasure
