import React, { Component } from "react"
import { connect } from "react-redux"
import Notifications from "react-notification-system-redux"
import { Redirect, Route, Switch } from "react-router-dom"
import structure from "./modules/structure"
import { urlParamsObject } from "./modules/utils"
import {
    getActiveRoutes,
    isModuleDisabledSelector
} from "./modules/moduleUtils"
import { loginRoute, protectedRoutes, systemDown } from "./routes"

const Notifs = connect(({ notifications }) => ({ notifications }))(
    ({ notifications }) => {
        return (
            <Notifications
                notifications={notifications}
                style={structure.constants.NOTIFICATION_CONFIG}
            />
        )
    }
)

export default class App extends Component {
    componentDidMount() {
        this.props.store.dispatch({ type: "PAGE_LOAD" })
    }

    render() {
        return (
            <>
                <Switch>
                    <LoginRoute exact {...loginRoute} />
                    {/*<StatusRoute exact {...statusRoute} />*/}
                    {/*XXX disabling the status page until we can figure out how to get it to work on nginx*/}
                    <PrivateRoute />
                </Switch>
                <Notifs />
            </>
        )
    }
}

const ProtectedApplication = props => (
    <structure.Structure {...props}>
        <Switch>
            {getActiveRoutes(protectedRoutes, props).map(route =>
                route.redirect ? (
                    <Redirect
                        from={route.path}
                        to={route.redirect}
                        key={route.path}
                    />
                ) : (
                    <Route
                        path={route.path}
                        component={route.component}
                        key={route.path}
                        {...props}
                    />
                )
            )}
        </Switch>
    </structure.Structure>
)

const mapStateToProps = state => ({
    isLoggedIn: state.authentication.isLoggedIn,
    rehydrated: state.rehydrated,
    system_down: state.system_down,
    isModuleDisabled: isModuleDisabledSelector(state)
})

const PrivateRoute = connect(mapStateToProps)(
    ({
        component: Component,
        isLoggedIn,
        rehydrated,
        system_down,
        ...rest
    }) => (
        <Route
            {...rest}
            render={props => {
                const search = new URLSearchParams(props.location.search)
                search.set("redir", props.location.pathname)

                return system_down ? (
                    <systemDown.component />
                ) : !isLoggedIn && rehydrated ? (
                    <Redirect
                        to={{
                            pathname: "/",
                            search: search.toString()
                        }}
                    />
                ) : (
                    <ProtectedApplication {...rest} {...props} />
                )
            }}
        />
    )
)

const LoginRoute = connect(mapStateToProps)(
    ({
        component: Component,
        isLoggedIn,
        rehydrated,
        system_down,
        ...rest
    }) => (
        <Route
            {...rest}
            render={props => {
                const { redir, ...search } = urlParamsObject(
                    props.location.search
                )
                return system_down ? (
                    <systemDown.component />
                ) : isLoggedIn && rehydrated ? (
                    <Redirect
                        to={{
                            pathname: redir || "/dashboard",
                            search: new URLSearchParams(search).toString(),
                            state: { from: props.location }
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }}
        />
    )
)

//XXX disabling the status page until we can figure out how to get it to work on nginx
/*const StatusRoute = ({ component: Component, ...props }) => (
    <Route {...props}>
        <Component />
    </Route>
)*/
