import React, { useCallback, useRef, useState } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import fetcher from "../../core/fetcher"
import transformFilter from "../../transformFilter"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import {
    barChartDataSelector,
    chartHeaderSelector,
    chartNamesSelector,
    colorMapSelector,
    pieChartDataSelector
} from "../selectors"
import * as C from "../constants"
import { ProductCharts } from "./ProductCharts"

export const GraphConnect = connect((state, props) => ({
    // props include state injected by GraphContainer, which are used by some selectors
    barData: barChartDataSelector(state, props),
    pieData: pieChartDataSelector(state, props),
    names: chartNamesSelector(state, props),
    headers: chartHeaderSelector(state, props),
    colors: colorMapSelector(state)
}))(ProductCharts)

export const GraphContainer = props => {
    const pieRef = useRef()
    const barRef = useRef()

    const [hidden, setHidden] = useState([])
    const [pivoted, setPivoted] = useState(false)
    const [show, setShow] = useState(true)

    const updateHidden = useCallback(
        id => {
            pieRef?.current?.toggle(id)
            barRef?.current?.toggle(id)

            setHidden(hidden => {
                if (_.isNil(id)) return
                if (hidden.includes(id)) return _.without(hidden, id)
                return [...hidden, id]
            })
        },
        [pieRef, barRef]
    )
    const onShow = useCallback(() => setShow(show => !show), [])
    const onPivot = useCallback(() => {
        setPivoted(pivoted => !pivoted)
    }, [])

    if (!props.loading && _.isEmpty((props.data || {}).productPayers)) {
        return null
    }

    return (
        <GraphConnect
            {...props}
            pieRef={pieRef}
            barRef={barRef}
            hidden={hidden}
            toggle={pivoted}
            show={show}
            onShow={onShow}
            onPivot={onPivot}
            onUpdate={updateHidden}
        />
    )
}

export default fetcher({
    name: C.PATIENT_LIST_CHART,
    endpoint: state => {
        const filter = transformFilter(state.applied_filters)
        const compacted = _.pickBy(filter, v => !_.isEmpty(v))
        return urlWithParams(`/api/product/chart`, compacted)
    },
    method: ApiTypes.GET
})(GraphContainer)
