import React, { useEffect, useMemo } from "react"
import { bar } from "billboard.js"
import ProductChart from "./ProductChart"
import _ from "lodash"

const CONFIG = {
    padding: {
        bottom: 55
    },
    axis: {
        x: {
            type: "category"
        }
    }
}

const BarChart = ({ data, forwardRef, hidden, headers, ...props }) => {
    const chartData = useMemo(
        () =>
            _.merge(
                {
                    type: bar(),
                    groups: [headers],
                    keys: { x: "key" }
                },
                data
            ),
        [data, headers]
    )

    useEffect(() => {
        // update groups when the keys change
        forwardRef?.current?.groups?.([headers])
    }, [headers])

    return (
        <ProductChart
            {...props}
            forwardRef={forwardRef}
            config={CONFIG}
            headers={headers}
            data={chartData}
            name="bar-chart"
        />
    )
}

export default BarChart
