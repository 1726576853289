import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import moment from "moment"
import { DATE_STRING } from "../../dates"
import UndoRemoveButtons from "../../core/UndoRemoveButtons"
import { isSuperuser } from "../../authentication/selectors"
import { deleteComment, restoreComment } from "../actions"

export const Comment = ({ item, canEdit, ...props }) => (
    <li className={classNames("poc-comment", item.removed && "deleted")}>
        <span className="date-posted">
            {item.loginId}
            {" - "}
            {moment(item.entryDt).format(`${DATE_STRING}, hh:mm a`)}
        </span>
        <p
            style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word"
            }}
        >
            {item.comment}
        </p>
        {canEdit && (
            <UndoRemoveButtons
                rowData={item}
                field="userCommentId"
                {...props}
            />
        )}
    </li>
)

export default connect(
    (state, { item }) => ({
        canEdit:
            item.userId === state.authentication.userId || isSuperuser(state)
    }),
    { remove: deleteComment, undo: restoreComment }
)(Comment)
