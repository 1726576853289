import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import {
    optionLookupsSelector,
    patientListCombiner
} from "../patient_list/selectors"
import * as PLC from "../patient_list/constants"
import { getStartOfYear } from "../dates"
import { compactFilters, encodeFiltersInUrl } from "../utils"
import * as CC from "../constants"
import * as C from "./constants"

export const patientListSelector = createSelector(
    getResourceData(C.PATIENTS, data => data.patients),
    getResourceData(CC.MEASURES, data => data.measuresMap),
    optionLookupsSelector,
    state => state.server_status.anchorDate,
    patientListCombiner
)

export const measureNameSelector = createSelector(
    getResourceData(CC.MEASURES, data => data.measuresMap),
    state => state[C.NAME].measureId,
    (measureMap, measureId) => measureMap?.[measureId]?.name
)

export const filterOptionsSelector = createSelector(
    getResourceData(CC.FILTER_OPTIONS),
    getResourceData(C.NAME),
    (options, { measureId, partialMeasureId }) => ({
        ...options,
        measures: options.measures?.filter(measure =>
            [measureId, partialMeasureId].includes(measure.value)
        )
    })
)

export const isDefaultSelector = createSelector(
    state => state[C.NAME],
    ({ measureId, filters }) =>
        _.isEqual(
            compactFilters(filters),
            compactFilters({
                ...C.DEFAULT_FILTERS,
                [PLC.FILTERS.MEASURES]: measureId
            })
        )
)

export const cmPickerValueSelector = createSelector(
    state => state[C.NAME].filters,
    (filters = {}) => {
        const [measureId, compliant] = _.at(filters, [
            PLC.FILTERS.MEASURES,
            PLC.FILTERS.COMPLIANT
        ])
        const compliantMultiplier =
            _.isEmpty(compliant) || compliant?.length === 2
                ? -1
                : _.isMatch(compliant, [CC.COMPLIANCE.COMPLIANT])
                ? 1
                : 0
        return (measureId ?? 0) * compliantMultiplier
    }
)

const getFiltersForMeasure = (measureId, filters, anchorDate) => ({
    ...filters,
    [PLC.FILTERS.MEASURES]: Math.abs(measureId),
    [PLC.FILTERS.COMPLIANT]: measureId > 0 ? [CC.COMPLIANCE.COMPLIANT] : [],
    [PLC.FILTERS.LAST_SERVICE_FROM]:
        measureId > 0 ? getStartOfYear(anchorDate) : null
})
const getOptionMaker = (measureId, filters, anchorDate) => (value, label) => ({
    value,
    label,
    href: encodeFiltersInUrl(
        "/care-management/" + measureId,
        getFiltersForMeasure(value, filters, anchorDate)
    )
})

export const cmPickerOptionsSelector = createSelector(
    getResourceData(C.NAME),
    state => state[C.NAME].measureId,
    state => state[C.NAME].filters,
    state => state.server_status.anchorDate,
    (data, measureId = 0, filters, anchorDate) => {
        const makeOption = getOptionMaker(measureId, filters, anchorDate)

        const compliantOptions =
            data.numBillCdNeededForGoal === 2
                ? [
                      makeOption(
                          data.partialMeasureId ?? 0,
                          `${data.numPat_1BillCd ?? 0} with one billing code`
                      ),
                      makeOption(
                          measureId,
                          `${data.numPat_2plusBillCd ??
                              0} with 2+ billing codes`
                      )
                  ]
                : [
                      makeOption(
                          measureId,
                          `${_.sum(
                              _.compact([
                                  data.numPat_1BillCd,
                                  data.numPat_2plusBillCd
                              ])
                          )} with 1+ billing codes`
                      )
                  ]

        return [
            makeOption(-measureId, `${data.numPatElig ?? 0} eligible patients`),
            ...compliantOptions
        ]
    }
)
