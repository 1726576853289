import React, { Component } from "react"
import { connect } from "react-redux"
import { Button, Col, FormGroup, Row } from "react-bootstrap"
import { Field, getFormValues, reduxForm } from "redux-form"
import { getResourceData } from "../../core/fetcher"
import {
    SelectField,
    LabelledDatePicker,
    TextInput
} from "../../core/CustomFields"
import { after1900, validDate } from "../../core/validators"
import { GuideLink } from "../../indicators"
import { addNewCommunityLinkage } from "../actions"
import { validateNewLinkage } from "../validate"
import { getLinkages } from "../selectors"
import * as C from "../constants"
import LocationField from "./LocationField"

class AddCommunityLinkage extends Component {
    render() {
        const {
            linkageFormValues,
            communityLinkages,
            communityLinkageOptions,
            handleSubmit,
            dirty
        } = this.props
        const disabledText = validateNewLinkage(
            linkageFormValues,
            communityLinkages
        )

        return (
            <div className="add_community_linkage">
                <h4 style={{ display: "inline" }}>
                    Add New Service
                    <GuideLink page="view-patient-linked-services" />
                </h4>
                {!!disabledText && !!dirty ? (
                    <span style={{ color: "red", marginLeft: 15 }}>
                        {disabledText}
                    </span>
                ) : (
                    <span />
                )}
                <Row>
                    <Col
                        sm={6}
                        md={3}
                        lg={4}
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <Field
                            name={C.ADD_COMMUNITY_LINKAGE_FIELDS.CATEGORY}
                            options={communityLinkageOptions.categories}
                            component={SelectField}
                        />
                        <Field
                            name={C.ADD_COMMUNITY_LINKAGE_FIELDS.LOCATION}
                            component={LocationField}
                        />
                    </Col>
                    <Col
                        sm={6}
                        md={3}
                        lg={4}
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <Field
                            name={C.ADD_COMMUNITY_LINKAGE_FIELDS.STATUS}
                            options={communityLinkageOptions.statuses}
                            component={SelectField}
                        />
                        <Field
                            name={C.ADD_COMMUNITY_LINKAGE_FIELDS.DATE}
                            validate={[validDate, after1900]}
                            component={LabelledDatePicker}
                            notClearable
                            noHighlighting
                        />
                    </Col>
                    <Col
                        md={6}
                        lg={4}
                        className="add_community_linkage_comment"
                    >
                        <Field
                            name={C.ADD_COMMUNITY_LINKAGE_FIELDS.COMMENT}
                            component={TextInput}
                            isTextArea
                            notClearable
                        />
                        <FormGroup>
                            <Button
                                variant="primary"
                                disabled={!!disabledText}
                                className="btn-block"
                                onClick={handleSubmit}
                                style={{ height: "34px" }}
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}

const AddCommunityLinkageForm = reduxForm({
    form: C.ADD_COMMUNITY_LINKAGE_FORM
})(AddCommunityLinkage)

export default connect(
    state => ({
        linkageFormValues: getFormValues(C.ADD_COMMUNITY_LINKAGE_FORM)(state),
        communityLinkages: getLinkages(state),
        communityLinkageOptions: getResourceData(
            C.COMMUNITY_LINKAGES,
            data => data.communityLinkageOptions
        )(state)
    }),
    { addNewCommunityLinkage }
)(props => (
    <AddCommunityLinkageForm
        {...props}
        onSubmit={props.addNewCommunityLinkage}
    />
))
