import React from "react"
import { Link } from "react-router-dom"
import { SortDirection } from "react-virtualized"
import { Card } from "react-bootstrap"
import fetcher from "../../core/fetcher"
import LEHOC from "../../core/LoadingErrorHOC"
import CardTitle from "../../core/CardTitle"
import { tableFactory } from "../../table"
import { GuideLink } from "../../indicators"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import { FAVORITE_CAMPAIGN_COLUMNS } from "../columns"

const { Table, Description } = tableFactory({
    name: C.FAVORITE_CAMPAIGNS_NAME,
    columns: FAVORITE_CAMPAIGN_COLUMNS,
    sortBy: [C.TABLE_KEYS.CAMPAIGN_NAME],
    sortDirection: [SortDirection.ASC]
})

export const FavoriteCampaigns = ({ data: { campaigns }, loading, error }) => {
    const list = campaigns?.filter(
        item => item[C.USER_PINNED] || item[C.FORCE_PINNED]
    )
    return (
        <Card className="dashboard-table card-table">
            <Card.Header>
                <CardTitle>
                    <Link to="/campaigns">My Favorite Campaigns</Link>
                    <GuideLink page="view-favorite-campaigns-dashboard" />
                </CardTitle>
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC loading={loading} error={error} list={list}>
                    <Table list={list} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}

export default fetcher({
    name: C.FAVORITE_CAMPAIGNS_NAME,
    endpoint: "/api/campaigns",
    method: ApiTypes.GET
})(FavoriteCampaigns)
