import React from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"
import CardTitle from "../../core/CardTitle"
import LEHOC from "../../core/LoadingErrorHOC"
import { tableFactory } from "../../table"
import { COVERAGE_COLUMNS } from "../columns"
import * as C from "../constants"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: COVERAGE_COLUMNS,
    sortBy: [C.TABLE_KEYS.PRIORITY, C.TABLE_KEYS.SOURCE_NAME],
    sortDirection: [SortDirection.ASC, SortDirection.ASC]
})

export const PatientCoverage = ({ loading, error, data: { coverages } }) => (
    <Card className="card-table">
        <Card.Header id="priority-attention-list-header">
            <CardTitle>
                Coverage {_.isEmpty(coverages) ? " - No Data" : ""}
            </CardTitle>
            <Settings />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={coverages} hideEmpty>
                <Table list={coverages} />
            </LEHOC>
        </Card.Body>
    </Card>
)
